import React, {useState} from "react";
import {Badge, Button, Col, Form, InputGroup, Pagination, Table} from "react-bootstrap";
import Row from 'react-bootstrap/Row';
import GlobalConstants from "../../config/GlobalConstants";
import {Link} from "react-router-dom";
import {CreateAcquisition, CreateTestAcquisition, GetFilteredAcquisitions} from "../acquisitions/AcquisitionService";
import {ActionModal, InfoModal} from "../../generators/ModalGenerator";
import {Label} from "reactstrap";
import {GetFilteredProducts, GetProduct} from "../products/ProductService";
import DatePicker from "react-datepicker";
import {customDatePickerHeader, transformToZonedDateTime} from "../../util/ResourceService";
import {CreateCart} from "../carts/CartService";
import {CreateOrder} from "../order/OrderService";
import {GetFilteredCouponGroups, GetFilteredCoupons} from "../couponGroups/CouponGroupService";
import {AiOutlineFileAdd} from "react-icons/all";
import {ErrorHandler} from "../../util/ErrorHandler";

export function AccountAcquisitions(props) {

    let [acquisitions, setAcquisitions] = useState(props.acquisitions);
    let [error, setError] = useState(null)
    //Products
    let [products, setProducts] = useState([]);
    let [productsPage, setProductsPage] = useState(1);
    let [lastProductsPage, setLastProductsPage] = useState(1);
    let [productFilter, setProductFilter] = useState("");

    //Coupons & Coupon Groups
    let [selectedCouponGroup, setSelectedCouponGroup] = useState(null);
    let [couponGroups, setCouponGroups] = useState([]);
    let [couponGroupPage, setCouponGroupPage] = useState(1);
    let [lastCouponGroupPage, setLastCouponGroupPage] = useState(1);
    let [coupons, setCoupons] = useState([]);
    let [couponPage, setCouponPage] = useState(1);
    let [lastCouponPage, setLastCouponPage] = useState(1);

    //Creation
    let [productId, setProductId] = useState();
    let [product, setProduct] = useState();
    let [validUntil, setValidUntil] = useState();
    let [validUntilPicker, setValidUntilPicker] = useState();
    let [couponCode, setCouponCode] = useState();

    //Dialogs
    let [showCreateTestAcquisitionDialog, setShowCreateTestAcquisitionDialog] = useState(false);
    let [showCreatePreviewAcquisitionDialog, setShowCreatePreviewAcquisitionDialog] = useState(false);
    let [showCreateAccommodationAcquisitionDialog, setShowCreateAccommodationAcquisitionDialog] = useState(false);
    let [showProductPickerDialog, setShowProductPickerDialog] = useState(false);
    let [showCouponGroupPickerDialog, setShowCouponGroupPickerDialog] = useState(false);
    let [showCouponPickerDialog, setShowCouponPickerDialog] = useState(false);
    let [showAddCouponDialog, setShowAddCouponDialog] = useState(false);

    //API METHODS
    async function loadAcquisitions(page) {
        let loadedAcquisitions = await GetFilteredAcquisitions(this.state.account, {active_only: false, page: page});

        if(!loadedAcquisitions.error) {
            let acquisitions = loadedAcquisitions.result;
            setAcquisitions(acquisitions);
            props.onLoadPage(page);
        } else {
            setError(loadedAcquisitions);
        }
    }

    async function createTestAcquisition(productId) {
        let acquisition = {
            accountId: props.accountId,
            source: "TEST",
            assignmentType: "FULL",
            productId: productId
        };

        let createdAcquisition = await CreateTestAcquisition({id: props.accountId}, acquisition);

        if (!createdAcquisition.error) {
            acquisitions = acquisitions.push(createdAcquisition.result);
            setAcquisitions(acquisitions);
        } else {
            setError(createdAcquisition);
        }
    }

    async function createPreviewAcquisition(validUntil, productId) {
        let acquisition = {
            accountId: props.accountId,
            source: "ADMIN",
            assignmentType: "PREVIEW",
            validUntil: validUntil,
            productId: productId
        };

        let createdAcquisition = await CreateAcquisition({id: props.accountId}, acquisition);

        if (!createdAcquisition.error) {
            acquisitions = acquisitions.push(createdAcquisition.result);
            setAcquisitions(acquisitions);
        } else {
            setError(createdAcquisition);
        }
    }

    async function createAccommodationProduct(product) {
        return CreateCart(props.accountId, createCart(product))
            .then(createdCart => {
                if (!createdCart.error) {
                    let cart = createdCart.result;
                    CreateOrder(props.accountId, createOrder(cart.id, props.accountId, product.iapProductIdentifier))
                        .then(result => {
                            if (!result.error) {
                                setShowCreateAccommodationAcquisitionDialog(false);
                            } else {
                                setError(result);
                            }
                        }).catch(error => setError(error));
                } else {
                    setError(createdCart);
                }

            }).catch(error => setError(error));
    }

    async function addCoupon() {
        let createdOrder = await CreateOrder(props.accountId, {
            deviceId: 'acp_' + props.accountId,
            couponCode: couponCode,
        });

        if (!createdOrder.error) {
            setShowAddCouponDialog(false);
        } else {
            setError(createdOrder);
        }
    }

    async function loadCoupons(group, page) {
        let loadedCoupons = await GetFilteredCoupons(group.id, {page: page});
        if (!loadedCoupons.error) {
            setCoupons(loadedCoupons.result);
            setCouponPage(page);
            setLastCouponPage(Math.ceil(loadedCoupons.length / 20));
        } else {
            setError(loadedCoupons);
        }
    }

    async function loadCouponGroups(page) {
        let loadedGroups = await GetFilteredCouponGroups({page: page});
        if (!loadedGroups.error) {
            setCouponGroups(loadedGroups.result);
            setCouponGroupPage(page);
            setLastCouponGroupPage(Math.ceil(loadedGroups.length / 20));
        } else {
            setError(loadedGroups);
        }
    }

    async function loadProducts(page) {
        let loadedProducts = await GetFilteredProducts({title: productFilter, page: page});

        if (!loadedProducts.error) {
            setProducts(loadedProducts.result);
            setProductsPage(page);
            setLastProductsPage(Math.ceil(loadedProducts.length / 20));
        } else {
            setError(loadedProducts);
        }

    }

    async function loadSingleProduct(id) {
        let loadedProduct = await GetProduct(id);

        if (!loadedProduct.error) {
            setProduct(loadedProduct.result);
            setProductId(loadedProduct.result.id);
        } else {
            setError(loadedProduct);
        }
    }

    //DIALOGS
    function addCouponDialog() {
        return (
            <>
                <p>
                    Provide a coupon code that should be added. Afterwards, an order will be created.:
                </p>
                <Form>
                    <Row>
                        <Form.Group as={Col} controlId="couponCode">
                            <Label>Coupon Code</Label>
                            <InputGroup className="mb-3">
                                <Form.Control type="text" value={couponCode}
                                              onChange={(e) => setCouponCode(e.target.value)}/>
                                    <Button variant="outline-secondary" onClick={() => {
                                        setShowCouponGroupPickerDialog(true);
                                        loadCouponGroups(1).then(r => r);
                                    }}>Pick</Button>
                            </InputGroup>

                        </Form.Group>
                    </Row>
                </Form>
            </>
        );
    }

    function createTestOrAccommodationAcquisitionDialog() {
        return (
            <>
                <p>
                    Assign a product to the acquisition:
                </p>
                <Form>
                    <Row>
                        <Form.Group as={Col} controlId="productId">
                            <Label>Product ID</Label>
                            <InputGroup className="mb-3">
                                <Form.Control type="text" value={productId}
                                              onChange={(e) => setProductId(e.target.value)}/>
                                <Button variant="outline-secondary" onClick={() => {
                                    setShowProductPickerDialog(true);
                                    loadProducts(1).then(r => r);
                                }}>Pick</Button>
                            </InputGroup>
                        </Form.Group>
                    </Row>
                </Form>
            </>
        );
    }

    function createPreviewAcquisitionDialog() {
        return (
            <>
                <p>
                    Assign a product to the preview acquisition and set the validity date:
                </p>
                <Form>
                    <Row>
                        <Form.Group as={Col} controlId="validUntil">
                            <Label>Valid Until</Label>
                            <br/>
                            <DatePicker
                                todayButton={"Today"}
                                dateFormat={"yyyy-MM-dd"}
                                renderCustomHeader={({date, changeYear, changeMonth, decreaseMonth, increaseMonth, prevMonthButtonDisabled, nextMonthButtonDisabled}) => customDatePickerHeader(new Date(date), changeYear, changeMonth, decreaseMonth, increaseMonth, prevMonthButtonDisabled, nextMonthButtonDisabled)}
                                selected={validUntilPicker}
                                onChange={(date) => {
                                    setValidUntilPicker(date);
                                    setValidUntil(transformToZonedDateTime(date.toString()));
                                }}/>
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group as={Col} controlId="productId">
                            <Label>Product ID</Label>
                            <InputGroup className="mb-3">
                                <Form.Control type="text" value={productId}
                                              onChange={(e) => setProductId(e.target.value)}/>
                                    <Button variant="outline-secondary" onClick={() => {
                                        setShowProductPickerDialog(true);
                                        loadProducts(1).then(r => r);
                                    }}>Pick</Button>
                            </InputGroup>
                        </Form.Group>
                    </Row>
                </Form>
            </>
        );
    }

    function pickProductDialog() {
        return (
            <>
                <Form>
                    <Row>
                        <Form.Group as={Col} controlId="productFilter">
                            <InputGroup className="mb-3">
                                <Form.Control type="text" value={productFilter}
                                              placeholder="Search for a product by its title"
                                              onChange={(e) => setProductFilter(e.target.value)}/>
                                    <Button variant="outline-secondary"
                                            onClick={() => loadProducts(productsPage)}>Search</Button>
                            </InputGroup>
                        </Form.Group>
                    </Row>
                </Form>
                <Table responsive bordered hover striped>
                    <thead>
                    <tr>
                        <th>ID</th>
                        <th>State</th>
                        <th>Type</th>
                        <th>Title</th>
                        <th>IAP Product Identifier</th>
                    </tr>
                    </thead>
                    <tbody>
                    {products.map(product => (
                        <tr role="row" key={product.id} style={{cursor: "pointer"}} onClick={() => {
                            setProductId(product.id);
                            setProduct(product);
                            setShowProductPickerDialog(false);
                        }}>
                            <td>{product.id}</td>
                            <td>
                                <Badge bg={
                                    product.state === 'NEW' ? "primary" :
                                        product.state === 'ACTIVE' ? "success" :
                                            product.state === 'INACTIVE' ? "warning" : "danger"
                                }>{product.state}</Badge>
                            </td>
                            <td>
                                <Badge bg={
                                    product.productType === 'TIGERBOOK' ? "primary" :
                                        product.productType === 'MOVIE' ? "info" :
                                            product.productType === 'AUDIOBOOK' ? "light" :
                                                product.productType === 'BOOK' ? "success" : "dark"
                                }>{product.productType}</Badge>
                            </td>
                            <td>{product.title}</td>
                            <td>{product.iapProductIdentifier}</td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
                <Pagination>
                    <Pagination.Item hidden={productsPage === 1}
                                     onClick={() => loadProducts(1)}>&lt;&lt;</Pagination.Item>
                    <Pagination.Item hidden={productsPage === 1}
                                     onClick={() => loadProducts(productsPage - 1)}>&lt;</Pagination.Item>
                    <Pagination.Item hidden={productsPage <= 2}
                                     onClick={() => loadProducts(productsPage - 2)}>{productsPage - 2}</Pagination.Item>
                    <Pagination.Item hidden={productsPage === 1}
                                     onClick={() => loadProducts(productsPage - 1)}>{productsPage - 1}</Pagination.Item>
                    <Pagination.Item active={true}>{productsPage}</Pagination.Item>
                    <Pagination.Item hidden={productsPage === lastProductsPage}
                                     onClick={() => loadProducts(productsPage + 1)}>{productsPage + 1}</Pagination.Item>
                    <Pagination.Item hidden={productsPage >= lastProductsPage - 1}
                                     onClick={() => loadProducts(productsPage + 2)}>{productsPage + 2}</Pagination.Item>
                    <Pagination.Item hidden={productsPage === lastProductsPage}
                                     onClick={() => loadProducts(productsPage + 1)}>&gt;</Pagination.Item>
                </Pagination>
            </>
        );
    }

    function pickCouponGroupDialog() {
        return (
            <>
                <Table responsive bordered hover striped>
                    <thead>
                    <tr>
                        <th>ID</th>
                        <th>Name</th>
                        <th>Usage Limit</th>
                        <th>Aggregated Usage</th>
                        <th>Valid From</th>
                        <th>Valid Until</th>
                    </tr>
                    </thead>
                    <tbody>
                    {couponGroups.map(group => (
                        <tr role="row" key={group.id} style={{cursor: "pointer"}} onClick={() => {
                            setSelectedCouponGroup(group);
                            loadCoupons(group,1).then(r => {
                                setShowCouponGroupPickerDialog(false);
                                setShowCouponPickerDialog(true);
                            })
                        }}>
                            <td>{group.id}</td>
                            <td>{group.name}</td>
                            <td>{group.usageLimit}</td>
                            <td>{group.aggregatedUsage}</td>
                            <td>{group.validFrom}</td>
                            <td>{group.validUntil}</td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
                <Pagination>
                    <Pagination.Item hidden={couponGroupPage === 1}
                                     onClick={() => loadCouponGroups(1)}>&lt;&lt;</Pagination.Item>
                    <Pagination.Item hidden={couponGroupPage === 1}
                                     onClick={() => loadCouponGroups(couponGroupPage - 1)}>&lt;</Pagination.Item>
                    <Pagination.Item hidden={couponGroupPage <= 2}
                                     onClick={() => loadCouponGroups(couponGroupPage - 2)}>{couponGroupPage - 2}</Pagination.Item>
                    <Pagination.Item hidden={couponGroupPage === 1}
                                     onClick={() => loadCouponGroups(couponGroupPage - 1)}>{couponGroupPage - 1}</Pagination.Item>
                    <Pagination.Item active={true}>{couponGroupPage}</Pagination.Item>
                    <Pagination.Item hidden={couponGroupPage === lastCouponGroupPage}
                                     onClick={() => loadCouponGroups(couponGroupPage + 1)}>{couponGroupPage + 1}</Pagination.Item>
                    <Pagination.Item hidden={couponGroupPage >= lastCouponGroupPage - 1}
                                     onClick={() => loadCouponGroups(couponGroupPage + 2)}>{couponGroupPage + 2}</Pagination.Item>
                    <Pagination.Item hidden={couponGroupPage === lastCouponGroupPage}
                                     onClick={() => loadCouponGroups(couponGroupPage + 1)}>&gt;</Pagination.Item>
                </Pagination>
            </>
        );
    }

    function pickCouponDialog() {
        return (
            <>
                <Table responsive bordered hover striped>
                    <thead>
                    <tr>
                        <th>ID</th>
                        <th>Code</th>
                        <th>Usage Limit</th>
                        <th>Usage</th>
                        <th>Usable</th>
                        <th>Last Usage Date</th>
                    </tr>
                    </thead>
                    <tbody>
                    {coupons.map(coupon => (
                        <tr role="row" key={coupon.id} style={{cursor: "pointer"}} onClick={() => {
                            setCouponCode(coupon.code);
                            setShowCouponPickerDialog(false);
                        }}>
                            <td>{coupon.id}</td>
                            <td>{coupon.code}</td>
                            <td>{coupon.usageLimit}</td>
                            <td>{coupon.usage}</td>
                            <td>
                                <Badge
                                        bg={coupon.usable ? "success" : "danger"}>{coupon.usable ? "Y" : "N"}</Badge>
                            </td>
                            <td>{coupon.lastUsageDate}</td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
                <Pagination>
                    <Pagination.Item hidden={couponPage === 1}
                                     onClick={() => loadCoupons(selectedCouponGroup, 1)}>&lt;&lt;</Pagination.Item>
                    <Pagination.Item hidden={couponPage === 1}
                                     onClick={() => loadCoupons(selectedCouponGroup, couponPage - 1)}>&lt;</Pagination.Item>
                    <Pagination.Item hidden={couponPage <= 2}
                                     onClick={() => loadCoupons(selectedCouponGroup, couponPage - 2)}>{couponPage - 2}</Pagination.Item>
                    <Pagination.Item hidden={couponPage === 1}
                                     onClick={() => loadCoupons(selectedCouponGroup, couponPage - 1)}>{couponPage - 1}</Pagination.Item>
                    <Pagination.Item active={true}>{couponPage}</Pagination.Item>
                    <Pagination.Item hidden={couponPage === lastCouponPage}
                                     onClick={() => loadCoupons(selectedCouponGroup, couponPage + 1)}>{couponPage + 1}</Pagination.Item>
                    <Pagination.Item hidden={couponPage >= lastCouponPage - 1}
                                     onClick={() => loadCoupons(selectedCouponGroup, couponPage + 2)}>{couponPage + 2}</Pagination.Item>
                    <Pagination.Item hidden={couponPage === lastCouponPage}
                                     onClick={() => loadCoupons(selectedCouponGroup, couponPage + 1)}>&gt;</Pagination.Item>
                </Pagination>
            </>
        );
    }

    return (
        <>
            <button className="form-btn-ci-blue" type="button"
                    onClick={() => setShowCreatePreviewAcquisitionDialog(true)}><AiOutlineFileAdd/>&#xA0;Preview-Acquisition
            </button>
            <button className="form-btn-ci-blue" type="button"
                    onClick={() => setShowCreateAccommodationAcquisitionDialog(true)}><AiOutlineFileAdd/>&#xA0;Add accommodation product
            </button>
            <button className="form-btn-ci-blue" type="button"
                    onClick={() => setShowCreateTestAcquisitionDialog(true)}><AiOutlineFileAdd/>&#xA0;Add test product
            </button>
            <button className="form-btn-ci-blue" type="button" onClick={() => setShowAddCouponDialog(true)}><AiOutlineFileAdd/>&#xA0;Add coupon
            </button>

            <Table bordered hover responsive striped style={{marginTop: "10px"}}>
                <thead>
                <tr>
                    <th>ID</th>
                    <th>Type</th>
                    <th>Source</th>
                    <th>Valid Until</th>
                    <th>Slot Number</th>
                    <th>Subscription</th>
                    <th>Product</th>
                </tr>
                </thead>
                <tbody>
                {acquisitions.map(acquisition => (
                    <tr role="row" key={acquisition.id}>
                        <td><Link style={{fontStyle: "italic", color: "#333"}}
                                  to={"/" + GlobalConstants.APP_PATH + "accounts/" + acquisition.accountId + "/acquisitions/" + acquisition.id}>{acquisition.id}</Link>
                        </td>
                        <td>
                            <Badge bg={
                                acquisition.assignmentType === 'FULL' ? "primary" :
                                    acquisition.assignmentType === 'SUBSCRIPTION' ? "success" : "warning"
                            }>
                                {acquisition.assignmentType}
                            </Badge>
                        </td>
                        <td>
                            <Badge bg={
                                acquisition.source === 'UNKNOWN' ? "danger" :
                                    acquisition.source === 'SUPPORT' ? "warning" :
                                        acquisition.source === 'SUBSCRIPTION' ? "success" :
                                            acquisition.source === 'TEST' ? "primary" : "dark"
                            }>{acquisition.source}</Badge>
                        </td>
                        <td>{acquisition.validUntil}</td>
                        <td>{acquisition.slotNumber}</td>
                        <td><Link style={{fontStyle: "italic", color: "#333"}}
                                  to={"/" + GlobalConstants.APP_PATH + "accounts/" + acquisition.accountId + "/subscriptions/" + acquisition.subscriptionId}>{acquisition.subscriptionId}</Link>
                        </td>
                        <td><Link style={{fontStyle: "italic", color: "#333"}}
                                  to={"/" + GlobalConstants.APP_PATH + "products/" + acquisition.productId}>{acquisition.productId}</Link>
                        </td>
                    </tr>
                ))}
                </tbody>
            </Table>
            <Pagination>
                <Pagination.Item hidden={props.page === 1}
                                 onClick={() => loadAcquisitions(1)}>&lt;&lt;</Pagination.Item>
                <Pagination.Item hidden={props.page === 1}
                                 onClick={() =>loadAcquisitions(props.page - 1)}>&lt;</Pagination.Item>
                <Pagination.Item hidden={props.page <= 2}
                                 onClick={() => loadAcquisitions(props.page - 2)}>{props.page - 2}</Pagination.Item>
                <Pagination.Item hidden={props.page === 1}
                                 onClick={() => loadAcquisitions(props.page - 1)}>{props.page - 1}</Pagination.Item>
                <Pagination.Item active={true}>{props.page}</Pagination.Item>
                <Pagination.Item hidden={props.page === props.lastPage}
                                 onClick={() => loadAcquisitions(props.page + 1)}>{props.page + 1}</Pagination.Item>
                <Pagination.Item hidden={props.page >= props.lastPage - 1}
                                 onClick={() => loadAcquisitions(props.page + 2)}>{props.page + 2}</Pagination.Item>
                <Pagination.Item hidden={props.page === props.lastPage}
                                 onClick={() => loadAcquisitions(props.page + 1)}>&gt;</Pagination.Item>
            </Pagination>

            {/* DIALOGS */}
            <ActionModal show={showAddCouponDialog}
                         onHide={() => setShowAddCouponDialog(false)}
                         onAction={() => addCoupon()}
                         actionButtonText="Add"
                         title={"Add a coupon"}
                         body={addCouponDialog()}/>

            <ActionModal show={showCreateTestAcquisitionDialog}
                         onHide={() => setShowCreateTestAcquisitionDialog(false)}
                         onAction={() => createTestAcquisition(productId)}
                         actionButtonText="Create"
                         title={"Create a test acquisition"}
                         body={createTestOrAccommodationAcquisitionDialog()}/>

            <ActionModal show={showCreatePreviewAcquisitionDialog}
                         onHide={() => setShowCreatePreviewAcquisitionDialog(false)}
                         onAction={() => createPreviewAcquisition(validUntil, productId)}
                         actionButtonText="Create"
                         title={"Create a preview acquisition"}
                         body={createPreviewAcquisitionDialog()}/>

            <ActionModal show={showCreateAccommodationAcquisitionDialog}
                         onHide={() => setShowCreateAccommodationAcquisitionDialog(false)}
                         onAction={() => {
                             if (product == null) {
                                 loadSingleProduct(productId).then(prod => createAccommodationProduct(prod));
                             } else if (product.id !== productId) {
                                 loadSingleProduct(productId).then(prod => createAccommodationProduct(prod));
                             } else {
                                 createAccommodationProduct(product).then(r => r);
                             }
                         }}
                         actionButtonText="Add"
                         title={"Add an accommodation product"}
                         body={createTestOrAccommodationAcquisitionDialog()}/>

            <InfoModal show={showProductPickerDialog}
                       onHide={() => setShowProductPickerDialog(false)}
                       title="Select a product"
                       body={pickProductDialog()}/>

            <InfoModal show={showCouponGroupPickerDialog}
                       onHide={() => setShowCouponGroupPickerDialog(false)}
                       title="Pick a coupon group"
                       body={pickCouponGroupDialog()}/>

            <InfoModal show={showCouponPickerDialog}
                       onHide={() => {
                           setShowCouponPickerDialog(false);
                           setShowCouponGroupPickerDialog(true);
                       }}
                       title="Pick a coupon"
                       body={pickCouponDialog()}/>

            <ErrorHandler error={error}
                          onHide={() => setError(null)} />

        </>
    );


    //--------
    // HELPERS
    //--------

    function createCart(product) {
        let dePrice = product.prices.filter(price => price.countryCode === 'DE')[0];
        return {
            deviceId: 'acp',
            lineItems: [
                {
                    amount: dePrice.amount,
                    currencyCode: dePrice.currencyCode,
                    countryCode: 'DE',
                    productIap: product.iapProductIdentifier
                }
            ]
        };
    };

    function createOrder(cartId, accountId, iap) {
        let uniqueOrderNumber = accountId + "_" + new Date().getTime();
        return {
            deviceId: 'acp_' + accountId,
            cartId: cartId,
            receipt: {
                receiptType: 'ACCOMMODATION',
                originalReceiptData: '{"order_number": "' + uniqueOrderNumber + '",' +
                    '"productId": "' + iap + '"}',
                deviceId: 'acp_' + accountId
            }
        };
    };
}