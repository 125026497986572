import {Button, Col, Form, FormCheck} from "react-bootstrap";
import React, {Component} from "react";
import Row from "react-bootstrap/Row";
import {withToast} from "../../util/ToastService";
import {Link} from "react-router-dom";
import {
    BsClockHistory
} from "react-icons/all";
import GlobalConstants from "../../config/GlobalConstants";
import {CreateCoupon, GetCoupon, UpdateCoupon} from "./CouponGroupService";
import {DetailsSection} from "../../generators/DetailsGenerator";
import CouponMetaData from "./CouponMetaData";
import {PromiseButton} from "../../global/SpinningTiger";
import {ErrorHandler} from "../../util/ErrorHandler";

class LegacyCouponDetails extends Component {

    //------------
    //Constructor
    //------------

    constructor(props) {
        super(props);

        this.state = {
            error: null,
            flowState: props.location.state,
            id: this.props.match.params.id,
            groupId: this.props.match.params.groupId,
            editMode: {
                active: false,
                expertMode: false,
                autoSave: true,
            },
            coupon: {},
            originalCoupon: {},
            sections: {
                general: true,
                usageDetails: true,
            }
        }
    }

    //---------
    //Mounting
    //---------

    async componentDidMount() {
        //Set the title
        if(this.state.id != null) {
            document.title = "Coupon " + this.state.id + " :: Tiger UI";
        } else {
            document.title = "New coupon :: Tiger UI";
        }
        await this.loadCoupon();
    }

    //--------
    //Loading
    //--------

    async loadCoupon() {
        let loadedCoupon = {};

        if(this.state.id != null && this.state.id !== "add") {

            loadedCoupon = await GetCoupon(this.state.groupId, this.state.id);

            if (!loadedCoupon.error) {
                const coupon = loadedCoupon.result;

                this.setState({
                    id: this.state.id,
                    editMode: this.state.editMode,
                    coupon: coupon,
                    originalCoupon: coupon,
                });
            } else {
                this.setState(prevState => ({...prevState, error: loadedCoupon}));
            }
        } else {
            this.setState((prevState) => ({...prevState,
                id: null,
                coupon: {
                    id: null,
                },
                editMode: {
                    active: true,
                    expertMode: false,
                    autoSave: false,
                }}));
        }
    }

    //----------
    //Rendering
    //----------

    render() {

        return (
            <>
                <div className="details-title">
                    {this.state.coupon.id != null ? "Details of Coupon " + this.state.coupon.id : "Create a new Coupon"}
                </div>
                <div className="details-button-box" style={{height: "70px"}}>
                    <Link to={{pathname: "/" + GlobalConstants.APP_PATH +"couponGroups/" + this.state.groupId, state: this.state.flowState}}>
                        <button className="form-btn-ci-light-blue" type="button">Back</button>
                    </Link>
                    {this.state.coupon.id != null &&
                    <button className={this.state.editMode.active ? "form-btn-ci-red" : "form-btn-ci-blue"} type="button" onClick={() => this.setState({
                        editMode: {
                            active: !this.state.editMode.active,
                            expertMode: this.state.editMode.expertMode
                        },
                        coupon: this.state.originalCoupon
                    })}>{this.state.editMode.active ? "Cancel" : "Edit"}</button>
                    }
                    {this.state.editMode.active &&
                    <PromiseButton text="Save" onClick={() => this.saveOrUpdateCoupon()} />
                    }
                    <Button style={{float: "right", marginTop: "5px", marginRight: "10px"}} variant={this.state.editMode.autoSave ? "secondary": "outline-secondary"}
                            onClick={() => this.setState(prevState => ({
                                ...prevState,
                                editMode: {
                                    ...prevState.editMode,
                                    autoSave: !this.state.editMode.autoSave
                                }
                            }))}>Auto Save</Button>
                </div>

                <div className="details-box-title">Resource Details</div>
                <div className="details-box">
                    {/* GENERAL DATA */}
                    <DetailsSection
                        nameInState="coupon"
                        fields={CouponMetaData.DETAILS_GENERAL}
                        state={this.state}
                        onSetState={(s) => this.setState(s)}
                        onUpdateResource={() => this.saveOrUpdateCoupon()}
                        sectionId="general"
                    />

                    {/* Usage Details */}
                    <DetailsSection
                        nameInState="coupon"
                        fields={CouponMetaData.USAGE_DETAILS}
                        state={this.state}
                        onSetState={(s) => this.setState(s)}
                        sectionId="usageDetails"
                        onUpdateResource={() => this.saveOrUpdateCoupon()}
                        label={<span><BsClockHistory/>&#xA0;Usage Details</span>}
                    />

                    {this.state.sections.usageDetails &&
                    <Form>
                        <Row>
                            <Form.Group as={Col} controlId="usableSwitch">
                                <FormCheck id="usableSwitch" type="switch"
                                           label="Usable"
                                           checked={this.state.coupon.usable}
                                           onChange={() => this.setState(prevState => ({
                                               coupon: {
                                                   ...prevState.coupon,
                                                   usable: !this.state.coupon.usable
                                               }
                                           }))}
                                           readOnly={!this.state.editMode.active}/>
                            </Form.Group>
                        </Row>
                    </Form>
                    }

                </div>
                <div className="details-button-box" style={{height: "70px"}}>
                    <Link to={{pathname: "/" + GlobalConstants.APP_PATH +"couponGroups/" + this.state.groupId, state: this.state.flowState}}>
                        <button className="form-btn-ci-light-blue" type="button">Back</button>
                    </Link>
                    {this.state.editMode.active &&
                    <PromiseButton text="Save" onClick={() => this.saveOrUpdateCoupon()} />
                    }
                </div>

                <ErrorHandler error={this.state.error}
                              onHide={() => this.setState(prevState => ({...prevState, error: null}))} />
            </>
        );
    }

    //------------
    //API Methods
    //------------

    async saveOrUpdateCoupon() {
        let coupon = {};
        if(this.state.coupon.id != null) {
            coupon = await UpdateCoupon(this.state.groupId, this.state.coupon);
        } else {
            coupon = await CreateCoupon(this.state.groupId, this.state.coupon);
        }

        if (!coupon.error) {
            this.setState((prevState) =>({...prevState, coupon: coupon.result, editMode: {...prevState.editMode, active: this.state.editMode.autoSave}}));

            this.props.addToast("The coupon has been updated successfully.", {
                autoDismiss: true,
                appearance: 'success'
            });
        } else {
            this.setState(prevState => ({...prevState, error: coupon}));
        }
    }

}


export default withToast(LegacyCouponDetails);