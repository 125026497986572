import {Badge, Button, Col, Form, FormControl, InputGroup, Table} from "react-bootstrap";
import Row from 'react-bootstrap/Row';
import React, {Component} from "react";
import FormSelect from "react-bootstrap/FormSelect";
import {withToast} from "../../util/ToastService";
import {PickTagDialog} from "../tags/PickTagDialog";
import {
    CreateEpisode,
    CreateSeason,
    CreateSeries, CreateSeriesTag,
    DeleteEpisode,
    DeleteSeason, DeleteSeriesTag,
    GetSeason,
    GetSeries,
    UpdateEpisode,
    UpdateSeason,
    UpdateSeries
} from "./SeriesService";
import {Link} from "react-router-dom";
import {
    BiAddToQueue,
    FaBook,
    FiTrash2,
    GoDiffAdded,
    GoPencil,
    MdOutlineCancel,
    RiArrowGoBackLine,
    RiSave3Fill, TiDeleteOutline
} from "react-icons/all";
import GlobalConstants from "../../config/GlobalConstants";
import {DetailsSection, RequiredFieldsAreValid} from "../../generators/DetailsGenerator";
import SeriesMetaData from "./SeriesMetaData";
import {Label} from "reactstrap";
import {ActionModal, InfoModal} from "../../generators/ModalGenerator";
import {GetFilteredProducts} from "../products/ProductService";
import {ProductStateBadges, ProductTypeBadges} from "../products/ProductMetaData";
import {TablePagination} from "../../generators/TableGenerator";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import {PromiseButton} from "../../global/SpinningTiger";
import {ErrorHandler} from "../../util/ErrorHandler";
import {openExternalTargetInNewTab} from "../../util/ResourceService";
import {iso6392} from 'iso-639-2';

class SeriesDetails extends Component {

    constructor(props) {
        super(props);

        this.state = {
            error: null,
            flowState: props.location.state,
            id: this.props.match.params.id,
            editMode: {
                active: false,
                expertMode: false,
                autoSave: false,
            },
            series: {
                seasons: []
            },
            originalSeries: {},
            selectedSeason: {
                episodes: [],
            },
            selectedEpisode: {},
            sections: {
                general: true,
            },
            showEpisodes: false,
            editSeason: false,
            seasonIsSelected: false,
            editEpisode: false,
            showAddEpisode: false,

            showTagsDialog: false,

            products: [],
            productPage: 1,
            lastProductPage: 1,
            productFilterName: "",
            showProductPickerDialog: false,

            showLanguageDialog: false,
            isos: [],
            filteredIsos: [],

            episodesToAdd: [],
            episodeToAdd: {
                seriesIndex: null,
                productId: null,
                episodeNumber: null,
                episodeTitle: null,
            },
            showAddEpisodeDialog: false,
            originalOrder: [],
            validationErrors: []
        }

        this.onDragEnd = this.onDragEnd.bind(this);

    }

    //Mounting
    async componentDidMount() {

        //Set the title
        if(this.state.id != null) {
            document.title = "Series " + this.state.id + " :: Tiger UI";
        } else {
            document.title = "New series :: Tiger UI";
        }

        if (this.state.id != null && this.state.id !== "add") {
            await this.loadSeries(this.state.id);
        } else {
            this.setState((prevState) => ({
                ...prevState,
                id: null,
                series: {
                    id: null,
                    divisionType: "EPISODES",
                    seasons: []
                },
                editMode: {
                    active: true,
                    expertMode: true,
                    autoSave: false
                }
            }));
        }

        this.setState(prevState => ({...prevState, isos: iso6392, filteredIsos: iso6392}));
    }

    async reloadSeries() {
        return this.loadSeries(this.state.series.id)
    }

    async loadSeries(id) {
        let loadedSeries = await GetSeries(id);
        if (!loadedSeries.error) {
            let series = loadedSeries.result;
            let season = {episodes: []};
            let episode = {};

            if (this.state.selectedSeason.id != null && this.state.selectedSeason.id !== "") {
                for (let i = 0; i < series.seasons.length; i++) {
                    if (series.seasons[i].id === this.state.selectedSeason.id) {
                        season = series.seasons[i];
                    }
                }
            } else {
                season = series.seasons.length > 0 ? series.seasons[0] : {episodes: []}
                if (season.id != null && season.id !== "") {
                    let loadedSeason = await GetSeason(series.id, season.id);
                    if(!loadedSeason.error) {
                        season = loadedSeason.result;
                    }
                }
            }
            if (this.state.selectedEpisode.id != null && this.state.selectedEpisode.id !== "") {
                for (let i = 0; i < season.episodes.length; i++) {
                    if (season.episodes[i].id === this.state.selectedEpisode.id) {
                        episode = season.episodes[i];
                    }
                }
            }
            this.setState(prevState => ({
                ...prevState,
                series: series,
                originalSeries: series,
                selectedSeason: season,
                seasonIsSelected: season.id != null && season.id !== "",
                selectedEpisode: episode
            }));
        }
    }

    async loadProducts(page) {
        let loadedProducts = await GetFilteredProducts({page: page, title: this.state.productFilterName, state: "ACTIVE,INACTIVE,NEW"});
        if (!loadedProducts.error) {
            this.setState(prevState => ({
                ...prevState,
                products: loadedProducts.result,
                productPage: page,
                lastProductPage: Math.ceil(loadedProducts.length / 20)
            }));
        } else {
            this.setState(prevState => ({...prevState, error: loadedProducts}));
        }
    }


    //Rendering
    render() {
        const seriesTags = (
                <div>
                    {this.state.series?._embedded?.tags && this.state.editMode.active && this.state.series._embedded.tags.map(tag => (
                            <button key={tag.id} className="form-btn-ci" type="button" onClick={() => {
                                this.removeTag(tag).then(value => {
                                    this.setState(prevState => ({
                                                showTagsDialog: false,
                                            }), () => {
                                                this.props.addToast("The tag has been removed successfully.", {
                                                    autoDismiss: true,
                                                    appearance: 'success'
                                                })
                                            }
                                    );
                                });
                            }}>{tag.name}&#xA0;<TiDeleteOutline/></button>
                    ))}
                    {this.state.series?._embedded?.tags && !this.state.editMode.active && this.state.series._embedded.tags.map(
                            tag => (
                                    <button key={tag.id} className="form-btn-ci" disabled={true}>{tag.name}</button>
                            ))}
                </div>
        );

        const labelStyle = {
            fontSize: "20px",
            backgroundColor: "#C8C8C8",
            borderStyle: "solid",
            borderColor: "#cdd7e0",
            borderWidth: "1px",
            borderRadius: "5px",
            paddingLeft: "10px",
            paddingRight: "10px",
            width: "100%"
        };

        return (
                <>
                    <div className="details-title">
                        {this.state.series.id != null ? "Details of Series " + this.state.series.id : "Create a new series"}
                </div>
                <div className="details-button-box" style={{height: "70px"}}>
                    <Link to={{pathname: "/" + GlobalConstants.APP_PATH + "series", state: null}}>
                        <button className="form-btn-ci-light-blue" type="button">Back</button>
                    </Link>
                    {this.state.series.id != null &&
                    <button className={this.state.editMode.active ? "form-btn-ci-red" : "form-btn-ci-blue"}
                            type="button" onClick={() => this.setState(prevState => ({
                        ...prevState,
                        editMode: {
                            ...prevState.editMode,
                            active: !this.state.editMode.active
                        },
                        series: this.state.originalSeries
                    }))}>{this.state.editMode.active ? "Cancel" : "Edit"}</button>
                    }
                    {this.state.editMode.active &&
                    <PromiseButton text="Save" onClick={() => this.saveOrUpdateSeries()} />
                    }
                    <Button style={{float: "right", marginTop: "5px", marginRight: "10px"}} variant={this.state.editMode.autoSave ? "secondary": "outline-secondary"}
                            onClick={() => this.setState(prevState => ({
                                ...prevState,
                                editMode: {
                                    ...prevState.editMode,
                                    autoSave: !this.state.editMode.autoSave
                                }
                            }))}>Auto Save</Button>
                </div>

                <div className="details-box-title">Resource Details</div>
                <div className="details-box">
                    <DetailsSection
                        nameInState="series"
                        fields={[]}
                        state={this.state}
                        onSetState={(s) => this.setState(s)}
                        onUpdateResource={() => this.saveOrUpdateSeries()}
                        sectionId="general"
                    />

                    <Form>
                        <Row className="details-row">
                            <Form.Group as={Col}>
                                <Label style={labelStyle}>ID</Label>
                                <FormControl type="text" value={this.state.series.id} readOnly={true}/>
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Label style={labelStyle}>Division Type*</Label>
                                <FormSelect value={this.state.series.divisionType}
                                             readOnly={!this.state.editMode.active}
                                            disabled={!this.state.editMode.active}
                                             onChange={(e) => {
                                                 if(e.target.value === 'EPISODES') {
                                                     if(this.state.series.seasons.length > 1) {
                                                         this.props.addToast("Division Type 'EPISODES' is not valid if the series has > 1 seasons.", {
                                                             appearance: "error",
                                                             autoDismiss: true
                                                         });
                                                     } else if(this.state.series.seasons.length === 1) {
                                                         this.setState(prevState => ({
                                                             ...prevState,
                                                             selectedSeason: this.state.series.seasons[0],
                                                             seasonIsSelected: true,
                                                             series: {
                                                                 ...prevState.series,
                                                                 divisionType: e.target.value
                                                             }
                                                         }));
                                                     } else {
                                                         this.setState(prevState => ({
                                                             ...prevState,
                                                             selectedSeason: {episodes: []},
                                                             seasonIsSelected: false,
                                                             series: {
                                                                 ...prevState.series,
                                                                 divisionType: e.target.value
                                                             }
                                                         }));
                                                     }
                                                 } else {
                                                     this.setState(prevState => ({
                                                         ...prevState,
                                                         series: {
                                                             ...prevState.series,
                                                             divisionType: e.target.value
                                                         }
                                                     }));
                                                 }

                                             }}>
                                    {SeriesMetaData.DIVISION_TYPES.map(type => (
                                        <option key={type}>{type}</option>
                                    ))}
                                </FormSelect>
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Label style={labelStyle}>Recommended Age</Label>
                                <FormControl type="number" value={this.state.series.ageRecommended}
                                             readOnly={!this.state.editMode.active}
                                             onChange={(e) => this.setState(prevState => ({
                                                 ...prevState,
                                                 series: {
                                                     ...prevState.series,
                                                     ageRecommended: e.target.value
                                                 }
                                             }))}/>
                            </Form.Group>
                            <Form.Group as={Col} controlId="seriesLanguage">
                                <Label style={labelStyle}>Language</Label>
                                <InputGroup>
                                    <Form.Control type="text" value={this.state.series.language ? this.state.series.language : ""}
                                                  onChange={(e) => this.setState(prevState => ({
                                                      series: {
                                                          ...prevState.series,
                                                          language: e.target.value
                                                      }
                                                  }))}
                                                  readOnly={true}/>
                                    {this.state.editMode.active &&
                                                <Button variant="outline-secondary"
                                                        onClick={() => this.setState(prevState => ({
                                                            ...prevState,
                                                            showLanguageDialog: true
                                                        }))}>Pick</Button>
                                    }
                                </InputGroup>
                            </Form.Group>
                        </Row>
                        <Row className="details-row">
                            <Form.Group as={Col}>
                                <Label style={labelStyle}>Name*</Label>
                                <FormControl type="text" value={this.state.series.name}
                                             isInvalid={this.state.validationErrors.indexOf("name") > -1}
                                             readOnly={!this.state.editMode.active}
                                             onChange={(e) => this.setState(prevState => ({
                                                 ...prevState,
                                                 series: {
                                                     ...prevState.series,
                                                     name: e.target.value
                                                 },
                                                 validationErrors: this.state.validationErrors.filter(e => e !== 'name')
                                             }))} />
                                <Form.Control.Feedback type="invalid">
                                    Field 'Name' is required
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Label style={labelStyle}>Visibility</Label>
                                <FormSelect value={this.state.series.visibility}
                                             readOnly={!this.state.editMode.active}
                                            disabled={!this.state.editMode.active}
                                             onChange={(e) => {
                                                 this.setState(prevState => ({
                                                     ...prevState,
                                                     series: {
                                                         ...prevState.series,
                                                         visibility: e.target.value
                                                     }
                                                 }));
                                             }}>
                                    {SeriesMetaData.VISIBILITY_TYPES.map(type => (
                                            <option key={type}>{type}</option>
                                    ))}
                                </FormSelect>
                            </Form.Group>

                        </Row>
                        <Row className="details-row">
                            <Form.Group as={Col}>
                                <Label style={labelStyle}>Description</Label>
                                <FormControl as="textarea" rows={5}
                                             value={this.state.series.description}
                                             readOnly={!this.state.editMode.active}
                                             onChange={(e) => this.setState(prevState => ({
                                                 ...prevState,
                                                 series: {
                                                     ...prevState.series,
                                                     description: e.target.value
                                                 }
                                             }))}/>
                            </Form.Group>
                        </Row>
                        <Row className="details-row">
                            <Form.Group as={Col}>
                                <Label style={labelStyle}>Content Cluster ID</Label>
                                <FormControl type="number" value={this.state.series.contentClusterId}
                                             readOnly={!this.state.editMode.active}
                                             onChange={(e) => this.setState(prevState => ({
                                                 ...prevState,
                                                 series: {
                                                     ...prevState.series,
                                                     contentClusterId: e.target.value
                                                 }
                                             }))}/>
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Label style={labelStyle}>Display Image Url</Label>
                                <FormControl type="text" value={this.state.series.displayImageUrl}
                                             readOnly={!this.state.editMode.active}
                                             onChange={(e) => this.setState(prevState => ({
                                                 ...prevState,
                                                 series: {
                                                     ...prevState.series,
                                                     displayImageUrl: e.target.value
                                                 }
                                             }))} />
                            </Form.Group>
                            <Form.Group as={Col} className={"display-image-column"}>
                                <Label style={labelStyle}>Preview</Label>
                                {this.state.series.displayImageUrl ?
                                    <div className={"display-image-container"}>
                                        <img src={this.state.series.displayImageUrl}
                                             alt={"Preview of display image of series " + this.state.series.id} height={240}
                                             style={{cursor: "pointer"}}
                                             onClick={() => openExternalTargetInNewTab(this.state.series.displayImageUrl)}/>
                                    </div>
                                        :
                                        <p style={{fontSize: "20px", textAlign: "center"}}>No preview available</p>
                                }

                            </Form.Group>
                        </Row>
                        <Row className="details-row">
                            <Form.Group as={Col}>
                                <Label style={labelStyle}>Series Type</Label>
                                <FormSelect value={this.state.series.seriesType}
                                             disabled={!this.state.editMode.active}
                                             onChange={(e) => {
                                                 this.setState(prevState => ({
                                                     ...prevState,
                                                     series: {
                                                         ...prevState.series,
                                                         seriesType: e.target.value
                                                     }
                                                 }));
                                             }}>
                                    {SeriesMetaData.SERIES_TYPES.map(type => (
                                            <option key={type}>{type}</option>
                                    ))}
                                </FormSelect>
                            </Form.Group>
                            <Form.Group as={Col} controlId="seriesTags">
                                <Label style={labelStyle}>Tags</Label>
                                {this.state.editMode.active &&
                                        <Button variant="outline-secondary"
                                                style={{height: "42px", marginRight: "5px", float: "left"}} onClick={() => {
                                                this.setState(prevState => ({
                                                    ...prevState,
                                                    showTagsDialog: true,
                                                }));
                                        }}><BiAddToQueue size={18}/> Add Tags</Button>
                                }
                                {seriesTags}
                            </Form.Group>
                        </Row>
                    </Form>

                    {this.state.series.divisionType === 'SEASONS' &&
                    <Form>

                        <hr/>
                        <h4><FaBook/>&#xA0;Seasons</h4>
                        <hr/>

                        <button className={this.state.series.id == null ? "form-btn-ci-off" : "form-btn-ci-blue"}
                                disabled={this.state.series.id == null} type="button"
                                onClick={() => {
                                    if(this.state.series !== this.state.originalSeries) {
                                        this.props.addToast("Please save the changes first before adding a new season. ", {
                                            autoDismiss: true,
                                            appearance: "info"
                                        });
                                    } else {
                                        this.setState(prevState => ({
                                            ...prevState,
                                            seasonIsSelected: !this.state.seasonIsSelected,
                                            editSeason: true,
                                            selectedSeason: {id: "", episodes: [], seriesId: this.state.series.id, name: "", description: "", section: ""}
                                        }));
                                    }
                                }}>{this.state.seasonIsSelected ? "Hide season details" : "Add new Season"}
                        </button>
                        <div style={{float: "right"}}>
                            <Button variant={this.state.showEpisodes ? "secondary" : "outline-secondary"}
                                    onClick={() => this.setState(prevState => ({
                                        ...prevState,
                                        showEpisodes: !this.state.showEpisodes
                                    }))}>{this.state.showEpisodes ? "Hide episodes in table" : "Show episodes in table"}</Button>
                        </div>

                        {/* SELECTED SEASON */}
                        {this.state.seasonIsSelected &&
                            <>
                                <br />
                                <br />
                                <div style={{marginTop: "20px"}}>
                                    {this.state.series.divisionType === 'SEASONS' &&
                                    <>
                                    <hr/>
                                    <h4>{(this.state.selectedSeason.id != null && this.state.selectedSeason.id !== "") ? "Season " + this.state.selectedSeason.id : "Create new season"}
                                        <span style={{float: "right", marginBottom: "10px"}}>
                                            {this.state.editSeason &&
                                            <button className="form-btn-ci-red" onClick={() => this.setState(prevState => (
                                                {
                                                    ...prevState,
                                                    selectedSeason: (this.state.selectedSeason.id != null && this.state.selectedSeason.id !== "") ? this.state.series.seasons.filter(s => s.id === this.state.selectedSeason.id)[0] : {episodes: []},
                                                    editSeason: false
                                                }))}>Cancel</button>
                                            }
                                                        <button type="button" className="form-btn-ci-blue" onClick={() => {
                                                            if (!this.state.editSeason) {
                                                                this.setState(prevState => ({...prevState, editSeason: true}));
                                                            } else {
                                                                this.saveOrUpdateSeason(this.state.id, this.state.selectedSeason).then(r => r);
                                                            }
                                                        }
                                                        }>{this.state.editSeason ? "Save" : "Edit"}</button>
                                            <button type="button" className="form-btn-ci-light-blue"
                                                    onClick={() => this.setState(prevState => ({
                                                        ...prevState,
                                                        selectedSeason: {
                                                            episodes: [],
                                                        },
                                                        seasonIsSelected: false,
                                                        selectedEpisode: {},
                                                        showAddEpisode: false
                                                    }))}>Close</button>
                                        </span>
                                    </h4>
                                    <Row className="details-row form-row">
                                        <Form.Group as={Col}>
                                            <Label style={labelStyle}>ID</Label>
                                            <FormControl type="text" value={this.state.selectedSeason.id} readOnly={true}/>
                                        </Form.Group>
                                        <Form.Group as={Col}>
                                            <Label style={labelStyle}>Name</Label>
                                            <FormControl type="text" value={this.state.selectedSeason.name}
                                                         readOnly={!this.state.editSeason}
                                                         onChange={(e) => this.setState(prevState => ({
                                                             ...prevState,
                                                             selectedSeason: {
                                                                 ...prevState.selectedSeason,
                                                                 name: e.target.value
                                                             }
                                                         }))}/>
                                        </Form.Group>
                                        <Form.Group as={Col}>
                                            <Label style={labelStyle}>Sort Order*</Label>
                                            <FormControl type="text" value={this.state.selectedSeason.section}
                                                         readOnly={!this.state.editSeason}
                                                         onChange={(e) => this.setState(prevState => ({
                                                             ...prevState,
                                                             selectedSeason: {
                                                                 ...prevState.selectedSeason,
                                                                 section: e.target.value
                                                             }
                                                         }))}/>
                                        </Form.Group>
                                    </Row>
                                    <Row  className="details-row form-row">
                                        <Form.Group as={Col}>
                                            <Label style={labelStyle}>Description</Label>
                                            <FormControl as="textarea" rows={5}
                                                         value={this.state.selectedSeason.description}
                                                         readOnly={!this.state.editSeason}
                                                         onChange={(e) => this.setState(prevState => ({
                                                             ...prevState,
                                                             selectedSeason: {
                                                                 ...prevState.selectedSeason,
                                                                 description: e.target.value
                                                             }
                                                         }))}/>
                                        </Form.Group>
                                    </Row>
                                </>
                                    }
                                </div>
                            </>
                        }

                            <Table style={{marginTop: "10px"}} responsive striped bordered hover>
                                <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Name</th>
                                    <th>Description</th>
                                    <th>Sort Order in Series</th>
                                    {this.state.showEpisodes &&
                                    <th>Episodes</th>
                                    }
                                    <th>Delete</th>
                                </tr>
                                </thead>
                                <tbody>
                                {this.state.series.seasons
                                    .sort((a,b) => (a.section > b.section) ? 1 : ((b.section > a.section) ? -1 : 0))
                                    .map(season => (
                                        <tr role="row" key={season.id} style={{cursor: "pointer"}}
                                            onClick={() => this.setState(prevState => ({
                                                ...prevState,
                                                selectedSeason: season,
                                                seasonIsSelected: true,
                                                showAddEpisode: false,
                                                editSeason: false
                                            }))}>
                                            <td><Link
                                                to={"/" + GlobalConstants.APP_PATH + "series/" + this.state.series.id + "/seasons/" + season.id}
                                                style={{fontStyle: "italic", color: "#333", fontWeight: this.state.selectedSeason.id === season.id ? "bold" : "normal"}}>{season.id}</Link></td>
                                            <td style={{fontWeight: this.state.selectedSeason.id === season.id ? "bold" : "normal"}}>{season.name}</td>
                                            <td style={{fontWeight: this.state.selectedSeason.id === season.id ? "bold" : "normal"}}>{season.description}</td>
                                            <td style={{fontWeight: this.state.selectedSeason.id === season.id ? "bold" : "normal"}}>{season.section}</td>
                                            {this.state.showEpisodes &&
                                            <td>
                                                {season.episodes.length &&
                                                <Table responsive striped bordered hover>
                                                    <thead>
                                                    <tr>
                                                        <th>Id</th>
                                                        <th>Episode Number</th>
                                                        <th>Sort Order in Season</th>
                                                        <th>Title</th>
                                                        <th>Product ID</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {season.episodes
                                                        .sort((a,b) => (a.seriesIndex > b.seriesIndex) ? 1 : ((b.seriesIndex > a.seriesIndex) ? -1 : 0))
                                                        .map(episode => (
                                                            <tr role="row" key={episode.id}>
                                                                <td><Link
                                                                    to={"/" + GlobalConstants.APP_PATH + "series/" + this.state.series.id + "/seasons/" + episode.seasonId + "/episodes/" + episode.id}
                                                                    style={{fontStyle: "italic", color: "#333"}}>{episode.id}</Link>
                                                                </td>
                                                                <td>{episode.episodeNumber}</td>
                                                                <td>{episode.seriesIndex}</td>
                                                                <td>{episode.episodeTitle}</td>
                                                                <td><Link
                                                                    to={"/" + GlobalConstants.APP_PATH + "products/" + episode.productId}
                                                                    style={{
                                                                        fontStyle: "italic",
                                                                        color: "#333"
                                                                    }}>{episode.productId}</Link></td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </Table>
                                                }
                                            </td>
                                            }
                                            <td>
                                                <button className="form-btn-ci-red" type="button"
                                                        onClick={() => this.deleteSeason(this.state.series.id, season)}><FiTrash2/></button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>

                        </Form>
                    }

                    {this.state.seasonIsSelected &&
                        <Form>
                        <Row>
                            <Form.Group as={Col}>
                                <Label style={labelStyle}>Episodes</Label>
                                <div style={{marginBottom: "5px"}}>
                                    <button
                                        className={(this.state.selectedSeason.id == null || this.state.selectedSeason.id === "") ? "form-btn-ci-off" : "form-btn-ci-blue"}
                                        disabled={(this.state.selectedSeason.id == null || this.state.selectedSeason.id === "")}
                                        type="button"
                                        onClick={() => this.setState(prevState => ({
                                            ...prevState,
                                            showAddEpisode: !this.state.showAddEpisode,
                                            editEpisode: true,
                                            selectedEpisode: {
                                                id: "",
                                                seriesId: this.state.series.id,
                                                seasonId: this.state.selectedSeason.id,
                                                episodeNumber: "",
                                                episodeTitle: "",
                                                seriesIndex: "",
                                                productId: ""
                                            }
                                        }))}>
                                        <GoDiffAdded/> {!this.state.showAddEpisode ? "Add single Episode" : "Hide dialog"}
                                    </button>
                                    <button
                                        className={(this.state.selectedSeason.id == null || this.state.selectedSeason.id === "") ? "form-btn-ci-off" : "form-btn-ci-blue"}
                                        disabled={(this.state.selectedSeason.id == null || this.state.selectedSeason.id === "")}
                                        type="button"
                                        onClick={() => this.setState(prevState => ({
                                            ...prevState,
                                            showAddEpisodeDialog: true
                                        }))}><BiAddToQueue/> Add multiple episodes
                                    </button>
                                    <span style={{float: "right"}}>
                                        <button
                                            className={this.state.originalOrder.length > 0 ? "form-btn-ci-light-blue" : "form-btn-ci-off"}
                                            disabled={this.state.originalOrder.length === 0}
                                            type="button"
                                            onClick={() => this.updateEpisodesOrder()}><RiSave3Fill/> Save Order</button>
                                        <button
                                            className={this.state.originalOrder.length > 0 ? "form-btn-ci-red" : "form-btn-ci-off"}
                                            disabled={this.state.originalOrder.length === 0}
                                            type="button"
                                            onClick={() => this.setState(prevState => ({...prevState,
                                                selectedSeason: {
                                                    ...prevState.selectedSeason,
                                                    episodes: this.state.originalOrder
                                                },
                                                originalOrder: []
                                            }))}><RiArrowGoBackLine/> Rollback</button>
                                    </span>
                                </div>

                                {/* SELECTED EPISODE */}
                                {this.state.showAddEpisode &&
                                <>
                                    <br/>
                                    <br/>
                                    <hr/>
                                    <h4>{(this.state.selectedEpisode.id != null && this.state.selectedEpisode.id !== "") ? "Episode " + this.state.selectedEpisode.id : "Create new episode"}
                                        <span style={{float: "right"}}>
                            {this.state.editEpisode &&
                            <button className="form-btn-ci-red" onClick={() => this.setState(prevState => (
                                {
                                    ...prevState,
                                    selectedEpisode: (this.state.selectedEpisode.id != null && this.state.selectedEpisode.id !== "") ? this.state.selectedSeason.episodes.filter(e => e.id === this.state.selectedEpisode.id)[0] : {},
                                    editEpisode: false
                                }))}>Cancel</button>
                            }
                                            <button type="button" className="form-btn-ci-blue" onClick={() => {
                                                if (!this.state.editEpisode) {
                                                    this.setState(prevState => ({...prevState, editEpisode: true}));
                                                } else {
                                                    this.saveOrUpdateEpisode(this.state.id, this.state.selectedSeason.id, this.state.selectedEpisode).then(r => r);
                                                }
                                            }
                                            }>{this.state.editEpisode ? "Save" : "Edit"}</button>
                            <button type="button" className="form-btn-ci-light-blue"
                                    onClick={() => this.setState(prevState => ({
                                        ...prevState,
                                        selectedEpisode: {},
                                        showAddEpisode: false
                                    }))}>Close</button>
                        </span>
                                    </h4>
                                    <div style={{marginTop: "20px"}}>
                                        <Row className="details-row">
                                            <Form.Group as={Col}>
                                                <Label style={labelStyle}>ID</Label>
                                                <FormControl type="text" value={this.state.selectedEpisode.id}
                                                             readOnly={true}/>
                                            </Form.Group>
                                            <Form.Group as={Col}>
                                                <Label style={labelStyle}>Product ID*</Label>
                                                <InputGroup>
                                                    <FormControl type="text"
                                                                 value={this.state.selectedEpisode.productId}
                                                                 readOnly={!this.state.editEpisode}
                                                                 onChange={(e) => this.setState(prevState => ({
                                                                     ...prevState,
                                                                     selectedEpisode: {
                                                                         ...prevState.selectedEpisode,
                                                                         productId: e.target.value
                                                                     }
                                                                 }))}/>
                                                    {this.state.editEpisode &&
                                                            <Button variant="outline-secondary"
                                                                    onClick={() => {
                                                                        this.setState(prevState => ({
                                                                            ...prevState,
                                                                            showProductPickerDialog: true
                                                                        }));
                                                                        this.loadProducts(1).then(r => r);
                                                                    }}>Pick</Button>
                                                    }
                                                </InputGroup>
                                            </Form.Group>
                                            <Form.Group as={Col}>
                                                <Label style={labelStyle}>Sort Order in Season</Label>
                                                <FormControl type="text" value={this.state.selectedEpisode.seriesIndex}
                                                             readOnly={!this.state.editEpisode}
                                                             onChange={(e) => this.setState(prevState => ({
                                                                 ...prevState,
                                                                 selectedEpisode: {
                                                                     ...prevState.selectedEpisode,
                                                                     seriesIndex: e.target.value
                                                                 }
                                                             }))}/>
                                            </Form.Group>
                                        </Row>
                                        <Row>
                                            <Form.Group as={Col}>
                                                <Label style={labelStyle}>Displayed Episode Number*</Label>
                                                <FormControl type="text"
                                                             value={this.state.selectedEpisode.episodeNumber}
                                                             readOnly={!this.state.editEpisode}
                                                             onChange={(e) => this.setState(prevState => ({
                                                                 ...prevState,
                                                                 selectedEpisode: {
                                                                     ...prevState.selectedEpisode,
                                                                     episodeNumber: e.target.value
                                                                 }
                                                             }))}/>
                                            </Form.Group>
                                            <Form.Group as={Col}>
                                                <Label style={labelStyle}>Episode Title*</Label>
                                                <FormControl type="text" value={this.state.selectedEpisode.episodeTitle}
                                                             readOnly={!this.state.editEpisode}
                                                             onChange={(e) => this.setState(prevState => ({
                                                                 ...prevState,
                                                                 selectedEpisode: {
                                                                     ...prevState.selectedEpisode,
                                                                     episodeTitle: e.target.value
                                                                 }
                                                             }))}/>
                                            </Form.Group>
                                        </Row>
                                    </div>
                                </>
                                }

                                <DragDropContext onDragEnd={this.onDragEnd}>
                                    <Droppable droppableId="droppable">
                                        {(provided, snapshot) => (
                                            <Table ref={provided.innerRef} responsive bordered hover striped
                                                   style={{marginTop: "10px"}}>
                                                <thead>
                                                <tr>
                                                    <th>ID</th>
                                                    <th>Displayed episode number</th>
                                                    <th>Episode title</th>
                                                    <th>Sort Order in Season</th>
                                                    <th>Product ID</th>
                                                    <th>Edit</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {this.state.selectedSeason.episodes
                                                    .map((episode, index) => (
                                                        this.episodeRow(episode, index)
                                                    ))}
                                                {provided.placeholder}
                                                </tbody>
                                            </Table>
                                        )}
                                    </Droppable>
                                </DragDropContext>
                            </Form.Group>
                        </Row>
                    </Form>
                    }

                </div>
                <div className="details-button-box" style={{height: "70px"}}>
                    <Link to={{pathname: "/" + GlobalConstants.APP_PATH + "series", state: null}}>
                        <button className="form-btn-ci-light-blue" type="button">Back</button>
                    </Link>
                </div>

                {/* DIALOGS */}
                <ActionModal show={this.state.showAddEpisodeDialog}
                             onHide={() => this.setState(prevState => ({...prevState, showAddEpisodeDialog: false}))}
                             onAction={() => this.addEpisodesToSeason()}
                             actionButtonText="Save"
                             title={"Add episodes to " + this.state.selectedSeason.name}
                             body={this.addEpisodesDialog()}/>

                <InfoModal show={this.state.showProductPickerDialog}
                           onHide={() => this.setState(prevState => ({...prevState, showProductPickerDialog: false}))}
                           title={"Pick a product"}
                           body={this.productPickerDialog()}/>
                <InfoModal show={this.state.showLanguageDialog}
                           onHide={() => this.setState(prevState => ({...prevState, showLanguageDialog: false}))}
                           title={"Pick a Language"}
                           body={this.pickLanguageDialog()}/>
                <InfoModal show={this.state.showTagsDialog}
                           onHide={() => this.setState(prevState => ({
                               ...prevState,
                               showTagsDialog: false,
                           }))}
                           title="Add tags to series"
                           body={<PickTagDialog onClickTag={(tag) => this.onClickTag(tag)}/>}/>

                <ErrorHandler error={this.state.error}
                              onHide={() => this.setState(prevState => ({...prevState, error: null}))} />
            </>
        );
    }

    //------------
    //API Methods
    //------------
    async updateEpisodesOrder() {
        let error = false;
        //Update only episodes that have been changed
        for(let i = 0; i < this.state.selectedSeason.episodes.length; i++) {
            let updatedEpisode = this.state.selectedSeason.episodes[i];
            let originalEpisode = this.state.originalOrder[i];
            if(updatedEpisode.seriesIndex !== originalEpisode.seriesIndex) {
                let response = await UpdateEpisode(updatedEpisode);
                if(response.error) {
                    this.setState(prevState => ({...prevState, error: response}));
                    error = true;
                }
            }
        }
        if(!error) {
            this.props.addToast("Updating the order has been completed successfully.", {
                autoDismiss: true,
                appearance: "success"
            });
            this.setState(prevState => ({...prevState, originalOrder: []}));
        } else {
            this.props.addToast("The order update has been completed with errors.", {
                autoDismiss: true,
                appearance: "warning"
            });
        }

    }

    async saveOrUpdateSeries() {
        if (!RequiredFieldsAreValid("series", SeriesMetaData.DETAILS_GENERAL, this.state, (s) => this.setState(s))) {
            return;
        }
        let series = {};
        if (this.state.series.id != null) {
            series = await UpdateSeries(this.state.series);
        } else {
            series = await CreateSeries(this.state.series);

            if (!series.error) {
                const seriesRes = series.result;
                GetSeries(seriesRes.id).then(r => this.setState(prevState => ({...prevState, selectedSeason: r.result.seasons[0], seasonIsSelected: true})));
            }
        }

        if (!series.error) {
            if (this.state.series.id == null) {
                this.props.history.push("/"+ GlobalConstants.APP_PATH + "series/" + series.result.id);
            }
            this.setState((prevState) => ({
                ...prevState,
                series: series.result,
                originalSeries: series.result,
                editMode: {...prevState.editMode, active: this.state.editMode.autoSave}
            }));

            this.props.addToast("The series has been created/updated successfully.", {
                autoDismiss: true,
                appearance: 'success'
            });
        } else {
            this.setState(prevState => ({...prevState, error: series}));
        }
    }

    async saveOrUpdateSeason(seriesId, season) {
        if(this.state.originalSeries !== this.state.series) {
            this.props.addToast("The series has been updated. Please save the changes first.", {
                autoDismiss: true,
                appearance: "warning"
            });
            return;
        }

        let response = {};
        if (season.id == null || season.id === "") {
            response = await CreateSeason(seriesId, season);
        } else {
            response = await UpdateSeason(seriesId, season);
        }
        if (!response.error) {
            this.setState(prevState => ({...prevState, editSeason: false, selectedSeason: {...season, episodes: season.episodes}}));
            this.props.addToast("The season has been created/updated successfully.", {
                autoDismiss: true,
                appearance: "success"
            });
            await this.reloadSeries();
        } else {
            this.setState(prevState => ({...prevState, error: response}));
        }
    }

    async saveOrUpdateEpisode(seriesId, seasonId, episode) {
        let response = {};
        if (episode.id == null || episode.id === "") {
            response = await CreateEpisode(seriesId, seasonId,episode);
        } else {
            response = await UpdateEpisode(episode);
        }
        if (!response.error) {
            this.setState(prevState => ({...prevState, editEpisode: false, selectedEpisode: {id: null}}));
            this.props.addToast("The episode has been created/updated successfully.", {
                autoDismiss: true,
                appearance: "success"
            });
            await this.reloadSeries();
        } else {
            this.setState(prevState => ({...prevState, error: response}));
        }
    }

    async deleteSeason(seriesId, season) {
        let response = await DeleteSeason(seriesId, season);
        if (response.error) {
            this.setState(prevState => ({...prevState, error: response}));
        } else {
            this.setState(prevState => ({
                ...prevState,
                selectedSeason: {},
                seasonIsSelected: false,
                selectedEpisode: {},
                showAddEpisode: false
            }));
            await this.reloadSeries();
        }
    }

    async addEpisodesToSeason() {
        let errors = [];
        for (let i = 0; i < this.state.episodesToAdd.length; i++) {
            let response = await CreateEpisode(this.state.series.id, this.state.selectedSeason.id, this.state.episodesToAdd[i]);
            if (response.error) {
                errors.push(this.state.episodesToAdd[i].episodeNumber + ", reason: " + response.message + ";\n");
            }
        }

        if (errors.length > 0) {
            this.props.addToast("An error occurred during creating the episode(s) with number(s) " + errors);
        } else {
            this.setState(prevState => ({...prevState, episodesToAdd: [], showAddEpisodeDialog: false}));
            await this.reloadSeries();
        }
    }

    async deleteEpisode(episode) {
        let response = await DeleteEpisode(this.state.series.id, this.state.selectedSeason.id, episode);
        if (response.error) {
            this.setState(prevState => ({...prevState, error: response}));
        } else {
            this.setState(prevState => ({...prevState, selectedEpisode: {}, showAddEpisode: false}));
            await this.reloadSeries();
        }
    }

    reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    onDragEnd(result) {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        let items = this.reorder(
            this.state.selectedSeason.episodes,
            result.source.index,
            result.destination.index
        );

        //Get the min section
        const minSection = Math.min.apply(Math, this.state.selectedSeason.episodes.map(ep => Number(ep.seriesIndex)));

        //Update the sections starting from the min section
        for(let i = 0; i < items.length; i++) {
            items[i].seriesIndex = minSection + i;
        }

        this.setState(prevState => ({
            ...prevState,
            originalOrder: this.state.selectedSeason.episodes,
            selectedSeason: {
                ...prevState.selectedSeason,
                episodes: items
            }
        }));
    }

    //--------
    // HELPERS
    //--------

    episodeRow(episode, index) {
        return (
            <Draggable key={episode.id} draggableId={""+episode.id} index={index}>
                {(provided, snapshot) => (
                    <tr ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                    >
                        <td><Link
                            to={"/" + GlobalConstants.APP_PATH + "series/" + this.state.series.id + "/seasons/" + this.state.selectedSeason.id + "/episodes/" + episode.id}
                            style={{
                                fontStyle: "italic",
                                color: "#333"
                            }}>{episode.id}</Link>
                        </td>
                        {this.state.selectedEpisode.id === episode.id ?
                            <>
                                <td>
                                    <Form.Control type="text" value={this.state.selectedEpisode.episodeNumber}
                                                  onChange={(e) => this.setState(prevState => ({
                                                      ...prevState,
                                                      selectedEpisode: {
                                                          ...prevState.selectedEpisode,
                                                          episodeNumber: e.target.value
                                                      }
                                                  }))}/>
                                </td>
                                <td>
                                    <Form.Control type="text" value={this.state.selectedEpisode.episodeTitle}
                                                  onChange={(e) => this.setState(prevState => ({
                                                      ...prevState,
                                                      selectedEpisode: {
                                                          ...prevState.selectedEpisode,
                                                          episodeTitle: e.target.value
                                                      }
                                                  }))}/>
                                </td>
                                <td>
                                    <Form.Control type="text" value={this.state.selectedEpisode.seriesIndex}
                                                  onChange={(e) => this.setState(prevState => ({
                                                      ...prevState,
                                                      selectedEpisode: {...prevState.selectedEpisode, seriesIndex: e.target.value}
                                                  }))}/>
                                </td>
                                <td>
                                    <Form.Control type="text" value={this.state.selectedEpisode.productId}
                                                  onChange={(e) => this.setState(prevState => ({
                                                      ...prevState,
                                                      selectedEpisode: {...prevState.selectedEpisode, productId: e.target.value}
                                                  }))}/>
                                </td>
                            </>
                            :
                            <>
                                <td>{episode.episodeNumber}</td>
                                <td>{episode.episodeTitle}</td>
                                <td>{episode.seriesIndex}</td>
                                <td><Link
                                    to={"/" + GlobalConstants.APP_PATH + "products/" + episode.productId}
                                    style={{
                                        fontStyle: "italic",
                                        color: "#333"
                                    }}>{episode.productId}</Link>
                                </td>
                            </>
                        }
                        <td>
                            {this.state.selectedEpisode.id === episode.id ?
                                <>
                                    <button className="form-btn-ci-green" type="button"
                                            onClick={() => this.saveOrUpdateEpisode(this.state.id, this.state.selectedSeason.id, this.state.selectedEpisode)}>
                                        <RiSave3Fill/>
                                    </button>
                                    <button className="form-btn-ci-red" type="button"
                                            onClick={() => this.setState(prevState => ({
                                                ...prevState,
                                                selectedEpisode: {id: null}
                                            }))}>
                                        <MdOutlineCancel/>
                                    </button>
                                </>
                                :
                                <>
                                    <button className="form-btn-ci-blue" type="button"
                                            onClick={() => this.setState(prevState => ({
                                                ...prevState,
                                                selectedEpisode: episode
                                            }))}>
                                        <GoPencil/>
                                    </button>
                                    <button className="form-btn-ci-red" type="button"
                                            onClick={() => this.deleteEpisode(episode)}>
                                        <FiTrash2/>
                                    </button>
                                </>
                            }

                        </td>
                    </tr>
                )}
            </Draggable>
        )
    }

    addEpisodeToList() {
        let episodesToAdd = this.state.episodesToAdd;
        let episode = this.state.episodeToAdd;
        episode.seriesId = this.state.series.id;
        episode.seasonId = this.state.selectedSeason.id;
        episode.episodeTitle = this.state.episodeToAdd.episodeTitle;
        episode.episodeNumber = this.state.episodeToAdd.episodeNumber;

        episodesToAdd.push(episode);
        this.setState(prevState => ({...prevState, episodesToAdd: episodesToAdd,
            episodeToAdd: {
            ...episode,
            episodeTitle: "",
            episodeNumber: isNaN(this.state.episodeToAdd.episodeNumber) ? "" : Number(this.state.episodeToAdd.episodeNumber) + 1,
            seriesIndex: Number(this.state.episodeToAdd.seriesIndex) + 1
        }}));
    }

    removeEpisodeFromList(episode) {
        let episodesToAdd = this.state.episodesToAdd.filter(ep => ep.episodeNumber !== episode.episodeNumber);
        this.setState(prevState => ({...prevState, episodesToAdd: episodesToAdd, episodeToAdd: {...this.state.episodeToAdd, episodeNumber: ""}}));
    }

    //--------
    // DIALOGS
    //--------

    addEpisodesDialog() {
        return (
            <>
                <Form>
                    <Row className="details-row">
                        <Form.Group as={Col}>
                            <Label>Product ID*</Label>
                            <InputGroup>
                                <FormControl type="text" value={this.state.episodeToAdd.productId}
                                             onChange={(e) => this.setState(prevState => ({
                                                 ...prevState,
                                                 episodeToAdd: {...prevState.episodeToAdd, productId: e.target.value}
                                             }))}/>
                                    <Button variant="outline-secondary" onClick={() => {
                                        this.setState(prevState => ({...prevState, showProductPickerDialog: true}));
                                        this.loadProducts(1).then(r => r);
                                    }}>Pick</Button>
                            </InputGroup>
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Label>Sort Order in Season (optional)</Label>
                            <FormControl type="text" value={this.state.episodeToAdd.seriesIndex}
                                         onChange={(e) => this.setState(prevState => ({
                                             ...prevState,
                                             episodeToAdd: {...prevState.episodeToAdd, seriesIndex: e.target.value}
                                         }))}/>
                        </Form.Group>
                    </Row>
                    <Row className="details-row">
                        <Form.Group as={Col}>
                            <Label>Displayed Episode Number*</Label>
                            <FormControl type="text" value={this.state.episodeToAdd.episodeNumber}
                                         onChange={(e) => this.setState(prevState => ({
                                             ...prevState,
                                             episodeToAdd: {...prevState.episodeToAdd, episodeNumber: e.target.value}
                                         }))}/>
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Label>Episode Title</Label>
                            <FormControl type="text" value={this.state.episodeToAdd.episodeTitle}
                                         onChange={(e) => this.setState(prevState => ({
                                             ...prevState,
                                             episodeToAdd: {...prevState.episodeToAdd, episodeTitle: e.target.value}
                                         }))}/>
                        </Form.Group>
                    </Row>
                </Form>
                <div style={{float: "right"}}>
                    <button className={(this.state.episodeToAdd.productId == null || this.state.episodeToAdd.episodeNumber == null || this.state.episodeToAdd.episodeTitle == null ||
                                        this.state.episodeToAdd.productId === "" || this.state.episodeToAdd.episodeNumber === "" || this.state.episodeToAdd.episodeTitle === "") ? "form-btn-ci-off" : "form-btn-ci-light-blue" }
                            disabled={this.state.episodeToAdd.productId == null || this.state.episodeToAdd.episodeNumber == null || this.state.episodeToAdd.episodeTitle == null ||  this.state.episodeToAdd.productId === "" || this.state.episodeToAdd.episodeNumber === "" || this.state.episodeToAdd.episodeTitle === ""}
                            onClick={() => this.addEpisodeToList()}>Add to list
                    </button>
                </div>

                <Table style={{marginTop: "10px"}} responsive bordered hover striped>
                    <thead>
                    <tr>
                        <th>Episode Number</th>
                        <th>Sort Order in Season</th>
                        <th>Title</th>
                        <th>Product ID</th>
                        <th>Remove</th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.state.episodesToAdd
                        .sort((a,b)=> (a.seriesIndex - b.seriesIndex))
                        .map(episode => (
                        <tr role="row" key={episode.seriesIndex}>
                            <td>{episode.episodeNumber}</td>
                            <td>{episode.seriesIndex}</td>
                            <td>{episode.episodeTitle}</td>
                            <td>{episode.productId}</td>
                            <td>
                                <button className="form-btn-ci-red" onClick={() => this.removeEpisodeFromList(episode)}>
                                    <FiTrash2/></button>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
            </>
        );
    }

    productPickerDialog() {
        return (
            <>
                <Form>
                    <Row>
                        <Form.Group as={Col}>
                            <InputGroup>
                                <FormControl type="text" value={this.state.productFilterName}
                                             placeholder={"Search for a product by its title"}
                                             onChange={(e) => this.setState(prevState => ({
                                                 ...prevState,
                                                 productFilterName: e.target.value
                                             }))}/>
                                    <Button variant="outline-secondary"
                                            onClick={() => this.loadProducts(1)}>Search</Button>
                            </InputGroup>
                        </Form.Group>
                    </Row>
                </Form>
                <Table responsive bordered hover striped>
                    <thead>
                    <tr>
                        <th>ID</th>
                        <th>State</th>
                        <th>Type</th>
                        <th>Title</th>
                        <th>IAP Product Identifier</th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.state.products.map(product => (
                        <tr role="row" key={product.id} style={{cursor: "pointer"}}
                            onClick={() => this.setState(prevState => ({
                                ...prevState,
                                showProductPickerDialog: false,
                                episodeToAdd: {...prevState.episodeToAdd, productId: product.id},
                                selectedEpisode: {
                                    ...prevState.selectedEpisode,
                                    productId: this.state.editEpisode ? product.id : this.state.selectedEpisode.productId
                                }
                            }))}>
                            <td><Link to={"/" + GlobalConstants.APP_PATH + "products/" + product.id}
                                      style={{fontStyle: "italic", color: "#333"}}>{product.id}</Link></td>
                            <td><Badge bg={ProductStateBadges(product)}>{product.state}</Badge></td>
                            <td><Badge bg={ProductTypeBadges(product)}>{product.productType}</Badge></td>
                            <td>{product.title}</td>
                            <td>{product.iapProductIdentifier}</td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
                <TablePagination page={this.state.productPage} lastPage={this.state.lastProductPage}
                                 onLoad={(p) => this.loadProducts(p)}/>
            </>
        );
    }

    pickLanguageDialog() {
        return (
                <>
                    <div style={{width: "100%"}}>
                        <h5>Most common:</h5>
                        <div style={{float: "left"}}>
                            <Button variant="outline-secondary" style={{marginRight: "10px"}} onClick={() => this.handleLanguageSelection('deu')}>German</Button>
                            <Button variant="outline-secondary" style={{marginRight: "10px"}} onClick={() => this.handleLanguageSelection('eng')}>English</Button>
                            <Button variant="outline-secondary" style={{marginRight: "10px"}} onClick={() => this.handleLanguageSelection('fra')}>French</Button>
                            <Button variant="outline-secondary" style={{marginRight: "10px"}} onClick={() => this.handleLanguageSelection('spa')}>Spanish</Button>
                            <Button variant="outline-secondary" style={{marginRight: "10px"}} onClick={() => this.handleLanguageSelection('ita')}>Italian</Button>
                            <Button variant="outline-secondary" style={{marginRight: "10px"}} onClick={() => this.handleLanguageSelection('rus')}>Russian</Button>
                            <Button variant="outline-secondary" style={{marginRight: "10px"}} onClick={() => this.handleLanguageSelection('fin')}>Finnish</Button>
                            <Button variant="outline-secondary" style={{marginRight: "10px"}} onClick={() => this.handleLanguageSelection('por')}>Portugues</Button>
                            <Button variant="outline-secondary" style={{marginRight: "10px"}} onClick={() => this.handleLanguageSelection('gsw')}>Swiss german</Button>
                        </div>
                        <br />
                        <br />
                    </div>
                    <hr />
                    <Form>
                        <Row>
                            <Form.Group as={Col}>
                                <Form.Control type="text" placeholder="Search for a language"
                                              onChange={(e) => this.setState(prevState => ({...prevState, filteredIsos: this.state.isos.filter(i => i.name.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1)}))} />
                            </Form.Group>
                        </Row>
                    </Form>
                    <Table bordered responsive hover striped>
                        <thead>
                        <tr>
                            <th>Language</th>
                            <th>ISO Code</th>
                        </tr>
                        </thead>
                        <tbody>
                        {this.state.filteredIsos.map(i => (
                                <tr key={i.name} role="row" style={{cursor: "pointer"}}
                                    onClick={() => this.handleLanguageSelection(i.iso6392T ? i.iso6392T : i.iso6392B)}>
                                    <td>{i.name}</td>
                                    <td>{i.iso6392T ? i.iso6392T : i.iso6392B}</td>
                                </tr>
                        ))}
                        </tbody>
                    </Table>
                </>
        );
    }

    handleLanguageSelection(lang) {
        this.setState(prevState => ({
            ...prevState,
            series: {
                ...prevState.series,
                language: lang.toLowerCase()
            },
            showLanguageDialog: false
        }));
    }

    onClickTag(tag) {
        let tagIds = [];
        this.state.series._embedded.tags.forEach(tg => (tagIds.push(tg.id)));
        if (!tagIds.includes(tag.id)) {
            this.addTag(tag).then(value => {
                this.setState(prevState => ({
                            showTagsDialog: false,
                        }), () => {
                            this.props.addToast("The tag has been added successfully.", {
                                autoDismiss: true,
                                appearance: 'success'
                            })}
                );
            });
        } else {
            this.props.addToast("This tag is already assigned to this series", {
                autoDismiss: true,
                appearance: 'warning'
            });
        }
    }

    async addTag(tag) {
        let response = await CreateSeriesTag(this.state.series, tag);
        if (response.error) {
            this.setState(prevState => ({
                ...prevState,
                error: response
            }));
        } else {
            await this.reloadSeries();
        }
    }

    async removeTag(tag) {
        let response = await DeleteSeriesTag(this.state.series, tag);
        if (response.error) {
            this.setState(prevState => ({
                ...prevState,
                error: response
            }));
        } else {
            await this.reloadSeries();
        }
    }
}
export default withToast(SeriesDetails);