import {useState} from "react";
import {Col, Form} from "react-bootstrap";
import Row from 'react-bootstrap/Row';
import FormSelect from "react-bootstrap/FormSelect";
import {Label} from "reactstrap";
import {CreateSubscription, GetFilteredSubscriptions} from "../../../resources/subscriptions/SubscriptionService";
import {
    transformToZonedDateTime
} from "../../../util/ResourceService";

export function CreateSubscriptionDialog(props) {

    const availableDurations = [
        {id: 1, name: "1 Woche"},
        {id: 2, name: "2 Wochen"},
        {id: 3, name: "1 Monat"},
        {id: 4, name: "3 Monate"},
        {id: 5, name: "6 Monate"},
        {id: 6, name: "1 Jahr"},
        {id: 7, name: "2 Jahre"}
        ];

    let [account] = useState(props.account);
    let [products] = useState(props.products);
    let [productGroups] = useState(props.productGroups);

    let [selectedGroupId, setSelectedGroupId] = useState(props.productGroups[0].id);
    let [duration, setDuration] = useState("3");
    let [note, setNote] = useState("");
    let [overwrite, setOverwrite] = useState(false);

    return(
        <>
            <p>
                Erstelle eine neue Subscription für {account.email}:
            </p>
            <hr />
            <Form>
                <Row>
                    <Form.Group as={Col}>
                        <Label>Produktgruppe:</Label>
                        <FormSelect value={selectedGroupId.id} onChange={(e) => setSelectedGroupId(e.target.value)}>
                            {productGroups.map(group => (
                                <option value={group.id}>{group.name}</option>
                            ))}
                        </FormSelect>
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Label>Zeitraum:</Label>
                        <FormSelect value={duration} onChange={(e) => setDuration(e.target.value)}>
                            {availableDurations.map(dur => (
                                <option value={dur.id}>{dur.name}</option>
                            ))}
                        </FormSelect>
                    </Form.Group>
                </Row>
                <Row>
                    <Form.Group as={Col}>
                        <Label>Aktuellen Zugang überschreiben?</Label>
                        <div style={{marginLeft: "5px"}}>
                            <Form.Text>In diesem Fall ist der Zugang ab Zeitpunkt der Einlösung {availableDurations[duration - 1].name} gültig. Falls aktuell noch ein aktiver Zugang existiert, verfällt dessen Laufzeit.<br />Wird dieses Feld nicht ausgewählt, verlängert sich der aktuelle Zugang um {availableDurations[duration - 1].name}.</Form.Text>
                            <Form.Check value={overwrite} onChange={(e) => setOverwrite(!overwrite)} />
                        </div>

                    </Form.Group>
                </Row>
                <Row>
                    <Form.Group as={Col}>
                        <Label>Bemerkung:</Label>
                        <Form.Text>(Optional)</Form.Text>
                        <Form.Control as="textarea" rows={5} value={note} onChange={(e) => setNote(e.target.value)} />
                    </Form.Group>
                </Row>
            </Form>

            <p style={{float: "right"}}>
                <button className="form-btn-ci-red" onClick={() => props.onHide(false)}>Abbrechen</button>
                <button className="form-btn-ci-blue" onClick={() => saveSubscription(account, products, productGroups, selectedGroupId, duration, note, overwrite).then(r => {
                    if(r.error) {
                        props.parentProps.addToast("Beim Erstellen des Abos ist ein Fehler aufgetreten: " + r.message, {
                            appearance: "error",
                            autoDismiss: true
                        });
                    } else {
                        props.parentProps.addToast("Das Abo wurde erfolgreich erstellt.", {
                            autoDismiss: true,
                            appearance: "success"
                        });
                        props.onHide(true);
                    }
                })}>Speichern</button>
            </p>
        </>
    );
}

async function saveSubscription(account, products, productGroups, selectedGroupId, duration, note, overwrite) {

    let validFrom = new Date();

    //Calculate the article id by using the product group and iap product identifier
    let articleId = calculateArticleId(selectedGroupId, products, productGroups);

    //Calculate the valid until by using the duration
    let currentValidUntil = await fetchCurrentAccessValidity(account);
    let validUntil = calculateValidUntil(currentValidUntil, duration, overwrite);

    return await CreateSubscription(account, {accountId: account.id, articleId: articleId, productGroupId: selectedGroupId, validFrom: transformToZonedDateTime(validFrom.toString()), validUntil: transformToZonedDateTime(validUntil.toString()), source: 'SUPPORT', note: note, active: true});
}

async function fetchCurrentAccessValidity(account) {
    //Check if the account has an active premium account
    let premiumValidUntil = null;
    let activeSub = await GetFilteredSubscriptions(account, {active_only: true});
    if(!activeSub.error) {
        if(activeSub.result.length >= 1) {
            premiumValidUntil = activeSub.result[0].validUntil;
        }
    }

    return premiumValidUntil;
}

function calculateValidUntil(currentValidUntil, duration, overwrite) {

    let validUntil = (currentValidUntil != null && !overwrite) ? new Date(currentValidUntil) : new Date();

    if(duration === "1") {
        validUntil.setDate(validUntil.getDate() + 7);
    } else if(duration === "2") {
        validUntil.setDate(validUntil.getDate() + 14);
    } else if(duration === "3"){
        validUntil.setMonth(validUntil.getMonth() + 1);
    } else if(duration === "4") {
        validUntil.setMonth(validUntil.getMonth() + 3);
    }  else if(duration === "5") {
        validUntil.setMonth(validUntil.getMonth() + 6);
    } else if(duration === "6") {
        validUntil.setFullYear(validUntil.getFullYear() + 1);
    } else if(duration === "7") {
        validUntil.setFullYear(validUntil.getFullYear() + 2);
    } else {
        //Default
        validUntil.setMonth(validUntil.getMonth() +2);
    }

    return validUntil;
}

function calculateArticleId(selectedGroupId, products, productGroups) {
    let articleId = "";

    //find the matching group
    let group;
    let groups = productGroups.filter(pg => pg.id + "" === selectedGroupId);
    if(groups.length > 0) {
        group = groups[0];
    } else {
        group = productGroups[0];
    }

    let tigertonesProducts = products.filter(prod => prod.iapProductIdentifier.indexOf('tigertones') > -1);
    let tigerbooksProducts = products.filter(prod => prod.iapProductIdentifier.indexOf('tigerbook') > -1);

    let isTonesProduct = (group.name.toLowerCase().indexOf('tigertones') > -1);

    if(isTonesProduct) {
        if(tigertonesProducts.length > 0) {
            articleId = tigertonesProducts[0].iapProductIdentifier;
        } else {
            articleId =products[0].iapProductIdentifier;
        }
    } else {
        if(tigerbooksProducts.length > 0) {
            articleId = tigerbooksProducts[0].iapProductIdentifier;
        } else {
            articleId = products[0].iapProductIdentifier;
        }
    }
    return articleId;
}