import {Badge, Table} from "react-bootstrap";

export function AccountSelectionByEmailDialog(props) {
    const getEmail = (item) => {
        return (item.account != null && item.account.email != null) ? item.account.email : ((item.customer != null && item.customer.EmailAddress != null ) ? item.customer.EmailAddress : "N/A");
    }
    return(
        <>
            <p>Es wurden mehrere Treffer gefunden. Bitte wähle das gewünschte Konto aus der Liste</p>
            <Table responsive bordered hover striped>
                <thead>
                    <tr>
                        <th>Konto-ID</th>
                        <th>E-Mail</th>
                        <th>Gelöschtes Konto</th>
                    </tr>
                </thead>
                <tbody>
                    {props.accountList.map(acc => (
                        <tr role="row" key={acc.account.id} style={{cursor: "pointer"}} onClick={() => props.onSelectAccount(acc)}>
                            <td>{acc.account.id}</td>
                            <td>{getEmail(acc)}</td>
                            <td><Badge bg={acc.deleted ? "danger" : "success"}>
                                {acc.deleted ? "Ja" : "Nein"}</Badge></td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </>
    );
}