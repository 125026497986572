import {Button} from "react-bootstrap";
import React, {Component} from "react";
import {withToast} from "../../util/ToastService";
import {Link} from "react-router-dom";
import {FaBook} from "react-icons/all";
import GlobalConstants from "../../config/GlobalConstants";
import {DetailsSection, RequiredFieldsAreValid} from "../../generators/DetailsGenerator";
import {PromiseButton} from "../../global/SpinningTiger";
import {ErrorHandler} from "../../util/ErrorHandler";
import {GetOrderedBundle, UpdateOrderedBundle} from "./OrderedBundlesService";
import OrderedBundlesMetaData from "./OrderedBundlesMetaData";

class OrderedBundleDetails extends Component {

    //------------
    //Constructor
    //------------

    constructor(props)
    {
        super(props);

        this.state = {
            error: null,
            flowState: props.location.state,
            id: this.props.match.params.id,
            orderedBundle : {},
            originalOrderedBundle : {},
            editMode: {
                active: false,
                expertMode: false
            },
            sections: {
                general: true,
                shipmentData: true,
                returnShipmentData: true
            }

        }
    }

    //---------
    //Mounting
    //---------

    async componentDidMount()
    {
        if (this.state.id != null) {
            document.title = "Ordered Bundle " + this.state.id + " :: Tiger UI";
        }
        await this.loadOrderedBundle();
    }

    //--------
    //Loading
    //--------

    async loadOrderedBundle()
    {
        let response = {};

        if (this.state.id != null && this.state.id !== "add")
        {

            response = await GetOrderedBundle(this.state.id);

            if (!response.error)
            {
                const orderedBundle = response.result;

                this.setState({
                    id: this.state.id,
                    editMode: this.state.editMode,
                    orderedBundle: orderedBundle,
                    originalOrderedBundle: orderedBundle,
                });
            }
            else
            {
                this.setState(prevState => ({...prevState, error: response}));
            }
        }
        else
        {
            this.setState((prevState) => ({
                ...prevState,
                id: null,
                orderedBundle: {
                    id: null,
                },
                editMode: {
                    active: true,
                    expertMode: false
                }
            }));
        }
    }

    //----------
    //Rendering
    //----------

    render()
    {
        return (
                <>
                    <div className="details-title">
                        {this.state.orderedBundle.id ? "Details of Ordered Bundle " + this.state.orderedBundle.id : "Create a new Ordered Bundle"}
                    </div>
                    <div className="details-button-box" style={{height: "70px"}}>
                        <Link to={{pathname: "/" + GlobalConstants.APP_PATH + "purchases/ordered-bundles", state: this.state.flowState}}>
                            <button className="form-btn-ci-light-blue" type="button">Back</button>
                        </Link>
                        {this.state.orderedBundle.id != null &&
                                <button className={this.state.editMode.active ? "form-btn-ci-red" : "form-btn-ci-blue"}
                                        type="button" onClick={() => this.setState({
                                    editMode: {
                                        active: !this.state.editMode.active,
                                        expertMode: this.state.editMode.expertMode
                                    },
                                    orderedBundle: this.state.originalOrderedBundle
                                })}>{this.state.editMode.active ? "Cancel" : "Edit"}</button>
                        }
                        {this.state.editMode.active &&
                                <PromiseButton text="Save" onClick={() => this.saveOrUpdateOrderedBundle()}/>
                        }
                        <Button style={{float: "right", marginTop: "5px"}}
                                variant={this.state.editMode.expertMode ? "secondary" : "outline-secondary"}
                                onClick={() => this.setState(prevState => ({
                                    ...prevState,
                                    editMode: {
                                        ...prevState.editMode,
                                        expertMode: !this.state.editMode.expertMode
                                    }
                                }))}>Expert Mode</Button>
                    </div>

                    <div className="details-box-title">Resource Details</div>
                    <div className="details-box">
                        {/* GENERAL DATA */}
                        <DetailsSection
                                nameInState="orderedBundle"
                                fields={OrderedBundlesMetaData.DETAILS_GENERAL}
                                state={this.state}
                                onSetState={(s) => this.setState(s)}
                                sectionId="general"
                                key={"general" + this.state.id}
                        />

                        {/* SECTION 1 */}
                        <DetailsSection
                                nameInState="orderedBundle"
                                fields={OrderedBundlesMetaData.DETAILS_SECTION_SHIPMENT_DATA}
                                state={this.state}
                                onSetState={(s) => this.setState(s)}
                                sectionId="shipmentData"
                                label={<span><FaBook/>&#xA0;Versand-Daten</span>}
                                key={"shipmentData" + this.state.id}
                        />

                        {this.state.orderedBundle.contractState === 'CANCELLED' &&
                                <DetailsSection
                                        nameInState="orderedBundle"
                                        fields={OrderedBundlesMetaData.DETAILS_SECTION_RETURN_SHIPMENT_DATA}
                                        state={this.state}
                                        onSetState={(s) => this.setState(s)}
                                        sectionId="returnShipmentData"
                                        label={<span><FaBook/>&#xA0;Versand-Daten Retoure</span>}
                                        key={"returnShipmentData" + this.state.id}
                                />
                        }


                    </div>
                    <div className="details-button-box" style={{height: "70px"}}>
                        <Link to={{pathname: "/" + GlobalConstants.APP_PATH + "purchases/ordered-bundles", state: this.state.flowState}}>
                            <button className="form-btn-ci-light-blue" type="button">Back</button>
                        </Link>
                        {this.state.editMode.active &&
                                <PromiseButton text="Save" onClick={() => this.saveOrUpdateOrderedBundle()}/>
                        }
                    </div>

                    <ErrorHandler error={this.state.error}
                                  onHide={() => this.setState(prevState => ({...prevState, error: null}))}/>
                </>
        );
    }

    //------------
    //API Methods
    //------------

    async saveOrUpdateOrderedBundle()
    {
        let addtionalSection;
        if (this.state.orderedBundle.contractState === 'ACTIVE') {
            addtionalSection = OrderedBundlesMetaData.DETAILS_SECTION_SHIPMENT_DATA;
        } else if (this.state.orderedBundle.contractState === 'CANCELLED') {
            addtionalSection = OrderedBundlesMetaData.DETAILS_SECTION_RETURN_SHIPMENT_DATA;
        }
        if (!RequiredFieldsAreValid("orderedBundle", [OrderedBundlesMetaData.DETAILS_GENERAL, addtionalSection],
                this.state, (s) => this.setState(s))) {
            return;
        }
        let response = await UpdateOrderedBundle(this.state.orderedBundle);

        if (!response.error)
        {
            if (this.state.orderedBundle.id == null) {
                this.props.history.push("/" + GlobalConstants.APP_PATH + "purchases/ordered-bundles" + response.result.id);
            }
            this.setState((prevState) => ({
                ...prevState,
                orderedBundle: response.result,
                originalOrderedBundle: response.result,
                editMode: {...prevState.editMode, active: false}
            }));

            this.props.addToast("Die Kaufinformationen zum Kombi-Paket wurde erfolgreich aktualisiert.", {
                autoDismiss: true,
                appearance: 'success'
            });
        }
        else
        {
            this.setState(prevState => ({...prevState, error: response}));
        }
    }
}


export default withToast(OrderedBundleDetails);