import {
    CreateSpineResource, GetBlob,
    GetFilteredSpineResources,
    GetSpineResource,
    UpdateSpineResource
} from "../../clients/SpineClient";
import {CreateBillResource, GetBillResource, GetFilteredBillResources} from "../../clients/PurchaseClient";
import {GetFilteredOrders} from "../../resources/order/OrderService";
import GlobalConstants from "../../config/GlobalConstants";
import {trackPromise} from "react-promise-tracker";
import {getTicketRedemptionError} from "./SupportToolHelper";
import {getToken} from "../../auth/SpineTokenStorageService";
import defaultCover from "../../../assets/images/loader_bg.png";
import {GetRestResource} from "../../clients/RestResourceClient";

export async function GetAccountDevices(account) {
//    return await GetSpineResources('api/devices/account/' + account.id);
    return await GetRestResource('api/devices/search/by-account-id?accountId=' + account.id, false);

    //TESTING PURPOSE
    //return await {error: false, result: [TigerboxObject(1031082495)]};
}

export async function GetAccountGeneratedContentItems(accountGeneratedContentId, nfcCardId) {
    return await GetFilteredSpineResources('api/accountgeneratedcontents/' + accountGeneratedContentId + '/items', {nfcCardId: nfcCardId});
}

export async function GetAccountWildcards(account) {
//    return await GetFilteredSpineResources('api/cards/search/wildcards-by-account-id', {accountId: account.id});
    return await GetFilteredSpineResources('api/cards/search/by-account-id', {accountId: account.id});
    //TESTING PURPOSE
    /**
    return await {
        error: false,
        result: {
            _embedded: {
                cards: [WildcardWithTonesContent(1031082495), WildCardWithAccountGeneratedContent(1031082495)]
            }
        }
    }*/
}

export async function GetWildcardCover(card) {
    const product = card._embedded ? (card._embedded.accountgeneratedcontents || card._embedded.products) : null;
    const downloadUrl = product && product._links &&
       product._links.cover && product._links.cover.href;
    if (downloadUrl) {
        //Amazon or Google -> Return the url
        if (downloadUrl.indexOf('tigerbooks.de') > -1) {
            return await GetBlob(downloadUrl, "");
        } else {
            return downloadUrl;
        }
    } else {
        return defaultCover;
    }
}

export async function GetBillwerkProducts() {
    const response = await GetFilteredBillResources('api/purchases/products?pageSize=200');
    if (!response.error) {
        return response.result;
    } else {
        return [];
    }
}

export async function GetExtendedCustomerTicketInfos(code) {
    return await GetFilteredBillResources('api/purchases/customer/search/extended-by-ticket-code', {code: code});
}

export async function GetWildcardByCode(code) {
//    return await GetSpineResource('api/cards/search/wildcard-by-code?code=' + code);
    return await GetSpineResource('api/cards/search/by-code?code=' + code);
}

export async function GetUploadInformationOfWildcards(account) {
    return await GetFilteredSpineResources('api/accountgeneratedcontents/upload-information', {accountId: account.id});
    //TESTING PURPOSE
    /**
    return await {
        error: false,
        result: FailedUploadInformation(1031082495, 289, "DURATION_CAN_NOT_DETERMINED")
    }*/
}

export async function UpdateDeviceSupplements(remark) {
    return await UpdateSpineResource('api/devices/supplements/' + remark.id, remark);
}

export async function CreateDeviceSupplements(remark) {
    return await CreateSpineResource('api/devices/supplements', remark);
}

export async function GetFilteredDeviceSupplements(account) {
    return await GetFilteredSpineResources('api/devices/supplements/search/by-account-id', {accountId: account.id});
}

export async function GetDeviceSupplementByDeviceId(deviceId) {
    return await GetFilteredSpineResources('api/devices/supplements/search/by-device-id', {deviceId: deviceId});
}

export async function RedeemTicket(ticket, accountId) {
    const token = getToken();
    let errorOccurred = false;
    return trackPromise(fetch(GlobalConstants.BILL_CLIENT_HOST + 'api/purchases/order/submit-ticket-order-by-support?accountId=' + accountId, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token.access_token,
        },
        body: JSON.stringify({
            code: ticket.code,
            pin: ticket.pin
        }),
    })
        .then(function (response){
            if(!response.ok) {
                errorOccurred = true;
            }
            return response.json();
        })
        .then(function (response){
            if(errorOccurred) {
                return {error: true, reason: getTicketRedemptionError(response)}
            } else {
                return {error: false, message: "", result: response}
            }
        })
        .catch(function (error){
            return {error: true, reason: "Ein unbekannter Fehler ist aufgetreten: " + error, result: {}}
        }));
}

export async function GetSupportPresentsOfAccount(accountId) {
    return await GetFilteredBillResources('api/purchases/customer/' + accountId + '/contract-phases?activeOnly=false&planVariantIds=' +
        GlobalConstants.PLANVARIANT_ID_PRESENT_1WEEK + ',' + GlobalConstants.PLANVARIANT_ID_PRESENT_1MONTH + ',' + GlobalConstants.PLANVARIANT_ID_PRESENT_1YEAR, {});
}

export async function GetAccountTickets(accountId) {
    return await GetFilteredBillResources('api/purchases/tickets/search/by-account-id', {accountId: accountId});
}

export async function ResetTicket(ticket, removePhase) {
    const type = ticket.coupon.type;
    let url = type === 'DIGITICKET' ? 'api/purchases/digitickets/' + ticket.id + '/reset?cancelContractPhase=' + removePhase : (type === 'TIGERTICKET' ? 'api/purchases/tigertickets/' + ticket.id + '/reset?cancelContractPhase=' + removePhase : 'api/purchases/coupons/' + ticket.id + '/reset?cancelContractPhase=' + removePhase);
    return GetBillResource(url);
}

export async function GetCompletedAccountOrders(account, page) {
    return await GetFilteredOrders({accountId: account.id, state: "COMPLETED", page: page});
}

export async function PerformSwissUpgrade(accountId) {
    return await GetBillResource('api/purchases/customer/' + accountId + '/swiss-upgrade');
}

export async function CreatePremiumAccess(request) {
    return await CreateBillResource('api/purchases/order/submit-coupon-order-by-support', request);
}

//-----------------
// Profiles
//-----------------

export async function LoadAccountProfiles(accountId) {
    return await GetFilteredSpineResources('api/accounts/' + accountId + '/profiles', {});
}
//-----------------
// Newsletter stuff
//-----------------

export async function SendDoubleOptInMail(request) {
    return await CreateSpineResource('api/newsletter/subscribe', request, false);
}

export async function RemoveFromNewsletterList(request) {
    return await CreateSpineResource('api/newsletter/unsubscribe', request, false);
}

export async function GetNewsletterListsByEmail(email) {
    return await GetFilteredSpineResources('api/newsletter/lists', {email: email});
    //return {error: false, message: "", length: 2, result: [GlobalConstants.MAILJET_TIGERTIPPS_LIST_ID,123456]}
}

export async function HasSubscribedToRecommendations(email) {
    let response = await GetFilteredSpineResources('api/crm/recommendation/subscribers', {
        email: email,
        subscribed: true
    });
    return !response.error && response.result.length > 0;
}