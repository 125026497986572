const AggregatorMetaData = {

    //Columns
    COLUMNS: [
        //Default & Badges
        {label: "ID", id: 'id', columnType: 'default', show: true},
        {label: "Phase", id: 'phase', columnType: 'badge', options: [
                {value: 'WEIGHTED', style: 'primary'},
                {value: 'MEASURED', style: 'success'}
            ], show: true},
        {label: "Name", id: 'name', columnType: 'default', show: true},
        {label: "Address", id: 'address', columnType: 'default', show : false},
        {label: "Person", id: 'person', columnType: 'default', show: true},
        {label: "Platform Share Deducted", id: 'platformShareDeducted', columnType: 'boolean', show: true},
        {label: "Financial Share", id: 'financialShare', columnType: 'default', show: true},
        {label: "Created By", id: 'createdBy', columnType: 'default', show: false},
        {label: "Created Date", id: 'createdDate', columnType: 'default', show: false},
        {label: "Last Modified By", id: 'lastModifiedBy', columnType: 'default', show: false},
        {label: "Last Modified Date", id: 'lastModifiedDate', columnType: 'default', show: false}
    ],

    //Details
    DETAILS_GENERAL: [
        [{label: 'ID', id: 'id', type: 'text', editable: 'never'}, {label: 'Last Modified By', id: 'lastModifiedBy', type: 'text', editable: 'never'}, {label: "Last Modified Date", id: 'lastModifiedDate', type: 'text', editable: 'never'}],
        [{label: 'Created By', id: 'createdBy', type: 'text', editable: 'never', isExpertField: true}, {label: 'Created Date', id: 'createdDate', type: 'text', editable: 'never', isExpertField: true}],
    ],
    AGGREGATOR_DETAILS: [
        [{label: 'Name', id: 'name', type: 'text', editable: 'true', required: true}, {label: 'Person', id: 'person', type: 'text', editable: 'true', required: true}, {label: 'Address', id: 'address', type: 'text', editable: 'true', required: true}],
        [{label: 'Financial Share', id: 'financialShare', type: 'text', editable: 'true'}, {label: 'Phase', id: 'phase', type: 'select', options: ['WEIGHTED', 'MEASURED'], editable: 'true'}, {label: 'Platform Share Deducted', id: 'platformShareDeducted', type: 'boolean', editable: 'true'}]
    ],

    //Sorting
    AGGREGATOR_SORT_PARAMS: [],
};

export default AggregatorMetaData;