import {Button, Col, FormControl, InputGroup, Table} from "react-bootstrap";
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import FormSelect from 'react-bootstrap/FormSelect';
import React, {Component} from "react";
import {PickTagDialog} from "../tags/PickTagDialog";
import {withToast} from "../../util/ToastService";
import {PickCollectionDialog} from "../collections/PickCollectionDialog";
import {
    CreateRoutine, CreateRoutineCollection, DeleteRoutineCollection,
    GetRoutine,
    UpdateRoutine, UpdateRoutineCollection, CreateRoutineTag, DeleteRoutineTag
} from "./RoutineService";
import {Link} from "react-router-dom";
import {
    BiAddToQueue,
    FiTrash2,
    GoDiffAdded,
    MdOutlineCancel,
    TiDeleteOutline
} from "react-icons/all";
import GlobalConstants from "../../config/GlobalConstants";
import {DetailsSection, RequiredFieldsAreValid} from "../../generators/DetailsGenerator";
import RoutineMetaData from "./RoutineMetaData";
import {InfoModal} from "../../generators/ModalGenerator";
import {PromiseButton} from "../../global/SpinningTiger";
import {ErrorHandler} from "../../util/ErrorHandler";
import {iso6392} from 'iso-639-2';

class RoutineDetails extends Component {

    constructor(props) {
        super(props);

        this.state = {
            error: null,
            flowState: props.location.state,
            id: this.props.match.params.id,
            editMode: {
                active: false,
                expertMode: false,
                autoSave: false,
            },
            routine: {
                tags: [],
                defaultWeekdays: [],
            },
            originalRoutine: {},
            sections: {
                general: true,
                time: true,
            },

            showTagsDialog: false,

            routineCollections: [],
            selectedRoutineCollection: {
                collection: {
                    id: null
                }
            },
            showAddRoutineCollection: false,
            showCollectionPickerDialog: false,

            isos: [],
            filteredIsos: [],
            originalOrder: [],
            validationErrors: []
        }
    }

    //Mounting
    async componentDidMount() {

        //Set the title
        if (this.state.id != null) {
            document.title = "Routine " + this.state.id + " :: Tiger UI";
        } else {
            document.title = "New Routine :: Tiger UI";
        }

        if (this.state.id != null && this.state.id !== "add") {
            await this.loadRoutine(this.state.id);
        } else {
            this.setState((prevState) => ({
                ...prevState,
                id: null,
                routine: {
                    ...prevState.routine,
                    id: null,
                },
                editMode: {
                    active: true,
                    expertMode: true,
                    autoSave: false
                }
            }));
        }

        this.setState(prevState => ({
            ...prevState,
            isos: iso6392,
            filteredIsos: iso6392
        }));
    }

    async reloadRoutine() {
        return this.loadRoutine(this.state.routine.id)
    }

    async loadRoutine(id) {
        let response = await GetRoutine(id);
        if (!response.error) {
            let routine = response.result;

            this.setState(prevState => ({
                ...prevState,
                routine: routine,
                originalRoutine: routine,
                routineCollections: routine._embedded.routineCollections,
                sections: {
                    ...prevState.sections,
                    account: routine.routineType === 'CUSTOMER_ROUTINE'
                }
            }));
        }
    }

    //Rendering
    render() {
        const routineTags = (
                <div>
                    {this.state.routine?._embedded?.tags && this.state.editMode.active && this.state.routine._embedded.tags.map(
                            tag => (
                                    <button key={tag.id} className="form-btn-ci" type="button" onClick={() => {
                                        this.removeTag(tag).then(value => {
                                            this.setState(prevState => ({
                                                showTagsDialog: false,
                                            }), () => {
                                                this.props.addToast("The tag has been removed successfully.", {
                                                autoDismiss: true,
                                                appearance: 'success'
                                            })}
                                        );
                                        });
                                    }}>{tag.name}&#xA0;<TiDeleteOutline/></button>
                            ))}
                    {this.state.routine?._embedded?.tags && !this.state.editMode.active && this.state.routine._embedded.tags.map(
                            tag => (
                                    <button key={tag.id} className="form-btn-ci" disabled={true}>{tag.name}</button>
                            ))}
                </div>
        );

        const tagFormGroup = (
                <>
                    {this.state.editMode.active &&
                        <Button variant="outline-secondary"
                                style={{
                                    height: "42px",
                                    marginRight: "5px",
                                    float: "left"
                                }} onClick={() => {
                            this.setState(prevState => ({
                                ...prevState,
                                showTagsDialog: true
                            }));


                        }}><BiAddToQueue size={18}/> Add Tags</Button>
                    }
                    {routineTags}
                </>
        );

        const labelStyle = {
            fontSize: "20px",
            backgroundColor: "#C8C8C8",
            borderStyle: "solid",
            borderColor: "#cdd7e0",
            borderWidth: "1px",
            borderRadius: "5px",
            paddingLeft: "10px",
            paddingRight: "10px",
            width: "100%"
        };

        return (
                <>
                    <div className="details-title">
                        {this.state.routine?.id ? "Details of Routine " + this.state.routine.id :
                                "Create a new Routine"}
                    </div>
                    <div className="details-button-box" style={{height: "70px"}}>
                        <Link to={{
                            pathname: "/" + GlobalConstants.APP_PATH + "routines",
                            state: null
                        }}>
                            <button className="form-btn-ci-light-blue" type="button">Back</button>
                        </Link>
                        {this.state.routine.id != null &&
                                <button className={this.state.editMode.active ? "form-btn-ci-red" : "form-btn-ci-blue"}
                                        type="button" onClick={() => this.setState(prevState => ({
                                    ...prevState,
                                    editMode: {
                                        ...prevState.editMode,
                                        active: !this.state.editMode.active
                                    },
                                    routine: this.state.originalRoutine
                                }))}>{this.state.editMode.active ? "Cancel" : "Edit"}</button>
                        }
                        {this.state.editMode.active &&
                            <PromiseButton text="Save" onClick={() => this.saveOrUpdateRoutine()}/>
                        }
                        <Button style={{
                            float: "right",
                            marginTop: "5px",
                            marginRight: "10px"
                        }} variant={this.state.editMode.autoSave ? "secondary" : "outline-secondary"}
                                onClick={() => this.setState(prevState => ({
                                    ...prevState,
                                    editMode: {
                                        ...prevState.editMode,
                                        autoSave: !this.state.editMode.autoSave
                                    }
                                }))}>Auto Save</Button>
                    </div>

                    <div className="details-box-title">Resource Details</div>
                    <div className="details-box">
                        <DetailsSection
                                nameInState="routine"
                                fields={RoutineMetaData.DETAILS_GENERAL}
                                state={this.state}
                                onSetState={(s) => this.setState(s)}
                                onUpdateResource={() => this.saveOrUpdateRoutine()}
                                sectionId="general"
                                customFieldContent={tagFormGroup}
                        />

                        <DetailsSection
                                label={"Time Information"}
                                nameInState="routine"
                                fields={RoutineMetaData.DETAILS_SECTION_TIME}
                                state={this.state}
                                onSetState={(s) => this.setState(s)}
                                onUpdateResource={() => this.saveOrUpdateRoutine()}
                                sectionId="time"
                        />

                        <Form>
                            <Row>
                                <Form.Group as={Col}>
                                    <Form.Label style={labelStyle}>Routine Collections</Form.Label>
                                    <div style={{marginBottom: "5px"}}>
                                        <button
                                                className={"form-btn-ci-blue"}
                                                disabled={false}
                                                type="button"
                                                onClick={() => this.setState(prevState => ({
                                                    ...prevState,
                                                    showAddRoutineCollection: !this.state.showAddRoutineCollection,
                                                    editRoutineCollection: true,
                                                    selectedRoutineCollection: {
                                                        id: null,
                                                        collection: {
                                                            id: null,
                                                        },
                                                        routine: {
                                                            id: null,
                                                        }
                                                    }
                                                }))}>
                                            <GoDiffAdded/> {!this.state.showAddRoutineCollection ? "Add Collection" :
                                                "Hide dialog"}
                                        </button>
                                    </div>

                                    {/* SELECTED routine collections */}
                                    {this.state.showAddRoutineCollection &&
                                            <>
                                                <br/>
                                                <br/>
                                                <hr/>
                                                <h4>{(this.state.selectedRoutineCollection.id) ?
                                                        "Routine Collection " + this.state.selectedRoutineCollection.id :
                                                        "Add collection to routine"}
                                                    <span style={{float: "right"}}>
                                                        {this.state.editRoutineCollection &&
                                                                <button className="form-btn-ci-red" onClick={() => this.setState(prevState => (
                                                                        {
                                                                            ...prevState,
                                                                            selectedRoutineCollection: (this.state.selectedRoutineCollection.id != null && this.state.selectedRoutineCollection.id !== "") ?
                                                                                    this.state.selectedRoutineCollection.filter(
                                                                                            e => e.id === this.state.selectedRoutineCollection.id)[0] :
                                                                                    {},
                                                                            editRoutineCollection: false
                                                                        }))}>Cancel</button>
                                                        }
                                                        <button type="button" className="form-btn-ci-blue"
                                                                onClick={() => {
                                                                    if (!this.state.selectedRoutineCollection) {
                                                                        this.setState(prevState => ({
                                                                            ...prevState,
                                                                            editRoutineCollection: true
                                                                        }));
                                                                    } else {
                                                                        this.saveOrUpdateRoutineCollection(this.state.id,
                                                                                this.state.selectedRoutineCollection)
                                                                                .then(r => r);
                                                                    }
                                                                }
                                                                }>{this.state.editRoutineCollection ? "Save" :
                                                                "Edit"}</button>
                                                        <button type="button" className="form-btn-ci-light-blue"
                                                                onClick={() => this.setState(prevState => ({
                                                                    ...prevState,
                                                                    selectedRoutineCollection: {
                                                                        collection: {
                                                                            id: null,
                                                                        },
                                                                        routine: {
                                                                            id: null,
                                                                        }
                                                                    },
                                                                    showAddRoutineCollection: false
                                                                }))}>Close</button>
                                                </span>
                                                </h4>
                                                <div style={{marginTop: "20px"}}>
                                                    <Row>
                                                        <Form.Group as={Col}>
                                                            <Form.Label style={labelStyle}>ID</Form.Label>
                                                            <FormControl type="text"
                                                                         value={this.state.selectedRoutineCollection?.id ? this.state.selectedRoutineCollection.id : ""}
                                                                         readOnly={true}/>
                                                        </Form.Group>
                                                        <Form.Group as={Col}>
                                                            <Form.Label style={labelStyle}>Collection ID*</Form.Label>
                                                            <InputGroup>
                                                                <FormControl type="text"
                                                                             value={this.state.selectedRoutineCollection?.collection?.id ? this.state.selectedRoutineCollection.collection.id : ""}
                                                                             readOnly={!this.state.editRoutineCollection}
                                                                             onChange={(e) => this.setState(
                                                                                     prevState => ({
                                                                                         ...prevState,
                                                                                         selectedRoutineCollection: {
                                                                                             ...prevState.selectedRoutineCollection,
                                                                                             collection: {
                                                                                               id: e.target.value,
                                                                                             },
                                                                                             routine: {
                                                                                                 id: this.state.routine.id,
                                                                                             }
                                                                                         }
                                                                                     }))}/>
                                                                {this.state.editRoutineCollection &&
                                                                            <Button variant="outline-secondary"
                                                                                    onClick={() => {
                                                                                        this.setState(prevState => ({
                                                                                            ...prevState,
                                                                                            showCollectionPickerDialog: true
                                                                                        }));
                                                                                    }}>Pick</Button>
                                                                }
                                                            </InputGroup>
                                                        </Form.Group>
                                                        <Form.Group as={Col}>
                                                            <Form.Label style={labelStyle}>Routine Collection Type</Form.Label>
                                                            <FormSelect value={this.state.selectedRoutineCollection?.defaultRoutineCollectionType ? this.state.selectedRoutineCollection?.defaultRoutineCollectionType : ""}
                                                                         readOnly={!this.state.editRoutineCollection}
                                                                         onChange={(e) => this.setState(prevState => ({
                                                                             ...prevState,
                                                                             selectedRoutineCollection: {
                                                                                 ...prevState.selectedRoutineCollection,
                                                                                 defaultRoutineCollectionType: e.target.value
                                                                             }
                                                                         }))}>
                                                                {RoutineMetaData.ROUTINE_COLLECTION_TYPE.map(type => (
                                                                        <option key={type}>{type}</option>
                                                                ))}
                                                            </FormSelect>
                                                        </Form.Group >
                                                    </Row>
                                                </div>
                                            </>
                                    }

                                    <Table responsive bordered hover striped
                                           style={{marginTop: "10px"}}>
                                        <thead>
                                        <tr>
                                            <th>ID</th>
                                            <th>Collection ID</th>
                                            <th>Name</th>
                                            <th>Edit</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {this.state.routineCollections
                                                .map((routineCollection) => (
                                                        this.routineCollectionRow(routineCollection)
                                                ))}
                                        </tbody>
                                    </Table>

                                </Form.Group>
                            </Row>
                        </Form>
                    </div>
                    <div className="details-button-box" style={{height: "70px"}}>
                        <Link to={{
                            pathname: "/" + GlobalConstants.APP_PATH + "routines",
                            state: null
                        }}>
                            <button className="form-btn-ci-light-blue" type="button">Back</button>
                        </Link>
                    </div>


                    {/* DIALOGS */}
                    <InfoModal show={this.state.showCollectionPickerDialog}
                               onHide={() => this.setState(prevState => ({
                                   ...prevState,
                                   showCollectionPickerDialog: false
                               }))}
                               title={"Pick a collection"}
                               body={<PickCollectionDialog
                                       onClickCollection={(collection) => {
                                           this.setState(prevState => ({
                                               ...prevState,
                                               collection: collection,
                                               showCollectionPickerDialog: false,
                                               selectedRoutineCollection: {
                                                   ...prevState.selectedRoutineCollection,
                                                   collection : {
                                                        id : collection.id,
                                                   },
                                                   routine: {
                                                       id: this.state.routine.id,
                                                   }
                                               }
                                           }))
                                       }}
                               />}/>

                    <InfoModal show={this.state.showTagsDialog}
                               onHide={() => this.setState(prevState => ({
                                   ...prevState,
                                   showTagsDialog: false,
                               }))}
                               title="Add tags to routine"
                               body={<PickTagDialog onClickTag={(tag) => this.onClickTag(tag)}/>}/>

                    <ErrorHandler error={this.state.error}
                                  onHide={() => this.setState(prevState => ({
                                      ...prevState,
                                      error: null
                                  }))}/>
                </>
        );
    }

    //------------
    //API Methods
    //------------
    async saveOrUpdateRoutine() {
        if (!RequiredFieldsAreValid("routine", RoutineMetaData.DETAILS_GENERAL, this.state,
                (s) => this.setState(s))) {
            return;
        }
        let response = {};
        if (this.state.routine.id != null) {
            response = await UpdateRoutine(this.state.routine);
        } else {
            response = await CreateRoutine(this.state.routine);
        }

        if (!response.error) {
            if (this.state.routine.id == null) {
                this.props.history.push("/" + GlobalConstants.APP_PATH + "routines/" + response.result.id);
            }
            this.setState((prevState) => ({
                ...prevState,
                routine: response.result,
                originalRoutine: response.result,
                editMode: {
                    ...prevState.editMode,
                    active: this.state.editMode.autoSave
                }
            }));

            this.props.addToast("The routine has been created/updated successfully.", {
                autoDismiss: true,
                appearance: 'success'
            });
        } else {
            this.setState(prevState => ({
                ...prevState,
                error: response
            }));
        }
    }

    async saveOrUpdateRoutineCollection(routineId, routineCollection) {
        let response = {};
        if (!routineCollection.id) {
            response = await CreateRoutineCollection(routineId, routineCollection);
        } else {
            response = await UpdateRoutineCollection(routineId, routineCollection);
        }
        if (!response.error) {
            this.setState(prevState => ({
                ...prevState,
                editRoutineCollection: false,
                selectedRoutineCollection: {
                    id: null,
                    routine: {
                        id: null,
                    },
                    collection : {
                        id: null,
                    }
                }
            }));
            this.props.addToast("The routine collection has been created/updated successfully.", {
                autoDismiss: true,
                appearance: "success"
            });
            await this.reloadRoutine();
        } else {
            this.setState(prevState => ({
                ...prevState,
                error: response
            }));
        }
    }

    async deleteRoutineCollection(routineCollection) {
        let response = await DeleteRoutineCollection(this.state.routine.id, routineCollection);
        if (response.error) {
            this.setState(prevState => ({
                ...prevState,
                error: response
            }));
        } else {
            this.setState(prevState => ({
                ...prevState,
                selectedRoutineCollection: {}
            }));
            await this.reloadRoutine();
        }
    }

    async addTag(tag) {
        let response = await CreateRoutineTag(this.state.routine, tag);
        if (response.error) {
            this.setState(prevState => ({
                ...prevState,
                error: response
            }));
        } else {
            await this.reloadRoutine();
        }
    }

    async removeTag(tag) {
        let response = await DeleteRoutineTag(this.state.routine, tag);
        if (response.error) {
            this.setState(prevState => ({
                ...prevState,
                error: response
            }));
        } else {
            await this.reloadRoutine();
        }
    }

    //--------
    // HELPERS
    //--------

    onClickTag(tag) {
        let tagIds = [];
        this.state.routine._embedded.tags.forEach(tg => (tagIds.push(tg.id)));
        if (!tagIds.includes(tag.id)) {
            this.addTag(tag).then(value => {
                this.setState(prevState => ({
                    showTagsDialog: false,
                }), () => {
                    this.props.addToast("The tag has been added successfully.", {
                    autoDismiss: true,
                    appearance: 'success'
                })}
            );
            });
        } else {
            this.props.addToast("This tag is already assigned to this routine", {
                autoDismiss: true,
                appearance: 'warning'
            });
        }
    }

    routineCollectionRow(routineCollection) {
        return (
                <tr key={routineCollection.id}>
                    <td>{routineCollection.id}</td>
                    {this.state.selectedRoutineCollection.id === routineCollection.id ?
                            <>
                                <td>
                                    <Form.Control type="text" value={routineCollection._embedded.collection.id}
                                                  onChange={(e) => this.setState(prevState => ({
                                                      ...prevState,
                                                      selectedRoutineCollection: {
                                                          ...prevState.selectedRoutineCollection,
                                                          collection: {
                                                            id: e.target.value,
                                                          }
                                                      }
                                                  }))}/>
                                </td>
                                <td>{routineCollection._embedded?.collection?.name ?
                                        routineCollection._embedded?.collection?.name : ""}</td>
                                <td>
                                    <PromiseButton className="form-btn-ci-red" type="button"
                                            onClick={() => this.setState(prevState => ({
                                                ...prevState,
                                                selectedRoutineCollection: {id: null}
                                            }))}>
                                        <MdOutlineCancel/>
                                    </PromiseButton>
                                </td>
                            </>
                            :
                            <>
                                <td>
                                    <Link
                                            to={"/" + GlobalConstants.APP_PATH + "collections/" + routineCollection._embedded.collection.id}
                                            style={{
                                                fontStyle: "italic",
                                                color: "#333"
                                            }}>{routineCollection._embedded.collection.id}</Link>
                                </td>
                                <td>{routineCollection._embedded?.collection?.name ?
                                        routineCollection._embedded?.collection?.name : ""}</td>
                                <td>
                                    <PromiseButton className="form-btn-ci-red" type="button"
                                            onClick={() => this.deleteRoutineCollection(routineCollection)}>
                                        <FiTrash2/>
                                    </PromiseButton>
                                </td>
                            </>
                    }
                </tr>
        )
    }
}

export default withToast(RoutineDetails);