import {Button} from "react-bootstrap";
import React, {Component} from "react";
import {withToast} from "../../util/ToastService";
import {Link} from "react-router-dom";
import {
    GrAggregate,
} from "react-icons/all";
import GlobalConstants from "../../config/GlobalConstants";
import {CreateAggregator, GetAggregator, UpdateAggregator} from "./AggregatorService";
import {DetailsSection, RequiredFieldsAreValid} from "../../generators/DetailsGenerator";
import AggregatorMetaData from "./AggregatorMetaData";
import {PromiseButton} from "../../global/SpinningTiger";
import {ErrorHandler} from "../../util/ErrorHandler";

class AggregatorDetails extends Component {

    //------------
    //Constructor
    //------------

    constructor(props) {
        super(props);

        this.state = {
            error: null,
            flowState: props.location.state,
            id: this.props.match.params.id,
            editMode: {
                active: false,
                expertMode: false
            },
            aggregator: {},
            originalAggregator: {},
            sections: {
                general: true,
                aggregatorDetails: true,
            }
        }
    }

    //---------
    //Mounting
    //---------

    async componentDidMount() {
        await this.loadAggregator();
    }

    //--------
    //Loading
    //--------

    async loadAggregator() {
        let loadedAggregator = {};

        if(this.state.id != null && this.state.id !== "add") {

            loadedAggregator = await GetAggregator(this.state.id);

            if (!loadedAggregator.error) {
                const aggregator = loadedAggregator.result;

                this.setState({
                    id: this.state.id,
                    editMode: this.state.editMode,
                    aggregator: aggregator,
                    originalAggregator: aggregator,
                });
            } else {
                this.setState(prevState => ({...prevState, error: loadedAggregator}));
            }
        } else {
            this.setState((prevState) => ({...prevState,
                id: null,
                aggregator: {
                    id: null,
                    platformShareDeducted: true,
                    phase: "MEASURED"
                },
                editMode: {
                    active: true,
                    expertMode: false
                }}));
        }
    }

    //----------
    //Rendering
    //----------

    render() {

        return (
            <>
                <div className="details-title">
                    {this.state.aggregator.id != null ? "Details of Aggregator " + this.state.aggregator.id : "Create a new Aggregator"}
                </div>
                <div className="details-button-box" style={{height: "70px"}}>
                    {this.state.aggregator.id != null &&
                    <button className={this.state.editMode.active ? "form-btn-ci-red" : "form-btn-ci-blue"} type="button" onClick={() => this.setState({
                        editMode: {
                            active: !this.state.editMode.active,
                            expertMode: this.state.editMode.expertMode
                        },
                        aggregator: this.state.originalAggregator
                    })}>{this.state.editMode.active ? "Cancel" : "Edit"}</button>
                    }
                    {this.state.editMode.active &&
                        <PromiseButton text="Save" onClick={() => this.saveOrUpdateAggregator()} />
                    }
                    <Button style={{float: "right", marginTop: "5px"}} variant={this.state.editMode.expertMode ? "secondary": "outline-secondary"}
                            onClick={() => this.setState(prevState => ({
                                ...prevState,
                                editMode: {
                                    ...prevState.editMode,
                                    expertMode: !this.state.editMode.expertMode
                                }
                            }))}>Expert Mode</Button>
                </div>

                <div className="details-box-title">Resource Details</div>
                <div className="details-box">
                    {/* GENERAL DATA */}
                    <DetailsSection
                        nameInState="aggregator"
                        fields={AggregatorMetaData.DETAILS_GENERAL}
                        state={this.state}
                        onSetState={(s) => this.setState(s)}
                        sectionId="general"
                    />

                    {/* SECTION 1 */}
                    <DetailsSection
                        nameInState="aggregator"
                        fields={AggregatorMetaData.AGGREGATOR_DETAILS}
                        state={this.state}
                        onSetState={(s) => this.setState(s)}
                        sectionId="aggregatorDetails"
                        label={<span><GrAggregate/>&#xA0;Aggregator Details</span>}
                    />

                </div>
                <div className="details-button-box" style={{height: "70px"}}>
                    <Link to={{pathname: "/" + GlobalConstants.APP_PATH +"aggregators", state: this.state.flowState}}>
                        <button className="form-btn-ci-light-blue" type="button">Back</button>
                    </Link>
                    {this.state.editMode.active &&
                        <PromiseButton text="Save" onClick={() => this.saveOrUpdateAggregator()} />
                    }
                </div>

                <ErrorHandler error={this.state.error}
                              onHide={() => this.setState(prevState => ({...prevState, error: null}))} />
            </>
        );
    }

    //------------
    //API Methods
    //------------

    async saveOrUpdateAggregator() {
        if (!RequiredFieldsAreValid('aggregator', AggregatorMetaData.AGGREGATOR_DETAILS, this.state, (s) => this.setState(s))) {
            return;
        }

        let aggregator = {};
        if(this.state.aggregator.id != null) {
            aggregator = await UpdateAggregator(this.state.aggregator);
        } else {
            aggregator = await CreateAggregator(this.state.aggregator);
        }

        if (!aggregator.error) {
            this.setState((prevState) =>({...prevState, aggregator: aggregator.result, editMode: {...prevState.editMode, active: false}}));

            this.props.addToast("The aggregator has been updated successfully.", {
                autoDismiss: true,
                appearance: 'success'
            });
        } else {
            this.setState(prevState => ({...prevState, error: aggregator}));
        }
    }

}


export default withToast(AggregatorDetails);