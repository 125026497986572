import React, {Component} from "react";
import '../../../App.scss'

import "react-datepicker/dist/react-datepicker.css";

import background from "../../../assets/images/support_tool_bg.jpeg";
import {SpinningTiger} from "../../global/SpinningTiger";
import {TableSettingsDialog} from "../../generators/TableGenerator";
import {withToast} from "../../util/ToastService";
import {GetPurchaseFailurePage, GetTransactionPage, GetTransactionsByState} from "./TransactionService";
import TransactionMetaData from "./TransactionMetaData";
import {Badge, Button, ButtonGroup, Dropdown, Pagination, Tab, Table, Tabs} from "react-bootstrap";
import {FcAdvance, FcExpired, FiExternalLink} from "react-icons/all";
import GlobalConstants from "../../config/GlobalConstants";
import {Link} from "react-router-dom";
import {ErrorHandler} from "../../util/ErrorHandler";

class Transactions extends Component {

    //------------
    //Constructor
    //------------

    constructor(props) {
        super(props);

        this.state = {
            error: null,
            loading: false,

            //Transactions
            transactions: [],
            transactionFilter: {page: 0, per_page: 20},
            lastTransactionPage: 1,
            transactionState: null,

            //Purchase Failures
            purchaseFailures: [],
            purchaseFailureFilter: {page: 0, per_page: 20},
            lastPurchaseFailurePage: 1,

            //Appearance
            mainTab: "transactions",
            transactionColumns: TransactionMetaData.TRANSACTION_COLUMNS,
            showDialog: {
                tableSettings: false,
                deletionModal: false
            },

            //Processing
            isLoading: false,
        };

        this.updateFilter = this.updateFilter.bind(this);
        this.showOrHideDialog = this.showOrHideDialog.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
    }

    //---------
    //Mounting
    //---------

    async componentDidMount() {
        await this.loadTransactions(this.state.transactionFilter, 0);
    }

    //--------
    //Loading
    //--------

    async loadTransactions(filter, page) {

        this.setState(prevState => ({...prevState, loading: true}));

        filter.page = page;

        let loadedTransactions = {};
        if(this.state.transactionState == null) {
            loadedTransactions = await GetTransactionPage(page);
        } else {
            loadedTransactions = await GetTransactionsByState(this.state.transactionState, page);
        }

        if (loadedTransactions.error === true) {
            this.setState(prevState => ({...prevState, error: loadedTransactions}));
        } else if(Array.isArray(loadedTransactions.result)) {
            loadedTransactions.result.forEach(sub => {
                if (this.state.transactions.filter(ex => (ex.isChecked === true)).includes(sub)) {
                    sub.isChecked = true;
                }
            });
        } else {
            loadedTransactions.result = [];
        }

        this.setState(prevState => ({
            ...prevState,
            loading: false,
            transactions: loadedTransactions.result,
            transactionFilter: filter,
            lastTransactionPage: Math.ceil(loadedTransactions.length / this.state.transactionFilter.per_page)
        }));
    }

    async loadPurchaseFailures(filter, page) {

        this.setState(prevState => ({...prevState, loading: true}));

        filter.page = page;

        let loadedPurchaseFailures = await GetPurchaseFailurePage(page);

        if (loadedPurchaseFailures.error === true) {
            this.setState(prevState => ({...prevState, error: loadedPurchaseFailures}));
        } else if (Array.isArray(loadedPurchaseFailures.result)) {
            loadedPurchaseFailures.result.forEach(sub => {
                if (this.state.purchaseFailures.filter(ex => (ex.isChecked === true)).includes(sub)) {
                    sub.isChecked = true;
                }
            });
        } else {
            loadedPurchaseFailures.result = [];
        }

        this.setState(prevState => ({
            ...prevState,
            loading: false,
            purchaseFailures: loadedPurchaseFailures.result,
            purchaseFailureFilter: filter,
            lastPurchaseFailurePage: Math.ceil(loadedPurchaseFailures.length / this.state.purchaseFailureFilter.per_page)
        }));
    }

    //----------
    //Rendering
    //----------

    render() {

        return (
            <div style={{paddingTop: 30, backgroundImage: `url(${background})`, minHeight: "100vh", height: "auto"}}>
                <div className="new-content-header">
                    <div className="new-content-header-title">Transactions</div>
                </div>

                {/* SHOW THE PRODUCT LIST*/}
                <div className="support-tool-box">
                    <Tabs id="recommendation-main-tab" activeKey={this.state.mainTab}
                          onSelect={(k) => {
                              if(k === 'transactions') {
                                  this.loadTransactions({per_page: 20, page: 0}, 0).then(r => r);
                              } else {
                                  this.loadPurchaseFailures({per_page: 20, page: 0}, 0).then(r => r);
                              }
                              this.updateState('mainTab', k);
                          }}
                          className="mb-5">

                        <Tab eventKey="transactions"
                             title={<span style={{color: "#495057"}}><FcAdvance size={24}/> Transactions</span>}>
                            <Dropdown as={ButtonGroup} drop="up">
                                <Button style={{
                                    paddingTop: "8px",
                                    paddingBottom: "10px",
                                    marginBottom: "10px",
                                    height: "40px"
                                }}
                                        variant={this.state.transactionState === null ? "secondary" : "success"}
                                        onClick={() => this.loadTransactions({}, 0)}>State: {this.state.transactionState != null ? this.state.transactionState : "All"}</Button>
                                <Dropdown.Toggle style={{marginBottom: "10px"}} split
                                                 variant={this.state.transactionState === null ? "outline-secondary" : "outline-success"}/>
                                <Dropdown.Menu>
                                    <Dropdown.Item
                                        onClick={() => this.setState({transactionState: null})}>All</Dropdown.Item>
                                    <Dropdown.Divider />
                                    <Dropdown.Item
                                        onClick={() => this.setState({transactionState: 'CREATED'})}>Created</Dropdown.Item>
                                    <Dropdown.Item
                                        onClick={() => this.setState({transactionState: 'TEMPORARY_DISABLED'})}>Temporary Disabled</Dropdown.Item>
                                    <Dropdown.Item
                                        onClick={() => this.setState({transactionState: 'WRONG_PIN'})}>Wrong Pin</Dropdown.Item>
                                    <Dropdown.Item
                                        onClick={() => this.setState({transactionState: 'PREPARING'})}>Preparing</Dropdown.Item>
                                    <Dropdown.Item
                                        onClick={() => this.setState({transactionState: 'QUEUED'})}>Queued</Dropdown.Item>
                                    <Dropdown.Item
                                        onClick={() => this.setState({transactionState: 'SUBMITTED'})}>Submitted</Dropdown.Item>
                                    <Dropdown.Item
                                        onClick={() => this.setState({transactionState: 'POST_PROCESSING'})}>Post Processing</Dropdown.Item>
                                    <Dropdown.Item
                                        onClick={() => this.setState({transactionState: 'APPROVED'})}>Approved</Dropdown.Item>
                                    <Dropdown.Item
                                        onClick={() => this.setState({transactionState: 'SUCCESS'})}>Success</Dropdown.Item>
                                    <Dropdown.Item
                                        onClick={() => this.setState({transactionState: 'ERROR'})}>Error</Dropdown.Item>
                                    <Dropdown.Item
                                        onClick={() => this.setState({transactionState: 'DENIED'})}>Denied</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                            <hr/>

                            {this.state.loading ?
                                <SpinningTiger/>
                                :
                                <>
                                    <Table hover responsive bordered>
                                        <thead>
                                        <tr>
                                            <th><FiExternalLink size={24} /> ID</th>
                                            <th>State</th>
                                            <th>Current Client</th>
                                            <th>E-Mail</th>
                                            <th>Failures</th>
                                            <th>Blocked Until</th>
                                            <th><FiExternalLink size={24} /> Ticket ID</th>
                                            <th>Ticket Type</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {this.state.transactions.map(transaction => (
                                            <tr role="row" key={transaction.id}>
                                                <td style={{cursor: "pointer"}}>
                                                    <Link style={{color: "#333"}}  to={"/" + GlobalConstants.APP_PATH + "purchases/transactions/" + transaction.id}>{transaction.id}</Link>
                                                </td>
                                                <td><Badge bg={transaction.state === "BLOCKED" ? "danger" : (transaction.state === "WRONG_PIN" || transaction.state === "TEMPORARY_DISABLED" || transaction.state === "ERROR" ? "warning" : "success")}>{transaction.state.replace("_", " ")}</Badge></td>
                                                <td><Badge bg={transaction.currentClient  === "INTERN" ? "success" : (transaction.currentClient === "BILLWERK" ? "primary" : "danger")}>{transaction.currentClient}</Badge></td>
                                                <td>{transaction.accountEmail}</td>
                                                <td><Badge bg={transaction.failures > 0 ? "danger" : "success"}>{transaction.failures}</Badge></td>
                                                <td>{transaction.blockedUntil}</td>
                                                <td>
                                                    <Link style={{color: "#333"}}  to={transaction.premiumType === 'TIGERTICKET' ? "/" + GlobalConstants.APP_PATH + "purchases/tigertickets/" + transaction.ticketId : "/" + GlobalConstants.APP_PATH + "purchases/digitickets/" + transaction.ticketId}>{transaction.ticketId}</Link>
                                                </td>
                                                <td><Badge bg={transaction.premiumType === 'TIGERTICKET' ? 'danger' : (transaction.premiumType === 'DIGITICKET' ? 'primary' : (transaction.premiumType === 'SUBSCRIPTION' ? 'success' : 'warning'))}>{transaction.premiumType}</Badge></td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </Table>
                                    <Pagination>
                                        <Pagination.Item hidden={this.state.transactionFilter.page === 0}
                                                         onClick={() => this.loadTransactions({}, this.state.transactionFilter.page - 1)}>&lt;</Pagination.Item>
                                        <Pagination.Item active={true}>{this.state.transactionFilter.page + 1}</Pagination.Item>
                                        <Pagination.Item hidden={this.state.transactionFilter.page === this.state.lastTransactionPage - 1}
                                                         onClick={() => this.loadTransactions({}, this.state.transactionFilter.page + 1)}>&gt;</Pagination.Item>
                                    </Pagination>
                                </>
                            }

                        </Tab>

                        <Tab eventKey="purchaseFailures"
                             title={<span style={{color: "#495057"}}><FcExpired size={24}/>Purchase Failures</span>}>
                            <hr/>

                            {this.state.loading ?
                                <SpinningTiger/>
                                :
                                <>
                                    <Table hover responsive bordered>
                                        <thead>
                                        <tr>
                                            <th>ID</th>
                                            <th>Transaction ID</th>
                                            <th>Reason</th>
                                            <th>Type</th>
                                            <th>Error Text</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {this.state.purchaseFailures.map(failure => (
                                            <tr role="row" key={failure.id}>
                                                <td>{failure.id}</td>
                                                <td>{failure.transactionId}</td>
                                                <td>{failure.reason}</td>
                                                <td><Badge bg={failure.type === "TECHNICAL" ? "primary" : "danger"}>{failure.type}</Badge></td>
                                                <td>{failure.errorText}</td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </Table>
                                    <Pagination>
                                        <Pagination.Item hidden={this.state.purchaseFailureFilter.page === 0}
                                                         onClick={() => this.loadPurchaseFailures({}, this.state.purchaseFailureFilter.page - 1)}>&lt;</Pagination.Item>
                                        <Pagination.Item active={true}>{this.state.purchaseFailureFilter.page + 1}</Pagination.Item>
                                        <Pagination.Item hidden={this.state.purchaseFailureFilter.page === this.state.lastPurchaseFailurePage - 1}
                                                         onClick={() => this.loadPurchaseFailures({}, this.state.purchaseFailureFilter.page + 1)}>&gt;</Pagination.Item>
                                    </Pagination>
                                </>
                            }

                        </Tab>

                    </Tabs>

                    <hr/>

                </div>

                {/* TABLE SETTINGS */}
                <TableSettingsDialog show={this.state.showDialog.tableSettings}
                                     columns={this.state.transactionColumns}
                                     onSetState={(columns) => this.setState(prevState => ({
                                         ...prevState,
                                         transactionColumns: columns
                                     }))}
                                     onHide={() => this.showOrHideDialog('tableSettings', false)}
                />

                <ErrorHandler error={this.state.error}
                              onHide={() => this.setState(prevState => ({...prevState, error: null}))} />
            </div>
        );
    }

    //----------------
    // API Methods
    //----------------


    //----------------
    // STATE HANDLING
    //----------------

    updateFilter(id, value) {
        //Get the state and the filter
        let state = this.state;
        let filter = state.filter;

        //Update the attribute
        filter[id] = value;

        //Set the state
        this.setState(prevState => ({...prevState, filter: filter}));
    }

    updateState(param, value) {
        let state = this.state;
        state[param] = value;
        this.setState(state);
    }

    showOrHideDialog(dialogId, show) {
        //Get the state and the dialog
        let state = this.state;
        let dialogs = state.showDialog;

        //Update the visibility
        dialogs[dialogId] = show;

        //Set the state
        this.setState(prevState => ({...prevState, showDialog: dialogs}));
    }
}

export default withToast(Transactions);