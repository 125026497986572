
//API METHODS

import {GetFilteredRestResource, GetRestResource, UpdateRestResource} from "../../clients/RestResourceClient";

export async function GetOrderedBundles(filter){
    return await GetFilteredRestResource('api/purchases/ordered-bundles', filter);
}

export async function GetOrderedBundle(id){
    return await GetRestResource('api/purchases/ordered-bundles/' + id);
}

export async function UpdateOrderedBundle(orderedBundle) {
    return await UpdateRestResource(orderedBundle._links.self.href, orderedBundle, true);
}

