import {Form, Col} from "react-bootstrap";
import {useState} from "react";
import FormSelect from "react-bootstrap/FormSelect";
import Row from 'react-bootstrap/Row';
import {Label} from "reactstrap";
import ProductMetaData from "./ProductMetaData";
import {BsImage} from "react-icons/all";

export function AddOrEditAssetProductDialog(props) {

    let [assetProduct, setAssetProduct] = useState(props.assetProduct);
    let [asset, setAsset] = useState(props.asset);

    return(
        <div>
            <Form>
                <Row>
                    <Form.Group as={Col} controlId="assetProductId">
                        <Label>Asset Product ID</Label>
                        <Form.Control type="number" value={assetProduct.id} readOnly={true}/>
                    </Form.Group>
                </Row>
                <Row>
                    <Form.Group as={Col} controlId="ordinal">
                        <Label>Ordinal</Label>
                        <Form.Control type="number" value={assetProduct.ordinal} onChange={(e) => {
                            setAssetProduct(prevAssetProduct => ({...prevAssetProduct, ordinal: e.target.value}));
                            assetProduct.ordinal = e.target.value;
                            props.onUpdateAssetProduct(assetProduct);
                        }}/>
                    </Form.Group>
                    <Form.Group as={Col} controlId="assetProductType">
                        <Label>Type</Label>
                        <FormSelect value={assetProduct.assetProductType} onChange={(e) => {
                            setAssetProduct(prevAssetProduct => ({...prevAssetProduct, assetProductType: e.target.value}));
                            assetProduct.assetProductType = e.target.value;
                            props.onUpdateAssetProduct(assetProduct);
                        }}>
                            {ProductMetaData.ASSET_PRODUCT_TYPES.map(type => (
                                <option>{type}</option>
                            ))}
                        </FormSelect>
                    </Form.Group>
                </Row>
                <hr />
                <h4><BsImage />&#xA0;Asset</h4>
                <hr />
                    <Row>
                        <Form.Group as={Col} controlId="assetId">
                            <Label>Asset ID</Label>
                            <Form.Control type="number" value={asset.id} readOnly={true}/>
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group as={Col} controlId="name">
                            <Label>Name</Label>
                            <Form.Control value={asset.name} disabled={asset.id != null} onChange={(e) => {
                                setAsset(prevAsset => ({...prevAsset, name: e.target.value}));
                                asset.name = e.target.value;
                                props.onUpdateAsset(asset);
                            }}/>
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group as={Col} controlId="type">
                            <Label>Asset Type</Label>
                            <FormSelect value={asset.assetType} disabled={asset.id != null} onChange={(e) => {
                                setAsset(prevAsset => ({...prevAsset, assetType: e.target.value}));
                                asset.assetType = e.target.value;
                                props.onUpdateAsset(asset);
                            }}>
                                {ProductMetaData.ASSET_TYPES.map(assetType => (
                                    <option>{assetType}</option>
                                ))}
                            </FormSelect>
                        </Form.Group>
                        <Form.Group as={Col} controlId="visibility">
                            <Label>Visibility</Label>
                            <FormSelect value={asset.visibility} disabled={asset.id != null} onChange={(e) => {
                                setAsset(prevAsset => ({...prevAsset, visibility: e.target.value}));
                                asset.visibility = e.target.value;
                                props.onUpdateAsset(asset);
                            }}>
                                {ProductMetaData.ASSET_VISIBILITY.map(visibility => (
                                    <option>{visibility}</option>
                                ))}
                            </FormSelect>
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group as={Col} controlId="upload">
                            <Label>Upload</Label>
                            <Form.Control type="file" onChange={(e) => {
                                setAsset(prevAsset => ({...prevAsset, file: e.target.files[0]}));
                                asset.file = e.target.files[0];
                                props.onUpdateAsset(asset);
                            }}/>
                        </Form.Group>
                    </Row>
            </Form>
        </div>
    )
}