import React, {useState} from "react";
import {Badge, Button, ButtonGroup, Pagination, Table} from "react-bootstrap";
import GlobalConstants from "../../config/GlobalConstants";
import {Link} from "react-router-dom";
import {FiTrash2, IoSettingsOutline, IoSettingsSharp} from "react-icons/all";
import {DeleteSubscription, GetFilteredSubscriptions} from "../subscriptions/SubscriptionService";
import {ErrorHandler} from "../../util/ErrorHandler";

export function AccountSubscriptions(props) {
    let [subscriptions, setSubscriptions] = useState(props.subscriptions);
    let [error, setError] = useState(null);

    //Settings
    let [showTableSettings, setShowTableSettings] = useState(false);

    //Columns
    let [showVersion, setShowVersion] = useState(false);
    let [showArticleId, setShowArticleId] = useState(true);
    let [showPurchaseTime, setShowPurchaseTime] = useState(false);
    let [showValidFrom, setShowValidFrom] = useState(true);
    let [showValidUntil, setShowValidUntil] = useState(true);
    let [showOrigValidFrom, setShowOrigValidFrom] = useState(false);
    let [showOrigValidUntil, setShowOrigValidUntil] = useState(false);
    let [showActive, setShowActive] = useState(true);
    let [showNote, setShowNote] = useState(true);
    let [showSource, setShowSource] = useState(true);
    let [showSlots, setShowSlots] = useState(false);
    let [showAccountId, setShowAccountId] = useState(false);
    let [showProductGroupId, setShowProductGroupId] = useState(true);
    let [showOrderId, setShowOrderId] = useState(false);
    let [showRenewable, setShowRenewable] = useState(false);

    async function deleteSubscription(subscription) {
        let response = await DeleteSubscription({id: props.accountId}, subscription);

        if(!response.error) {
            setSubscriptions(subscriptions.filter(sub => sub.id !== subscription.id));
        } else {
            setError(response);
        }
    }

    async function loadSubscriptions(page) {
        let loadedSubscriptions = await GetFilteredSubscriptions({id: props.accountId}, {page: page, active_only: false});

        if (!loadedSubscriptions.error) {
            let subscriptions = loadedSubscriptions.result;
            setSubscriptions(subscriptions);
            props.onLoadPage(page);
        } else {
            setError(loadedSubscriptions);
        }
    }

    return(
        <>
            <Link to={"/" + GlobalConstants.APP_PATH + "accounts/" + props.accountId + "/subscriptions/add"}><button className="form-btn-ci-blue" type="button">New Subscription</button></Link>
            <h2 style={{float: "right", cursor: "pointer", fontSize: "20px"}} onClick={() => setShowTableSettings(!showTableSettings)}>
              <Button variant="secondary">{showTableSettings ? <IoSettingsSharp /> : <IoSettingsOutline />}</Button>
            </h2>
            {showTableSettings &&
                <p style={{marginTop: "50px"}}>
                    <hr />
                    <ButtonGroup>
                        <Button variant={showVersion ? "secondary" : "outline-secondary"} onClick={() => setShowVersion(!showVersion)}>Version</Button>
                        <Button variant={showArticleId ? "secondary" : "outline-secondary"} onClick={() => setShowArticleId(!showArticleId)}>Article ID</Button>
                        <Button variant={showPurchaseTime ? "secondary" : "outline-secondary"} onClick={() => setShowPurchaseTime(!showPurchaseTime)}>Purchase Time</Button>
                        <Button variant={showValidFrom ? "secondary" : "outline-secondary"} onClick={() => setShowValidFrom(!showValidFrom)}>Valid From</Button>
                        <Button variant={showValidUntil ? "secondary" : "outline-secondary"} onClick={() => setShowValidUntil(!showValidUntil)}>Valid Until</Button>
                        <Button variant={showOrigValidFrom ? "secondary" : "outline-secondary"} onClick={() => setShowOrigValidFrom(!showOrigValidFrom)}>Original Valid From</Button>
                        <Button variant={showOrigValidUntil ? "secondary" : "outline-secondary"} onClick={() => setShowOrigValidUntil(!showOrigValidUntil)}>Original Valid Until</Button>
                        <Button variant={showActive ? "secondary" : "outline-secondary"} onClick={() => setShowActive(!showActive)}>Active</Button>
                        <Button variant={showNote ? "secondary" : "outline-secondary"} onClick={() => setShowNote(!showNote)}>Note</Button>
                        <Button variant={showSource ? "secondary" : "outline-secondary"} onClick={() => setShowSource(!showSource)}>Source</Button>
                        <Button variant={showSlots ? "secondary" : "outline-secondary"} onClick={() => setShowSlots(!showSlots)}>Slots</Button>
                        <Button variant={showAccountId ? "secondary" : "outline-secondary"} onClick={() => setShowAccountId(!showAccountId)}>Account ID</Button>
                        <Button variant={showProductGroupId ? "secondary" : "outline-secondary"} onClick={() => setShowProductGroupId(!showProductGroupId)}>Product Group</Button>
                        <Button variant={showOrderId ? "secondary" : "outline-secondary"} onClick={() => setShowOrderId(!showOrderId)}>Order</Button>
                        <Button variant={showRenewable ? "secondary" : "outline-secondary"} onClick={() => setShowRenewable(!showRenewable)}>Renewable</Button>
                    </ButtonGroup>
                </p>
            }
            <Table bordered hover responsive striped>
                <thead>
                    <tr>
                        <th>ID</th>
                        {showVersion &&
                            <th>Version</th>
                        }
                        {showArticleId &&
                            <th>Article ID</th>
                        }
                        {showPurchaseTime &&
                            <th>Purchase Time</th>
                        }
                        {showValidFrom &&
                            <th>Valid From</th>
                        }
                        {showValidUntil &&
                            <th>Valid Until</th>
                        }
                        {showOrigValidFrom &&
                            <th>Original Valid From</th>
                        }
                        {showOrigValidUntil &&
                            <th>Original Valid Until</th>
                        }
                        {showActive &&
                            <th>Active</th>
                        }
                        {showNote &&
                            <th width={500}>Note</th>
                        }
                        {showSource &&
                            <th>Source</th>
                        }
                        {showSlots &&
                            <th>Slots</th>
                        }
                        {showAccountId &&
                            <th>Account ID</th>
                        }
                        {showProductGroupId &&
                            <th>Product Group</th>
                        }
                        {showOrderId &&
                            <th>Order ID</th>
                        }
                        {showRenewable &&
                            <th>Renewable</th>
                        }
                        <th>Remove</th>
                    </tr>
                </thead>
                <tbody>
                {subscriptions.map(subscription => (
                    <tr role="row" key={subscription.id}>
                        <td><Link style={{fontStyle: "italic", color: "#333"}} to={"/" + GlobalConstants.APP_PATH + "accounts/" + subscription.accountId + "/subscriptions/" + subscription.id}>{subscription.id}</Link></td>
                        {showVersion &&
                            <td>{subscription.version}</td>
                        }
                        {showArticleId &&
                            <td>{subscription.articleId}</td>
                        }
                        {showPurchaseTime &&
                            <td>{subscription.purchaseTime}</td>
                        }
                        {showValidFrom &&
                            <td>{subscription.validFrom}</td>
                        }
                        {showValidUntil &&
                            <td>{subscription.validUntil}</td>
                        }
                        {showOrigValidFrom &&
                            <td>{subscription.origValidFrom}</td>
                        }
                        {showOrigValidUntil &&
                            <td>{subscription.origValidUntil}</td>
                        }
                        {showActive &&
                            <td><Badge bg={subscription.active ? "success" : "danger"}>{subscription.active ? "Y" : "N"}</Badge></td>
                        }
                        {showNote &&
                            <td>{subscription.note}</td>
                        }
                        {showSource &&
                            <td>{subscription.source}</td>
                        }
                        {showSlots &&
                            <td>{subscription.slots}</td>
                        }
                        {showAccountId &&
                            <td><Link style={{fontStyle: "italic", color: "#333"}} to={"/" + GlobalConstants.APP_PATH + "accounts/" + subscription.accountId}>{subscription.accountId}</Link></td>
                        }
                        {showProductGroupId &&
                            <td><Link style={{fontStyle: "italic", color: "#333"}} to={"/" + GlobalConstants.APP_PATH + "productGroups/" + subscription.productGroupId}>{subscription.productGroupId}</Link></td>
                        }
                        {showOrderId &&
                            <td><Link style={{fontStyle: "italic", color: "#333"}} to={"/" + GlobalConstants.APP_PATH + "orders/" + subscription.orderId}>{subscription.orderId}</Link></td>
                        }
                        {showRenewable &&
                            <td><Badge bg={subscription.renewable ? "success" : "danger"}>{subscription.renewable ? "Y" : "N"}</Badge></td>
                        }
                        <td><button className="form-btn-ci-red" onClick={() => deleteSubscription(subscription)}><FiTrash2 /></button></td>
                    </tr>
                ))}
                </tbody>
            </Table>
            <Pagination>
              <Pagination.Item hidden={props.page === 1}
                            onClick={() => loadSubscriptions(1)}>&lt;&lt;</Pagination.Item>
              <Pagination.Item hidden={props.page === 1}
                            onClick={() => loadSubscriptions(props.page - 1)}>&lt;</Pagination.Item>
              <Pagination.Item hidden={props.page <= 2}
                            onClick={() => loadSubscriptions(props.page - 2)}>{props.page - 2}</Pagination.Item>
              <Pagination.Item hidden={props.page === 1}
                            onClick={() => loadSubscriptions(props.page - 1)}>{props.page - 1}</Pagination.Item>
              <Pagination.Item active={true}>{props.page}</Pagination.Item>
              <Pagination.Item hidden={props.page === props.lastPage}
                            onClick={() => loadSubscriptions(props.page + 1)}>{props.page + 1}</Pagination.Item>
              <Pagination.Item hidden={props.page >= props.lastPage - 1}
                  onClick={() =>loadSubscriptions(props.page + 2)}>{props.page + 2}</Pagination.Item>
              <Pagination.Item hidden={props.page === props.lastPage}
                  onClick={() => loadSubscriptions(props.page + 1)}>&gt;</Pagination.Item>
            </Pagination>
            <ErrorHandler error={error}
                          onHide={() => setError(null)} />
      </>
    );
}