import {CreateSpineResource, DeleteSpineResource, GetFilteredSpineResources, GetSpineResource, UpdateSpineResource} from "../../clients/SpineClient";

export async function GetFilteredTags(filter){
    return await GetFilteredSpineResources('api/tags', filter);
}

export async function CreateTag(example) {
    return await CreateSpineResource('api/tags', example);
}

export async function GetTag(id) {
    return await GetSpineResource('api/tags/' + id);
}

export async function UpdateTag(tag) {
    return await UpdateSpineResource('api/tags/' + tag.id, tag);
}

export async function DeleteTag(tag) {
    return await DeleteSpineResource('api/tags/' + tag.id);
}