import {Button} from "react-bootstrap";
import React, {Component} from "react";
import {withToast} from "../../util/ToastService";
import {Link} from "react-router-dom";
import GlobalConstants from "../../config/GlobalConstants";
import {CreateNewsletter, GetNewsletter, GetSubscribersOfNewsletter, UpdateNewsletter} from "./NewsletterService";
import {DetailsSection, RequiredFieldsAreValid} from "../../generators/DetailsGenerator";
import NewsletterMetaData from "./NewsletterMetaData";
import {NewsletterSubscribersList} from "./subscribers/NewsletterSubscribersList";
import {IoPeopleOutline} from "react-icons/all";
import {PromiseButton} from "../../global/SpinningTiger";
import {ErrorHandler} from "../../util/ErrorHandler";

class NewsletterDetails extends Component {

    //------------
    //Constructor
    //------------

    constructor(props) {
        super(props);

        this.state = {
            error: null,
            flowState: props.location.state,
            id: this.props.match.params.id,
            editMode: {
                active: false,
                expertMode: false
            },
            newsletter: {
                name: "",
                displayName: "",
                description: "",
                mailjetListId: 0,
                active: true
            },
            originalNewsletter: {},
            sections: {
                general: true,
                section1: true,
            },
            showSubscribers: false,
            validationErrors: []
        }
    }

    //---------
    //Mounting
    //---------

    async componentDidMount() {
        await this.loadNewsletter();
    }

    //--------
    //Loading
    //--------

    async loadNewsletter() {
        let loadedNewsletter = {};

        if(this.state.id != null && this.state.id !== "add") {

            loadedNewsletter = await GetNewsletter(this.state.id);

            if (!loadedNewsletter.error) {
                const newsletter = loadedNewsletter.result;

                this.setState({
                    id: this.state.id,
                    editMode: this.state.editMode,
                    newsletter: newsletter,
                    originalNewsletter: newsletter,
                });
            } else {
                this.setState(prevState => ({...prevState, error: loadedNewsletter}));
            }
        } else {
            this.setState((prevState) => ({...prevState,
                id: null,
                newsletter: {
                    id: null,
                    name: "",
                    displayName: "",
                    description: "",
                    mailjetListId: 0,
                    active: true
                },
                editMode: {
                    active: true,
                    expertMode: false
                }}));
        }
    }

    //----------
    //Rendering
    //----------

    render() {

        if(this.state.showSubscribers) {
            return (<NewsletterSubscribersList
                subscribers={this.state.subscribers}
                newsletter={this.state.newsletter}
                newsletterList={[]}
                onHideDialog={() => this.setState(prevState => ({...prevState, showSubscribers: false}))}
            />);
        }

        return (
            <>
                <div className="details-title">
                    {this.state.newsletter.id != null ? "Details of Newsletter " + this.state.newsletter.id : "Create a new Newsletter"}
                </div>
                <div className="details-button-box" style={{height: "70px"}}>
                    <Link to={{pathname: "/" + GlobalConstants.APP_PATH +"newsletters", state: this.state.flowState}}>
                        <button className="form-btn-ci-light-blue" type="button">Back</button>
                    </Link>
                    {this.state.newsletter.id != null &&
                    <button className={this.state.editMode.active ? "form-btn-ci-red" : "form-btn-ci-blue"} type="button" onClick={() => this.setState({
                        editMode: {
                            active: !this.state.editMode.active,
                            expertMode: this.state.editMode.expertMode
                        },
                        newsletter: this.state.originalNewsletter
                    })}>{this.state.editMode.active ? "Cancel" : "Edit"}</button>
                    }
                    {this.state.editMode.active &&
                    <PromiseButton text="Save" onClick={() => this.saveOrUpdateNewsletter()} />
                    }
                    <button className="form-btn-ci-light" style={{marginLeft: "30px"}} type="button"
                            onClick={() => this.loadSubscribersOfSelectedNewsletter().then(r => {
                                if(!r.error) {
                                    const subRes = r.result;
                                    this.setState(prevState => ({
                                        ...prevState,
                                        subscribers: subRes,
                                        showSubscribers: true
                                    }));
                                }
                            })}><IoPeopleOutline size={22} /> Show Subscribers</button>
                    <Button style={{float: "right", marginTop: "5px"}} variant={this.state.editMode.expertMode ? "secondary": "outline-secondary"}
                            onClick={() => this.setState(prevState => ({
                                ...prevState,
                                editMode: {
                                    ...prevState.editMode,
                                    expertMode: !this.state.editMode.expertMode
                                }
                            }))}>Expert Mode</Button>
                </div>

                <div className="details-box-title">Resource Details</div>
                <div className="details-box">
                    {/* GENERAL DATA */}
                    <DetailsSection
                        nameInState="newsletter"
                        fields={NewsletterMetaData.DETAILS_GENERAL}
                        state={this.state}
                        onSetState={(s) => this.setState(s)}
                        sectionId="general"
                    />

                </div>
                <div className="details-button-box" style={{height: "70px"}}>
                    <Link to={{pathname: "/" + GlobalConstants.APP_PATH +"newsletters", state: this.state.flowState}}>
                        <button className="form-btn-ci-light-blue" type="button">Back</button>
                    </Link>
                    {this.state.editMode.active &&
                    <PromiseButton text="Save" onClick={() => this.saveOrUpdateNewsletter()} />
                    }
                </div>

                <ErrorHandler error={this.state.error}
                              onHide={() => this.setState(prevState => ({...prevState, error: null}))} />
            </>
        );
    }

    //------------
    //API Methods
    //------------

    async loadSubscribersOfSelectedNewsletter() {
        let subscribers = await GetSubscribersOfNewsletter(this.state.newsletter,{});

        if(subscribers.error) {
            this.setState(prevState => ({...prevState, error: subscribers}));
        }

        return subscribers;
    }

    async saveOrUpdateNewsletter() {
        if (!RequiredFieldsAreValid("newsletter", NewsletterDetails.DETAILS_GENERAL, this.state, (s) => this.setState(s))) {
            return;
        }
        let newsletter = {};
        if(this.state.newsletter.id != null) {
            newsletter = await UpdateNewsletter(this.state.newsletter);
        } else {
            newsletter = await CreateNewsletter(this.state.newsletter);
        }

        if (!newsletter.error) {
            if (this.state.newsletter.id == null) {
                this.props.history.push("/"+ GlobalConstants.APP_PATH + "newsletters/" + newsletter.result.id);
            }
            this.setState((prevState) =>({...prevState, newsletter: newsletter.result, editMode: {...prevState.editMode, active: false}}));

            this.props.addToast("The newsletter has been updated successfully.", {
                autoDismiss: true,
                appearance: 'success'
            });
        } else {
            this.setState(prevState => ({...prevState, error: newsletter}));
        }
    }

}


export default withToast(NewsletterDetails);