import React, {Component} from "react";
import '../../../App.scss'
import {withToast} from "../../util/ToastService";
import {
    FiSettings,
    GrObjectGroup
} from "react-icons/all";
import {ActionModal} from "../../generators/ModalGenerator";
import {ResourcesTable, TableHeader, TableSettingsDialog} from "../../generators/TableGenerator";
import "react-datepicker/dist/react-datepicker.css";
import OrderMetaData from "./OrderMetaData";
import {GetFilteredOrders} from "./OrderService";
import {Button, ButtonGroup, Dropdown} from "react-bootstrap";
import {SpinningTiger} from "../../global/SpinningTiger";
import {ErrorHandler} from "../../util/ErrorHandler";

class Orders extends Component {

    //------------
    //Constructor
    //------------

    constructor(props) {
        super(props);

        this.state = {
            error: null,
            loading: false,
            orders: [],
            filter: props.location.state != null ? props.location.state.filter :
                {page: 1, state: "", accountId: "", appInstId: "", per_page: 20, sortBy: "id", sortDirection: "ASC"},
            lastPage: props.location.state != null ? props.location.state.lastPage : 0,
            columns: props.location.state != null ? props.location.state.columns : OrderMetaData.COLUMNS,
            customColumnData: [],
            showDialog: {
                tableSettings: false,
                deletionModal: false,
            },
        };

        this.resetFilter = this.resetFilter.bind(this);
        this.updateFilter = this.updateFilter.bind(this);
        this.showOrHideDialog = this.showOrHideDialog.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.loadOrderList = this.loadOrderList.bind(this);
    }

    //---------
    //Mounting
    //---------

    async componentDidMount() {
        //Set the title
        document.title = "Orders :: Tiger UI";

        await this.loadOrderList(this.state.filter);
    }

    //--------
    //Loading
    //--------

    async loadOrderList(filter) {

        this.setState(prevState => ({...prevState, loading: true}));

        const loadedOrders = await GetFilteredOrders(filter);

        if (loadedOrders.error === true) {
            this.setState(prevState => ({...prevState, error: loadedOrders}));
        } else {
            loadedOrders.result.forEach(order => {
                if (this.state.orders.filter(ex => (ex.isChecked === true)).includes(order)) {
                    order.isChecked = true;
                }
            });
        }

        this.setState(prevState => ({
            ...prevState,
            loading: false,
            orders: loadedOrders.result,
            lastPage: Math.ceil(loadedOrders.length/this.state.filter.per_page),
            filter: filter
        }));

        this.loading = false;

    }

    async applyFilter(e, filter) {
        e.preventDefault();
        await this.loadOrderList(filter);
    }

    //----------
    //Rendering
    //----------

    render() {
        return (
            <div style={{marginBottom: 50}}>
                <div className="content-header">
                    <div className="content-header-title">Orders</div>
                    <hr/>
                    <div className="content-header-description"><GrObjectGroup/>&#xA0;List and view orders and their details
                    </div>
                </div>

                {/* FILTER */}
                <div className="content-box-centered">
                    <div className="content-box-title">Filter</div>
                    <div className="content-box-body">
                        <form onSubmit={(e) => this.applyFilter(e, this.state.filter)}>
                            <div className="row">
                                <div className="col-md-3">
                                    <label>Account ID</label>
                                    <input type="text" value={this.state.filter.accountId}
                                           onChange={(e) => this.updateFilter('accountId', e.target.value)}/>
                                </div>
                                <div className="col-md-3">
                                    <label>Application Instance ID</label>
                                    <input type="text" value={this.state.filter.appInstId}
                                           onChange={(e) => this.updateFilter('appInstId', e.target.value)}/>
                                </div>
                                <div className="col-md-2">
                                    <label>State</label>
                                    <br />
                                    <Dropdown as={ButtonGroup} drop="up">
                                        <Button style={{paddingTop: "10px", paddingBottom: "10px", marginBottom: "10px"}}
                                                variant={this.state.filter.state === '' ? "dark" : this.state.filter.state === 'PENDING' ? "secondary" : this.state.filter.state === 'PROCESSING' ? "primary" : this.state.filter.state === 'FAILED' ? "danger": this.state.filter.state === 'ASSIGNING' ? "warning": "success"}>
                                            {this.state.filter.state === "" ? "All" : this.state.filter.state}</Button>
                                        <Dropdown.Toggle style={{marginBottom: "10px"}} split
                                                         variant={this.state.filter.state === '' ? "outline-dark" : this.state.filter.state === 'PENDING' ? "outline-secondary" : this.state.filter.state === 'PROCESSING' ? "outline-primary" : this.state.filter.state === 'FAILED' ? "outline-danger": this.state.filter.state === 'ASSIGNING' ? "outline-warning": "outline-success"}/>
                                        <Dropdown.Menu>
                                            <Dropdown.Item
                                                onClick={() => this.updateFilter('state', "")}>All</Dropdown.Item>
                                            <Dropdown.Item
                                                onClick={() => this.updateFilter('state', "PENDING")}>Pending</Dropdown.Item>
                                            <Dropdown.Item
                                                onClick={() => this.updateFilter('state', "PROCESSING")}>Processing</Dropdown.Item>
                                            <Dropdown.Item
                                                onClick={() => this.updateFilter('state', "FAILED")}>Failed</Dropdown.Item>
                                            <Dropdown.Item
                                                onClick={() => this.updateFilter('state', "ASSIGNING")}>Assigning</Dropdown.Item>
                                            <Dropdown.Item
                                                onClick={() => this.updateFilter('state', "COMPLETED")}>Completed</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </div>
                            <hr/>
                            <div className="row">
                                <div className="col-md-3" style={{marginLeft: "auto"}}>
                                    <button className="form-btn-ci-light" type="button"
                                            onClick={() => this.resetFilter()}>Reset
                                    </button>
                                    <button className="form-btn-ci-blue" type="submit" onClick={(e) => this.applyFilter(e, this.state.filter)}>Apply Filter</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                {/* SHOW THE PRODUCT LIST*/}
                <div className="content-box-centered">
                    <div className="content-box-title">Orders List
                        <div style={{float: "right", fontWeight: "normal", cursor: "pointer"}}
                             onClick={() => this.showOrHideDialog('tableSettings', true)}><FiSettings/></div>
                    </div>
                    <div className="content-box-body">
                        <TableHeader nameInState="orders"
                                     viewOnly={true}
                                     deletionForbidden={true}
                                     sortParams={OrderMetaData.ORDER_FAILURE_LOG_SORT_PARAMS}
                                     state={this.state}
                                     onSetState={(s) => this.setState(s)}
                                     onLoadPage={() => this.loadOrderList(this.state.filter)}/>
                        <hr/>
                        {this.state.loading ?
                            <SpinningTiger />
                        :
                            <ResourcesTable
                                state={this.state}
                                columns={this.state.columns}
                                customcolumndata={this.state.customColumnData}
                                dataset={this.state.orders}
                                page={this.state.filter.page}
                                lastPage={this.state.lastPage}
                                onLoadPage={(page) => this.loadOrderList({...this.state.filter, page: page})}
                                selectable={true}
                                resourcesurl='orders'
                                nameInState='orders'
                                onToggleResource={(allOrders) => this.setState(prevState => ({
                                    ...prevState,
                                    orders: allOrders
                                }))}/>
                        }
                    </div>
                </div>

                {/* TABLE SETTINGS */}
                <TableSettingsDialog show={this.state.showDialog.tableSettings}
                                     columns={this.state.columns}
                                     onSetState={(columns) => this.setState(prevState => ({
                                         ...prevState,
                                         columns: columns
                                     }))}
                                     onHide={() =>this.showOrHideDialog('tableSettings', false)}
                />

                {/* DIALOGS */}
                <ActionModal show={this.state.showDialog.deletionModal}
                             onHide={() => this.showOrHideDialog('deletionModal', false)}
                             onAction={() => this.deleteSelectedOrders()}
                             actionButtonText="Delete"
                             title={"Delete orders"}
                             body={"Are you sure you want to delete the selected orders?"}/>
                <ErrorHandler error={this.state.error}
                              onHide={() => this.setState(prevState => ({...prevState, error: null}))} />
            </div>
        );
    }

    //--------
    //ACTIONS
    //--------

    async deleteSelectedOrders() {
        this.state.orders
            .filter(order => order.isChecked === true);

        await this.loadOrderList(this.state.filter);

        this.showOrHideDialog('deletionModal', false);
    }

    //----------------
    // STATE HANDLING
    //----------------

    updateFilter(id, value) {
        //Get the state and the filter
        let state = this.state;
        let filter = state.filter;

        //Update the attribute
        filter[id] = value;

        //Set the state
        this.setState(prevState => ({...prevState, filter: filter}));
    }

    showOrHideDialog(dialogId, show) {
        //Get the state and the dialog
        let state = this.state;
        let dialogs = state.showDialog;

        //Update the visibility
        dialogs[dialogId] = show;

        //Set the state
        this.setState(prevState => ({...prevState, showDialog: dialogs}));
    }

    async resetFilter(){
        let filter = {page: 1, state: "", accountId: "", appInstId: "", per_page: 20, sortBy: "id", sortDirection: "ASC"};
        this.setState(prevState =>({
            ...prevState,
            filter:filter
        }));
        await this.loadOrderList(filter);
    }

}

export default withToast(Orders);