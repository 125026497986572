import {Badge, Button, FormControl, InputGroup, Pagination, Table} from "react-bootstrap";
import React, {Component} from "react";
import {withToast} from "../../util/ToastService";
import {Link} from "react-router-dom";
import {
    BiSitemap,
    BiTrash,
    FaBook,
    FaRegEye,
    FiThumbsUp,
    GiTigerHead,
    GiTrashCan,
    GrObjectUngroup,
    MdAudiotrack,
    RiLayoutMasonryLine,
    RiMovie2Line,
} from "react-icons/all";
import GlobalConstants from "../../config/GlobalConstants";
import {CreateProductGroup, GetProductGroup, UpdateProductGroup} from "./ProductGroupService";
import {DetailsSection} from "../../generators/DetailsGenerator";
import ProductGroupMetaData from "./ProductGroupMetaData";
import {GetFilteredShopLayouts, GetShopLayout} from "../shopLayout/ShopLayoutService";
import {InfoModal} from "../../generators/ModalGenerator";
import {LayoutItemsDialog} from "./LayoutItemsDialog";
import {AddProductToProductGroup, GetFilteredProducts, RemoveProductFromProductGroup} from "../products/ProductService";
import {PromiseButton} from "../../global/SpinningTiger";
import {ErrorHandler} from "../../util/ErrorHandler";

class ProductGroupDetails extends Component {

    //------------
    //Constructor
    //------------

    constructor(props) {
        super(props);

        this.state = {
            error: null,
            flowState: props.location.state,
            id: this.props.match.params.id,
            editMode: {
                active: false,
                expertMode: false,
                autoSave: true,
            },
            productGroup: {
                shopLayouts: {
                    OVERVIEW: [],
                    BOOKS: [],
                    MOVIES: [],
                    AUDIO_BOOKS: [],
                    TIGERBOOKS: [],
                    SUPERBOOKS: []
                }
            },
            originalProductGroup: {},
            shopLayouts: {
                all: [],
                overview: [],
                books: [],
                movies: [],
                audiobooks: [],
                tigerbooks: [],
                superbooks: [],
            },
            products: [],
            productsPage: 1,
            lastProductsPage: 1,
            sections: {
                general: true,
                shopLayouts: true,
                products: true,
            },
            showLineItemsDialog: false,
            selectedShopLayout: {
                layoutItems: [],
            },
            showShopLayoutDialog: false,
            availableShopLayouts: [],
            showProductsDialog: false,
            availableProducts: [],
            productFilterName: "",
            productFilterPage: 1,
            validationErrors: []
        }
    }

    //---------
    //Mounting
    //---------

    async componentDidMount() {
        //Set the title
        if(this.state.id != null) {
            document.title = "Product Group " + this.state.id + " :: Tiger UI";
        } else {
            document.title = "New product group :: Tiger UI";
        }

        await this.loadProductGroup();
        await this.loadShopLayouts();
        await this.loadProducts(1);
    }

    //--------
    //Loading
    //--------

    async loadProductGroup() {
        let loadedProductGroup = {};

        if (this.state.id != null && this.state.id !== "add") {

            loadedProductGroup = await GetProductGroup(this.state.id);

            if (!loadedProductGroup.error) {
                const productGroup = loadedProductGroup.result;

                this.setState({
                    id: this.state.id,
                    editMode: this.state.editMode,
                    productGroup: productGroup,
                    originalProductGroup: productGroup,
                });
            } else {
                this.setState(prevState => ({...prevState, error: loadedProductGroup}));
            }
        } else {
            this.setState((prevState) => ({
                ...prevState,
                id: null,
                productGroup: {
                    id: null,
                    shopLayouts: {
                        OVERVIEW: [],
                        BOOKS: [],
                        MOVIES: [],
                        AUDIO_BOOKS: [],
                        TIGERBOOKS: [],
                        SUPERBOOKS: []
                    }
                },
                editMode: {
                    active: true,
                    expertMode: false,
                    autoSave: false,
                }
            }));
        }
    }

    async loadShopLayouts() {
        let allLayouts = [];
        let overviewLayouts = this.state.productGroup.shopLayouts.OVERVIEW != null ? this.state.productGroup.shopLayouts.OVERVIEW : [];
        let bookLayouts = this.state.productGroup.shopLayouts.BOOKS != null ? this.state.productGroup.shopLayouts.BOOKS : [];
        let movieLayouts = this.state.productGroup.shopLayouts.MOVIES != null ? this.state.productGroup.shopLayouts.MOVIES : [];
        let audiobookLayouts = this.state.productGroup.shopLayouts.AUDIO_BOOKS != null ? this.state.productGroup.shopLayouts.AUDIO_BOOKS : [];
        let tigerbookLayouts = this.state.productGroup.shopLayouts.TIGERBOOKS != null ? this.state.productGroup.shopLayouts.TIGERBOOKS : [];
        let superbookLayouts = this.state.productGroup.shopLayouts.SUPERBOOKS != null ? this.state.productGroup.shopLayouts.SUPERBOOKS : [];



        if (this.state.id != null && this.state.id !== "add") {

            let overviews = [];
            overviewLayouts.forEach(l => {
                GetShopLayout(l).then(r => {
                    if(!r.error) {
                        overviews.push(r.result);
                        allLayouts.push(r.result);
                    }
                });
            });
            let books = [];
            bookLayouts.forEach(l => {
                GetShopLayout(l).then(r => {
                    if(!r.error) {
                        books.push(r.result);
                        allLayouts.push(r.result);
                    }
                });
            });
            let movies = [];
            movieLayouts.forEach(l => {
                GetShopLayout(l).then(r => {
                    if(!r.error) {
                        movies.push(r.result);
                        allLayouts.push(r.result);
                    }
                });
            });
            let audiobooks = [];
            audiobookLayouts.forEach(l => {
                GetShopLayout(l).then(r => {
                    if(!r.error) {
                        audiobooks.push(r.result);
                        allLayouts.push(r.result);
                    }
                });
            });
            let tigerbooks = [];
            tigerbookLayouts.forEach(l => {
                GetShopLayout(l).then(r => {
                    if(!r.error) {
                        tigerbooks.push(r.result);
                        allLayouts.push(r.result);
                    }
                });
            });

            let superbooks = [];
            superbookLayouts.forEach(l => {
                GetShopLayout(l).then(r => {
                    if(!r.error) {
                        superbooks.push(r.result);
                        allLayouts.push(r.result);
                    }
                });
            });

            this.setState({
                id: this.state.id,
                editMode: this.state.editMode,
                shopLayouts: {
                    all: allLayouts,
                    overview: overviews,
                    books: books,
                    movies: movies,
                    audiobooks: audiobooks,
                    tigerbooks: tigerbooks,
                    superbooks: superbooks
                },
            });
        }
    }

    async loadProducts(page) {
        let loadedProducts = {};

        if (this.state.id != null && this.state.id !== "add") {

            loadedProducts = await GetFilteredProducts({productGroupId: this.state.id, page: page});

            if (!loadedProducts.error) {
                const products = loadedProducts.result;

                this.setState({
                    id: this.state.id,
                    editMode: this.state.editMode,
                    products: products,
                    productsPage: page,
                    lastProductsPage: Math.ceil(loadedProducts.length / 20)
                });
            } else {
                this.setState(prevState => ({...prevState, error: loadedProducts}));
            }
        }
    }


    //----------
    //Rendering
    //----------

    render() {

        return (
            <>
                <div className="details-title">
                    {this.state.productGroup.id != null ? "Details of ProductGroup " + this.state.productGroup.id : "Create a new ProductGroup"}
                </div>
                <div className="details-button-box" style={{height: "70px"}}>
                    <Link to={{pathname: "/" + GlobalConstants.APP_PATH + "productGroups", state: this.state.flowState}}>
                        <button className="form-btn-ci-light-blue" type="button">Back</button>
                    </Link>
                    {this.state.productGroup.id != null &&
                    <button className={this.state.editMode.active ? "form-btn-ci-red" : "form-btn-ci-blue"}
                            type="button" onClick={() => this.setState({
                        editMode: {
                            active: !this.state.editMode.active,
                            expertMode: this.state.editMode.expertMode
                        },
                        productGroup: this.state.originalProductGroup
                    })}>{this.state.editMode.active ? "Cancel" : "Edit"}</button>
                    }
                    {this.state.editMode.active &&
                    <PromiseButton text="Save" onClick={() => this.saveOrUpdateProductGroup()} />
                    }
                    <Button style={{float: "right", marginTop: "5px", marginRight: "10px"}} variant={this.state.editMode.autoSave ? "secondary": "outline-secondary"}
                            onClick={() => this.setState(prevState => ({
                                ...prevState,
                                editMode: {
                                    ...prevState.editMode,
                                    autoSave: !this.state.editMode.autoSave
                                }
                            }))}>Auto Save</Button>
                </div>

                <div className="details-box-title">Resource Details</div>
                <div className="details-box">
                    {/* GENERAL DATA */}
                    <DetailsSection
                        nameInState="productGroup"
                        fields={ProductGroupMetaData.DETAILS_GENERAL}
                        state={this.state}
                        onSetState={(s) => this.setState(s)}
                        onUpdateResource={() => this.saveOrUpdateProductGroup()}
                        sectionId="general"
                    />

                    {/* SHOP LAYOUTS */}
                    <DetailsSection
                        nameInState="productGroup"
                        fields={[]}
                        state={this.state}
                        onSetState={(s) => this.setState(s)}
                        sectionId="shopLayouts"
                        label={<span><RiLayoutMasonryLine/>&#xA0;Shop Layouts</span>}
                    />

                    {this.state.sections.shopLayouts &&
                    <>

                        {/* OVERVIEW */}
                        {this.state.editMode.active &&
                        <>
                            <p style={{width: "100%", marginBottom: "30px"}}>
                                <span style={{
                                    float: "left",
                                    fontSize: "20px",
                                    marginRight: "20px",
                                    marginTop: "5px",
                                    fontWeight: "bold"
                                }}>Add new:</span>
                                <button className="form-btn-ci-blue" type="button" onClick={() =>
                                    this.loadAvailableShopLayouts('OVERVIEW')
                                        .then(r => this.setState(prevState => ({
                                            ...prevState,
                                            showShopLayoutDialog: true
                                        })))
                                }>Overview
                                </button>
                                <button className="form-btn-ci-blue" type="button" onClick={() =>
                                    this.loadAvailableShopLayouts('BOOKS')
                                        .then(r => this.setState(prevState => ({
                                            ...prevState,
                                            showShopLayoutDialog: true
                                        })))
                                }>Book
                                </button>
                                <button className="form-btn-ci-blue" type="button" onClick={() =>
                                    this.loadAvailableShopLayouts('TIGERBOOKS')
                                        .then(r => this.setState(prevState => ({
                                            ...prevState,
                                            showShopLayoutDialog: true
                                        })))
                                }>Tigerbook
                                </button>
                                <button className="form-btn-ci-blue" type="button" onClick={() =>
                                    this.loadAvailableShopLayouts('MOVIES')
                                        .then(r => this.setState(prevState => ({
                                            ...prevState,
                                            showShopLayoutDialog: true
                                        })))
                                }>Movie
                                </button>
                                <button className="form-btn-ci-blue" type="button" onClick={() =>
                                    this.loadAvailableShopLayouts('SUPERBOOKS')
                                        .then(r => this.setState(prevState => ({
                                            ...prevState,
                                            showShopLayoutDialog: true
                                        })))
                                }>Superbook
                                </button>
                                <button className="form-btn-ci-blue" type="button" onClick={() =>
                                    this.loadAvailableShopLayouts('AUDIO_BOOKS')
                                        .then(r => this.setState(prevState => ({
                                            ...prevState,
                                            showShopLayoutDialog: true
                                        })))
                                }>Audiobook
                                </button>
                            </p>
                            <br/>
                            <hr/>
                        </>
                        }

                        {this.state.shopLayouts.overview.length > 0 &&
                        <>
                            <br/><h4><GrObjectUngroup/>&#xA0;Overview</h4><br/>
                            <Table responsive bordered hover striped>
                                <thead>
                                <tr>
                                    <th>Shop Layout ID</th>
                                    <th>Name</th>
                                    <th>State</th>
                                    <th>Layout Items</th>
                                    {this.state.editMode.active &&
                                    <th>Remove</th>
                                    }
                                </tr>
                                </thead>
                                <tbody>
                                {this.state.shopLayouts.overview.map(layout => (
                                    <tr role="row" key={layout.id}>
                                        <td><a
                                            href={GlobalConstants.SPINE_CLIENT_HOST + GlobalConstants.APP_PATH + "shopLayouts/" + layout.id}
                                            style={{fontStyle: "italic", color: "#333"}}>{layout.id}</a></td>
                                        <td>{layout.name}</td>
                                        <td><Badge
                                            variant={layout.state === 'ACTIVE' ? "success" : "danger"}>{layout.state}</Badge>
                                        </td>
                                        <td>
                                            <button className="form-btn-ci-light-blue" type="button"
                                                    onClick={() => this.setState(prevState => ({
                                                        ...prevState,
                                                        selectedShopLayout: layout,
                                                        showLineItemsDialog: true
                                                    }))}><FaRegEye/></button>
                                        </td>
                                        {this.state.editMode.active &&
                                        <td>
                                            <button className="form-btn-ci-red" type="button"
                                                    onClick={() => this.removeShopLayoutFromProductGroup(layout)}>
                                                <BiTrash/>
                                            </button>
                                        </td>
                                        }
                                    </tr>
                                ))}
                                </tbody>
                            </Table>
                            <hr style={{opacity: "0.1"}}/>
                        </>
                        }

                        {/* BOOKS */}
                        {this.state.shopLayouts.books.length > 0 &&
                        <>
                            <br/><h4><FaBook/>&#xA0;Books</h4><br/>
                            <Table responsive bordered hover striped>
                                <thead>
                                <tr>
                                    <th>Shop Layout ID</th>
                                    <th>Name</th>
                                    <th>State</th>
                                    <th>Layout Items</th>
                                    {this.state.editMode.active &&
                                    <th>Remove</th>
                                    }
                                </tr>
                                </thead>
                                <tbody>
                                {this.state.shopLayouts.books.map(layout => (
                                    <tr role="row" key={layout.id}>
                                        <td><a
                                            href={GlobalConstants.SPINE_CLIENT_HOST + GlobalConstants.APP_PATH + "shopLayouts/" + layout.id}
                                            style={{fontStyle: "italic", color: "#333"}}>{layout.id}</a></td>
                                        <td>{layout.name}</td>
                                        <td><Badge
                                            variant={layout.state === 'ACTIVE' ? "success" : "danger"}>{layout.state}</Badge>
                                        </td>
                                        <td>
                                            <button className="form-btn-ci-light-blue" type="button"
                                                    onClick={() => this.setState(prevState => ({
                                                        ...prevState,
                                                        selectedShopLayout: layout,
                                                        showLineItemsDialog: true
                                                    }))}><FaRegEye/></button>
                                        </td>
                                        {this.state.editMode.active &&
                                        <td>
                                            <button className="form-btn-ci-red" type="button"
                                                    onClick={() => this.removeShopLayoutFromProductGroup(layout)}>
                                                <BiTrash/>
                                            </button>
                                        </td>
                                        }
                                    </tr>
                                ))}
                                </tbody>
                            </Table>
                            <hr style={{opacity: "0.1"}}/>
                        </>
                        }

                        {/* MOVIES */}
                        {this.state.shopLayouts.movies.length > 0 &&
                        <>
                            <br/><h4><RiMovie2Line/>&#xA0;Movies</h4><br/>
                            <Table responsive bordered hover striped>
                                <thead>
                                <tr>
                                    <th>Shop Layout ID</th>
                                    <th>Name</th>
                                    <th>State</th>
                                    <th>Layout Items</th>
                                    {this.state.editMode.active &&
                                    <th>Remove</th>
                                    }
                                </tr>
                                </thead>
                                <tbody>
                                {this.state.shopLayouts.movies.map(layout => (
                                    <tr role="row" key={layout.id}>
                                        <td><a
                                            href={GlobalConstants.SPINE_CLIENT_HOST + GlobalConstants.APP_PATH + "shopLayouts/" + layout.id}
                                            style={{fontStyle: "italic", color: "#333"}}>{layout.id}</a></td>
                                        <td>{layout.name}</td>
                                        <td><Badge
                                            variant={layout.state === 'ACTIVE' ? "success" : "danger"}>{layout.state}</Badge>
                                        </td>
                                        <td>
                                            <button className="form-btn-ci-light-blue" type="button"
                                                    onClick={() => this.setState(prevState => ({
                                                        ...prevState,
                                                        selectedShopLayout: layout,
                                                        showLineItemsDialog: true
                                                    }))}><FaRegEye/></button>
                                        </td>
                                        {this.state.editMode.active &&
                                        <td>
                                            <button className="form-btn-ci-red" type="button"
                                                    onClick={() => this.removeShopLayoutFromProductGroup(layout)}>
                                                <BiTrash/>
                                            </button>
                                        </td>
                                        }
                                    </tr>
                                ))}
                                </tbody>
                            </Table>
                            <hr style={{opacity: "0.1"}}/>
                        </>
                        }

                        {/* AUDIOBOOKS */}
                        {this.state.shopLayouts.audiobooks.length > 0 &&
                        <>
                            <br/><h4><MdAudiotrack/>&#xA0;Audiobooks</h4><br/>
                            <Table responsive bordered hover striped>
                                <thead>
                                <tr>
                                    <th>Shop Layout ID</th>
                                    <th>Name</th>
                                    <th>State</th>
                                    <th>Layout Items</th>
                                    {this.state.editMode.active &&
                                    <th>Remove</th>
                                    }
                                </tr>
                                </thead>
                                <tbody>
                                {this.state.shopLayouts.audiobooks.map(layout => (
                                    <tr role="row" key={layout.id}>
                                        <td><a
                                            href={GlobalConstants.SPINE_CLIENT_HOST + GlobalConstants.APP_PATH + "shopLayouts/" + layout.id}
                                            style={{fontStyle: "italic", color: "#333"}}>{layout.id}</a></td>
                                        <td>{layout.name}</td>
                                        <td><Badge
                                            variant={layout.state === 'ACTIVE' ? "success" : "danger"}>{layout.state}</Badge>
                                        </td>
                                        <td>
                                            <button className="form-btn-ci-light-blue" type="button"
                                                    onClick={() => this.setState(prevState => ({
                                                        ...prevState,
                                                        selectedShopLayout: layout,
                                                        showLineItemsDialog: true
                                                    }))}><FaRegEye/></button>
                                        </td>
                                        {this.state.editMode.active &&
                                        <td>
                                            <button className="form-btn-ci-red" type="button"
                                                    onClick={() => this.removeShopLayoutFromProductGroup(layout)}>
                                                <BiTrash/>
                                            </button>
                                        </td>
                                        }
                                    </tr>
                                ))}
                                </tbody>
                            </Table>
                            <hr style={{opacity: "0.1"}}/>
                        </>
                        }

                        {/* TIGERBOOKS */}
                        {this.state.shopLayouts.tigerbooks.length > 0 &&
                        <>
                            <br/><h4><GiTigerHead/>&#xA0;Tigerbooks</h4><br/>
                            <Table responsive bordered hover striped>
                                <thead>
                                <tr>
                                    <th>Shop Layout ID</th>
                                    <th>Name</th>
                                    <th>State</th>
                                    <th>Layout Items</th>
                                    {this.state.editMode.active &&
                                    <th>Remove</th>
                                    }
                                </tr>
                                </thead>
                                <tbody>
                                {this.state.shopLayouts.tigerbooks.map(layout => (
                                    <tr role="row" key={layout.id}>
                                        <td><a
                                            href={GlobalConstants.SPINE_CLIENT_HOST + GlobalConstants.APP_PATH + "shopLayouts/" + layout.id}
                                            style={{fontStyle: "italic", color: "#333"}}>{layout.id}</a></td>
                                        <td>{layout.name}</td>
                                        <td><Badge
                                            variant={layout.state === 'ACTIVE' ? "success" : "danger"}>{layout.state}</Badge>
                                        </td>
                                        <td>
                                            <button className="form-btn-ci-light-blue" type="button"
                                                    onClick={() => this.setState(prevState => ({
                                                        ...prevState,
                                                        selectedShopLayout: layout,
                                                        showLineItemsDialog: true
                                                    }))}><FaRegEye/></button>
                                        </td>
                                        {this.state.editMode.active &&
                                        <td>
                                            <button className="form-btn-ci-red" type="button"
                                                    onClick={() => this.removeShopLayoutFromProductGroup(layout)}>
                                                <BiTrash/>
                                            </button>
                                        </td>
                                        }
                                    </tr>
                                ))}
                                </tbody>
                            </Table>
                            <hr style={{opacity: "0.1"}}/>
                        </>
                        }

                        {/* SUPERBOOKS */}
                        {this.state.shopLayouts.superbooks.length > 0 &&
                        <>
                            <br/><h4><FiThumbsUp/>&#xA0;Superbooks</h4><br/>
                            <Table responsive bordered hover striped>
                                <thead>
                                <tr>
                                    <th>Shop Layout ID</th>
                                    <th>Name</th>
                                    <th>State</th>
                                    <th>Layout Items</th>
                                    {this.state.editMode.active &&
                                    <th>Remove</th>
                                    }
                                </tr>
                                </thead>
                                <tbody>
                                {this.state.shopLayouts.superbooks.map(layout => (
                                    <tr role="row" key={layout.id}>
                                        <td><a
                                            href={GlobalConstants.SPINE_CLIENT_HOST + GlobalConstants.APP_PATH + "shopLayouts/" + layout.id}
                                            style={{fontStyle: "italic", color: "#333"}}>{layout.id}</a></td>
                                        <td>{layout.name}</td>
                                        <td><Badge
                                            variant={layout.state === 'ACTIVE' ? "success" : "danger"}>{layout.state}</Badge>
                                        </td>
                                        <td>
                                            <button className="form-btn-ci-light-blue" type="button"
                                                    onClick={() => this.setState(prevState => ({
                                                        ...prevState,
                                                        selectedShopLayout: layout,
                                                        showLineItemsDialog: true
                                                    }))}><FaRegEye/></button>
                                        </td>
                                        {this.state.editMode.active &&
                                        <td>
                                            <button className="form-btn-ci-red" type="button"
                                                    onClick={() => this.removeShopLayoutFromProductGroup(layout)}>
                                                <BiTrash/>
                                            </button>
                                        </td>
                                        }
                                    </tr>
                                ))}
                                </tbody>
                            </Table>
                            <hr style={{opacity: "0.1"}}/>
                        </>
                        }

                    </>
                    }

                    {/* PRODUCTS */}
                    <DetailsSection
                        nameInState="productGroup"
                        fields={[]}
                        state={this.state}
                        onSetState={(s) => this.setState(s)}
                        sectionId="products"
                        label={<span><BiSitemap/>&#xA0;Products</span>}
                    />

                    {this.state.sections.products &&
                    <>
                        {this.state.editMode.active && this.state.productGroup.id != null &&
                        <button className={this.state.productGroup.id == null ? "form-btn-ci-off" : "form-btn-ci-blue"}
                                disabled={this.state.productGroup.id == null} style={{marginBottom: "10px"}}
                                onClick={() => {
                                    this.setState(prevState => ({
                                        ...prevState,
                                        showProductsDialog: true
                                    }));
                                    this.loadAvailableProducts(1).then(r => r);
                                }}>
                            Add product(s)</button>
                        }
                        <Table responsive bordered hover striped>
                            <thead>
                            <tr>
                                <th>ID</th>
                                <th>State</th>
                                <th>Product Type</th>
                                <th>Title</th>
                                {this.state.editMode.active &&
                                <th>Remove</th>
                                }
                            </tr>
                            </thead>
                            <tbody>
                            {this.state.products.map(product => (
                                <tr role="row" key={product.id}>
                                    <td><a style={{fontStyle: "italic", color: "#333"}}
                                           href={GlobalConstants.SPINE_CLIENT_HOST + GlobalConstants.APP_PATH + "products/" + product.id}>{product.id}</a>
                                    </td>
                                    <td>
                                        <Badge bg={
                                            product.state === 'NEW' ? "primary" :
                                                product.state === 'ACTIVE' ? "success" :
                                                    product.state === 'INACTIVE' ? "warning" : "danger"
                                        }>{product.state}</Badge>
                                    </td>
                                    <td>
                                        <Badge bg={
                                            product.productType === 'TIGERBOOK' ? "primary" :
                                                product.productType === 'MOVIE' ? "info" :
                                                    product.productType === 'AUDIOBOOK' ? "light" :
                                                        product.productType === 'BOOK' ? "success" : "dark"
                                        }>{product.productType}</Badge>
                                    </td>
                                    <td>{product.title}</td>
                                    {this.state.editMode.active &&
                                    <td>
                                        <button className="form-btn-ci-red"
                                                onClick={() => this.removeProductFromProductGroup(product)}>
                                            <GiTrashCan/></button>
                                    </td>
                                    }
                                </tr>
                            ))}
                            </tbody>
                        </Table>

                        <Pagination>
                            <Pagination.Item hidden={this.state.productsPage === 1}
                                             onClick={() => this.loadProducts(1)}>&lt;&lt;</Pagination.Item>
                            <Pagination.Item hidden={this.state.productsPage === 1}
                                             onClick={() => this.loadProducts(this.state.productsPage - 1)}>&lt;</Pagination.Item>
                            <Pagination.Item hidden={this.state.productsPage <= 2}
                                             onClick={() => this.loadProducts(this.state.productsPage - 2)}>{this.state.productsPage - 2}</Pagination.Item>
                            <Pagination.Item hidden={this.state.productsPage === 1}
                                             onClick={() => this.loadProducts(this.state.productsPage - 1)}>{this.state.productsPage - 1}</Pagination.Item>
                            <Pagination.Item active={true}>{this.state.productsPage}</Pagination.Item>
                            <Pagination.Item hidden={this.state.productsPage >= this.state.lastProductsPage}
                                             onClick={() => this.loadProducts(this.state.productsPage + 1)}>{this.state.productsPage + 1}</Pagination.Item>
                            <Pagination.Item hidden={this.state.productsPage >= this.state.lastProductsPage - 1}
                                             onClick={() => this.loadProducts(this.state.productsPage + 2)}>{this.state.productsPage + 2}</Pagination.Item>
                            <Pagination.Item hidden={this.state.productsPage >= this.state.lastProductsPage}
                                             onClick={() => this.loadProducts(this.state.productsPage + 1)}>&gt;</Pagination.Item>
                            <Pagination.Item hidden={this.state.productsPage >= this.state.lastProductsPage}
                                             onClick={() => this.loadProducts(this.state.lastProductsPage)}>&gt;&gt;</Pagination.Item>
                        </Pagination>

                    </>
                    }

                </div>
                <div className="details-button-box" style={{height: "70px"}}>
                    <Link to={{pathname: "/" + GlobalConstants.APP_PATH + "productGroups", state: this.state.flowState}}>
                        <button className="form-btn-ci-light-blue" type="button">Back</button>
                    </Link>
                    {this.state.editMode.active &&
                    <PromiseButton text="Save" onClick={() => this.saveOrUpdateProductGroup()} />
                    }
                </div>

                {/* DIALOGS */}

                <InfoModal show={this.state.showLineItemsDialog}
                           onHide={() => this.setState(prevState => ({
                               ...prevState,
                               showLineItemsDialog: false
                           }))}
                           title={"Line Items of Shop Layout " + this.state.selectedShopLayout.id}
                           body={<LayoutItemsDialog shopLayout={this.state.selectedShopLayout}/>}/>

                <InfoModal show={this.state.showShopLayoutDialog}
                           onHide={() => this.setState(prevState => ({...prevState, showShopLayoutDialog: false}))}
                           title={"Select a shoplayout"}
                           body={this.pickShopLayoutDialog()}/>

                <InfoModal show={this.state.showProductsDialog}
                           onHide={() => this.setState(prevState => ({...prevState, showProductsDialog: false}))}
                           title={"Select one or more products to add"}
                           body={this.pickProductsDialog()}/>

                <ErrorHandler error={this.state.error}
                              onHide={() => this.setState(prevState => ({...prevState, error: null}))} />
            </>
        );
    }

    //------------
    //API Methods
    //------------

    async saveOrUpdateProductGroup() {
        let productGroup = {};
        if (this.state.productGroup.id != null) {
            productGroup = await UpdateProductGroup(this.state.productGroup);
        } else {
            productGroup = await CreateProductGroup(this.state.productGroup);
        }

        if (!productGroup.error) {
            this.setState((prevState) => ({
                ...prevState,
                productGroup: productGroup.result,
                editMode: {...prevState.editMode, active: this.state.editMode.autoSave}
            }));

            this.props.addToast("The productGroup has been updated successfully.", {
                autoDismiss: true,
                appearance: 'success'
            });
        } else {
            this.setState(prevState => ({...prevState, error: productGroup}));
        }
    }

    async loadAvailableShopLayouts(shopLayoutType) {
        let layouts = [];

        let loadedLayouts = await GetFilteredShopLayouts({per_page: 50, layout_type: shopLayoutType});

        if (!loadedLayouts.error) {
            layouts = loadedLayouts.result;
        } else {
            this.setState(prevState => ({...prevState, error: loadedLayouts}));
        }

        this.setState(prevState => ({...prevState, availableShopLayouts: layouts}));

    }

    async loadAvailableProducts(page) {
        let loadedProducts = await GetFilteredProducts({page: page, title: this.state.productFilterName});

        if (!loadedProducts.error) {
            this.setState(prevState => ({
                ...prevState,
                availableProducts: loadedProducts.result,
                productFilterPage: page
            }));
        } else {
            this.setState(prevState => ({...prevState, error: loadedProducts}));
        }
    }

    async removeProductFromProductGroup(product) {
        let response = await RemoveProductFromProductGroup(product, this.state.productGroup);

        if (response.error) {
            this.setState(prevState => ({...prevState, error: response}));
        } else {
            let products = this.state.products;
            products = products.filter(pr => pr.id !== product.id);
            this.setState(prevState => ({...prevState, products: products}));

            this.props.addToast("The product has been removed successfully", {
                autoDismiss: true,
                appearance: 'success'
            });
        }
    }

    async addProductToProductGroup(product) {
        let response = await AddProductToProductGroup(product, this.state.productGroup);

        if (response.error) {
            this.setState(prevState => ({...prevState, error: response}));
        } else {
            let products = this.state.products;
            products.push(product);
            this.setState(prevState => ({...prevState, products: products}));

            this.props.addToast("The product has been added successfully", {
                autoDismiss: true,
                appearance: 'success'
            });
        }
    }

    //--------
    // HELPERS
    //--------

    removeShopLayoutFromProductGroup(shopLayout) {
        let productGroup = this.state.productGroup;
        let layoutsInProductGroup = productGroup.shopLayouts;

        let books = this.state.shopLayouts.books;
        let overviews = this.state.shopLayouts.overview;
        let tigerbooks = this.state.shopLayouts.tigerbooks;
        let superbooks = this.state.shopLayouts.superbooks;
        let movies = this.state.shopLayouts.movies;
        let audiobooks = this.state.shopLayouts.audiobooks;

        if (shopLayout.layoutType === 'OVERVIEW') {
            layoutsInProductGroup.OVERVIEW = layoutsInProductGroup.OVERVIEW.filter(id => id !== shopLayout.id);
            overviews = overviews.filter(layout => layout.id !== shopLayout.id);
        } else if (shopLayout.layoutType === 'BOOKS') {
            layoutsInProductGroup.BOOKS = layoutsInProductGroup.BOOKS.filter(id => id !== shopLayout.id);
            books = books.filter(layout => layout.id !== shopLayout.id);
        } else if (shopLayout.layoutType === 'TIGERBOOKS') {
            layoutsInProductGroup.TIGERBOOKS = layoutsInProductGroup.TIGERBOOKS.filter(id => id !== shopLayout.id);
            tigerbooks = tigerbooks.filter(layout => layout.id !== shopLayout.id);
        } else if (shopLayout.layoutType === 'SUPERBOOKS') {
            layoutsInProductGroup.SUPERBOOKS = layoutsInProductGroup.SUPERBOOKS.filter(id => id !== shopLayout.id);
            superbooks = superbooks.filter(layout => layout.id !== shopLayout.id);
        } else if (shopLayout.layoutType === 'MOVIES') {
            layoutsInProductGroup.MOVIES = layoutsInProductGroup.MOVIES.filter(id => id !== shopLayout.id);
            movies = movies.filter(layout => layout.id !== shopLayout.id);
        } else if (shopLayout.layoutType === 'AUDIO_BOOKS') {
            layoutsInProductGroup.AUDIO_BOOKS = layoutsInProductGroup.AUDIO_BOOKS.filter(id => id !== shopLayout.id);
            audiobooks = audiobooks.filter(layout => layout.id !== shopLayout.id);
        }

        productGroup.shopLayouts = layoutsInProductGroup;

        this.setState(prevState => ({
            ...prevState, productGroup: productGroup,
            shopLayouts: {
                ...prevState.shopLayouts,
                all: layoutsInProductGroup,
                books: books,
                overview: overviews,
                tigerbooks: tigerbooks,
                superbooks: superbooks,
                movies: movies,
                audiobooks: audiobooks
            }
        }));

    }

    addShopLayoutToProductGroup(shopLayout) {
        let productGroup = this.state.productGroup;
        let layoutsInProductGroup = productGroup.shopLayouts;
        let books = this.state.shopLayouts.books;
        let overviews = this.state.shopLayouts.overview;
        let tigerbooks = this.state.shopLayouts.tigerbooks;
        let superbooks = this.state.shopLayouts.superbooks;
        let movies = this.state.shopLayouts.movies;
        let audiobooks = this.state.shopLayouts.audiobooks;

        if (shopLayout.layoutType === 'OVERVIEW') {
            if (layoutsInProductGroup.OVERVIEW == null) {
                layoutsInProductGroup.OVERVIEW = [];
            }
            layoutsInProductGroup.OVERVIEW.push(shopLayout.id);
            overviews.push(shopLayout);
        } else if (shopLayout.layoutType === 'BOOKS') {
            if (layoutsInProductGroup.BOOKS == null) {
                layoutsInProductGroup.BOOKS = [];
            }
            layoutsInProductGroup.BOOKS.push(shopLayout.id);
            books.push(shopLayout);
        } else if (shopLayout.layoutType === 'TIGERBOOKS') {
            if (layoutsInProductGroup.TIGERBOOKS == null) {
                layoutsInProductGroup.TIGERBOOKS = [];
            }
            layoutsInProductGroup.TIGERBOOKS.push(shopLayout.id);
            tigerbooks.push(shopLayout);
        } else if (shopLayout.layoutType === 'SUPERBOOKS') {
            if (layoutsInProductGroup.SUPERBOOKS == null) {
                layoutsInProductGroup.SUPERBOOKS = [];
            }
            layoutsInProductGroup.SUPERBOOKS.push(shopLayout.id);
            superbooks.push(shopLayout);
        } else if (shopLayout.layoutType === 'MOVIES') {
            if (layoutsInProductGroup.MOVIES == null) {
                layoutsInProductGroup.MOVIES = [];
            }
            layoutsInProductGroup.MOVIES.push(shopLayout.id);
            movies.push(shopLayout);
        } else if (shopLayout.layoutType === 'AUDIO_BOOKS') {
            if (layoutsInProductGroup.AUDIO_BOOKS == null) {
                layoutsInProductGroup.AUDIO_BOOKS = [];
            }
            layoutsInProductGroup.AUDIO_BOOKS.push(shopLayout.id);
            audiobooks.push(shopLayout);
        }

        productGroup.shopLayouts = layoutsInProductGroup;

        this.setState(prevState => ({
            ...prevState, productGroup: productGroup,
            shopLayouts: {
                ...prevState.shopLayouts,
                all: layoutsInProductGroup,
                books: books,
                overview: overviews,
                tigerbooks: tigerbooks,
                superbooks: superbooks,
                movies: movies,
                audiobooks: audiobooks
            },
            showShopLayoutDialog: false
        }));
    }

    //--------
    // DIALOGS
    //--------

    pickShopLayoutDialog() {
        return (
            <div>
                <Table striped bordered hover>
                    <thead>
                    <tr>
                        <th>ID</th>
                        <th>Name</th>
                        <th>State</th>
                        <th>Layout Items</th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.state.availableShopLayouts.map(layout => (
                        <tr role="row" key={layout.id} style={{cursor: "pointer"}}>
                            <td onClick={() => this.addShopLayoutToProductGroup(layout)}>{layout.id}</td>
                            <td onClick={() => this.addShopLayoutToProductGroup(layout)}>{layout.name}</td>
                            <td onClick={() => this.addShopLayoutToProductGroup(layout)}><Badge
                                variant={layout.state === 'ACTIVE' ? "success" : "danger"}>{layout.state}</Badge></td>
                            <td>
                                <button className="form-btn-ci-light-blue" type="button"
                                        onClick={() => this.setState(prevState => ({
                                            ...prevState,
                                            selectedShopLayout: layout,
                                            showLineItemsDialog: true
                                        }))}><FaRegEye/></button>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
            </div>
        )
    }

    pickProductsDialog() {
        return (
            <div>
                <InputGroup className="mb-3">
                    <FormControl
                        placeholder="Search for a product"
                        onChange={(e) => this.setState(prevState => ({
                            ...prevState,
                            productFilterName: e.target.value
                        }))}
                    />
                        <Button variant="outline-secondary"
                                onClick={() => this.loadAvailableProducts(1)}>Search</Button>
                </InputGroup>
                <Table striped bordered hover>
                    <thead>
                    <tr>
                        <th>ID</th>
                        <th>State</th>
                        <th>Product Type</th>
                        <th>Title</th>
                        <th>External ID</th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.state.availableProducts.map(prod => (
                        <tr role="row" key={prod.id} style={{cursor: "pointer"}}
                            onClick={() => this.addProductToProductGroup(prod)}>
                            <td>{prod.id}</td>
                            <td>
                                <Badge bg={
                                    prod.state === 'NEW' ? "primary" :
                                        prod.state === 'ACTIVE' ? "success" :
                                            prod.state === 'INACTIVE' ? "warning" : "danger"
                                }>{prod.state}</Badge>
                            </td>
                            <td>
                                <Badge bg={
                                    prod.productType === 'TIGERBOOK' ? "primary" :
                                        prod.productType === 'MOVIE' ? "info" :
                                            prod.productType === 'AUDIOBOOK' ? "light" :
                                                prod.productType === 'BOOK' ? "success" : "dark"
                                }>{prod.productType}</Badge>
                            </td>
                            <td>{prod.title}</td>
                            <td>{prod.externalId}</td>
                        </tr>
                    ))}
                    </tbody>
                </Table>

                <Pagination>
                    <Pagination.Item hidden={this.state.productFilterPage === 1}
                                     onClick={() => this.loadAvailableProducts(1)}>&lt;&lt;</Pagination.Item>
                    <Pagination.Item hidden={this.state.productFilterPage === 1}
                                     onClick={() => this.loadAvailableProducts(this.state.productFilterPage - 1)}>&lt;</Pagination.Item>
                    <Pagination.Item hidden={this.state.productFilterPage <= 2}
                                     onClick={() => this.loadAvailableProducts(this.state.productFilterPage - 2)}>{this.state.productFilterPage - 2}</Pagination.Item>
                    <Pagination.Item hidden={this.state.productFilterPage === 1}
                                     onClick={() => this.loadAvailableProducts(this.state.productFilterPage - 1)}>{this.state.productFilterPage - 1}</Pagination.Item>
                    <Pagination.Item active={true}>{this.state.productFilterPage}</Pagination.Item>
                    <Pagination.Item
                        onClick={() => this.loadAvailableProducts(this.state.productFilterPage + 1)}>{this.state.productFilterPage + 1}</Pagination.Item>
                    <Pagination.Item
                        onClick={() => this.loadAvailableProducts(this.state.productFilterPage + 2)}>{this.state.productFilterPage + 2}</Pagination.Item>
                    <Pagination.Item
                        onClick={() => this.loadAvailableProducts(this.state.productFilterPage + 1)}>&gt;</Pagination.Item>
                </Pagination>
            </div>
        )
    }

}


export default withToast(ProductGroupDetails);