import {Button, Table} from "react-bootstrap";
import React, {Component} from "react";
import {withToast} from "../../util/ToastService";
import {Link} from "react-router-dom";
import {
    BiReceipt,
    BsListUl,
    IoPeopleCircleOutline,
} from "react-icons/all";
import GlobalConstants from "../../config/GlobalConstants";
import {GetRestore} from "./RestoreService";
import {DetailsSection} from "../../generators/DetailsGenerator";
import RestoreMetaData from "./RestoreMetaData";
import {ErrorHandler} from "../../util/ErrorHandler";

class RestoreDetails extends Component {

    //------------
    //Constructor
    //------------

    constructor(props) {
        super(props);

        this.state = {
            error: null,
            flowState: props.location.state,
            id: this.props.match.params.id,
            editMode: {
                active: false,
                expertMode: false,
                autoSave: true,
            },
            restore: {
                lineItems: []
            },
            originalRestore: {},
            sections: {
                general: true,
                customerDetails: true,
                lineItems: true,
                receiptDetails: true,
            }
        }
    }

    //---------
    //Mounting
    //---------

    async componentDidMount() {
        //Set the title
        if(this.state.id != null) {
            document.title = "Restore " + this.state.id + " :: Tiger UI";
        } else {
            document.title = "New restore :: Tiger UI";
        }
        await this.loadRestore();
    }

    //--------
    //Loading
    //--------

    async loadRestore() {
        let loadedRestore = {};

        if(this.state.id != null && this.state.id !== "add") {

            loadedRestore = await GetRestore(this.state.id);

            if (!loadedRestore.error) {
                const restore = loadedRestore.result;

                this.setState({
                    id: this.state.id,
                    editMode: this.state.editMode,
                    restore: restore,
                    originalRestore: restore,
                });
            } else {
                this.setState(prevState => ({...prevState, error: loadedRestore}));
            }
        } else {
            this.setState((prevState) => ({...prevState,
                id: null,
                restore: {
                    id: null,
                },
                editMode: {
                    active: true,
                    expertMode: false,
                    autoSave: false,
                }}));
        }
    }

    //----------
    //Rendering
    //----------

    render() {

        return (
            <>
                <div className="details-title">
                    {"Details of Restore " + this.props.match.params.id}
                </div>
                <div className="details-button-box" style={{height: "70px"}}>
                    <Link to={{pathname: "/" + GlobalConstants.APP_PATH +"restores", state: this.state.flowState}}>
                        <button className="form-btn-ci-light-blue" type="button">Back</button>
                    </Link>
                    <Button style={{float: "right", marginTop: "5px"}} variant={this.state.editMode.expertMode ? "secondary": "outline-secondary"}
                            onClick={() => this.setState(prevState => ({
                                ...prevState,
                                editMode: {
                                    ...prevState.editMode,
                                    expertMode: !this.state.editMode.expertMode
                                }
                            }))}>Expert Mode</Button>
                </div>

                <div className="details-box-title">Resource Details</div>
                <div className="details-box">
                    {/* GENERAL DATA */}
                    <DetailsSection
                        nameInState="restore"
                        fields={RestoreMetaData.DETAILS_GENERAL}
                        state={this.state}
                        onSetState={(s) => this.setState(s)}
                        onUpdateResource={() => this.saveOrUpdateRestore()}
                        sectionId="general"
                    />

                    {/* CUSTOMER DETAILS */}
                    <DetailsSection
                        nameInState="restore"
                        fields={RestoreMetaData.DETAILS_CUSTOMER}
                        state={this.state}
                        onSetState={(s) => this.setState(s)}
                        sectionId="customerDetails"
                        onUpdateResource={() => this.saveOrUpdateRestore()}
                        label={<span><IoPeopleCircleOutline/>&#xA0;Customer Details</span>}
                    />

                    {/* LINE ITEMS */}
                    <DetailsSection
                        nameInState="restore"
                        fields={[]}
                        state={this.state}
                        onSetState={(s) => this.setState(s)}
                        sectionId="lineItems"
                        label={<span><BsListUl/>&#xA0;Line Items</span>}
                    />

                    <Table responsive striped bordered hover>
                        <thead>
                        <tr>
                            <th>ID</th>
                            <th>Amount</th>
                            <th>Currency Code</th>
                            <th>Country Code</th>
                            <th>Product IAP</th>
                            <th>Product Name</th>
                        </tr>
                        </thead>
                        <tbody>
                        {this.state.restore.lineItems.map(item => (
                            <tr role="row" key={item.id} style={{cursor: "pointer"}}>
                                <td>{item.id}</td>
                                <td>{item.amount}</td>
                                <td>{item.currencyCode}</td>
                                <td>{item.countryCode}</td>
                                <td>{item.productIap}</td>
                                <td>{item.productName}</td>
                            </tr>
                        ))}
                        </tbody>
                    </Table>

                    {/* CUSTOMER DETAILS */}
                    <DetailsSection
                        nameInState="restore"
                        fields={RestoreMetaData.DETAILS_RECEIPT}
                        state={this.state}
                        onSetState={(s) => this.setState(s)}
                        sectionId="receiptDetails"
                        onUpdateResource={() => this.saveOrUpdateRestore()}
                        label={<span><BiReceipt/>&#xA0;Receipt Details</span>}
                    />

                </div>
                <div className="details-button-box" style={{height: "70px"}}>
                    <Link to={{pathname: "/" + GlobalConstants.APP_PATH +"restores", state: this.state.flowState}}>
                        <button className="form-btn-ci-light-blue" type="button">Back</button>
                    </Link>
                </div>

                <ErrorHandler error={this.state.error}
                              onHide={() => this.setState(prevState => ({...prevState, error: null}))} />
            </>
        );
    }

    //------------
    //API Methods
    //------------

    async saveOrUpdateRestore() {
        let restore = {};

        if (!restore.error) {
            this.setState((prevState) =>({...prevState, restore: restore.result, editMode: {...prevState.editMode, active: this.state.editMode.autoSave}}));

            this.props.addToast("The restore has been updated successfully.", {
                autoDismiss: true,
                appearance: 'success'
            });
        } else {
            this.setState(prevState => ({...prevState, error: restore}));
        }
    }

}


export default withToast(RestoreDetails);