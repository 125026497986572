const AccountMetaData = {

    //Columns
    COLUMNS: [
        //Default & Badges
        {label: "ID", id: 'id', columnType: 'default', show: true},
        {label: "Account Type", id: 'accountType', columnType: 'badge', options: [
                {value: 'DEFAULT', style: "success"},
                {value: 'GUEST', style: "secondary"},
                {value: 'LIBRARY', style: "primary"},
                {value: 'SYSTEM', style: "warning"},
            ],
            show: true},
        {label: "E-Mail", id: 'email', columnType: 'default', show: true},
        {label: "Enabled", id: 'enabled', columnType: 'boolean', show: true},
        {label: "Validated", id: 'validated', columnType: 'boolean', show: true},
    ],

    //Details
    DETAILS_GENERAL: [
        [{label: 'ID', id: 'id', type: 'text', editable: 'never'}, {label: 'Account Type', id: 'accountType', type: 'select', options: ['DEFAULT', 'LIBRARY', 'SYSTEM', 'GUEST'], editable: 'new'}, {label: "Version", id: 'version', type: 'text', editable: 'never'}],
        [{label: 'Enabled', id: 'enabled', type: 'boolean', editable: 'true'},{label: 'Validated', id: 'validated', type: 'boolean', editable: 'true'}],
        [{label: 'tigertipps', id: 'tigertipps', type: 'boolean', editable: 'true'}, {label: 'tigerpost', id: 'tigerpost', type: 'boolean', editable: 'true'}]
    ],
    CUSTOMER_DETAILS: [
        [{label: 'First Name', id: 'firstName', type: 'text', editable: 'true'},{label: 'Last Name', id: 'lastName', type: 'text', editable: 'true'},{label: 'E-Mail', id: 'email', type: 'text', editable: 'new'}],
    ],
    TECHNICAL_DETAILS: [
        [{label: 'Max Devices', id: 'maxDevices', type: 'text', editable: 'true'},{label: 'Max Profiles', id: 'maxProfiles', type: 'text', editable: 'true'},{label: 'Pin', id: 'pin', type: 'text'}],
    ],

    //Sorting
    ACCOUNT_SORT_PARAMS: [],
};

export default AccountMetaData;