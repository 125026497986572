const LibraryPoolMetaData = {

    //Columns
    COLUMNS: [
        //Default & Badges
        {label: "ID", id: 'id', columnType: 'default', show: true},
        {label: "Cardinality", id: 'cardinality', columnType: 'badge', options: [
                {value: 'SINGLE', style: "success"},
                {value: 'MULTIPLE', style: "primary"}
            ],
            show: true},
        {label: "Name", id: 'name', columnType: 'default', show: true},
        {label: "Active Licenses", id: 'activeLicenses', columnType: 'default', show: true},

    ],

    //Details
    DETAILS_GENERAL: [
        [{label: 'ID', id: 'id', type: 'text', editable: 'never', isExpertField: false}, {label: ' Name', id: 'name', type: 'text', editable: 'true', required: true}, {label: "Cardinality", id: 'cardinality', type: 'select', options: ['SINGLE', 'MULTIPLE'], editable: 'true'}],
    ],
    DETAILS_OWNER: [
        [{label: 'Active Licenses', id: 'activeLicenses', type: 'text', editable: 'true', required: true}, {label: 'Owner', id: 'owner', type: 'text', editable: 'true'}, {label: 'Region', id: 'region', type: 'text', editable: 'true'}],
    ],

    //Sorting
    LIBRARY_POOL_SORT_PARAMS: [],
};

export default LibraryPoolMetaData;