const NewsletterMetaData = {

    //Columns
    COLUMNS: [
        //Default & Badges
        {label: "ID", id: 'id', columnType: 'default', show: true},
        {label: "Name", id: 'name', columnType: 'default', show: true},
        {label: "Display Name", id: 'displayName', columnType: 'default', show: true},
        {label: "Description", id: 'description', columnType: 'default', show: false},
        {label: "Subscription Message", id: 'subscriptionMessage', columnType: 'default', show: false},
        {label: "Unsubscription Message", id: 'unsubscriptionMessage', columnType: 'default', show: false},
        {label: "Mailjet List ID", id: 'mailjetListId', columnType: 'default', show: true},
        {label: "Active", id: 'active', columnType: 'boolean', show: true}
    ],

    //Details
    DETAILS_GENERAL: [
        [{label: 'ID', id: 'id', type: 'text', editable: 'never', isExpertField: false}, {label: 'Created At', id: 'createdAt', type: 'datetime', editable: 'never'}, {label: "Updated At", id: 'updatedAt', type: 'datetime', editable: 'never'}],
        [{label: 'Name', id: 'name', type: 'text', editable: 'true', required: true}, {label: 'Display Name', id: 'displayName', type: 'text', editable: 'true', required: true}, {label: 'Description', id: 'description', type: 'text', editable: 'true'}],
        [{label: "Mailjet List Id", id: 'mailjetListId', type: 'text', editable: 'true'}, {label: "Active", id: 'active', type: 'boolean', editable: 'true'}],
        [{label: 'Subscription Message', id: 'subscriptionMessage', type: 'textarea', rows: 5, editable: 'true'},{label: 'Unsubscription Message', id: 'unsubscriptionMessage', type: 'textarea', rows: 5, editable: 'true'}]
    ],

    //Sorting
    NEWSLETTER_SORT_PARAMS: [],
};

export default NewsletterMetaData;