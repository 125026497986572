import {useToasts} from "react-toast-notifications";

export function withToast(Component) {
    return function WrappedComponent(props) {
        const toastFuncs = useToasts();
        return <Component {...props} {...toastFuncs} />;
    }
}

export function showError(props, response) {
    if(response.result != null) {
        for(let i = 0; i < response.result.length; i++) {
            props.addToast("Error: '" + response.result[i].field + "' " + response.result[i].message, {
                appearance: "error",
                autoDismiss: true
            });
        }
    } else {
        props.addToast("Error: " + response.message, {
            appearance: "error",
            autoDismiss: true
        });
    }
}