import {Badge, Button, Col, Form, FormCheck, FormControl, InputGroup, Pagination, Table} from "react-bootstrap";
import FormSelect from "react-bootstrap/FormSelect";
import Row from "react-bootstrap/Row";
import {Label} from "reactstrap";
import React, {useState} from "react";
import {openExternalTargetInNewTab} from "../../util/ResourceService";
import ShopLayoutMetaData from "./ShopLayoutMetaData";
import {GetFilteredProducts} from "../products/ProductService";
import GlobalConstants from "../../config/GlobalConstants";
import {Link} from "react-router-dom";
import ProductMetaData, {ProductStateBadges, ProductTypeBadges} from "../products/ProductMetaData";
import {FiTrash2} from "react-icons/all";
import {PromiseButton} from "../../global/SpinningTiger";

export function EditImageDialog(props) {
    let [image, setImage] = useState(props.maxOrdinal != null ? {...props.image, ordinal: props.maxOrdinal} :props.image);
    let [includedProducts, setIncludedProducts] = useState([]);
    let [query, setQuery] = useState(props.image.query != null ? props.image.query : null);

    let [products, setProducts] = useState([]);
    let [productsPage, setProductsPage] = useState(1);
    let [lastProductsPage, setLastProductsPage] = useState(1);
    let [productFilterName, setProductFilterName] = useState("");

    //Dialogs
    let [showAddProductListDialog, setShowAddProductListDialog] = useState(false);
    let [showQueryDialog, setShowQueryDialog] = useState(props.image.query != null);

    const labelStyle = {
        fontSize: "20px",
        backgroundColor: "#C8C8C8",
        borderStyle: "solid",
        borderColor: "#cdd7e0",
        borderWidth: "1px",
        borderRadius: "5px",
        paddingLeft: "10px",
        paddingRight: "10px",
        width: "100%"
    };

    //Load products
    if(image.productIdentifier.length > 0) {
        loadIncludedProducts(image).then(r => setIncludedProducts(r));
    }

    return(
        <div>
            <div className="details-box">
                <Form>
                    <Row>
                        <Form.Group as={Col} controlId="ordinal">
                            <Label style={labelStyle}>Ordinal</Label>
                            <FormControl type="text" value={image.ordinal} onChange={(e) => updateImageParam('ordinal', e.target.value)}/>
                        </Form.Group>
                        <Form.Group as={Col} controlId="title">
                            <Label style={labelStyle}>Title</Label>
                            <FormControl type="text" value={image.title} onChange={(e) => updateImageParam('title', e.target.value)} />
                        </Form.Group>
                        <Form.Group as={Col} controlId="age">
                            <Label style={labelStyle}>Age</Label>
                            <FormControl type="number" value={image.age} onChange={(e) => updateImageParam('age', e.target.value)} />
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group as={Col} controlId="sdImage">
                            <Label style={labelStyle}>SD Image</Label>
                            <InputGroup>
                                <FormControl type="text" value={image.sdImage} onChange={(e) => updateImageParam('sdImage', e.target.value)} />
                                    <Button variant="outline-secondary" onClick={() => openExternalTargetInNewTab(image.sdImage)}>Preview</Button>
                            </InputGroup>
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group as={Col} controlId="hdImage">
                            <Label style={labelStyle}>HD Image</Label>
                            <InputGroup>
                                <FormControl type="text" value={image.hdImage} onChange={(e) => updateImageParam('hdImage', e.target.value)}/>
                                    <Button variant="outline-secondary" onClick={() => openExternalTargetInNewTab(image.hdImage)}>Preview</Button>
                            </InputGroup>
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group as={Col} controlId="sortBy">
                            <Label style={labelStyle}>Sort By</Label>
                            <FormSelect value={image.sortBy} onChange={(e) => updateImageParam('sortBy', e.target.value)}>
                                {ShopLayoutMetaData.IMAGE_SORTBY.map(img => (
                                    <option>{img}</option>
                                ))}
                            </FormSelect>
                        </Form.Group>
                        <Form.Group as={Col} controlId="sortDirection">
                            <Label style={labelStyle}>Sort Direction</Label>
                            <FormSelect value={image.sortDirection} onChange={(e) => updateImageParam('sortDirection', e.target.value)}>
                                {ShopLayoutMetaData.IMAGE_SORTDIRECTION.map(img => (
                                    <option>{img}</option>
                                ))}
                            </FormSelect>
                        </Form.Group>
                        <Form.Group as={Col} controlId="ageFiltered">
                            <Label style={labelStyle}>Age Filtered</Label>
                            <FormCheck id="ageFilteredSwitch" type="switch"
                                       checked={image.ageFiltered} onChange={() => updateImageParam('ageFiltered', !image.ageFiltered)}/>
                        </Form.Group>
                    </Row>
                    <hr />

                    {/* QUERY */}
                    <h4 style={{marginBottom: "20px"}}>Query<span style={{float: "right"}}>
                        <button type="button" className={query == null ? "form-btn-ci-light-blue" : "form-btn-ci-blue"}
                        onClick={() => {
                            if(query == null) {
                                setQuery({});
                            }
                            setShowQueryDialog(!showQueryDialog);
                        }}>{query == null ? "Add query" : showQueryDialog ? "Hide query" : "Show query"}</button>

                        {query != null &&
                            <button className="form-btn-ci-red" onClick={() => {
                                setShowQueryDialog(false);
                                setQuery(null);
                            }}>Remove Query</button>
                        }
                    </span></h4>

                    {/* QUERY */}
                    {showQueryDialog &&
                        <>
                            <Row>
                                <Form.Group as={Col}>
                                    <Label style={labelStyle}>Name</Label>
                                    <FormControl type="text" value={query.name} onChange={(e) => setQuery({...query, name: e.target.value})} />
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <Label style={labelStyle}>Author</Label>
                                    <FormControl type="text" value={query.author} onChange={(e) => setQuery({...query, author: e.target.value})}/>
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <Label style={labelStyle}>Imprint</Label>
                                    <FormControl type="text" value={query.imprintName} onChange={(e) => setQuery({...query, imprintName: e.target.value})} />
                                </Form.Group>
                            </Row>
                            <Row>
                                <Form.Group as={Col}>
                                    <Label style={labelStyle}>Product Type</Label>
                                    <FormSelect value={query.productType} onChange={(e) => setQuery({...query, productType: e.target.value})}>
                                        {ProductMetaData.PRODUCT_TYPES.map(type => (
                                            <option>{type}</option>
                                        ))}
                                    </FormSelect>
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <Label style={labelStyle}>Product State</Label>
                                    <FormSelect value={query.productState} onChange={(e) => setQuery({...query, productState: e.target.value})}>
                                        {ProductMetaData.PRODUCT_STATES.map(state => (
                                            <option>{state}</option>
                                        ))}
                                    </FormSelect>
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <Label style={labelStyle}>Product Classification</Label>
                                    <FormSelect value={query.productClassifications}
                                                 style={{cursor: "pointer"}}
                                                 onChange={(e) => setQuery({...query, productClassifications: e.target.value})}>
                                        {ShopLayoutMetaData.SHOPLAYOUT_PRODUCTCLASSIFICATIONS.map(c => (
                                            <option>{c}</option>
                                        ))}
                                    </FormSelect>
                                </Form.Group>
                            </Row>
                            <Row>
                                <Form.Group as={Col}>
                                    <Label style={labelStyle}>Product Group ID</Label>
                                    <FormControl type="text" value={query.productGroupId} onChange={(e) => setQuery({...query, productGroupId: e.target.value})}/>
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <Label style={labelStyle}>Age Range Min</Label>
                                    <FormControl type="text" value={query.ageRangeMin} onChange={(e) => setQuery({...query, ageRangeMin: e.target.value})} />
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <Label style={labelStyle}>Age Range Max</Label>
                                    <FormControl type="text" value={query.ageRangeMax} onChange={(e) => setQuery({...query, ageRangeMax: e.target.value})}/>
                                </Form.Group>
                            </Row>
                            <Row>
                                <Form.Group as={Col}>
                                    <Label style={labelStyle}>Category Codes</Label>
                                    <FormControl type="text" value={query.categoryCodes} onChange={(e) => setQuery({...query, categoryCodes: e.target.value})}/>
                                </Form.Group>
                            </Row>
                        </>
                    }
                    <hr />

                    {/* Products IDs*/}
                    <h4 style={{marginBottom: "20px"}}>Product IDs<span style={{float: "right"}}>
                        <button className={!showAddProductListDialog ? "form-btn-ci-light-blue" : "form-btn-ci-blue"} type="button" onClick={() => {
                            if(!showAddProductListDialog) {
                                loadAllProducts(1, "", setLastProductsPage).then(r => {
                                    setProducts(r);
                                    setShowAddProductListDialog(true);
                                });
                            } else {
                                setShowAddProductListDialog(false);
                            }

                        }}>{!showAddProductListDialog ? "Add Product(s)" : "Close Dialog"}</button>
                    </span></h4>

                    {/* INCLUDED PRODUCTS*/}
                    {includedProducts.length > 0 &&
                        <Table responsive bordered hover striped>
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Title</th>
                                    <th>State</th>
                                    <th>Product Type</th>
                                    <th>IAP Product Identifier</th>
                                    <th>Remove</th>
                                </tr>
                            </thead>
                            <tbody>
                                {includedProducts.map(product => (
                                    <tr role="row" key={product.id}>
                                        <td><Link to={"/" + GlobalConstants.APP_PATH + "/products/" + product.id} style={{fontStyle: "italic", color: "#333"}}>{product.id}</Link></td>
                                        <td>{product.title}</td>
                                        <td><Badge bg={ProductStateBadges(product)}>{product.state}</Badge></td>
                                        <td><Badge bg={ProductTypeBadges(product)}>{product.productType}</Badge></td>
                                        <td>{product.iapProductIdentifier}</td>
                                        <td><button className="form-btn-ci-red" type="button"
                                            onClick={() => {
                                                let img = image;
                                                img.productIdentifier = img.productIdentifier.filter(id => id !== product.id);
                                                setImage(img);

                                                setIncludedProducts(includedProducts.filter(prod => prod.id !== product.id));
                                            }}><FiTrash2 /></button> </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    }

                    {/* ADD PRODUCT LIST*/}
                    {showAddProductListDialog &&
                    <>
                        <hr />
                        <h4>Add Products</h4>
                        <Form>
                            <Row>
                                <Form.Group as={Col}>
                                    <InputGroup>
                                        <FormControl type="text" placeholder="Search for a product title"
                                                     value={productFilterName} onChange={(e) => setProductFilterName(e.target.value)}/>
                                            <Button variant="outline-secondary" type="button"
                                                    onClick={() => loadAllProducts(1, productFilterName, setLastProductsPage).then(r => setProducts(r))}>Search</Button>
                                    </InputGroup>
                                </Form.Group>
                            </Row>
                        </Form>
                        <Table responsive bordered hover striped>
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Title</th>
                                    <th>State</th>
                                    <th>Product Type</th>
                                    <th>IAP Product Identifier</th>
                                </tr>
                            </thead>
                            <tbody>
                            {products.map(product => (
                                <tr role="row" key={product.id} style={{cursor: "pointer"}} onClick={() => {
                                    //Toggle the product
                                    if(!includedProducts.includes(product)){
                                        let img = image;
                                        img.productIdentifier = img.productIdentifier.concat(product.id);
                                        setImage(img);

                                        setIncludedProducts(includedProducts.concat(product));
                                    }
                                }}>
                                    <td>{product.id}</td>
                                    <td>{product.title}</td>
                                    <td><Badge bg={ProductStateBadges(product)}>{product.state}</Badge></td>
                                    <td><Badge bg={ProductTypeBadges(product)}>{product.productType}</Badge></td>
                                    <td>{product.iapProductIdentifier}</td>
                                </tr>
                            ))}
                            </tbody>
                        </Table>
                        <Pagination>
                            <Pagination.Item hidden={productsPage === 1}
                                             onClick={() => {
                                                 setProductsPage(1);
                                                 loadAllProducts(1, productFilterName, setLastProductsPage).then(r => setProducts(r));
                                             }}>&lt;&lt;</Pagination.Item>
                            <Pagination.Item hidden={productsPage === 1}
                                             onClick={() => {
                                                 loadAllProducts(productsPage - 1, productFilterName, setLastProductsPage).then(r => setProducts(r));
                                                 setProductsPage(productsPage - 1);
                                             }}>&lt;</Pagination.Item>
                            <Pagination.Item hidden={productsPage <= 2}
                                             onClick={() => {
                                                 loadAllProducts(productsPage - 2, productFilterName, setLastProductsPage).then(r => setProducts(r));
                                                 setProductsPage(productsPage - 2);
                                             }}>{productsPage - 2}</Pagination.Item>
                            <Pagination.Item hidden={productsPage === 1}
                                             onClick={() => {
                                                 loadAllProducts(productsPage - 1, productFilterName, setLastProductsPage).then(r => setProducts(r));
                                                 setProductsPage(productsPage - 1);
                                             }}>{productsPage - 1}</Pagination.Item>
                            <Pagination.Item active={true}>{productsPage}</Pagination.Item>
                            <Pagination.Item hidden={productsPage === lastProductsPage}
                                             onClick={() => {
                                                 loadAllProducts(productsPage + 1, productFilterName, setLastProductsPage).then(r => setProducts(r));
                                                 setProductsPage(productsPage + 1);
                                             }}>{productsPage + 1}</Pagination.Item>
                            <Pagination.Item hidden={productsPage > lastProductsPage - 2}
                                             onClick={() => {
                                                 loadAllProducts(productsPage + 2, productFilterName, setLastProductsPage).then(r => setProducts(r));
                                                 setProductsPage(productsPage + 2);
                                             }}>{productsPage + 2}</Pagination.Item>
                            <Pagination.Item hidden={productsPage === lastProductsPage}
                                             onClick={() => {
                                                 loadAllProducts(productsPage + 1, productFilterName, setLastProductsPage).then(r => setProducts(r));
                                                 setProductsPage(productsPage + 1);
                                             }}>&gt;</Pagination.Item>
                            <Pagination.Item hidden={productsPage === lastProductsPage}
                                             onClick={() => {
                                                 loadAllProducts(lastProductsPage, productFilterName, setLastProductsPage).then(r => setProducts(r));
                                                 setProductsPage(lastProductsPage);
                                             }}>&gt;&gt;</Pagination.Item>
                        </Pagination>
                    </>
                    }
                </Form>
            </div>
            <div style={{float: "right", marginTop: "20px"}}>
                <button className="form-btn-ci-red" onClick={() => {
                    let state = props.state;
                    state.showEditImageDialog = false;
                    props.onSetState(state);
                }}>Cancel</button>
                <PromiseButton text="Save" onClick={() => {
                    if(props.createNew) {
                        saveNewImage();
                    } else {
                        updateExistingImage();
                    }
                }} />
            </div>
        </div>
    );

    function updateImageParam(field, value) {
        setImage({...image, [field]: value});
    }

    function updateExistingImage() {
        let state = props.state;

        //We have to check the source of the image (cluster or shoplayout banner)
        let images = [];
        let sourceIsBanner = state.selectedItem.banner != null;
        if(sourceIsBanner) {
            images = state.selectedItem.banner.images;
        } else {
            images = state.selectedItem.shopLayoutImages;
        }

        //Update the query
        if(query != null) {
            image.query = query
        }

        //Update the images of the selected item list
        for(let i = 0; i < images.length; i++) {
            if(images[i].ordinal === state.selectedImage.ordinal) {
                images[i] = image;
            }
        }

        if(sourceIsBanner) {
            state.selectedItem.banner.images = images;

            //Update the shoplayout
            let items = state.shopLayout.layoutItems;
            for(let i = 0; i < items.length; i++) {
                if(items[i].ordinal === state.selectedItem.ordinal) {
                    items[i] = state.selectedItem;
                }
            }
            state.shopLayout.layoutItems = items;
        } else {
            state.selectedItem.shopLayoutImages = images;

            //Update the app in the cluster
            state.cluster["layoutItems"][String(state.selectedItem.appId)]["shopLayoutImages"] = images;
        }

        state.showEditImageDialog = false;
        props.onSetState(state);
    }

    function saveNewImage() {
        let state = props.state;

        //We have to check the source of the image (cluster or shoplayout banner)
        let images = [];
        let sourceIsBanner = state.selectedItem.banner != null;
        if(sourceIsBanner) {
            images = state.selectedItem.banner.images;
        } else {
            images = state.selectedItem.shopLayoutImages;
        }

        if(query != null) {
            image.query = query;
        }

        //Add the image to the list of the selected item
        images = images.concat(image);

        if(sourceIsBanner) {
            state.selectedItem.banner.images = images;
        } else {
            state.selectedItem.shopLayoutImages = images;

            //Update the app in the cluster
            state.cluster["layoutItems"][String(state.selectedItem.appId)]["shopLayoutImages"] = images;
        }

        state.showEditImageDialog = false;
        props.onSetState(state);
    }
}

//------------
// API METHODS
//------------
async function loadIncludedProducts(image) {
    let products = [];

    let loadedProducts = await GetFilteredProducts({productId: image.productIdentifier});

    if(!loadedProducts.error) {
        products = loadedProducts.result;
    }

    return products;
}

async function loadAllProducts(page, name, setLastPage) {
    let products = [];

    let loadedProducts = await GetFilteredProducts({page: page, title: name});

    if(!loadedProducts.error) {
        products = loadedProducts.result;
        setLastPage(Math.ceil(loadedProducts.length / 20));
    }

    return products;
}

//--------
// HELPERS
//--------

