import {Button} from "react-bootstrap";
import React, {Component} from "react";
import {withToast} from "../../util/ToastService";
import {Link} from "react-router-dom";
import {
    IoPeopleCircleOutline,
} from "react-icons/all";
import GlobalConstants from "../../config/GlobalConstants";
import {CreateMappedBillwerkProduct, GetMappedBillwerkProduct, UpdateMappedBillwerkProduct} from "./PremiumAccessTypeService";
import {DetailsSection} from "../../generators/DetailsGenerator";
import PremiumAccessTypeMetaData from "./PremiumAccessTypeMetaData";
import {PromiseButton} from "../../global/SpinningTiger";
import {ErrorHandler} from "../../util/ErrorHandler";

class MappedBillwerkProductDetails extends Component {

    //------------
    //Constructor
    //------------

    constructor(props) {
        super(props);

        this.state = {
            error: null,
            flowState: props.location.state,
            id: this.props.match.params.id,
            editMode: {
                active: false,
                expertMode: false,
                autoSave: true,
            },
            mappedBillwerkProduct: {},
            originalMappedBillwerkProduct: {},
            sections: {
                general: true,
                productDetails: true,
            }
        }
    }

    //---------
    //Mounting
    //---------

    async componentDidMount() {
        //Set the title
        if(this.state.id != null) {
            document.title = "Mapped Billwerk Product " + this.state.id + " :: Tiger UI";
        } else {
            document.title = "New Mapped Billwerk Product :: Tiger UI";
        }
        await this.loadMappedBillwerkProduct();
    }

    //--------
    //Loading
    //--------

    async loadMappedBillwerkProduct() {
        let loadedMappedBillwerkProduct = {};

        if(this.state.id != null && this.state.id !== "add") {

            loadedMappedBillwerkProduct = await GetMappedBillwerkProduct(this.state.id);

            if (!loadedMappedBillwerkProduct.error) {
                const mappedBillwerkProduct = loadedMappedBillwerkProduct.result;

                this.setState({
                    id: this.state.id,
                    editMode: this.state.editMode,
                    mappedBillwerkProduct: mappedBillwerkProduct,
                    originalMappedBillwerkProduct: mappedBillwerkProduct,
                });
            } else {
                this.setState(prevState => ({...prevState, error: loadedMappedBillwerkProduct}));
            }
        } else {
            this.setState((prevState) => ({...prevState,
                id: null,
                mappedBillwerkProduct: {
                    id: null,
                    type: 'DIGITICKET',
                    edition: 'NORMAL',
                    period: 'P1M',
                    landingPageId: GlobalConstants.DEFAULT_LANDING_PAGE_ID,
                    createdBy: 'system'
                },
                editMode: {
                    active: true,
                    expertMode: false,
                    autoSave: false,
                }}));
        }
    }

    //----------
    //Rendering
    //----------

    render() {

        return (
            <>
                <div className="details-title">
                    {this.state.mappedBillwerkProduct.id != null ? "Details of Mapped Billwerk Product " + this.state.mappedBillwerkProduct.id : "Create a new Mapped Billwerk Product"}
                </div>
                <div className="details-button-box" style={{height: "70px"}}>
                    <Link to={{pathname: "/" + GlobalConstants.APP_PATH +"purchases/premium-access-types", state: this.state.flowState}}>
                        <button className="form-btn-ci-light-blue" type="button">Back</button>
                    </Link>
                    {this.state.mappedBillwerkProduct.id != null &&
                    <button className={this.state.editMode.active ? "form-btn-ci-red" : "form-btn-ci-blue"} type="button" onClick={() => this.setState({
                        editMode: {
                            active: !this.state.editMode.active,
                            expertMode: this.state.editMode.expertMode
                        },
                        mappedBillwerkProduct: this.state.originalMappedBillwerkProduct
                    })}>{this.state.editMode.active ? "Cancel" : "Edit"}</button>
                    }
                    {this.state.editMode.active &&
                        <PromiseButton text="Save" onClick={() => this.saveOrUpdateMappedBillwerkProduct()} />
                    }
                    <Button style={{float: "right", marginTop: "5px", marginRight: "10px"}} variant={this.state.editMode.autoSave ? "secondary": "outline-secondary"}
                            onClick={() => this.setState(prevState => ({
                                ...prevState,
                                editMode: {
                                    ...prevState.editMode,
                                    autoSave: !this.state.editMode.autoSave
                                }
                            }))}>Auto Save</Button>
                </div>

                <div className="details-box-title">Resource Details</div>
                <div className="details-box">
                    {/* GENERAL DATA */}
                    <DetailsSection
                        nameInState="mappedBillwerkProduct"
                        fields={PremiumAccessTypeMetaData.BILLWERK_PRODUCT_DETAILS_GENERAL}
                        state={this.state}
                        onSetState={(s) => this.setState(s)}
                        onUpdateResource={() => this.saveOrUpdateMappedBillwerkProduct()}
                        sectionId="general"
                    />

                    <DetailsSection
                        nameInState="mappedBillwerkProduct"
                        fields={PremiumAccessTypeMetaData.BILLWERK_PRODUCT_PRODUCT_DETAILS}
                        state={this.state}
                        onSetState={(s) => this.setState(s)}
                        onUpdateResource={() => this.saveOrUpdateMappedBillwerkProduct()}
                        sectionId="productDetails"
                        label={<span><IoPeopleCircleOutline/>&#xA0;Product Details</span>}
                    />

                </div>
                <div className="details-button-box" style={{height: "70px"}}>
                    <Link to={{pathname: "/" + GlobalConstants.APP_PATH +"purchases/premium-access-types", state: this.state.flowState}}>
                        <button className="form-btn-ci-light-blue" type="button">Back</button>
                    </Link>
                    {this.state.editMode.active &&
                        <PromiseButton text="Save" onClick={() => this.saveOrUpdateMappedBillwerkProduct()} />
                    }
                </div>

                <ErrorHandler error={this.state.error}
                              onHide={() => this.setState(prevState => ({...prevState, error: null}))} />
            </>
        );
    }

    //------------
    //API Methods
    //------------

    async saveOrUpdateMappedBillwerkProduct() {
        let mappedBillwerkProduct = {};
        if(this.state.mappedBillwerkProduct.id != null) {
            mappedBillwerkProduct = await UpdateMappedBillwerkProduct(this.state.mappedBillwerkProduct);
        } else {
            mappedBillwerkProduct = await CreateMappedBillwerkProduct(this.state.mappedBillwerkProduct);
        }

        if (!mappedBillwerkProduct.error) {
            this.setState((prevState) =>({...prevState, mappedBillwerkProduct: mappedBillwerkProduct.result, editMode: {...prevState.editMode, active: this.state.editMode.autoSave}}));

            this.props.addToast("The mapped billwerk product has been updated successfully.", {
                autoDismiss: true,
                appearance: 'success'
            });
        } else {
            this.setState(prevState => ({...prevState, error: mappedBillwerkProduct}));
        }
    }
        
}


export default withToast(MappedBillwerkProductDetails);