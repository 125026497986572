import {Badge, Button, ButtonGroup, Col, Form, Table} from "react-bootstrap";
import Row from 'react-bootstrap/Row';
import FormSelect from "react-bootstrap/FormSelect";
import {AiFillLock, FiLoader, IoArrowRedoOutline} from "react-icons/all";
import {openExternalTargetInNewTab, transformStringToReadableDate} from "../../../util/ResourceService";
import React, {useState} from "react";
import GlobalConstants from "../../../config/GlobalConstants";
import {InfoModal} from "../../../generators/ModalGenerator";
import {GetBillwerkContractOfCustomer} from "../../../billwerk/customers/BillwerkCustomerService";
import {getPlanById, getPlanVariantById} from "../../../billwerk/BillwerkMetaData";
import {StopBillwerkContract} from "../../../billwerk/contracts/BillwerkContractService";
import {sortByParamDesc} from "../SupportToolHelper";

export function BillwerkTab(props) {
    const [customer] = useState(props.customer);
    const [contracts, setContracts] = useState([]);
    const [selectedContract, setSelectedContract] = useState({Phases: []});
    const [showPlanNames, setShowPlanNames] = useState(true);

    const [showStopContractModal, setShowStopContractModal] = useState(false);
    const [showContractSelectionModal, setShowContractSelectionModal] = useState(false);

    const labelStyle = {
        float: "left",
        fontSize: "20px",
        marginRight: "15px",
        marginTop: "10px",
        width: "250px"
    }

    async function loadContracts() {
        let contractResponse = await GetBillwerkContractOfCustomer(customer.Id);
        if(!contractResponse.error) {
            let contracts = contractResponse.result;
            setContracts(contracts);
            if(contracts.length < 1) {
                props.parentProps.addToast("Zu diesem Kunden wurden keine Verträge gefunden.", {autoDismiss: true, appearance: "info"});
            } else if(contracts.length > 1) {
                setShowContractSelectionModal(true);
            } else {
                setSelectedContract(contracts[0]);
            }
        } else {
            props.parentProps.addToast("Beim Laden der Verträge ist ein Fehler aufgetreten.", {autoDismiss: true, appearance: "error"});
        }
    }

    async function stopContract() {
        let response = await StopBillwerkContract(selectedContract.Id);
        if(!response.error) {
            props.parentProps.addToast("Der Vertrag wurde erfolgreich beendet.", {appearance: "success", autoDismiss: true});
        } else {
            props.parentProps.addToast("Beim Beenden des Vertrages ist ein Fehler aufgetreten.", {appearance: "error", autoDismiss: true});
        }
        setShowStopContractModal(false);
    }

    function setContractByReference(reference) {
        let contract = selectedContract;
        for(let i = 0; i < contracts.length; i++) {
            if(contracts[i].ReferenceCode === reference) {
                contract = contracts[i];
                break;
            }
        }
        setSelectedContract(contract);
    }

    return (
        <div>
            {customer.Id != null ?
                <>
                    <p style={{marginBottom: "40px", height: "60px"}}>
                        <button className="form-btn-ci-blue" style={{marginRight: "10px"}}
                                onClick={() => openExternalTargetInNewTab(GlobalConstants.BILLWERK_BASE_URL + '#/customers/' + customer.Id)}
                        ><IoArrowRedoOutline size={22}/> In Billwerk öffnen
                        </button>
                        <button className="form-btn-ci-light-blue" style={{marginRight: "10px"}}
                                onClick={() => loadContracts()}>
                            <FiLoader size={22}/> Verträge laden
                        </button>
                        {/**
                        <div style={{float: "right"}}>
                            <button className="form-btn-ci-red"
                                   onClick={() => setShowDeletionModal(true)}>
                                <AiFillLock size={22}/> Account
                                sperren
                            </button>
                        </div>
                        **/}
                    </p>

                    <Form style={{marginTop: "10px"}}>
                        <Row>
                            <Form.Group as={Col}>
                                <div style={{...labelStyle, paddingLeft: "100px"}}>Billwerk-ID</div>
                                <Form.Control style={{width: "400px"}} type="text"
                                              value={customer.Id} disabled={true}/>
                            </Form.Group>
                            <Form.Group as={Col}>
                                <div style={labelStyle}>Erstellt am</div>
                                <Form.Control style={{width: "300px"}} type="text" value={transformStringToReadableDate(customer.CreatedAt)}
                                              disabled={true}/>
                            </Form.Group>
                        </Row>
                        <Row>
                            <Form.Group as={Col}>
                                <div style={{...labelStyle, paddingLeft: "100px"}}>Löschbar</div>
                                <Badge bg={customer.IsDeletable ? "success" : "danger"} style={{
                                    fontSize: "16px",
                                    marginTop: "12px"
                                }}>{customer.IsDeletable ? "Ja" : "Nein"}</Badge>
                            </Form.Group>
                            <Form.Group as={Col}>
                                <div style={{...labelStyle}}>Gesperrt</div>
                                <Badge bg={customer.IsLocked ? "success" : "danger"} style={{
                                    fontSize: "16px",
                                    marginTop: "12px"
                                }}>{customer.IsLocked ? "Ja" : "Nein"}</Badge>
                            </Form.Group>
                        </Row>
                    </Form>

                    {contracts.length > 0 &&
                        <>
                            <hr />
                            <div className="support-tool-box">
                                <Form style={{paddingLeft: "10px"}}>
                                    <Row>
                                        <Form.Group>
                                            <div style={{...labelStyle}}>Ausgewählter Vertrag:</div>
                                            <FormSelect style={{cursor: "pointer"}} disabled={contracts.length === 0} value={selectedContract.ReferenceCode} onChange={(e) => setContractByReference(e.target.value)}>
                                                {contracts.map(c => (
                                                    <option value={c.ReferenceCode}>{c.ReferenceCode}</option>
                                                ))}
                                            </FormSelect>
                                        </Form.Group>
                                        <Form.Group>
                                            <Badge style={{fontSize: "20px", marginTop: "43px", marginLeft: "20px"}} bg={selectedContract.LifecycleStatus === 'Active' ? "success" : (selectedContract.LifecycleStatus === 'Inactive' ? 'warning' : (selectedContract.LifecycleStatus === 'Ended' ? 'danger' : 'secondary'))}>
                                                {selectedContract.LifecycleStatus === 'Active' ? "Aktiv" : (selectedContract.LifecycleStatus === 'Inactive' ? "Inaktiv" : (selectedContract.LifecycleStatus === 'Ended' ? "Beended" : selectedContract.LifecycleStatus))}
                                            </Badge>
                                        </Form.Group>
                                    </Row>
                                </Form>

                                <div style={{float: "right", marginTop: "-60px"}}>

                                    <ButtonGroup className="mb-3" style={{height: "42px", float: "left", marginRight: "50px"}}>
                                        <Button
                                            variant={showPlanNames ? "secondary" : "outline-secondary"}
                                            onClick={() => setShowPlanNames(true)}>Produkt-Name</Button>
                                        <Button
                                            variant={!showPlanNames ? "secondary" : "outline-secondary"}
                                            onClick={() => setShowPlanNames(false)}>Produkt-ID</Button>
                                    </ButtonGroup>

                                    <button className="form-btn-ci-red"
                                            onClick={() => setShowStopContractModal(true)}>
                                        <AiFillLock size={22}/> Vertrag beenden
                                    </button>

                                </div>

                                {selectedContract.Phases.length > 0 &&
                                <div style={{marginTop: "25px"}}>
                                    <Table responsive bordered striped hover>
                                        <thead>
                                        <th>Beginn</th>
                                        <th>Art</th>
                                        <th>Anzahl</th>
                                        <th>Paket</th>
                                        <th>Paketvariante</th>
                                        </thead>
                                        <tbody>
                                        {sortByParamDesc(selectedContract.Phases, 'StartDate').map(p => (
                                            <tr role="row" key={p.PlanVariantId}>
                                                <td>{transformStringToReadableDate(p.StartDate, true)}</td>
                                                <td>{p.Type}</td>
                                                <td>{p.Quantity}</td>
                                                <td>{showPlanNames ? getPlanById(p.PlanId).name : p.PlanId}</td>
                                                <td>{showPlanNames ? getPlanVariantById(p.PlanVariantId).name : p.PlanVariantId}</td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </Table>
                                </div>
                                }
                            </div>
                        </>

                    }

                </>
                :
                <div style={{textAlign: "center", fontSize: "22px"}}>
                    Zu dieser E-Mail-Adresse wurde kein Konto bei Billwerk gefunden.
                </div>
            }

            <InfoModal show={showStopContractModal}
                       size="m"
                       hideFooter={true}
                       onHide={() => setShowStopContractModal(false)}
                       title={"Billwerk-Vertrag beenden"}
                       body={
                           <>
                               <div style={{textAlign: "center"}}>
                                   Soll der ausgewählte Billwerk-Vertrag <span style={{fontWeight: "bold"}}>{selectedContract.ReferenceCode}</span> für den folgenden Kunden wirklich beendet werden?
                               </div>
                               <div style={{marginLeft: "5%", marginTop: "20px"}}>
                                   <ul style={{listStyleType: "square"}}>
                                       <li><span style={{fontWeight: "bold"}}>Billwerk-ID:</span> {customer.Id}</li>
                                       <li><span style={{fontWeight: "bold"}}>E-Mail-Adresse:</span> {customer.EmailAddress}</li>
                                   </ul>
                               </div>
                               <div style={{textAlign: "center", marginTop: "20px", marginLeft: "20%"}}>
                                   <button className="form-btn-ci-red" onClick={() => stopContract()}>Vertrag beenden</button>
                                   <button className="form-btn-ci-light" onClick={() => setShowStopContractModal(false)}>Abbrechen</button>
                               </div>
                           </>
                       }
           />

            <InfoModal show={showContractSelectionModal}
                       hideFooter={true}
                       onHide={() => setShowContractSelectionModal(false)}
                       title={"Bitte wähle einen Vertrag aus der Liste:"}
                       body={
                           <>
                               <Table bordered responsive hover striped>
                                   <thead>
                                        <tr>
                                            <th>Reference</th>
                                            <th>Lifecycle Status</th>
                                            <th>Anzahl an Phasen</th>
                                            <th>Vertragsstart</th>
                                            <th>Vertragende</th>
                                        </tr>
                                   </thead>
                                   <tbody>
                                   {contracts.map(c => (
                                       <tr role="row" key={c.Id} style={{cursor: "pointer"}} onClick={() => {
                                           setSelectedContract(c);
                                           setShowContractSelectionModal(false);
                                       }}>
                                           <td>{c.ReferenceCode}</td>
                                           <td><Badge bg={c.LifecycleStatus === 'Active' ? "success" : "danger"}>{c.LifecycleStatus === 'Active' ? "Aktiv" : "Inaktiv"}</Badge></td>
                                           <td>{c.Phases.length}</td>
                                           <td>{transformStringToReadableDate(c.StartDate)}</td>
                                           <td>{transformStringToReadableDate(c.EndDate)}</td>
                                       </tr>
                                   ))}
                                   </tbody>
                               </Table>
                               <div style={{float: "right", marginRight: "10%"}}>
                                   <button className="form-btn-ci-light" onClick={() => setShowContractSelectionModal(false)}>Abbrechen</button>
                               </div>
                           </>
                       }
            />
        </div>
    )
}