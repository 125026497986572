import React, {Component} from "react";
import {withToast} from "../../util/ToastService";
import {Link} from "react-router-dom";
import {
    FaBook,
} from "react-icons/all";
import GlobalConstants from "../../config/GlobalConstants";
import {GetOrderFailureLog} from "./OrderFailureLogService";
import {DetailsSection} from "../../generators/DetailsGenerator";
import OrderFailureLogMetaData from "./OrderFailureLogMetaData";
import {PromiseButton} from "../../global/SpinningTiger";
import {ErrorHandler} from "../../util/ErrorHandler";

class OrderFailureLogDetails extends Component {

    //------------
    //Constructor
    //------------

    constructor(props) {
        super(props);

        this.state = {
            error: null,
            flowState: props.location.state,
            id: this.props.match.params.id,
            editMode: {
                active: false,
                expertMode: false,
                autoSave: true,
            },
            orderFailureLog: {},
            originalOrderFailureLog: {},
            sections: {
                general: true,
                failureDetails: true,
            }
        }
    }

    //---------
    //Mounting
    //---------

    async componentDidMount() {
        //Set the title
        if(this.state.id != null) {
            document.title = "Order Failure Log " + this.state.id + " :: Tiger UI";
        } else {
            document.title = "New order failure log :: Tiger UI";
        }
        await this.loadOrderFailureLog();
    }

    //--------
    //Loading
    //--------

    async loadOrderFailureLog() {
        let loadedOrderFailureLog = {};

        if(this.state.id != null && this.state.id !== "add") {

            loadedOrderFailureLog = await GetOrderFailureLog(this.state.id);

            if (!loadedOrderFailureLog.error) {
                const orderFailureLog = loadedOrderFailureLog.result;

                this.setState({
                    id: this.state.id,
                    editMode: this.state.editMode,
                    orderFailureLog: orderFailureLog,
                    originalOrderFailureLog: orderFailureLog,
                });
            } else {
                this.setState(prevState => ({...prevState, error: loadedOrderFailureLog}));
            }
        } else {
            this.setState((prevState) => ({...prevState,
                id: null,
                orderFailureLog: {
                    id: null,
                },
                editMode: {
                    active: true,
                    expertMode: false,
                    autoSave: false
                }}));
        }
    }

    //----------
    //Rendering
    //----------

    render() {

        return (
            <>
                <div className="details-title">
                    {this.state.orderFailureLog.id != null ? "Details of OrderFailureLog " + this.state.orderFailureLog.id : "Create a new OrderFailureLog"}
                </div>

                <div className="details-box-title">Resource Details</div>
                <div className="details-box">
                    {/* GENERAL DATA */}
                    <DetailsSection
                        nameInState="orderFailureLog"
                        fields={OrderFailureLogMetaData.DETAILS_GENERAL}
                        state={this.state}
                        onSetState={(s) => this.setState(s)}
                        onUpdateResource={() => this.saveOrUpdateOrderFailureLog()}
                        sectionId="general"
                    />

                    {/* SECTION 1 */}
                    <DetailsSection
                        nameInState="orderFailureLog"
                        fields={OrderFailureLogMetaData.FAILURE_DETAILS}
                        state={this.state}
                        onSetState={(s) => this.setState(s)}
                        sectionId="failureDetails"
                        onUpdateResource={() => this.saveOrUpdateOrderFailureLog()}
                        label={<span><FaBook/>&#xA0;Failure Details</span>}
                    />

                </div>
                <div className="details-button-box" style={{height: "70px"}}>
                    <Link to={{pathname: "/" + GlobalConstants.APP_PATH +"orderFailureLogs", state: this.state.flowState}}>
                        <button className="form-btn-ci-light-blue" type="button">Back</button>
                    </Link>
                    {this.state.editMode.active &&
                    <PromiseButton text="Save" onClick={() => this.saveOrUpdateOrderFailureLog()} />
                    }
                </div>

                <ErrorHandler error={this.state.error}
                              onHide={() => this.setState(prevState => ({...prevState, error: null}))} />
            </>
        );
    }

    //------------
    //API Methods
    //------------

    async saveOrUpdateOrderFailureLog() {
        let orderFailureLog = {};
        /**
        if(this.state.orderFailureLog.id != null) {
            orderFailureLog = await UpdateOrderFailureLog(this.state.orderFailureLog);
        } else {
            orderFailureLog = await CreateOrderFailureLog(this.state.orderFailureLog);
        }*/

        if (!orderFailureLog.error) {
            this.setState((prevState) =>({...prevState, orderFailureLog: orderFailureLog.result, editMode: {...prevState.editMode, active: false}}));

            this.props.addToast("The orderFailureLog has been updated successfully.", {
                autoDismiss: true,
                appearance: 'success'
            });
        } else {
            this.setState(prevState => ({...prevState, error: orderFailureLog}));
        }
    }

}


export default withToast(OrderFailureLogDetails);