import {
    CreateSpineResource,
    DeleteSpineResource,
    GetFilteredSpineResources,
    GetSpineResource,
    UpdateSpineResource
} from "../../clients/SpineClient";

//API METHODS
export async function CreateAffiliate(affiliate) {
    return await CreateSpineResource('api/affiliates', affiliate);
}

export async function GetFilteredAffiliates(filter){
    return await GetFilteredSpineResources('api/affiliates', filter);
}

export async function GetAffiliate(id) {
    return await GetSpineResource('api/affiliates/' + id);
}

export async function UpdateAffiliate(affiliate) {
    return await UpdateSpineResource('api/affiliates/' + affiliate.id, affiliate);
}

export async function DeleteAffiliate(affiliate) {
    return await DeleteSpineResource('api/affiliates/' + affiliate.id);
}

//---------- OPTIONAL -------------
/*
export async function PatchExample(example, newValue) {
    return await PatchResource('api/examples/' + example.id + '/patch', {attribute: newValue});
}
 */