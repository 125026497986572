import {
    CreateSpineResource,
    DeleteSpineResource,
    GetFilteredSpineResources,
    GetSpineResource,
    UpdateSpineResource
} from "../../clients/SpineClient";

//API METHODS

//COUPON GROUPS
export async function CreateCouponGroup(couponGroup) {
    return await CreateSpineResource('api/couponGroups', couponGroup);
}

export async function GetFilteredCouponGroups(filter){
    return await GetFilteredSpineResources('api/couponGroups', filter);
}

export async function GetCouponGroup(id) {
    return await GetSpineResource('api/couponGroups/' + id);
}

export async function UpdateCouponGroup(couponGroup) {
    return await UpdateSpineResource('api/couponGroups/' + couponGroup.id, couponGroup);
}

export async function DeleteCouponGroup(couponGroup) {
    return await DeleteSpineResource('api/couponGroups/' + couponGroup.id);
}

//COUPONS
export async function CreateCoupon(groupId, coupon) {
    return await CreateSpineResource('api/couponGroups/' + groupId + '/coupons', coupon);
}

export async function GetFilteredCoupons(groupId, filter) {
    return await GetFilteredSpineResources('api/couponGroups/' + groupId + '/coupons', filter);
}

export async function GetCoupon(groupId, id) {
    return await GetSpineResource('api/couponGroups/' + groupId + '/coupons/' + id);
}

export async function UpdateCoupon(groupId, coupon) {
    return await UpdateSpineResource('api/couponGroups/' + groupId + '/coupons/' + coupon.id, coupon);
}

export async function DeleteCoupon(groupId, coupon) {
    return await DeleteSpineResource('api/couponGroups/' + groupId + '/coupons/' + coupon.id, false);
}

export async function CouponBulkCreation(groupId, bulkRequest) {
    return await CreateSpineResource('api/couponGroups/' + groupId + '/coupons/bulkcreate', bulkRequest, false);
}



//---------- OPTIONAL -------------
/*
export async function PatchCouponGroup(CouponGroup, newValue) {
    return await PatchResource('api/CouponGroups/' + CouponGroup.id + '/patch', {attribute: newValue});
}
 */