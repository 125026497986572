import {Table} from "react-bootstrap";
import React, {Component} from "react";
import {withToast} from "../../util/ToastService";
import {Link} from "react-router-dom";
import {
    BiErrorAlt,
    BiReceipt, BsListUl,
    IoPeopleCircleOutline,
} from "react-icons/all";
import GlobalConstants from "../../config/GlobalConstants";
import {GetOrder} from "./OrderService";
import {DetailsSection} from "../../generators/DetailsGenerator";
import OrderMetaData from "./OrderMetaData";
import {GetFilteredOrderFailureLogs} from "../orderFailureLog/OrderFailureLogService";
import {PromiseButton} from "../../global/SpinningTiger";

class OrderDetails extends Component {

    //------------
    //Constructor
    //------------

    constructor(props) {
        super(props);

        this.state = {
            flowState: props.location.state,
            id: this.props.match.params.id,
            editMode: {
                active: false,
                expertMode: false,
                autoSave: true,
            },
            order: {
                lineItems: []
            },
            originalOrder: {},
            failureLogs: [],
            sections: {
                general: true,
                customerDetails: true,
                receiptDetails: true,
                lineItems: true,
                failureLogs: true,
            }
        }
    }

    //---------
    //Mounting
    //---------

    async componentDidMount() {
        //Set the title
        if(this.state.id != null) {
            document.title = "Order " + this.state.id + " :: Tiger UI";
        } else {
            document.title = "New order :: Tiger UI";
        }
        await this.loadOrder();
        await this.loadFailureLogs();
    }

    //--------
    //Loading
    //--------

    async loadOrder() {
        let loadedOrder = {};

        if(this.state.id != null && this.state.id !== "add") {

            loadedOrder = await GetOrder(this.state.id);

            if (!loadedOrder.error) {
                const order = loadedOrder.result;

                this.setState({
                    id: this.state.id,
                    editMode: this.state.editMode,
                    order: order,
                    originalOrder: order,
                });
            } else {
                this.props.addToast("An error occurred during fetching order " + this.state.id + ". Cause: " + loadedOrder.message, {
                    autoDismiss: true,
                    appearance: 'error'
                });
            }
        } else {
            this.setState((prevState) => ({...prevState,
                id: null,
                order: {
                    id: null,
                },
                editMode: {
                    active: true,
                    expertMode: false,
                    autoSave: false
                }}));
        }
    }

    async loadFailureLogs(){
        let loadedLogs = await GetFilteredOrderFailureLogs({orderId: this.state.id});
        if(!loadedLogs.error) {
            const logs = loadedLogs.result;

            this.setState(prevState => ({...prevState, failureLogs: logs}));
        } else {
            this.props.addToast("An error occurred during fetching order failure logs of order " + this.state.id + ". Cause: " + loadedLogs.message, {
                autoDismiss: true,
                appearance: 'error'
            });
        }
    }


    //----------
    //Rendering
    //----------

    render() {

        return (
            <>
                <div className="details-title">
                    {"Details of Order " + this.props.match.params.id}
                </div>

                <div className="details-box-title">Resource Details</div>
                <div className="details-box">
                    {/* GENERAL DATA */}
                    <DetailsSection
                        nameInState="order"
                        fields={OrderMetaData.DETAILS_GENERAL}
                        state={this.state}
                        onSetState={(s) => this.setState(s)}
                        onUpdateResource={() => this.saveOrUpdateOrder()}
                        sectionId="general"
                    />

                    {/* CUSTOMER DETAILS */}
                    <DetailsSection
                        nameInState="order"
                        fields={OrderMetaData.DETAILS_CUSTOMER}
                        state={this.state}
                        onSetState={(s) => this.setState(s)}
                        sectionId="customerDetails"
                        onUpdateResource={() => this.saveOrUpdateOrder()}
                        label={<span><IoPeopleCircleOutline/>&#xA0;Customer Details</span>}
                    />

                    {/* RECEIPT DETAILS */}
                    <DetailsSection
                        nameInState="order"
                        fields={OrderMetaData.DETAILS_RECEIPT}
                        state={this.state}
                        onSetState={(s) => this.setState(s)}
                        sectionId="receiptDetails"
                        onUpdateResource={() => this.saveOrUpdateOrder()}
                        label={<span><BiReceipt/>&#xA0;Receipt Details</span>}
                    />

                    {/* LINE ITEMS */}
                    <DetailsSection
                        nameInState="order"
                        fields={[]}
                        state={this.state}
                        onSetState={(s) => this.setState(s)}
                        sectionId="lineItems"
                        label={<span><BsListUl/>&#xA0;Line Items</span>}
                    />

                    <Table responsive striped bordered hover>
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Amount</th>
                                <th>Currency Code</th>
                                <th>Country Code</th>
                                <th>Product IAP</th>
                                <th>Product Name</th>
                            </tr>
                        </thead>
                        <tbody>
                        {this.state.order.lineItems.map(item => (
                            <tr role="row" key={item.id} style={{cursor: "pointer"}}>
                                <td>{item.id}</td>
                                <td>{item.amount}</td>
                                <td>{item.currencyCode}</td>
                                <td>{item.countryCode}</td>
                                <td>{item.productIap}</td>
                                <td>{item.productName}</td>
                            </tr>
                        ))}
                        </tbody>
                    </Table>

                    {/* FAILURE LOGS */}
                    <DetailsSection
                        nameInState="order"
                        fields={[]}
                        state={this.state}
                        onSetState={(s) => this.setState(s)}
                        sectionId="failureLogs"
                        label={<span><BiErrorAlt/>&#xA0;Failure Logs</span>}
                    />

                    <Table responsive striped bordered hover>
                        <thead>
                        <tr>
                            <th>ID</th>
                            <th>Reason</th>
                            <th>Timestamp</th>
                            <th>Stack Trace</th>
                        </tr>
                        </thead>
                        <tbody>
                        {this.state.failureLogs.map(log => (
                            <tr role="row" key={log.id} style={{cursor: "pointer"}}>
                                <td>{log.id}</td>
                                <td>{log.reason}</td>
                                <td>{log.failureTimestamp}</td>
                                <td>{log.failureStackTrace}</td>
                            </tr>
                        ))}
                        </tbody>
                    </Table>

                </div>
                <div className="details-button-box" style={{height: "70px"}}>
                    <Link to={{pathname: "/" + GlobalConstants.APP_PATH +"orders", state: this.state.flowState}}>
                        <button className="form-btn-ci-light-blue" type="button">Back</button>
                    </Link>
                    {this.state.editMode.active &&
                    <PromiseButton text="Save" onClick={() => this.saveOrUpdateOrder()} />
                    }
                </div>
            </>
        );
    }

    //------------
    //API Methods
    //------------

    async saveOrUpdateOrder() {
        let order = {};
        /**
        if(this.state.order.id != null) {
            order = await UpdateOrder(this.state.order);
        } else {
            order = await CreateOrder(this.state.order);
        }*/

        if (!order.error) {
            this.setState((prevState) =>({...prevState, order: order.result, editMode: {...prevState.editMode, active: false}}));

            this.props.addToast("The order has been updated successfully.", {
                autoDismiss: true,
                appearance: 'success'
            });
        } else {
            this.props.addToast("An error occurred. Cause: " + order.message + ".", {
                autoDismiss: true,
                appearance: 'error'
            });
        }
    }

}


export default withToast(OrderDetails);