import React, {useState} from "react";
import CSVReader from "react-csv-reader";
import {
    BsInfoCircle, FiRepeat,
    ImArrowDownRight2, ImArrowRight2,
    ImArrowUpRight2, ImSigma, IoArrowBack, IoArrowForward, IoTicketOutline, VscQuestion
} from "react-icons/all";
import {Pagination, ProgressBar, Table} from "react-bootstrap";
import {Line, Pie} from "react-chartjs-2";
import {getDay, getMonth, getYear} from "../../util/ResourceService";

const tigertippsStartDate = "2021-07-13T00:00:00";

const papaparseOptions = {
    header: true,
    dynamicTyping: true,
    skipEmptyLines: true,
    transformHeader: header => header.toLowerCase().replace(/\W/g, "_")
};

export function TigertippsStatistics(props) {

    //File & raw data
    let [data, setData] = useState();
    let [fileInfo, setFileInfo] = useState();

    //Processed data
    let [tigertippsDetails, setTigertippsDetails] = useState({});
    let [subscriptionDetails, setSubscriptionDetails] = useState([]);
    let [subscriptionList, setSubscriptionList] = useState([]);
    let [subscriptionPage, setSubscriptionPage] = useState(1);

    //Charts
    let [customerDistributionChart, setCustomerDistributionChart] = useState({});
    let [activeUserChart, setActiveUserChart] = useState({});
    let [subscriptionChart, setSubscriptionChart] = useState({});

    //Processing
    let [fileUploaded, setFileUploaded] = useState(false);
    let [processing, setProcessing] = useState(false);
    let [progress, setProgress] = useState(0);
    let [action, setAction] = useState("");

    const handleForce = (data, fileInfo) => {
        setData(data);
        setFileInfo(fileInfo);
        setFileUploaded(true);
    }

    return (
        <>
            {/* Header */}
            <div className="content-header">
                <div className="content-header-title">Mailjet Analysis Tools</div>
            </div>

            {/* Upload */}
            <div className="content-box-centered">
                <h4 className="react-csv-input-label">1. Upload a .csv file</h4>
                <hr/>
                <CSVReader
                    cssClass="react-csv-input"
                    onFileLoaded={handleForce}
                    parserOptions={papaparseOptions}
                />
                {fileUploaded &&
                <p>
                    <span style={{marginLeft: "20px", fontSize: "20px"}}><BsInfoCircle/>&#xA0;</span>
                    <span style={{
                        verticalAlign: "center",
                        fontFamily: "Mikado"
                    }}> Size: {fileInfo.size}kB - Entries: {data.length}</span>
                </p>
                }
            </div>

            {/* Action */}
            <div className="content-box-centered">
                <h4 className="react-csv-input-label">2. Select the an action</h4>
                <hr/>
                <div className="row" style={{marginLeft: "10px"}}>
                    <div className="col-md-2">
                        <button className={fileUploaded ? "form-btn-ci" : "form-btn-ci-off"}
                                onClick={() => {
                                    setAction("");
                                    processTigertippsData(data, setTigertippsDetails, setSubscriptionList, setSubscriptionDetails, setProgress, setProcessing,
                                        setAction, setCustomerDistributionChart, setSubscriptionChart, setActiveUserChart);
                                }}>
                            Analyze tigertipps
                        </button>
                    </div>
                </div>
            </div>

            {/* Result */}
            {action !== "" &&
            <div className="content-box-centered">
                <h4 className="react-csv-input-label">Result</h4>
                <hr/>

                {/* Progress bar */}
                {processing &&
                <ProgressBar animated variant="danger" now={progress} label={`${progress}%`}/>
                }

                {/* Tigertipps */}
                {action === "tigertipps" &&
                <>
                    <div className="row" style={{marginLeft: "10px", marginBottom: "20px", fontFamily: "Mikado"}}>
                        <h4>Customer distribution (total):</h4>
                    </div>
                    <div className="row" style={{alignItems: "center", margin: "auto"}}>
                        <div className="col-md-3">
                            <p style={{color: 'rgba(54, 162, 235, 1)'}}>
                                <ImArrowUpRight2/>&#xA0;Active: {tigertippsDetails.activeSinceLive}</p>
                            <p style={{color: 'rgba(255, 99, 132, 1)'}}>
                                <ImArrowDownRight2/>&#xA0;Unsubscribed: {tigertippsDetails.unsubscribedSinceLive}</p>
                            <hr/>
                            <p><ImArrowRight2/>&#xA0;Total: {tigertippsDetails.totalSinceLive}</p>
                        </div>
                        <div className="col-md-3">
                            <Pie data={customerDistributionChart}/>
                        </div>
                        <div className="col-md-3">
                            <p><IoTicketOutline/>&#xA0;Active
                                Tickets: {subscriptionDetails.map(sub => sub.source).filter(source => source === "Ticket").length}
                            </p>
                            <p><FiRepeat/>&#xA0;Active
                                Subscriptions: {subscriptionDetails.map(sub => sub.source).filter(source => source === "Abo").length}
                            </p>
                            <p><VscQuestion/>&#xA0;
                                Other: {subscriptionDetails.map(sub => sub.source).filter(source => (source !== "Abo" && source !== "Ticket")).length}
                            </p>
                            <hr/>
                            <p><ImSigma/>&#xA0;Total: {subscriptionDetails.length}</p>
                        </div>
                        <div className="col-md-3">
                            <Pie data={activeUserChart}/>
                        </div>
                    </div>
                    <hr/>
                    <div className="row" style={{marginLeft: "10px", fontFamily: "Mikado"}}>
                        <h4>This month:</h4>
                    </div>
                    <div className="row">
                        <Line data={subscriptionChart}/>
                    </div>
                    {subscriptionDetails.length > 0 &&
                    <div style={{padding: "10px"}}>
                        <hr/>
                        <div className="row" style={{marginLeft: "10px", fontFamily: "Mikado"}}>
                            <h4>Tickets and subscription list:</h4>
                        </div>
                        <Table responsive striped hover bordered>
                            <thead>
                            <tr>
                                <th>E-Mail</th>
                                <th>Type</th>
                                <th>Validity</th>
                            </tr>
                            </thead>
                            <tbody>
                            {subscriptionList.map(sub => (
                                <tr role="row" key={sub.email}>
                                    <td>{sub.email}</td>
                                    <td>{sub.source}</td>
                                    <td>{sub.validity}</td>
                                </tr>
                            ))}
                            </tbody>
                        </Table>
                        <Pagination>
                            <>

                                {subscriptionPage > 1 &&
                                <Pagination.Item key={"back"} onClick={() => {
                                    setSubscriptionDetailsList(subscriptionDetails, subscriptionPage - 1, setSubscriptionList);
                                    setSubscriptionPage(subscriptionPage - 1);
                                }}><IoArrowBack/></Pagination.Item>
                                }
                                {subscriptionPage < Math.ceil(subscriptionDetails.length / 25) &&
                                <Pagination.Item key={"next"} onClick={() => {
                                    setSubscriptionDetailsList(subscriptionDetails, subscriptionPage + 1, setSubscriptionList);
                                    setSubscriptionPage(subscriptionPage + 1);
                                }}><IoArrowForward/></Pagination.Item>
                                }
                            </>
                        </Pagination>
                        Page {subscriptionPage} of {Math.ceil(subscriptionDetails.length / 25) + 1}
                    </div>
                    }
                </>
                }
            </div>
            }
        </>
    );
}

function processTigertippsData(data, setTigertippsDetails, setSubscriptionList, setSubscriptionDetails, setProgress, setProcessing, setAction, setCustomerDistributionChart, setSubscriptionChart, setActiveUserChart) {
    //Reads the newsletter data, i.e. the total number of users, the number of active users,
    //the number of users that have received the first, second etc. mail

    let processedDetails = {
         totalInList: null,
         activeInList: null,
         unsubscribedInList: null,
         totalSinceLive: null,
         activeSinceLive: null,
         unsubscribedSinceLive: null,
    }

    let processedSubscriptionDetails = [];

    let unsubscribedInList = 0;
    let totalSinceLive = 0;
    let unsubscribedSinceLive = 0;
    const startDate = Date.parse(tigertippsStartDate);

    //Process
    let progress = 0;
    setProgress(progress);
    setProcessing(true);

    //Loop over the whole data
    for(let i = 0; i < data.length; i++) {
        //Check if the customer has been added to the mailjet list after the official start of tigertipps
        let parsedSubscribtionDate = Date.parse(data[i].subscribedat);
        if(parsedSubscribtionDate >= startDate) {
            totalSinceLive++;
            if(data[i].unsubscribed !== "f") {
                unsubscribedSinceLive++;
            }
        }

        //Get the number of unsubscriptions
        if(data[i].unsubscribed !== "f") {
            unsubscribedInList++;
        }

        //Get the number of active subscriptions and its details
        let subscriptionDetails = {
            email: null,
            validity: null,
            source: null,
        };

        if(data[i].subscriptionvaliduntil != null) {
            subscriptionDetails.validity = data[i].subscriptionvaliduntil;
        }

        if(data[i].purchaseresource != null) {
            subscriptionDetails.source = data[i].purchaseresource;
        }

        if(data[i].subscriptionvaliduntil != null || data[i].purchaseresource != null) {
            subscriptionDetails.email = data[i].email;
            processedSubscriptionDetails.push(subscriptionDetails);
        }

        //Set the progress
        progress = Math.round(100 * i/data.length);
        setProgress(progress);
    }

    setProcessing(false);

    //Set the data
    processedDetails.totalInList = data.length;
    processedDetails.unsubscribedInList = unsubscribedInList;
    processedDetails.activeInList = processedDetails.totalInList - processedDetails.unsubscribedInList;
    processedDetails.totalSinceLive = totalSinceLive;
    processedDetails.unsubscribedSinceLive = unsubscribedSinceLive;
    processedDetails.activeSinceLive = processedDetails.totalSinceLive - processedDetails.unsubscribedSinceLive;

    setTigertippsDetails(processedDetails);
    setSubscriptionDetails(processedSubscriptionDetails);
    setAction("tigertipps");

    //Chart generation
    setCustomerDistributionChart(genererateCustomerDistributionChart(processedDetails));
    setSubscriptionChart(generateSubscriptionChartThisMonth(data));
    setActiveUserChart(genererateChartOfActiveUser(processedSubscriptionDetails.length, data.length - processedSubscriptionDetails.length));


    //Generation of the subscription list
    setSubscriptionDetailsList(processedSubscriptionDetails, 1, setSubscriptionList);

}

//Charts
function genererateCustomerDistributionChart(data) {
    return {
        labels: ['Subscribed', 'Unsubscribed'],
        datasets: [
            {
                label: 'Customer distribution',
                data: [data.activeSinceLive, data.unsubscribedSinceLive],
                backgroundColor: [
                    'rgba(54, 162, 235, 0.2)',
                    'rgba(255, 99, 132, 0.2)'
                ],
                borderColor: [
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 99, 132, 1)'
                ],
                borderWidth: 1,
            },
        ],
    };
}

function genererateChartOfActiveUser(active, inactive) {
    return {
        labels: ['Active', 'Inactive'],
        datasets: [
            {
                label: 'Customer distribution',
                data: [active, inactive],
                backgroundColor: [
                    'rgba(54, 162, 235, 0.2)',
                    'rgba(255, 99, 132, 0.2)'
                ],
                borderColor: [
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 99, 132, 1)'
                ],
                borderWidth: 1,
            },
        ],
    };
}

function generateSubscriptionChartThisMonth(data) {
    let today = new Date(Date.now());

    //Generate the xlables
    let xLabels = [];
    for (let i = 1; i <= today.getDate(); i++) {
        xLabels.push(new Date(today.getFullYear(), today.getMonth(), i).toDateString());
    }

    //Generate the data
    let subscriptionsThisMonth = [];
    let unsubscriptionsThisMonth = [];
    for (let i = 1; i <= today.getDate(); i++) {
        let subscriptionThisDay = 0;
        let unsubscriptionsThisDay = 0;
        for (let j = data.length - 1; j >= 0; j--) {
            let year = getYear(data[j].subscribedat);
            let month = getMonth(data[j].subscribedat);
            let day = getDay(data[j].subscribedat);

            if (year === today.getFullYear() && month === today.getMonth() + 1 && day === i) {
                subscriptionThisDay++;

                //Check if the customer is still active or not
                if (data[j].unsubscribed !== "f") {
                    unsubscriptionsThisDay++;
                }
            }
        }
        subscriptionsThisMonth.push(subscriptionThisDay);
        unsubscriptionsThisMonth.push(unsubscriptionsThisDay);
    }
    return {
        labels: xLabels,
        datasets: [
            {
                label: '# of subscriptions/day',
                data: subscriptionsThisMonth,
                fill: false,
                backgroundColor: 'rgba(54, 162, 235)',
                borderColor: 'rgba(54, 162, 235, 0.6)',
            },
            {
                label: '# of which unsubscribed again',
                data: unsubscriptionsThisMonth,
                fill: false,
                backgroundColor: 'rgb(255, 99, 132)',
                borderColor: 'rgba(255, 99, 132, 0.6)',
            },
        ],
    };
}

//Helpers
function setSubscriptionDetailsList(completeList, page, setSubscriptionList) {
    //Calculate the indices
    let i1 = 25*(page - 1);
    let i2 = 25*page - 1;
    //Check if the second index is still in the list
    if(i2 >= completeList.length) {
        i2 = completeList.length - 1;
    }

    setSubscriptionList(completeList.slice(i1, i2+1));
}
