const subscriptionsSources = ["ADMIN", "SUPPORT", "UNKNOWN", "IAP_APPLE", "IAP_GOOGLE", "SHOP", "MOBILCOM", "PARTNER", "COUPON", "LIBRARY", "GUESTACCOUNT", "SUBSCRIPTION", "RECOMMENDATION", "ACCOUNTCREATION", "IAP_APPLE_RESTORE", "IAP_GOOGLE_RESTORE", "TEST"];

const SubscriptionMetaData = {

    //Columns
    COLUMNS: [
        //Default & Badges
        {label: "ID", id: 'id', columnType: 'default', show: true},
        {label: "Name", id: 'name', columnType: 'default', show: true},
    ],

    //Details
    DETAILS_GENERAL: [
        [{label: 'ID', id: 'id', type: 'text', editable: 'never'}, {label: 'Version', id: 'version', type: 'text', editable: 'never'}, {label: "Account ID", id: 'accountId', type: 'text', editable: 'never'}, {label: "Source", id: 'source', type: 'select', options: subscriptionsSources, editable: 'true', required: true}],
    ],
    PURCHASE_DETAILS: [
        [{label: 'Active', id: 'active', type: 'boolean', editable: 'true'}, {label: 'Renewable', id: 'renewable', type: 'boolean', editable: 'true'}],
        [{label: 'Purchase Time', id: 'purchaseTime', type: 'text', editable: 'never'}, {label: 'Valid From', id: 'validFrom', type: 'datetime', editable: 'true', required: true}, {label: 'Valid Until', id: 'validUntil', type: 'datetime', editable: 'true', required: true}],
        [{label: 'Original Valid From', id: 'origValidFrom', type: 'datetime', editable: 'true', isExpertField: true}, {label: 'Original Valid Until', id: 'originalValidUntil', type: 'datetime', editable: 'true', isExpertField: true}],
    ],
    ORDER_DETAILS: [
        [{label: 'Order ID', id: 'orderId', type: 'text', editable: 'true'}, {label: "Slots", id: 'slots', type: 'text', editable: 'true'}],
        [{label: 'Note', id: 'note', type: 'textarea', rows: 5, editable: 'true'}]
    ],

    //Sorting
    SUBSCRIPTION_SORT_PARAMS: [],
};

export default SubscriptionMetaData;