import {Button, Form, InputGroup, Table} from "react-bootstrap";
import {
    AiOutlineCheck,
    BsPencil,
    FcAdvertising,
    FcAndroidOs,
    FcApprove,
    FcBiotech,
    FcCableRelease, FcConferenceCall,
    FcElectronics,
    FcInTransit
} from "react-icons/all";
import {transformStringToReadableDate} from "../../../util/ResourceService";
import React, {useState} from "react";
import {CreateDeviceSupplements, GetDeviceSupplementByDeviceId, UpdateDeviceSupplements} from "../SupportToolService";

export function BoxesTab(props) {
    const [devices, setDevices] = useState(props.devices);
    const [profiles] = useState(props.profiles);
    const [selectedDevice, setSelectedDevice] = useState(props.selectedDevice);
    const [remark, setRemark] = useState("");

    function deviceInfoField(entry) {
        if(selectedDevice.id === entry.id) {
            return(
                <Form>
                    <InputGroup>
                        <Form.Control type="text"
                                      value={remark} style={{fontSize: "16px"}}
                                      onChange={(e) => setRemark(e.target.value)} />
                            <Button variant="outline-secondary" onClick={() => updateSelectedDeviceRemark()}><AiOutlineCheck /></Button>
                    </InputGroup>
                </Form>
            );
        } else {
            return (
                entry.remark
            );
        }
    }

    function getProfileName(device) {
        if (device == null || device.currentProfileId == null || profiles == null) {
            return "Unbekannt";
        }
        //Get the matching profile
        const matchingProfile = profiles.filter(p => Number(p.id) === Number(device.currentProfileId));
        if (matchingProfile == null || !Array.isArray(matchingProfile) || matchingProfile.length !== 1) {
            return "Unbekannt";
        }
        return matchingProfile[0].name;
    }

    function getSoftwareVersion(currentVersion) {
        let softwareVersion = "";
        if (currentVersion) {
            softwareVersion = currentVersion;
            if (softwareVersion && softwareVersion.indexOf("R1.00") < 0) { //extract version only from version string of rewritten software, otherwise it's everytime 1.00, which not helps
                softwareVersion = softwareVersion.slice(1, -22);
            }
        }
        return softwareVersion;
    }

    async function updateSelectedDeviceRemark() {
        if(selectedDevice.id != null) {
            let response;
            let exists = await GetDeviceSupplementByDeviceId(selectedDevice.id);

            if(!exists.error) {
                let sup = exists.result;
                if(sup.id != null) {
                    response = await UpdateDeviceSupplements({id: sup.id, deviceId: selectedDevice.id, remark: remark});
                } else {
                    response = await CreateDeviceSupplements({deviceId: selectedDevice.id, remark: remark});
                }
            } else {
                response = await CreateDeviceSupplements({deviceId: selectedDevice.id, remark: remark});
            }

            if(!response.error) {
                let entries = [];
                //Change the entry in ticket/subscription list
                for(let i = 0; i < devices.length; i++) {
                    let entry = devices[i];
                    if(entry.id === selectedDevice.id) {
                        entry.remark = remark;
                    }
                    entries.push(entry);
                }
                setDevices(entries);
                setSelectedDevice({});
                setRemark("");
                props.parentProps.addToast("Die Bemerkung wurde gespeichert.", {autoDismiss: true, appearance: "success"});
            } else {
                props.parentProps.addToast("Beim Updaten der Bemerkung ist ein Fehler aufgetreten. Bitte versuche es erneut oder kontaktiere einen Administrator." + response.message, {autoDismiss: true, appearance: "error"});
            }
        }
    }

    return (
        <>
            <div className="support-tool-box">
                {devices.length > 0 ?
                    <Table bordered striped responsive hover>
                        <thead>
                        <tr>
                            <th colSpan="3" style={{textAlign: "center"}}>Box-Details:</th>
                            <th colSpan="2" style={{textAlign: "center"}}>Erstes Onboarding
                                am:
                            </th>
                            <th colSpan="3" style={{textAlign: "center"}}>Technische
                                Details:
                            </th>
                        </tr>
                        <tr>
                            <th style={{fontWeight: "normal"}}>
                                <FcCableRelease size={20}/>&#xA0;&#xA0;Name
                            </th>
                            <th style={{
                                fontWeight: "normal",
                                width: "300px"
                            }}><FcAdvertising size={20}/>&#xA0;&#xA0;Bemerkung<span
                                style={{float: "right"}}><BsPencil/></span>
                            </th>
                            <th style={{fontWeight: "normal"}}>
                                <FcConferenceCall size={20}/>&#xA0;&#xA0;Aktuelles Profil
                            </th>
                            <th style={{fontWeight: "normal"}}>
                                <FcInTransit size={20}/>&#xA0;&#xA0;Dieser Box
                            </th>
                            <th style={{fontWeight: "normal"}}><FcApprove size={20}/>&#xA0;&#xA0;Von
                                diesem Konto
                            </th>
                            <th style={{fontWeight: "normal"}}>
                                <FcBiotech size={20}/>&#xA0;&#xA0;Geräte-ID
                            </th>
                            <th style={{fontWeight: "normal"}}>
                                <FcAndroidOs size={20}/>&#xA0;&#xA0;Software-Version
                            </th>
                            <th style={{fontWeight: "normal"}}>
                                <FcElectronics size={20}/>&#xA0;&#xA0;Hardware-Version
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {devices.map(device => (
                            <tr role="row" key={device.deviceCredential}
                                style={{height: "80px"}}
                                /*onClick={() => this.updateState('selectedPremiumAccess', device.index)}*/
                                /*style={{cursor: "pointer", borderWidth: device.index === this.state.selectedPremiumAccess.index ? "2px" : "1px", fontWeight: device.index === this.state.selectedPremiumAccess.index ? "bold" : "normal"}}*/
                            >
                                <td>{device.displayName}</td>
                                <td style={{cursor: "pointer"}}
                                    onClick={() => setSelectedDevice(device)}>{deviceInfoField(device)}</td>
                                <td>{getProfileName(device)}</td>
                                <td>{transformStringToReadableDate(device.firstOnboardedDate)}</td>
                                <td>{transformStringToReadableDate(device.firstOnboardedDateCurrentAccount)}</td>
                                <td>{device.deviceIdentifier}</td>
                                <td>{getSoftwareVersion(device.currentVersion)}</td>
                                <td>{device?._embedded?.versions?.short ? device._embedded.versions.short : "N/A"}</td>
                            </tr>
                        ))}
                        </tbody>
                    </Table>
                    :
                    <div style={{textAlign: "center", fontSize: "22px"}}>
                        Es sind keine Boxen mit diesem Konto verknüpft
                    </div>
                }
            </div>
        </>
    )
}