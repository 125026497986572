import {
    CreateBillResource,
    GetFilteredBillResources, GetBillResource
} from "../../clients/PurchaseClient";

//API METHODS - TRANSACTIONS
export async function GetTransactionPage(page){
    return await GetFilteredBillResources('api/purchases/transactions', {page: page});
}

export async function GetTransactionsByState(state, page) {
    return await GetFilteredBillResources('api/purchases/transactions/search/by-state', {state: state, page: page});
}

export async function GetTransaction(id) {
    return await GetBillResource('api/purchases/transactions/' + id);
}

export async function ResetTransaction(id) {
    return await GetBillResource('api/purchases/transactions/' + id + '/reset');
}

export async function DisableTransaction(id) {
    return await GetBillResource('api/purchases/transactions/' + id + '/disable');
}

export async function UnlockTransaction(id) {
    return await GetBillResource('api/purchases/transactions/' + id + '/unblock');
}

export async function SaveTransaction(transaction) {
    return await CreateBillResource('api/purchases/transactions/', transaction);
}

export async function BlockTransaction(id) {
    return await GetBillResource('api/purchases/transactions/' + id + '/block')
}

export async function GetPurchaseFailurePage(page) {
    return await GetFilteredBillResources('api/purchases/failures', {page: page});
}