import {Form, Col, Table} from "react-bootstrap";
import Row from 'react-bootstrap/Row';
import {useState} from "react";
import {Label} from "reactstrap";
import {AiOutlineCaretRight, BiMailSend, GiConfirmed, MdUnsubscribe} from "react-icons/all";
import GlobalConstants from "../../../config/GlobalConstants";
import {
    ConfirmNewsletterSubscription,
    CreateSubscriber,
    SubscribeToNewsletter,
    UnSubscribeFromNewsletter
} from "../NewsletterService";

export function AddOrEditSubscriberDialog(props) {

    let [subscriber, setSubscriber] = useState(props.subscriber);
    const [newsletter] = useState(props.newsletter);
    const [subExists] = useState(props.subscriber.id != null);

    return(
        <div>
            <Form>
                <Row>
                    <Form.Group as={Col} controlId="subscriberId">
                        <Label>ID</Label>
                        <Form.Control type="text" value={subscriber.id} readOnly={true}/>
                    </Form.Group>
                    <Form.Group as={Col} controlId="subscriberAccountId">
                        <Label>Account ID</Label>
                        <Form.Control type="text" value={subscriber.accountId} readOnly={true}/>
                    </Form.Group>
                    <Form.Group as={Col} controlId="subscriberEmail">
                        <Label>E-Mail</Label>
                        <Form.Control type="text" value={subscriber.email} readOnly={subExists} onChange={(e) => setSubscriber({...subscriber, email: e.target.value})}/>
                    </Form.Group>
                </Row>
            </Form>
            <Table responsive striped>
                <tbody>
                    <tr style={{height: "120px"}}>
                        <td width={"70%"}>
                            <span style={{fontWeight: "bold"}}><AiOutlineCaretRight /> Send a "Double-Opt-In"-Mail</span><br />
                            Use this button to send again the "Double-Opt-In"-Mail from Mailjet. The subscriber will be set to "ACTIVE", but "NOT CONFIRMED".
                            He will NOT be set on to the corresponding mailjet list, hence he will NOT receive any newsletter mails.
                        </td>
                        <td><button className="form-btn-ci-blue" style={{marginTop: "25px", marginLeft: "40px"}} onClick={() => sendDoubleOptInMail(subscriber, props)}><BiMailSend size={20} /> Send Double-Opt-In mail</button></td>
                    </tr>
                    <tr style={{height: "120px"}}>
                        <td width={"70%"}>
                            <span style={{fontWeight: "bold"}}><AiOutlineCaretRight /> Confirm the subscription</span><br />
                            Use this button to confirm an existing subscription. The subscriber will be set to "ACTIVE" and "CONFIRMED". NOTE: In addition, the email
                            address will be added to the corresponding mailjet list, hence he WILL receive newsletter mails.
                        </td>
                        <td valign={"bottom"}><button className="form-btn-ci-green" style={{marginTop: "25px", marginLeft: "40px"}} onClick={() => confirmSubscription(newsletter, subscriber, props)}><GiConfirmed size={20}/> Confirm subscription</button></td>
                    </tr>
                    {subExists &&
                        <tr style={{height: "120px"}}>
                            <td width={"70%"}>
                                <span style={{fontWeight: "bold"}}><AiOutlineCaretRight /> Unsubscribe from mailjet list</span><br />
                                Use this button to unsubscribe the subscriber from the corresponding newsletter. He will be set to "NOT ACTIVE". As a result,
                                he will be removed from the mailjet list and NOT receive any further newsletter mails.
                            </td>
                            <td><button className="form-btn-ci-red" style={{marginTop: "25px", marginLeft: "40px"}} onClick={() => unsubscribeFromNewsletter(subscriber, props)}><MdUnsubscribe size={20}/> Unsubscribe from list</button></td>
                        </tr>
                    }
                </tbody>
            </Table>
        </div>
    )
}

async function sendDoubleOptInMail(sub, props) {
    const request = createNewsletterChangeRequest(sub);
    const response = await SubscribeToNewsletter(request);
    if(!response.error) {
        props.onHideDialog();
    } else {
        console.log("ERROR: " + response.message);
    }
}

async function confirmSubscription(newsletter, sub, props) {
    //Check if we create a new sub
    if(sub.id === null) {
        let created = await CreateSubscriber(newsletter, sub);
        if(!created.error) {
            sub = created.result;
        }
    }
    const response = await ConfirmNewsletterSubscription(newsletter, sub);
    if(!response.error) {
        props.onHideDialog();
    } else {
        console.log("ERROR: " + response.message);
    }
}

async function unsubscribeFromNewsletter(sub, props) {
    const request = createNewsletterChangeRequest(sub);
    const response = await UnSubscribeFromNewsletter(request);
    if(!response.error) {
        props.onHideDialog();
    } else {
        console.log("ERROR: " + response.message);
    }
}

function createNewsletterChangeRequest(sub) {
    return {
        email: sub.email,
        tigertipps: Number(sub.mailjetListId) === Number(GlobalConstants.MAILJET_TIGERTIPPS_LIST_ID),
        tigerpost: Number(sub.mailjetListId) === Number(GlobalConstants.MAILJET_TIGERPOST_LIST_ID)
    }
}