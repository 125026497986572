const RestoreMetaData = {

    //Columns
    COLUMNS: [
        //Default & Badges
        {label: "ID", id: 'id', columnType: 'default', show: true},
        {label: "State", id: 'state', columnType: 'badge', options: [
                {value: 'PENDING', style: "secondary"},
                {value: 'PROCESSING', style: "primary"},
                {value: 'FAILED', style: "danger"},
                {value: 'ASSIGNING', style: "warning"},
                {value: 'COMPLETED', style: "success"}
            ],
            show: true},
        {label: "Account ID", id: 'accountId', columnType: 'default', show: true},
        {label: "Device ID", id: 'deviceId', columnType: 'default', show: true},
        {label: "IAP", id: 'iap', columnType: 'default', show: true},
    ],

    //Details
    DETAILS_GENERAL: [
        [{label: 'ID', id: 'id', type: 'text', editable: 'never'}, {label: 'State', id: 'state', type: 'text', editable: 'never'}, {label: "IAP", id: 'iap', type: 'text', editable: 'never'}],
    ],
    DETAILS_CUSTOMER: [
        [{label: 'Account ID', id: 'accountId', type: 'text', editable: 'never'}, {label: 'Device ID', id: 'deviceId', type: 'text', editable: 'never'}],
    ],
    DETAILS_RECEIPT: [
        [{label: 'Receipt ID', id: 'receipt', subId: 'id', type: 'sub', editable: 'never'},  {label: 'Receipt Type', id: 'receipt', subId: 'receiptType', type: 'sub', editable: 'never'}],
        [{label: 'Original Receipt Data', id: 'receipt', subId: 'originalReceiptData', type: 'sub', editable: 'never'},  {label: 'Data Hash', id: 'receipt', subId: 'dataHash', type: 'sub', editable: 'never'}],
        [{label: 'Client ID', id: 'receipt', subId: 'clientId', type: 'sub', editable: 'never'},  {label: 'App ID', id: 'receipt', subId: 'appId', type: 'sub', editable: 'never'}],

        [{label: 'Quantity', id: 'receipt', subId: 'attributes', subSubId: 'quantity', type: 'subsub', editable: 'never', isExpertField: true},  {label: 'Product Identifier', id: 'receipt', subId: 'attributes', subSubId: 'productIdentifier', type: 'subsub', editable: 'never', isExpertField: true},  {label: 'Transaction Identifier', id: 'receipt', subId: 'attributes', subSubId: 'transactionIdentifier', type: 'subsub', editable: 'never', isExpertField: true}],
        [{label: 'Purchase Date', id: 'receipt', subId: 'attributes', subSubId: 'purchaseDate', type: 'subsub', editable: 'never', isExpertField: true},  {label: 'Expires Date', id: 'receipt', subId: 'attributes', subSubId: 'expiresDate', type: 'subsub', editable: 'never', isExpertField: true}],
        [{label: 'Original Transaction Identifier', id: 'receipt', subId: 'attributes', subSubId: 'originalTransactionIdentifier', type: 'subsub', editable: 'never', isExpertField: true},  {label: 'Original Purchase Date', id: 'receipt', subId: 'attributes', subSubId: 'originalPurchaseDate', type: 'subsub', editable: 'never', isExpertField: true}],
        [{label: 'App Item Identifier', id: 'receipt', subId: 'attributes', subSubId: 'appItemIdentifier', type: 'subsub', editable: 'never', isExpertField: true},  {label: 'Version External Identifier', id: 'receipt', subId: 'attributes', subSubId: 'versionExternalIdentifier', type: 'subsub', editable: 'never', isExpertField: true}],
        [{label: 'App Bundle Identifier', id: 'receipt', subId: 'attributes', subSubId: 'appBundleIdentifier', type: 'subsub', editable: 'never', isExpertField: true},  {label: 'App Version Number', id: 'receipt', subId: 'attributes', subSubId: 'appVersionNumber', type: 'subsub', editable: 'never', isExpertField: true}]
    ],

    //Sorting
    RESTORE_SORT_PARAMS: [],
};

export default RestoreMetaData;