import React, {useState} from "react";
import {Badge, Pagination, Table} from "react-bootstrap";
import GlobalConstants from "../../config/GlobalConstants";
import {Link} from "react-router-dom";

export function AccountRestores(props) {
    let [restores] = useState(props.restores);
    return(
      <>
        <Table bordered hover responsive striped>
            <thead>
                <tr>
                    <th>ID</th>
                    <th>State</th>
                    <th>Device ID</th>
                    <th>Items</th>
                </tr>
            </thead>
            <tbody>
            {restores.map(restore => (
                <tr role="row" key={restore.id}>
                    <td><Link style={{fontStyle: "italic", color: "#333"}} to={"/" + GlobalConstants.APP_PATH + "restores/" + restore.id}>{restore.id}</Link></td>
                    <td>
                        <Badge bg={
                            restore.state === 'PENDING' ? "secondary" :
                            restore.state === 'PROCESSING' ? "primary" :
                            restore.state === 'FAILED' ? "danger" :
                            restore.state === 'ASSIGNING' ? "warning" : "success"
                        }>{restore.state}</Badge>
                    </td>
                    <td>{restore.deviceId}</td>
                    <td>
                        <ul style={{listStyle: "square"}}>
                            {restore.lineItems.map(item => (
                                <li>{item.productIap} - {item.productName}</li>
                            ))}
                        </ul>
                    </td>
                </tr>
            ))}
            </tbody>
        </Table>
        <Pagination>
          <Pagination.Item hidden={props.page === 1}
                        onClick={() => props.onLoadPage(1)}>&lt;&lt;</Pagination.Item>
          <Pagination.Item hidden={props.page === 1}
                        onClick={() => props.onLoadPage(props.page - 1)}>&lt;</Pagination.Item>
          <Pagination.Item hidden={props.page <= 2}
                        onClick={() => props.onLoadPage(props.page - 2)}>{props.page - 2}</Pagination.Item>
          <Pagination.Item hidden={props.page === 1}
                        onClick={() => props.onLoadPage(props.page - 1)}>{props.page - 1}</Pagination.Item>
          <Pagination.Item active={true}>{props.page}</Pagination.Item>
          <Pagination.Item hidden={props.page === props.lastPage}
                        onClick={() => props.onLoadPage(props.page + 1)}>{props.page + 1}</Pagination.Item>
          <Pagination.Item hidden={props.page >= props.lastPage - 1}
              onClick={() => props.onLoadPage(props.page + 2)}>{props.page + 2}</Pagination.Item>
          <Pagination.Item hidden={props.page === props.lastPage}
              onClick={() => props.onLoadPage(props.page + 1)}>&gt;</Pagination.Item>
        </Pagination>
      </>
    );
}