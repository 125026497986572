import {Modal, Table} from "react-bootstrap";
import React, {useState} from "react";
import {FcCancel, FiArrowDown, FiArrowUp} from "react-icons/all";

export function ErrorHandler(props) {

    let data = (props.error != null && props.error.result != null ) ? props.error.result: {};
    const [showCompleteInfo, setShowCompleteInfo] = useState(false);

    return(
        <Modal
            {...props}
            show={props.error != null}
            size={"lg"}
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    <FcCancel size={30}/> <span style={{color: "#d44848"}}>Request was not successful</span>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Table responsive bordered hover striped>
                    <tbody>
                        {data.customInfo != null &&
                            <tr><td><strong>Reason:</strong></td><td>{data.customInfo}</td></tr>
                        }
                        {data.errorCode != null &&
                            <tr><td><strong>Error Code:</strong></td><td>{data.errorCode}</td></tr>
                        }
                        {data.info != null &&
                            <tr><td><strong>Info:</strong></td>
                                <td>{(data.info.length > 200 && !showCompleteInfo) ? data.info.substr(0,200) + "..." : data.info}
                                    {data.info.length > 200 &&
                                    <>
                                        <br />
                                        <hr />
                                        <span style={{cursor: "pointer", color: "#1979ca", fontWeight: "bold"}} onClick={() => setShowCompleteInfo(!showCompleteInfo)}>{showCompleteInfo ? <>Collapse <FiArrowUp size={24} /></> : <>Expand <FiArrowDown size={24}/></>}</span>
                                    </>
                                    }
                                </td>
                            </tr>
                        }
                        {data.message != null &&
                            <tr><td><strong>Message:</strong></td><td>{data.message}</td></tr>
                        }
                        {data.path != null &&
                            <tr><td><strong>Path:</strong></td><td>{data.path}</td></tr>
                        }
                        {data.errors != null && Array.isArray(data.errors) && data.errors.length > 0 &&
                            <tr>
                                <td><strong>Errors:</strong></td>
                                <td>
                                    <Table responsive bordered hover striped>
                                        <thead>
                                            <tr>
                                                <th>Field</th>
                                                <th>Message</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {data.errors.map(e => (
                                            <tr role="row" key={e.field}>
                                                <td>{e.field}</td>
                                                <td>{e.message}</td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </Table>
                                </td>
                            </tr>
                        }
                    </tbody>
                </Table>
            </Modal.Body>
            <Modal.Footer>
                <button className="btn-ci" onClick={props.onHide}>Close</button>
            </Modal.Footer>
        </Modal>
    );
}