import {Button, Col, Form, FormControl, InputGroup, Pagination, Table} from "react-bootstrap";
import Row from 'react-bootstrap/Row';
import React, {Component} from "react";
import {withToast} from "../../util/ToastService";
import {Link} from "react-router-dom";
import {GiFamilyHouse,} from "react-icons/all";
import GlobalConstants from "../../config/GlobalConstants";
import {CreateImprint, GetImprint, UpdateImprint} from "./ImprintService";
import {DetailsSection, RequiredFieldsAreValid} from "../../generators/DetailsGenerator";
import ImprintMetaData from "./ImprintMetaData";
import {Label} from "reactstrap";
import {GetFilteredPublishingHouses} from "../publishingHouse/PublishingHouseService";
import {InfoModal} from "../../generators/ModalGenerator";
import {PromiseButton} from "../../global/SpinningTiger";
import {ErrorHandler} from "../../util/ErrorHandler";

class ImprintDetails extends Component {

    //------------
    //Constructor
    //------------

    constructor(props) {
        super(props);

        this.state = {
            error: null,
            flowState: props.location.state,
            id: this.props.match.params.id,
            editMode: {
                active: false,
                expertMode: false,
                autoSave: true,
            },
            imprint: {},
            originalImprint: {},
            publishingHouses: [],
            publishingHousePage: 1,
            publishingHouseFilterName: "",
            showPublishingHouseDialog: false,
            sections: {
                general: true,
                publishingHouse: true,
            },
            validationErrors: []
        }
    }

    //---------
    //Mounting
    //---------

    async componentDidMount() {
        //Set the title
        if(this.state.id != null) {
            document.title = "Imprint " + this.state.id + " :: Tiger UI";
        } else {
            document.title = "New imprint :: Tiger UI";
        }
        await this.loadImprint();
        await this.loadPublishingHouses();
    }

    //--------
    //Loading
    //--------

    async loadImprint() {
        let loadedImprint = {};

        if(this.state.id != null && this.state.id !== "add") {

            loadedImprint = await GetImprint(this.state.id);

            if (!loadedImprint.error) {
                const imprint = loadedImprint.result;

                this.setState({
                    id: this.state.id,
                    editMode: this.state.editMode,
                    imprint: imprint,
                    originalImprint: imprint,
                });
            } else {
                this.setState(prevState => ({...prevState, error: loadedImprint}));
            }
        } else {
            this.setState((prevState) => ({...prevState,
                id: null,
                imprint: {
                    id: null,
                },
                editMode: {
                    active: true,
                    expertMode: false,
                    autoSave: false,
                }}));
        }
    }

    async loadPublishingHouses(page = null){
        let loadedPublishingHouses = [];

        if(page != null) {
            loadedPublishingHouses = await GetFilteredPublishingHouses({page: page, name: this.state.publishingHouseFilterName});
            this.setState(prevState => ({...prevState, publishingHousePage: page}));
        } else {
            loadedPublishingHouses = await GetFilteredPublishingHouses({page: this.state.publishingHousePage, name: this.state.publishingHouseFilterName});
        }


        if(!loadedPublishingHouses.error) {
            const publishingHouses = loadedPublishingHouses.result;

            this.setState(prevState => ({...prevState, publishingHouses: publishingHouses}));
        } else {
            this.setState(prevState => ({...prevState, error: loadedPublishingHouses}));
        }
    }

    //----------
    //Rendering
    //----------

    render() {

        const labelStyle = {
            fontSize: "20px",
            backgroundColor: "#C8C8C8",
            borderStyle: "solid",
            borderColor: "#cdd7e0",
            borderWidth: "1px",
            borderRadius: "5px",
            paddingLeft: "10px",
            paddingRight: "10px",
            width: "100%"
        };


        return (
            <>
                <div className="details-title">
                    {this.state.imprint.id != null ? "Details of Imprint " + this.state.imprint.id : "Create a new Imprint"}
                </div>
                <div className="details-button-box" style={{height: "70px"}}>
                    <Link to={{pathname: "/" + GlobalConstants.APP_PATH + "imprints", state: this.state.flowState}}>
                        <button className="form-btn-ci-light-blue" type="button">Back</button>
                    </Link>
                    {this.state.imprint.id != null &&
                    <button className={this.state.editMode.active ? "form-btn-ci-red" : "form-btn-ci-blue"}  type="button" onClick={() => this.setState({
                        editMode: {
                            active: !this.state.editMode.active,
                            expertMode: this.state.editMode.expertMode
                        },
                        imprint: this.state.originalImprint
                    })}>{this.state.editMode.active ? "Cancel" : "Edit"}</button>
                    }
                    {this.state.editMode.active &&
                    <PromiseButton text="Save" onClick={() => this.saveOrUpdateImprint()} />
                    }
                    <Button style={{float: "right", marginTop: "5px", marginRight: "10px"}} variant={this.state.editMode.autoSave ? "secondary": "outline-secondary"}
                            onClick={() => this.setState(prevState => ({
                                ...prevState,
                                editMode: {
                                    ...prevState.editMode,
                                    autoSave: !this.state.editMode.autoSave
                                }
                            }))}>Auto Save</Button>
                </div>

                <div className="details-box-title">Resource Details</div>
                <div className="details-box">
                    {/* GENERAL DATA */}
                    <DetailsSection
                        nameInState="imprint"
                        fields={ImprintMetaData.DETAILS_GENERAL}
                        state={this.state}
                        onSetState={(s) => this.setState(s)}
                        onUpdateResource={() => this.saveOrUpdateImprint()}
                        sectionId="general"
                    />

                    {/* PUBLISHING HOUSE */}
                    <DetailsSection
                        nameInState="imprint"
                        fields={[]}
                        state={this.state}
                        onSetState={(s) => this.setState(s)}
                        sectionId="publishingHouse"
                        label={<span><GiFamilyHouse/>&#xA0;Publishing House</span>}
                    />
                    {this.state.sections.publishingHouse &&
                    <Row>
                        <Form.Group as={Col} controlId="publishingHouseId">
                            <Label style={labelStyle}>Publishing House ID*</Label>
                            <InputGroup className="mb-3">
                                <Form.Control
                                    isInvalid={this.state.validationErrors.indexOf("publishingHouseId") > -1}
                                    value={this.state.imprint.publishingHouseId}
                                    readOnly={!this.state.editMode.active}/>

                                {this.state.editMode.active &&
                                    <Button variant="outline-secondary" type="button" onClick={() => {
                                        this.loadPublishingHouses().then(r => {
                                            this.setState(prevState => ({
                                                ...prevState,
                                                showPublishingHouseDialog: true
                                            }));
                                        });
                                    }}>Pick</Button>
                                }
                                <Form.Control.Feedback type="invalid">
                                    The field 'Publishing House ID' is required
                                </Form.Control.Feedback>
                            </InputGroup>
                        </Form.Group>
                        <Form.Group as={Col} controlId="publishingHouseName">
                            <Label style={labelStyle}>Publishing House Name</Label>
                            <InputGroup className="mb-3">
                                <Form.Control
                                    value={this.state.imprint.publishingHouseName}
                                    readOnly={!this.state.editMode.active}/>

                                {this.state.editMode.active &&
                                    <Button variant="outline-secondary" type="button" onClick={() => {
                                        this.loadPublishingHouses().then(r => {
                                            this.setState(prevState => ({
                                                ...prevState,
                                                showPublishingHouseDialog: true
                                            }));
                                        });
                                    }}>Pick</Button>
                                }
                            </InputGroup>
                        </Form.Group>
                    </Row>
                    }


                </div>
                <div className="details-button-box" style={{height: "70px"}}>
                    <Link to={{pathname: "/" + GlobalConstants.APP_PATH + "imprints", state: this.state.flowState}}>
                        <button className="form-btn-ci-light-blue" type="button">Back</button>
                    </Link>
                    {this.state.editMode.active &&
                    <PromiseButton text="Save" onClick={() => this.saveOrUpdateImprint()} />
                    }
                </div>

                <InfoModal show={this.state.showPublishingHouseDialog}
                           onHide={() => this.setState(prevState => ({
                               ...prevState,
                               showPublishingHouseDialog: false,
                               publishingHouseFilterName: "",
                           }))}
                           title="Pick a publishing House"
                           body={this.pickPublishingHouseDialog()}/>

                <ErrorHandler error={this.state.error}
                              onHide={() => this.setState(prevState => ({...prevState, error: null}))} />
            </>
        );
    }

    //------------
    //API Methods
    //------------

    async saveOrUpdateImprint() {
        if (!RequiredFieldsAreValid("imprint", [ImprintMetaData.DETAILS_GENERAL, ImprintMetaData.DETAILS_PUBLISHING_HOUSE], this.state, (s) => this.setState(s))) {
            return;
        }
        let imprint = {};
        if(this.state.imprint.id != null) {
            imprint = await UpdateImprint(this.state.imprint);
        } else {
            imprint = await CreateImprint(this.state.imprint);
        }

        if (!imprint.error) {
            if (this.state.imprint.id == null) {
                this.props.history.push("/"+ GlobalConstants.APP_PATH + "imprints/" + imprint.result.id);
            }
            this.setState((prevState) =>({...prevState, imprint: imprint.result, editMode: {...prevState.editMode, active: this.state.editMode.autoSave}}));

            this.props.addToast("The imprint has been updated successfully.", {
                autoDismiss: true,
                appearance: 'success'
            });
        } else {
            this.setState(prevState => ({...prevState, error: imprint}));
        }
    }

    //--------
    // DIALOGS
    //--------

    pickPublishingHouseDialog() {
        return (
            <div>
                <InputGroup className="mb-3">
                    <FormControl
                        placeholder="Search for a publishing house"
                        onChange={(e) => this.setState(prevState => ({
                            ...prevState,
                            publishingHouseFilterName: e.target.value,
                        }))}
                    />
                        <Button variant="outline-secondary"
                                onClick={() => this.loadPublishingHouses()}>Search</Button>
                </InputGroup>
                <Table striped bordered hover>
                    <thead>
                    <tr>
                        <th>ID</th>
                        <th>Name</th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.state.publishingHouses.map(publishingHouse => (
                        <tr role="row" key={publishingHouse.id} style={{cursor: "pointer"}} onClick={() => {
                            const validationErrors = this.state.validationErrors.filter(e => e !== "publishingHouseId");
                            this.setState(prevState => ({
                                ...prevState,
                                imprint: {
                                    ...prevState.imprint,
                                    publishingHouseId: publishingHouse.id,
                                    publishingHouseName: publishingHouse.name,
                                },
                                validationErrors: validationErrors,
                                showPublishingHouseDialog: false
                            }));
                        }}>
                            <td>{publishingHouse.id}</td>
                            <td>{publishingHouse.name}</td>
                        </tr>
                    ))}
                    </tbody>
                </Table>

                <Pagination>
                    <Pagination.Item hidden={this.state.publishingHousePage === 1}
                                     onClick={() => this.loadPublishingHouses(1)}>&lt;&lt;</Pagination.Item>
                    <Pagination.Item hidden={this.state.publishingHousePage === 1}
                                     onClick={() => this.loadPublishingHouses(this.state.publishingHousePage - 1)}>&lt;</Pagination.Item>
                    <Pagination.Item hidden={this.state.publishingHousePage <= 2}
                                     onClick={() => this.loadPublishingHouses(this.state.publishingHousePage - 2)}>{this.state.publishingHousePage - 2}</Pagination.Item>
                    <Pagination.Item hidden={this.state.publishingHousePage === 1}
                                     onClick={() => this.loadPublishingHouses(this.state.publishingHousePage - 1)}>{this.state.publishingHousePage - 1}</Pagination.Item>
                    <Pagination.Item active={true}>{this.state.publishingHousePage}</Pagination.Item>
                    <Pagination.Item
                        onClick={() => this.loadPublishingHouses(this.state.publishingHousePage + 1)}>{this.state.publishingHousePage + 1}</Pagination.Item>
                    <Pagination.Item
                        onClick={() => this.loadPublishingHouses(this.state.publishingHousePage + 2)}>{this.state.publishingHousePage + 2}</Pagination.Item>
                    <Pagination.Item
                        onClick={() => this.loadPublishingHouses(this.state.publishingHousePage + 1)}>&gt;</Pagination.Item>
                </Pagination>
            </div>
        )
    }

}


export default withToast(ImprintDetails);