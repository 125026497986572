import {iso6392} from "iso-639-2";
import React, {useState} from "react";
import {Button, Col, Form, Table} from "react-bootstrap";
import Row from "react-bootstrap/Row";

export function PickLanguageDialog(props) {
    let [languages, setLanguages] = useState({
        data: [],
        filter: {
            page: 0,
            name: "",
            query: "",
            per_page: 20,
        },
        totalPages: 0,
        showDialog: false,
        isos: iso6392,
        filteredIsos: iso6392
    });

    return (
            <>
                <div style={{width: "100%"}}>
                    <h5>Most common:</h5>
                    <div style={{float: "left"}}>
                        <Button variant="outline-secondary" style={{marginRight: "10px"}}
                                onClick={() => props.onClickLanguage('deu')}>German</Button>
                        <Button variant="outline-secondary" style={{marginRight: "10px"}}
                                onClick={() => props.onClickLanguage('eng')}>English</Button>
                        <Button variant="outline-secondary" style={{marginRight: "10px"}}
                                onClick={() => props.onClickLanguage('fra')}>French</Button>
                        <Button variant="outline-secondary" style={{marginRight: "10px"}}
                                onClick={() => props.onClickLanguage('spa')}>Spanish</Button>
                        <Button variant="outline-secondary" style={{marginRight: "10px"}}
                                onClick={() => props.onClickLanguage('ita')}>Italian</Button>
                        <Button variant="outline-secondary" style={{marginRight: "10px"}}
                                onClick={() => props.onClickLanguage('rus')}>Russian</Button>
                        <Button variant="outline-secondary" style={{marginRight: "10px"}}
                                onClick={() => props.onClickLanguage('fin')}>Finnish</Button>
                        <Button variant="outline-secondary" style={{marginRight: "10px"}}
                                onClick={() => props.onClickLanguage('por')}>Portugues</Button>
                        <Button variant="outline-secondary" style={{marginRight: "10px"}}
                                onClick={() => props.onClickLanguage('gsw')}>Swiss german</Button>
                    </div>
                    <br/>
                    <br/>
                </div>
                <hr/>
                <Form>
                    <Row>
                        <Form.Group as={Col}>
                            <Form.Control type="text" placeholder="Search for a language"
                                          onChange={(e) => setLanguages({
                                              ...languages,
                                              filteredIsos: languages.isos.filter(
                                                      i => i.name.toLowerCase().indexOf(
                                                              e.target.value.toLowerCase()) > -1)
                                          })}/>
                        </Form.Group>
                    </Row>
                </Form>
                <Table bordered responsive hover striped>
                    <thead>
                    <tr>
                        <th>Language</th>
                        <th>ISO Code</th>
                    </tr>
                    </thead>
                    <tbody>
                    {languages.filteredIsos.map(i => (
                            <tr key={i.name} role="row" style={{cursor: "pointer"}}
                                onClick={() => props.onClickLanguage(i.iso6392T ? i.iso6392T : i.iso6392B)}>
                                <td>{i.name}</td>
                                <td>{i.iso6392T ? i.iso6392T : i.iso6392B}</td>
                            </tr>
                    ))}
                    </tbody>
                </Table>
            </>
    );
}