import {
    DeleteRestResource,
    GetFilteredRestResource,
    GetRestResource,
    CreateRestResource,
    UpdateRestResource
} from "../../clients/RestResourceClient";

//Devices
export async function GetFilteredDevices(filter){
    return await GetFilteredRestResource('api/devices', filter);

}

export async function CreateDevice(device) {
    return await CreateRestResource('api/devices', device);
}

export async function GetDevice(id) {
    return await GetRestResource('api/devices/' + id + "?projection=internalDeviceProjection");
}

export async function GetDeviceProductionEntityQueryDSL() {
    return await GetFilteredRestResource('api/productions');
}

export async function GetResourceByUrl(url) {
    return await GetRestResource(url, true);
}

export async function UpdateDevice(device) {
    return await UpdateRestResource('api/devices/' + device.id, device);
}

export async function DeleteDevice(device) {
    return await DeleteRestResource('api/devices/' + device.id);
}