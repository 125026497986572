import GlobalConstants from "../../config/GlobalConstants";

const CONTRACT_STATES = [{value: 'ACTIVE', label: 'Aktiv'}, {value: 'REJECTED', label: 'Zurückgewiesen'}, {value: 'CANCELLED', label: 'Widerrufen'}];
const SHIPMENT_STATES = [{value: 'PURCHASE_COMPLETED', label: "Offen"},
    {value: 'POSSIBLY_DUPLICATE_RECORD', label: "Mögliche Dublette"},
    {value: 'PACKAGED', label: "Erledigt"}];
const RETURN_SHIPMENT_STATES = [{value: '', label: ''},
    {value: 'CONTRACT_CANCELLED', label: "Vertrag widerrufen"},
    {value: 'CANCELLATION_MAIL_TO_SUPPORT_SENT', label: "Mail an Support versendet"},
    {value: 'RETURN_SHIPMENT_LABEL_SENT', label: "Retouren-Label versendet"},
    {value: 'IN_PROGRESS', label: "Retoure im Zulauf"},
    {value: 'PACKAGE_LOST', label: "Paket verloren"},
    {value: 'FINISHED', label: "Retoure eingetroffen"},
    {value: 'FIRST_RETURN_SHIPMENT_REMINDER_SENT', label: "Erste Erinnerungsmail versendet"},
    {value: 'SECOND_RETURN_SHIPMENT_REMINDER_SENT', label: "Zweite Erinnerungsmail versendet"},
    {value: 'THIRD_RETURN_SHIPMENT_REMINDER_SENT', label: "Dritte Erinnerungsmail versendet"}];

const OrderedBundlesMetaData = {

    //Columns
    COLUMNS: [
        //Default & Badges
        {label: "ID", id: 'id', columnType: 'default', show: true},
        {label: "Bestell-Datum", id: 'createdDate', columnType: 'datetime', show: true},
        {label: "Vertrags-Status", id: 'contractState', columnType: 'badge', options: [
                {value: "ACTIVE", style: "success", label: "Aktiv"},
                {value: "REJECTED", style: "warning", label: "Zurückgewiesen"},
                {value: "CANCELLED", style: "danger", label: "Widerrufen"}
            ], show: true},
        {label: "Bearbeitungs-Status", id: 'shipmentState', columnType: 'badge', options: [
                {value: "PURCHASE_COMPLETED", style: "selected", label: "Offen"},
                {value: "POSSIBLY_DUPLICATE_RECORD", style: "yellow", label: "Dublette?"},
                {value: "PACKAGED", style: "cyan", label: "Erledigt"},
                {value: "SHIPPED", style: "cyan", label: "Versendet"},
                {value: "DELIVERED", style: "success", label: "Geliefert"}
            ], show: true},
        {label: "Retouren-Status", id: 'returnShipmentState', columnType: 'badge', nullable: true, options: [
                {value: "CONTRACT_CANCELLED", style: "selected", label: "Vertrag widerrufen"},
                {value: "CANCELLATION_MAIL_TO_SUPPORT_SENT", style: "light-blue", label: "Mail an Support"},
                {value: "RETURN_SHIPMENT_LABEL_SENT", style: "cyan", label: "Label versendet"},
                {value: "IN_PROGRESS", style: "cyan", label: "Im Zulauf"},
                {value: "PACKAGE_LOST", style: "darkred", label: "Paket verloren"},
                {value: "FINISHED", style: "success", label: "Eingetroffen"},
                {value: "FIRST_RETURN_SHIPMENT_REMINDER_SENT", style: "light-red", label: "1. Erinnerung"},
                {value: "SECOND_RETURN_SHIPMENT_REMINDER_SENT", style: "middle-red", label: "2. Erinnerung"},
                {value: "THIRD_RETURN_SHIPMENT_REMINDER_SENT", style: "darkred", label: "3. Erinnerung"},

            ], show: true},
        {label: "Kombi-Paket", id: 'planVariantId', columnType: 'badge', options: [
                {value: GlobalConstants.BUNDLE_FLEX_PLAN_VARIANT_ID, style: "selected", label: "Kombi-Paket FLEX"},
                {value: GlobalConstants.BUNDLE_12_PLAN_VARIANT_ID, style: "cyan", label: "Kombi-Paket 12"},
                {value: GlobalConstants.BUNDLE_24_PLAN_VARIANT_ID, style: "success", label: "Kombi-Paket 24"}
            ], show: true},
        {label: "Farbe", id: 'color', columnType: 'badge', options: [
                {value: "BLUE", style: "box-blue", label: "Blau"},
                {value: "RED", style: "box-red", label: "Rot"},
                {value: "GREY", style: "box-grey", label: "Grau"},
                {value: "BLACK", style: "box-black", label: "Schwarz"},
                {value: "PURPLE", style: "box-purple", label: "Violett"},
                {value: "GREEN", style: "box-green", label: "Grün"},
                {value: "YELLOW", style: "box-yellow", label: "Gelb"},
            ]
            , show: true},
        {label: "E-Mail", id: 'orderEmailAddress', columnType: 'default', show: true},
        {label: "Name", id: 'fullName', columnType: 'default', show: true},
        {label: "Adresse", id: 'address', columnType: 'default', show: true},
        {label: "Billwerk-Vertrags-ID", id: 'billwerkContractId', columnType: 'default', show: true},
        {label: "Konto-ID", id: 'accountId', columnType: 'default', show: true}
    ],

    //Details
    DETAILS_GENERAL: [
        [
            {label: 'ID', id: 'id', type: 'number', editable: 'never', isExpertField: false},
            {label: 'Bestelldatum', id: 'createdDate', type: 'datetime', editable: 'never'},
            {label: 'Vertrags-Status', id: 'contractState', type: 'select', labelValueOptions: true, options: CONTRACT_STATES, editable: 'true'},
            {label: 'Bearbeitungs-Status', id: 'shipmentState', type: 'select', labelValueOptions: true, options: SHIPMENT_STATES, editable: 'true'},
        ],
        [
            {label: 'Konto-ID', id: 'accountId', type: 'number', editable: 'never', isExpertField: false},
            {label: 'Billwerk-Vertrags-ID', id: 'billwerkContractId', type: 'text', editable: 'never', isExpertField: false},
            {label: 'Billwerk-Order-ID', id: 'billwerkOrderId', type: 'text', editable: 'never', isExpertField: false},
            {label: 'Billwerk-Kunden-ID', id: 'billwerkCustomerId', type: 'text', editable: 'never', isExpertField: false},
        ],
        [
            {label: "Farbe", id: 'color', type: 'badge', editable: 'true', options: [
                    {value: "BLUE", style: "box-blue", label: "BLUE"},
                    {value: "RED", style: "box-red", label: "RED"},
                    {value: "GREY", style: "box-grey", label: "GREY"},
                    {value: "BLACK", style: "box-black", label: "BLACK"},
                    {value: "PURPLE", style: "box-purple", label: "PURPLE"},
                    {value: "GREEN", style: "box-green", label: "GREEN"},
                    {value: "YELLOW", style: "box-yellow", label: "YELLOW"},
                ]},
            {label: "E-Mail", id: 'orderEmailAddress', type: 'text', editable: 'true'},
            {label: "Vorame", id: 'firstName', type: 'text', editable: 'true'},
            {label: "Nachname", id: 'lastName', type: 'text', editable: 'true'},
        ],

        [
            {label: "Notizen", id: 'notes', type: 'textarea', rows: 10, editable: 'true'},
        ],
    ],
    DETAILS_SECTION_SHIPMENT_DATA: [
        [
            {label: "Straße", id: 'street', type: 'text', editable: 'true'},
            {label: "Hausnummer", id: 'houseNumber', type: 'text', editable: 'true'},
            {label: "PLZ", id: 'postalCode', type: 'text', editable: 'true'},
            {label: "Ort", id: 'city', type: 'text', editable: 'true'},
        ],
        [
            {label: "Vollständiger Name", id: 'fullName', type: 'text', editable: 'never'},
            {label: "Address", id: 'address', type: 'textarea', rows: 3,  editable: 'never'}
        ],
        [
            {label: "Verpackt am", id: 'packedDate', type: 'datetime', editable: 'true'},
            {label: "Sendungsnummer", id: 'shipmentNumber', type: 'text', editable: 'true'}
        ],
    ],
    DETAILS_SECTION_RETURN_SHIPMENT_DATA: [
        [
            {label: 'Küdigungs-Datum', id: 'cancellationDate', type: 'datetime', editable: 'true'},
            {label: 'Ende des Testzeitraums', id: 'contractEndDate', type: 'datetime', editable: 'true'},
        ],
        [
            {label: 'Retouren-Status', id: 'returnShipmentState', type: 'select', labelValueOptions: true, options: RETURN_SHIPMENT_STATES, editable: 'true'},
            {label: 'Retouren-Label versendet am', id: 'returnShipmentLabelSentDate', type: 'datetime', editable: 'true'},
            {label: 'Sendungsnumer Retoure', id: 'returnShipmentNumber', type: 'text', editable: 'true'},
            {label: 'Retoure eingegangen am', id: 'returnShipmentFinishedDate', type: 'datetime', editable: 'true'}]
    ],

    //Sorting
    ORDERED_BUNDLES_SORT_PARAMS: [
        {id: 'createdDate', label: 'Bestell-Datum'},
        {id: 'lastName', label: 'Nachname'},
        {id: 'color', label: 'Farbe'},
    ],
};

export default OrderedBundlesMetaData;