import React, {Component} from "react";
import {Table} from "react-bootstrap";
import {transformToLocalDate} from "../util/ResourceService";
import {GetFilteredExpiringSubscriptions, GetFilteredNotRenewedSubscriptions} from "./DashboardService";
import {FiHelpCircle} from "react-icons/all";
import ReactTooltip from "react-tooltip";

class Dashboard extends Component{

    constructor(props) {
        super(props);

        this.state = {
            selectedDate: new Date(),
            notRenewedSubscriptions: [],
            expiringSubscriptions: [],
        }
    }

    async componentDidMount() {
        await this.loadNotRenewedSubscriptions(this.state.selectedDate);
        await this.loadExpiringSubscriptions(this.state.selectedDate);
    }

    async loadNotRenewedSubscriptions(date) {
        let dateFrom = transformToLocalDate(date.toString());
        date.setDate(date.getDate() + 1);
        let dateTo = transformToLocalDate(date.toString());
        let loadedSubscriptions = await GetFilteredNotRenewedSubscriptions({endsAfter: dateFrom, endsBefore: dateTo});
        if(!loadedSubscriptions.error) {
            this.setState(prevState => ({...prevState, notRenewedSubscriptions: loadedSubscriptions.result}));
        }
    }

    async loadExpiringSubscriptions(date) {
        let expiringSubscriptions = [];
        let dateFrom = transformToLocalDate(date.toString());
        date.setDate(date.getDate() + 1);
        let dateTo = transformToLocalDate(date.toString());
        let loadedSubscriptions = await GetFilteredExpiringSubscriptions({endsAfter: dateFrom, endsBefore: dateTo, source: "SHOP", article: "subscription"});
        if(!loadedSubscriptions.error) {
            expiringSubscriptions = loadedSubscriptions.result;

            let loadedTickets = await GetFilteredExpiringSubscriptions({endsAfter: dateFrom, endsBefore: dateTo, source: "SHOP", article: "tigerticket"});
            if(!loadedTickets.error) {
                expiringSubscriptions = expiringSubscriptions.concat(loadedTickets.result);

                this.setState(prevState => ({...prevState, expiringSubscriptions: expiringSubscriptions}));
            }
        }
    }

    render() {
        return(
            <div>

                <div className="content-header">
                    <div className="content-header-title">Dashboard</div>
                </div>
                <div className="dashboard-content">
                    <div className="dashboard-content dashboard-card" style={{marginRight: "4%"}}>
                        <div className="dashboard-content dashboard-card card-title">
                            Today's expiring tickets and subscriptions&#xA0;<span style={{float: "right", marginRight: "10px"}}><a style={{color: "black"}} href="#/" data-tip data-for='expiringSubscriptionsTooltip'><FiHelpCircle/></a></span>
                        </div>
                        <div className="dashboard-content dashboard-card card-content">
                            {this.state.expiringSubscriptions.length > 0 ?
                                <Table bordered striped hover responsive>
                                    <thead>
                                    <tr>
                                        <th>ID</th>
                                        <th>Article</th>
                                        <th>Source</th>
                                        <th>Account ID</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {this.state.expiringSubscriptions.map(sub => (
                                        <tr role="row" key={sub.id}>
                                            <td>{sub.id}</td>
                                            <td>{sub.articleId}</td>
                                            <td>{sub.source}</td>
                                            <td>{sub.accountId}</td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </Table>
                                :
                                <p style={{textAlign: "center", paddingTop: "25px", fontSize: "18px"}}>The list is empty</p>
                            }
                        </div>
                    </div>

                    <div className="dashboard-content dashboard-card">
                        <div className="dashboard-content dashboard-card card-title">
                            Today's not renewed subscriptions&#xA0;<span style={{float: "right", marginRight: "10px"}}><a style={{color: "black"}} href="#/" data-tip data-for='notRenewedSubscriptionsTooltip'><FiHelpCircle/></a></span>
                        </div>
                        <div className="dashboard-content dashboard-card card-content">
                            {this.state.notRenewedSubscriptions.length > 0 ?
                                <Table bordered striped hover responsive>
                                    <thead>
                                    <tr>
                                        <th>ID</th>
                                        <th>Article</th>
                                        <th>Source</th>
                                        <th>Account ID</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {this.state.notRenewedSubscriptions.map(sub => (
                                        <tr role="row" key={sub.id}>
                                            <td>{sub.id}</td>
                                            <td>{sub.articleId}</td>
                                            <td>{sub.source}</td>
                                            <td>{sub.accountId}</td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </Table>
                                :
                                <p style={{textAlign: "center", paddingTop: "25px", fontSize: "18px"}}>The list is empty</p>
                            }
                        </div>
                    </div>
                </div>

                <ReactTooltip id='notRenewedSubscriptionsTooltip' aria-haspopup='true' role='example'>
                    <p style={{fontWeight: "bold"}}>Not renewed subscriptions of today</p>
                    <p style={{width: "400px"}}>A list of expiring subscriptions (valid_until = today) and the corresponding account ids of customers who don't have purchased a new tigerticket or renewed their subscription</p>
                </ReactTooltip>

                <ReactTooltip id='expiringSubscriptionsTooltip' aria-haspopup='true' role='example'>
                    <p style={{fontWeight: "bold"}}>Expiring subscriptions and tickets of today</p>
                    <p style={{width: "400px"}}>A list of expiring subscriptions (valid_until = today) + tigertickets and the corresponding account ids (including renewed subscriptions)</p>
                </ReactTooltip>

            </div>
        )
    }


}

export default Dashboard;