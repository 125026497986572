import {Button} from "react-bootstrap";
import React, {Component} from "react";
import {withToast} from "../../util/ToastService";
import {Link} from "react-router-dom";
import {IoPeopleCircleOutline,} from "react-icons/all";
import GlobalConstants from "../../config/GlobalConstants";
import {CreateCouponVariant, GetCouponVariant, UpdateCouponVariant} from "./PremiumAccessTypeService";
import {DetailsSection} from "../../generators/DetailsGenerator";
import PremiumAccessTypeMetaData from "./PremiumAccessTypeMetaData";
import {PromiseButton} from "../../global/SpinningTiger";
import {ErrorHandler} from "../../util/ErrorHandler";

class CouponVariantDetails extends Component {

    //------------
    //Constructor
    //------------

    constructor(props) {
        super(props);

        this.state = {
            error: null,
            flowState: props.location.state,
            id: this.props.match.params.id,
            editMode: {
                active: false,
                expertMode: false,
                autoSave: true,
            },
            couponVariant: {
            },
            originalCouponVariant: {},
            billwerkProducts: [],
            productPage: 0,
            lastProductPage: 0,
            showProductModal: false,
            sections: {
                general: true,
                accessTypeDetails: true,
            }
        }
    }

    //---------
    //Mounting
    //---------

    async componentDidMount() {
        //Set the title
        if(this.state.id != null) {
            document.title = "Coupon Variant " + this.state.id + " :: Tiger UI";
        } else {
            document.title = "New Coupon Variant :: Tiger UI";
        }
        await this.loadCouponVariant();
    }

    //--------
    //Loading
    //--------

    async loadCouponVariant() {
        let loadedCoupon = {};

        if(this.state.id != null && this.state.id !== "add") {

            loadedCoupon = await GetCouponVariant(this.state.id);

            if (!loadedCoupon.error) {
                const couponVariant = loadedCoupon.result;

                this.setState({
                    id: this.state.id,
                    editMode: this.state.editMode,
                    couponVariant: couponVariant,
                    originalCouponVariant: couponVariant,
                });
            } else {
                this.setState(prevState => ({...prevState, error: loadedCoupon}));
            }
        } else {
            this.setState((prevState) => ({...prevState,
                id: null,
                couponVariant: {
                    id: null,
                    couponType: PremiumAccessTypeMetaData.COUPON_VARIANT_COUPON_TYPES[0],
                    redemptionType: PremiumAccessTypeMetaData.COUPON_VARIANT_REDEMPTION_TYPES[0],
                    createdBy: "system"
                },
                editMode: {
                    active: true,
                    expertMode: false,
                    autoSave: false,
                }}));
        }
    }

    //----------
    //Rendering
    //----------

    render() {

        return (
            <>
                <div className="details-title">
                    {this.state.couponVariant.id != null ? "Details of Coupon Variant " + this.state.couponVariant.id : "Create a Coupon Variant"}
                </div>
                <div className="details-button-box" style={{height: "70px"}}>
                    <Link to={{
                        pathname: "/" + GlobalConstants.APP_PATH + "purchases/premium-access-types",
                        state: this.state.flowState
                    }}>
                        <button className="form-btn-ci-light-blue" type="button">Back</button>
                    </Link>
                    {this.state.couponVariant.id != null &&
                    <button className={this.state.editMode.active ? "form-btn-ci-red" : "form-btn-ci-blue"}
                            type="button" onClick={() => this.setState({
                        editMode: {
                            active: !this.state.editMode.active,
                            expertMode: this.state.editMode.expertMode
                        },
                        couponVariant: this.state.originalCouponVariant
                    })}>{this.state.editMode.active ? "Cancel" : "Edit"}</button>
                    }
                    {this.state.editMode.active &&
                        <PromiseButton text="Save" onClick={() => this.saveOrUpdateCouponVariant()} />
                    }
                    <Button style={{float: "right", marginTop: "5px", marginRight: "10px"}}
                            variant={this.state.editMode.autoSave ? "secondary" : "outline-secondary"}
                            onClick={() => this.setState(prevState => ({
                                ...prevState,
                                editMode: {
                                    ...prevState.editMode,
                                    autoSave: !this.state.editMode.autoSave
                                }
                            }))}>Auto Save</Button>
                </div>

                <div className="details-box-title">Resource Details</div>
                <div className="details-box">
                    {/* GENERAL DATA */}
                    <DetailsSection
                        nameInState="couponVariant"
                        fields={PremiumAccessTypeMetaData.COUPON_VARIANT_GENERAL_DETAILS}
                        state={this.state}
                        onSetState={(s) => this.setState(s)}
                        onUpdateResource={() => this.saveOrUpdateCouponVariant()}
                        sectionId="general"
                    />

                    <DetailsSection
                        nameInState="couponVariant"
                        fields={PremiumAccessTypeMetaData.COUPON_VARIANT_VARIANT_DETAILS}
                        state={this.state}
                        onSetState={(s) => this.setState(s)}
                        onUpdateResource={() => this.saveOrUpdateCouponVariant()}
                        sectionId="accessTypeDetails"
                        label={<span><IoPeopleCircleOutline/>&#xA0;Variant Details</span>}
                    />

                </div>
                <div className="details-button-box" style={{height: "70px"}}>
                    <Link to={{
                        pathname: "/" + GlobalConstants.APP_PATH + "purchases/premium-access-types",
                        state: this.state.flowState
                    }}>
                        <button className="form-btn-ci-light-blue" type="button">Back</button>
                    </Link>
                    {this.state.editMode.active &&
                        <PromiseButton text="Save" onClick={() => this.saveOrUpdateCouponVariant()} />
                    }
                </div>

                <ErrorHandler error={this.state.error}
                              onHide={() => this.setState(prevState => ({...prevState, error: null}))} />

            </>
        );
    }

    //------------
    //API Methods
    //------------

    async saveOrUpdateCouponVariant() {
        let couponVariant = {};
        if(this.state.couponVariant.id != null) {
            couponVariant = await UpdateCouponVariant(this.state.couponVariant);
        } else {
            couponVariant = await CreateCouponVariant(this.state.couponVariant);
        }

        if (!couponVariant.error) {
            this.setState((prevState) =>({...prevState, couponVariant: couponVariant.result, editMode: {...prevState.editMode, active: this.state.editMode.autoSave}}));

            this.props.addToast("The coupon variant has been updated successfully.", {
                autoDismiss: true,
                appearance: 'success'
            });
        } else {
            this.setState(prevState => ({...prevState, error: couponVariant}));
        }
    }

}


export default withToast(CouponVariantDetails);