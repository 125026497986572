import {isNumeric, transformDateToReadableDate, transformStringToReadableDate} from "../../util/ResourceService";
import {Button} from "react-bootstrap";
import {
    AiFillAndroid,
    AiOutlineShop,
    FaApple,
    FiRepeat,
    GiPresent,
    IoTicketOutline,
    RiQuestionFill
} from "react-icons/all";
import React from "react";
import GlobalConstants from "../../config/GlobalConstants";
import {Badge} from "reactstrap";

//--------
// GENERAL
//--------

const SubscriptionType = {
    TIGERTICKET: "ticket",
    DIGITICKET: "digiticket",
    SUBSCRIPTION: "Abo TM",
    IAP_APPLE: "Abo Apple",
    IAP_GOOGLE: "Abo Google",
    PRESENT: "Geschenk",
    COUPON: "Swisscard"
}

export function sortByValidUntil(list) {
    return list.sort((a,b) => ((a.validUntil < b.validUntil) ? 1 : (b.validUntil < a.validUntil) ? -1 : 0));
}

export function sortByParamDesc(list, param) {
    return list.sort((a,b) => (a[param] < b[param]) ? 1 : ((b[param] < a[param]) ? -1 : 0));
}

export function isSubscription(s) {
    return s != null && s.articleId != null && (s.articleId.indexOf('subscription') > -1 || s.articleId.indexOf('leseflat') > -1);
}

export function isAppleIap(s) {
    return s != null && s.source != null && (s.source.indexOf("IAP_APPLE") > -1 || s.source.indexOf("IAP_APPLE_RESTORE") > -1);
}

export function isGoogleIap(s) {
    return s != null && s.source != null && (s.source.indexOf("IAP_GOOGLE") > -1 || s.source.indexOf("IAP_GOOGLE_RESTORE") > -1);
}

export function isTicket(s) {
    return s != null && s.articleId != null && s.articleId.indexOf('tigerticket') > -1;
}

export function isCoupon(s) {
    return s != null && s.articleId != null && s.articleId.indexOf("coupon") > -1;
}

export function isSupportPresent(s) {
    return s != null && s.articleId != null && (s.articleId === GlobalConstants.PRODUCT_IAP_1_WEEK_PREMIUMACCESS ||
        s.articleId === GlobalConstants.PRODUCT_IAP_1_MONTH_PREMIUMACCESS || s.article === GlobalConstants.PRODUCT_IAP_1_YEAR_PREMIUMACCESS);
}

export function isDigital(s) {
    return s != null && s.articleId != null && s.articleId.indexOf("digital") > -1;
}

export function isSwiss(s) {
    return s != null && s.articleId != null && s.articleId.indexOf('swiss') > -1;
}

export function isTigertones(s) {
    return s != null && s.articleId != null && s.articleId.indexOf('tigertones') > -1;
}

export function presentIsActive(p) {
    let now = new Date();
    return p != null && p.StartDate != null && p.EndDate != null && new Date(p.StartDate) < now && new Date(p.EndDate) > now;
}

export function ticketIsActive(t) {
    let now = new Date();
    return t != null && t.phaseStart != null && t.phaseEnd != null && new Date(t.phaseStart) < now && new Date(t.phaseEnd) > now;
}

export function getTicketType(ticket) {
    const t = ticket.ticket
    if (t == null || t.coupon == null || t.coupon.type == null) {
        return null;
    }
    return t.coupon.type;
}

export function getTicketEdition(ticket) {
    const t = ticket.ticket;
    if (t == null || t.coupon == null || t.coupon.edition == null) {
        return null;
    }
    return t.coupon.edition;
}

export function getTicketTypeBadge(t) {
    const type = getTicketType(t);

    let label = type === "DIGITICKET" ? "digiticket" : (type === "TIGERTICKET" ? "Ticket" : "Coupon");

    return (
        <Badge color={type === "DIGITICKET" ? "primary" : (type === "TIGERTICKET" ? "danger" : "warning")} pill>
            {label}
        </Badge>
    )
}
export function getPresentType(p) {
    if (p.PlanVariantId === GlobalConstants.PLANVARIANT_ID_PRESENT_1WEEK) {
        return "1 Woche";
    } else if (p.PlanVariantId === GlobalConstants.PLANVARIANT_ID_PRESENT_1MONTH) {
        return "1 Monat";
    } else if (p.PlanVariantId === GlobalConstants.PLANVARIANT_ID_PRESENT_1YEAR) {
        return "1 Jahr";
    } else {
        return null;
    }
}

export function getPresentTypeBadge(p) {
    return (
        <Badge color={p.PlanVariantId === GlobalConstants.PLANVARIANT_ID_PRESENT_1WEEK ?
            "success" : (p.PlanVariantId === GlobalConstants.PLANVARIANT_ID_PRESENT_1MONTH ? "warning" : "danger")
        } pill>{getPresentType(p)}</Badge>)
}

export function getSubscriptionSource(s) {
    if (isSupportPresent(s)) {
        return SubscriptionType.PRESENT;
    } else if (isSubscription(s)) {
        if (isGoogleIap(s)) {
            return SubscriptionType.IAP_GOOGLE;
        } else if (isAppleIap(s)) {
            return SubscriptionType.IAP_APPLE;
        } else {
            return SubscriptionType.SUBSCRIPTION;
        }
    } else if (isCoupon(s)) {
        return SubscriptionType.COUPON;
    } else {
        if (isDigital(s)) {
            return SubscriptionType.DIGITICKET;
        } else {
            return SubscriptionType.TIGERTICKET;
        }
    }
}
export function getPremiumTypeBadge(s) {
    if (s === null) {
        return <></>
    }

    let type = getSubscriptionSource(s);

    return (
        <Badge color={(isSupportPresent(s) || isCoupon(s))? "success" : (isTicket(s) ? 'danger' : 'primary')} pill>
            {isSupportPresent(s) ? <GiPresent size={20} /> : (isTicket(s) ? <IoTicketOutline size={20} /> : <FiRepeat size={20} /> )}
            {type}
        </Badge>
    );
}

export function getSubscriptionSourceBadge(s) {
    if (s == null) {
        return <></>
    }

    return (
        <Badge color={isAppleIap(s) ? "danger" : (isGoogleIap(s) ? "success" : "primary")} pill>
            {isAppleIap(s) ? <FaApple size={20} /> : (isGoogleIap(s) ? <AiFillAndroid size={20} /> : <AiOutlineShop size={20} />)}
            {isAppleIap(s) ? " Apple" : (isGoogleIap(s) ? " Google" : " Billwerk")}
        </Badge>
    )
}

export function getSubscriptionTypeBadge(s) {
    if (s == null || s.articleId == null) {
        return <></>
    }

    switch (s.articleId) {

        case GlobalConstants.PRODUCT_IAP_SUB_1MONTH_OLD:
            return <Badge color="danger" pill><FiRepeat size={20}/>&#xA0;1 Monat (alt)</Badge>;
        case GlobalConstants.PRODUCT_IAP_SUB_1MONTH:
            return <Badge color="primary" pill><FiRepeat size={20}/>&#xA0;1 Monat</Badge>;
        case GlobalConstants.PRODUCT_IAP_SUB_12MONTH_MIN:
            return <Badge color="secondary" pill><FiRepeat size={20}/>&#xA0;1 Monat (12 Monate min)</Badge>;
        case GlobalConstants.PRODUCT_IAP_SUB_24MONTH_MIN:
            return <Badge color="success" pill><FiRepeat size={20}/>&#xA0;1 Monat (24 Monate min)</Badge>;
        case GlobalConstants.PRODUCT_IAP_SUB_4WEEK_COUPON:
            return <Badge color="primary" pill><FiRepeat size={20}/>&#xA0;1 Monat</Badge>;
        case GlobalConstants.PRODUCT_IAP_SUB_4WEEK_COUPON_2021:
            return <Badge color="primary" pill><FiRepeat size={20}/>&#xA0;1 Monat</Badge>;
        case GlobalConstants.PRODUCT_IAP_SUB_4WEEK_COUPON_KITA:
            return <Badge color="primary" pill><FiRepeat size={20}/>&#xA0;1 Monat</Badge>;
        case GlobalConstants.PRODUCT_IAP_SUB_TIGERBOOKS_1WEEK:
            return <Badge color="warning" pill><FiRepeat size={20}/>&#xA0;1 Woche</Badge>;
        case GlobalConstants.PRODUCT_IAP_SUB_TIGERBOOKS_1MONTH:
            return <Badge color="primary" pill><FiRepeat size={20}/>&#xA0;1 Monat</Badge>;
        case GlobalConstants.PRODUCT_IAP_SUB_TIGERBOOKS_6MONTH:
            return <Badge color="info" pill><FiRepeat size={20}/>&#xA0;6 Monate</Badge>;
        case 'tigertones.subscription.1month.bill.1minwithbox':
            return <Badge color="info" pill><FiRepeat size={20}/>&#xA0;Kombi-Paket Flex</Badge>;
        case 'tigertones.subscription.1month.bill.12minwithbox':
            return <Badge color="info" pill><FiRepeat size={20}/>&#xA0;Kombi-Paket 12</Badge>;
        case 'tigertones.subscription.1month.bill.24minwithbox':
            return <Badge color="info" pill><FiRepeat size={20}/>&#xA0;Kombi-Paket 24</Badge>;
        default:
            return <></>;
    }

}

//--------
// SORTING
//--------
export function sortTicketListBy(field, data, direction) {
    if (field == null) {
        return data;
    }
    if (field === 'used') {
        if (direction === "ASC") {
            return data.sort((a, b) => b.ticket.used - a.ticket.used);
        } else {
            return data.sort((a, b) => a.ticket.used - b.ticket.used);
        }
    } else if (field === 'type') {
        if (direction === "ASC") {
            return data.sort((a, b) => {
                if (b.ticket.coupon.type > a.ticket.coupon.type) {
                    return -1;
                }
                if (a.ticket.coupon.type > b.ticket.coupon.type) {
                    return 1;
                }
                return 0;
            });
        } else {
            return data.sort((a, b) => {
                if (b.ticket.coupon.type > a.ticket.coupon.type) {
                    return 1;
                }
                if (a.ticket.coupon.type > b.ticket.coupon.type) {
                    return -1;
                }
                return 0;
            });
        }
    } else if (field === 'swiss') {
        if (direction === "ASC") {
            return data.sort((a, b) => {
                if (b.ticket.coupon.edition > a.ticket.coupon.edition) {
                    return -1;
                }
                if (a.ticket.coupon.edition > b.ticket.coupon.edition) {
                    return 1;
                }
                return 0;
            });
        } else {
            return data.sort((a, b) => {
                if (b.ticket.coupon.edition > a.ticket.coupon.edition) {
                    return 1;
                }
                if (a.ticket.coupon.edition > b.ticket.coupon.edition) {
                    return -1;
                }
                return 0;
            });
        }
    } else if (field === 'reserved') {
        if (direction === "ASC") {
            return data.sort((a, b) => {
                if (b.ticket.reserved > a.ticket.reserved) {
                    return -1;
                }
                if (a.ticket.reserved > b.ticket.reserved) {
                    return 1;
                }
                return 0;
            });
        } else {
            return data.sort((a, b) => {
                if (b.ticket.reserved > a.ticket.reserved) {
                    return 1;
                }
                if (a.ticket.reserved > b.ticket.reserved) {
                    return -1;
                }
                return 0;
            });
        }
    } else if (field === 'phaseStart') {
        if (direction === "ASC") {
            return data.sort((a, b) => {
                if (b.phaseStart > a.phaseStart) {
                    return -1;
                }
                if (a.phaseStart > b.phaseStart) {
                    return 1;
                }
                return 0;
            });
        } else {
            return data.sort((a, b) => {
                if (b.phaseStart > a.phaseStart) {
                    return 1;
                }
                if (a.phaseStart > b.phaseStart) {
                    return -1;
                }
                return 0;
            });
        }
    } else if (field === 'phaseEnd') {
        if (direction === "ASC") {
            return data.sort((a, b) => {
                if (b.phaseEnd > a.phaseEnd) {
                    return -1;
                }
                if (a.phaseEnd > b.phaseEnd) {
                    return 1;
                }
                return 0;
            });
        } else {
            return data.sort((a, b) => {
                if (b.phaseEnd > a.phaseEnd) {
                    return 1;
                }
                if (a.phaseEnd > b.phaseEnd) {
                    return -1;
                }
                return 0;
            });
        }
    } else {
        return data;
    }
}

//---------------
// ERROR HANDLING
//---------------

export function getTicketRedemptionError(response) {
    if (response == null || response.code == null || response.errorCode == null) {
        return "Ein unbekannter Fehler ist aufgetreten. Bitte versuche es gleich noch einmal oder wende dich an einen Adrministrator."
    }

    const errorCode = Number(response.errorCode);

    switch (errorCode) {
        case 201:
            return "Dieses Ticket ist keinem Billwerk-Produkt zugeordnet. Um das zu ändern, öffne das Ticket unter 'Purchases -> Tickets' und gib eine 'Mapped Billwerk Product ID an'";
        case 6:
            return "Dieses Ticket hat einen für die Einlösung ungültigen Typ (erlaubt sind: DIGITICKET,TIGERTICKET,COUPON)";
        case 10:
            return "Dieses Ticket wurde bereits eingelöst und muss zunächst zurückgesetzt werden.";
        case 3:
            return "Eine benötigte Ressource wurde nicht gefunden: " + response.message;
        case 24:
            return "Der Kunde hat ein aktives, nicht gekündigtes Abo";
        case 25:
            return "Der Kunde hat ein aktives Abo, das bereits gekündigt wurde und am " + response.message + " abläuft";
        case 38:
            return "Der Kunde hat mehrere aktive Verträge in Billwerk. Damit das Ticket eingelöst werden kann, darf maximal 1 aktiver Vertrag existieren";
        case 42:
            return "Der Kunde hat bereits einen 'temporär inaktiven' Vertrag in Billwerk, der nach dem aktuell aktiven Vertrag startet. Erst wenn dieser Vertrag aktiv wird, kann ein weiteres Ticket eingelöst werden";
        case 40:
            return "Für diesen Kunden existiert kein Vertrag, der in einen Swiss-Zugang umgewandelt werden kann";
        case 41:
            return "Der Kunde hat ein aktives Abo. Das Swiss Upgrade kann nur für Tickets verwendet werden";
        case 39:
            return "Der Kunde hat bereits einen Swiss-Zugang.";
        default:
            return "Ein nicht definierter Fehlercode " + errorCode + " wurde zurückgegeben. Bitte wende dich an einen Administrator.";

    }
}

//--------
// TICKETS
//--------

export function calculateTicketValidity(ticket) {
    if(ticket.redeemedAt == null) {
        return "";
    }

    let validity = new Date(ticket.redeemedAt.replace(/\s/g, 'T'));
    let coupon = ticket.coupon;
    let period = coupon.period;

    switch (period) {
        case "P1M":
            validity.setMonth(validity.getMonth() + 1);
            break;
        case "P3M":
            validity.setMonth(validity.getMonth() + 3);
            break;
        case "P6M":
            validity.setMonth(validity.getMonth() + 6);
            break;
        case "P12M":
            validity.setMonth(validity.getMonth() + 12);
            break;
        case "P24M":
            validity.setMonth(validity.getMonth() + 24);
            break;
        default:
            validity.setMonth(validity.getMonth() + 1);
            break;
    }

    return validity.toString();
}

export function getTicketValidUntil(ticket) {
    if(ticket.reserved == null) {
        return "";
    }
    let validity = calculateTicketValidity(ticket);

    return transformDateToReadableDate(validity.toString());
}

export function getTicketDuration(ticket) {
    //Get the matching coupon first and the corresponding period
    let coupon = ticket.coupon;
    let period = coupon.period;

    switch (period) {
        case "P1D":
            return "1 Tag";
        case "P1W":
            return "1 Woche";
        case "P6W":
            return "6 Wochen";
        case "P1M":
            return "1 Monat";
        case "P3M":
            return "3 Monate";
        case "P6M":
            return "6 Monate";
        case "P12M":
            return "12 Monate";
        case "P24M":
            return "24 Monate";
        default:
            return "Unbekannt";
    }
}

export function calculateTicketLimit(duration) {
    let ticketLimit = new Date();
    switch (duration) {
        case "1 Woche":
            ticketLimit = ticketLimit.setDate(ticketLimit.getDate() + 7);
            break;
        case "1 Monat":
            ticketLimit = ticketLimit.setMonth(ticketLimit.getMonth() + 1);
            break;
        case "3 Monate":
            ticketLimit = ticketLimit.setMonth(ticketLimit.getMonth() + 3);
            break;
        case "6 Monate":
            ticketLimit = ticketLimit.setMonth(ticketLimit.getMonth() + 6);
            break;
        case "12 Monate":
            ticketLimit = ticketLimit.setMonth(ticketLimit.getMonth() + 12);
            break;
        case "24 Monate":
            ticketLimit = ticketLimit.setMonth(ticketLimit.getMonth() + 24);
            break;
        default:
            ticketLimit = ticketLimit.setMonth(ticketLimit.getMonth() + 1);
            break;
    }
    return ticketLimit;
}

//--------------
// SUBSCRIPTIONS
//--------------

export function findTicketBySubscription(subscriptionFromSpine, ticketsFromBillwerk, orders) {

    let ticket = {};
    let matchingOrder = {};

    //Find the matching order resource
    if(orders.length > 0) {
        let matchingOrders = orders.filter(order => order.id === subscriptionFromSpine.orderId);
        if(matchingOrders.length === 1) {
            matchingOrder = matchingOrders[0];
        }
    }

    //Find the matching coupon code if an order has been found
    if(matchingOrder.id !== null) {
        let matchingTickets = ticketsFromBillwerk.filter(t => t.id === matchingOrder.couponCodeId);
        if(matchingTickets.length === 1) {
            ticket = matchingTickets[0];
        }
    }

    return ticket;
}

export function findSubscriptionByTicket(ticket, subscriptionsFromSpine, orders) {
    let subscription = {};
    let matchingOrder = {};

    //Find the matching order resource
    if(orders.length > 0) {
        let matchingOrders = orders.filter(order => order.couponCodeId === ticket.id);
        if(matchingOrders.length === 1) {
            matchingOrder = matchingOrders[0];
        }
    }

    //Find the matching subscription if an order has been found
    if(matchingOrder.id != null) {
        let matchingSubs = subscriptionsFromSpine.filter(s => s.orderId === matchingOrder.id);
        if(matchingSubs.length === 1) {
            subscription = matchingSubs[0];
        }
    }

    return subscription;
}

function hasNumber(testString) {
    return /\d/.test(testString);
}

export function getSubscriptionPrices(subscription, subscriptionProducts) {
    let prices = [];
    let matchingProds = [];
    matchingProds = subscriptionProducts.filter(prod => prod.iapProductIdentifier === subscription.articleId);
    if(matchingProds.length > 0) {
        prices = matchingProds[0].prices;
    }
    return prices;
}

export function getSubscriptionDuration(subscription) {
    //Get the article ID
    let articleId = subscription.articleId;

    //Get the time unit (week/month etc.)
    let timeunit = "";
    let duration = "";
    let index = -1;

    //Split the article id into an array
    let articleArray = articleId.split(".");

    //Find the array entry that contains a number
    articleArray = articleArray.filter(e => hasNumber(e));

    if (articleArray.length > 0) {

        //filter out numeric entries
        articleArray = articleArray.filter(e => !isNumeric(e));

        //Duration and timeunit
        let time = articleArray[0];
        time = time.replace("_","");

        if(time.indexOf('d') > - 1) {
            timeunit = "Tage";
            index = time.indexOf('d');
        } else if(time.indexOf('w') > -1) {
            timeunit = "Wochen";
            index = time.indexOf('w');
        } else if(time.indexOf('m')) {
            timeunit = "Monate";
            index = time.indexOf('m');
        } else if(time.indexOf('y')) {
            timeunit = "Jahre";
            index = time.indexOf('y');
        }

        if(index > -1) {
            duration = time.substring(0, index);
        }

        if(duration === "1") {
            timeunit = timeunit.substring(0, timeunit.length - 1);
        }

    }

    //Return the original article id if the duration or timeunit could not be validated
    if(duration === "" || timeunit === "") {
        return "Unbekannt (" + articleId + ")";
    }

    return duration + " " + timeunit;
}

export function getSubscriptionValidUntil(subscription) {
    let validity = new Date(subscription.validFrom.replace(/\s/g, 'T'));
    let durationWithUnit = getSubscriptionDuration(subscription);

    //Return the original valid until of the subscription if the duration could not be validated
    if(durationWithUnit.indexOf("Unbekannt") > -1) {
        return transformStringToReadableDate(subscription.validUntil);
    }

    let duration = durationWithUnit.substring(0, durationWithUnit.indexOf(" "));
    let timeunit = durationWithUnit.substring(durationWithUnit.indexOf(" "), durationWithUnit.length);

    if(timeunit.indexOf("Tag") > -1) {
        validity.setDate(validity.getDate() + Number(duration));
    } else if(timeunit.indexOf("Woche") > -1) {
        validity.setDate(validity.getDate() + Number(duration) * 7);
    } else if(timeunit.indexOf("Monat") > -1) {
        validity.setMonth(validity.getMonth() + Number(duration));
    } else if(timeunit.indexOf("Jahr") > -1) {
        validity.setMonth(validity.getMonth() + Number(duration) * 12);
    }

    return transformDateToReadableDate(validity.toString());
}

export function getSubscriptionsType(subscription) {
    let source = subscription.source;
    switch (source) {
        case "ADMIN":
            return "Abo (Administrator)";
        case "SUPPORT":
            return "Abo (Support)";
        case "UNKNOWN":
            return "Abo (Unbekannte Quelle)";
        case "IAP_APPLE":
            return "Abo Apple";
        case "IAP_GOOGLE":
            return "Abo Google";
        case "SHOP":
            return "Abo TM";
        case "MOBILCOM":
            return "Mobilcom-Abo";
        case "PARTNER":
            return "Partner-Abo";
        case "COUPON":
            return "Coupon-Abo";
        case "LIBRARY":
            return "Library-Abo";
        case "GUESTACCOUNT":
            return "Gast-Abo";
        case "SUBSCRIPTION":
            return "Subscription-Abo";
        case "ACCOUNTCREATION":
            return "Neukunden-Abo";
        case "IAP_APPLE_RESTORE":
            return "Apple-Abo Wiederherstellung";
        case "IAP_GOOGLE_RESTORE":
            return "Google-Abo Wiederherstellung";
        case "TEST":
            return "Test-Abo";
        default:
            return "Abo (Unbekannte Quelle)";
    }
}

export function generateWildcardListItem(wildcard, onOpenProduct, onOpenPlaylist, openUploadInfo, openTranscodeInfo) {
    //Check if this wildcard has content at all
    if(wildcard["_embedded"] == null) {
        //It does not have any content
        return (
          <div style={{width: "500px", textAlign: "center"}}>
              <span style={{textAlign: "center", fontSize: "20px"}}>
                  Kein Produkt oder eigener Content hochgeladen
              </span>
          </div>
        );
    }

    //First check if the assigned product is the default
    if(wildcard["defaultProductAssigned"]) {
        return (
            <div style={{width: "500px", textAlign: "center"}}>
              <span style={{textAlign: "center", fontSize: "18px"}}>
                  Keinem tigertones-Produkt zugeordnet oder eigenen Content hochgeladen
              </span>
            </div>
        );
    }

    //Wildcard has content -> Check if it is a tigertones product or account generated content
    let isTonesProduct = wildcard["_embedded"]["products"] != null;

    if(isTonesProduct) {
        //Generate tigertones product
        let product = wildcard["_embedded"]["products"];
        return(
            <div style={{width: "500px"}}>
                <div style={{float: "left", marginRight: "20px"}}>
                    <img src={wildcard.coverUrl} width={150} alt={"Cover of wildcard with code " + wildcard.code}/>
                </div>
                <div>
                    <ul style={{listStyleType: "none"}}>
                        <li><span style={{fontWeight: "bold", borderLeftStyle: "solid", borderLeftWidth: "4px", paddingLeft: "10px"}}>Title: </span>{product.title}</li>
                        <li><span style={{fontWeight: "bold", borderLeftStyle: "solid", borderLeftWidth: "4px", paddingLeft: "10px"}}>Status: </span>
                            <Badge bg={product.state === 'NEW' ? 'primary' : product.state === 'ACTIVE' ? 'success' : product.state === 'INACTIVE' ? 'warning' : 'danger'}>{product.state === 'NEW' ? "Neu" : product.state === 'ACTIVE' ? "Aktiv" : product.state === 'INACTIVE' ? "Inaktiv" : "Entfernt"}</Badge></li>
                    </ul>
                    <span>
                        <Button variant="secondary" onClick={() => onOpenProduct(product.id)}>Öffne Produkt</Button>
                    </span>
                </div>
            </div>
        )
    } else {
        //Account generated content
        let content = wildcard["_embedded"]["accountgeneratedcontents"];
        let selfLink = content["_links"]["self"]["href"];
        let nfcCardId = selfLink.substring(selfLink.indexOf("nfcCardId=") + 10, selfLink.length);
        return (
            <div style={{width: "500px"}}>
                <div style={{float: "left", marginRight: "20px"}}>
                    <img src={wildcard.coverUrl} width={150} alt={"Cover of wildcard with code " + wildcard.code}/>
                </div>
                <div>
                    <ul style={{listStyleType: "none"}}>
                        <li><span style={{fontWeight: "bold", borderLeftStyle: "solid", borderLeftWidth: "4px", paddingLeft: "10px"}}>Name: </span>{content.name}</li>
                        <li><span style={{fontWeight: "bold", borderLeftStyle: "solid", borderLeftWidth: "4px", paddingLeft: "10px"}}>Länge: </span>{Math.floor(content.totalDuration/60) < 10 ? "0" + Math.floor(content.totalDuration/60) : Math.floor(content.totalDuration/60)}:{content.totalDuration%60 < 10 ? "0" + content.totalDuration%60 : content.totalDuration%60} min</li>
                        <li><span style={{fontWeight: "bold", borderLeftStyle: "solid", borderLeftWidth: "4px", paddingLeft: "10px"}}>Verfügbar: </span>{Math.floor(content.unusedDuration/60) < 10 ? "0" + Math.floor(content.unusedDuration/60) : Math.floor(content.unusedDuration/60)}:{content.unusedDuration%60 < 10 ? "0" + content.unusedDuration%60 : content.unusedDuration%60} min</li>
                        <li><span style={{fontWeight: "bold", borderLeftStyle: "solid", borderLeftWidth: "4px", paddingLeft: "10px"}}>Transcoding-Status: </span><Badge bg={content.transcodingStatus === 'FINISHED' ? 'success' : 'danger'}>{content.transcodingStatus === 'FINISHED' ? "Fertig" : content.transcodingStatus}</Badge>&#xA0;{content.transcodingStatus !== 'FINISHED' && <span style={{cursor: "pointer"}} onClick={() => openTranscodeInfo()}><RiQuestionFill /></span>}</li>
                        <li><span style={{fontWeight: "bold", borderLeftStyle: "solid", borderLeftWidth: "4px", paddingLeft: "10px"}}>Fehler: </span><Badge bg={wildcard.uploadInformation != null ? "danger" : "success"}>{wildcard.uploadInformation != null ? wildcard.uploadInformation : "Nein"}</Badge>&#xA0;{wildcard.uploadInformation != null && <span style={{cursor: "pointer"}} onClick={() => openUploadInfo()}><RiQuestionFill /></span>}</li>
                        <li><span style={{fontWeight: "bold", borderLeftStyle: "solid", borderLeftWidth: "4px", paddingLeft: "10px"}}>Öffentlich: </span><Badge bg={content.publicContent ? "success" : "danger"}>{content.publicContent ? "Ja" : "Nein"}</Badge></li>
                    </ul>
                    <span>
                        <Button variant="secondary" onClick={() => onOpenPlaylist(wildcard.accountGeneratedContentId, nfcCardId)}>Öffne Playlist</Button>
                    </span>
                </div>
            </div>
        )
    }
}

//DEVICES
export const SupportToolMetaData = {

    //Codes & Info
    ProcessingFailureCodes: [
        {code: 'DURATION_CAN_NOT_DETERMINED', description: 'Die Spieldauer der Datei kann nicht ermittelt werden. Das könnte zum Beispiel daran liegen, dass die Datei defekt ist.'},
        {code: 'AUDIO_FILE_WITH_LENGTH_0', description: 'Eine der hochgeladenen Dateien hat die Länge 0, der Vorgang wird abgebrochen. Die entsprechende Datei sollte entfernt werden.'},
        {code: 'DURATION_EXCEEDED', description: 'Die Gesamtlänge beträgt mehr als die zugelassene Länge von 120 Minuten.'},
        {code: 'UPLOAD_LIMIT_EXCEEDED', description: 'Anzahl erlaubter Uploads pro Zeiteinheit wurde überschritten. Der Upload sollte später noch einmal versucht werden'},
        {code: 'TRANSCODING_FAILED', description: 'Die Verarbeitung/das Transcoding ist gescheitert. Das könnte an einer defekten/ nicht unterstützten Datei oder einem Fehler im Backend liegen.'},
        {code: 'PROCESSING_TRANSCODED_CONTENT_FAILED', description: 'Die Verarbeitung des bereits transcodierten Contents ist gescheitert (z.B. konnten die Dateien nicht öffentlich lesbar gemacht werden). Weitere Details müssen dem Backend-Log entnommen werden.'},
    ],

    TranscodeStatusCodes: [
        {code: 'UPLOADING_BY_USER', description: 'Der Upload wurde vom Kunden gestartet und wird gerade durchgeführt.'},
        {code: 'CHECK_FOR_DURATION', description: 'Die Spieldauer des aktuell hochgeladenen Contents wird überprüft.'},
        {code: 'FINISHED_AND_CHECK_ADDITIONAL_CONTENT_FOR_DURATION', description: 'Falls vorhanden, wird die Spieldauer von weiterem Content überprüft.'},
        {code: 'UPLOADED_BY_USER', description: 'Der Upload wurde abgeschlossen und die Spieldauer erfolgreich überprüft.'},
        {code: 'COVER_UPLOADED_BY_USER', description: 'Ein neues Cover wurde für bereits existierenden und transcodeten Content hochgeladen. Kein weiteres Transcoden erforderlich.'},
        {code: 'FINISHED_AND_ADDITIONAL_CONTENT_UPLOADED', description: 'Weiterer Content wurde hochgeladen und dessen Spieldauer überprüft.'},
        {code: 'ENQUEUED', description: 'Der Content wurde in die Warteschlange für den Transcode-Service aufgenommen.'},
        {code: 'FINISHED_AND_ADDITIONAL_CONTENT_ENQUEUED', description: 'Weiterer Content wurde für späteres Transcoden in die Warteschlange aufgenommen.'},
        {code: 'FINISHED_AND_COVER_ENQUEUED', description: 'Ein neu hochgeladenes Cover wurde in die Warteschlange für den Transcode-Service aufgenommen.'},
        {code: 'ENQUEUED_AND_TO_BE_DELETED', description: 'Content wurde in die Warteschlange für das Transcoden aufgenommen, wird aber nach erfolgreicher Bearbeitung gelöscht, da der Kunde den Content zwischenzeitlich für das Löschen markiert hat'},
        {code: 'FINISHED', description: 'Die Verarbeitung wurde erfolgreich abgeschlossen.'},
        {code: 'FINISHED_AND_COVER_UPLOADED_BY_USER', description: 'Die Verarbeitung wurde erfolgreich abgeschlossen und ein neues Cover wurde hochgeladen.'},
        {code: 'FAILED', description: 'Das Transcoding war nicht erfolgreich. Der Content wird gelöscht.'},
        {code: 'TO_BE_DELETED', description: 'Der Content wurde in die Warteschlange zum Löschen aufgenommen.'},
        {code: 'DELETING', description: 'Der Content wird gelöscht.'},
    ],

    INFO_SPINE_SUBSCRIPTIONS: "In diesem Tab wird eine Liste der Ressource 'Subscription' des Kunden angezeigt. Diese beinhaltet alle abgeschlossenen Käufe (i.e. 'Orders'), d.h. alle aktiven & inaktiven Abos sowie eingelösten Tickets. Die aktuell aktive Subscription ist mit einem grünen Haken markiert.",
    INFO_TICKETS: "In diesem Tab werden alle Tickets des Kunden angezeigt, sowohl die eingelösten als auch die nicht eingelösten. Für jedes bereits eingelöste Ticket existiert auch ein Eintrag in der Subscription-Liste. Entsprechende Tickets sind mit einem grünen Haken markiert.",
    INFO_SUBSCRIPTIONS: "In diesem Tab werden alle Abos des Kunden angezeigt. Falls vorhanden, ist das aktive Abo mit einem grünen Haken markiert."


}

