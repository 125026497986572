const COLLECTION_ITEM_TYPE_ITEMS = ['PRODUCT', 'PLAYLIST'];

const CollectionItemMetaData = {

    //Columns
    COLUMNS: [
    ],

    //Details
    DETAILS_GENERAL: [
        [{label: 'ID', id: 'id', type: 'text', editable: 'never'}, {label: 'Collection ID', id: 'collection.id', type: 'text', editable: 'never'}],
        [
            {label: 'Sort Order', id: 'sortNumber', type: 'text', editable: 'true'},
            {label: 'Collection Item Type', id: 'collectionItemType', type: 'select', options: COLLECTION_ITEM_TYPE_ITEMS, editable: 'true'},
        ],
    ],
    COLLECTION_ITEM_TYPES: COLLECTION_ITEM_TYPE_ITEMS,
};

export default CollectionItemMetaData;