import {
    Badge,
    Button,
    ButtonGroup,
    Col,
    Dropdown,
    Form,
    FormCheck,
    FormControl,
    Pagination,
    Table
} from "react-bootstrap";
import Row from 'react-bootstrap/Row';
import React, {Component} from "react";
import {withToast} from "../../util/ToastService";
import {Link} from "react-router-dom";
import {
    AiOutlineEuro,
    AiOutlineEye,
    BsCart4,
    BsTrash,
    GiDuration,
    IoIosRemoveCircleOutline,
    IoPeopleCircleOutline,
    IoTextOutline,
    IoTicketOutline,
} from "react-icons/all";
import GlobalConstants from "../../config/GlobalConstants";
import {
    AddPremiumTypePhase,
    CreatePremiumAccessType,
    DeletePremiumTypePhase, GetAvailableTarifUpgrades,
    GetBillwerkProductPage,
    GetBillwerkProductsByType, GetPossibleTariffSources,
    GetPremiumAccessType,
    GetPremiumAccessTypePage, TogglePaymentRequiredForPhase,
    UpdatePhaseOrder,
    UpdatePremiumAccessType
} from "./PremiumAccessTypeService";
import {DetailsSection} from "../../generators/DetailsGenerator";
import PremiumAccessTypeMetaData from "./PremiumAccessTypeMetaData";
import {Label} from "reactstrap";
import {InfoModal} from "../../generators/ModalGenerator";
import {LoadingIndicator, PromiseButton} from "../../global/SpinningTiger";
import {ErrorHandler} from "../../util/ErrorHandler";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";

class PremiumAccessTypeDetails extends Component {

    //------------
    //Constructor
    //------------

    constructor(props) {
        super(props);

        this.state = {
            error: null,
            flowState: props.location.state,
            id: this.props.match.params.id,
            editMode: {
                active: false,
                expertMode: false,
                autoSave: true,
            },
            premiumAccessType: {
                active: true,
                digital: true,
                reselectable: false,
                paymentRequired: false,
                components: [],
                compatibleBillwerkOrderConfigs: [],
            },
            originalPremiumAccessType: {
                active: true,
                digital: true,
                reselectable: false,
                paymentRequired: false,
                compatibleBillwerkOrderConfigs: [],
            },
            productType: null,
            billwerkProducts: [],
            productPage: 0,
            lastProductPage: 0,
            types: [],
            typePage: 0,
            lastTypePage: 0,
            showTypeModal: false,
            showAddPhaseDialog: false,
            showAvailableUpgrades: false,
            showAvailableSources: false,
            availableUpgrades: [],
            availableSources: [],
            compatibleTypes: [],
            sections: {
                general: true,
                accessTypeDetails: true,
                appearance: true,
                accessTypePhases: true,
            }
        };
        this.onDragEnd = this.onDragEnd.bind(this);
    }

    //---------
    //Mounting
    //---------

    async componentDidMount() {
        //Set the title
        if(this.state.id != null) {
            document.title = "Premium Access Type " + this.state.id + " :: Tiger UI";
        } else {
            document.title = "New Premium Access Type :: Tiger UI";
        }
        await this.loadPremiumAccessType();
    }

    //--------
    //Loading
    //--------

    async loadPremiumAccessType() {
        let loadedPremiumAccessType = {};

        if(this.state.id != null && this.state.id !== "add") {

            loadedPremiumAccessType = await GetPremiumAccessType(this.state.id);

            if (!loadedPremiumAccessType.error) {
                const premiumAccessType = loadedPremiumAccessType.result;
                let compatibleTypes =  [];
                for (let i = 0; i < premiumAccessType.compatibleBillwerkOrderConfigs.length; i++) {
                    let type = await GetPremiumAccessType(premiumAccessType.compatibleBillwerkOrderConfigs[i]);
                    if (!type.error) {
                        compatibleTypes.push(type.result);
                    }
                }
                this.setState({
                    id: this.state.id,
                    editMode: this.state.editMode,
                    premiumAccessType: premiumAccessType,
                    compatibleTypes: compatibleTypes,
                    originalPremiumAccessType: premiumAccessType,
                });
            } else {
                this.setState(prevState => ({...prevState, error: loadedPremiumAccessType}));
            }
        } else {
            this.setState((prevState) => ({...prevState,
                id: null,
                premiumAccessType: {
                    id: null,
                    active: true,
                    digital: true,
                    reselectable: false,
                    paymentRequired: false,
                    components: [],
                    compatiblePremiumAccessTypes: [],
                    createdBy: "system"
                },
                editMode: {
                    active: true,
                    expertMode: false,
                    autoSave: false,
                }}));
        }
    }

    //----------
    //Rendering
    //----------

    render() {

        const labelStyle = {
            fontSize: "20px",
            backgroundColor: "#C8C8C8",
            borderStyle: "solid",
            borderColor: "#cdd7e0",
            borderWidth: "1px",
            borderRadius: "5px",
            paddingLeft: "10px",
            paddingRight: "10px",
            width: "100%"
        };

        const phaseItemStyle = {
            fontSize: "18px",
            textAlign: "left"
        }

        return (
            <>
                <div className="details-title">
                    {this.state.premiumAccessType.id != null ? "Details of Premium Access Type " + this.state.premiumAccessType.id : "Create a Premium Access Type"}
                </div>
                <div className="details-button-box" style={{height: "70px"}}>
                    <Link to={{
                        pathname: "/" + GlobalConstants.APP_PATH + "purchases/premium-access-types",
                        state: this.state.flowState
                    }}>
                        <button className="form-btn-ci-light-blue" type="button">Back</button>
                    </Link>
                    {this.state.premiumAccessType.id != null &&
                    <button className={this.state.editMode.active ? "form-btn-ci-red" : "form-btn-ci-blue"}
                            type="button" onClick={() => this.setState({
                        editMode: {
                            active: !this.state.editMode.active,
                            expertMode: this.state.editMode.expertMode
                        },
                        premiumAccessType: this.state.originalPremiumAccessType
                    })}>{this.state.editMode.active ? "Cancel" : "Edit"}</button>
                    }
                    {this.state.editMode.active &&
                    <PromiseButton text="Save" onClick={() => this.saveOrUpdatePremiumAccessType()}/>
                    }
                    <PromiseButton text="Show available upgrades"
                                   disabled={this.state.premiumAccessType.id == null}
                                   className="form-btn-ci-light"
                                   onClick={() => this.loadAvailableUpgrades()}
                    />
                    <PromiseButton text="Show possible sources"
                                   disabled={this.state.premiumAccessType.id == null}
                                   className="form-btn-ci-light"
                                   onClick={() => this.loadPossibleSources()}
                    />
                    <Button style={{float: "right", marginTop: "5px", marginRight: "10px"}}
                            variant={this.state.editMode.autoSave ? "secondary" : "outline-secondary"}
                            onClick={() => this.setState(prevState => ({
                                ...prevState,
                                editMode: {
                                    ...prevState.editMode,
                                    autoSave: !this.state.editMode.autoSave
                                }
                            }))}>Auto Save</Button>
                </div>

                <div className="details-box-title">Resource Details</div>
                <div className="details-box">
                    {/* GENERAL DATA */}
                    <DetailsSection
                        nameInState="premiumAccessType"
                        fields={PremiumAccessTypeMetaData.PREMIUM_ACCESS_TYPE_DETAILS_GENERAL}
                        state={this.state}
                        onSetState={(s) => this.setState(s)}
                        onUpdateResource={() => this.saveOrUpdatePremiumAccessType()}
                        sectionId="general"
                    />

                    <DetailsSection
                        nameInState="premiumAccessType"
                        fields={[]}
                        state={this.state}
                        onSetState={(s) => this.setState(s)}
                        onUpdateResource={() => this.saveOrUpdatePremiumAccessType()}
                        sectionId="accessTypeDetails"
                        label={<span><IoPeopleCircleOutline/>&#xA0;Tariff Details</span>}
                    />

                    {this.state.sections.accessTypeDetails &&
                    <Form>
                        <Row>
                            <Form.Group as={Col}>
                                <Row>
                                    <Label style={labelStyle}>Active</Label>
                                    <FormCheck id="premiumAccessActiveSwitch" type="switch"
                                               readOnly={!this.state.editMode.active}
                                               disabled={!this.state.editMode.active}
                                               checked={this.state.premiumAccessType.active}
                                               onChange={() => this.setState(prevState => ({
                                                   ...prevState,
                                                   premiumAccessType: {
                                                       ...prevState.premiumAccessType,
                                                       active: !this.state.premiumAccessType.active
                                                   }
                                               }))}/>
                                </Row>
                                <br />
                                <Row>
                                    <Label style={labelStyle}>Payment Required</Label>
                                    <FormCheck id="premiumAccessPaymentRequiredSwitch" type="switch"
                                               readOnly={!this.state.editMode.active}
                                               disabled={!this.state.editMode.active}
                                               checked={this.state.premiumAccessType.paymentRequired}
                                               onChange={() => this.setState(prevState => ({
                                                   ...prevState,
                                                   premiumAccessType: {
                                                       ...prevState.premiumAccessType,
                                                       paymentRequired: !this.state.premiumAccessType.paymentRequired
                                                   }
                                               }))}/>
                                </Row>
                                <br />
                                <Row>
                                    <Label style={labelStyle}>Re-Selectable</Label>
                                    <FormCheck id="premiumAccessReselectableSwitch" type="switch"
                                               readOnly={!this.state.editMode.active}
                                               disabled={!this.state.editMode.active}
                                               checked={this.state.premiumAccessType.reselectable}
                                               onChange={() => this.setState(prevState => ({
                                                   ...prevState,
                                                   premiumAccessType: {
                                                       ...prevState.premiumAccessType,
                                                       reselectable: !this.state.premiumAccessType.reselectable
                                                   }
                                               }))}/>
                                </Row>
                                <br />
                                <Row>
                                    <Label style={labelStyle}>Is default</Label>
                                    <FormCheck id="premiumAccessDefaultSwitch" type="switch"
                                               readOnly={!this.state.editMode.active}
                                               disabled={!this.state.editMode.active}
                                               checked={this.state.premiumAccessType.default}
                                               onChange={() => this.setState(prevState => ({
                                                   ...prevState,
                                                   premiumAccessType: {
                                                       ...prevState.premiumAccessType,
                                                       default: !this.state.premiumAccessType.default
                                                   }
                                               }))}/>
                                </Row>
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Label style={labelStyle}>Can be upgraded to</Label>
                                <PromiseButton disabled={!this.state.editMode.active} text="Add"
                                               onClick={() => this.loadPremiumAccessTypes(0)
                                                   .then(r => this.setState(prevState => ({
                                                       ...prevState,
                                                       showTypeModal: true
                                                   })))}/>
                                {this.state.premiumAccessType.compatibleBillwerkOrderConfigs.length > 0 &&
                                    <Table>
                                        <thead>
                                            <tr>
                                                <th>ID</th>
                                                <th>Name</th>
                                                <th>Remove</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.premiumAccessType.compatibleBillwerkOrderConfigs.map(t => (
                                                <tr role="row" key={t}>
                                                    <td>{t}</td>
                                                    <td>{this.getCompatibleTypeName(t)}</td>
                                                    <td><button className={this.state.editMode.active ? "form-btn-ci-red" : "form-btn-ci-off"}
                                                                disabled={!this.state.editMode.active}
                                                                type="button"
                                                                onClick={() => this.toggleCompatibleType(t, null)}>{t}&#xA0;&#xA0;
                                                        <IoIosRemoveCircleOutline style={{fontSize: "18px"}}/></button></td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                }
                                {this.state.premiumAccessType.compatibleBillwerkOrderConfigs.length === 0 &&
                                    <div style={{textAlign: "center", fontSize: "24px"}}>---</div>
                                }
                            </Form.Group>
                        </Row>
                    </Form>
                    }

                    <DetailsSection
                        nameInState="premiumAccessType"
                        fields={[]}
                        state={this.state}
                        onSetState={(s) => this.setState(s)}
                        onUpdateResource={() => this.saveOrUpdatePremiumAccessType()}
                        sectionId="appearance"
                        label={<span><IoPeopleCircleOutline/>&#xA0;Appearance</span>}
                    />

                    {this.state.sections.appearance &&
                    <Form>
                        <Row>
                            <Form.Group as={Col}>
                                <Label style={labelStyle}>Name</Label>
                                <FormControl type="text" value={this.state.premiumAccessType.name}
                                             onChange={(e) => this.setState(prevState => ({
                                                 ...prevState,
                                                 premiumAccessType: {
                                                     ...prevState.premiumAccessType,
                                                     name: e.target.value
                                                 }
                                             }))}/>
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Label style={labelStyle}>Cover URL</Label>
                                <FormControl type="text" value={this.state.premiumAccessType.coverUrl}
                                             onChange={(e) => this.setState(prevState => ({
                                                 ...prevState,
                                                 premiumAccessType: {
                                                     ...prevState.premiumAccessType,
                                                     coverUrl: e.target.value
                                                 }
                                             }))}/>
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Label style={labelStyle}>Display Price</Label>
                                <FormControl type="text" value={this.state.premiumAccessType.displayPrice}
                                             onChange={(e) => this.setState(prevState => ({
                                                 ...prevState,
                                                 premiumAccessType: {
                                                     ...prevState.premiumAccessType,
                                                     displayPrice: e.target.value
                                                 }
                                             }))}/>
                            </Form.Group>
                        </Row>
                        <Row>
                            <Form.Group as={Col}>
                                <Label style={labelStyle}>Overview Description</Label>
                                <FormControl as="textarea" rows={5} readOnly={!this.state.editMode.active}
                                             disabled={!this.state.editMode.active}
                                             value={this.state.premiumAccessType.description}
                                             onChange={(e) => this.setState(prevState => ({
                                                 ...prevState,
                                                 premiumAccessType: {
                                                     ...prevState.premiumAccessType,
                                                     description: e.target.value
                                                 }
                                             }))}/>
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Label style={labelStyle}>Cart Description</Label>
                                <FormControl as="textarea" rows={5} readOnly={!this.state.editMode.active}
                                             disabled={!this.state.editMode.active}
                                             value={this.state.premiumAccessType.cartDescription}
                                             onChange={(e) => this.setState(prevState => ({
                                                 ...prevState,
                                                 premiumAccessType: {
                                                     ...prevState.premiumAccessType,
                                                     cartDescription: e.target.value
                                                 }
                                             }))}/>
                            </Form.Group>
                        </Row>
                    </Form>
                    }

                    <DetailsSection
                        nameInState="premiumAccessType"
                        fields={[]}
                        state={this.state}
                        onSetState={(s) => this.setState(s)}
                        onUpdateResource={() => this.saveOrUpdatePremiumAccessType()}
                        sectionId="accessTypePhases"
                        label={<span><IoPeopleCircleOutline/>&#xA0;Phases</span>}
                    />

                    {this.state.sections.accessTypePhases &&
                    <>
                        <PromiseButton text="Add" disabled={this.state.premiumAccessType.id == null}
                                       onClick={() => this.loadBillwerkProducts(0).then(r => this.setState(prevState => ({
                                           ...prevState,
                                           showAddPhaseDialog: true
                                       })))}/>
                        <br/>
                        <br/>
                        <br/>
                        <DragDropContext onDragEnd={this.onDragEnd}>
                            <Droppable droppableId="droppable">
                                {(provided) => (
                                    <div
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                    >
                                        {this.state.premiumAccessType.components.map((item, index) => (
                                            <Draggable
                                                draggableId={"item-" + index}
                                                index={index}
                                                isDragDisabled={false}
                                            >
                                                {(providedItem) => (
                                                    <div
                                                        ref={providedItem.innerRef}
                                                        {...providedItem.draggableProps}
                                                        {...providedItem.dragHandleProps}
                                                        className="draggable-item"
                                                    >
                                                        <div
                                                            className="d-flex align-items-center draggable-item-name ml-2">
                                                            <div style={{...phaseItemStyle, width: "15%"}}>
                                                                <BsCart4/> Cart
                                                                pos.: <strong>{item.cartPosition}</strong></div>
                                                            <div style={{
                                                                ...phaseItemStyle,
                                                                width: "10%"
                                                            }}>ID: <strong>{item.id}</strong></div>
                                                            <div style={{...phaseItemStyle, width: "12%"}}>Billwerk
                                                                Product:
                                                            </div>
                                                            <div style={{
                                                                ...phaseItemStyle,
                                                                width: "20%"
                                                            }}>{this.printProduct(item)}</div>
                                                            <div style={{
                                                                ...phaseItemStyle,
                                                                width: "25%"
                                                            }}>
                                                                <Dropdown as={ButtonGroup}
                                                                          style={{marginLeft: "50px", float: "left"}}
                                                                          drop="down">
                                                                    <Button style={{
                                                                        marginBottom: "10px",
                                                                        height: "40px"
                                                                    }}
                                                                            variant={item.paymentRequired ? "danger" : "success"}
                                                                            onClick={() => this.togglePhasePaymentRequiredFlag(item)}><LoadingIndicator text={item.paymentRequired ? "Payment required" : "No payment required"} /></Button>
                                                                    <Dropdown.Toggle style={{marginBottom: "10px"}}
                                                                                     split
                                                                                     variant={item.paymentRequired ? "outline-danger" : "outline-success"}/>
                                                                    <Dropdown.Menu>
                                                                        <Dropdown.Header>Change to</Dropdown.Header>
                                                                        <Dropdown.Item
                                                                            onClick={() => this.togglePhasePaymentRequiredFlag(item)}>{item.paymentRequired ? "No payment required" : "Payment required"}</Dropdown.Item>
                                                                    </Dropdown.Menu>
                                                                </Dropdown>
                                                            </div>
                                                            <div>
                                                                <button className="form-btn-ci-red"
                                                                        style={{float: "right"}} type="button"
                                                                        onClick={() => this.deletePhase(item)}>
                                                                    <BsTrash/>
                                                                </button>

                                                                {item.mappedBillwerkProduct != null &&
                                                                <a style={{color: "#333"}}
                                                                   href={GlobalConstants.BILLWERK_BASE_URL + "#/planvariants/" + item.mappedBillwerkProduct.planVariantId + "/edit"}
                                                                   target={"_blank"} rel={"noreferrer"}>
                                                                    <button className="form-btn-ci-light-blue"
                                                                            type="button"><AiOutlineEye/></button>
                                                                </a>
                                                                }

                                                            </div>
                                                        </div>
                                                    </div>
                                                )}

                                            </Draggable>
                                        ))}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                    </>
                    }

                </div>
                <div className="details-button-box" style={{height: "70px"}}>
                    <Link to={{
                        pathname: "/" + GlobalConstants.APP_PATH + "purchases/premium-access-types",
                        state: this.state.flowState
                    }}>
                        <button className="form-btn-ci-light-blue" type="button">Back</button>
                    </Link>
                    {this.state.editMode.active &&
                    <PromiseButton text="Save" onClick={() => this.saveOrUpdatePremiumAccessType()}/>
                    }
                </div>

                <InfoModal show={this.state.showAddPhaseDialog}
                           title={"Select a product in order to add it to premium access type " + this.state.premiumAccessType.id}
                           onHide={() => this.setState(prevState => ({...prevState, showAddPhaseDialog: false}))}
                           body={
                               <>
                                   <Dropdown as={ButtonGroup} drop="up">
                                       <Button style={{
                                           paddingTop: "8px",
                                           paddingBottom: "10px",
                                           marginBottom: "10px",
                                           height: "40px"
                                       }}
                                               variant={this.state.productType === null ? "secondary" : "success"}
                                               onClick={() => this.loadBillwerkProducts(0)}>Type: {this.state.productType != null ? this.state.productType : "All"}</Button>
                                       <Dropdown.Toggle style={{marginBottom: "10px"}} split
                                                        variant={this.state.productType === null ? "outline-secondary" : "outline-success"}/>
                                       <Dropdown.Menu>
                                           <Dropdown.Item
                                               onClick={() => this.setState({productType: null})}>All</Dropdown.Item>
                                           <Dropdown.Divider/>
                                           <Dropdown.Item
                                               onClick={() => this.setState({productType: 'TIGERTICKET'})}>tigerticket</Dropdown.Item>
                                           <Dropdown.Item
                                               onClick={() => this.setState({productType: 'DIGITICKET'})}>digiticket</Dropdown.Item>
                                           <Dropdown.Item
                                               onClick={() => this.setState({productType: 'SUBSCRIPTION'})}>Subscription</Dropdown.Item>
                                           <Dropdown.Item
                                               onClick={() => this.setState({productType: 'COUPON'})}>Coupon</Dropdown.Item>
                                       </Dropdown.Menu>
                                   </Dropdown>
                                   <Table responsive bordered hover striped>
                                       <thead>
                                       <tr>
                                           <th>ID</th>
                                           <th>Type</th>
                                           <th>Name</th>
                                           <th>Plan ID</th>
                                           <th>Plan Variant ID</th>
                                           <th>Duration</th>
                                           <th>Price</th>
                                       </tr>
                                       </thead>
                                       <tbody>
                                       {this.state.billwerkProducts.map(p => (
                                           <tr role="row" style={{cursor: "pointer"}} key={p.id}
                                               onClick={() => this.addPhase(p)}>
                                               <td>{p.id}</td>
                                               <td><Badge
                                                       bg={p.type === 'TIGERTICKET' ? "danger" : (p.type === 'DIGITICKET' ? "primary" : (p.type === 'COUPON' ? "warning" : "success"))}>{p.type}</Badge>
                                               </td>
                                               <td>{p.billwerkName}</td>
                                               <td>{p.planId}</td>
                                               <td>{p.planVariantId}</td>
                                               <td>{p.premiumAccessDuration}</td>
                                               <td>{p.price}</td>
                                           </tr>
                                       ))}
                                       </tbody>
                                   </Table>
                                   <Pagination>
                                       <Pagination.Item hidden={this.state.productPage === 0}
                                                        onClick={() => this.loadBillwerkProducts(this.state.productPage - 1)}>&lt;</Pagination.Item>
                                       <Pagination.Item active={true}>{this.state.productPage + 1}</Pagination.Item>
                                       <Pagination.Item hidden={this.state.productPage === this.state.lastProductPage}
                                                        onClick={() => this.loadBillwerkProducts(this.state.productPage + 1)}>&gt;</Pagination.Item>
                                   </Pagination>
                               </>
                           }
                />
                <InfoModal show={this.state.showAvailableUpgrades}
                           title={"Available upgrades"}
                           onHide={() => this.setState(prevState => ({...prevState, showAvailableUpgrades: false}))}
                           body={
                               <>
                                   <Table>
                                       <thead>
                                            <tr>
                                                <th>ID</th>
                                                <th>Name</th>
                                                <th>Payment Required</th>
                                                <th>Phases</th>
                                                <th>Maintain</th>
                                            </tr>
                                       </thead>
                                       <tbody>
                                       {this.state.availableUpgrades.map(u => (
                                           <tr role="row" key={u.id}>
                                               <td>{u.id}</td>
                                               <td>{u.name}</td>
                                               <td><Badge bg={u.paymentRequired ? "success": "danger"}>{u.paymentRequired ? "Y" : "N"}</Badge></td>
                                               <td>
                                                   {u.components.map(p => (
                                                       <div>
                                                           <a style={{color: "#333"}} href={"/" + GlobalConstants.APP_PATH + "purchases/products/" + p.mappedBillwerkProduct.id} target="_blank" rel={"noreferrer"}>
                                                               #{p.cartPosition} - {(p.mappedBillwerkProduct.id + " (" + p.mappedBillwerkProduct.billwerkName + ") - " + p.mappedBillwerkProduct.premiumAccessDuration)}
                                                           </a>
                                                       </div>
                                                   ))}
                                               </td>
                                               <td>
                                                   <a href={"/" + GlobalConstants.APP_PATH + "purchases/premium-access-types/" + u.id}
                                                      target={"_blank"} rel={"noreferrer"}>
                                                       <button className="form-btn-ci-light-blue" type="button">
                                                           <AiOutlineEye/></button>
                                                   </a>
                                                   <button className="form-btn-ci-red" onClick={() => this.toggleCompatibleType(u, null)} type="button"><BsTrash /></button>
                                               </td>
                                           </tr>
                                       ))}
                                       </tbody>
                                   </Table>
                                </>
                           }
                           />
                <InfoModal show={this.state.showAvailableSources}
                           title={"This premium access type can be upgraded from"}
                           onHide={() => this.setState(prevState => ({...prevState, showAvailableSources: false}))}
                           body={
                               <>
                                   <Table>
                                       <thead>
                                       <tr>
                                           <th>ID</th>
                                           <th>Active</th>
                                           <th>Name</th>
                                           <th>Payment Required</th>
                                           <th>Phases</th>
                                           <th>Maintain</th>
                                       </tr>
                                       </thead>
                                       <tbody>
                                       {this.state.availableSources.map(u => (
                                           <tr role="row" key={u.id}>
                                               <td>{u.id}</td>
                                               <td><Badge bg={u.active ? "success" : "danger"}>{u.active ? "Y" : "N"}</Badge> </td>
                                               <td>{u.name}</td>
                                               <td><Badge bg={u.paymentRequired ? "success": "danger"}>{u.paymentRequired ? "Y" : "N"}</Badge></td>
                                               <td>
                                                   {u.components.map(p => (
                                                       <div>
                                                           <a style={{color: "#333"}} href={"/" + GlobalConstants.APP_PATH + "purchases/products/" + p.mappedBillwerkProduct.id} target="_blank" rel={"noreferrer"}>
                                                               #{p.cartPosition} - {(p.mappedBillwerkProduct.id + " (" + p.mappedBillwerkProduct.billwerkName + ") - " + p.mappedBillwerkProduct.premiumAccessDuration)}
                                                           </a>
                                                       </div>
                                                   ))}
                                               </td>
                                               <td>
                                                   <a href={"/" + GlobalConstants.APP_PATH + "purchases/premium-access-types/" + u.id}
                                                      target={"_blank"} rel={"noreferrer"}>
                                                       <button className="form-btn-ci-light-blue" type="button">
                                                           <AiOutlineEye/></button>
                                                   </a>
                                               </td>
                                           </tr>
                                       ))}
                                       </tbody>
                                   </Table>
                               </>
                           }
                />
                <InfoModal show={this.state.showTypeModal}
                           title={"Select a premium access type"}
                           onHide={() => this.setState(prevState => ({...prevState, showTypeModal: false}))}
                           body={
                               <>
                                   <Table responsive bordered hover striped>
                                       <thead>
                                       <tr>
                                           <th>ID</th>
                                           <th>Active</th>
                                           <th>Name</th>
                                           <th>Phases</th>
                                           <th>Selected</th>
                                       </tr>
                                       </thead>
                                       <tbody>
                                       {this.state.types.map(t => (
                                           <tr role="row" key={t.id} style={{cursor: "pointer"}}
                                               onClick={() => this.toggleCompatibleType(t.id, t)}>
                                               <td>{t.id}</td>
                                               <td><Badge
                                                       bg={t.active ? "success" : "danger"}>{t.active ? "Y" : "N"}</Badge>
                                               </td>
                                               <td>{t.name}</td>
                                               <td>{t.components.length}</td>
                                               <td>
                                                   <input type="checkbox"
                                                          checked={this.state.premiumAccessType.compatibleBillwerkOrderConfigs.indexOf(t.id) > -1}
                                                          value={this.state.premiumAccessType.compatibleBillwerkOrderConfigs.indexOf(t.id) > -1}/>
                                               </td>
                                           </tr>
                                       ))}
                                       </tbody>
                                   </Table>
                                   <Pagination>
                                       <Pagination.Item hidden={this.state.typePage === 0}
                                                        onClick={() => this.loadPremiumAccessTypes(this.state.typePage - 1)}>&lt;</Pagination.Item>
                                       <Pagination.Item active={true}>{this.state.typePage + 1}</Pagination.Item>
                                       <Pagination.Item hidden={this.state.typePage === this.state.lastTypePage}
                                                        onClick={() => this.loadPremiumAccessTypes(this.state.typePage + 1)}>&gt;</Pagination.Item>
                                   </Pagination>
                               </>
                           }/>

                <ErrorHandler error={this.state.error}
                              onHide={() => this.setState(prevState => ({...prevState, error: null}))}/>
            </>
        );
    }

    //--------
    //HELPERS
    //--------
    getCompatibleTypeName(id) {
        if (this.state.compatibleTypes == null) {
            return "---";
        }
        const type = this.state.compatibleTypes.filter(t => t.id === id);
        if (type != null && type.length > 0) {
            return type[0].name;
        } else {
            return "---";
        }
    }
    printProduct(phase) {
        if (phase.mappedBillwerkProduct == null) {
            return (<div>---</div>);
        } else {
            const product = phase.mappedBillwerkProduct;
            return (
                <>
                    <div>
                        <IoTextOutline/>&#xA0;{product.billwerkName}
                    </div>
                    <div>
                        <IoTicketOutline/>&#xA0;{product.type.toLowerCase()}
                    </div>
                    <div>
                        <GiDuration/>&#xA0;{product.premiumAccessDuration}
                    </div>
                    <div>
                        <AiOutlineEuro/>&#xA0;{product.price} Eur.
                    </div>
                </>
            )
        }
    }
    async onDragEnd(result) {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        const sourceIndex = result.source.index;
        const destinationIndex = result.destination.index;

        if (sourceIndex === destinationIndex) {
            return;
        }
        let phases = this.state.premiumAccessType.components;

        let ordered = this.reorder(phases, sourceIndex, destinationIndex);
        let orderUpdateRequest = []
        //Reset the cart positions
        for (let i = 0; i < ordered.length; i++) {
            ordered[i].cartPosition = i+1;
            orderUpdateRequest.push({phaseId: ordered[i].id, cartPosition: i+1, paymentRequired: ordered[i].paymentRequired})
        }

        UpdatePhaseOrder(this.state.premiumAccessType.id, orderUpdateRequest).then(r => {
            if (!r.error) {
               this.setState(prevState => ({
                   ...prevState,
                   premiumAccessType: {
                       ...prevState.premiumAccessType,
                       components: ordered
                   }
               }));
            }
        });
    }

    reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    toggleCompatibleType(id, type) {
        let existingTypes = this.state.premiumAccessType.compatibleBillwerkOrderConfigs;
        let compatibleTypes = this.state.compatibleTypes;
        const index = existingTypes.indexOf(id);
        if (index > -1) {
            existingTypes.splice(index, 1);
        } else {
            existingTypes.push(id);
            if (type != null) {
                compatibleTypes.push(type);
            }
        }
        this.setState(prevState => ({
            ...prevState,
            premiumAccessType: {
                ...prevState.premiumAccessType,
                compatibleBillwerkOrderConfigs: existingTypes,
            },
            compatibleTypes: compatibleTypes
        }));
    }

    //------------
    //API Methods
    //------------
    async loadAvailableUpgrades() {
        if (this.state.premiumAccessType.id != null) {
            let response = await GetAvailableTarifUpgrades(this.state.premiumAccessType.id);
            if (!response.error) {
                this.setState(prevState => ({
                    ...prevState,
                    availableUpgrades: response.result,
                    showAvailableUpgrades: true
                }));
            }
        }
    }

    async loadPossibleSources() {
        if (this.state.premiumAccessType.id != null) {
            let response = await GetPossibleTariffSources(this.state.premiumAccessType.id);
            if (!response.error) {
                this.setState(prevState => ({
                    ...prevState,
                    availableSources: response.result,
                    showAvailableSources: true
                }));
            }
        }
    }

    async togglePhasePaymentRequiredFlag(phase) {
        let response = await TogglePaymentRequiredForPhase(this.state.premiumAccessType.id, phase.id, !phase.paymentRequired);
        if (!response.error) {
            await this.loadPremiumAccessType();
        }
    }

    async addPhase(product) {
        if (product != null && this.state.premiumAccessType.id != null) {
            let response = await AddPremiumTypePhase(this.state.premiumAccessType.id, product.id);
            if (!response.error) {
                this.setState(prevState => ({
                    ...prevState,
                    showAddPhaseDialog: false
                }), () => this.loadPremiumAccessType())
            }
        }
    }

    async deletePhase(phase) {
        if (phase != null && phase.id != null && phase.premiumAccessTypeId != null) {
            let response = await DeletePremiumTypePhase(phase);
            if (!response.error) {
                await this.loadPremiumAccessType();
            }
        }
    }
    async saveOrUpdatePremiumAccessType() {
        let premiumAccessType = {};
        if(this.state.premiumAccessType.id != null) {
            premiumAccessType = await UpdatePremiumAccessType({...this.state.premiumAccessType, isDefault: this.state.premiumAccessType.default, isPaymentRequired: this.state.paymentRequired});
        } else {
            premiumAccessType = await CreatePremiumAccessType({...this.state.premiumAccessType, isDefault: this.state.premiumAccessType.default});
            if (!premiumAccessType.error) {
                this.props.history.push("/" + GlobalConstants.APP_PATH + "purchases/premium-access-types/" + premiumAccessType.result.id);
            }
        }

        if (!premiumAccessType.error) {
            this.setState((prevState) =>({...prevState, premiumAccessType: premiumAccessType.result, originalPremiumAccessType: premiumAccessType.result,
                editMode: {...prevState.editMode, active: this.state.editMode.autoSave}}));

            this.props.addToast("The premium access type has been updated successfully.", {
                autoDismiss: true,
                appearance: 'success'
            });
        } else {
            this.setState(prevState => ({...prevState, error: premiumAccessType}));
        }
    }

    async loadPremiumAccessTypes(page) {
        let response = await GetPremiumAccessTypePage({page: page});
        if (!response.error) {
            this.setState(prevState => ({
                ...prevState,
                types: response.result,
                typePage: page,
                lastTypePage: Math.ceil(response.length / 20)
            }));
        } else {
            this.setState(prevState => ({...prevState, error: response}));
        }
    }

    async loadBillwerkProducts(page) {
        let response = {};
        if(this.state.productType == null) {
            response = await GetBillwerkProductPage(page);
        } else {
            response = await GetBillwerkProductsByType(this.state.productType,page);
        }
        if(!response.error) {
            this.setState(prevState => ({
                ...prevState,
                billwerkProducts: response.result,
                productPage: page,
                lastProductPage: Math.ceil(response.length / 20)}));
        } else {
            this.setState(prevState => ({...prevState, error: response}));
        }
    }
        
}


export default withToast(PremiumAccessTypeDetails);