import GlobalConstants from "../config/GlobalConstants";
import React, {forwardRef} from "react";
import {Badge, Button} from "react-bootstrap";

//LAYOUT
export function createBooleanBadge(value) {
    return(
        <Badge bg={value ? "success" : "danger"}>{value ? "Y" : "N"}</Badge>
    );
}

const years = [...Array(100).keys()].map(i => i + 1970);
const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
];

//BROWSER HANDLING
export const openDetailsInNewTab = (relUrl) => {
    const newWindow = window.open(GlobalConstants.SPINE_CLIENT_HOST + GlobalConstants.APP_PATH + relUrl, '_blank');
    if (newWindow) newWindow.opener = null;
}

export const openExternalTargetInNewTab = (externalUrl) => {
    const newWindow = window.open(externalUrl, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
}

//ASSETS

export function generateAssetDownloadUrl(asset) {
    let generatedUrl = null;
    let visibility = null;

    //Visibility (required for the amazon bucket)
    if (asset.visibility === "PUBLIC") {
        visibility = ".public/";
    } else if (asset.visibility === "PROTECTED") {
        visibility = ".protected/";
    }

    if (asset.storage === "AMAZON_S3") {
        generatedUrl = GlobalConstants.AMAZONBUCKET_URL + GlobalConstants.ENVIRONMENT + visibility + asset.storageReferenceId;
    } else if (asset.storage === "GOOGLE_CLOUD_STORAGE") {
        //Default prefix of the google storage reference id
        let urlPrefix = "gs://";
        //Check if the current id has the default prefix
        if (!asset.storageReferenceId.includes(urlPrefix)) {
            //No -> Get it
            urlPrefix = asset.storageReferenceId.substring(0, asset.storageReferenceId.indexOf(GlobalConstants.GOOGLESTORAGE_URL));
        }
        //Now replace the prefix by https://
        generatedUrl = asset.storageReferenceId.replace(urlPrefix, "https://");
    }

    return generatedUrl;
}

//DATEPICKER
export function transformToZonedDateTime(dateFromPicker) {
    return transformToLocalDate(dateFromPicker) + "T00:00:00.000Z";
}

export function transformToLocalDate(dateFromPicker) {
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]

    //Remove the name of the weekday (first 4 chars)
    dateFromPicker = dateFromPicker.substring(4);

    //Get the day, month and year
    let day = dateFromPicker.substring(4,6);
    let month = months.indexOf(dateFromPicker.substring(0,3)) + 1;
    let year = dateFromPicker.substring(7,11);

    //Make sure that the month has to chars, i.e. "2" -> "02"
    if(month < 10) {
        month = "0" + month;
    }
    return year + "-" + month + "-" + day;
}

export function transformDateToReadableDate(dateFromPicker) {

    if(dateFromPicker == null || dateFromPicker === "") {
        return "";
    }

    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]

    //Remove the name of the weekday (first 4 chars)
    dateFromPicker = dateFromPicker.substring(4);

    //Get the day, month and year
    let day = dateFromPicker.substring(4,6);
    let month = months.indexOf(dateFromPicker.substring(0,3)) + 1;
    let year = dateFromPicker.substring(7,11);

    //Make sure that the month has to chars, i.e. "2" -> "02"
    if(month < 10) {
        month = "0" + month;
    }
    return day + "." + month + "." + year;
}

export function transformStringToReadableDate(dateFromResource, withTime = false) {

    if(dateFromResource != null && dateFromResource !== "") {
        //Get the year, month and day as number
        const year = dateFromResource.substring(0, 4);
        let month = dateFromResource.substring(5, 7);
        let day = dateFromResource.substring(8, 10);

        let hour = "";
        let minute = "";
        let second = "";
        if(withTime && dateFromResource.length > 10) {
            hour = dateFromResource.substring(11, 13);
            minute = dateFromResource.substring(14,16);
            second = dateFromResource.substring(17,19);
        }

        //Now return the date in the format "XXX Jul 27 2021 00:00:00 GMT+0200 (CEST)"
        if(withTime) {
            return day + "." + month + "." + year + " " + hour + ":" + minute + ":" + second;
        } else {
            return day + "." + month + "." + year;
        }
    } else {
        return "";
    }
}

export function transformReadableDateToDate(readableDate) {
    if(readableDate !== "") {
        let day = readableDate.substring(0,2);
        let month = readableDate.substring(3,5);
        let year = readableDate.substring(6, readableDate.length);

        return new Date(year + "-" + month + "-" + day);
    } else {
        return new Date("9999-12-31");
    }
}

export function transformToDatepickerFormat(dateFromResource) {

    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]

    if(dateFromResource != null) {
        //Get the year, month and day as number
        const year = dateFromResource.substring(0, 4);
        let month = dateFromResource.substring(5, 7);
        let day = dateFromResource.substring(8, 10);

        //Remove the leading "0" in month and day (is there is any)
        if (month[0] === "0") {
            month = month.substring(1);
        }
        if (day[0] === "0") {
            day = day.substring(1);
        }

        //Now return the date in the format "XXX Jul 27 2021 00:00:00 GMT+0200 (CEST)"
        return Date.parse(months[month - 1] + " " + day + " " + year + " 00:00:00 GMT");
    } else {
        return "";
    }
}

export function transformStringToCamelCase(value) {
    let result = "";
    if(value.length > 0) {
        result = result + value[0].toUpperCase();
    }
    if(value.length > 1) {
        result = result + value.substring(1, value.length).toLowerCase();
    }
    return result;
}

export const DatePickerButton = forwardRef(({ value, onClick }, ref) => (
    <Button variant="outline-secondary" onClick={onClick} ref={ref}>
        Pick
    </Button>
));

export function getYear(dateTime) {
    if(dateTime !== "") {
        return parseInt(dateTime.substring(0,4));
    } else {
        return 0;
    }
}

export function getMonth(dateTime) {
    if(dateTime !== "") {
        return parseInt(dateTime.substring(5,7));
    } else {
        return 0;
    }
}

export function getDay(dateTime) {
    if(dateTime !== "") {
        return parseInt(dateTime.substring(8,10));
    } else {
        return 0;
    }
}

export function customDatePickerHeader(date, changeYear, changeMonth, decreaseMonth, increaseMonth, prevMonthButtonDisabled, nextMonthButtonDisabled) {
    return (
        <div
            style={{
                margin: 10,
                display: "flex",
                justifyContent: "center",
            }}
        >
            <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled} type="button">
                {"<"}
            </button>
            <select
                style={{fontSize: "18px", marginTop: "4px"}}
                value={date.getFullYear()}
                onChange={({ target: { value } }) => changeYear(value)}
            >
                {years.map((option) => (
                    <option key={option} value={option}>
                        {option}
                    </option>
                ))}
            </select>

            <select
                style={{fontSize: "18px", marginTop: "4px"}}
                value={months[date.getMonth()]}
                onChange={({ target: { value } }) =>
                    changeMonth(months.indexOf(value))
                }
            >
                {months.map((option) => (
                    <option key={option} value={option}>
                        {option}
                    </option>
                ))}
            </select>

            <button onClick={increaseMonth} disabled={nextMonthButtonDisabled} type="button">
                {">"}
            </button>
        </div>
    )
}

export function PeriodToReadableDuration(period) {
    if (period == null || period === "") {
        return null;
    }

    let plural = false;
    let unit = period[period.length - 1];
    let duration = period.length === 4 ? period.substring(1,3) : period[1];

    if (Number(duration) > 1) {
        plural = true;
    }

    if (unit === 'D') {
        unit = plural ? "Days" : "Day";
    } else if (unit === 'W') {
        unit = plural ? "Weeks" : "Week";
    } else if (unit === 'M') {
        unit = plural ? "Months" : "Month";
    } else {
        unit = plural ? "Years" : "Year";
    }

    return duration + " " + unit;
}

//Validation
export function isNumeric(str) {
    if (typeof str != "string") return false // we only process strings!
    return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
        !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
}
