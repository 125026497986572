import {
    DeleteRestResource,
    GetFilteredRestResource,
    GetRestResource,
    CreateRestResource,
    UpdateRestResource
} from "../../clients/RestResourceClient";

export async function GetFilteredRoutines(filter){
    return await GetFilteredRestResource('api/routines', filter);

}

export async function CreateRoutine( routine) {
    return await CreateRestResource('api/routines',  routine);
}

export async function GetRoutine(id) {
    return await GetRestResource('api/routines/' + id);
}

export async function GetResourceByUrl(url) {
    return await GetRestResource(url, true);
}

export async function UpdateRoutine( routine) {
    return await UpdateRestResource('api/routines/' +  routine.id,  routine);
}

export async function DeleteRoutine( routine) {
    return await DeleteRestResource('api/routines/' +  routine.id);
}

export async function CreateRoutineCollection(routineId, item) {
    return await CreateRestResource('api/routines/' +  routineId + "/routineCollections", item);
}

export async function GetRoutineCollection( routineId, itemId) {
    return await GetRestResource('api/routines/' +  routineId + "/routineCollections/" + itemId);
}

export async function UpdateRoutineCollection(routineId, item) {
    return await UpdateRestResource('api/routines/' + routineId + '/routineCollections/' + item.id, item);
}

export async function DeleteRoutineCollection(routineId, item) {
    return await DeleteRestResource('api/routines/' + routineId + '/routineCollections/'  + item.id);
}

export async function CreateRoutineTag(routine, tag) {
    return await CreateRestResource('api/routines/' +  routine.id + "/tags", tag);
}

export async function DeleteRoutineTag(routine, tag) {
    return await DeleteRestResource('api/routines/' +  routine.id + "/tags?tagId=" + tag.id);
}
