import {Button, Col, Form} from "react-bootstrap";
import Row from 'react-bootstrap/Row';
import React, {Component} from "react";
import {withToast} from "../../util/ToastService";
import {Link} from "react-router-dom";
import {AiOutlineEye, MdSdStorage,} from "react-icons/all";
import GlobalConstants from "../../config/GlobalConstants";
import {CreateAsset, GetAsset, UpdateAsset} from "./AssetService";
import {DetailsSection, RequiredFieldsAreValid} from "../../generators/DetailsGenerator";
import AssetMetaData from "./AssetMetaData";
import {generateAssetDownloadUrl, openExternalTargetInNewTab} from "../../util/ResourceService";
import {PromiseButton} from "../../global/SpinningTiger";
import {ErrorHandler} from "../../util/ErrorHandler";

class AssetDetails extends Component {

    //------------
    //Constructor
    //------------

    constructor(props) {
        super(props);

        this.state = {
            error: null,
            flowState: props.location.state,
            id: this.props.match.params.id,
            editMode: {
                active: false,
                expertMode: false,
                autoSave: true,
            },
            asset: {},
            originalAsset: {},
            sections: {
                general: true,
                storage: true,
                appearance: true,
            },
            validationErrors: []
        }
    }

    //---------
    //Mounting
    //---------

    async componentDidMount() {
        //Set the title
        if(this.state.id != null) {
            document.title = "Asset " + this.state.id + " :: Tiger UI";
        } else {
            document.title = "New asset :: Tiger UI";
        }

        await this.loadAsset();
    }

    //--------
    //Loading
    //--------

    async loadAsset() {
        let loadedAsset = {};

        if (this.state.id != null && this.state.id !== "add") {

            loadedAsset = await GetAsset(this.state.id);

            if (!loadedAsset.error) {
                const asset = loadedAsset.result;

                this.setState({
                    id: this.state.id,
                    editMode: this.state.editMode,
                    asset: asset,
                    originalAsset: asset,
                });
            } else {
                this.setState(prevState => ({...prevState, error: loadedAsset}));
            }
        } else {
            this.setState((prevState) => ({
                ...prevState,
                id: null,
                asset: {
                    id: null,
                    assetType: "IMAGE",
                    visibility: "PUBLIC",
                    storage: "GOOGLE_CLOUD_STORAGE"
                },
                editMode: {
                    active: true,
                    expertMode: false,
                    autoSave: false,
                }
            }));
        }
    }

    //----------
    //Rendering
    //----------

    render() {

        let link = null;
        if (this.state.asset.id != null) {
            link = generateAssetDownloadUrl(this.state.asset)
        }


        return (
            <>
                <div className="details-title">
                    {this.state.asset.id != null ? "Details of Asset " + this.state.asset.id : "Create a new Asset"}
                </div>
                <div className="details-button-box" style={{height: "70px"}}>
                    <Link to={{pathname: "/" + GlobalConstants.APP_PATH + "assets", state: this.state.flowState}}>
                        <button className="form-btn-ci-light-blue" type="button">Back</button>
                    </Link>
                    {this.state.editMode.active &&
                        <PromiseButton text="Save" onClick={() => this.saveOrUpdateAsset()} />
                    }
                    {this.state.asset.id != null &&
                    <div>
                        <Button variant="outline-secondary"
                                onClick={() => openExternalTargetInNewTab(link)}>Preview</Button>
                        &#xA0;
                        &#xA0;
                        <Button variant="outline-secondary"
                                onClick={() => {
                                    navigator.clipboard.writeText(link).then(r => {
                                        this.props.addToast("URL copied to clipboard", {
                                            autoDismiss: true,
                                            appearance: 'info'
                                        });
                                    });
                                }}>Copy URL</Button>
                    </div>
                    }
                </div>

                <div className="details-box-title">Resource Details</div>
                <div className="details-box">
                    {/* GENERAL DATA */}
                    <DetailsSection
                        nameInState="asset"
                        fields={AssetMetaData.DETAILS_GENERAL}
                        state={this.state}
                        onSetState={(s) => this.setState(s)}
                        onUpdateResource={() => this.saveOrUpdateAsset()}
                        sectionId="general"
                    />

                    {/* STORAGE */}
                    <DetailsSection
                        nameInState="asset"
                        fields={AssetMetaData.DETAILS_STORAGE}
                        state={this.state}
                        onSetState={(s) => this.setState(s)}
                        sectionId="storage"
                        onUpdateResource={() => this.saveOrUpdateAsset()}
                        label={<span><MdSdStorage/>&#xA0;Storage Details</span>}
                    />

                    {/* APPEARANCE */}
                    <DetailsSection
                        nameInState="asset"
                        fields={AssetMetaData.DETAILS_APPEARANCE}
                        state={this.state}
                        onSetState={(s) => this.setState(s)}
                        sectionId="appearance"
                        onUpdateResource={() => this.saveOrUpdateAsset()}
                        label={<span><AiOutlineEye/>&#xA0;Appearance</span>}
                    />

                    {this.state.asset.id != null && this.state.asset.id !== "add" && this.state.asset.assetType === 'IMAGE' &&
                    <>
                        <hr/>
                        <h5>Preview:</h5>
                        <img src={generateAssetDownloadUrl(this.state.asset)}
                             style={{cursor: "pointer"}}
                             alt={"Preview of asset " + this.state.asset.id} width={400} height={400}
                             onClick={() => openExternalTargetInNewTab(generateAssetDownloadUrl(this.state.asset))}/>
                    </>
                    }

                    {this.state.asset.id == null &&
                    <>
                        <hr/>
                        <h5>Upload:</h5>
                        <Form>
                            <Row>
                                <Form.Group as={Col} controlId="upload">
                                    <Form.Control type="file" onChange={(e) => this.setState(prevState => ({
                                        ...prevState,
                                        asset: {...prevState.asset, file: e.target.files[0]}
                                    }))}/>
                                </Form.Group>
                            </Row>
                        </Form>
                    </>
                    }

                </div>
                <div className="details-button-box" style={{height: "70px"}}>
                    <Link to={{pathname: "/" + GlobalConstants.APP_PATH + "assets", state: this.state.flowState}}>
                        <button className="form-btn-ci-light-blue" type="button">Back</button>
                    </Link>
                    {this.state.editMode.active &&
                        <PromiseButton text="Save" onClick={() => this.saveOrUpdateAsset()} />
                    }
                </div>

                <ErrorHandler error={this.state.error}
                              onHide={() => this.setState(prevState => ({...prevState, error: null}))} />
            </>
        );
    }

    //------------
    //API Methods
    //------------

    async saveOrUpdateAsset() {
        if (!RequiredFieldsAreValid('asset', [AssetMetaData.DETAILS_GENERAL, AssetMetaData.DETAILS_STORAGE], this.state, (s) => this.setState(s))) {
            return;
        }
        let asset = {};
        if (this.state.asset.id != null) {
            asset = await UpdateAsset(this.state.asset);
        } else {
            asset = await CreateAsset(this.state.asset);
        }

        if (!asset.error) {
            if (this.state.asset.id == null) {
                this.props.history.push("/"+ GlobalConstants.APP_PATH + "assets/" + asset.result.id);
            }
            this.setState((prevState) => ({
                ...prevState,
                asset: asset.result,
                editMode: {...prevState.editMode, active: this.state.editMode.autoSave}
            }));

            this.props.addToast("The asset has been updated successfully.", {
                autoDismiss: true,
                appearance: 'success'
            });
        } else {
            this.setState(prevState => ({...prevState, error: asset}));
        }
    }

    //--------
    // HELPERS
    //--------

    transformUrlToLink(asset) {

        let link = generateAssetDownloadUrl(asset)

        if (link != null) {
            return (
                <>
                    <Button variant="outline-secondary"
                            onClick={() => openExternalTargetInNewTab(link)}>Open</Button>
                    &#xA0;
                    &#xA0;
                    <Button variant="outline-secondary"
                            onClick={() => {
                                navigator.clipboard.writeText(link).then(r => {
                                    this.props.addToast("URL copied to clipboard", {
                                        autoDismiss: true,
                                        appearance: 'info'
                                    });
                                });
                            }}>Copy</Button>
                </>
            )
        } else {
            return null;
        }
    }

}


export default withToast(AssetDetails);