import {Button} from "react-bootstrap";
import React, {Component} from "react";
import {withToast} from "../../util/ToastService";
import {Link} from "react-router-dom";
import {FaBook,} from "react-icons/all";
import GlobalConstants from "../../config/GlobalConstants";
import {CreatePublishingHouse, GetPublishingHouse, UpdatePublishingHouse} from "./PublishingHouseService";
import {DetailsSection, RequiredFieldsAreValid} from "../../generators/DetailsGenerator";
import PublishingHouseMetaData from "./PublishingHouseMetaData";
import {PromiseButton} from "../../global/SpinningTiger";
import {ErrorHandler} from "../../util/ErrorHandler";

class PublishingHouseDetails extends Component {

    //------------
    //Constructor
    //------------

    constructor(props) {
        super(props);

        this.state = {
            error: null,
            flowState: props.location.state,
            id: this.props.match.params.id,
            editMode: {
                active: false,
                expertMode: false,
                autoSave: true,
            },
            publishingHouse: {},
            originalPublishingHouse: {},
            sections: {
                general: true,
                ownerDetails: true,
            },
            validationErrors: []
        }
    }

    //---------
    //Mounting
    //---------

    async componentDidMount() {
        //Set the title
        if(this.state.id != null) {
            document.title = "Publishing House " + this.state.id + " :: Tiger UI";
        } else {
            document.title = "New publishing house :: Tiger UI";
        }

        await this.loadPublishingHouse();
    }

    //--------
    //Loading
    //--------

    async loadPublishingHouse() {
        let loadedPublishingHouse = {};

        if(this.state.id != null && this.state.id !== "add") {

            loadedPublishingHouse = await GetPublishingHouse(this.state.id);

            if (!loadedPublishingHouse.error) {
                const publishingHouse = loadedPublishingHouse.result;

                this.setState({
                    id: this.state.id,
                    editMode: this.state.editMode,
                    publishingHouse: publishingHouse,
                    originalPublishingHouse: publishingHouse,
                });
            } else {
                this.setState(prevState => ({...prevState, error: loadedPublishingHouse}));
            }
        } else {
            this.setState((prevState) => ({...prevState,
                id: null,
                publishingHouse: {
                    id: null,
                },
                editMode: {
                    active: true,
                    expertMode: false,
                    autoSave: false,
                }}));
        }
    }

    //----------
    //Rendering
    //----------

    render() {

        return (
            <>
                <div className="details-title">
                    {this.state.publishingHouse.id != null ? "Details of Publishing House " + this.state.publishingHouse.id : "Create a new PublishingHouse"}
                </div>
                <div className="details-button-box" style={{height: "70px"}}>
                    <Link to={{pathname: "/" + GlobalConstants.APP_PATH +"publishingHouses", state: this.state.flowState}}>
                        <button className="form-btn-ci-light-blue" type="button">Back</button>
                    </Link>
                    {this.state.publishingHouse.id != null &&
                    <button className={this.state.editMode.active ? "form-btn-ci-red" : "form-btn-ci-blue"}  type="button" onClick={() => this.setState({
                        editMode: {
                            active: !this.state.editMode.active,
                            expertMode: this.state.editMode.expertMode
                        },
                        publishingHouse: this.state.originalPublishingHouse
                    })}>{this.state.editMode.active ? "Cancel" : "Edit"}</button>
                    }
                    {this.state.editMode.active &&
                    <PromiseButton text="Save" onClick={() => this.saveOrUpdatePublishingHouse()} />
                    }
                    <Button style={{float: "right", marginTop: "5px", marginRight: "10px"}} variant={this.state.editMode.autoSave ? "secondary": "outline-secondary"}
                            onClick={() => this.setState(prevState => ({
                                ...prevState,
                                editMode: {
                                    ...prevState.editMode,
                                    autoSave: !this.state.editMode.autoSave
                                }
                            }))}>Auto Save</Button>
                </div>

                <div className="details-box-title">Resource Details</div>
                <div className="details-box">
                    {/* GENERAL DATA */}
                    <DetailsSection
                        nameInState="publishingHouse"
                        fields={PublishingHouseMetaData.DETAILS_GENERAL}
                        state={this.state}
                        onSetState={(s) => this.setState(s)}
                        onUpdateResource={() => this.saveOrUpdatePublishingHouse()}
                        sectionId="general"
                    />

                    {/* SECTION 1 */}
                    <DetailsSection
                        nameInState="publishingHouse"
                        fields={PublishingHouseMetaData.OWNER_DETAILS}
                        state={this.state}
                        onSetState={(s) => this.setState(s)}
                        sectionId="ownerDetails"
                        onUpdateResource={() => this.saveOrUpdatePublishingHouse()}
                        label={<span><FaBook/>&#xA0;Owner Details</span>}
                    />

                </div>
                <div className="details-button-box" style={{height: "70px"}}>
                    <Link to={{pathname: "/" + GlobalConstants.APP_PATH +"publishingHouses", state: this.state.flowState}}>
                        <button className="form-btn-ci-light-blue" type="button">Back</button>
                    </Link>
                    {this.state.editMode.active &&
                    <PromiseButton text="Save" onClick={() => this.saveOrUpdatePublishingHouse()} />
                    }
                </div>

                <ErrorHandler error={this.state.error}
                              onHide={() => this.setState(prevState => ({...prevState, error: null}))} />
            </>
        );
    }

    //------------
    //API Methods
    //------------

    async saveOrUpdatePublishingHouse() {
        if (!RequiredFieldsAreValid('publishingHouse', [PublishingHouseMetaData.DETAILS_GENERAL, PublishingHouseMetaData.OWNER_DETAILS], this.state, (s) => this.setState(s))) {
            return;
        }
        let publishingHouse = {};
        if(this.state.publishingHouse.id != null) {
            publishingHouse = await UpdatePublishingHouse(this.state.publishingHouse);
        } else {
            publishingHouse = await CreatePublishingHouse(this.state.publishingHouse);
        }

        if (!publishingHouse.error) {
            if (this.state.publishingHouse.id == null) {
                this.props.history.push("/"+ GlobalConstants.APP_PATH + "publishingHouses/" + publishingHouse.result.id);
            }
            this.setState((prevState) =>({...prevState, publishingHouse: publishingHouse.result, editMode: {...prevState.editMode, active: this.state.editMode.autoSave}}));

            this.props.addToast("The publishingHouse has been updated successfully.", {
                autoDismiss: true,
                appearance: 'success'
            });
        } else {
            this.setState(prevState => ({...prevState, error: publishingHouse}));
        }
    }

}


export default withToast(PublishingHouseDetails);