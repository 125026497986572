import {Button} from "react-bootstrap";
import React, {Component} from "react";
import {withToast} from "../../util/ToastService";
import {Link} from "react-router-dom";
import {
    IoPeopleCircleOutline,
} from "react-icons/all";
import GlobalConstants from "../../config/GlobalConstants";
import {DetailsSection} from "../../generators/DetailsGenerator";
import {CreateCoupon, CreateTicketOrder, GetCoupon, ResetCoupon, UpdateCoupon} from "./TicketService";
import TicketMetaData from "./TicketMetaData";
import {ActionModal, InfoModal} from "../../generators/ModalGenerator";
import {OrderTicketDialog} from "./OrderTicketDialog";
import {PromiseButton} from "../../global/SpinningTiger";
import {ErrorHandler} from "../../util/ErrorHandler";

class CouponDetails extends Component {

    //------------
    //Constructor
    //------------

    constructor(props) {
        super(props);

        this.state = {
            error: null,
            flowState: props.location.state,
            id: this.props.match.params.id,
            editMode: {
                active: false,
                expertMode: false,
                autoSave: true,
            },
            coupon: {},
            originalCoupon: {},
            sections: {
                general: true,
                ticketDetails: true,
                statusDetails: true,
            },
            showResetModal: false,
            showOrderDialog: false,
        }
    }

    //---------
    //Mounting
    //---------

    async componentDidMount() {
        //Set the title
        if(this.state.id != null) {
            document.title = "Coupon " + this.state.id + " :: Tiger UI";
        } else {
            document.title = "New coupon :: Tiger UI";
        }
        await this.loadCoupon();
    }

    //--------
    //Loading
    //--------

    async loadCoupon() {
        let loadedCoupon = {};

        if(this.state.id != null && this.state.id !== "add") {

            loadedCoupon = await GetCoupon(this.state.id);

            if (!loadedCoupon.error) {
                const coupon = loadedCoupon.result;

                this.setState({
                    id: this.state.id,
                    editMode: this.state.editMode,
                    coupon: coupon,
                    originalCoupon: coupon,
                });
            } else {
                this.setState(prevState => ({...prevState, error: loadedCoupon}));
            }
        } else {
            this.setState((prevState) => ({...prevState,
                id: null,
                coupon: {
                    id: null,
                    createdBy: 'system'
                },
                editMode: {
                    active: true,
                    expertMode: false,
                    autoSave: false,
                }}));
        }
    }

    //----------
    //Rendering
    //----------

    render() {

        return (
            <>
                <div className="details-title">
                    {this.state.coupon.id != null ? "Details of coupon " + this.state.coupon.id : "Create a coupon"}
                </div>
                <div className="details-button-box" style={{height: "70px"}}>
                    <Link to={{
                        pathname: "/" + GlobalConstants.APP_PATH + "purchases/tickets",
                        state: this.state.flowState
                    }}>
                        <button className="form-btn-ci-light-blue" type="button">Back</button>
                    </Link>
                    {this.state.coupon.id != null &&
                    <button className={this.state.editMode.active ? "form-btn-ci-red" : "form-btn-ci-blue"}
                            type="button" onClick={() => this.setState({
                        editMode: {
                            active: !this.state.editMode.active,
                            expertMode: this.state.editMode.expertMode
                        },
                        coupon: this.state.originalCoupon
                    })}>{this.state.editMode.active ? "Cancel" : "Edit"}</button>
                    }
                    {this.state.editMode.active &&
                        <PromiseButton text="Save" onClick={() => this.saveOrUpdateCoupon()} />
                    }
                    <button style={{marginLeft: "20px"}} className="form-btn-ci-light"
                            onClick={() => this.setState(prevState => ({...prevState, showResetModal: true}))}>Reset
                        coupon
                    </button>
                    <button className={this.state.coupon.used ? "form-btn-ci-off" : "form-btn-ci-green"}
                            onClick={() => this.setState(prevState => ({...prevState, showOrderDialog: true}))}
                        disabled={this.state.coupon.used}>
                        Create Order
                    </button>
                    <Button style={{float: "right", marginTop: "5px", marginRight: "10px"}}
                            variant={this.state.editMode.autoSave ? "secondary" : "outline-secondary"}
                            onClick={() => this.setState(prevState => ({
                                ...prevState,
                                editMode: {
                                    ...prevState.editMode,
                                    autoSave: !this.state.editMode.autoSave
                                }
                            }))}>Auto Save</Button>
                </div>

                <div className="details-box-title">Resource Details</div>
                <div className="details-box">
                    {/* GENERAL DATA */}
                    <DetailsSection
                        nameInState="coupon"
                        fields={TicketMetaData.COUPON_DETAILS_GENERAL}
                        state={this.state}
                        onSetState={(s) => this.setState(s)}
                        onUpdateResource={() => this.saveOrUpdateCoupon()}
                        sectionId="general"
                    />

                    <DetailsSection
                        nameInState="coupon"
                        fields={TicketMetaData.COUPON_DETAILS_TICKET}
                        state={this.state}
                        onSetState={(s) => this.setState(s)}
                        onUpdateResource={() => this.saveOrUpdateCoupon()}
                        sectionId="ticketDetails"
                        label={<span><IoPeopleCircleOutline/>&#xA0;Coupon</span>}
                    />

                    <DetailsSection
                        nameInState="coupon"
                        fields={TicketMetaData.COUPON_DETAILS_STATUS}
                        state={this.state}
                        onSetState={(s) => this.setState(s)}
                        onUpdateResource={() => this.saveOrUpdateCoupon()}
                        sectionId="statusDetails"
                        label={<span><IoPeopleCircleOutline/>&#xA0;Status</span>}
                    />

                </div>
                <div className="details-button-box" style={{height: "70px"}}>
                    <Link to={{
                        pathname: "/" + GlobalConstants.APP_PATH + "purchases/tickets",
                        state: this.state.flowState
                    }}>
                        <button className="form-btn-ci-light-blue" type="button">Back</button>
                    </Link>
                    {this.state.editMode.active &&
                        <PromiseButton text="Save" onClick={() => this.saveOrUpdateCoupon()} />
                    }
                </div>

                <ActionModal show={this.state.showResetModal}
                             actionButtonText={"Continue"}
                             size='sm'
                             onAction={() => this.resetCoupon()}
                             onHide={() => this.setState(prevState => ({...prevState, showResetModal: false}))}
                             title="Reset Coupon "
                             body={
                                 <div style={{textAlign: "center"}}>
                                     By resetting the coupon, redemption status will be deleted so it can be
                                     redeemed again. Continue?
                                 </div>
                             }/>

                <InfoModal show={this.state.showOrderDialog}
                             onHide={() => this.setState(prevState => ({...prevState, showOrderDialog: false}))}
                             title={"Create order for ticket " + this.state.coupon.code}
                             body={
                                 <OrderTicketDialog code={this.state.coupon.code} pin={this.state.coupon.pin}
                                                    onOrder={(accountId) => this.createCouponOrder(accountId)}/>
                             }/>

                <ErrorHandler error={this.state.error}
                              onHide={() => this.setState(prevState => ({...prevState, error: null}))} />
            </>
        );
    }

    //------------
    //API Methods
    //------------

    async createCouponOrder(accountId) {
        let response = await CreateTicketOrder(this.state.coupon.code, this.state.coupon.pin, accountId);
        if (!response.error) {
            this.loadCoupon().then(r => this.setState(prevState => ({...prevState, showOrderDialog: false})));
        } else {
            this.setState(prevState => ({...prevState, error: response}));
        }
    }

    async saveOrUpdateCoupon() {
        let coupon = {};
        if(this.state.coupon.id != null) {
            coupon = await UpdateCoupon(this.state.coupon);
        } else {
            coupon = await CreateCoupon(this.state.coupon);
        }

        if (!coupon.error) {
            this.setState((prevState) =>({...prevState, coupon: coupon.result, editMode: {...prevState.editMode, active: this.state.editMode.autoSave}}));

            this.props.addToast("The coupon has been updated successfully.", {
                autoDismiss: true,
                appearance: 'success'
            });
        } else {
            this.setState(prevState => ({...prevState, error: coupon}));
        }
    }

    async resetCoupon() {
        let response = await ResetCoupon(this.state.id);
        if(!response.error) {
            this.loadCoupon().then(r => {
                this.props.addToast("Coupon has been reset successfully", {
                    autoDismiss: true,
                    appearance: "success"
                });
                this.setState(prevState => ({...prevState, showResetModal: false}));
            });
        } else {
            this.setState(prevState => ({...prevState, error: response}));
        }
    }
}


export default withToast(CouponDetails);