import {
    DeleteSpineResource,
    UpdateSpineResource
} from "../../clients/SpineClient";
import {GetFilteredRestResource} from "../../clients/RestResourceClient";

//API METHODS
export async function GetCancelledContracts(filter){

    return await GetFilteredRestResource('api/crm/contracts/cancellations', filter);
}

export async function UpdateCancelledContract(contractCancellation) {
    return await UpdateSpineResource('api/crm/contracts/cancellations/' + contractCancellation.id, contractCancellation);
}

export async function DeleteCancelledContract(contractCancellation) {
    return await DeleteSpineResource('api/crm/contracts/cancellations/' + contractCancellation.id, false);
}
