import {
    CreateSpineResource,
    DeleteSpineResource, GetAllSpineResources,
    GetSpineResource,
    UpdateSpineResource
} from "../../clients/SpineClient";
import {GetFilteredRestResource} from "../../clients/RestResourceClient";

//API METHODS
export async function CreateAggregator(aggregator) {
    return await CreateSpineResource('api/aggregators', aggregator);
}

export async function GetFilteredAggregators(filter){
    return await GetFilteredRestResource('api/aggregators', filter);
}

export async function GetAllAggregators() {
    return await GetAllSpineResources('api/aggregators');
}

export async function GetAggregator(id) {
    return await GetSpineResource('api/aggregators/' + id);
}

export async function UpdateAggregator(aggregator) {
    return await UpdateSpineResource('api/aggregators/' + aggregator.id, aggregator);
}

export async function DeleteAggregator(aggregator) {
    return await DeleteSpineResource('api/aggregators/' + aggregator.id);
}

//---------- OPTIONAL -------------
/*
export async function PatchAggregator(aggregator, newValue) {
    return await PatchResource('api/aggregators/' + aggregator.id + '/patch', {attribute: newValue});
}
 */