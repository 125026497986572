import {useState} from "react";
import {Col, Form, InputGroup} from "react-bootstrap";
import Row from 'react-bootstrap/Row';
import {Label} from "reactstrap";
import GlobalConstants from "../../../config/GlobalConstants";
import {CreatePremiumAccess} from "../SupportToolService";
import {PromiseButton} from "../../../global/SpinningTiger";

export function CreatePremiumAccessDialog(props) {

    let [account] = useState(props.account);
    let [amountWeeks, setAmountWeeks] = useState(0);
    let [amountMonths, setAmountMonths] = useState(0);
    let [amountYears, setAmountYears] = useState(0);
    let [note, setNote] = useState("");
    let [overwrite, setOverwrite] = useState(false);

    return(
        <>
            <p>
                Erstelle einen neuen Zugang für {account.email}:
            </p>
            <hr />
            <Form>
                <Row>
                    <Form.Group as={Col}>
                        <InputGroup className="mb-3">
                            <Form.Control type="number" value={amountWeeks} onChange={(e) => setAmountWeeks(e.target.value)}/>
                            <InputGroup.Text id="basic-addon2">Wochen</InputGroup.Text>
                        </InputGroup>
                    </Form.Group>
                    <Form.Group as={Col}>
                        <InputGroup className="mb-3">
                            <Form.Control type="number" value={amountMonths} onChange={(e) => setAmountMonths(e.target.value)}/>
                            <InputGroup.Text id="basic-addon2">Monate</InputGroup.Text>
                        </InputGroup>
                    </Form.Group>
                    <Form.Group as={Col}>
                        <InputGroup className="mb-3">
                            <Form.Control type="number" value={amountYears} onChange={(e) => setAmountYears(e.target.value)}/>
                            <InputGroup.Text id="basic-addon2">Jahre</InputGroup.Text>
                        </InputGroup>
                    </Form.Group>
                </Row>
                <Row>
                    <Form.Group as={Col}>
                        <Label>Aktuellen Zugang überschreiben?</Label>
                        <div style={{marginLeft: "5px"}}>
                            <Form.Text>In diesem Fall ist der Zugang ab Zeitpunkt der Einlösung gültig. Falls aktuell noch ein aktiver Zugang existiert, verfällt dessen Laufzeit.<br />Wird dieses Feld nicht ausgewählt, verlängert sich der aktuelle Zugang um die ausgewählte Laufzeit.</Form.Text>
                            <Form.Check value={overwrite} onChange={(e) => setOverwrite(!overwrite)} />
                        </div>
                    </Form.Group>
                </Row>
                <Row>
                    <Form.Group as={Col}>
                        <Label>Bemerkung:</Label>
                        <Form.Text>(Optional)</Form.Text>
                        <Form.Control as="textarea" rows={5} value={note} onChange={(e) => setNote(e.target.value)} />
                    </Form.Group>
                </Row>
            </Form>

            <p style={{float: "right"}}>
                <button className="form-btn-ci-red" onClick={() => props.onHide(false)}>Abbrechen</button>
                <PromiseButton text="Speichern" onClick={() => createPremiumAccess(account.id, amountWeeks, amountMonths, amountYears, overwrite, note).then(r => {
                    if(r.error) {
                        props.parentProps.addToast("Beim Erstellen des Zugang ist ein Fehler aufgetreten: " + r.message, {
                            appearance: "error",
                            autoDismiss: true
                        });
                    } else {
                        props.parentProps.addToast("Der Zugang wurde erfolgreich erstellt.", {
                            autoDismiss: true,
                            appearance: "success"
                        });
                        props.onHide(true);
                    }
                })} />
            </p>
        </>
    );
}

async function createPremiumAccess(accountId, amountWeeks, amountMonths, amountYears, overwrite, note) {
    let productIds = [];
    for (let i = 0; i < amountWeeks; i++) {
        productIds.push(GlobalConstants.PRODUCT_ID_1_WEEK_PREMIUMACCESS);
    }
    for (let i = 0; i < amountMonths; i++) {
        productIds.push(GlobalConstants.PRODUCT_ID_1_MONTH_PREMIUMACCESS);
    }
    for (let i = 0; i < amountYears; i++) {
        productIds.push(GlobalConstants.PRODUCT_ID_1_YEAR_PREMIUMACCESS);
    }

    return await CreatePremiumAccess({productIds: productIds, accountId: accountId, overwrite: overwrite, note: note})
}