import {Badge, Button, Col, Form, FormControl, InputGroup, Table} from "react-bootstrap";
import Row from 'react-bootstrap/Row';
import React, {Component} from "react";
import {withToast} from "../../util/ToastService";
import {Link} from "react-router-dom";
import GlobalConstants from "../../config/GlobalConstants";
import {CreateEpisode, GetEpisode, UpdateEpisode} from "./SeriesService";
import {DetailsSection} from "../../generators/DetailsGenerator";
import EpisodeMetaData from "./EpisodeMetaData";
import {Label} from "reactstrap";
import {GetFilteredProducts, GetProduct} from "../products/ProductService";
import {InfoModal} from "../../generators/ModalGenerator";
import {ProductStateBadges, ProductTypeBadges} from "../products/ProductMetaData";
import {TablePagination} from "../../generators/TableGenerator";
import {PromiseButton} from "../../global/SpinningTiger";
import {ErrorHandler} from "../../util/ErrorHandler";
import {openExternalTargetInNewTab} from "../../util/ResourceService";

class EpisodeDetails extends Component {

    //------------
    //Constructor
    //------------

    constructor(props) {
        super(props);

        this.state = {
            error: null,
            flowState: props.location.state,
            seriesId: this.props.match.params.seriesId,
            seasonId: this.props.match.params.seasonId,
            id: this.props.match.params.id,
            editMode: {
                active: false,
                expertMode: false,
                autoSave: true,
            },
            episode: {},
            originalEpisode: {},
            product: {},
            sections: {
                general: true,
            },
            products: [],
            productPage: 1,
            lastProductPage: 1,
            productFilterName: "",
            showProductPickerDialog: false,
        }
    }

    //---------
    //Mounting
    //---------

    async componentDidMount() {
        //Set the title
        if(this.state.id != null) {
            document.title = "Episode " + this.state.id + " :: Tiger UI";
        } else {
            document.title = "New episode :: Tiger UI";
        }
        await this.loadEpisode();
        await this.loadProduct();
    }

    //--------
    //Loading
    //--------

    async loadEpisode() {
        let loadedEpisode = {};

        if(this.state.id != null && this.state.id !== "add") {

            loadedEpisode = await GetEpisode(this.state.seriesId, this.state.seasonId, this.state.id);

            if (!loadedEpisode.error) {
                const episode = loadedEpisode.result;

                this.setState({
                    id: this.state.id,
                    editMode: this.state.editMode,
                    episode: episode,
                    originalEpisode: episode,
                });
            } else {
                this.setState(prevState => ({...prevState, error: loadedEpisode}));
            }
        } else {
            this.setState((prevState) => ({...prevState,
                id: null,
                episode: {
                    id: null,
                },
                editMode: {
                    active: true,
                    expertMode: false,
                    autoSave: false
                }}));
        }
    }

    async loadProduct() {
        if(this.state.episode.productId != null) {
            let loadedProduct = await GetProduct(this.state.episode.productId);
            if(!loadedProduct.error) {
                this.setState(prevState => ({...prevState, product: loadedProduct.result}));
            } else {
                this.setState(prevState => ({...prevState, error: loadedProduct}));
            }
        }
    }

    async loadProducts(page) {
        let loadedProducts = await GetFilteredProducts({page: page, title: this.state.productFilterName});
        if(!loadedProducts.error) {
            this.setState(prevState => ({
                ...prevState,
                products: loadedProducts.result,
                productPage: page,
                lastProductPage: Math.ceil(loadedProducts.length / 20)
            }));
        } else {
            this.setState(prevState => ({...prevState, error: loadedProducts}));
        }
    }

    //----------
    //Rendering
    //----------

    render() {

        const labelStyle = {
            fontSize: "20px",
            backgroundColor: "#C8C8C8",
            borderStyle: "solid",
            borderColor: "#cdd7e0",
            borderWidth: "1px",
            borderRadius: "5px",
            paddingLeft: "10px",
            paddingRight: "10px",
            width: "100%"
        };

        return (
            <>
                <div className="details-title">
                    {this.state.episode.id != null ? "Details of Episode " + this.state.episode.id : "Create a new Episode"}
                </div>
                <div className="details-button-box" style={{height: "70px"}}>
                    <Link to={{pathname: "/" + GlobalConstants.APP_PATH +"series/" + this.state.seriesId + "/seasons/" + this.state.seasonId, state: this.state.flowState}}>
                        <button className="form-btn-ci-light-blue" type="button">Back</button>
                    </Link>
                    {this.state.episode.id != null &&
                    <button className={this.state.editMode.active ? "form-btn-ci-red" : "form-btn-ci-blue"} type="button" onClick={() => this.setState({
                        editMode: {
                            active: !this.state.editMode.active,
                            expertMode: this.state.editMode.expertMode
                        },
                        episode: this.state.originalEpisode
                    })}>{this.state.editMode.active ? "Cancel" : "Edit"}</button>
                    }
                    {this.state.editMode.active &&
                    <PromiseButton text="Save" onClick={() => this.saveOrUpdateEpisode()} />
                    }
                    <Button style={{float: "right", marginTop: "5px"}} variant={this.state.editMode.expertMode ? "secondary": "outline-secondary"}
                            onClick={() => this.setState(prevState => ({
                                ...prevState,
                                editMode: {
                                    ...prevState.editMode,
                                    expertMode: !this.state.editMode.expertMode
                                }
                            }))}>Expert Mode</Button>
                    <Button style={{float: "right", marginTop: "5px", marginRight: "10px"}} variant={this.state.editMode.autoSave ? "secondary": "outline-secondary"}
                            onClick={() => this.setState(prevState => ({
                                ...prevState,
                                editMode: {
                                    ...prevState.editMode,
                                    autoSave: !this.state.editMode.autoSave
                                }
                            }))}>Auto Save</Button>
                </div>

                <div className="details-box-title">Resource Details</div>
                <div className="details-box">
                    {/* GENERAL DATA */}
                    <DetailsSection
                        nameInState="episode"
                        fields={EpisodeMetaData.DETAILS_GENERAL}
                        state={this.state}
                        onSetState={(s) => this.setState(s)}
                        onUpdateResource={() => this.saveOrUpdateEpisode()}
                        sectionId="general"
                    />

                    <Form>
                        <Row>
                            <Form.Group as={Col}>
                                <Label style={labelStyle}>Product ID</Label>
                                <InputGroup>
                                    <FormControl type="text" value={this.state.episode.productId} readOnly={!this.state.editMode.active}
                                                 onChange={(e) => this.setState(prevState => ({...prevState, episode: {...prevState.episode, productId: e.target.value}}))} />
                                    {this.state.editMode.active &&
                                            <Button variant="outline-secondary" onClick={() => {
                                                this.setState(prevState => ({...prevState, showProductPickerDialog: true}));
                                                this.loadProducts(1).then(r => r);
                                            }}>Pick</Button>
                                    }
                                </InputGroup>
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Label style={labelStyle}>Product Title</Label>
                                <InputGroup>
                                    <FormControl type="text" value={this.state.product.title} readOnly={true}/>
                                    {this.state.editMode.active &&
                                        <Button variant="outline-secondary" onClick={() => {
                                            this.setState(prevState => ({...prevState, showProductPickerDialog: true}));
                                            this.loadProducts(1).then(r => r);
                                        }}>Pick</Button>
                                    }
                                </InputGroup>
                            </Form.Group>
                        </Row>
                        <Row>
                            <Form.Group as={Col}>
                                <Label style={labelStyle}>Display Image Url</Label>
                                <FormControl type="text" value={this.state.episode.displayImageUrl}
                                             readOnly={!this.state.editMode.active}
                                             onChange={(e) => this.setState(prevState => ({
                                                 ...prevState,
                                                 episode: {
                                                     ...prevState.episode,
                                                     displayImageUrl: e.target.value
                                                 }
                                             }))} />
                            </Form.Group>
                            <Form.Group as={Col} className={"display-image-column"}>
                                <Label style={labelStyle}>Preview</Label>
                                {this.state.episode.displayImageUrl ?
                                    <div className={"display-image-container"}>
                                        <img src={this.state.episode.displayImageUrl}
                                             alt={"Preview of display image of episode " + this.state.episode.id} height={240}
                                             style={{cursor: "pointer"}}
                                             onClick={() => openExternalTargetInNewTab(this.state.episode.displayImageUrl)}/>
                                    </div>
                                    :
                                    <p style={{fontSize: "20px", textAlign: "center"}}>No preview available</p>
                                }

                            </Form.Group>
                        </Row>
                        <Row>
                            <Form.Group as={Col}>
                                <Label style={labelStyle}>Description</Label>
                                <FormControl as="textarea" rows={15}
                                             value={this.state.episode.description}
                                             readOnly={!this.state.editMode.active}
                                             onChange={(e) => this.setState(prevState => ({
                                                 ...prevState,
                                                 episode: {
                                                     ...prevState.episode,
                                                     description: e.target.value
                                                 }
                                             }))}/>
                            </Form.Group>
                        </Row>
                    </Form>



                </div>
                <div className="details-button-box" style={{height: "70px"}}>
                    <Link to={{pathname: "/" + GlobalConstants.APP_PATH +"series/" + this.state.seriesId + "/seasons/" + this.state.seasonId, state: this.state.flowState}}>
                        <button className="form-btn-ci-light-blue" type="button">Back</button>
                    </Link>
                    {this.state.editMode.active &&
                    <PromiseButton text="Save" onClick={() => this.saveOrUpdateEpisode()} />
                    }
                </div>

                {/* DIALOGS */}
                <InfoModal show={this.state.showProductPickerDialog}
                           onHide={() => this.setState(prevState => ({...prevState, showProductPickerDialog: false}))}
                           title={"Select a product"}
                           body={this.productPickerDialog()}/>

                <ErrorHandler error={this.state.error}
                              onHide={() => this.setState(prevState => ({...prevState, error: null}))} />
            </>
        );
    }

    //------------
    //API Methods
    //------------

    async saveOrUpdateEpisode() {
        let episode = {};
        if(this.state.episode.id != null) {
            episode = await UpdateEpisode(this.state.episode);
        } else {
            episode = await CreateEpisode(this.state.seriesId, this.state.seasonId, this.state.episode);
        }

        if (!episode.error) {
            this.setState((prevState) =>({...prevState,
                episode: episode.result,
                originalEpisode: episode.result,
                editMode: {...prevState.editMode,
                            active: this.state.editMode.autoSave
                }
            }));

            this.props.addToast("The episode has been updated successfully.", {
                autoDismiss: true,
                appearance: 'success'
            });
        } else {
            this.setState(prevState => ({...prevState, error: episode}));
        }
    }

    //--------
    // DIALOGS
    //--------

    productPickerDialog() {
        return (
            <>
                <Form>
                    <Row>
                        <Form.Group as={Col}>
                            <InputGroup>
                                <FormControl as="text" placeholder="Search for a product by its title" value={this.state.productFilterName}
                                             onChange={(e) => this.setState(prevState => ({...prevState, productFilterName: e.target.value}))} />
                                     <Button variant="outline-secondary" onClick={() => this.loadProducts(1)}>Search</Button>
                            </InputGroup>
                        </Form.Group>
                    </Row>
                </Form>
                <Table responsive bordered hover striped>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>State</th>
                            <th>Type</th>
                            <th>Title</th>
                            <th>IAP Product Identifier</th>
                        </tr>
                    </thead>
                    <tbody>
                    {this.state.products.map(product => (
                        <tr role="row" key={product.id} style={{cursor: "pointer"}}
                            onClick={() => this.setState(prevState => ({...prevState, product: product, showProductPickerDialog: false, episode: {...prevState.episode, productId: product.id}}))}>
                            <td><Link to={"/" + GlobalConstants.APP_PATH + "products/" + product.id} style={{fontStyle: "italic", color: "#333"}}>{product.id}</Link></td>
                            <td><Badge bg={ProductStateBadges(product)}>{product.state}</Badge></td>
                            <td><Badge bg={ProductTypeBadges(product)}>{product.productType}</Badge></td>
                            <td>{product.title}</td>
                            <td>{product.iapProductIdentifier}</td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
                <TablePagination page={this.state.productPage} lastPage={this.state.lastProductPage}
                                 onLoad={(p) => this.loadProducts(p)}/>
            </>
        );
    }

}


export default withToast(EpisodeDetails);