import {
    CreateSpineResource,
    DeleteSpineResource,
    GetFilteredSpineResources,
    GetSpineResource,
    UpdateSpineResource
} from "../../clients/SpineClient";

//API METHODS
export async function CreateLibraryPool(libraryPool) {
    return await CreateSpineResource('api/library_pools', libraryPool);
}

export async function GetFilteredLibraryPools(filter){
    return await GetFilteredSpineResources('api/library_pools', filter);
}

export async function GetLibraryPool(id) {
    return await GetSpineResource('api/library_pools/' + id);
}

export async function UpdateLibraryPool(libraryPool) {
    return await UpdateSpineResource('api/library_pools/' + libraryPool.id, libraryPool);
}

export async function DeleteLibraryPool(libraryPool) {
    return await DeleteSpineResource('api/library_pools/' + libraryPool.id);
}

//---------- OPTIONAL -------------
/*
export async function PatchExample(example, newValue) {
    return await PatchResource('api/examples/' + example.id + '/patch', {attribute: newValue});
}
 */