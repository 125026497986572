const DIVISION_TYPES = ['SEASONS', 'EPISODES'];
const SERIES_TYPES = ['STANDARD', 'ARTIST'];
const VISIBILITY_TYPE_ITEMS = ['NOT_VISIBLE', 'VISIBLE', 'PREVIEW'];

const SeriesMetaData = {

    //Columns
    COLUMNS: [
    ],

    //Details
    DETAILS_GENERAL: [
        [{label: 'ID', id: 'id', type: 'number', editable: 'never', isExpertField: false}, {label: 'Division Type', id: 'divisionType', type: 'select', options: DIVISION_TYPES, editable: 'true', required: true}, {label: "Recommended Age", id: 'ageRecommended', type: 'number', editable: 'true'}],
        [{label: 'Display Image Url', id: 'displayImageUrl', type: 'text', editable: 'true', isExpertField: false}, {label: 'Series Type', id: 'divisionType', type: 'select', options: SERIES_TYPES, editable: 'true', required: false}],
        [{label: 'Name', id: 'name', type: 'text', editable: 'true', required: true}],
        [{label: 'Description', id: 'description', type: 'textarea', rows: 5, editable: 'true'}]
    ],

    //Attributes
    DIVISION_TYPES: DIVISION_TYPES,
    SERIES_TYPES: SERIES_TYPES,
    VISIBILITY_TYPES: VISIBILITY_TYPE_ITEMS,

    //Sorting
    SERIES_SORT_PARAMS: [{id: 'id', label: 'Series ID'}, {id: 'name', label: 'Name'}],
};

export default SeriesMetaData;