import {
    CreateSpineResource,
    DeleteSpineResource, GetAllSpineResources,
    GetFilteredSpineResources,
    GetSpineResource, PatchSpineResourceWithBody,
    UpdateSpineResource
} from "../../clients/SpineClient";
import GlobalConstants from "../../config/GlobalConstants";

//MAIN API METHODS
export async function CreateAccount(account) {
    return await CreateSpineResource('api/accounts', account);
}

export async function GetFilteredAccounts(filter){
    //TODO Update as soon as the account service is deployed and testet
    if (GlobalConstants.ENVIRONMENT === 'production_TBD') {
        return await GetFilteredSpineResources('api/accounts', filter);
    } else {
        if (filter.email != null && filter.email !== "") {
            return await GetFilteredSpineResources('api/accounts/search/byEmail', filter);
        } else {
            return await GetFilteredSpineResources('api/accounts', filter);
        }
    }
}

export async function GetAccountsByEmail(email) {
    return await GetFilteredSpineResources('api/accounts/search/byEmail', {email: email});
}

export async function GetAccount(id) {
    return await GetSpineResource('api/accounts/' + id);
}

export async function UpdateAccount(account) {
    return await UpdateSpineResource('api/accounts/' + account.id, account);
}

export async function DeleteAccount(account) {
    return await DeleteSpineResource('api/accounts/' + account.id, false);
}

export async function GetAccountRoles(id) {
    return await GetAllSpineResources('api/accounts/' + id + '/roles');
}

//ADDITIONAL API METHODS
export async function GetRecentlyUsedProducts(account, filter) {
    return await GetFilteredSpineResources('api/accounts/' + account.id + '/recentlyUsedProducts', {page: filter.page});
}

export async function ChangePassword(account, password) {
    return await PatchSpineResourceWithBody('api/accounts/' + account.id + '/password', {password: password});
}

export async function ChangeEmail(account, email) {
    return await PatchSpineResourceWithBody('api/accounts/' + account.id + '/change_mail', {email: email});
}

export async function ChangeRoles(account, roles) {
    return await PatchSpineResourceWithBody('api/accounts/' + account.id + '/roles', roles, false);
}

export async function ChangeAffiliate(account, affiliate) {
    return await PatchSpineResourceWithBody('api/accounts/' + account.id + '/affiliate', {affiliate: affiliate});
}

export async function GetAvailableProductTypes(account) {
    return await GetAllSpineResources('api/accounts/' + account.id + '/availableProductTypes');
}

export async function GetAllRoles() {
    return await GetAllSpineResources('api/roles');
}

//---------- OPTIONAL -------------
/*
export async function PatchAccount(example, newValue) {
    return await PatchResource('api/examples/' + example.id + '/patch', {attribute: newValue});
}
 */