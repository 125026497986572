import {Button, Col, Form, InputGroup, Pagination, Table} from "react-bootstrap";
import Row from 'react-bootstrap/Row';
import React, {Component} from "react";
import FormSelect from "react-bootstrap/FormSelect";
import {withToast} from "../../util/ToastService";
import {Link} from "react-router-dom";
import {AiOutlineEye, BsCardImage, BsTrash, GrEdit,} from "react-icons/all";
import GlobalConstants from "../../config/GlobalConstants";
import {CreateCategory, GetCategory, GetFilteredCategories, UpdateCategory} from "./CategoryService";
import {DetailsSection, RequiredFieldsAreValid} from "../../generators/DetailsGenerator";
import CategoryMetaData from "./CategoryMetaData";
import {Label} from "reactstrap";
import {ActionModal, InfoModal} from "../../generators/ModalGenerator";
import {openExternalTargetInNewTab} from "../../util/ResourceService";
import {copyUrlToClipboardColumn} from "../../generators/TableGenerator";
import {AddOrEditIconDialog} from "./AddOrEditAssetDialog";
import {PromiseButton} from "../../global/SpinningTiger";
import {ErrorHandler} from "../../util/ErrorHandler";

class CategoryDetails extends Component {

    //------------
    //Constructor
    //------------

    constructor(props) {
        super(props);

        this.state = {
            error: null,
            flowState: props.location.state,
            id: this.props.match.params.id,
            editMode: {
                active: false,
                expertMode: false,
                autoSave: true,
            },
            category: {},
            originalCategory: {},
            categories: [],
            categoryPage: 1,
            categoryFilterName: "",
            showParentCategoryDialog: false,
            showAssetDialog: false,
            deleteAssetDialog: false,
            icons: [],
            sections: {
                general: true,
                appearance: true,
                icons: true,
            },
            validationErrors: []

        }

        this.selectedIcon = {};
    }

    //---------
    //Mounting
    //---------

    async componentDidMount() {
        //Set the title
        if(this.state.id != null) {
            document.title = "Category " + this.state.id + " :: Tiger UI";
        } else {
            document.title = "New category :: Tiger UI";
        }
        await this.loadCategory();
        await this.loadCategories();
    }

    //--------
    //Loading
    //--------

    async loadCategory() {
        let loadedCategory = {};

        if(this.state.id != null && this.state.id !== "add") {

            loadedCategory = await GetCategory(this.state.id);

            if (!loadedCategory.error) {
                const category = loadedCategory.result;

                let icons = category.icons;

                for(let i = 0; i < icons.length; i++){
                    icons[i].url = icons[i].location.location;
                }

                this.setState({
                    id: this.state.id,
                    editMode: this.state.editMode,
                    category: category,
                    originalCategory: category,
                    icons: icons,
                });
            } else {
                this.setState(prevState => ({...prevState, error: loadedCategory}));
            }
        } else {
            this.setState((prevState) => ({...prevState,
                id: null,
                category: {
                    id: null,
                    visibility: "VISIBLE"
                },
                editMode: {
                    active: true,
                    expertMode: false,
                    autoSave: false,
                }}));
        }
    }

    async loadCategories(page = null){
        let loadedCategories = [];

        if(page != null) {
            loadedCategories = await GetFilteredCategories({page: page});
            this.setState(prevState => ({...prevState, categoryPage: page}));
        } else {
            loadedCategories = await GetFilteredCategories({page: this.state.categoryPage});
        }


        if(!loadedCategories.error) {
            const categories = loadedCategories.result;

            this.setState(prevState => ({...prevState, categories: categories}));
        } else {
            this.setState(prevState => ({...prevState, error: loadedCategories}));
        }
    }

    //----------
    //Rendering
    //----------

    render() {

        const labelStyle = {
            fontSize: "20px",
            backgroundColor: "#C8C8C8",
            borderStyle: "solid",
            borderColor: "#cdd7e0",
            borderWidth: "1px",
            borderRadius: "5px",
            paddingLeft: "10px",
            paddingRight: "10px",
            width: "100%"
        };

        return (
            <>
                <div className="details-title">
                    {this.state.category.id != null ? "Details of Category " + this.state.category.id : "Create a new Category"}
                </div>
                <div className="details-button-box" style={{height: "70px"}}>
                    <Link to={{pathname: "/" + GlobalConstants.APP_PATH + "categories", state: this.state.flowState}}>
                        <button className="form-btn-ci-light-blue" type="button">Back</button>
                    </Link>
                    {this.state.category.id != null &&
                    <button className={this.state.editMode.active ? "form-btn-ci-red" : "form-btn-ci-blue"}  type="button" onClick={() => this.setState({
                        editMode: {
                            active: !this.state.editMode.active,
                            expertMode: this.state.editMode.expertMode
                        },
                        category: this.state.originalCategory
                    })}>{this.state.editMode.active ? "Cancel" : "Edit"}</button>
                    }
                    {this.state.editMode.active &&
                        <PromiseButton text="Save" onClick={() => this.saveOrUpdateCategory()} />
                    }
                    <Button style={{float: "right", marginTop: "5px", marginRight: "10px"}} variant={this.state.editMode.autoSave ? "secondary": "outline-secondary"}
                            onClick={() => this.setState(prevState => ({
                                ...prevState,
                                editMode: {
                                    ...prevState.editMode,
                                    autoSave: !this.state.editMode.autoSave
                                }
                            }))}>Auto Save</Button>
                </div>

                <div className="details-box-title">Resource Details</div>
                <div className="details-box">
                    {/* GENERAL DATA */}
                    <DetailsSection
                        nameInState="category"
                        fields={CategoryMetaData.DETAILS_GENERAL}
                        state={this.state}
                        onSetState={(s) => this.setState(s)}
                        onUpdateResource={() => this.saveOrUpdateCategory()}
                        sectionId="general"
                    />

                    {/* APPEARANCE */}
                    <DetailsSection
                        nameInState="category"
                        fields={[]}
                        state={this.state}
                        onSetState={(s) => this.setState(s)}
                        sectionId="appearance"
                        onUpdateResource={() => this.saveOrUpdateCategory()}
                        label={<span><AiOutlineEye/>&#xA0;Appearance</span>}
                    />

                    {this.state.sections.appearance &&
                    <Row>
                        <Form.Group as={Col} controlId="visibility">
                            <Label style={labelStyle}>Visibility</Label>
                            <FormSelect value={this.state.category.visibility} defaultValue=""
                                          onChange={(e) => this.setState(prevState => ({
                                              category: {
                                                  ...prevState.category,
                                                  visibility: e.target.value
                                              }
                                          }))}
                                          readOnly={!this.state.editMode.active}
                                          disabled={!this.state.editMode.active}>
                                <option>VISIBLE</option>
                                <option>HIDDEN</option>
                            </FormSelect>
                        </Form.Group>
                        <Form.Group as={Col} controlId="parentCategoryId">
                            <Label style={labelStyle}>Parent Category ID</Label>
                            <InputGroup className="mb-3">
                                <Form.Control
                                    value={this.state.category.parentCategoryId}
                                    readOnly={!this.state.editMode.active}/>

                                {this.state.editMode.active &&
                                    <Button variant="outline-secondary" type="button" onClick={() => {
                                        this.loadCategories().then(r => {
                                            this.setState(prevState => ({
                                                ...prevState,
                                                showParentCategoryDialog: true
                                            }));
                                        });
                                    }}>Pick</Button>
                                }
                            </InputGroup>
                        </Form.Group>
                        <Form.Group as={Col} controlId="parentCategoryName">
                            <Label style={labelStyle}>Parent Category Name</Label>
                            <InputGroup className="mb-3">
                                <Form.Control
                                    value={this.state.category.parentCategoryName}
                                    readOnly={!this.state.editMode.active}/>

                                {this.state.editMode.active &&
                                    <Button variant="outline-secondary" type="button" onClick={() => {
                                        this.loadCategories().then(r => {
                                            this.setState(prevState => ({
                                                ...prevState,
                                                showParentCategoryDialog: true
                                            }));
                                        });
                                    }}>Pick</Button>
                                }
                            </InputGroup>
                        </Form.Group>
                    </Row>
                    }

                    {/* ICONS */}
                    <DetailsSection
                        nameInState="category"
                        fields={[]}
                        state={this.state}
                        onSetState={(s) => this.setState(s)}
                        sectionId="icons"
                        label={<span><BsCardImage/>&#xA0;Icons</span>}
                    />

                    {this.state.sections.icons &&
                    <>
                        <button type="button" className="form-btn-ci" style={{marginBottom: "15px"}}
                                onClick={() => {
                                    this.selectedIcon = {};
                                    this.setState(prevState => ({
                                        ...prevState,
                                        showAssetDialog: true
                                    }));
                                }}>Add
                        </button>
                        <Table responsive bordered striped hover>
                            <thead>
                            <tr>
                                <th>Asset ID</th>
                                <th>Client ID</th>
                                <th>Width x Height</th>
                                <th>Resize Method</th>
                                <th>Preview</th>
                                <th>URL</th>
                                <th>Manage</th>
                            </tr>
                            </thead>
                            <tbody>
                            {this.state.icons.map(icon => (
                                <tr role="row" key={icon.id}>
                                    <td>
                                        <a style={{color: "#333", fontStyle: "italic"}}
                                           href={GlobalConstants.SPINE_CLIENT_HOST + GlobalConstants.APP_PATH_ASSET_DETAILS + icon.assetId}>{icon.assetId}</a>
                                    </td>
                                    <td>{icon.clientId}</td>
                                    <td>{icon.width} x {icon.height}</td>
                                    <td>{icon.resizeMethod}</td>
                                    <td>
                                        <img src={icon.url} alt={"Preview of asset " + icon.assetId}
                                             style={{width: "100px", height: "100px", cursor: "pointer"}}
                                             onClick={() => openExternalTargetInNewTab(icon.location.location)}/>
                                    </td>
                                    <td>{copyUrlToClipboardColumn(icon, this.props)}</td>
                                    <td>
                                        <Button variant="info" onClick={() => {
                                            this.selectedIcon = icon;
                                            this.setState(prevState => ({
                                                ...prevState,
                                                showAssetDialog: true
                                            }));
                                        }}><GrEdit/></Button>
                                        <span style={{margin: "5px"}}></span>

                                        <Button variant="danger" onClick={() => {
                                            this.selectedIcon = icon;
                                            this.setState(prevState => ({
                                                ...prevState,
                                                deleteAssetDialog: true
                                            }));
                                        }}><BsTrash/></Button>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </Table>
                    </>
                    }

                </div>
                <div className="details-button-box" style={{height: "70px"}}>
                    <Link to={{pathname: "/" + GlobalConstants.APP_PATH + "categories", state: this.state.flowState}}>
                        <button className="form-btn-ci-light-blue" type="button">Back</button>
                    </Link>
                    {this.state.editMode.active &&
                        <PromiseButton text="Save" onClick={() => this.saveOrUpdateCategory()} />
                    }
                </div>

                {/* DIALOGS */}

                <InfoModal show={this.state.showParentCategoryDialog}
                           onHide={() => this.setState(prevState => ({
                               ...prevState,
                               showParentCategoryDialog: false,
                               categoryFilterName: "",
                           }))}
                           title="Pick a category"
                           body={this.pickCategoryDialog()}/>

                <ActionModal show={this.state.deleteAssetDialog}
                             onHide={() => this.setState((prevState) => ({
                                 ...prevState,
                                 deleteAssetDialog: false
                             }))}
                             onAction={() => this.deleteAsset()}
                             actionButtonText="Delete"
                             title={"Delete Icon with asset ID " + this.selectedIcon.assetId}
                             body={"Are you sure you want to delete icon with asset ID " + this.selectedIcon.assetId + "?"}/>

                <ActionModal show={this.state.showAssetDialog}
                             onHide={() => this.setState((prevState) => ({
                                 ...prevState,
                                 showAssetDialog: false
                             }))}
                             onAction={() => this.saveOrUpdateAsset()}
                             actionButtonText="Save"
                             title={this.selectedIcon.assetId != null ? "Edit Icon with Asset ID " + this.selectedIcon.assetId : "Add an Icon"}
                             body={<AddOrEditIconDialog
                                 icon={this.selectedIcon}
                                 onUpdateIcon={(icon) => this.selectedIcon = icon}/>}/>
                <ErrorHandler error={this.state.error}
                              onHide={() => this.setState(prevState => ({...prevState, error: null}))} />
            </>
        );
    }

    //------------
    //API Methods
    //------------

    async saveOrUpdateCategory() {
        if (!RequiredFieldsAreValid("category", CategoryMetaData.DETAILS_GENERAL, this.state, (s) => this.setState(s))) {
            return;
        }
        let category = {};
        if(this.state.category.id != null) {
            category = await UpdateCategory(this.state.category);
        } else {
            category = await CreateCategory(this.state.category);
        }

        if (!category.error) {
            if (this.state.category.id == null) {
                this.props.history.push("/"+ GlobalConstants.APP_PATH + "categories/" + category.result.id);
            }
            this.setState((prevState) =>({...prevState, category: category.result, editMode: {...prevState.editMode, active: this.state.editMode.autoSave}}));

            this.props.addToast("The category has been updated successfully.", {
                autoDismiss: true,
                appearance: 'success'
            });
        } else {
            this.setState(prevState => ({...prevState, error: category}));
        }
    }

    async saveOrUpdateAsset()   {

        if(this.selectedIcon.assetId == null) {
            this.props.addToast("Please provide an asset Id.", {
                autoDismiss: true,
                appearance: 'error'
            });
        } else {

            let icons = this.state.category.icons;

            //Check if the icon is already in the category (by checking the asset Id)
            if(!this.state.category.icons.map(icon => icon.assetId).includes(this.selectedIcon.assetId)) {
                //Add the new icon
                icons.push(this.selectedIcon);
            } else {
                //Update the existing icon
                for(let i = 0; i < icons.length; i++) {
                    if(icons[i].assetId === this.selectedIcon.assetId) {
                        icons[i] = this.selectedIcon;
                    }
                }
            }

            //Update the category
            let category = this.state.category;
            category.icons = icons;

            let updatedCategory = await UpdateCategory(category);

            if(!updatedCategory.error){
                this.setState(prevState => ({...prevState, showAssetDialog: false, category: {...prevState.category, icons: icons}}));
            } else {
                this.setState(prevState => ({...prevState, error: updatedCategory}));
            }
        }

    }

    async deleteAsset(){

        let icons = this.state.category.icons;
        let category = this.state.category;

        icons = icons.filter(icon => icon.assetId !== this.selectedIcon.assetId);
        category.icons = icons;

        let updatedCategory = await UpdateCategory(category);

        if(!updatedCategory.error) {
            this.setState(prevState => ({...prevState, deleteAssetDialog: false, category: {...prevState.category, icons: icons}}));
        } else {
            this.setState(prevState => ({...prevState, error: updatedCategory}));
        }
    }

    //--------
    // DIALOGS
    //--------

    pickCategoryDialog() {
        return (
            <div>
                <Table striped bordered hover>
                    <thead>
                    <tr>
                        <th>ID</th>
                        <th>Name</th>
                        <th>Code</th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.state.categories.map(category => (
                        <tr role="row" key={category.id} style={{cursor: "pointer"}} onClick={() => {
                            this.setState(prevState => ({
                                ...prevState,
                                category: {
                                    ...prevState.category,
                                    parentCategoryId: category.id,
                                    parentCategoryName: category.name,
                                },
                                showParentCategoryDialog: false
                            }));
                        }}>
                            <td>{category.id}</td>
                            <td>{category.name}</td>
                            <td>{category.code}</td>
                        </tr>
                    ))}
                    </tbody>
                </Table>

                <Pagination>
                    <Pagination.Item hidden={this.state.categoryPage === 1}
                                     onClick={() => this.loadCategories(1)}>&lt;&lt;</Pagination.Item>
                    <Pagination.Item hidden={this.state.categoryPage === 1}
                                     onClick={() => this.loadCategories(this.state.categoryPage - 1)}>&lt;</Pagination.Item>
                    <Pagination.Item hidden={this.state.categoryPage <= 2}
                                     onClick={() => this.loadCategories(this.state.categoryPage - 2)}>{this.state.categoryPage - 2}</Pagination.Item>
                    <Pagination.Item hidden={this.state.categoryPage === 1}
                                     onClick={() => this.loadCategories(this.state.categoryPage - 1)}>{this.state.categoryPage - 1}</Pagination.Item>
                    <Pagination.Item active={true}>{this.state.categoryPage}</Pagination.Item>
                    <Pagination.Item
                        onClick={() => this.loadCategories(this.state.categoryPage + 1)}>{this.state.categoryPage + 1}</Pagination.Item>
                    <Pagination.Item
                        onClick={() => this.loadCategories(this.state.categoryPage + 2)}>{this.state.categoryPage + 2}</Pagination.Item>
                    <Pagination.Item
                        onClick={() => this.loadCategories(this.state.categoryPage + 1)}>&gt;</Pagination.Item>
                </Pagination>
            </div>
        )
    }

}


export default withToast(CategoryDetails);