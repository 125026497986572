import {
    CreateSpineResource,
    DeleteSpineResource,
    GetFilteredSpineResources,
    GetSpineResource,
    UpdateSpineResource
} from "../../clients/SpineClient";

//API METHODS
export async function CreateCluster(cluster) {
    return await CreateSpineResource('api/clusters', cluster);
}

export async function GetFilteredClusters(filter){
    return await GetFilteredSpineResources('api/clusters', filter);
}

export async function GetCluster(id) {
    return await GetSpineResource('api/clusters/' + id);
}

export async function UpdateCluster(cluster) {
    return await UpdateSpineResource('api/clusters/' + cluster.id, cluster);
}

export async function DeleteCluster(cluster) {
    return await DeleteSpineResource('api/clusters/' + cluster.id);
}

//---------- OPTIONAL -------------
/*
export async function PatchCluster(cluster, newValue) {
    return await PatchResource('api/clusters/' + cluster.id + '/patch', {attribute: newValue});
}
 */