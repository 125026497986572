const DEVICETYPELIST =
    [{value: 'STANDARDBOX', label: 'STANDARDBOX'},
    {value: 'PREMIUMBOX', label: 'PREMIUMBOX'},
    {value: 'MINIBOX', label: 'MINIBOX'}];

const COLORLIST =
    [{value: "BLUE", label: "Blue"},
    {value: "RED", label: "Red"},
    {value: "GREY", label: "Grey"},
    {value: "BLACK", label: "Black"},
    {value: "PURPLE", label: "Violet"},
    {value: "GREEN", label: "Green"},
    {value: "YELLOW", label: "Yellow"}];

const DevicesMetaData = {

    //Columns
    COLUMNS: [
        //Default & Badges
        {label: "ID", id: 'id', columnType: 'default', show: true},
        {label: "Identifier", id: 'deviceIdentifier', columnType: 'default', show: true},
        {label: "Account ID", id: 'accountId', columnType: 'default', show: true},
        {label: "Current Version", id: 'currentVersion', columnType: 'default', show: true},
        {label: "Farbe", id: 'color', columnType: 'badge', options: [
                {value: "BLUE", style: "box-blue", label: "Blue"},
                {value: "RED", style: "box-red", label: "Red"},
                {value: "GREY", style: "box-grey", label: "Grey"},
                {value: "BLACK", style: "box-black", label: "Black"},
                {value: "PURPLE", style: "box-purple", label: "Violet"},
                {value: "GREEN", style: "box-green", label: "Green"},
                {value: "YELLOW", style: "box-yellow", label: "Yellow"},
                {value: null, style: "box-white", label: ""},
            ]
            , show: true},
        {label: "Device Type", id: 'deviceType', columnType: 'default', show: true},
        {label: "Hardware Version", id: '_embedded.versions.short', columnType: 'default', show: true},
    ],

    //Details
    DETAILS_GENERAL: [
        [{label: 'ID', id: 'id', type: 'number', editable: 'never', isExpertField: false, show: true},
        {label: 'Device Identifier', id: 'deviceIdentifier', type: 'text', show: true},
        {label: 'Device Credential', id: 'device_credential', type: 'text', show: true}],

        [{label: 'Device Type', id: 'deviceType', type: 'select', labelValueOptions: true, options: DEVICETYPELIST, editable: 'true'},
        {label: 'Enabled', id: 'enabled', type: 'boolean', show: true},
        {label: 'Color', id: 'color', type: 'select', labelValueOptions: true, options: COLORLIST, editable: 'true'}],

        [{label: 'Current Version', id: 'currentVersion', type: 'text', editable: 'false', show: true},
        {label: 'Batch Number', id: 'batch_number', type: 'text', show: true}],
    ],

    ONBOARDING: [
        [{label: 'Current User Id', id: 'currentUserId', type: 'text', editable: 'true', show: true},
        {label: 'Display Name', id: 'displayName', type: 'text', editable: 'true', show: true}],

        [{label: 'First Onboarded Date ', id: 'first_onboarded_date ', type: 'date', editable: 'true', show: true},
        {label: 'First Onboarded Date Current Account', id: 'first_onboarded_date_current_account ', type: 'date', editable: 'true', show: true},
        {label: 'last Onboarded By ', id: 'lastModifiedDate', type: 'date', editable: 'true', show: true}],
    ],

    ADDITIONAL_INFORMATION: [
        [{label: 'ID', id: 'id', type: 'number', editable: 'never', isExpertField: false, show: true},
        {label: 'Device Id', id: 'deviceId', type: 'text', editable: 'never', show: true}],

        [{label: 'Remark', id: 'remark', type: 'textarea', editable: 'true', isExpertField: false, show: true},
        {label: 'Flags', id: 'flags', type: 'text', editable: 'true', show: true}],

        [{label: 'Created By', id: 'createdBy', type: 'number', editable: 'never', isExpertField: false, show: true},
        {label: 'Created Date', id: 'createdDate', type: 'date', editable: 'never', show: true}],

        [{label: 'Last Modified By', id: 'lastModifiedBy', type: 'number', editable: 'never', isExpertField: false, show: true},
        {label: 'Last Modified Date', id: 'lastModifiedDate', type: 'date', editable: 'never', isExpertField: false, show: true}],
    ],

    //Sorting
    DEVICES_SORT_PARAMS: [{id: 'id', label: 'Device ID'}]
};

export default DevicesMetaData;