const OrderFailureLogMetaData = {

    //Columns
    COLUMNS: [
        //Default & Badges
        {label: "ID", id: 'id', columnType: 'default', show: true},
        {label: "Reason", id: 'reason', columnType: 'badge', options: [
                {value: 'CONNECTION', style: "primary"},
                {value: 'PROVIDER_VALIDATION', style: "success"},
                {value: 'TIBA_VALIDATION', style: "warning"},
                {value: 'UNKNOWN', style: "danger"}
            ],
            show: true},
        {label: "Order ID", id: 'orderId', columnType: 'default', show: true},
        {label: "Failure Timestamp", id: 'failureTimestamp', columnType: 'default', show: true},
        {label: "Failure Stack Trace", id: 'failureStackTrace', columnType: 'default', show: false},
    ],

    //Details
    DETAILS_GENERAL: [
        [{label: 'ID', id: 'id', type: 'number', editable: 'never', isExpertField: false}, {label: 'Order ID', id: 'orderId', type: 'number', editable: 'never'}],
    ],
    FAILURE_DETAILS: [
        [{label: 'Reason', id: 'reason', type: 'text', editable: 'never'}, {label: 'Failure Timestamp', id: 'failureTimestamp', type: 'text', editable: 'never'}],
        [{label: 'Failure Stack Trace', id: 'failureStackTrace', type: 'textarea', rows: 50,editable: 'never'}]
    ],

    //Sorting
    ORDER_FAILURE_LOG_SORT_PARAMS: [],
};

export default OrderFailureLogMetaData;