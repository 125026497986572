import React, {Component} from "react";
import {Button, ButtonGroup, Col, Form, Pagination, Tab, Table, Tabs} from "react-bootstrap";
import Row from 'react-bootstrap/Row';
import {openDetailsInNewTab, transformStringToReadableDate} from "../../util/ResourceService";
import {ChangeEmail, DeleteAccount, GetAccount, UpdateAccount} from "../../resources/accounts/AccountService";
import {ActionModal, InfoModal} from "../../generators/ModalGenerator";
import {AccountSelectionByEmailDialog} from "./dialogs/AccountSelectionByEmailDialog";
import {GetFilteredSubscriptions, UpdateSubscription} from "../../resources/subscriptions/SubscriptionService";
import {
    generateWildcardListItem,
    getPremiumTypeBadge,
    getPresentTypeBadge,
    getSubscriptionSourceBadge,
    getSubscriptionTypeBadge,
    getTicketEdition,
    getTicketTypeBadge,
    isSubscription,
    isSwiss,
    isTigertones,
    presentIsActive,
    sortByParamDesc,
    sortTicketListBy,
    SupportToolMetaData,
    ticketIsActive
} from "./SupportToolHelper";
import {
    AiOutlineArrowDown,
    AiOutlineArrowUp,
    AiOutlineMail,
    AiOutlinePauseCircle,
    AiOutlineUnlock,
    BiChevronLeft,
    BiChevronRight,
    BiChevronsLeft,
    BiChevronsRight,
    BiHeadphone,
    BiSearchAlt,
    BsBook,
    BsCheck2,
    BsPencil,
    CgSwiss,
    FcApproval,
    FcCalendar,
    FcCheckmark,
    FcClock,
    FcCloseUpMode,
    FcCollapse,
    FcComboChart,
    FcConferenceCall,
    FcCurrencyExchange,
    FcDiploma1,
    FcExpand,
    FcExpired,
    FcInfo,
    FcIphone,
    FcKey,
    FcLink,
    FcList,
    FcMusic,
    FcOk,
    FcRating,
    FcRefresh,
    FcSearch,
    FcSelfServiceKiosk,
    FcShop,
    FcStatistics,
    FcSupport,
    GiPresent,
    GiProcessor,
    IoNewspaperOutline,
    IoTicketOutline,
    RiDeleteBin6Line,
    RiSave3Fill,
    TiCancelOutline
} from "react-icons/all";
import {ChangeEmailDialog} from "./dialogs/ChangeEmailDialog";
import {ChangePasswordDialog} from "./dialogs/ChangePasswordDialog";
import {DTTSingleGeneratorDialog} from "./dialogs/DTTSingleGeneratorDialog";
import {PromiseButton, SpinningTiger} from "../../global/SpinningTiger";
import {
    GetAccountDevices,
    GetAccountGeneratedContentItems,
    GetAccountTickets,
    GetAccountWildcards,
    GetExtendedCustomerTicketInfos,
    GetFilteredDeviceSupplements,
    GetNewsletterListsByEmail,
    GetSupportPresentsOfAccount,
    GetUploadInformationOfWildcards,
    GetWildcardByCode,
    GetWildcardCover,
    HasSubscribedToRecommendations,
    LoadAccountProfiles,
    PerformSwissUpgrade,
    RedeemTicket,
    ResetTicket
} from "./SupportToolService";
import {RedeemTicketDialog} from "./dialogs/RedeemTicketDialog";
import background from "./../../../assets/images/support_tool_bg.jpeg";
import tiger_logo from "./../../../assets/images/loader_bg.png";
import {ManageNewsletterDialog} from "./dialogs/ManageNewsletterDialog";
import {CreateSubscriptionDialog} from "./dialogs/CreateSubscriptionDialog";
import {CreatePremiumAccessDialog} from "./dialogs/CreatePremiumAccessDialog";
import GlobalConstants from "../../config/GlobalConstants";
import {TransferTicketDialog} from "./dialogs/TransferTicketDialog";
import {BoxesTab} from "./components/BoxesTab";
import {
    GetBillwerkCustomer,
    GetExtendedBillwerkCustomerDetails
} from "../../billwerk/customers/BillwerkCustomerService";
import {BillwerkTab} from "./components/BillwerkTab";
import {DTTMassGeneratorDialog} from "./dialogs/DTTMassGeneratorDialog";
import {AccountSelectionByCodeDialog} from "./dialogs/AccountSelectionByCodeDialog";
import {Badge} from "reactstrap";
import {ProfilesTab} from "./components/ProfilesTab";
import {withToast} from "../../util/ToastService";

class SupportTool extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            products: [],
            productGroups: [],

            //General
            sortDirection: "ASC",
            sortBy: null,
            filter: {
                email: "",
                accountId: "",
                ticketCode: "",
                wildcardCode: "",
            },
            accountsByEmail: [],
            accountsByTicket: [],
            account: {},
            filterTicket: null,
            isDeletedAccount: false,
            restoreEmail: null,
            customer: null,

            //Zugänge
            selectedSubscription: null,
            activeAccess: null,
            subscriptions: [],
            subscriptionCount: 0,
            showActiveOnly: true,
            accountTickets: [],
            supportPresents: [],
            selectedTicket: null,
            subscriptionPage: 1,
            lastSubscriptionPage: 1,

            //Profiles
            profiles: [],

            //Settings
            deactivateWhenReset: true,
            redeemAutomatically: false,

            //Newsletter
            newsletter: [],
            subscribedToRecommendations: false,

            //Billwerk
            billwerkCustomer: {},

            //Boxen
            devices: [],
            selectedDevice: {},
            remark: "",
            boxId: "",
            boxUpdate: "",

            //Wildcards
            wildcards: [],
            filteredWildcards: [],
            showWildcardItems: false,
            wildcardItems: [],

            //Tabs
            mainTab: 'premium',
            premiumTab: 'overview',

            //Modals
            showRestoreAccountModal: false,
            showChangeEmailModal: false,
            showChangePasswordModal: false,
            showDeleteAccountModal: false,
            showGenerateDTTModal: false,
            showGenerateMultiDTTModal: false,
            showRedeemedTicketModal: false,
            showUploadInformationDialog: false,
            showTranscodeInformationDialog: false,
            showManualRedemptionDialog: false,
            showResetTicketDialog: false,
            showHelpDialog: false,
            showNewsletterModal: false,
            showGenerateSubscriptionModal: false,
            showGeneratePremiumAccessModal: false,
            showTransferTicketModal: false,
            showSwissUpgradeModal: false,

            //Appearance
            showToolsBox: false,
            showSearchBox: true,
            showCustomerBox: true,
            showInfosBox: true,

            //Filter
            showActive: true,
            showSaveAccount: false,

            //Search
            searchCode: "",
        };

        this.updateFilter = this.updateFilter.bind(this);
        this.resetFilter = this.resetFilter.bind(this);
        this.updateState = this.updateState.bind(this);
    }

    async componentDidMount() {
        document.title = "Support :: Tiger UI";
    }

    render() {

        const labelStyle = {
            float: "left",
            fontSize: "20px",
            marginRight: "15px",
            marginTop: "10px",
            width: "250px"
        }

        return (
            <div style={{paddingTop: 30, backgroundImage: `url(${background})`, minHeight: "100vh", height: "auto"}}>
                <div className="support-tool-header">
                    <div className="support-tool-header-title">Support</div>
                </div>

                {/* ACCOUNT-SUCHE */}
                <div className="support-tool-box-title"
                     onClick={() => this.updateState('showToolsBox', !this.state.showToolsBox)}><h4>
                    <FcSupport/> Allgemeine Tools<span
                    style={{float: "right", marginRight: "10px"}}>{this.state.showToolsBox ?
                    <FcCollapse/> : <FcExpand/>}</span></h4></div>
                {this.state.showToolsBox &&
                <div className="support-tool-box" style={{height: "80px"}}>
                    <button className="form-btn-ci-blue" type="button"
                            onClick={() => {
                                this.updateState('account', {});
                                this.updateState('showGenerateDTTModal', true);
                            }}><IoTicketOutline/> Einzelnes DTT generieren
                    </button>
                    <button className="form-btn-ci-blue" type="button"
                            onClick={() => {
                                this.updateState('account', {});
                                this.updateState('showGenerateMultiDTTModal', true);
                            }}><IoTicketOutline/><IoTicketOutline/> Mehrere DTT generieren
                    </button>
                </div>
                }

                {/* ACCOUNT-SUCHE */}
                <div className="support-tool-box-title"
                     onClick={() => this.updateState('showSearchBox', !this.state.showSearchBox)}><h4>
                    <FcSearch/> Konto-Suche<span
                    style={{float: "right", marginRight: "10px"}}>{this.state.showSearchBox ?
                    <FcCollapse/> : <FcExpand/>}</span></h4></div>
                {this.state.showSearchBox &&
                <div className="support-tool-box">
                    <form>
                        <div className="row">
                            <div className="col-md-3">
                                <input type="text"
                                       value={this.state.filter.email}
                                       onChange={(e) => this.updateFilter("email", e.target.value)}
                                       placeholder="E-Mail"/>
                            </div>
                            <div className="col-md-3">
                                <input type="text"
                                       value={this.state.filter.ticketCode}
                                       onChange={(e) => this.updateFilter("ticketCode", e.target.value)}
                                       placeholder="Zugangscode"/>
                            </div>
                            <div className="col-md-3">
                                <input type="text"
                                       value={this.state.filter.wildcardCode}
                                       onChange={(e) => this.updateFilter("wildcardCode", e.target.value)}
                                       placeholder="wildcard-Code"/>
                            </div>
                        </div>
                        <hr/>
                        <div className="row">
                            <div className="col-md-3" style={{marginLeft: "auto"}}>
                                <button className="form-btn-ci-light" type="button"
                                        onClick={() => this.resetFilter()}>Reset
                                </button>
                                <button className="form-btn-ci-blue" type="submit"
                                        onClick={(e) => this.applyFilter(e, this.state.filter)}><BiSearchAlt
                                    size={22}/> Suche
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
                }

                {/* KUNDE */}
                <div className="support-tool-box-title"
                     onClick={() => this.updateState('showCustomerBox', !this.state.showCustomerBox)}><h4>
                    <FcConferenceCall/> Kunde<span
                    style={{float: "right", marginRight: "10px"}}>{this.state.showCustomerBox ? <FcCollapse/> :
                    <FcExpand/>}</span></h4></div>
                {this.state.showCustomerBox &&
                <div className="support-tool-box">
                    {/* GLOBAL LOADING */}
                    {this.state.account.id == null ?
                        <>
                            {this.state.loading ?
                                <SpinningTiger/>
                                :
                                <div style={{
                                    marginLeft: "auto",
                                    marginRight: "auto",
                                    paddingTop: "25px",
                                    height: "180px",
                                    width: "300px",
                                    textAlign: "center",
                                    fontWeight: "bold",
                                    fontSize: "20px"
                                }}>
                                    <img src={tiger_logo} width={100} alt={"tigermedia tiger logo"}/><br/>
                                    Kein Konto geladen</div>
                            }
                        </>
                        :
                        <>
                            <p style={{marginBottom: "40px", height: "60px"}}>
                                <button className="form-btn-ci-blue" style={{marginRight: "10px"}}
                                        onClick={() => this.updateState('showChangeEmailModal', true)}
                                ><AiOutlineMail size={22}/> E-Mail-Adresse
                                    ändern
                                </button>
                                <button className="form-btn-ci-light-blue" style={{marginRight: "10px"}}
                                        onClick={() => this.updateState('showChangePasswordModal', true)}>
                                    <AiOutlineUnlock size={22}/> Passwort
                                    ändern
                                </button>
                                <button className="form-btn-ci-light"
                                        onClick={() => this.updateState('showNewsletterModal', true)}>
                                    <IoNewspaperOutline size={22}/> Newsletter verwalten
                                </button>
                                <div style={{float: "right"}}>
                                    {this.state.showSaveAccount &&
                                    <button className="form-btn-ci-green" onClick={() => this.updateAccount()}>
                                        <RiSave3Fill size={22}/> Änderungen speichern
                                    </button>
                                    }
                                    {/**
                                    <button
                                        className={this.state.isDeletedAccount ? "form-btn-ci-light-blue" : "form-btn-ci-off"}
                                        disabled={!this.state.isDeletedAccount}
                                        onClick={() => {
                                            if (this.state.customer != null && this.state.customer.EmailAddress != null) {
                                                this.updateState('restoreEmail', this.state.customer.EmailAddress.replace("deleted_", ""));
                                            }
                                            this.updateState('showRestoreAccountModal', true);
                                        }}>
                                        <FaTrashRestore size={22}/> Account wiederherstellen
                                    </button>**/}
                                    <button
                                        className={this.state.isDeletedAccount ? "form-btn-ci-off" : "form-btn-ci-red"}
                                        disabled={this.state.isDeletedAccount}
                                        onClick={() => this.updateState('showDeleteAccountModal', true)}>
                                        <RiDeleteBin6Line size={22}/> Konto löschen
                                    </button>
                                </div>
                            </p>

                            <Form style={{marginTop: "10px"}}>
                                <Row>
                                    <Form.Group as={Col}>
                                        <div style={{...labelStyle, paddingLeft: "100px"}}>E-Mail</div>
                                        <Form.Control style={{width: "400px"}} type="text"
                                                      value={this.state.account.email} disabled={true}/>
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <div style={labelStyle}>Software-Version Box</div>
                                        <Form.Control style={{width: "300px"}} type="text" value={this.state.boxUpdate}
                                                      disabled={true}/>
                                    </Form.Group>
                                </Row>
                                <Row>
                                    <Form.Group as={Col}>
                                        <div style={{...labelStyle, paddingLeft: "100px"}}>Nachname</div>
                                        <Form.Control style={{width: "400px"}} type="text"
                                                      value={this.state.account.lastName}
                                                      onChange={(e) => this.setState(prevState => ({
                                                          ...prevState,
                                                          showSaveAccount: true,
                                                          account: {...prevState.account, lastName: e.target.value}
                                                      }))}/>
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <div style={labelStyle}>tigertones-Version</div>
                                        <Form.Control style={{width: "300px"}} type="text" disabled={true}/>
                                    </Form.Group>
                                </Row>
                                <Row>
                                    <Form.Group as={Col}>
                                        <div style={{...labelStyle, paddingLeft: "100px"}}>Vorname</div>
                                        <Form.Control style={{width: "400px"}} type="text"
                                                      value={this.state.account.firstName}
                                                      onChange={(e) => this.setState(prevState => ({
                                                          ...prevState,
                                                          showSaveAccount: true,
                                                          account: {...prevState.account, firstName: e.target.value}
                                                      }))}/>
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <div style={labelStyle}>Geräte-ID</div>
                                        <Form.Control style={{width: "300px"}} type="text" value={this.state.boxId}
                                                      disabled={true}/>
                                    </Form.Group>
                                </Row>
                                <Row>
                                    <Form.Group as={Col}>
                                        <div style={{...labelStyle, paddingLeft: "100px"}}>Zugang</div>
                                        <Badge color={this.state.activeAccess != null ? "success" : "danger"} style={{
                                            fontSize: "16px",
                                            marginTop: "12px"
                                        }}>{this.state.activeAccess != null ? "Ja" : "Nein"}</Badge>
                                        <span style={{
                                            marginLeft: "10px",
                                            fontSize: "20px"
                                        }}>{this.state.activeAccess != null && "- gültig bis " + transformStringToReadableDate(this.state.activeAccess.validUntil)}</span>
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <div style={labelStyle}>Newsletter</div>
                                        <Badge color="secondary"
                                               style={{fontSize: "16px", marginTop: "12px"}}>tigertipps</Badge>
                                        <Badge
                                            color={this.state.newsletter.indexOf(GlobalConstants.MAILJET_TIGERTIPPS_LIST_ID) > -1 ? "success" : "danger"}
                                            style={{
                                                fontSize: "16px", marginTop: "12px"
                                            }}>{this.state.newsletter.indexOf(GlobalConstants.MAILJET_TIGERTIPPS_LIST_ID) > -1 ? "Ja" : "Nein"}</Badge>
                                        <Badge color="secondary" style={{
                                            fontSize: "16px",
                                            marginTop: "12px",
                                            marginLeft: "20px"
                                        }}>tigerpost</Badge>
                                        <Badge
                                            color={this.state.newsletter.indexOf(GlobalConstants.MAILJET_TIGERPOST_LIST_ID) > -1 ? "success" : "danger"}
                                            style={{
                                                fontSize: "16px", marginTop: "12px"
                                            }}>{this.state.newsletter.indexOf(GlobalConstants.MAILJET_TIGERPOST_LIST_ID) > -1 ? "Ja" : "Nein"}</Badge>
                                        <Badge color="secondary" style={{
                                            fontSize: "16px",
                                            marginTop: "12px",
                                            marginLeft: "20px"
                                        }}>Content-News</Badge>
                                        <Badge color={this.state.subscribedToRecommendations ? "success" : "danger"}
                                               style={{
                                                   fontSize: "16px", marginTop: "12px"
                                               }}>{this.state.subscribedToRecommendations ? "Ja" : "Nein"}</Badge>
                                    </Form.Group>
                                </Row>
                                <Row>
                                    <Form.Group as={Col}>
                                        <div style={{...labelStyle, paddingLeft: "100px"}}>Swiss-Zugang</div>
                                        <Badge color={isSwiss(this.state.activeAccess) ? "success" : "danger"} style={{
                                            fontSize: "16px",
                                            marginTop: "12px"
                                        }}>{isSwiss(this.state.activeAccess) ? "Ja" : "Nein"}</Badge>
                                    </Form.Group>
                                </Row>
                            </Form>
                        </>
                    }

                </div>
                }

                {/* INFOS */}
                {this.state.account.id != null &&
                <>
                    <div className="support-tool-box-title"
                         onClick={() => this.updateState('showInfosBox', !this.state.showInfosBox)}><h4>
                        <FcComboChart/> Infos<span
                        style={{float: "right", marginRight: "10px"}}>{this.state.showInfosBox ? <FcCollapse/> :
                        <FcExpand/>}</span></h4></div>
                    {this.state.showInfosBox &&
                    <div className="support-tool-box">
                        <Tabs id="support-tool-mainTabs" activeKey={this.state.mainTab}
                              onSelect={(k) => {
                                  this.updateState('mainTab', k);
                                  //Load the billwerk customer if the billwerk tab is selected
                                  if (k === 'billwerk') {
                                      this.loadBillwerkCustomer().then(r => r);
                                  }
                              }}
                              className="mb-5">

                            {/* PREMIUM */}
                            <Tab eventKey="premium"
                                 title={<span style={{color: "#495057"}}><FcApproval size={24}/> Zugänge</span>}>

                                {this.state.loading ?
                                    <SpinningTiger/>
                                    :
                                    <>
                                        <div style={{padding: "10px", height: "60px"}}>
                                            <button className="form-btn-ci-blue" style={{marginRight: "10px"}}
                                                    onClick={() => this.updateState('showGenerateDTTModal', true)}>
                                                <GiProcessor size={22}/> Neues Ticket generieren
                                            </button>
                                            <button
                                                className={isSubscription(this.state.activeAccess) ? "form-btn-ci-off" : "form-btn-ci-light-blue"}
                                                disabled={isSubscription(this.state.activeAccess)}
                                                onClick={() => this.updateState('showManualRedemptionDialog', true)}>
                                                <IoTicketOutline size={22}/> Existierendes Ticket einlösen
                                            </button>
                                            <button
                                                className={isSubscription(this.state.activeAccess) ? "form-btn-ci-off" : "form-btn-ci-green"}
                                                style={{marginLeft: "10px"}}
                                                disabled={isSubscription(this.state.activeAccess)}
                                                onClick={() => this.updateState('showGeneratePremiumAccessModal', true)}>
                                                <GiPresent size={22}/> Zugang schenken
                                            </button>
                                            <button
                                                className={isSwiss(this.state.activeAccess) ? "form-btn-ci-off" : "form-btn-ci-green"}
                                                style={{marginLeft: "10px"}}
                                                disabled={isSwiss(this.state.activeAccess)}
                                                onClick={() => this.updateState('showSwissUpgradeModal', true)}>
                                                <CgSwiss size={22}/> Swiss Upgrade durchführen
                                            </button>
                                            <span style={{float: "right", marginRight: "40px"}}>
                                                <span className="support-tool-box-help-button"
                                                      onClick={() => this.updateState('showHelpDialog', true)}>
                                                    Was wird hier angezeigt? <FcInfo size={24}/>
                                                </span>
                                            </span>
                                        </div>

                                        <div className="support-tool-box">
                                            <Tabs id="support-tool-premiumTabs" activeKey={this.state.premiumTab}
                                                  onSelect={(k) => {
                                                      this.updateState('premiumTab', k);
                                                      this.updateState('subscriptionPage', 1);
                                                      if (k === "overview") {
                                                          this.updateState('loading', true);
                                                          this.loadSubscriptions({page: 1}).then(r => {
                                                              this.updateState('loading', false);
                                                          });
                                                          this.updateState('showActiveOnly', true);
                                                      } else if (k === 'subscriptions') {
                                                          this.updateState('loading', true);
                                                          this.loadSubscriptions({
                                                              page: 1,
                                                              articleNotLike: 'tigerticket'
                                                          }).then(r => {
                                                              this.updateState('loading', false);
                                                          });
                                                      } else if (k === 'tickets') {
                                                          this.updateState('loading', true);
                                                          this.loadAccountTickets().then(r => this.updateState('loading', false));
                                                      } else if (k === 'presents') {
                                                          this.updateState('loading', true);
                                                          this.loadSupportPresents().then(r => {
                                                              this.updateState('loading', false);
                                                          });
                                                      }
                                                  }}
                                                  className="mb-3">
                                                <Tab eventKey="overview"
                                                     title={<span style={{color: "#495057"}}><FcStatistics size={24}/> Übersicht / Subscriptions</span>}>
                                                    {this.state.subscriptions.length > 0 &&
                                                    <>
                                                        <Table bordered striped responsive hover>
                                                            <thead>
                                                            <tr>
                                                                <th style={{width: "50px"}}>Aktiv</th>
                                                                <th><FcRating size={20}/>&#xA0;&#xA0;Art</th>
                                                                <th><FcIphone size={20}/>&#xA0;&#xA0;Für</th>
                                                                <th><CgSwiss style={{color: "red"}}
                                                                             size={20}/>&#xA0;&#xA0;Swiss-Zugang
                                                                </th>
                                                                <th><FcCalendar size={20}/>&#xA0;&#xA0;gültig ab</th>
                                                                <th><FcExpired size={20}/>&#xA0;&#xA0;gültig bis</th>
                                                                <th style={{width: "500px"}}><FcInfo size={20}/>&#xA0;&#xA0;Notiz</th>
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            {this.state.subscriptionPage === 1 && this.state.activeAccess != null &&
                                                            <tr role="row" key={this.state.activeAccess.id}
                                                                style={{height: "80px"}}>
                                                                <td><FcOk size={24}/></td>
                                                                <td>{getPremiumTypeBadge(this.state.activeAccess)}</td>
                                                                <td>
                                                                    <Badge
                                                                        color={isTigertones(this.state.activeAccess) ? 'info' : 'light'}
                                                                        pill>
                                                                        {isTigertones(this.state.activeAccess) ?
                                                                            <BiHeadphone size={20}/> : <BsBook
                                                                                size={20}/>} {isTigertones(this.state.activeAccess) ? "tigertones" : "tigerbooks"}
                                                                    </Badge>
                                                                </td>
                                                                <td><Badge
                                                                    color={isSwiss(this.state.activeAccess) ? "success" : "danger"}
                                                                    pill>{isSwiss(this.state.activeAccess) ? "Ja" : "Nein"}</Badge>
                                                                </td>
                                                                <td>{transformStringToReadableDate(this.state.activeAccess.validFrom)}</td>
                                                                <td>{transformStringToReadableDate(this.state.activeAccess.validUntil)}</td>
                                                                <td>{this.subscriptionNoteField(this.state.activeAccess)}</td>
                                                            </tr>
                                                            }
                                                            {!this.state.showActiveOnly && this.state.subscriptions.filter(s => s.active === false).map(s => (
                                                                <tr role="row" key={s.id} style={{height: "80px"}}>
                                                                    <td></td>
                                                                    <td>{getPremiumTypeBadge(s)}</td>
                                                                    <td>
                                                                        <Badge
                                                                            color={isTigertones(s) ? 'info' : 'light'}
                                                                            pill>
                                                                            {isTigertones(s) ?
                                                                                <BiHeadphone size={20}/> : <BsBook
                                                                                    size={20}/>} {isTigertones(s) ? "tigertones" : "tigerbooks"}
                                                                        </Badge>
                                                                    </td>
                                                                    <td><Badge color={isSwiss(s) ? "success" : "danger"}
                                                                               pill>{isSwiss(s) ? "Ja" : "Nein"}</Badge>
                                                                    </td>
                                                                    <td>{transformStringToReadableDate(s.validFrom)}</td>
                                                                    <td>{transformStringToReadableDate(s.validUntil)}</td>
                                                                    <td>{this.subscriptionNoteField(s)}</td>
                                                                </tr>
                                                            ))}
                                                            </tbody>
                                                        </Table>
                                                        {this.state.showActiveOnly &&
                                                        <div style={{textAlign: "center", cursor: "pointer"}}
                                                             onClick={() => this.updateState('showActiveOnly', false)}>
                                                                    <span style={{
                                                                        width: "100%",
                                                                        textAlign: "center",
                                                                        fontSize: "20px",
                                                                        fontWeight: "bold"
                                                                    }}>Alle anzeigen</span><br/>
                                                            <FcExpand size={26}/>
                                                        </div>
                                                        }
                                                        {!this.state.showActiveOnly && this.state.activeAccess != null &&
                                                        <div style={{textAlign: "center", cursor: "pointer"}}
                                                             onClick={() => this.loadSubscriptions({page: 1}).then(r => this.updateState('showActiveOnly', true))}>
                                                            <FcCollapse size={26}/><br/>
                                                            <span style={{
                                                                width: "100%",
                                                                textAlign: "center",
                                                                fontSize: "20px",
                                                                fontWeight: "bold"
                                                            }}>Nur aktiven Zugang anzeigen</span>

                                                        </div>
                                                        }
                                                        {!this.state.showActiveOnly &&
                                                        <Pagination>
                                                            <Pagination.Item
                                                                hidden={this.state.subscriptionPage === 1}
                                                                onClick={() => this.loadSubscriptions({page: 1})}><BiChevronsLeft
                                                                size={16}/></Pagination.Item>
                                                            <Pagination.Item
                                                                hidden={this.state.subscriptionPage === 1}
                                                                onClick={() => this.loadSubscriptions({page: this.state.subscriptionPage - 1})}><BiChevronLeft
                                                                size={16}/></Pagination.Item>
                                                            <Pagination.Item
                                                                hidden={this.state.subscriptionPage <= 2}
                                                                onClick={() => this.loadSubscriptions({page: this.state.subscriptionPage - 2})}>{this.state.subscriptionPage - 2}</Pagination.Item>
                                                            <Pagination.Item
                                                                hidden={this.state.subscriptionPage === 1}
                                                                onClick={() => this.loadSubscriptions({page: this.state.subscriptionPage - 1})}>{this.state.subscriptionPage - 1}</Pagination.Item>
                                                            <Pagination.Item
                                                                active={true}>{this.state.subscriptionPage}</Pagination.Item>
                                                            <Pagination.Item
                                                                hidden={this.state.subscriptionPage === this.state.lastSubscriptionPage}
                                                                onClick={() => this.loadSubscriptions({page: this.state.subscriptionPage + 1})}>{this.state.subscriptionPage + 1}</Pagination.Item>
                                                            <Pagination.Item
                                                                hidden={this.state.subscriptionPage >= this.state.lastSubscriptionPage - 1}
                                                                onClick={() => this.loadSubscriptions({page: this.state.subscriptionPage + 2})}>{this.state.subscriptionPage + 2}</Pagination.Item>
                                                            <Pagination.Item
                                                                hidden={this.state.subscriptionPage === this.state.lastSubscriptionPage}
                                                                onClick={() => this.loadSubscriptions({page: this.state.subscriptionPage + 1})}><BiChevronRight
                                                                size={16}/></Pagination.Item>
                                                            <Pagination.Item
                                                                style={{height: "50px"}}
                                                                hidden={this.state.subscriptionPage === this.state.lastSubscriptionPage}
                                                                onClick={() => this.loadSubscriptions({page: this.state.lastSubscriptionPage})}><BiChevronsRight
                                                                size={16}/></Pagination.Item>
                                                        </Pagination>
                                                        }
                                                    </>
                                                    }
                                                    {this.state.subscriptions.length === 0 &&
                                                    <div style={{textAlign: "center", fontSize: "22px"}}>
                                                        Dieses Konto hat keine Zugänge
                                                    </div>
                                                    }
                                                </Tab>
                                                <Tab eventKey="tickets"
                                                     title={<span style={{color: "#495057"}}><FcDiploma1 size={24}/> Tickets</span>}>
                                                    {this.state.accountTickets.length > 0 ?
                                                        <>
                                                            <Table bordered striped responsive hover>
                                                                <thead>
                                                                <tr>
                                                                    <th style={{width: "50px"}}>Aktiv</th>
                                                                    <th style={{cursor: "pointer"}}
                                                                        onClick={() => this.applyTicketSort('used')}>{this.getSortSymbol('used')}&#xA0;&#xA0;
                                                                        <AiOutlinePauseCircle
                                                                            size={20}/>&#xA0;&#xA0;Eingelöst
                                                                    </th>
                                                                    <th style={{cursor: "pointer"}}
                                                                        onClick={() => this.applyTicketSort('type')}>{this.getSortSymbol('type')}&#xA0;&#xA0;
                                                                        <FcRating size={20}/>&#xA0;&#xA0;Art
                                                                    </th>
                                                                    <th style={{cursor: "pointer"}}
                                                                        onClick={() => this.applyTicketSort('swiss')}>{this.getSortSymbol('swiss')}&#xA0;&#xA0;
                                                                        <CgSwiss style={{color: "red"}}
                                                                                 size={20}/>&#xA0;&#xA0;Swiss-Zugang
                                                                    </th>
                                                                    <th><FcClock size={20}/>&#xA0;&#xA0;Laufzeit</th>
                                                                    <th><FcKey size={20}/>&#xA0;&#xA0;Zugangscode</th>
                                                                    <th style={{cursor: "pointer"}}
                                                                        onClick={() => this.applyTicketSort('reserved')}>{this.getSortSymbol('reserved')}&#xA0;&#xA0;
                                                                        <FcCalendar size={20}/>&#xA0;&#xA0;Aktiviert am
                                                                    </th>
                                                                    <th style={{cursor: "pointer"}}
                                                                        onClick={() => this.applyTicketSort('phaseStart')}>{this.getSortSymbol('phaseStart')}&#xA0;&#xA0;
                                                                        <FcCalendar size={20}/>&#xA0;&#xA0;Startet am
                                                                    </th>
                                                                    <th style={{cursor: "pointer"}}
                                                                        onClick={() => this.applyTicketSort('phaseEnd')}>{this.getSortSymbol('phaseEnd')}&#xA0;&#xA0;
                                                                        <FcExpired size={20}/>&#xA0;&#xA0;Endet am
                                                                    </th>
                                                                    <th><FcSupport size={20}/>&#xA0;&#xA0;Tools</th>
                                                                </tr>
                                                                </thead>
                                                                <tbody>
                                                                {this.state.accountTickets.map(t => (
                                                                    <tr role="row" key={t.ticket.code}
                                                                        style={{height: "80px"}}>
                                                                        <td>{ticketIsActive(t) ?
                                                                            <FcCheckmark size={24}/> : " "}</td>
                                                                        <td><Badge
                                                                            color={t.ticket.used === true ? "danger" : "success"}
                                                                            pill>{t.ticket.used ? "Ja" : "Nein"}</Badge>
                                                                        </td>
                                                                        <td>{getTicketTypeBadge(t)}</td>
                                                                        <td><Badge
                                                                            color={getTicketEdition(t) === "SWISS" ? "success" : "danger"}
                                                                            pill>{getTicketEdition(t) === "SWISS" ? "Ja" : "Nein"}</Badge>
                                                                        </td>
                                                                        <td>{(t.ticket != null && t.ticket.coupon != null) ? t.ticket.coupon.premiumAccessDuration : ""}</td>
                                                                        <td>{t.ticket != null ? t.ticket.code : ""}</td>
                                                                        <td>{t.ticket.used ? transformStringToReadableDate(t.ticket.reserved) : ""}</td>
                                                                        <td>{t.ticket.used ? transformStringToReadableDate(t.phaseStart) : ""}</td>
                                                                        <td>{t.ticket.used ? transformStringToReadableDate(t.phaseEnd) : ""}</td>
                                                                        <td>{this.ticketToolColumn(t)}</td>
                                                                    </tr>
                                                                ))}
                                                                </tbody>
                                                            </Table>
                                                        </>
                                                        :
                                                        <div style={{textAlign: "center", fontSize: "22px"}}>
                                                            Dieses Konto hat keine Tickets
                                                        </div>
                                                    }
                                                </Tab>
                                                <Tab eventKey="subscriptions"
                                                     title={<span style={{color: "#495057"}}><FcRefresh size={24}/> Abos</span>}>
                                                    {this.state.subscriptions.length > 0 &&
                                                    <>
                                                        <Table bordered striped responsive hover>
                                                            <thead>
                                                            <tr>
                                                                <th style={{width: "50px"}}>Aktiv</th>
                                                                <th><FcShop size={20}/>&#xA0;&#xA0;Abgeschlossen bei</th>
                                                                <th><FcRating size={20}/>&#xA0;&#xA0;Typ</th>
                                                                <th><FcIphone size={20}/>&#xA0;&#xA0;Für</th>
                                                                <th><FcCalendar size={20}/>&#xA0;&#xA0;gültig ab</th>
                                                                <th><FcExpired size={20}/>&#xA0;&#xA0;gültig bis</th>
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            {this.state.subscriptions.map(s => (
                                                                <tr role="row" key={s.id} style={{height: "80px"}}>
                                                                    <td>{s.active ? <FcOk size={24}/> : ""}</td>
                                                                    <td>{getSubscriptionSourceBadge(s)}</td>
                                                                    <td>{getSubscriptionTypeBadge(s)}</td>
                                                                    <td>
                                                                        <Badge
                                                                            color={isTigertones(s) ? 'info' : 'light'}
                                                                            pill>
                                                                            {isTigertones(s) ?
                                                                                <BiHeadphone size={20}/> : <BsBook
                                                                                    size={20}/>} {isTigertones(s) ? " tigertones" : " tigerbooks"}
                                                                        </Badge>
                                                                    </td>
                                                                    <td>{transformStringToReadableDate(s.validFrom)}</td>
                                                                    <td>{transformStringToReadableDate(s.validUntil)}</td>
                                                                </tr>
                                                            ))}
                                                            </tbody>
                                                        </Table>
                                                        <Pagination>
                                                            <Pagination.Item
                                                                hidden={this.state.subscriptionPage === 1}
                                                                onClick={() => this.loadSubscriptions({
                                                                    page: 1,
                                                                    articleNotLike: 'tigerticket'
                                                                })}><BiChevronsLeft size={16}/></Pagination.Item>
                                                            <Pagination.Item
                                                                hidden={this.state.subscriptionPage === 1}
                                                                onClick={() => this.loadSubscriptions({
                                                                    page: this.state.subscriptionPage - 1,
                                                                    articleNotLike: 'tigerticket'
                                                                })}><BiChevronLeft size={16}/></Pagination.Item>
                                                            <Pagination.Item
                                                                hidden={this.state.subscriptionPage <= 2}
                                                                onClick={() => this.loadSubscriptions({
                                                                    page: this.state.subscriptionPage - 2,
                                                                    articleNotLike: 'tigerticket'
                                                                })}>{this.state.subscriptionPage - 2}</Pagination.Item>
                                                            <Pagination.Item
                                                                hidden={this.state.subscriptionPage === 1}
                                                                onClick={() => this.loadSubscriptions({
                                                                    page: this.state.subscriptionPage - 1,
                                                                    articleNotLike: 'tigerticket'
                                                                })}>{this.state.subscriptionPage - 1}</Pagination.Item>
                                                            <Pagination.Item
                                                                active={true}>{this.state.subscriptionPage}</Pagination.Item>
                                                            <Pagination.Item
                                                                hidden={this.state.subscriptionPage === this.state.lastSubscriptionPage}
                                                                onClick={() => this.loadSubscriptions({
                                                                    page: this.state.subscriptionPage + 1,
                                                                    articleNotLike: 'tigerticket'
                                                                })}>{this.state.subscriptionPage + 1}</Pagination.Item>
                                                            <Pagination.Item
                                                                hidden={this.state.subscriptionPage >= this.state.lastSubscriptionPage - 1}
                                                                onClick={() => this.loadSubscriptions({
                                                                    page: this.state.subscriptionPage + 2,
                                                                    articleNotLike: 'tigerticket'
                                                                })}>{this.state.subscriptionPage + 2}</Pagination.Item>
                                                            <Pagination.Item
                                                                hidden={this.state.subscriptionPage === this.state.lastSubscriptionPage}
                                                                onClick={() => this.loadSubscriptions({
                                                                    page: this.state.subscriptionPage + 1,
                                                                    articleNotLike: 'tigerticket'
                                                                })}><BiChevronRight size={16}/></Pagination.Item>
                                                            <Pagination.Item
                                                                style={{height: "50px"}}
                                                                hidden={this.state.subscriptionPage === this.state.lastSubscriptionPage}
                                                                onClick={() => this.loadSubscriptions({
                                                                    page: this.state.lastSubscriptionPage,
                                                                    articleNotLike: 'tigerticket'
                                                                })}><BiChevronsRight size={16}/></Pagination.Item>
                                                        </Pagination>
                                                    </>
                                                    }
                                                    {this.state.subscriptions.length === 0 &&
                                                    <div style={{textAlign: "center", fontSize: "22px"}}>
                                                        Dieses Konto hat keine Abos
                                                    </div>
                                                    }
                                                </Tab>
                                                <Tab eventKey="presents"
                                                     title={<span style={{color: "#495057"}}><FcCloseUpMode size={24}/> Geschenke</span>}>
                                                    {this.state.supportPresents.length > 0 ?
                                                        <>
                                                            <Table bordered striped responsive hover>
                                                                <thead>
                                                                <tr>
                                                                    <th style={{width: "50px"}}>Aktiv</th>
                                                                    <th><FcRating size={20}/>&#xA0;&#xA0;Laufzeit</th>
                                                                    <th><FcCloseUpMode size={20}/>&#xA0;&#xA0;Geschenkt
                                                                        am
                                                                    </th>
                                                                    <th><FcCalendar size={20}/>&#xA0;&#xA0;Startet am
                                                                    </th>
                                                                    <th><FcExpired size={20}/>&#xA0;&#xA0;Endet am</th>
                                                                </tr>
                                                                </thead>
                                                                <tbody>
                                                                {this.state.supportPresents.map(p => (
                                                                    <tr role="row" key={p.StartDate}
                                                                        style={{height: "80px"}}>
                                                                        <td>{presentIsActive(p) ?
                                                                            <FcOk size={24}/> : " "}</td>
                                                                        <td>{getPresentTypeBadge(p)}</td>
                                                                        <td>{transformStringToReadableDate(p.CreatedAt)}</td>
                                                                        <td>{transformStringToReadableDate(p.StartDate)}</td>
                                                                        <td>{transformStringToReadableDate(p.EndDate)}</td>
                                                                    </tr>
                                                                ))}
                                                                </tbody>
                                                            </Table>
                                                        </>
                                                        :
                                                        <div style={{textAlign: "center", fontSize: "22px"}}>
                                                            Dieses Konto hat keine Geschenke erhalten
                                                        </div>
                                                    }
                                                </Tab>
                                            </Tabs>
                                        </div>


                                    </>
                                }

                            </Tab>

                            {/* PROFILES */}
                            <Tab eventKey="profiles"
                                 title={<span style={{color: "#495057"}}><FcConferenceCall size={24}/> Profile</span>}>
                                {this.state.loading ?
                                    <SpinningTiger/>
                                    :
                                    <ProfilesTab account={this.state.account} profiles={this.state.profiles}/>
                                }
                            </Tab>

                            {/* WILDCARDS */}
                            <Tab eventKey="wildcards"
                                 title={<span style={{color: "#495057"}}><FcMusic size={24}/> wildcards</span>}>
                                {this.state.loading ?
                                    <SpinningTiger/>
                                    :
                                    <>
                                        <div className="support-tool-box">
                                            {this.state.filteredWildcards.length > 0 ?
                                                <Table responsive bordered striped hover>
                                                    <thead>
                                                    <tr>
                                                        <th colSpan="3"
                                                            style={{textAlign: "center"}}>wildcard-Informationen:
                                                        </th>
                                                        <th colSpan="2"
                                                            style={{textAlign: "center"}}>Content-Informationen:
                                                        </th>
                                                    </tr>
                                                    <tr>
                                                        <th style={{fontWeight: "normal"}}><FcKey
                                                            size={20}/>&#xA0;&#xA0;Code
                                                        </th>
                                                        <th style={{fontWeight: "normal"}}>
                                                            <FcCalendar size={20}/>&#xA0;&#xA0;Datum der Erstellung
                                                        </th>
                                                        <th style={{fontWeight: "normal"}}><FcClock
                                                            size={20}/>&#xA0;&#xA0;Zuletzt
                                                            verwendet
                                                        </th>
                                                        <th style={{fontWeight: "normal"}}><FcLink
                                                            size={20}/>&#xA0;&#xA0;Quelle
                                                        </th>
                                                        <th style={{fontWeight: "normal"}}><FcList
                                                            size={20}/>&#xA0;&#xA0;Inhalte
                                                        </th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {this.state.filteredWildcards.map(card => (
                                                        <tr role="row" key={card.id}>
                                                            <td>{card.code}</td>
                                                            <td>{transformStringToReadableDate(card.createdDate)}</td>
                                                            <td>{transformStringToReadableDate(card.lastModifiedDate)}</td>
                                                            <td><Badge
                                                                color={card.productId != null ? (card.defaultProductAssigned ? "danger" : "primary") : (card.accountGeneratedContentId != null ? "success" : "dark")}
                                                                pill>{card.productId != null ? (card.defaultProductAssigned ? "Kein Inhalt" : "tigertones") : (card.accountGeneratedContentId != null ? "Eigener Content" : "---")}</Badge>
                                                            </td>
                                                            <td>{generateWildcardListItem(
                                                                card,
                                                                (productId) => openDetailsInNewTab('products/' + productId),
                                                                (accountGeneratedContentId, nfcCardId) => {
                                                                    this.updateState('showWildcardItems', true);
                                                                    this.loadAccountGeneratedContentItems(accountGeneratedContentId, nfcCardId).then(r => r);
                                                                },
                                                                () => this.updateState('showUploadInformationDialog', true),
                                                                () => this.updateState('showTranscodeInformationDialog', true))}</td>
                                                        </tr>
                                                    ))}
                                                    </tbody>
                                                </Table>
                                                :
                                                <div style={{textAlign: "center", fontSize: "22px"}}>
                                                    Es sind keine wildcards mit diesem Konto verknüpft
                                                </div>
                                            }
                                        </div>
                                    </>
                                }
                            </Tab>

                            {/* BOXEN */}
                            <Tab eventKey="boxes"
                                 title={<span style={{color: "#495057"}}><FcSelfServiceKiosk size={24}/> Boxen</span>}>
                                {this.state.loading ?
                                    <SpinningTiger/>
                                    :
                                    <BoxesTab devices={this.state.devices} profiles={this.state.profiles}
                                              selectedDevice={this.state.selectedDevice}
                                              parentProps={this.props}/>
                                }
                            </Tab>

                            {/* BILLWERK */}
                            <Tab eventKey="billwerk" title={<span style={{color: "#495057"}}><FcCurrencyExchange
                                size={24}/> Billwerk</span>}>
                                {this.state.loading ?
                                    <SpinningTiger/>
                                    :
                                    <BillwerkTab customer={this.state.billwerkCustomer} parentProps={this.props}/>
                                }
                            </Tab>

                        </Tabs>

                    </div>
                    }
                </>
                }
                {/* MODALS */}
                <ActionModal
                    show={this.state.showSwissUpgradeModal}
                    onHide={() => this.updateState('showSwissUpgradeModal', false)}
                    title={"Swiss Upgrade"}
                    body={"Soll das Upgrade zu einem Swiss-Zugang durchgeführt werden?"}
                    actionButtonText={"Upgrade"}
                    onAction={() => this.performSwissUpgrade()}
                />

                <InfoModal
                    show={this.state.accountsByEmail.length > 0}
                    hideFooter={true}
                    onHide={() => this.updateState('accountsByEmail', [])}
                    title={"Wähle das passende Konto"}
                    body={<AccountSelectionByEmailDialog
                        accountList={this.state.accountsByEmail}
                        onSelectAccount={(item) => {
                            GetAccount(item.account.id).then(r => {
                                this.updateState('account', r.result);
                                this.updateState('customer', item.customer);
                                this.updateState('isDeletedAccount', item.deleted)
                                this.updateState('accountsByEmail', []);
                                this.updateState('premiumTab', 'overview');
                                this.loadAccountData().then(r => r);
                            });
                        }}/>}
                />

                <InfoModal
                    show={this.state.accountsByTicket.length > 0}
                    hideFooter={true}
                    onHide={() => this.updateState('accountsByTicket', [])}
                    title={"Wähle das passenden Konto"}
                    body={<AccountSelectionByCodeDialog
                        accounts={this.state.accountsByTicket}
                        ticket={this.state.filterTicket}
                        onSelectAccount={(account) => {
                            GetAccount(account.id).then(r => {
                                this.updateState('account', r.result);
                                this.updateState('accountsByTicket', []);
                                this.updateState('premiumTab', 'overview');
                                this.loadAccountData().then(l => l);
                            });
                        }}/>}
                />

                <InfoModal show={this.state.showChangeEmailModal}
                           hideFooter={true}
                           onHide={() => this.updateState('showChangeEmailModal', false)}
                           title={"Ändere die E-Mail des Kontos"}
                           body={<ChangeEmailDialog
                               account={this.state.account}
                               parentProps={this.props}
                               onHide={() => this.updateState('showChangeEmailModal', false)}
                           />}
                />

                <InfoModal show={this.state.showChangePasswordModal}
                           hideFooter={true}
                           onHide={() => this.updateState('showChangePasswordModal', false)}
                           title={"Ändere das Konto-Passwort"}
                           body={<ChangePasswordDialog
                               account={this.state.account}
                               parentProps={this.props}
                               onHide={() => this.updateState('showChangePasswordModal', false)}
                           />}
                />

                <InfoModal show={this.state.showNewsletterModal}
                           hideFooter={true}
                           onHide={() => this.updateState('showNewsletterModal', false)}
                           title={"Newsletter-Verwaltung"}
                           body={<ManageNewsletterDialog
                               account={this.state.account}
                               parentProps={this.props}
                               onHide={() => this.updateState('showNewsletterModal', false)}
                           />}
                />

                <InfoModal show={this.state.showManualRedemptionDialog}
                           hideFooter={true}
                           size={"l"}
                           onHide={() => this.updateState('showManualRedemptionDialog', false)}
                           title={"Ticket manuell einlösen"}
                           body={<RedeemTicketDialog
                               email={this.state.account.email}
                               accountId={this.state.account.id}
                               onHide={() => {
                                   this.updateState('showManualRedemptionDialog', false);
                                   this.props.addToast("Das Ticket wurde eingelöst und die Order erstellt. Der Prozess dauert einen kleinen Moment", {
                                       appearance: "success",
                                       autoDismiss: true
                                   });
                               }}
                           />}
                />

                <InfoModal show={this.state.showDeleteAccountModal}
                           hideFooter={true}
                           onHide={() => this.updateState('showDeleteAccountModal', false)}
                           size="m"
                           title={"Konto-Löschung"}
                           body={(
                               <div style={{textAlign: "center"}}>
                                   <p>Soll das Konto wirklich gelöscht werden?</p>
                                   <p>
                                       <PromiseButton className="form-btn-ci-light" style={{marginRight: "10px"}}
                                                      onClick={() => this.updateState('showDeleteAccountModal', false)}
                                                      text="Abbrechen"/>
                                       <PromiseButton className="form-btn-ci-red" onClick={() => this.deleteAccount()}
                                                      text="Löschen"/>
                                   </p>
                               </div>
                           )}
                />

                <InfoModal show={this.state.showGenerateDTTModal}
                           title={"Generiere ein digitales Ticket"}
                           onHide={() => {
                               this.updateState('showGenerateDTTModal', false);
                               if (this.state.account.id != null) {
                                   this.loadPremiumAccessData().then(r => r);
                               }
                           }}
                           body={
                               <DTTSingleGeneratorDialog
                                   account={this.state.account}
                                   onHide={() => {
                                       if (this.state.account.id != null) {
                                           this.loadAccountTickets().then(r => r);
                                       }
                                   }}
                                   finishProcess={() => {
                                       this.updateState('showGenerateDTTModal', false);
                                       if (this.state.account.id != null) {
                                           this.showInfo("Der Mailversand wurde gestartet.");
                                           this.loadAccountTickets().then(r => r);
                                       }
                                   }}
                                   onSuccess={() => this.showSuccess("Der Mailversand wurde erfolgreich abgeschlossen.")}
                                   onError={() => this.showError("Beim Versand der E-Mail ist ein Fehler aufgetreten.")}
                               />
                           }
                />

                <InfoModal show={this.state.showGenerateMultiDTTModal}
                           title={"Generiere mehrere digitale Tickets"}
                           onHide={() => {
                               this.updateState('showGenerateMultiDTTModal', false);
                           }}
                           body={<DTTMassGeneratorDialog
                               finishProcess={() => {
                                   this.updateState('showGenerateMultiDTTModal', false);
                                   this.props.addToast("Der Mailversand wurde gestartet.", {
                                       autoDismiss: true,
                                       appearance: "success"
                                   });
                               }}
                               onSuccess={() => {
                                   this.props.addToast("Der Mailversand wurde erfolgreich abgeschlossen.", {
                                       autoDismiss: true,
                                       appearance: "success"
                                   });
                               }}
                               onError={() => {
                                   this.props.addToast("Beim Versand der Mails ist ein Fehler aufgetreten. Bitte schaue in den Logs des CRM-Services nach.", {
                                       appearance: "error",
                                       autoDismiss: true
                                   });
                               }}
                           />}
                />

                <InfoModal show={this.state.showRedeemedTicketModal}
                           size="m"
                           hideFooter={true}
                           onHide={() => this.updateState('showRedeemedTicketModal', false)}
                           title={"Ticketeinlösung"}
                           body={(
                               <div style={{textAlign: "center"}}>
                                   <p>Soll das Ticket wirklich eingelöst werden?</p>
                                   <p>Bei Klick auf "Einlösen" wird eine Order erstellt. Dies kann bis zu zwei Minuten
                                       dauern. Lade die Seite dann noch einmal neu, um die Änderungen anzuzeigen.</p>
                                   <p>
                                       <PromiseButton className="form-btn-ci-red" style={{marginRight: "10px"}}
                                                      onClick={() => this.updateState('showRedeemedTicketModal', false)}
                                                      text="Abbrechen"/>
                                       <PromiseButton className="form-btn-ci-green"
                                                      onClick={() => this.redeemSelectedTicket()} text="Einlösen"/>
                                   </p>
                               </div>
                           )}
                />

                <InfoModal show={this.state.showGenerateSubscriptionModal}
                           hideFooter={true}
                           onHide={() => this.updateState('showGenerateSubscriptionModal', false)}
                           title={"Generiere ein neues Abo"}
                           body={<CreateSubscriptionDialog
                               account={this.state.account}
                               parentProps={this.props}
                               products={this.state.products}
                               productGroups={this.state.productGroups}
                               onHide={(reload) => {
                                   this.updateState('showGenerateSubscriptionModal', false);
                                   if (reload) {
                                       this.loadPremiumAccessData().then(r => r);
                                   }
                               }}
                           />}
                />

                <InfoModal show={this.state.showGeneratePremiumAccessModal}
                           hideFooter={true}
                           onHide={() => this.updateState('showGeneratePremiumAccessModal', false)}
                           title={"Erstelle einen neuen Zugang"}
                           body={<CreatePremiumAccessDialog
                               account={this.state.account}
                               parentProps={this.props}
                               onHide={(reload) => {
                                   this.updateState('showGeneratePremiumAccessModal', false);
                                   if (reload) {
                                       this.loadPremiumAccessData().then(r => r);
                                   }
                               }}
                           />}
                />

                <InfoModal show={this.state.showResetTicketDialog}
                           title="Ticketzurücksetzung"
                           hideFooter={true}
                           onHide={() => this.updateState('showResetTicketDialog', false)}
                           body={(
                               <div>
                                   <p>
                                       Dabei werden die aktuellen Einlöseinformationen gelöscht (dazu gehört
                                       Einlösedatum, "Eingelöst von" etc.). Anschließend kann das Ticket erneut wie
                                       gewohnt eingelöst werden.
                                   </p>
                                   <p>
                                       <Form>
                                           <Form.Check disabled={isSubscription(this.state.activeAccess)}
                                                       checked={this.state.redeemAutomatically}
                                                       value={this.state.redeemAutomatically}
                                                       onChange={() => this.updateState('redeemAutomatically', !this.state.redeemAutomatically)}
                                                       label={"Ticket direkt wieder einlösen?"}/>
                                       </Form>
                                       Falls ausgewählt, wird das Ticket nach Klick auf "Zurücksetzen" automatisch
                                       wieder eingelöst. Der Einlöseprozess kann bis zu zwei Minuten dauern.
                                       Falls nicht ausgewählt, wird das Ticket nur zurückgesetzt. Es kann dann zu einem
                                       beliebiegen Zeitpunkt wiederverwendet werden.
                                   </p>
                                   <p>
                                       <Form>
                                           <Form.Check checked={this.state.deactivateWhenReset}
                                                       value={this.state.deactivateWhenReset}
                                                       onChange={() => this.updateState('deactivateWhenReset', !this.state.deactivateWhenReset)}
                                                       label={"Verknüpften Zugang deaktivieren (falls vorhanden)?"}/>
                                       </Form>
                                       Falls ausgewählt, wird die Vertragsphase in Billwerk, die zu dem Ticket gehört,
                                       deaktiviert.
                                   </p>
                                   <p style={{textAlign: "center"}}>
                                       <PromiseButton className="form-btn-ci-red" style={{marginRight: "10px"}}
                                                      onClick={() => this.updateState('showResetTicketDialog', false)}
                                                      text="Abbrechen"/>
                                       <PromiseButton className="form-btn-ci-green" onClick={() => this.resetTicket()}
                                                      text="Zurücksetzen"/>
                                   </p>
                               </div>
                           )}
                />

                <InfoModal show={this.state.showWildcardItems}
                           hideFooter={true}
                           onHide={() => this.updateState('showWildcardItems', false)}
                           title={"Playlist"}
                           body={(
                               <>
                                   {this.state.loading ?
                                       <SpinningTiger/>
                                       :
                                       this.state.wildcardItems.length > 0 ?
                                           <Table bordered striped hover responsive>
                                               <thead>
                                               <tr>
                                                   <th>Position</th>
                                                   <th>Dateiname</th>
                                                   <th>Länge</th>
                                                   <th>Erstellt am</th>
                                                   <th>Zuletzt bearbeitet am</th>
                                               </tr>
                                               </thead>
                                               <tbody>
                                               {this.state.wildcardItems
                                                   .sort((a, b) => (a.sortNumber > b.sortNumber) ? 1 : ((b.sortNumber > a.sortNumber) ? -1 : 0))
                                                   .map(item => (
                                                       <tr role="row" key={item.id}>
                                                           <td>{item.sortNumber}</td>
                                                           <td>{item.name}</td>
                                                           <td>{Math.floor(item.duration / 60) < 10 ? "0" + Math.floor(item.duration / 60) : Math.floor(item.duration / 60)}:{item.duration % 60 < 10 ? "0" + item.duration % 60 : item.duration % 60}</td>
                                                           <td>{transformStringToReadableDate(item.createdDate)}</td>
                                                           <td>{transformStringToReadableDate(item.lastModifiedDate)}</td>
                                                       </tr>
                                                   ))
                                               }
                                               </tbody>
                                           </Table>
                                           :
                                           <div style={{textAlign: "center", fontSize: "22px"}}>
                                               Die Playlist hat keine Inhalte
                                           </div>
                                   }
                                   <div style={{float: "right"}}>
                                       <Button variant="secondary"
                                               onClick={() => this.updateState('showWildcardItems', false)}>Schließen</Button>
                                   </div>
                               </>
                           )}
                />

                <InfoModal show={this.state.showUploadInformationDialog}
                           hideFooter={true}
                           onHide={() => this.updateState('showUploadInformationDialog', false)}
                           title={"Verarbeitungsfehler-Codes"}
                           body={
                               <>
                                   <Table responsive bordered hover striped>
                                       <thead>
                                       <tr>
                                           <th>Code</th>
                                           <th>Bedeutung</th>
                                       </tr>
                                       </thead>
                                       <tbody>
                                       {SupportToolMetaData.ProcessingFailureCodes.map(code => (
                                           <tr role="row" key={code}>
                                               <td>{code.code}</td>
                                               <td>{code.description}</td>
                                           </tr>
                                       ))}
                                       </tbody>
                                   </Table>
                                   <div style={{float: "right"}}>
                                       <Button variant="secondary"
                                               onClick={() => this.updateState('showUploadInformationDialog', false)}>Schließen</Button>
                                   </div>
                               </>
                           }/>

                <InfoModal show={this.state.showTranscodeInformationDialog}
                           hideFooter={true}
                           onHide={() => this.updateState('showTranscodeInformationDialog', false)}
                           title={"Transcode-Statiscodes"}
                           body={
                               <>
                                   <Table responsive bordered hover striped>
                                       <thead>
                                       <tr>
                                           <th>Code</th>
                                           <th>Bedeutung</th>
                                       </tr>
                                       </thead>
                                       <tbody>
                                       {SupportToolMetaData.TranscodeStatusCodes.map(code => (
                                           <tr role="row" key={code}>
                                               <td>{code.code}</td>
                                               <td>{code.description}</td>
                                           </tr>
                                       ))}
                                       </tbody>
                                   </Table>
                                   <div style={{float: "right"}}>
                                       <Button variant="secondary"
                                               onClick={() => this.updateState('showTranscodeInformationDialog', false)}>Schließen</Button>
                                   </div>
                               </>
                           }/>

                <InfoModal show={this.state.showHelpDialog}
                           hideFooter={true}
                           onHide={() => this.updateState('showHelpDialog', false)}
                           title={"Info"}
                           body={
                               <>
                                   <h4>{this.state.premiumTab === "overview" ? "Übersicht der Zugänge" : this.state.premiumTab === "tickets" ? "Tickets" : "Abos"}</h4>
                                   <p>{this.state.premiumTab === "overview" ? SupportToolMetaData.INFO_SPINE_SUBSCRIPTIONS : this.state.premiumTab === "tickets" ? SupportToolMetaData.INFO_TICKETS : SupportToolMetaData.INFO_SUBSCRIPTIONS}</p>
                                   <p style={{float: "right"}}>
                                       <button className="form-btn-ci-light" type="button"
                                               onClick={() => this.updateState('showHelpDialog', false)}>Okay
                                       </button>
                                   </p>

                               </>
                           }/>

                <InfoModal show={this.state.showTransferTicketModal}
                           hideFooter={true}
                           onHide={() => this.updateState('showTransferTicketModal', false)}
                           title={"Aktives Ticket übertragen"}
                           body={<TransferTicketDialog
                               ticket={this.state.selectedTicket}
                               account={this.state.account}
                               onHide={() => this.updateState('showTransferTicketModal', false)}
                               onTransferCompleted={() => {
                                   this.updateState('showTransferTicketModal', false);
                                   this.loadAccountTickets().then(r => this.showSuccess("Das Ticket wurde erfolgreich übertragen"));
                               }}
                               onShowError={(m) => this.showError(m)}/>}

                />

                <ActionModal show={this.state.showRestoreAccountModal}
                             size="md"
                             title="Konto wiederherstellen"
                             onHide={() => this.updateState('showRestoreAccountModal', false)}
                             onAction={() => this.restoreAccount()}
                             actionButtonText="Wiederherstellen"
                             body={
                                 <div style={{textAlign: "center"}}>
                                     Soll das Konto <strong>{this.state.customer != null ? this.state.customer.EmailAddress : ""}</strong> wirklich wiederhergestellt werden?<br /><br />
                                    Falls ja, gib bitte die E-Mail-Adresse an, über die sich der Konto-Inhaber dann anmelden kann!
                                     <br />
                                     <br />
                                    <Form>
                                        <Form.Control value={this.state.restoreEmail} onChange={(e) => this.updateState('restoreEmail', e.target.value)} />
                                    </Form>
                                 </div>
                             }/>


            </div>
        );
    }

    //------------
    // API METHODS
    //------------
    async restoreAccount() {
        //Activate the account
        let response = await UpdateAccount({...this.state.account, email: this.state.restoreEmail, enabled: true});
        if (!response.error) {
            let changeMailResponse = await ChangeEmail(this.state.account, this.state.restoreEmail);
            if (!changeMailResponse.error) {
                this.showSuccess("Das Konto wurde mit der E-Mail " +  this.state.restoreEmail + " wiederhergestellt");
                this.updateState('account', {...this.state.account, email: this.state.restoreEmail});
                this.loadAccountData().then(r => {
                    this.updateState('restoreEmail', null);
                    this.updateState('showRestoreAccountModal', false);
                    this.updateState('isDeletedAccount', false);
                })
            } else {
                this.showError("Beim Ändern der E-Mail-Adresse ist ein Fehler aufgetreten: " + changeMailResponse.message);
            }
        } else {
            this.showError("Beim Reaktivieren des Kontos ist ein Fehler aufgetreten: " + response.message);
        }
    }

    async loadProfiles() {
        this.updateState('loading', true);
        let response = await LoadAccountProfiles(this.state.account.id);
        if (!response.error && response.result != null) {
            this.updateState('profiles', response.result);
        } else {
            this.props.addToast("Beim Laden der Profile ist ein Fehler aufgetreten: " + response.message, {
                appearance: "error",
                autoDismiss: true
            });
        }
        this.updateState('loading', false);
    }

    async performSwissUpgrade() {
        let response = await PerformSwissUpgrade(this.state.account.id);
        if (!response.error) {
            this.loadAccountData().then(r => this.updateState('showSwissUpgradeModal', false));
        } else {
            this.props.addToast("Beim Upgrade ist ein Fehler aufgetreten: " + response.message, {
                appearance: "error",
                autoDismiss: true
            });
        }
    }

    async updateAccount() {

        let account = this.state.account;
        if(account.newsletter == null) {
            account.newsletter = false;
        }

        let accountRes = await UpdateAccount(account);

        if (!accountRes.error) {
            this.setState((prevState) => ({
                ...prevState,
                account: accountRes.result,
                showSaveAccount: false
            }));
            this.showSuccess("Das Konto wurde erfolgreich gespeichert.")
        } else {
            this.showError("Beim Speichern des Kontos ist ein Fehler aufgetreten: " + accountRes.message);
        }
    }

    async resetTicket() {
        let response = await ResetTicket(this.state.selectedTicket, this.state.deactivateWhenReset);
        if (!response.error) {
            this.updateState('showResetTicketDialog', false);

            //Redeem the ticket automatically again
            if (this.state.redeemAutomatically) {
                const request = {
                    code: this.state.selectedTicket.ticket.code,
                    pin: this.state.selectedTicket.ticket.secondSecret
                }
                let response = await RedeemTicket(request, this.state.account.id);
                if (!response.error) {
                    this.showSuccess("Das Ticket wurde zurückgesetzt und neu eingelöst.");
                } else {
                    this.showError("Das Ticket wurde zurückgesetzt, aber beim Einlösen ist ein Fehler aufgetreten: " + response.message);
                }
            } else {
                this.showSuccess("Das Ticket wurde zurückgesetzt.");
            }
            await this.loadAccountTickets();
        } else {
            this.showError("Beim Zurücksetzen des Tickets ist ein Fehler aufgetreten: " + response.message);
        }
    }

    async loadAccountGeneratedContentItems(accountGeneratedContentId, nfcCardId) {
        this.updateState('loading', true);
        let response = await GetAccountGeneratedContentItems(accountGeneratedContentId, nfcCardId);
        if(!response.error) {
            if(response.result != null) {
                this.updateState('wildcardItems', response.result);
            }
        } else {
            this.showError("Beim Laden der Playlist ist ein Fehler aufgetreten. Bitte lade die Seite erneut oder kontaktiere einen Administrator. " + response.message);
        }
        this.updateState('loading', false);
    }

    async loadAccountData() {
        this.updateState('filter', {accountId: this.state.account.id});
        this.updateState('loading', true);
        await this.loadPremiumAccessData();
        await this.loadDevices();
        await this.loadProfiles();
        await this.loadWildcards();
        if (this.state.account.email != null && this.state.account.email.trim() !== "") {
            await this.loadNewsletterDetails();
        }
        this.updateState('loading', false);
    }

    async loadBillwerkCustomer() {
        this.updateState('loading', true);
        let customerResponse = await GetBillwerkCustomer(this.state.account.id);
        if(!customerResponse.error) {
            let result = customerResponse.result;
            if(result === null) {
                this.showInfo("Zu dieser E-Mail-Adresse wurde kein Billwerk-Konto gefunden.");
            } else {
                this.updateState('billwerkCustomer', result);
            }
        } else {
            this.showInfo("Zu dieser E-Mail-Adresse wurde kein Billwerk-Konto gefunden.");
        }
        this.updateState('loading', false);
    }

    async deleteAccount(){
        let response = await DeleteAccount(this.state.account);
        if(!response.error) {
            let updatedState = {
                account: {},
                accountTickets: [],
                subscriptions: [],
                showDeleteAccountModal: false,
            }

            this.showSuccess("Das Konto wurde erfolgreich gelöscht.");

            this.setState(prevState => ({...prevState, ...updatedState}));
        } else {
            this.showError("Beim Löschen des Kontos ist ein Fehler aufgetreten. Bitte versuche es noch einmal oder kontaktiere einen Administrator.");
        }
    }

    async searchForAccount(filter) {
        //Initialiazing
        this.updateState('account', {});
        this.updateState('loading', true);
        let response = {};

        //Search process depends on the filter criteria
        if(filter.ticketCode != null && filter.ticketCode.trim() !== "") {
            //A ticket code has been provided -> Get the matching ticket (if it exists)
            response = await GetExtendedCustomerTicketInfos(filter.ticketCode);
            if(!response.error && response.result != null && response.result.ticket != null) {
                const ticket = response.result.ticket;
                const accounts = response.result.accounts != null ? response.result.accounts : [];
                if (ticket.used === false && accounts.length === 0) {
                    this.showInfo("Das Ticket/die Swisscard mit dem Code " + filter.ticketCode + " wurde noch nicht eingelöst");
                    this.updateState('loading', false);
                } else if (accounts.length === 1) {
                    const accountResponse = await GetAccount(accounts[0].id);
                    if (!accountResponse.error) {
                        this.updateState('account', accountResponse.result);
                        this.updateState('loading', false);
                        this.updateState('premiumTab', 'overview');
                        await this.loadAccountData();
                    } else {
                        this.showError("Beim Laden des Kontos mit der ID " + accounts[0].id + " ist ein Fehler aufgetreten");
                        this.updateState('loading', false);
                    }
                } else {
                    this.updateState('filterTicket', ticket);
                    this.updateState('accountsByTicket', accounts);
                    this.updateState('loading', false);
                }
            } else {
                //No ticket found
                this.showInfo("Es wurde kein Ticket oder Swisscard mit dem Code " + filter.ticketCode + " gefunden.");
                this.updateState('loading', false);
            }
        } else if(filter.wildcardCode != null && filter.wildcardCode.trim() !== "") {
            let wildcard = await GetWildcardByCode(filter.wildcardCode);
            if(!wildcard.error) {
                const wildcardRes = wildcard.result;
                if(wildcardRes == null) {
                    this.showInfo("Es wurde keine Wildcard mit diesem Code gefunden.");
                    this.updateState('loading', false);
                    return;
                } else if(wildcardRes.wildcardAccountId == null) {
                    this.showInfo("Diese Wildcard ist mit keinem Konto verknüpft.");
                    this.updateState('loading', false);
                    return;
                }
                const accountResponse = await GetAccount(wildcardRes.wildcardAccountId);
                if (!accountResponse.error) {
                    this.updateState('account', accountResponse.result);
                    this.updateState('loading', false);
                    this.updateState('premiumTab', 'overview');
                    await this.loadAccountData();
                } else {
                    this.showError("Beim Laden des Kontos mit der ID " + wildcardRes.wildcardAccountId + " ist ein Fehler aufgetreten");
                    this.updateState('loading', false);
                }
            } else {
                this.showError("Beim Laden der Wildcard mit dem Code " + filter.wildcardCode + " ist ein Fehler aufgetreten.");
                this.updateState('loading', false);
            }
        } else if(filter.email != null && filter.email.trim() !== "") {
            //eslint-disable-next-line
            filter.email = filter.email.replaceAll('_', '\_').replaceAll('%', '\%');
            this.updateState('filter', filter);
            response = await GetExtendedBillwerkCustomerDetails(filter.email);
            if (!response.error && response.result != null) {
                if (response.result.length === 0) {
                    this.showInfo("Es wurde kein Konto mit der E-Mail " + filter.email + " gefunden");
                    this.updateState('loading', false);
                } else if (response.result.length === 1) {
                    const res = response.result[0];
                    if (res.account == null || res.account.id == null) {
                        this.showError("Beim Laden des Kontos mit der E-Mail " + filter.email + " ist ein Fehler aufgetreten.");
                        this.updateState('loading', false);
                    } else {
                        GetAccount(res.account.id).then(r => {
                            this.updateState('account', r.result);
                            this.loadAccountData().then(r => {
                                this.updateState('loading', false);
                                this.updateState('premiumTab', 'overview');
                            });
                        })
                    }
                } else {
                    this.updateState('accountsByEmail', response.result);
                    this.updateState('loading', false);
                }
            } else {
                this.showError("Beim Laden der Kontos mit der E-Mail " + filter.email + " ist ein Fehler aufgetreten.");
                this.updateState('loading', false);

            }
        } else {
            this.showInfo("Bitte gib ein Filterkriterium an");
            this.updateState('loading', false);
        }
    }

    async loadPremiumAccessData() {
        this.updateState('subscriptions', []);
        this.updateState('showActiveOnly', true);

        //Load the data
        await this.loadActiveAccess();
        await this.loadSubscriptions({page: 1});
    }

    async loadActiveAccess() {
        const activeAccess = await GetFilteredSubscriptions(this.state.account, {active_only: true});
        if (activeAccess.error) {
            this.showError("Beim Laden der aktiven Subscription ist ein Fehler aufgetreten. Bitte lade die Seite neu oder informiere einen Administrator: " + activeAccess.message);
            return;
        }
        if (activeAccess.result.length > 0) {
            const s = activeAccess.result[0]
            this.updateState('activeAccess', s);
            this.updateState('showActiveOnly', true);
            if (isSubscription(s)) {
                this.updateState('redeemAutomatically', false);
            }
        } else {
            this.updateState('activeAccess', null);
            this.updateState('showActiveOnly', false);
        }
    }

    async loadSubscriptions(filter) {
        let response = await GetFilteredSubscriptions(this.state.account, {...filter, active_only: false, per_page: 10, sortBy: "validUntil", sortDirection: "DESC"});
        if(!response.error && response.result != null) {
            //Calculate the last page
            let lastPage = Math.ceil(response.length/10);
            const lastPageRes = await GetFilteredSubscriptions(this.state.account, {...filter, page: 1, per_page: 250, active_only: false});
            if (!lastPageRes.error && lastPageRes.result != null) {
                lastPage = Math.ceil(lastPageRes.result.length/10);
            }
            this.updateState('subscriptionPage', filter.page);
            this.updateState('lastSubscriptionPage', lastPage);
            this.updateState('subscriptions', response.result);
        } else {
            this.showError("Beim Laden der Abos/Tickets ist ein Fehler aufgetreten. Bitte lade die Seite neu oder informiere einen Administrator: " + response.message);
        }
    }

    async loadSupportPresents() {
        let response = await GetSupportPresentsOfAccount(this.state.account.id);
        if (!response.error && response.result != null) {
            this.updateState('supportPresents', response.result);
        } else {
            this.showError("Beim Laden der Geschenke ist ein Fehler aufgetreten. Bitte lade die Seite neu oder informiere einen Administrator: " + response.message);
        }
    }

    async loadAccountTickets() {
        let response = await GetAccountTickets(this.state.account.id);
        if (!response.error && response.result != null) {
            this.updateState('accountTickets', response.result);
        } else {
            this.showError("Beim Laden der Tickets ist ein Fehler aufgetreten. Bitte lade die Seite neu oder informiere einen Administrator: "+ response.message);
        }
    }

    async loadDevices() {
        this.updateState('devices', []);
        let response = await GetAccountDevices(this.state.account);
        if(!response.error) {
            let devices = response.result._embedded?.devices ? response.result._embedded?.devices : [];
            if (devices.length > 0) {
                devices = response.result._embedded.devices;
                let remarkResponse = await GetFilteredDeviceSupplements(this.state.account);
                let remarks = remarkResponse.result;
                for(let i = 0; i < remarks.length; i++) {
                    for(let j = 0; j < devices.length; j++) {
                        if(devices[j].id === remarks[i].deviceId) {
                            devices[j].remark = remarks[i].remark;
                        }
                    }
                }

                //Set the update version and the id of the box (get the last modified device)
                let selectedDevice = sortByParamDesc(devices, 'lastModifiedDate')[0];
                this.updateState('boxId', selectedDevice.deviceIdentifier);
                this.updateState('boxUpdate', selectedDevice.currentVersion);

            } else {
                this.updateState('boxId', "---")
                this.updateState('boxUpdate', "---")
            }
            this.updateState('devices', devices);
        } else {
            this.showError('Beim Laden der Boxen ist ein Fehler aufgetreten. Bitte lade die Seite neu oder informiere einen Administrator: ' + response.message);
        }
    }

    async loadWildcards() {
        this.updateState('wildcards', []);
        this.updateState('filteredWildcards', []);
        let response = await GetAccountWildcards(this.state.account);
        if(!response.error && response.result != null) {
            let cards = response.result;

            //Get the upload information
            let uploadInformation = await GetUploadInformationOfWildcards(this.state.account);
            if(!uploadInformation.error) {
                let informationList = uploadInformation.result;
                for(let i = 0; i < informationList.length; i++) {
                    for(let j = 0; j < cards.length; j++) {
                        if(informationList[i].accountGeneratedContentId === cards[j].accountGeneratedContentId) {
                            cards[j].uploadInformation = informationList[i].processingErrorCause;
                        }
                    }
                }
            }

            //Set the cover
            for (let i = 0; i < cards.length; i++) {
                cards[i].coverUrl = await GetWildcardCover(cards[i]);
            }
            this.updateState('wildcards', cards);
            this.updateState('filteredWildcards', cards);
        } else {
            this.showError("Beim Laden der Wildcards ist ein Fehler aufgetreten. Bitte lade die Seite neu oder informiere einen Administrator: " + response.message);
        }
    }

    async loadNewsletterDetails() {
        this.updateState('newsletter', []);
        let response = await GetNewsletterListsByEmail(this.state.account.email);
        if(!response.error) {
            this.updateState('newsletter', response.result);
        } else {
            this.showError("Beim Laden der Newsletter-Informationen ist ein Fehler aufgetreten.");
        }

        let recommendation = await HasSubscribedToRecommendations(this.state.account.email);
        this.updateState('subscribedToRecommendations', recommendation);
    }

    async redeemSelectedTicket() {
        const request = {
            code: this.state.selectedTicket.code,
            pin: this.state.selectedTicket.secondSecret
        }
        let response = await RedeemTicket(request, this.state.account.id);
        if(!response.error) {
            await this.loadAccountTickets();
            this.updateState('showRedeemedTicketModal', false);
            this.showInfo("Der Einlöseprozess wurde gestartet. Bitte lade diese Seite in wenigen Sekunden neu.");
        } else {
            this.showError("Beim Einlöseprozess ist ein Fehler aufgetreten: " + response.message);
        }
    }

    async saveSubscriptionNote() {
        if (this.state.selectedSubscription != null && this.state.selectedSubscription.id != null && this.state.selectedSubscription.accountId) {
            const response = await UpdateSubscription({id: this.state.selectedSubscription.accountId}, this.state.selectedSubscription);
            if (response.error) {
                this.showError("Beim Speichern der Notiz ist ein Fehler aufgetreten.");
            } else {
                this.updateState('selectedSubscription', null);
                await this.loadAccountData();
            }
        }
    }

    //---------------
    // FILTER & STATE
    //---------------

    getSortSymbol(field) {
        if (field === this.state.sortBy) {
            if (this.state.sortDirection === "ASC") {
                return <AiOutlineArrowUp size={20} />
            } else {
                return <AiOutlineArrowDown size={20} />
            }
        } else {
            return null;
        }
    }

    applyTicketSort(field) {

        let sortDirection;
        if (field !== this.state.sortBy) {
            sortDirection = "DESC";
        } else {
            if (this.state.sortDirection === "DESC") {
                sortDirection = "ASC";
            } else {
                sortDirection = "DESC";
            }
        }
        let tickets = sortTicketListBy(field, this.state.accountTickets, sortDirection);
        this.updateState('accountTickets', tickets);
        this.updateState('sortDirection', sortDirection);
        this.updateState('sortBy', field);
    }

    async applyFilter(e, filter) {
        e.preventDefault();
        await this.searchForAccount(filter);
    }
    updateFilter(param, value) {
        let filter = {email: "", accountId: "", ticketCode: "", wildcardCode: ""};
        filter[param] = value;
        this.setState(prevState => ({...prevState, filter: filter}));
    }
    updateState(param, value) {
        let state = this.state;
        state[param] = value;
        this.setState(state);
    }
    resetFilter(){
        let filter = {email: "", accountId: "", ticketCode: "", wildcardCode: ""};
        this.setState(prevState => ({...prevState, filter: filter}));
    }

    //------------
    // APPEARANCE
    //------------

    subscriptionNoteField(subscription) {
        if (this.state.selectedSubscription == null || this.state.selectedSubscription.id !== subscription.id) {
            return (
                <>
                    <div style={{float: "right", cursor: "pointer", marginTop: "-5px"}} onClick={() => this.updateState('selectedSubscription', subscription)}><BsPencil /></div>
                    <div>{subscription.note}</div>
                </>
            );
        } else {
            return (
                <>
                    <div style={{marginTop: "-5px", marginBottom: "5px", float: "right"}}>
                        <ButtonGroup size="sm">
                            <PromiseButton className="form-btn-ci-red" type="button" onClick={() => this.updateState('selectedSubscription', null)} text={<TiCancelOutline size={20}/>}/>
                            <PromiseButton className="form-btn-ci-blue" type="button" onClick={() => this.saveSubscriptionNote()} text={<BsCheck2 size={20}/>} />
                        </ButtonGroup>
                    </div>
                    <div>
                        <Form.Control as="textarea" value={this.state.selectedSubscription.note} onChange={(e) => this.setState(prevState => ({
                            ...prevState,
                            selectedSubscription: {
                                ...prevState.selectedSubscription,
                                note: e.target.value
                            }
                        }))}/>
                    </div>
                </>
            )
        }
    }

    ticketToolColumn(entry) {
        const ticket = entry.ticket;
        if (ticket.used) {
            return (
                <>
                    <button className="form-btn-ci-red" onClick={() => {
                        this.updateState('selectedTicket', ticket);
                        this.updateState('showResetTicketDialog', true);
                    }}>Zurücksetzen</button>
                    <button className="form-btn-ci-blue" onClick={() => {
                        this.updateState('selectedTicket', ticket);
                        this.updateState('showTransferTicketModal', true);
                    }}>
                        Übertragen
                    </button>
                </>
            );
        }

        return (
            <>
                <button className={isSubscription(this.state.activeAccess) ? "form-btn-ci-off" : "form-btn-ci-light"}
                        disabled={isSubscription(this.state.activeAccess)}
                        onClick={() => {
                            this.updateState('selectedTicket', ticket);
                            this.updateState('showRedeemedTicketModal', true);
                        }}>Einlösen</button>
                <button className="form-btn-ci-blue" onClick={() => {
                    this.updateState('selectedTicket', ticket);
                    this.updateState('showTransferTicketModal', true);
                }}>
                    Übertragen
                </button>
            </>
        );
    }

    showError(message) {
        this.props.addToast(message, {
            autoDismiss: true,
            appearance: "error"
        });
    }

    showInfo(message) {
        this.props.addToast(message, {
            autoDismiss: true,
            appearance: "info"
        });
    }

    showSuccess(message) {
        this.props.addToast(message, {
            autoDismiss: true,
            appearance: "success"
        });
    }

}

export default withToast(SupportTool);