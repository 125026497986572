import {Form, Col} from "react-bootstrap";
import Row from 'react-bootstrap/Row';
import {useState} from "react";
import FormSelect from "react-bootstrap/FormSelect";
import {Label} from "reactstrap";
import AssetMetaData from "../assets/AssetMetaData";

export function AddOrEditIconDialog(props) {

        let [icon, setIcon] = useState(props.icon);

    return(
        <div>
            <Form>
                <Row>
                    <Form.Group as={Col} controlId="assetId">
                        <Label>Asset ID</Label>
                        <Form.Control type="number" value={icon.assetId} onChange={(e) => {
                            setIcon(prevIcon => ({...prevIcon, assetId: e.target.value}));
                            icon.assetId = e.target.value;
                            props.onUpdateIcon(icon);
                        }}/>
                    </Form.Group>
                </Row>
                <Row>
                    <Form.Group as={Col} controlId="clientId">
                        <Label>Client ID</Label>
                        <FormSelect value={icon.clientId} onChange={(e) => {
                            setIcon(prevIcon => ({...prevIcon, clientId: e.target.value}));
                            icon.clientId = e.target.value;
                            props.onUpdateIcon(icon);
                        }}>
                            {AssetMetaData.ASSET_CLIENTS.map(client => (
                                <option>{client}</option>
                            ))}
                        </FormSelect>
                    </Form.Group>
                    <Form.Group as={Col} controlId="resizeMethod">
                        <Label>Resize Method</Label>
                        <FormSelect value={icon.resizeMethod} onChange={(e) => {
                            setIcon(prevIcon => ({...prevIcon, resizeMethod: e.target.value}));
                            icon.resizeMethod = e.target.value;
                            props.onUpdateIcon(icon);
                        }}>
                            {AssetMetaData.RESIZE_METHOD.map(method => (
                                <option>{method}</option>
                            ))}
                        </FormSelect>
                    </Form.Group>
                </Row>
                <Row>
                    <Form.Group as={Col} controlId="width">
                        <Label>Width</Label>
                        <Form.Control value={icon.width}  readOnly={true} onChange={(e) => {
                            setIcon(prevIcon => ({...prevIcon, width: e.target.value}));
                            icon.width = e.target.value;
                            props.onUpdateIcon(icon);
                        }} />
                    </Form.Group>
                    <Form.Group as={Col} controlId="height">
                        <Label>Height</Label>
                        <Form.Control value={icon.height} readOnly={true} onChange={(e) => {
                            setIcon(prevIcon => ({...prevIcon, height: e.target.value}));
                            icon.height = e.target.value;
                            props.onUpdateIcon(icon);
                        }} />
                    </Form.Group>
                </Row>
                {icon.url != null ?
                    <>
                        <hr />
                        <h4>Preview</h4>
                        <img src={icon.url} alt={"Preview of asset " + icon.url} width="200px" height="200px" />
                    </>
                :
                    <Row>
                        <Form.Group as={Col} controlId="upload">
                            <Label>Upload</Label>
                            <Form.Control type="file" onChange={(e) => {
                                setIcon(prevIcon => ({...prevIcon, file: e.target.files[0]}));
                                icon.file = e.target.files[0];
                                props.onUpdateIcon(icon);
                            }}/>
                        </Form.Group>
                    </Row>
                }

            </Form>
        </div>
    )
}