const SeasonMetaData = {

    //Columns
    COLUMNS: [
        //Default & Badges
        {label: "ID", id: 'id', columnType: 'default', show: true},
        {label: "Name", id: 'name', columnType: 'default', show: true},
    ],

    //Details
    DETAILS_GENERAL: [
        [{label: 'ID', id: 'id', type: 'text', editable: 'never'}, {label: 'Name', id: 'name', type: 'text', editable: 'true'}, {label: "Section in Series", id: 'section', type: 'text', editable: 'true'}],
        [{label: 'Description', id: 'description', type: 'textarea', rows: 5, editable: 'true'}],
    ],

    //Sorting
    EXAMPLE_SORT_PARAMS: [{id: 'id', label: 'Example ID'}, {id: 'name', label: 'Name'}],
};

export default SeasonMetaData;