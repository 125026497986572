import {CreateSpineResource, DeleteSpineResource, GetFilteredSpineResources, GetSpineResource, UpdateSpineResource} from "../../clients/SpineClient";

export async function GetImprint(id) {
    return await GetSpineResource('api/imprints/' + id);
}

export async function GetFilteredImprints(filter){
    return await GetFilteredSpineResources('api/imprints', filter);
}

export async function CreateImprint(imprint) {
    return await CreateSpineResource('api/imprints/', imprint);
}

export async function UpdateImprint(imprint) {
    return await UpdateSpineResource('api/imprints/' + imprint.id, imprint);
}

export async function DeleteImprint(imprint) {
    return await DeleteSpineResource('api/imprints/' + imprint.id);
}