import React, {useState} from "react";
import DatePicker from "react-datepicker";
import {customDatePickerHeader, transformToDatepickerFormat, transformToLocalDate} from "../util/ResourceService";
import {
    CreateSpineFormDataResource,
    ImportReportingData,
    StartAutomaticImport
} from "../clients/SpineClient";
import {ErrorHandler} from "../util/ErrorHandler";

export function ReportingService(props) {

    let [error, setError] = useState(null);
    let [uploaded, setUploaded] = useState(false);
    let [automaticImporting, setAutomaticImporting] = useState(false);
    let [importing, setImporting] = useState(false);
    let [automaticImportMonth, setAutomaticImportMonth] = useState("");
    let [importMonth, setImportMonth] = useState("");
    let [reportMonth, setReportMonth] = useState("");
    let [data, setData] = useState();
    let [automaticImportMessage, setAutomaticImportMessage] = useState("");
    let [importMessage, setImportMessage] = useState("");
    let [reportMessage, setReportMessage] = useState("");

    const cardStyle = {
        float: "left",
        width: "400px",
        //height: "360px",
        margin: "20px",
        paddingTop: "10px",

        alignContent: "center",
        alignItems: "center",
        textAlign: "center",

        borderStyle: "solid",
        borderWidth: "1px",
        borderRadius: "10px"
    }

    return (
        <>
            {/* Header */}
            <div className="content-header">
                <div className="content-header-title">Reporting Tool</div>
            </div>

            {/* Upload */}
            <div className="flex-content-box-centered">
                <div style={cardStyle}>
                    <h4 style={{marginBottom: "80px"}}>1. Import reporting data</h4>
                    <div style={{width: "300px", marginLeft: "50px"}}>
                        <span style={{float: "left", fontWeight: "bold"}}>Import Month:</span>
                        <DatePicker wrapperClassName="datePicker"
                                    todayButton={"Today"}
                                    dateFormat={"yyyy-MM-dd"}
                                    renderCustomHeader={({
                                                             date,
                                                             changeYear,
                                                             changeMonth,
                                                             decreaseMonth,
                                                             increaseMonth,
                                                             prevMonthButtonDisabled,
                                                             nextMonthButtonDisabled
                                                         }) => customDatePickerHeader(new Date(date), changeYear, changeMonth, decreaseMonth, increaseMonth, prevMonthButtonDisabled, nextMonthButtonDisabled)}
                                    selected={transformToDatepickerFormat(automaticImportMonth)}
                                    onChange={(date) => {
                                        setAutomaticImportMonth(transformToLocalDate(date.toString()));
                                        setImportMonth(transformToLocalDate(date.toString()));
                                        setReportMonth(transformToLocalDate(date.toString()));
                                    }}/>
                    </div>
                    <div style={{width: "100%", display: "flex", flexDirection: "column"}}>

                        <button className={(!automaticImporting && automaticImportMonth !== "") ? "form-btn-ci-blue" : "form-btn-ci-off"}
                                style={{marginLeft: "15%", width: "70%", marginTop: "40px"}}
                                onClick={() => startAutomaticImport("APPLE", automaticImportMonth, setAutomaticImporting, setAutomaticImportMessage)}>{automaticImporting ? "Importing..." : "Start Apple"}</button>
                        <button className={(!automaticImporting && automaticImportMonth !== "") ? "form-btn-ci-blue" : "form-btn-ci-off"}
                                style={{marginLeft: "15%", width: "70%", marginTop: "20px"}}
                                onClick={() => startAutomaticImport("GOOGLE", automaticImportMonth, setAutomaticImporting, setAutomaticImportMessage)}>{automaticImporting ? "Importing..." : "Start Google"}</button>
                        <button className={(!automaticImporting && automaticImportMonth !== "") ? "form-btn-ci-blue" : "form-btn-ci-off"}
                                style={{marginLeft: "15%", width: "70%", marginTop: "20px"}}
                                onClick={() => startAutomaticImport("BILLWERK", automaticImportMonth, setAutomaticImporting, setAutomaticImportMessage)}>{automaticImporting ? "Importing..." : "Start Billwerk"}</button>
                    </div>

                    <div style={{
                        marginTop: "15px",
                        fontWeight: "bold",
                        textAlign: "center",
                        color: automaticImportMessage.indexOf("Error") > -1 ? "red" : "#333",
                        cursor: automaticImportMessage !== "" ? "pointer" : "default"
                    }} onClick={() => setAutomaticImportMessage("")}>{automaticImportMessage}</div>
                </div>
                <div style={cardStyle}>
                    <h4 style={{marginBottom: "80px"}}>2. Upload reporting data</h4>
                    <input type="file" name="reportFile" onChange={(e) => {
                        setData(e.target.files[0]);
                        setUploaded(true);
                    }}/>
                </div>
                <div style={cardStyle}>
                    <h4 style={{marginBottom: "35px"}}>3. Start the import</h4>
                    <div style={{width: "300px", marginLeft: "50px"}}>
                        <span style={{float: "left", fontWeight: "bold"}}>Import Month:</span>
                        <DatePicker wrapperClassName="datePicker"
                                    todayButton={"Today"}
                                    dateFormat={"yyyy-MM-dd"}
                                    renderCustomHeader={({
                                                             date,
                                                             changeYear,
                                                             changeMonth,
                                                             decreaseMonth,
                                                             increaseMonth,
                                                             prevMonthButtonDisabled,
                                                             nextMonthButtonDisabled
                                                         }) => customDatePickerHeader(new Date(date), changeYear, changeMonth, decreaseMonth, increaseMonth, prevMonthButtonDisabled, nextMonthButtonDisabled)}
                                    selected={transformToDatepickerFormat(importMonth)}
                                    onChange={(date) => {
                                        setImportMonth(transformToLocalDate(date.toString()));
                                        setReportMonth(transformToLocalDate(date.toString()));
                                    }}/>
                    </div>

                    <div style={{width: "100%", display: "inline-block"}}>
                        <button className={(uploaded && importMonth !== "") ? "form-btn-ci-blue" : "form-btn-ci-off"}
                                style={{marginLeft: "15%", width: "70%", marginTop: "40px"}}
                                disabled={!uploaded || importMonth === ""}
                                onClick={() => startImport(importMonth, data, setImporting, setImportMessage)}>{importing ? "Importing..." : "Start Import"}</button>
                    </div>

                    <div style={{
                        marginTop: "15px",
                        fontWeight: "bold",
                        textAlign: "center",
                        color: importMessage.indexOf("Error") > -1 ? "red" : "#333",
                        cursor: importMessage !== "" ? "pointer" : "default"
                    }} onClick={() => setImportMessage("")}>{importMessage}</div>

                </div>
                <div style={cardStyle}>
                    <h4 style={{marginBottom: "35px"}}>4. Start the report</h4>
                    <div style={{width: "300px", marginLeft: "50px"}}>
                        <span style={{float: "left", fontWeight: "bold"}}>Report Month:</span>
                        <DatePicker wrapperClassName="datePicker"
                                    todayButton={"Today"}
                                    dateFormat={"yyyy-MM-dd"}
                                    renderCustomHeader={({
                                                             date,
                                                             changeYear,
                                                             changeMonth,
                                                             decreaseMonth,
                                                             increaseMonth,
                                                             prevMonthButtonDisabled,
                                                             nextMonthButtonDisabled
                                                         }) => customDatePickerHeader(new Date(date), changeYear, changeMonth, decreaseMonth, increaseMonth, prevMonthButtonDisabled, nextMonthButtonDisabled)}
                                    selected={transformToDatepickerFormat(reportMonth)}
                                    onChange={(date) => setReportMonth(transformToLocalDate(date.toString()))}/>
                    </div>

                    <div style={{width: "100%", display: "inline-block"}}>
                        <button className={reportMonth !== "" ? "form-btn-ci-blue" : "form-btn-ci-off"}
                                style={{marginLeft: "15%", width: "70%", marginTop: "40px"}}
                                disabled={reportMonth === ""}
                                onClick={() => startReport(reportMonth, setReportMessage)}>Start Report
                        </button>
                    </div>

                    <div style={{
                        marginTop: "15px",
                        fontWeight: "bold",
                        textAlign: "center",
                        color: reportMessage.indexOf("Error") > -1 ? "red" : "#333",
                        cursor: reportMessage !== "" ? "pointer" : "default"
                    }} onClick={() => setReportMessage("")}>{reportMessage}</div>
                </div>
            </div>

            <ErrorHandler error={error}
                          onHide={() => setError(null)}/>
        </>
    );

    async function startAutomaticImport(importer, automaticImportMonth, setAutomaticImporting, setAutomaticImportMessage)
    {
        setAutomaticImporting(true);

        await StartAutomaticImport(importer, automaticImportMonth)
                .then((r) => {
                    setAutomaticImporting(false);
                    if(r.error) {
                        setError(r);
                    } else {
                        setAutomaticImportMessage("Import of " + importer + " data completed!");
                    }
                }).catch(error => setError(error));
    }

    async function startImport(importMonth, data, setImporting, setImportMessage)
    {
        setImporting(true);

        await ImportReportingData(importMonth, data)
                .then((r) => {
                    setImporting(false);
                if(r.error) {
                    setError(r);
                } else {
                    setImportMessage("Upload completed!");
                }
            }).catch(error => setError(error));
    }

    async function startReport(reportMonth, setReportMessage) {
        await CreateSpineFormDataResource('api/reports/generate', {reportMonth: reportMonth})
            .then((r) => {
                if(r.error) {
                    setError(r);
                } else {
                    setReportMessage("Report has been started!");
                }
            }).catch(error => setError(error));
    }
}

