import {
    CreateSpineResource,
    DeleteSpineResource,
    GetFilteredSpineResources,
    GetSpineResource,
    UpdateSpineResource
} from "../../clients/SpineClient";

//API METHODS
export async function CreateExample(example) {
    return await CreateSpineResource('api/examples', example);
}

export async function GetFilteredExamples(filter){
    return await GetFilteredSpineResources('api/examples', filter);
}

export async function GetExample(id) {
    return await GetSpineResource('api/examples/' + id);
}

export async function UpdateExample(example) {
    return await UpdateSpineResource('api/examples/' + example.id, example);
}

export async function DeleteExample(example) {
    return await DeleteSpineResource('api/examples/' + example.id);
}

//---------- OPTIONAL -------------
/*
export async function PatchExample(example, newValue) {
    return await PatchResource('api/examples/' + example.id + '/patch', {attribute: newValue});
}
 */