import {
    CreateSpineResource,
    DeleteSpineResource,
    GetFilteredSpineResources,
    GetSpineResource,
    UpdateSpineResource
} from "../../clients/SpineClient";

//API METHODS
export async function CreateLibrary(library) {
    return await CreateSpineResource('api/libraries', library);
}

export async function GetFilteredLibraries(filter){
    return await GetFilteredSpineResources('api/libraries', filter);
}

export async function GetLibrary(id) {
    return await GetSpineResource('api/libraries/' + id);
}

export async function UpdateLibrary(library) {
    return await UpdateSpineResource('api/libraries/' + library.id, library);
}

export async function DeleteLibrary(library) {
    return await DeleteSpineResource('api/libraries/' + library.id);
}

//---------- OPTIONAL -------------
/*
export async function PatchExample(example, newValue) {
    return await PatchResource('api/examples/' + example.id + '/patch', {attribute: newValue});
}
 */