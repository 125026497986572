const GlobalConstants = {
    //General details
    ENVIRONMENT: process.env["REACT_APP_CUSTOM_NODE_ENV"],
    VERSION: process.env["REACT_APP_VERSION"],

    //Server settings
    SPINE_CLIENT_HOST: process.env["REACT_APP_SPINE_CLIENT_HOST"],
    SEARCH_CLIENT_HOST: process.env["REACT_APP_SEARCH_CLIENT_HOST"],
    BILL_CLIENT_HOST: process.env["REACT_APP_BILL_CLIENT_HOST"],
    APP_PATH: process.env["REACT_APP_CLIENT_PATH"],
    WORDPRESS_CLIENT_HOST: "https://tiger.media/",
    BILLWERK_HOST: "https://app.billwerk.com/",

    //Mailjet
    MAILJET_HOST: "https://api.mailjet.com/",
    MAILJET_AUTH: 'ZTFmZTMyZWUyZDg4NDg1NTZmNTI1OWNlN2ExYTRjZmU6ZjRkNzliNGM1M2MwZThhZThiYTUzNmU0N2IyNWM3NGU=',
    MAILJET_PUBLIC_KEY: 'e1fe32ee2d8848556f5259ce7a1a4cfe',
    MAILJET_PRIVATE_KEY: 'f4d79b4c53c0e8ae8ba536e47b25c74e',
    MAILJET_TIGERTICKET_MAIL_TEMPLATES: [3279305],
    MAILJET_TIGERTIPPS_LIST_ID: 2404033,
    MAILJET_TIGERPOST_LIST_ID: 2400362,

    //Product Recommendation
    RECOMMENDATION_CLUSTER_APP_ID: process.env["REACT_APP_RECOMMENDATION_CLUSTER_APP_ID"],
    RECOMMENDATION_CLUSTER_APP_ID_FALLBACK: process.env["REACT_APP_RECOMMENDATION_CLUSTER_APP_ID_FALLBACK "],

    //Billwerk
    BILLWERK_BASE_URL: process.env["REACT_APP_BILLWERK_BASE_URL"],
    BILLWERK_OAUTH_URL: process.env["REACT_APP_BILLWERK_OAUTH_URL"],
    BILLWERK_CLIENT_ID: process.env["REACT_APP_BILLWERK_CLIENT_ID"],
    BILLWERK_CLIENT_SECRET: process.env["REACT_APP_BILLWERK_CLIENT_SECRET"],

    //Data
    AMAZONBUCKET_URL: "https://s3.eu-central-1.amazonaws.com/tigerbooks.",
    GOOGLESTORAGE_URL: "streaming-content.tiger.media/",
    AVATAR_LIST: "https://cdn.tigerbooks.de/profiles/list-avatars.json",

    //Links to entity lists
    APP_PATH_PRODUCTS_LIST: "/#/products",
    APP_PATH_SERIES_LIST: "/#/series",

    //Links to entity details
    APP_PATH_CATEGORY_DETAILS: "#/category/",
    APP_PATH_PRODUCT_DETAILS: "#/product/",
    APP_PATH_PRODUCTGROUP_DETAILS: "#/productGroup/",
    APP_PATH_SERIES_DETAILS: "#/series/",
    APP_PATH_ASSET_DETAILS: "#/asset/",

    //APPEARANCE
    SPINNER_COLOR: "orange",
    SPINNER_SECONDARY_COLOR: "black",
    //SPINNER_TYPE: "Circles",
    SPINNER_TYPE: "Oval",
    SPINNER_WIDTH: "50",
    SPINNER_HEIGHT: "50",

    //Support Tool
    PRODUCT_ID_1_WEEK_PREMIUMACCESS: process.env["REACT_APP_PRODUCT_ID_1_WEEK_PREMIUMACCESS"],
    PRODUCT_ID_1_MONTH_PREMIUMACCESS: process.env["REACT_APP_PRODUCT_ID_1_MONTH_PREMIUMACCESS"],
    PRODUCT_ID_1_YEAR_PREMIUMACCESS: process.env["REACT_APP_PRODUCT_ID_1_YEAR_PREMIUMACCESS"],
    PRODUCT_IAP_1_WEEK_PREMIUMACCESS: process.env["REACT_APP_PRODUCT_IAP_1_WEEK_PREMIUMACCESS"],
    PRODUCT_IAP_1_MONTH_PREMIUMACCESS: process.env["REACT_APP_PRODUCT_IAP_1_MONTH_PREMIUMACCESS"],
    PRODUCT_IAP_1_YEAR_PREMIUMACCESS: process.env["REACT_APP_PRODUCT_IAP_1_YEAR_PREMIUMACCESS"],
    PRODUCT_GROUP_ID_TIGERTONES: process.env["REACT_APP_PRODUCT_GROUP_ID_TIGERTONES"],
    PRODUCT_GROUP_ID_TIGERBOOKS: process.env["REACT_APP_PRODUCT_GROUP_ID_TIGERBOOKS"],
    PRODUCT_GROUP_ID_SWISS: process.env["REACT_APP_PRODUCT_GROUP_ID_SWISS"],
    PLANVARIANT_ID_PRESENT_1WEEK: process.env["REACT_APP_PLANVARIANT_ID_PRESENT_1WEEK"],
    PLANVARIANT_ID_PRESENT_1MONTH: process.env["REACT_APP_PLANVARIANT_ID_PRESENT_1MONTH"],
    PLANVARIANT_ID_PRESENT_1YEAR: process.env["REACT_APP_PLANVARIANT_ID_PRESENT_1YEAR"],
    PRODUCT_IAP_SUB_1MONTH_OLD: process.env["REACT_APP_PRODUCT_IAP_SUB_1MONTH_OLD"],
    PRODUCT_IAP_SUB_1MONTH: process.env["REACT_APP_PRODUCT_IAP_SUB_1MONTH"],
    PRODUCT_IAP_SUB_12MONTH_MIN: process.env["REACT_APP_PRODUCT_IAP_SUB_12MONTH_MIN"],
    PRODUCT_IAP_SUB_24MONTH_MIN: process.env["REACT_APP_PRODUCT_IAP_SUB_24MONTH_MIN"],
    PRODUCT_IAP_SUB_4WEEK_COUPON: process.env["REACT_APP_PRODUCT_IAP_SUB_4WEEK_COUPON"],
    PRODUCT_IAP_SUB_4WEEK_COUPON_2021: process.env["REACT_APP_PRODUCT_IAP_SUB_4WEEK_COUPON_2021"],
    PRODUCT_IAP_SUB_4WEEK_COUPON_KITA: process.env["REACT_APP_PRODUCT_IAP_SUB_4WEEK_COUPON_KITA"],
    PRODUCT_IAP_SUB_TIGERBOOKS_1WEEK: process.env["REACT_APP_PRODUCT_IAP_SUB_TIGERBOOKS_1WEEK"],
    PRODUCT_IAP_SUB_TIGERBOOKS_1MONTH: process.env["REACT_APP_PRODUCT_IAP_SUB_TIGERBOOKS_1MONTH"],
    PRODUCT_IAP_SUB_TIGERBOOKS_6MONTH: process.env["REACT_APP_PRODUCT_IAP_SUB_TIGERBOOKS_6MONTH"],

    //Purchases
    DEFAULT_LANDING_PAGE_ID: process.env["REACT_APP_DEFAULT_LANDING_PAGE_ID"],

    //Bundles
    BUNDLE_FLEX_PLAN_VARIANT_ID: process.env["REACT_APP_FLEX_PLAN_VARIANT_ID"],
    BUNDLE_12_PLAN_VARIANT_ID: process.env["REACT_APP_12_PLAN_VARIANT_ID"],
    BUNDLE_24_PLAN_VARIANT_ID: process.env["REACT_APP_24_PLAN_VARIANT_ID"]

};

export default GlobalConstants;