import React from "react";
import {usePromiseTracker} from "react-promise-tracker";
import {Spinner} from "react-bootstrap";

export const SpinningTiger = () => {
    return (
        <div className="loader">
            <span className="loader-bg"/>
        </div>
    );
}

export const PromiseButton = props => {
    const { promiseInProgress } = usePromiseTracker();
    const className = props.className != null ? props.className : "form-btn-ci-blue";
    return <button className={(promiseInProgress || props.disabled) ? "form-btn-ci-off" : className} type="button" disabled={promiseInProgress || props.disabled} onClick={props.onClick}>
            {promiseInProgress ?
                <Spinner style={{height: "16px", width: "16px"}} animation={"border"}/>
            :
                <>{props.text}</>}
        </button>
}

export const LoadingIndicator = props => {
    const { promiseInProgress } = usePromiseTracker();

    return promiseInProgress ?
            <Spinner style={{height: "16px", width: "16px"}} animation={"border"}/>
            :
            <>{props.text}</>
}