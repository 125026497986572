import {
    DeleteRestResource,
    GetFilteredRestResource,
    GetRestResource,
    CreateRestResource,
    UpdateRestResource
} from "../../clients/RestResourceClient";

export async function GetFilteredCollections(filter){
    return await GetFilteredRestResource('api/collections', filter);

}

export async function CreateCollection(collection) {
    return await CreateRestResource('api/collections', collection);
}

export async function GetCollection(id) {
    return await GetRestResource('api/collections/' + id);
}

export async function GetResourceByUrl(url) {
    return await GetRestResource(url, true);
}

export async function UpdateCollection(collection) {
    return await UpdateRestResource('api/collections/' + collection.id, collection);
}

export async function UpdateCollectionItemOrder(collection, newOrder) {
    return await UpdateRestResource('api/collections/' + collection.id + "/collectionItems/update-order", newOrder);
}

export async function DeleteCollection(collection) {
    return await DeleteRestResource('api/collections/' + collection.id);
}

export async function CreateCollectionItem(collection, item) {
    return await CreateRestResource('api/collections/' + collection.id + "/collectionItems", item);
}

export async function GetCollectionItem(collectionId, itemId) {
    return await GetRestResource('api/collections/' + collectionId + "/collectionItems/" + itemId);
}

export async function UpdateCollectionItem(collection, item) {
    return await UpdateRestResource('api/collections/' + collection.id + '/collectionItems/' + item.id, item);
}

export async function DeleteCollectionItem(item) {
    return await DeleteRestResource('api/collections/' + item.collection.id + '/collectionItems'  + item.id);
}
