const RECOMMENDATION_TYPES = [{id: 'NEW_PRODUCT', label: 'New Product'}, {id: 'SIMILIAR_PRODUCT', label: 'Similar Product'}];

const RecommendationMetaData = {

    //PRODUCTS
    PRODUCT_COLUMNS: [
        //Default & Badges
        {label: "ID", id: 'id', columnType: 'default', show: true},
        {label: "Empfehlungsbasis", id: 'notificationType', columnType: 'badge', options: [
                {value: 'NEW_PRODUCT', style: "primary"},
                {value: 'SIMILIAR_PRODUCT', style: "success"}
            ],
            show: true},
        {label: "Status", id: 'notificationStatus', columnType: 'badge', options: [
                {value: 'PENDING', style: "primary"},
                {value: 'RE_PENDING', style: "secondary"},
                {value: 'QUEUED', style: "warning"},
                {value: 'SUCCESSFUL', style: "success"},
                {value: 'ABORTED', style: "danger"},
            ],
            show: false},
        {label: "Product ID", id: 'productId', columnType: 'default', show: true},
        {label: "Product Title", id: 'title', columnType: 'default', show: true},
        {label: "Product Status", id: 'state', columnType: 'default', show: false},
        {label: "Helden ID", id: 'clusterId', columnType: 'default', show: true},
        {label: "Veröffentlichungsdatum", id: 'publicationDate', columnType: 'default', show: true},
    ],
    DETAILS_GENERAL: [],
    RECOMMENDATION_PRODUCTS_SORT_PARAMS: [],
    RECOMMENDATION_PRODUCT_TYPES: RECOMMENDATION_TYPES,

    //SUBSCRIBERS
    SUBSCRIBER_COLUMNS: [
        {label: "ID", id: 'id', columnType: 'default', show: true},
        {label: "E-Mail", id: 'email', columnType: 'default', show: true},
        {label: "Account ID", id: 'accountId', columnType: 'default', show: true},
        {label: "Profile ID", id: 'userId', columnType: 'default', show: true},
        {label: "Helden IDs", id: 'clusterIds', columnType: 'list', show: true},
        {label: "Mindestalter", id: 'ageRangeMin', columnType: 'default', show: false},
        {label: "Höchstalter", id: 'ageRangeMax', columnType: 'default', show: false},
        {label: "Aktiven Zugang", id: 'active', columnType: 'boolean', show: true},
    ],
    RECOMMENDATION_SUBSCRIBERS_SORT_PARAMS: [],

    //MAILS
    MAIL_COLUMNS: [
        {label: "ID", id: 'id', columnType: 'default', show: true},
        {label: "E-Mail", id: 'email', columnType: 'default', show: true},
        {label: "Mailjet-Template", id: 'mailjetTemplateId', columnType: 'default', show: true},
        {label: "Fehlversuche", id: 'numberRetries', columnType: 'default', show: true}
    ],
    RECOMMENDATION_MAILS_SORT_PARAMS: [],

};

export default RecommendationMetaData;