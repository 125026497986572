import {
    CreateSpineResource,
    DeleteSpineResource,
    GetFilteredSpineResources,
    GetSpineResource,
    UpdateSpineResource
} from "../../clients/SpineClient";

//API METHODS
export async function CreateSubscription(account, subscription) {
    return await CreateSpineResource('api/accounts/' + account.id + '/subscriptions', subscription);
}

export async function GetFilteredSubscriptions(account, filter){
    return await GetFilteredSpineResources('api/accounts/' + account.id + '/subscriptions', filter);
}

export async function GetSubscription(account, id) {
    return await GetSpineResource('api/accounts/' + account.id + '/subscriptions/' + id);
}

export async function UpdateSubscription(account, subscription) {
    return await UpdateSpineResource('api/accounts/' + account.id + '/subscriptions/' + subscription.id, subscription);
}

export async function DeleteSubscription(account, subscription) {
    return await DeleteSpineResource('api/accounts/' + account.id + '/subscriptions/' + subscription.id, false);
}

//---------- OPTIONAL -------------
/*
export async function PatchSubscription(subscription, newValue) {
    return await PatchResource('api/subscriptions/' + subscription.id + '/patch', {attribute: newValue});
}
 */