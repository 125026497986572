import React, {Component} from "react";
import '../../../App.scss'
import {withToast} from "../../util/ToastService";
import {
    FiSettings,
    GrObjectGroup
} from "react-icons/all";
import {ActionModal} from "../../generators/ModalGenerator";
import {ResourcesTable, TableHeader, TableSettingsDialog} from "../../generators/TableGenerator";
import "react-datepicker/dist/react-datepicker.css";
import AssetMetaData from "./AssetMetaData";
import {DeleteAsset, GetFilteredAssets} from "./AssetService";
import {generateAssetDownloadUrl, openExternalTargetInNewTab} from "../../util/ResourceService";
import {Button} from "react-bootstrap";
import {SpinningTiger} from "../../global/SpinningTiger";
import {ErrorHandler} from "../../util/ErrorHandler";

class Assets extends Component {

    //------------
    //Constructor
    //------------

    constructor(props) {
        super(props);

        this.state = {
            error: null,
            loading: false,
            assets: [],
            filter: props.location.state != null ? props.location.state.filter :
                {page: 1, query: "", per_page: 20, sortBy: "id", sortDirection: "ASC"},
            lastPage: props.location.state != null ? props.location.state.lastPage : 0,
            columns: props.location.state != null ? props.location.state.columns : AssetMetaData.COLUMNS,
            customColumnData: [
                {id: 'preview', dataset: []},
                {id: 'url', dataset: []}
            ],
            showDialog: {
                tableSettings: false,
                deletionModal: false,
                dialog_1: false,
                dialog_2: false,
                dialog_3: false
            },
        };

        this.resetFilter = this.resetFilter.bind(this);
        this.updateFilter = this.updateFilter.bind(this);
        this.showOrHideDialog = this.showOrHideDialog.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.loadAssetList = this.loadAssetList.bind(this);
    }

    //---------
    //Mounting
    //---------

    async componentDidMount() {
        //Set the title
        document.title = "Assets :: Tiger UI";

        await this.loadAssetList(this.state.filter);
    }

    //--------
    //Loading
    //--------

    async loadAssetList(filter) {

        this.setState(prevState => ({...prevState, loading: true}));

        const loadedAssets = await GetFilteredAssets(filter);

        if (loadedAssets.error === true) {
            this.setState(prevState => ({...prevState, error: loadedAssets}));
        } else {
            loadedAssets.result.forEach(asset => {
                if (this.state.assets.filter(ex => (ex.isChecked === true)).includes(asset)) {
                    asset.isChecked = true;
                }
            });
        }

        this.setState(prevState => ({
            ...prevState,
            loading: false,
            assets: loadedAssets.result,
            lastPage: Math.ceil(loadedAssets.length/this.state.filter.per_page),
            filter: filter
        }));

        this.setCustomData();

    }

    async applyFilter(e, filter) {
        e.preventDefault();
        await this.loadAssetList(filter);
    }

    setCustomData() {
        let customValues = this.state.customColumnData;

        //Generate the preview (if the asset is an image)
        let previews = [];
        for(let i = 0; i < this.state.assets.length; i++) {
            if(this.state.assets[i].assetType === 'IMAGE') {
                previews.push(generateAssetDownloadUrl(this.state.assets[i]));
            } else {
                previews.push(null);
            }
        }
        customValues[0].dataset = previews;
        customValues[1].dataset = this.state.assets.map(asset => this.transformUrlToLink(asset));
        this.setState(prevState => ({...prevState, customColumnData: customValues}));
    }

    //----------
    //Rendering
    //----------

    render() {
        return (
            <div style={{marginBottom: 50}}>
                <div className="content-header">
                    <div className="content-header-title">Assets</div>
                    <hr/>
                    <div className="content-header-description"><GrObjectGroup/>&#xA0;View and create assets
                    </div>
                </div>

                {/* FILTER */}
                <div className="content-box-centered">
                    <div className="content-box-title">Filter</div>
                    <div className="content-box-body">
                        <form onSubmit={(e) => this.applyFilter(e, this.state.filter)}>
                            <div className="row">
                                <div className="col-md-6">
                                    <label>Query</label>
                                    <input type="text" value={this.state.filter.query}
                                           onChange={(e) => this.updateFilter('query', e.target.value)}/>
                                </div>
                            </div>
                            <hr/>
                            <div className="row">
                                <div className="col-md-3" style={{marginLeft: "auto"}}>
                                    <button className="form-btn-ci-light" type="button"
                                            onClick={() => this.resetFilter()}>Reset
                                    </button>
                                    <button className="form-btn-ci-blue" type="submit" onClick={(e) => this.applyFilter(e, this.state.filter)}>Apply Filter</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                {/* SHOW THE PRODUCT LIST*/}
                <div className="content-box-centered">
                    <div className="content-box-title">Assets List
                        <div style={{float: "right", fontWeight: "normal", cursor: "pointer"}}
                             onClick={() => this.showOrHideDialog('tableSettings', true)}><FiSettings/></div>
                    </div>
                    <div className="content-box-body">
                        <TableHeader nameInState="assets"
                                     deletionForbidden
                                     sortParams={AssetMetaData.ASSET_SORT_PARAMS}
                                     state={this.state}
                                     onSetState={(s) => this.setState(s)}
                                     onLoadPage={() => this.loadAssetList(this.state.filter)}/>
                        <hr/>
                        {this.state.loading ?
                            <SpinningTiger />
                        :
                            <ResourcesTable
                                state={this.state}
                                columns={this.state.columns}
                                customcolumndata={this.state.customColumnData}
                                dataset={this.state.assets}
                                page={this.state.filter.page}
                                lastPage={this.state.lastPage}
                                onLoadPage={(page) => this.loadAssetList({...this.state.filter, page: page})}
                                selectable
                                resourcesurl='assets'
                                nameInState='assets'
                                onToggleResource={(allAssets) => this.setState(prevState => ({
                                    ...prevState,
                                    assets: allAssets
                                }))}/>
                            }
                    </div>
                </div>

                {/* TABLE SETTINGS */}
                <TableSettingsDialog show={this.state.showDialog.tableSettings}
                                     columns={this.state.columns}
                                     onSetState={(columns) => this.setState(prevState => ({
                                         ...prevState,
                                         columns: columns
                                     }))}
                                     onHide={() =>this.showOrHideDialog('tableSettings', false)}
                />

                {/* DIALOGS */}
                <ActionModal show={this.state.showDialog.deletionModal}
                             onHide={() => this.showOrHideDialog('deletionModal', false)}
                             onAction={() => this.deleteSelectedAssets()}
                             actionButtonText="Delete"
                             title={"Delete assets"}
                             body={"Are you sure you want to delete the selected assets?"}/>
                <ErrorHandler error={this.state.error}
                              onHide={() => this.setState(prevState => ({...prevState, error: null}))} />
            </div>
        );
    }

    //--------
    //ACTIONS
    //--------

    async deleteSelectedAssets() {
        this.state.assets
            .filter(asset => asset.isChecked === true)
            .forEach(asset => DeleteAsset(asset));

        await this.loadAssetList(this.state.filter);

        this.showOrHideDialog('deletionModal', false);
    }

    //----------------
    // STATE HANDLING
    //----------------

    updateFilter(id, value) {
        //Get the state and the filter
        let state = this.state;
        let filter = state.filter;

        //Update the attribute
        filter[id] = value;

        //Set the state
        this.setState(prevState => ({...prevState, filter: filter}));
    }

    showOrHideDialog(dialogId, show) {
        //Get the state and the dialog
        let state = this.state;
        let dialogs = state.showDialog;

        //Update the visibility
        dialogs[dialogId] = show;

        //Set the state
        this.setState(prevState => ({...prevState, showDialog: dialogs}));
    }

    async resetFilter(){
        let filter = {page: 1, query: "", per_page: 20, sortBy: "id", sortDirection: "ASC"};
        this.setState(prevState =>({
            ...prevState,
            filter:filter
        }));
        await this.loadAssetList(filter);
    }

    transformUrlToLink(asset) {

        let link = generateAssetDownloadUrl(asset)

        if (link != null) {
            return (
                <>
                    <Button variant="outline-secondary"
                            onClick={() => openExternalTargetInNewTab(link)}>Open</Button>
                    &#xA0;
                    &#xA0;
                    <Button variant="outline-secondary"
                            onClick={() => {
                                navigator.clipboard.writeText(link).then(r => {
                                    this.props.addToast("URL copied to clipboard", {
                                        autoDismiss: true,
                                        appearance: 'info'
                                    });
                                });
                            }}>Copy</Button>
                </>
            )
        } else {
            return null;
        }
    }
}

export default withToast(Assets);