import {
    CreateSpineFormDataResource,
    CreateSpineResource, DeleteSpineResource,
    GetAllSpineResources, GetFilteredSpineResources,
    GetSpineResource,
    PatchSpineResourceWithBody,
    UpdateSpineResource
} from "../../clients/SpineClient";
import {GetAsset} from "../assets/AssetService";
import GlobalConstants from "../../config/GlobalConstants";
import {GetFilteredRestResource} from "../../clients/RestResourceClient";

//API METHODS - PRODUCTS
export async function GetFilteredProducts(filter){
    return await GetFilteredRestResource('api/searches/products-by-clients', filter, null);
}

export async function GetFilteredNonAudioProducts(filter){
    return await GetFilteredSpineResources('api/products', filter);
}

export async function GetProduct(id) {
    return await GetSpineResource('api/products/' + id);
}

export async function GetAssetProductsOfProduct(productId) {
    return await GetAllSpineResources('api/products/' + productId + '/assets');
}

export async function GetAllProductGroups(){
    return await GetAllSpineResources('api/productGroups');
}

export async function CreateProduct(product){
    return await CreateSpineResource('api/products', product);
}

export async function DeleteProduct(product) {
    return await DeleteSpineResource('api/products/' + product.id);
}

export async function UpdateProduct(product) {
    return await UpdateSpineResource('api/products/' + product.id, product);
}

export async function UpdateProductState(product, newState) {
    if(product.state !== newState) {
        return await PatchSpineResourceWithBody('api/products/' + product.id + '/change_state', {state: newState});
    } else {
        return {error: true, message: "The product already is in state " + newState, result: {}};
    }
}

export async function UpdateAgeMin(product, ageMin) {
    if(product.ageMin !== ageMin) {
        return await PatchSpineResourceWithBody('api/products/' + product.id + '/change_age_min', {ageMin: ageMin});
    } else {
        return null;
    }
}

//API METHODS - PRODUCT GROUPS
export async function AddProductToProductGroup(product, productGroup){
    return await CreateSpineResource('api/productGroups/' + productGroup.id + '/products', {id: product.id}, false);
}

export async function RemoveProductFromProductGroup(product, productGroup) {
    return await DeleteSpineResource('api/productGroups/' + productGroup.id + '/products/' + product.id, false);
}

//API METHODS - CATEGORIES
export async function AddCategoriesToProducts(products, categories){

    let categoryIds = [];

    //Create the request
    categories.forEach(category => {
        categoryIds.push(category.id);
    });

    products.forEach(product => {
        //Now perform it
        PatchSpineResourceWithBody('api/products/' + product.id + '/add_categories', {categoryIds: categoryIds});
    });
}

//API METHODS - ASSETS
export async function GetAssetsOfProduct(product) {

    //First receive the asset products
    let assetProducts = await GetAssetProductsOfProduct(product.id);

    if(!assetProducts.error) {
        product.assetProducts = assetProducts.result;

        //Now get the assets
        let assets = [];
        for(const assetProduct of product.assetProducts) {
            let loadedAsset = await GetAsset(assetProduct.assetId);

            if(!loadedAsset.error) {
                let asset = loadedAsset.result;

                //Get the asset url
                asset.url = generateDownloadUrl(asset);

                assets.push(asset);

                //Get the url of the cover asset
                if(assetProduct.assetProductType === "COVER") {
                    product.coverUrl = generateDownloadUrl(asset);
                }
            }
        }
        product.assets = assets;

    } else {
        product.assetProducts = [];
        product.assets = [];
    }

    return product;
}

export async function UploadAsset(asset) {
    return await CreateSpineFormDataResource('api/assets', asset);
}

//API METHODS - ASSET PRODUCTS
export async function DeleteAssetProduct(assetProduct) {
    return await DeleteSpineResource('/api/products/' + assetProduct.productId + '/assets/' + assetProduct.id, false);
}

export async function UpdateAssetProduct(assetProduct) {
    return await UpdateSpineResource('api/products/' + assetProduct.productId + '/assets/' + assetProduct.id, assetProduct);
}

export async function CreateAssetProduct(assetProduct) {
    return await CreateSpineResource('api/products/' + assetProduct.productId + '/assets', assetProduct);
}

//HELPER
export function generateDownloadUrl(asset) {
    var generatedUrl = null;
    var visibility = null;

    //Visibility (required for the amazon bucket)
    if (asset.visibility === "PUBLIC") {
        visibility = ".public/";
    } else if (asset.visibility === "PROTECTED") {
        visibility = ".protected/";
    }

    if (asset.storage === "AMAZON_S3") {
        generatedUrl = GlobalConstants.AMAZONBUCKET_URL + GlobalConstants.ENVIRONMENT + visibility + asset.storageReferenceId;
    } else if (asset.storage === "GOOGLE_CLOUD_STORAGE") {
        //Default prefix of the google storage reference id
        let urlPrefix = "gs://";
        //Check if the current id has the default prefix
        if (!asset.storageReferenceId.includes(urlPrefix)) {
            //No -> Get it
            urlPrefix = asset.storageReferenceId.substring(0, asset.storageReferenceId.indexOf(GlobalConstants.GOOGLESTORAGE_URL));
        }
        //Now replace the prefix by https://
        generatedUrl = asset.storageReferenceId.replace(urlPrefix, "https://");
    }

    return generatedUrl;
}