import React, {Component} from "react";
import '../../../App.scss'
import {withToast} from "../../util/ToastService";
import {FiSettings, GrObjectGroup} from "react-icons/all";
import {ActionModal} from "../../generators/ModalGenerator";
import {ResourcesTable, TableHeader, TableSettingsDialog} from "../../generators/TableGenerator";
import "react-datepicker/dist/react-datepicker.css";
import CouponGroupMetaData from "./CouponGroupMetaData";
import {DeleteCouponGroup, GetFilteredCouponGroups} from "./CouponGroupService";
import GlobalConstants from "../../config/GlobalConstants";
import {GetFilteredNonAudioProducts} from "../products/ProductService";
import {Badge} from "react-bootstrap";
import {SpinningTiger} from "../../global/SpinningTiger";
import {ErrorHandler} from "../../util/ErrorHandler";

class CouponGroups extends Component {

    //------------
    //Constructor
    //------------

    constructor(props) {
        super(props);

        this.state = {
            error: null,
            loading: false,
            couponGroups: [],
            filter: props.location.state != null ? props.location.state.filter :
                {page: 1, name: "", couponCode: "", per_page: 20, sortBy: "id", sortDirection: "ASC"},
            lastPage: props.location.state != null ? props.location.state.lastPage : 0,
            columns: props.location.state != null ? props.location.state.columns : CouponGroupMetaData.COLUMNS,
            customColumnData: [
                {id: 'productIds', dataset: []}
                ],
            showDialog: {
                tableSettings: false,
                deletionModal: false,
                dialog_1: false,
                dialog_2: false,
                dialog_3: false
            },
        };

        this.resetFilter = this.resetFilter.bind(this);
        this.updateFilter = this.updateFilter.bind(this);
        this.showOrHideDialog = this.showOrHideDialog.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.loadCouponGroupList = this.loadCouponGroupList.bind(this);
        this.generateProductDetails = this.generateProductDetails.bind(this);
    }

    //---------
    //Mounting
    //---------

    async componentDidMount() {
        //Set the title
        document.title = "Coupon Groups :: Tiger UI";

        await this.loadCouponGroupList(this.state.filter);
    }

    //--------
    //Loading
    //--------

    async loadCouponGroupList(filter) {



        this.setState(prevState => ({...prevState, loading: true}));

        const loadedCouponGroups = await GetFilteredCouponGroups(filter);

        if (loadedCouponGroups.error === true) {
            this.setState(prevState => ({...prevState, error: loadedCouponGroups}));
        } else {
            let couponGroups = loadedCouponGroups.result;
            couponGroups.forEach(couponGroup => {
                if (this.state.couponGroups.filter(ex => (ex.isChecked === true)).includes(couponGroup)) {
                    couponGroup.isChecked = true;
                }
            });

        }

        this.setState(prevState => ({
            ...prevState,
            loading: false,
            couponGroups: loadedCouponGroups.result,
            lastPage: Math.ceil(loadedCouponGroups.length/this.state.filter.per_page),
            filter: filter
        }));

        await this.setCustomData();
    }

    async applyFilter(e, filter) {
        e.preventDefault();
        await this.loadCouponGroupList(filter);
    }

    async setCustomData() {
        let customData = this.state.customColumnData;
        let couponGroups = this.state.couponGroups;
        for(let i = 0; i < couponGroups.length; i++) {
            let loadedProducts = await GetFilteredNonAudioProducts({productId: couponGroups[i].productIds});

            if(!loadedProducts.error) {
                let products = loadedProducts.result;
                customData[0].dataset[i] = this.generateProductDetails(products);
            }
        }
        this.setState(prevState => ({...prevState, customColumnData: customData}));
    }

    //----------
    //Rendering
    //----------

    render() {
        return (
            <div style={{marginBottom: 50}}>
                <div className="content-header">
                    <div className="content-header-title">Coupon Groups</div>
                    <hr/>
                    <div className="content-header-description"><GrObjectGroup/>&#xA0;View, create and maintain coupon groups
                    </div>
                </div>

                {/* FILTER */}
                <div className="content-box-centered">
                    <div className="content-box-title">Filter</div>
                    <div className="content-box-body">
                        <form onSubmit={(e) => this.applyFilter(e, this.state.filter)}>
                            <div className="row">
                                <div className="col-md-5">
                                    <label>Name</label>
                                    <input type="text" value={this.state.filter.name}
                                           onChange={(e) => this.updateFilter('name', e.target.value)}/>
                                </div>
                                <div className="col-md-4">
                                    <label>Coupon Code</label>
                                    <input type="text" value={this.state.filter.couponCode}
                                           onChange={(e) => this.updateFilter('couponCode', e.target.value)}/>
                                </div>
                            </div>
                            <hr/>
                            <div className="row">
                                <div className="col-md-3" style={{marginLeft: "auto"}}>
                                    <button className="form-btn-ci-light" type="button"
                                            onClick={() => this.resetFilter()}>Reset
                                    </button>
                                    <button className="form-btn-ci-blue" type="submit" onClick={(e) => this.applyFilter(e, this.state.filter)}>Apply Filter</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                {/* SHOW THE PRODUCT LIST*/}
                <div className="content-box-centered">
                    <div className="content-box-title">Coupon Groups List
                        <div style={{float: "right", fontWeight: "normal", cursor: "pointer"}}
                             onClick={() => this.showOrHideDialog('tableSettings', true)}><FiSettings/></div>
                    </div>
                    <div className="content-box-body">
                        <TableHeader nameInState="couponGroups"
                                     viewOnly={false}
                                     deletionForbidden={false}
                                     sortParams={CouponGroupMetaData.COUPON_GROUP_SORT_PARAMS}
                                     state={this.state}
                                     onSetState={(s) => this.setState(s)}
                                     onLoadPage={() => this.loadCouponGroupList(this.state.filter)}/>
                        <hr/>
                        {this.state.loading ?
                            <SpinningTiger />
                        :
                            <ResourcesTable
                                state={this.state}
                                columns={this.state.columns}
                                customcolumndata={this.state.customColumnData}
                                dataset={this.state.couponGroups}
                                page={this.state.filter.page}
                                lastPage={this.state.lastPage}
                                onLoadPage={(page) => this.loadCouponGroupList({...this.state.filter, page: page})}
                                selectable={true}
                                resourcesurl='couponGroups'
                                nameInState='couponGroups'
                                onToggleResource={(allCouponGroups) => this.setState(prevState => ({
                                    ...prevState,
                                    couponGroups: allCouponGroups
                                }))}/>
                        }
                    </div>
                </div>

                {/* TABLE SETTINGS */}
                <TableSettingsDialog show={this.state.showDialog.tableSettings}
                                     columns={this.state.columns}
                                     onSetState={(columns) => this.setState(prevState => ({
                                         ...prevState,
                                         columns: columns
                                     }))}
                                     onHide={() =>this.showOrHideDialog('tableSettings', false)}
                />

                {/* DIALOGS */}
                <ActionModal show={this.state.showDialog.deletionModal}
                             onHide={() => this.showOrHideDialog('deletionModal', false)}
                             onAction={() => this.deleteSelectedCouponGroups()}
                             actionButtonText="Delete"
                             title={"Delete couponGroups"}
                             body={"Are you sure you want to delete the selected couponGroups?"}/>
                <ErrorHandler error={this.state.error}
                              onHide={() => this.setState(prevState => ({...prevState, error: null}))} />
            </div>
        );
    }

    //--------
    //ACTIONS
    //--------

    async deleteSelectedCouponGroups() {
        this.state.couponGroups
            .filter(couponGroup => couponGroup.isChecked === true)
            .forEach(couponGroup => DeleteCouponGroup(couponGroup));

        await this.loadCouponGroupList(this.state.filter);

        this.showOrHideDialog('deletionModal', false);
    }

    //----------------
    // STATE HANDLING
    //----------------

    updateFilter(id, value) {
        //Get the state and the filter
        let state = this.state;
        let filter = state.filter;

        //Update the attribute
        filter[id] = value;

        //Set the state
        this.setState(prevState => ({...prevState, filter: filter}));
    }

    showOrHideDialog(dialogId, show) {
        //Get the state and the dialog
        let state = this.state;
        let dialogs = state.showDialog;

        //Update the visibility
        dialogs[dialogId] = show;

        //Set the state
        this.setState(prevState => ({...prevState, showDialog: dialogs}));
    }

    async resetFilter(){
        let filter = {page: 1, name: "", couponCode: "", per_page: 20, sortBy: "id", sortDirection: "ASC"};
        this.setState(prevState =>({
            ...prevState,
            filter:filter
        }));
        await this.loadCouponGroupList(filter);
    }

    //--------
    // HELPERS
    //--------

    generateProductDetails(products) {

        return (
            <ul style={{listStyle: "none"}}>
                {products.map(product => (
                    <li><Badge bg={
                        product.productType === 'TIGERBOOK' ? "primary" :
                        product.productType === 'MOVIE' ? "info" :
                        product.productType === 'AUDIOBOOK' ? "light" :
                        product.productType === 'BOOK' ? "success" : "dark"
                    }>{product.productType}</Badge> <a style={{fontStyle: "italic", color: "#333"}} href={GlobalConstants.SPINE_CLIENT_HOST + GlobalConstants.APP_PATH + "products/" + product.id}>{product.id}</a> - {product.title}</li>
                ))}
            </ul>
        );
    }

}

export default withToast(CouponGroups);