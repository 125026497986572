const CouponGroupMetaData = {

    //Columns
    COLUMNS: [
        //Default & Badges
        {label: "ID", id: 'id', columnType: 'default', show: true},
        {label: "Name", id: 'name', columnType: 'default', show: true},
        {label: "Usage Limit", id: 'usageLimit', columnType: 'default', show: true},
        {label: "Aggregated Usage", id: 'aggregatedUsage', columnType: 'default', show: true},
        {label: "Valid From", id: 'validFrom', columnType: 'default', show: true},
        {label: "Valid Until", id: 'validUntil', columnType: 'default', show: true},
        {label: "Shared Pseudo Amount", id: 'sharedPseudoAmountCoupon', columnType: 'boolean', show: false},
        {label: "Products", id: 'productIds', columnType: 'customValue', show: true},
    ],

    //Details
    DETAILS_GENERAL: [
        [{label: 'ID', id: 'id', type: 'text', editable: 'never'}, {label: 'Name', id: 'name', type: 'text', editable: 'true'}],
    ],
    COUPON_DETAILS: [
        [{label: 'Usage Limit', id: 'usageLimit', type: 'text', editable: 'true'}, {label: 'Aggregated Usage', id: 'aggregatedUsage', type: 'text', editable: 'never'}],
        [{label: 'Valid From', id: 'validFrom', type: 'date', editable: 'true'}, {label: 'Valid Until', id: 'validUntil', type: 'date', editable: 'true'}]
    ],

    //Sorting
    COUPON_GROUP_SORT_PARAMS: [],
};

export default CouponGroupMetaData;