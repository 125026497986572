import {useState} from "react";
import {Col, Form} from "react-bootstrap";
import Row from 'react-bootstrap/Row';
import {RemoveFromNewsletterList, SendDoubleOptInMail} from "../SupportToolService";
import {Label} from "reactstrap";
import {HiUserRemove, RiMailSendLine} from "react-icons/all";
import {PromiseButton} from "../../../global/SpinningTiger";

export function ManageNewsletterDialog(props) {

    let [email, setEmail] = useState(props.account.email);
    let [tigertipps, setTigertipps] = useState(true);
    let [tigerpost, setTigerpost] = useState(true);
    let [newProduct, setNewProduct] = useState(true);

    return(
        <>
            <Form>
                <Row>
                    <Form.Group as={Col}>
                        <Label>E-Mail-Adresse</Label>
                        <Form.Control type="text"
                                      value={email}
                                      onChange={(e) => {
                                          setEmail(e.target.value);
                                      }}
                                      placeholder="Neue E-Mail-Adresse"/>
                    </Form.Group>
                </Row>
                <Row>
                    <Form.Group as={Col}>
                        <Label>Newsletter</Label>
                        <div style={{margin: "5px auto", justifyContent: "space-around", display: "flex", textAlign: "center", width: "80%"}}>
                            <Form.Check className="w-30" label="tigertipps" value={tigertipps} checked={tigertipps} onChange={() => setTigertipps(!tigertipps)}/>
                            <Form.Check className="w-30" label="tigerpost" value={tigerpost} checked={tigerpost} onChange={() => setTigerpost(!tigerpost)}/>
                            <Form.Check className="w-30" label="Content-News" value={newProduct} checked={newProduct} onChange={() => setNewProduct(!newProduct)}/>
                        </div>
                    </Form.Group>
                </Row>
                <Row>
                    <div style={{display: "flex", justifyContent: "space-around", width: "500px", margin: "5px auto"}}>
                        <PromiseButton className="form-btn-ci-red w-30" type="button" text={<><HiUserRemove size={20}/> Abmelden</>}
                                       onClick={() => removeFromNewsletterList(email, tigertipps, tigerpost, newProduct).then(r => {
                                           if(r.error) {
                                               props.parentProps.addToast("Beim Abmelden vom Newsletter ist ein Fehler aufgetreten: " + r.message, {
                                                   appearance: "error",
                                                   autoDismiss: true
                                               });
                                           } else {
                                               props.parentProps.addToast("Der Kunde wurde erfolgreich abgemeldet.", {
                                                   appearance: "success",
                                                   autoDismiss: true
                                               });
                                           }
                                       })}
                            />
                        <PromiseButton className="form-btn-ci-blue w-70" type="button" text={<><RiMailSendLine size={20}/> Bestätigungsmail verschicken*</>}
                                       onClick={() => sendDoubleOptInMail(email, tigertipps, tigerpost, newProduct).then(r => {
                                           if(r.error) {
                                               props.parentProps.addToast("Beim Versand der E-Mail ist ein Fehler aufgetreten: " + r.message, {
                                                   appearance: "error",
                                                   autoDismiss: true
                                               });
                                           } else {
                                               props.parentProps.addToast("Die E-Mail wurde erfolgreich verschickt.", {
                                                   appearance: "success",
                                                   autoDismiss: true
                                               });
                                           }
                                       })}
                            />
                    </div>
                    <div style={{width: "100%", margin: "auto"}}>
                        <Form.Text>* Aus Datenschutzgründen dürfen wir Kunden nicht selbst für Newsletter anmelden, sondern lediglich eine E-Mail zur Anmeldebestätigung (=Bestätigungsmail) verschicken.</Form.Text>
                    </div>
                </Row>
            </Form>
            <hr />
            <p style={{float: "right"}}>
                <button className="form-btn-ci-light" onClick={props.onHide}>Schließen</button>
            </p>
        </>
    );
}

async function sendDoubleOptInMail(email, tigertipps, tigerpost, newProduct) {
    return await SendDoubleOptInMail({email: email, tigertipps: tigertipps, tigerpost: tigerpost, newProduct: newProduct});
}

async function removeFromNewsletterList(email, tigertipps, tigerpost, newProduct) {
    return await RemoveFromNewsletterList({email: email, tigertipps: tigertipps, tigerpost: tigerpost, newProduct: newProduct});
}