const AssetMetaData = {

    //Columns
    COLUMNS: [
        //Default & Badges
        {label: "ID", id: 'id', columnType: 'default', show: true},
        {label: "Visibility", id: 'visibility', columnType: 'badge', options: [
                {value: 'PUBLIC', style: 'primary'},
                {value: 'PROTECTED', style: 'warning'}
            ],
            show: true},
        {label: "Storage", id: 'storage', columnType: 'badge', options: [
                {value: 'GOOGLE_CLOUD_STORAGE', style: 'primary'},
                {value: 'AMAZON_S3', style: 'danger'},
                {value: 'FILE_SYSTEM', style: 'success'}
            ],
            show: true},
        {label: "Version", id: 'version', columnType: 'default', show: false},
        {label: "Name", id: 'name', columnType: 'default', show: true},
        {label: "Asset Type", id: 'assetType', columnType: 'default', show: true},
        {label: "MIME Type", id: 'mimeType', columnType: 'default', show: false},
        {label: "Size", id: 'size', columnType: 'default', show: true},
        {label: "Width", id: 'width', columnType: 'default', show: false},
        {label: "Height", id: 'height', columnType: 'default', show: false},
        {label: "md5Hash", id: 'md5Hash', columnType: 'default', show: true},
        {label: "Storage Reference ID", id: 'storageReferenceId', columnType: 'default', show: false},

        {label: "URL", id: 'url', columnType: 'customValue', show: true},
        {label: "Image Preview", id: 'preview', columnType: 'image', show: true},

    ],

    //Details
    DETAILS_GENERAL: [
        [{label: 'ID', id: 'id', type: 'text', editable: 'never'}, {label: 'Name', id: 'name', type: 'text', editable: 'new', required: true}, {label: "Version", id: 'version', type: 'text', editable: 'never'}],
        [{label: 'Asset Type', id: 'assetType', type: 'select', options: ['IMAGE', 'BINARY', 'AUDIO', 'VIDEO', 'HLS'], editable: 'new', required: true}, {label: 'MIME Type', id: 'mimeType', type: 'text', editable: 'never'}],
    ],
    DETAILS_STORAGE: [
        [{label: 'Visibility', id: 'visibility', type: 'select', options: ['PUBLIC', 'PROTECTED'], editable: 'new', required: true}, {label: 'Storage', id: 'storage', type: 'select', options: ["GOOGLE_CLOUD_STORAGE", "AMAZON_S3", "FILE_SYSTEM"], editable: 'new'}],
        [{label: 'md5Hash', id: 'md5Hash', type: 'text', editable: 'never'}, {label: 'Storage Reference ID', id: 'storageReferenceId', type: 'text', editable: 'never'}],
    ],
    DETAILS_APPEARANCE: [
        [{label: 'Size', id: 'size', type: 'text', editable: 'never'}, {label: 'Width', id: 'width', type: 'text', editable: 'never'}, {label: 'Height', id: 'height', type: 'text', editable: 'never'} ],
    ],

    //Sorting
    ASSET_SORT_PARAMS: [],

    //Selects
    ASSET_CLIENTS: ['','billwerkClient','ekzdatronic','gurkentest','infrastructurezipkin','internal_content','internal_device','internal_spine','internal_tigercards','internal_transcode','test_postman','test_swagger','tibabibowapp','tibahesbapp','tibamobilecomapp','tibao2app','tibasbkberlinapp','tibavoebbapp','tigerbooks_appquisine','tigerbooksacp','tigerbooksapp'],
    RESIZE_METHOD: ['','COVER','CONTAIN'],
};

export default AssetMetaData;