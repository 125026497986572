import {Button, FormControl, InputGroup, Pagination, Table} from "react-bootstrap";
import React, {Component} from "react";
import {withToast} from "../../util/ToastService";
import {Link} from "react-router-dom";
import {FaBook, IoPeopleCircleOutline,} from "react-icons/all";
import GlobalConstants from "../../config/GlobalConstants";
import {CreateLibraryPool, GetLibraryPool, UpdateLibraryPool} from "./LibraryPoolService";
import {DetailsSection, RequiredFieldsAreValid} from "../../generators/DetailsGenerator";
import LibraryPoolMetaData from "./LibraryPoolMetaData";
import {GetFilteredLibraries, UpdateLibrary} from "../libraries/LibraryService";
import {ActionModal} from "../../generators/ModalGenerator";
import {PromiseButton} from "../../global/SpinningTiger";
import {ErrorHandler} from "../../util/ErrorHandler";

class LibraryPoolDetails extends Component {

    //------------
    //Constructor
    //------------

    constructor(props) {
        super(props);

        this.state = {
            error: null,
            flowState: props.location.state,
            id: this.props.match.params.id,
            editMode: {
                active: false,
                expertMode: false,
                autoSave: true,
            },
            libraryPool: {},
            librariesInPool: [],
            originalLibraryPool: {},
            pickLibraryDialog: false,
            libraries: [],
            libraryFilterPage: 1,
            libraryFilterName: "",
            sections: {
                general: true,
                ownerDetails: true,
                libraryDetails: true,
            },
            validationErrors: []
        }
    }

    //---------
    //Mounting
    //---------

    async componentDidMount() {
        //Set the title
        if(this.state.id != null) {
            document.title = "Library Pool " + this.state.id + " :: Tiger UI";
        } else {
            document.title = "New library pool :: Tiger UI";
        }
        await this.loadLibraryPool();
        await this.loadLibrariesInPool();
    }

    //--------
    //Loading
    //--------

    async loadLibraryPool() {
        let loadedLibraryPool = {};

        if(this.state.id != null && this.state.id !== "add") {

            loadedLibraryPool = await GetLibraryPool(this.state.id);

            if (!loadedLibraryPool.error) {
                const libraryPool = loadedLibraryPool.result;

                this.setState({
                    id: this.state.id,
                    editMode: this.state.editMode,
                    libraryPool: libraryPool,
                    originalLibraryPool: libraryPool,
                });
            } else {
                this.setState(prevState => ({...prevState, error: loadedLibraryPool}));
            }
        } else {
            this.setState((prevState) => ({...prevState,
                id: null,
                libraryPool: {
                    id: null,
                    cardinality: "SINGLE"
                },
                editMode: {
                    active: true,
                    expertMode: false,
                    autoSave: false,
                }}));
        }
    }

    //----------
    //Rendering
    //----------

    render() {

        return (
            <>
                <div className="details-title">
                    {this.state.libraryPool.id != null ? "Details of LibraryPool " + this.state.libraryPool.id : "Create a new LibraryPool"}
                </div>
                <div className="details-button-box" style={{height: "70px"}}>
                    <Link to={{pathname: "/" + GlobalConstants.APP_PATH +"libraryPools", state: this.state.flowState}}>
                        <button className="form-btn-ci-light-blue" type="button">Back</button>
                    </Link>
                    {this.state.libraryPool.id != null &&
                    <button className={this.state.editMode.active ? "form-btn-ci-red" : "form-btn-ci-blue"} type="button" onClick={() => this.setState({
                        editMode: {
                            active: !this.state.editMode.active,
                            expertMode: this.state.editMode.expertMode
                        },
                        libraryPool: this.state.originalLibraryPool
                    })}>{this.state.editMode.active ? "Cancel" : "Edit"}</button>
                    }
                    {this.state.editMode.active &&
                    <PromiseButton text="Save" onClick={() => this.saveOrUpdateLibraryPool()} />
                    }
                    <Button style={{float: "right", marginTop: "5px", marginRight: "10px"}} variant={this.state.editMode.autoSave ? "secondary": "outline-secondary"}
                            onClick={() => this.setState(prevState => ({
                                ...prevState,
                                editMode: {
                                    ...prevState.editMode,
                                    autoSave: !this.state.editMode.autoSave
                                }
                            }))}>Auto Save</Button>
                </div>

                <div className="details-box-title">Resource Details</div>
                <div className="details-box">
                    {/* GENERAL DATA */}
                    <DetailsSection
                        nameInState="libraryPool"
                        fields={LibraryPoolMetaData.DETAILS_GENERAL}
                        state={this.state}
                        onSetState={(s) => this.setState(s)}
                        onUpdateResource={() => this.saveOrUpdateLibraryPool()}
                        sectionId="general"
                    />

                    {/* OWNER DETAILS */}
                    <DetailsSection
                        nameInState="libraryPool"
                        fields={LibraryPoolMetaData.DETAILS_OWNER}
                        state={this.state}
                        onSetState={(s) => this.setState(s)}
                        sectionId="ownerDetails"
                        onUpdateResource={() => this.saveOrUpdateLibraryPool()}
                        label={<span><IoPeopleCircleOutline/>&#xA0;Owner Details</span>}
                    />

                    {/* LIBRARIES */}
                    <DetailsSection
                        nameInState="libraryPool"
                        fields={[]}
                        state={this.state}
                        onSetState={(s) => this.setState(s)}
                        sectionId="libraryDetails"
                        label={<span><FaBook/>&#xA0;Libraries</span>}
                    />
                    
                    {(this.state.libraryPool.cardinality === 'MULTIPLE' || this.state.librariesInPool.length < 1) &&
                        <>
                        <Button variant="primary"onClick={() => {
                            this.loadLibrariesPage(1).then(r => {
                                this.setState(prevState => ({
                                    ...prevState,
                                    pickLibraryDialog: true,
                                }));
                            });
                        }}>Add Libraries</Button>
                            <br /><br />
                        </>
                    }

                    <Table responsive striped bordered hover>
                        <thead>
                        <tr>
                            <th>ID</th>
                            <th>Name</th>
                            <th>External ID</th>
                            <th>Product ID</th>
                            <th>Concurrent Active Accounts</th>
                        </tr>
                        </thead>
                        <tbody>
                        {this.state.librariesInPool.map(lib => (
                            <tr role="row" key={lib.id} style={{cursor: "pointer"}}>
                                <td><Link style={{color: "#333", fontStyle: "italic"}} to={"/" + GlobalConstants.APP_PATH +"libraries/"+lib.id}>{lib.id}</Link></td>
                                <td>{lib.name}</td>
                                <td>{lib.externalId}</td>
                                <td>{lib.productId}</td>
                                <td>{lib.concurrentActiveAccounts}</td>
                            </tr>
                        ))}
                        </tbody>
                    </Table>

                </div>
                <div className="details-button-box" style={{height: "70px"}}>
                    <Link to={{pathname: "/" + GlobalConstants.APP_PATH +"libraryPools", state: this.state.flowState}}>
                        <button className="form-btn-ci-light-blue" type="button">Back</button>
                    </Link>
                    {this.state.editMode.active &&
                    <PromiseButton text="Save" onClick={() => this.saveOrUpdateLibraryPool()} />
                    }
                </div>

                {/* DIALOGS */}
                <ActionModal show={this.state.pickLibraryDialog}
                           onHide={() => this.setState(prevState => ({
                               ...prevState,
                               pickLibraryDialog: false
                           }))}
                             onAction={() => this.addLibrariesToPool()}
                            actionButtonText="Add selected Libraries"
                           title="Pick a library"
                           body={this.pickLibraryDialog()}/>

                <ErrorHandler error={this.state.error}
                              onHide={() => this.setState(prevState => ({...prevState, error: null}))} />
            </>
        );
    }

    //------------
    //API Methods
    //------------

    async saveOrUpdateLibraryPool() {
        if (!RequiredFieldsAreValid("libraryPool", [LibraryPoolMetaData.DETAILS_GENERAL, LibraryPoolMetaData.DETAILS_OWNER], this.state, (s) => this.setState(s))) {
            return;
        }
        let libraryPool = {};
        if(this.state.libraryPool.id != null) {
            libraryPool = await UpdateLibraryPool(this.state.libraryPool);
        } else {
            libraryPool = await CreateLibraryPool(this.state.libraryPool);
        }

        if (!libraryPool.error) {
            if (this.state.libraryPool.id == null) {
                this.props.history.push("/"+ GlobalConstants.APP_PATH + "libraryPools/" + libraryPool.result.id);
            }
            this.setState((prevState) =>({...prevState, libraryPool: libraryPool.result, editMode: {...prevState.editMode, active: this.state.editMode.autoSave}}));

            this.props.addToast("The libraryPool has been updated successfully.", {
                autoDismiss: true,
                appearance: 'success'
            });
        } else {
            this.setState(prevState => ({...prevState, error: libraryPool}));
        }
    }

    async loadLibrariesInPool(){
        if(this.props.match.params.id != null) {
            let loadedLibraries = await GetFilteredLibraries({poolId: this.props.match.params.id});

            if(!loadedLibraries.error) {
                this.setState(prevState => ({...prevState, librariesInPool: loadedLibraries.result}));
            } else {
                this.setState(prevState => ({...prevState, error: loadedLibraries}));
            }
        }
    }

    async loadLibrariesPage(page) {
        const loadedLibraries = await GetFilteredLibraries({page: page, name: this.state.libraryFilterName});

        if (loadedLibraries.error === true) {
            this.setState(prevState => ({...prevState, error: loadedLibraries}));
        }
        this.setState(prevState => ({
            ...prevState,
            libraries: loadedLibraries.result,
            libraryFilterPage: page
        }));
    }

    async addLibrariesToPool() {
        let librariesToAdd = this.state.libraries.filter(lib => lib.isChecked === true);

        for(let i = 0; i < librariesToAdd.length; i++) {
            if(librariesToAdd[i].poolId == null) {
                librariesToAdd[i].poolId = this.props.match.params.id;

                let updatedLibrary = await UpdateLibrary(librariesToAdd[i]);

                if(updatedLibrary.error) {
                    this.setState(prevState => ({...prevState, error: updatedLibrary}));
                }
            }
        }

        this.setState(prevState => ({...prevState, pickLibraryDialog: false}));
    }
    
    //--------
    // DIALOGS
    //--------

    pickLibraryDialog() {
        return (
            <div>
                <InputGroup className="mb-3">
                    <FormControl
                        placeholder="Search for a library"
                        onChange={(e) => this.setState(prevState => ({
                            ...prevState,
                            libraryFilterName: e.target.value
                        }))}
                    />
                        <Button variant="outline-secondary"
                                onClick={() => this.loadLibrariesPage(this.state.libraryFilterPage)}>Search</Button>
                </InputGroup>
                <Table striped bordered hover>
                    <thead>
                    <tr>
                        <th>ID</th>
                        <th>Name</th>
                        <th>External ID</th>
                        <th>Concurrent Active Accounts</th>
                        <th>Linked To Pool</th>
                        <th>Select</th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.state.libraries.map(lib => (
                        <tr role="row" key={lib.id} style={{cursor: "pointer"}} onClick={() => {
                            let libraries = this.state.libraries;
                            for(let i = 0; i < libraries.length; i++) {
                                if(libraries[i].id === lib.id) {
                                    lib.isChecked = !lib.isChecked;
                                    break;
                                }
                            }
                            this.setState(prevState => ({...prevState, libraries: libraries}));
                        }}>
                            <td>{lib.id}</td>
                            <td>{lib.name}</td>
                            <td>{lib.externalId}</td>
                            <td>{lib.concurrentActiveAccounts}</td>
                            <td>{lib.poolId == null ? <span style={{color: 'green'}}>NONE</span> : <span style={{color: 'red'}}>{lib.poolId}</span>}</td>
                            <td><input type="checkbox" checked={lib.isChecked}/></td>
                        </tr>
                    ))}
                    </tbody>
                </Table>

                <Pagination>
                    <Pagination.Item hidden={this.state.libraryFilterPage === 1}
                                     onClick={() => this.loadLibrariesPage(1)}>&lt;&lt;</Pagination.Item>
                    <Pagination.Item hidden={this.state.libraryFilterPage === 1}
                                     onClick={() => this.loadLibrariesPage(this.state.libraryFilterPage - 1)}>&lt;</Pagination.Item>
                    <Pagination.Item hidden={this.state.libraryFilterPage <= 2}
                                     onClick={() => this.loadLibrariesPage(this.state.libraryFilterPage - 2)}>{this.state.libraryFilterPage - 2}</Pagination.Item>
                    <Pagination.Item hidden={this.state.libraryFilterPage === 1}
                                     onClick={() => this.loadLibrariesPage(this.state.libraryFilterPage - 1)}>{this.state.libraryFilterPage - 1}</Pagination.Item>
                    <Pagination.Item active={true}>{this.state.libraryFilterPage}</Pagination.Item>
                    <Pagination.Item
                        onClick={() => this.loadLibrariesPage(this.state.libraryFilterPage + 1)}>{this.state.libraryFilterPage + 1}</Pagination.Item>
                    <Pagination.Item
                        onClick={() => this.loadLibrariesPage(this.state.libraryFilterPage + 2)}>{this.state.libraryFilterPage + 2}</Pagination.Item>
                    <Pagination.Item
                        onClick={() => this.loadLibrariesPage(this.state.libraryFilterPage + 1)}>&gt;</Pagination.Item>
                </Pagination>
            </div>
        )
    }

}


export default withToast(LibraryPoolDetails);