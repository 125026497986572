import {
    CreateSpineResource,
    GetFilteredSpineResources,
    GetSpineResource, GetVoidSpineRequest,
    UpdateSpineResource
} from "../../clients/SpineClient";

//API METHODS - PRODUCTS
export async function GetFilteredRecommendationsProducts(filter){
    return await GetFilteredSpineResources('api/crm/recommendation/products', filter);
}

export async function GetRecommendationProduct(id) {
    return await GetSpineResource('api/crm/recommendation/products/' + id);
}

export async function CreateRecommendationProduct(recommendation){
    return await CreateSpineResource('api/crm/recommendation/products', recommendation);
}

export async function UpdateRecommendationProduct(recommendation) {
    return await UpdateSpineResource('api/crm/recommendation/products/' + recommendation.id, recommendation);
}

export async function ChangeRecommendationProductNotificationStatus(recommendation, newStatus) {
    recommendation.notificationStatus = newStatus;
    return await UpdateSpineResource('api/crm/recommendation/products/' + recommendation.id, recommendation);
}

export async function ToggleRecommendationByProduct(recommendation, product, notificationType) {
    //Check if the product already has a recommendation
    if(recommendation.id !== null && recommendation.notificationStatus !== undefined) {
        let newStatus = recommendation.notificationStatus === "ABORTED" ? "PENDING" : "ABORTED";
        return await ChangeRecommendationProductNotificationStatus(recommendation, newStatus);
    } else {
        //Create a new one
        recommendation = {
            productId: product.id,
            title: product.title,
            state: product.state,
            coverUrl: product.coverUrl,
            notificationType: notificationType,
            notificationStatus: "PENDING",
            clusterId: null,
            clusterCoverUrl: null,
            ageMin: product.ageMin,
            ageMax: product.ageMax,
            ageFiltered: false,
            publicationDate: product.publicationDate != null ? new Date(product.publicationDate).toISOString() : null,
        };
        return await CreateRecommendationProduct(recommendation);
    }
}

export async function SetClusterIdsOfProducts() {
    return await GetVoidSpineRequest('api/crm/recommendation/products/set-cluster-ids');
}

//API METHODS - SUBSCRIBERS
export async function GetFilteredRecommendationSubscribers(filter){
    return await GetFilteredSpineResources('api/crm/recommendation/subscribers', filter);
}

export async function CreateRecommendationSubscriber(subscriber) {
    return await CreateSpineResource('api/crm/recommendation/subscribers', subscriber);
}
export async function UpdateRecommendationSubscriber(subscriber) {
    return await UpdateSpineResource('api/crm/recommendation/subscribers/' + subscriber.id, subscriber);
}

//API Methods - MAILS
export async function GetFilteredRecommendationMails(filter) {
    return await GetFilteredSpineResources('api/crm/recommendation/mails', filter);
}

export async function QueueRecommendationMails() {
    return await GetVoidSpineRequest('api/crm/recommendation/mails/create-mail-queue');
}

export async function SendRecommendationMails() {
    return await GetVoidSpineRequest('api/crm/recommendation/mails/send-queued-mails');
}