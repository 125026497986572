const CardMetaData = {

    //Columns
    COLUMNS: [
        //Default & Badges
        {label: "ID", id: 'id', columnType: 'default', show: true},
        {label: "UID", id: 'uid', columnType: 'default', show: true},
        {label: "Code", id: 'code', columnType: 'default', show: true},
        {label: "Product ID", id: 'productId', columnType: 'default', show: true},
        {label: "Card Type", id: 'cardType', columnType: 'default', show: true},
    ],

    //Details
    DETAILS_GENERAL: [
        [{label: 'ID', id: 'id', type: 'number', editable: 'never'},
            {label: 'UID', id: 'uid', type: 'text', editable: 'true'},
            {label: 'Code', id: 'code', type: 'text', editable: 'true', required: true},
            {label: 'Enabled', id: 'enabled', type: 'boolean', editable: 'true', value: "true"}],
            [{label: 'Card Type', id: 'cardType', type: 'select', options: ["", "TIGERCARD", "MULTI_TIGERCARD", "WILDCARD", "TIGERTICKET"], editable: 'true', required: true},
            {label: 'Redirect Target Url', id: 'redirectTargetUrl', type: 'text', editable: 'true', required: false},
            {label: 'Batch Name', id: 'batchName', type: 'text', editable: 'true', required: false}],
    ],
    DETAILS_SECTION_TIGERCARD: [
        [{label: 'Product ID', id: 'productId', type: 'number', editable: 'true', required: true},
            {label: 'Product Title', id: '_embedded.products.title', type: 'text', editable: 'never', required: false, value: "_embedded.products.title"},
            {label: 'Cover Image', id: '_embedded.products._links.cover.href', type: 'image', editable: 'never', required: false, value: "_embedded.products.title"}]
    ],
    DETAILS_SECTION_MULTICARD: [
        [{label: 'Variant Name', id: 'multiTigercardVariant.name', type: 'text', editable: 'never', required: false}]
    ],
    DETAILS_SECTION_WILDCARD: [
        [{label: 'Wildcard Name', id: 'wildcardName', type: 'text', editable: 'true', required: true}, {label: 'Wildcard Acount Id', id: 'wildcardAccountId', type: 'number', editable: 'true'}, {label: 'Wildcard Animal Id', id: 'wildcardAnimalId', type: 'number', editable: 'true'}],
        [{label: 'Content Id', id: 'accountGeneratedContentId', type: 'number', editable: 'false'}, {label: 'Public Content', id: 'publicAccountGeneratedContent', type: 'boolean', editable: 'false'}]
    ],
    DETAILS_SECTION_TIGERTICKET: [
        [{label: 'Code', id: 'code', type: 'text', editable: 'new', required: true}],
    ],

    //Sorting
    CARD_SORT_PARAMS: [{id: 'id', label: 'ID'}, {id: 'cardType', label: 'Card Type'}],
};

export default CardMetaData;