import React, {Component} from "react";
import '../../../App.scss'
import {
    AiFillDelete, AiFillEye, DiDatabase,
    FcApproval, FcCloseUpMode,
    FcShop, FiExternalLink, RiFileAddLine
} from "react-icons/all";

import "react-datepicker/dist/react-datepicker.css";
import {
    Badge,
    Button,
    ButtonGroup,
    Col,
    Dropdown,
    Form,
    FormCheck,
    Pagination,
    Tab,
    Table,
    Tabs
} from "react-bootstrap";
import Row from 'react-bootstrap/Row';

import {SpinningTiger} from "../../global/SpinningTiger";
import {TableSettingsDialog} from "../../generators/TableGenerator";
import {withToast} from "../../util/ToastService";
import PremiumAccessTypeMetaData from "./PremiumAccessTypeMetaData";
import {
    DeleteMappedBillwerkProduct,
    DeletePremiumAccessType,
    GetBillwerkProductPage, GetBillwerkProductsByType, GetCouponVariantByType, GetCouponVariantPage,
    GetPremiumAccessTypePage, ImportFromBillwerk
} from "./PremiumAccessTypeService";
import {Link} from "react-router-dom";
import GlobalConstants from "../../config/GlobalConstants";
import {ActionModal, InfoModal} from "../../generators/ModalGenerator";
import {GetCouponsOfCouponVariant} from "../tickets/TicketService";
import {ErrorHandler} from "../../util/ErrorHandler";

class PremiumAccessTypes extends Component {

    //------------
    //Constructor
    //------------

    constructor(props) {
        super(props);

        this.state = {
            error: null,
            loading: false,

            //BillwerkProducts
            billwerkProducts: [],
            billwerkProductFilter: {page: 0, per_page: 20},
            lastBillwerkProductsPage: 0,
            productType: null,

            //PremiumAccessTypes
            premiumAccessTypes: [],
            premiumAccessTypeFilter: {page: 0, name: "", active: null, digital: null, per_page: 20},
            lastPremiumAccessTypePage: 0,

            //Coupon Variants
            couponVariants: [],
            couponVariantFilter: {couponType: "", page: 0, per_page: 20},
            lastCouponVariantPage: 0,
            selectedCouponVariant: {
                coupons: []
            },
            couponsPage: 0,
            lastCouponsPage: 0,

            //Appearance
            mainTab: 'premiumAccessTypes',
            billwerkProductColumns: PremiumAccessTypeMetaData.BILLWERK_PRODUCT_COLUMNS,
            premiumAccessTypeColumns: PremiumAccessTypeMetaData.PREMIUM_ACCESS_TYPE_COLUMNS,
            showPremiumAccessTypeDeletionModal: false,
            selectedPremiumAccessType: {},
            showProductDeletionModal: false,
            selectedProduct: {},
            showDialog: {
                tableSettings: false,
                deletionModal: false
            },
            showCouponDialog: false,

            //Import Modal
            importModal: false,
            updateExisting: false,
            deleteNonExisting: false,

            //Processing
            isLoading: false,
        };

        this.updateFilter = this.updateFilter.bind(this);
        this.showOrHideDialog = this.showOrHideDialog.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
    }

    //---------
    //Mounting
    //---------

    async componentDidMount() {
        await this.loadPremiumAccessTypes(this.state.premiumAccessTypeFilter, 0);
    }

    //--------
    //Loading
    //--------

    async loadBillwerkProducts(filter, page) {

        this.setState(prevState => ({...prevState, loading: true}));

        filter.page = page;

        let loadedBillwerkProducts = {};
        if(this.state.productType == null) {
            loadedBillwerkProducts = await GetBillwerkProductPage(page);
        } else {
            loadedBillwerkProducts = await GetBillwerkProductsByType(this.state.productType,page);
        }

        if (loadedBillwerkProducts.error === true) {
            this.setState(prevState => ({...prevState, error: loadedBillwerkProducts}));
        } else if(Array.isArray(loadedBillwerkProducts.result)) {
            loadedBillwerkProducts.result.forEach(sub => {
                if (this.state.billwerkProducts.filter(ex => (ex.isChecked === true)).includes(sub)) {
                    sub.isChecked = true;
                }
            });
        } else {
            loadedBillwerkProducts.result = [];
        }

        this.setState(prevState => ({
            ...prevState,
            loading: false,
            billwerkProducts: loadedBillwerkProducts.result,
            billwerkProductFilter: filter,
            lastBillwerkProductsPage: Math.ceil(loadedBillwerkProducts.length / this.state.billwerkProductFilter.per_page)
        }));
    }

    async loadPremiumAccessTypes(filter, page) {

        this.setState(prevState => ({...prevState, loading: true}));

        filter.page = page;

        let loadPremiumAccessTypes = await GetPremiumAccessTypePage(filter);

        if (loadPremiumAccessTypes.error === true) {
            this.setState(prevState => ({...prevState, error: loadPremiumAccessTypes}));
        } else if (Array.isArray(loadPremiumAccessTypes.result)) {
            loadPremiumAccessTypes.result.forEach(sub => {
                if (this.state.premiumAccessTypes.filter(ex => (ex.isChecked === true)).includes(sub)) {
                    sub.isChecked = true;
                }
            });
        } else {
            loadPremiumAccessTypes.result = [];
        }

        this.setState(prevState => ({
            ...prevState,
            loading: false,
            premiumAccessTypes: loadPremiumAccessTypes.result,
            premiumAccessTypeFilter: filter,
            lastPremiumAccessTypePage: Math.ceil(loadPremiumAccessTypes.length / this.state.premiumAccessTypeFilter.per_page)
        }));
    }

    async loadCouponVariants(filter, page) {

        this.setState(prevState => ({...prevState, loading: true}));

        filter.page = page;

        let loadedCouponVariants = {}

        if (filter.couponType !== "") {
            loadedCouponVariants = await GetCouponVariantByType(filter.couponType, page);
        } else {
            loadedCouponVariants = await GetCouponVariantPage(page);
        }


        if (loadedCouponVariants.error === true) {
            this.setState(prevState => ({...prevState, error: loadedCouponVariants}));
        } else if (Array.isArray(loadedCouponVariants.result)) {
            loadedCouponVariants.result.forEach(sub => {
                if (this.state.couponVariants.filter(ex => (ex.isChecked === true)).includes(sub)) {
                    sub.isChecked = true;
                }
            });
        } else {
            loadedCouponVariants.result = [];
        }

        this.setState(prevState => ({
            ...prevState,
            loading: false,
            couponVariants: loadedCouponVariants.result,
            couponVariantFilter: filter,
            lastCouponVariantFilter: Math.ceil(loadedCouponVariants.length / 20)
        }));
    }

    //----------
    //Rendering
    //----------

    render() {

        return (
            <div style={{marginBottom: 50}}>
                <div className="content-header">
                    <div className="content-header-title">Order Configs</div>
                </div>

                {/* SHOW THE PRODUCT LIST*/}
                <div className="content-box-centered">
                    <Tabs id="recommendation-main-tab" activeKey={this.state.mainTab}
                          onSelect={(k) => {
                              if(k === 'billwerkProducts') {
                                  this.loadBillwerkProducts({per_page: 20, page: 0}, 0).then(r => r);
                              } else if (k === 'premiumAccessTypes') {
                                  this.loadPremiumAccessTypes({per_page: 20, page: 0}, 0).then(r => r);
                              } else {
                                  this.loadCouponVariants({per_page: 20, page: 0}, 0).then(r => r);
                              }
                              this.updateState('mainTab', k);
                          }}
                          className="mb-5">

                        <Tab eventKey="premiumAccessTypes"
                             title={<span style={{color: "#495057"}}><FcApproval size={24}/> Order Configs</span>}>
                            <Form>
                                <Row>
                                    <Form.Group as={Col}>
                                        <Form.Control type="text" value={this.state.premiumAccessTypeFilter.name}
                                                      onChange={(e) => this.setState(prevState => ({...prevState, premiumAccessTypeFilter: {...prevState.premiumAccessTypeFilter, name: e.target.value}}))} />
                                    </Form.Group>
                                    <Form.Group as={Col} />
                                    <Form.Group as={Col} />
                                </Row>
                            </Form>
                            <div style={{float: "right"}}>
                                <button className="form-btn-ci-light" onClick={() => this.loadPremiumAccessTypes({per_page: 20, page: 0}, 0)}>Reset</button>
                                <button className="form-btn-ci-blue" onClick={() => this.loadPremiumAccessTypes(this.state.premiumAccessTypeFilter, 0)}>Apply</button>
                            </div>
                            <br />
                            <br />
                            <hr />
                            <Link to={"/" + GlobalConstants.APP_PATH + "purchases/premium-access-types/add"}>
                                <button className="form-btn-ci-blue" type="button"><RiFileAddLine/></button>
                            </Link><br /><br />

                            {this.state.loading ?
                                <SpinningTiger/>
                                :
                                <>
                                    <Table hover responsive bordered>
                                        <thead>
                                            <tr>
                                                <th><FiExternalLink size={24} /> ID</th>
                                                <th>Active</th>
                                                <th>Name</th>
                                                <th>Payment Required</th>
                                                <th>Is Default</th>
                                                <th>Can be upgraded to</th>
                                                <th>Components</th>
                                                <th>Tools</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.premiumAccessTypes.map(type => (
                                                <tr role="row" key={type.id}>
                                                    <td><Link style={{color: "#333"}}  to={"/" + GlobalConstants.APP_PATH + "purchases/premium-access-types/" + type.id}>{type.id}</Link></td>
                                                    <td><Badge bg={type.active ? 'success' : 'danger'}>{type.active ? 'Y' : 'N'}</Badge></td>
                                                    <td>{type.name}</td>
                                                    <td><Badge bg={type.paymentRequired ? 'success' : 'danger'}>{type.paymentRequired ? "Y" : "N"}</Badge></td>
                                                    <td><Badge bg={type.default ? 'success' : 'danger'}>{type.default ? "Y" : "N"}</Badge></td>
                                                    <td>{this.compatiblePremiumAccessTypesColumn(type)}</td>
                                                    <td>{this.premiumAccessPhases(type)}</td>
                                                    <td>
                                                        <button className="form-btn-ci-red" onClick={() => this.setState(prevState => ({...prevState, showPremiumAccessTypeDeletionModal: true, selectedPremiumAccessType: type}))}>
                                                            <AiFillDelete size={18}/>
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                    <Pagination>
                                        <Pagination.Item hidden={this.state.premiumAccessTypeFilter.page === 0}
                                                         onClick={() => this.loadPremiumAccessTypes(this.state.premiumAccessTypeFilter, this.state.premiumAccessTypeFilter.page - 1)}>&lt;</Pagination.Item>
                                        <Pagination.Item active={true}>{this.state.premiumAccessTypeFilter.page + 1}</Pagination.Item>
                                        <Pagination.Item hidden={this.state.premiumAccessTypeFilter.page === this.state.lastPremiumAccessTypePage - 1}
                                                         onClick={() => this.loadPremiumAccessTypes(this.state.premiumAccessTypeFilter, this.state.premiumAccessTypeFilter.page + 1)}>&gt;</Pagination.Item>
                                    </Pagination>
                                </>
                            }

                        </Tab>

                        <Tab eventKey="billwerkProducts"
                             title={<span style={{color: "#495057"}}><FcShop size={24}/>Mapped Billwerk Products</span>}>
                            <Dropdown as={ButtonGroup} drop="up">
                                <Button style={{
                                    paddingTop: "8px",
                                    paddingBottom: "10px",
                                    marginBottom: "10px",
                                    height: "40px"
                                }}
                                        variant={this.state.productType === null ? "secondary" : "success"}
                                        onClick={() => this.loadBillwerkProducts({}, 0)}>Type: {this.state.productType != null ? this.state.productType : "All"}</Button>
                                <Dropdown.Toggle style={{marginBottom: "10px"}} split
                                                 variant={this.state.productType === null ? "outline-secondary" : "outline-success"}/>
                                <Dropdown.Menu>
                                    <Dropdown.Item
                                        onClick={() => this.setState({productType: null})}>All</Dropdown.Item>
                                    <Dropdown.Divider />
                                    <Dropdown.Item
                                        onClick={() => this.setState({productType: 'TIGERTICKET'})}>tigerticket</Dropdown.Item>
                                    <Dropdown.Item
                                        onClick={() => this.setState({productType: 'DIGITICKET'})}>digiticket</Dropdown.Item>
                                    <Dropdown.Item
                                        onClick={() => this.setState({productType: 'SUBSCRIPTION'})}>Subscription</Dropdown.Item>
                                    <Dropdown.Item
                                        onClick={() => this.setState({productType: 'COUPON'})}>Coupon</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                            <Link to={"/" + GlobalConstants.APP_PATH + "purchases/products/add"}>
                                <button className="form-btn-ci-blue" type="button"><RiFileAddLine/></button>
                            </Link>
                            <div style={{float: "right"}}>
                                <button className="form-btn-ci-green" onClick={() => this.setState(prevState => ({...prevState, importModal: true}))}><DiDatabase size={24} /> Import from Billwerk</button>
                            </div>
                            <br /><br />
                            <hr/>

                            {this.state.loading ?
                                <SpinningTiger/>
                                :
                                <>
                                    <Table hover responsive bordered>
                                        <thead>
                                        <tr>
                                            <th><FiExternalLink size={24} /> ID</th>
                                            <th>Type</th>
                                            <th>Name</th>
                                            <th>Duration</th>
                                            <th><FiExternalLink size={24} /> Plan ID</th>
                                            <th><FiExternalLink size={24} /> Plan Variant Id</th>
                                            <th><FiExternalLink size={24} /> Landing Page ID</th>
                                            <th>Price</th>
                                            <th>Edition</th>
                                            <th>Shop URL</th>
                                            <th>Tools</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {this.state.billwerkProducts.map(product => (
                                            <tr role="row" key={product.id}>
                                                <td><Link style={{color: "#333"}} to={"/" + GlobalConstants.APP_PATH + "purchases/products/" + product.id}>{product.id}</Link></td>
                                                <td><Badge bg={product.type === 'TIGERTICKET' ? 'danger' : (product.type === 'DIGITICKET' ? 'primary' : (product.type === 'SUBSCRIPTION' ? 'success' : 'warning'))}>{product.type}</Badge></td>
                                                <td>{product.billwerkName}</td>
                                                <td>{product.premiumAccessDuration}</td>
                                                <td><a style={{color: "#333"}} href={GlobalConstants.BILLWERK_BASE_URL + "#/plans/" + product.planId + "/edit"} target={"_blank"} rel={"noreferrer"}>{product.planId}</a></td>
                                                <td><a style={{color: "#333"}} href={GlobalConstants.BILLWERK_BASE_URL + "#/planvariants/" + product.planVariantId + "/edit"} target={"_blank"} rel={"noreferrer"}>{product.planVariantId}</a></td>
                                                <td><a style={{color: "#333"}} href={GlobalConstants.WORDPRESS_CLIENT_HOST + "premium/lp/" + product.landingPageId + "/" + product.planVariantId} target={"_blank"} rel={"noreferrer"}>{product.landingPageId}</a></td>
                                                <td>{product.price}</td>
                                                <td><Badge bg={product.edition === 'SWISS' ? 'danger' : 'success'}>{product.edition}</Badge></td>
                                                <td>
                                                    {product.type !== 'TIGERTICKET' &&
                                                        <a href={GlobalConstants.WORDPRESS_CLIENT_HOST + "premium/lp/" + product.landingPageId + "/" + product.planVariantId}
                                                           target={"_blank"} rel={"noreferrer"}>
                                                            <button className="form-btn-ci-light">Öffnen</button>
                                                        </a>
                                                    }
                                                </td>
                                                <td>
                                                    <button className="form-btn-ci-red" onClick={() => this.setState(prevState => ({...prevState, showProductDeletionModal: true, selectedProduct: product}))}>
                                                        <AiFillDelete size={18}/>
                                                    </button>
                                                </td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </Table>
                                    <Pagination>
                                        <Pagination.Item hidden={this.state.billwerkProductFilter.page === 0}
                                        onClick={() => this.loadBillwerkProducts({}, this.state.billwerkProductFilter.page - 1)}>&lt;</Pagination.Item>
                                        <Pagination.Item active={true}>{this.state.billwerkProductFilter.page + 1}</Pagination.Item>
                                        <Pagination.Item hidden={this.state.billwerkProductFilter.page === this.state.lastBillwerkProductsPage - 1}
                                        onClick={() => this.loadBillwerkProducts({}, this.state.billwerkProductFilter.page + 1)}>&gt;</Pagination.Item>
                                    </Pagination>
                                </>
                            }

                        </Tab>

                        <Tab eventKey="couponVariants"
                             title={<span style={{color: "#495057"}}><FcCloseUpMode size={24}/>Coupon Variants</span>}>
                            <Link to={"/" + GlobalConstants.APP_PATH + "purchases/couponVariants/add"}>
                                <button className="form-btn-ci-blue" type="button"><RiFileAddLine/></button>
                            </Link>
                            <br /><br />
                            <hr/>

                            {this.state.loading ?
                                <SpinningTiger/>
                                :
                                <>
                                    <Table hover responsive bordered>
                                        <thead>
                                        <tr>
                                            <th><FiExternalLink size={24} /> ID</th>
                                            <th>Coupon Type</th>
                                            <th>Name</th>
                                            <th>Redemption Type</th>
                                            <th>Coupons</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {this.state.couponVariants.map(variant => (
                                            <tr role="row" key={variant.id}>
                                                <td><Link style={{color: "#333"}} to={"/" + GlobalConstants.APP_PATH + "purchases/couponVariants/" + variant.id}>{variant.id}</Link></td>
                                                <td><Badge bg={variant.couponType === 'SWISS_UPGRADE' ? 'danger' : (variant.couponType === 'DDF' ? 'secondary' : 'success')}>{variant.couponType}</Badge></td>
                                                <td>{variant.name}</td>
                                                <td><Badge bg={variant.redemptionType === 'UPGRADE' ? 'danger' : 'success'}>{variant.redemptionType}</Badge></td>
                                                <td>
                                                    <button className="form-btn-ci-light-blue" onClick={() => this.loadCouponsOfCouponVariant(variant, 0).then(r => this.setState(prevState => ({...prevState, showCouponDialog: true, selectedCouponVariant: variant})))}>
                                                        <AiFillEye size={18}/>
                                                    </button>
                                                </td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </Table>
                                    <Pagination>
                                        <Pagination.Item hidden={this.state.couponVariantFilter.page === 0}
                                                         onClick={() => this.loadCouponVariants({}, this.state.couponVariantFilter.page - 1)}>&lt;</Pagination.Item>
                                        <Pagination.Item active={true}>{this.state.couponVariantFilter.page + 1}</Pagination.Item>
                                        <Pagination.Item hidden={this.state.couponVariantFilter.page === this.state.lastCouponVariantFilter - 1}
                                                         onClick={() => this.loadCouponVariants({}, this.state.couponVariantFilter.page + 1)}>&gt;</Pagination.Item>
                                    </Pagination>
                                </>
                            }

                        </Tab>
                    </Tabs>

                    <hr/>

                </div>

                {/* TABLE SETTINGS */}
                <TableSettingsDialog show={this.state.showDialog.tableSettings}
                                     columns={this.state.billwerkProductColumns}
                                     onSetState={(columns) => this.setState(prevState => ({
                                         ...prevState,
                                         billwerkProductColumns: columns
                                     }))}
                                     onHide={() => this.showOrHideDialog('tableSettings', false)}
                />

                <ActionModal show={this.state.showPremiumAccessTypeDeletionModal}
                             title={"Deletion"}
                             body={"Are you sure you want to delete premium access type " + this.state.selectedPremiumAccessType.id + "?"}
                             actionButtonText={"Delete"}
                             onAction={() => this.deletePremiumAccessType()}
                           onHide={() => this.setState(prevState => ({...prevState, showPremiumAccessTypeDeletionModal: false}))}
                />

                <ActionModal show={this.state.showProductDeletionModal}
                             title={"Deletion"}
                             body={"Are you sure you want to delete mapped billwerk product " + this.state.selectedProduct.id + "?"}
                             actionButtonText={"Delete"}
                             onAction={() => this.deleteMappedBillwerkProduct()}
                             onHide={() => this.setState(prevState => ({...prevState, showProductDeletionModal: false}))}
                />

                <ActionModal show={this.state.importModal}
                             title={"Import products from Billwerk"}
                             body={this.importModal()}
                             actionButtonText={"Start import"}
                             onAction={() => this.importFromBillwerk()}
                             onHide={() => this.setState(prevState => ({...prevState, importModal: false}))}
                />

                <InfoModal show={this.state.showCouponDialog}
                           title={"Coupons in Coupon Variant " + this.state.selectedCouponVariant.name}
                           onHide={() => this.setState(prevState => ({...prevState, showCouponDialog: false, selectedCouponVariant: {coupons: []}}))}
                           body={this.couponsModal()}
               />

                <ErrorHandler error={this.state.error}
                              onHide={() => this.setState(prevState => ({...prevState, error: null}))} />

            </div>
        );
    }

    //----------------
    // API Methods
    //----------------

    async loadCouponsOfCouponVariant(variant, page) {
        this.setState(prevState => ({...prevState, loading: true}))
        let loadedVariants = await GetCouponsOfCouponVariant(variant.id, page);
        if (!loadedVariants.error) {
            variant.coupons = loadedVariants.result;
        } else {
            this.setState(prevState => ({...prevState, error: loadedVariants}));
        }
        this.setState(prevState => ({
            ...prevState,
            loading: false,
            selectedCouponVariant: variant,
            couponsPage: page,
            lastCouponsPage: Math.ceil(loadedVariants.result / 20)
        }));
    }

    async deletePremiumAccessType() {
        if(this.state.selectedPremiumAccessType.id != null) {
            let response = await DeletePremiumAccessType(this.state.selectedPremiumAccessType);
            if(!response.error) {
                await this.loadPremiumAccessTypes(this.state.premiumAccessTypeFilter, 0);
                this.setState(prevState => ({...prevState, showPremiumAccessTypeDeletionModal: false, selectedPremiumAccessType: {}}));
                this.props.addToast("Premium Access Type has been deleted successfully", {
                    appearance: "success",
                    autoDismiss: true
                });
            } else {
                this.setState(prevState => ({...prevState, error: response}));
            }
        }
    }

    async deleteMappedBillwerkProduct() {
        if(this.state.selectedProduct.id != null) {
            let response = await DeleteMappedBillwerkProduct(this.state.selectedProduct);
            if(!response.error) {
                await this.loadBillwerkProducts({}, 0);
                this.setState(prevState => ({...prevState, showProductDeletionModal: false, selectedProduct: {}}));
                this.props.addToast("Mapped billwerk product has been deleted successfully", {
                    appearance: "success",
                    autoDismiss: true
                });
            } else {
                this.setState(prevState => ({...prevState, error: response}));
            }
        }
    }

    async importFromBillwerk() {
        this.setState(prevState => ({...prevState, loading: true}));
        let response = await ImportFromBillwerk(this.state.updateExisting, this.state.deleteNonExisting);
        if (!response.error) {
            const result = response.result;
            this.props.addToast("Import has been completed successfully. Created: " + result.created + ", updated: " + result.updated + ", deleted: " + result.deleted, {
                autoDismiss: true,
                appearance: 'success'
            });
            this.setState(prevState => ({...prevState, loading: false, importModal: false}));
        } else {
            this.setState(prevState => ({...prevState, error: response, loading: false}));
        }
        await this.loadBillwerkProducts({}, 0);
    }

    //----------------
    // STATE HANDLING
    //----------------

    updateFilter(id, value) {
        //Get the state and the filter
        let state = this.state;
        let filter = state.filter;

        //Update the attribute
        filter[id] = value;

        //Set the state
        this.setState(prevState => ({...prevState, filter: filter}));
    }

    updateState(param, value) {
        let state = this.state;
        state[param] = value;
        this.setState(state);
    }

    showOrHideDialog(dialogId, show) {
        //Get the state and the dialog
        let state = this.state;
        let dialogs = state.showDialog;

        //Update the visibility
        dialogs[dialogId] = show;

        //Set the state
        this.setState(prevState => ({...prevState, showDialog: dialogs}));
    }

    //----------------
    // MODALS
    //----------------

    importModal() {
        return (
            <Form>
                <Row>
                    <Form.Group as={Col}>
                        <FormCheck onClick={(e) => this.setState(prevState => ({...prevState, updateExisting: !this.state.updateExisting}))}
                                   value={this.state.updateExisting} checked={this.state.updateExisting}
                                   label={"Update existing products"}
                                   onChange={(e) => this.setState(prevState => ({...prevState, updateExisting: !this.state.updateExisting}))}>
                        </FormCheck>
                        <Form.Text className="text-muted">Update an existing product in the database if it differs from the corresponding billwerk product (default: false)</Form.Text>
                    </Form.Group>
                </Row>
                <Row>
                    <Form.Group as={Col}>
                        <FormCheck onClick={(e) => this.setState(prevState => ({...prevState, deleteNonExisting: !this.state.deleteNonExisting}))}
                                   label={"Delete non existing products"}
                                   value={this.state.deleteNonExisting} checked={this.state.deleteNonExisting}
                                   onChange={(e) => this.setState(prevState => ({...prevState, deleteNonExisting: !this.state.deleteNonExisting}))} />
                        <Form.Text className="text-muted">Remove an existing product from the database if it does not exist in billwerk anymore (default: false)</Form.Text>
                    </Form.Group>
                </Row>
            </Form>
        );
    }

    couponsModal() {
        return (
            <>
                <Table responsive bordered hover striped>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Used</th>
                            <th>Code</th>
                            <th>PIN</th>
                            <th>Redeemed By Account ID</th>
                            <th>NFC Card Code</th>
                        </tr>
                    </thead>
                    <tbody>
                    {this.state.selectedCouponVariant.coupons.map(c => (
                        <tr role="row" key={c.id}>
                            <td><Link style={{color: "#333"}} to={"/" + GlobalConstants.APP_PATH + "purchases/coupons/" + c.id}>{c.id}</Link></td>
                            <td><Badge bg={c.used ? "success" : "danger"}>{c.used ? "Y" : "N"}</Badge></td>
                            <td>{c.code}</td>
                            <td>{c.pin}</td>
                            <td>{c.redeemedByAccountId}</td>
                            <td>{c.nfcCardCode}</td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
                <Pagination>
                    <Pagination.Item hidden={this.state.couponsPage === 0}
                                     onClick={() => this.loadCouponsOfCouponVariant(this.state.selectedCouponVariant, this.state.couponsPage - 1)}>&lt;</Pagination.Item>
                    <Pagination.Item active={true}>{this.state.couponsPage + 1}</Pagination.Item>
                    <Pagination.Item hidden={this.state.couponsPage === this.state.lastCouponsPage - 1}
                                     onClick={() => this.loadCouponsOfCouponVariant(this.state.selectedCouponVariant, this.state.couponsPage + 1)}>&gt;</Pagination.Item>
                </Pagination>
            </>
        )
    }

    premiumAccessPhases(type) {
        const phases = (type != null && type.components != null) ? type.components : [];
        if (phases.length > 0) {
            return (
                <>
                    {phases.map(p => (
                        <button className="form-btn-ci" style={{float: "left"}}>
                            {p.cartPosition}
                        </button>
                    ))}
                </>
            )
        } else {
            return (
                <>---</>
            );
        }
    }

    compatiblePremiumAccessTypesColumn(type) {
        if (type.compatibleBillwerkOrderConfigs != null && type.compatibleBillwerkOrderConfigs.length > 0) {
            const allowedTypes = type.compatibleBillwerkOrderConfigs.length > 4 ? type.compatibleBillwerkOrderConfigs.splice(0,4) : type.compatibleBillwerkOrderConfigs;
            const additionals = type.compatibleBillwerkOrderConfigs.length > 4 ? type.compatibleBillwerkOrderConfigs.length - 5 : null;
            if (additionals != null) {
                return (
                    <>
                        {allowedTypes.map(t => (
                            <button className="form-btn-ci" style={{float: "left"}}>{t}</button>
                        ))}
                        <br />
                        and {additionals} more...
                    </>
                )
            } else {
                return (
                    <>
                        {allowedTypes.map(t => (
                            <button className="form-btn-ci" style={{float: "left"}}>{t}</button>
                        ))}
                    </>
                )
            }
        } else {
            return "---"
        }
    }
}

export default withToast(PremiumAccessTypes);