import React, {Component} from "react";
import {withToast} from "../../util/ToastService";
import {Link} from "react-router-dom";
import {
    IoPeopleCircleOutline,
} from "react-icons/all";
import GlobalConstants from "../../config/GlobalConstants";
import {DetailsSection} from "../../generators/DetailsGenerator";
import TransactionMetaData from "./TransactionMetaData";
import {
    BlockTransaction,
    DisableTransaction,
    GetTransaction,
    ResetTransaction, SaveTransaction,
    UnlockTransaction
} from "./TransactionService";
import {ActionModal} from "../../generators/ModalGenerator";
import {PromiseButton} from "../../global/SpinningTiger";
import {ErrorHandler} from "../../util/ErrorHandler";

class TransactionDetails extends Component {

    //------------
    //Constructor
    //------------

    constructor(props) {
        super(props);

        this.state = {
            error: null,
            flowState: props.location.state,
            id: this.props.match.params.id,
            searchParams: props.location.search,
            editMode: {
                active: false,
                expertMode: false,
                autoSave: true,
            },
            transaction: {},
            originalTransaction: {},
            showResetModal: false,
            showDisableEnableModal: false,
            showBlockModal: false,
            sections: {
                general: true,
                productDetails: true,
                purchaseDetails: true
            }
        }
    }

    //---------
    //Mounting
    //---------

    async componentDidMount() {
        //Set the title
        if(this.state.id != null) {
            document.title = "Transaction " + this.state.id + " :: Tiger UI";
        } else {
            document.title = "New Transaction :: Tiger UI";
        }
        await this.loadTransaction();
    }

    //--------
    //Loading
    //--------

    async loadTransaction() {
        let loadedTransaction = {};

        if(this.state.id != null && this.state.id !== "add") {

            loadedTransaction = await GetTransaction(this.state.id);

            if (!loadedTransaction.error) {
                const transaction = loadedTransaction.result;

                this.setState({
                    id: this.state.id,
                    editMode: this.state.editMode,
                    transaction: transaction,
                    originalTransaction: transaction,
                });
            } else {
                this.setState(prevState => ({...prevState, error: loadedTransaction}));
            }
        } else {
            let searchParams = new URLSearchParams(this.state.searchParams);
            this.setState((prevState) => ({...prevState,
                id: null,
                transaction: {
                    id: null,
                    currentClient: 'INTERN',
                    paymentChannelType: null,
                    merchantReference: null,
                    accountEmail: searchParams.get("email"),
                    state: searchParams.get("state") != null ? searchParams.get("state") : "CREATED",
                    payoneTransactionId: searchParams.get("payoneTransactionId"),
                    billwerkOrderId: searchParams.get("orderId"),
                    billwerkContractId: searchParams.get("contractId"),
                    mappedBillwerkProductId: searchParams.get("state") === "BLOCKED" ? 0 : null,
                    createdBy: 'system'
                },
                editMode: {
                    active: true,
                    expertMode: false,
                    autoSave: false,
                }}));
        }
    }

    //----------
    //Rendering
    //----------

    render() {

        return (
            <>
                <div className="details-title">
                    {this.state.transaction.id != null ? "Details of Transaction " + this.state.transaction.id : "Create a new Transaction"}
                </div>
                <div className="details-button-box" style={{height: "70px"}}>
                    <Link to={{
                        pathname: "/" + GlobalConstants.APP_PATH + "purchases/transactions",
                        state: this.state.flowState
                    }}>
                        <button className="form-btn-ci-light-blue" type="button">Back</button>
                    </Link>
                    {this.state.editMode.active &&
                        <PromiseButton text="Save" onClick={() => this.saveTransaction()} />
                    }
                    <button style={{marginLeft: "30px"}} className={this.state.transaction.id === null ? "form-btn-ci-off" : "form-btn-ci-light"} disabled={this.state.transaction.id === null}
                            onClick={() => this.setState(prevState => ({...prevState, showResetModal: true}))}>Reset
                        Failures
                    </button>
                    <button style={{marginLeft: "2px"}} disabled={this.state.transaction.state === 'BLOCKED' || this.state.transaction.id === null}
                            className={this.state.transaction.state === 'TEMPORARY_DISABLED' && this.state.transaction.id != null ? "form-btn-ci-green" : (this.state.transaction.state === 'BLOCKED' || this.state.transaction.id === null ? "form-btn-ci-off" : "form-btn-ci-blue")}
                            onClick={() => this.setState(prevState => ({
                                ...prevState,
                                showDisableEnableModal: true
                            }))}>{this.state.transaction.state === 'TEMPORARY_DISABLED' && this.state.transaction.id != null ? "Unlock Transaction" : "Disable temporary"}
                    </button>
                    <button style={{marginLeft: "2px"}} className={this.state.transaction.id === null || this.state.transaction.state === 'BLOCKED' ? "form-btn-ci-off" : "form-btn-ci-red"} disabled={this.state.transaction.id === null || this.state.transaction.state === 'BLOCKED'}
                            onClick={() => this.setState(prevState => ({...prevState, showBlockModal: true}))}>Block
                    </button>
                </div>

                <div className="details-box-title">Resource Details</div>
                <div className="details-box">
                    {/* GENERAL DATA */}
                    <DetailsSection
                        nameInState="transaction"
                        fields={TransactionMetaData.TRANSACTION_DETAILS_GENERAL}
                        state={this.state}
                        onSetState={(s) => this.setState(s)}
                        sectionId="general"
                    />

                    <DetailsSection
                        nameInState="transaction"
                        fields={TransactionMetaData.TRANSACTION_PRODUCT_DETAILS}
                        state={this.state}
                        onSetState={(s) => this.setState(s)}
                        sectionId="productDetails"
                        label={<span><IoPeopleCircleOutline/>&#xA0;Product Details</span>}
                    />

                    <DetailsSection
                        nameInState="transaction"
                        fields={TransactionMetaData.TRANSACTION_PURCHASE_DETAILS}
                        state={this.state}
                        onSetState={(s) => this.setState(s)}
                        sectionId="purchaseDetails"
                        label={<span><IoPeopleCircleOutline/>&#xA0;Purchase Details</span>}
                    />

                </div>
                <div className="details-button-box" style={{height: "70px"}}>
                    <Link to={{
                        pathname: "/" + GlobalConstants.APP_PATH + "purchases/transactions",
                        state: this.state.flowState
                    }}>
                        <button className="form-btn-ci-light-blue" type="button">Back</button>
                    </Link>
                </div>

                <ActionModal show={this.state.showResetModal}
                             actionButtonText={"Continue"}
                             size='sm'
                             onAction={() => this.resetTransaction()}
                             onHide={() => this.setState(prevState => ({...prevState, showResetModal: false}))}
                             title="Reset Transaction Failures" body={
                    <div style={{textAlign: "center"}}>
                        By resetting the transaction failures, 'Failures' will be set to '0' and the transaction will be
                        unblocked. Continue?
                    </div>
                }/>

                <ActionModal show={this.state.showDisableEnableModal}
                             actionButtonText={this.state.transaction.state === 'TEMPORARY_DISABLED' ? "Unlock" : "Disable"}
                             size='sm'
                             onAction={() => this.enableOrDisableTransaction()}
                             onHide={() => this.setState(prevState => ({...prevState, showDisableEnableModal: false}))}
                             title={this.state.transaction.state === 'TEMPORARY_DISABLED' ? "Unlock transaction " + this.state.transaction.id : "Disable transaction " + this.state.transaction.id + " temporary"}
                             body={
                                 <div style={{textAlign: "center"}}>
                                     {this.state.transaction.state === 'TEMPORARY_DISABLED' ?
                                         <>
                                             Do you want to unlock the transaction again? Afterwards, webhook requests
                                             will be processed for this transaction.
                                         </>
                                         :
                                         <>
                                             Do you want to disable this transaction temporary? Webhooks requests will
                                             be ignored for this transaction as long as its disabled.
                                         </>
                                     }
                                 </div>
                             }/>

                <ActionModal show={this.state.showBlockModal}
                             actionButtonText={"Block"}
                             size='sm'
                             onAction={() => this.blockTransaction()}
                             onHide={() => this.setState(prevState => ({...prevState, showBlockModal: false}))}
                             title="Block transaction"
                             body={
                                 <div style={{textAlign: "center"}}>
                                     Do you want to block this transaction? Afterwards, no webhook requests will be
                                     consumed for this transaction anymore. This can not be undone.
                                 </div>
                             }/>

                <ErrorHandler error={this.state.error}
                              onHide={() => this.setState(prevState => ({...prevState, error: null}))} />
            </>
        );
    }

    //------------
    //API Methods
    //------------

    async saveTransaction() {
        if(this.state.transaction.mappedBillwerkProductId === null || this.state.transaction.mappedBillwerkProductId === undefined) {
            this.props.addToast("Mapped Product ID must not be null", {
                autoDismiss: true,
                appearance: "error"
            });
            return;
        }
        let response = await SaveTransaction(this.state.transaction);
        if (!response.error) {
            this.setState((prevState) => ({
                ...prevState,
                transaction: response.result,
                editMode: {...prevState.editMode, active: this.state.editMode.autoSave}
            }));

            this.props.addToast("Transaction has been saved successfully.", {
                autoDismiss: true,
                appearance: 'success'
            });
        } else {
            this.setState(prevState => ({...prevState, error: response}));
        }
    }

    async blockTransaction() {
        let response = await BlockTransaction(this.state.id);
        if(!response.error) {
            this.loadTransaction().then(r => {
                this.props.addToast("Transaction has been blocked successfully", {
                    autoDismiss: true,
                    appearance: "success"
                });
                this.setState(prevState => ({...prevState, showBlockModal: false}));
            })
        } else {
            this.setState(prevState => ({...prevState, error: response}));
        }
    }

    async enableOrDisableTransaction() {
        if(this.state.transaction.state === 'TEMPORARY_DISABLED') {
            let response = await UnlockTransaction(this.state.id);
            if(!response.error) {
                this.loadTransaction().then(r => {
                    this.props.addToast("Transaction has been unlocked successfully", {
                        autoDismiss: true,
                        appearance: "success"
                    });
                    this.setState(prevState => ({...prevState, showDisableEnableModal: false}));
                });
            } else {
                this.setState(prevState => ({...prevState, error: response}));
            }
        } else {
            let response = await DisableTransaction(this.state.id);
            if(!response.error) {
                this.loadTransaction().then(r => {
                    this.props.addToast("Transaction has been disabled successfully", {
                        autoDismiss: true,
                        appearance: "success"
                    });
                    this.setState(prevState => ({...prevState, showDisableEnableModal: false}));
                });
            } else {
                this.setState(prevState => ({...prevState, error: response}));
            }
        }
    }

    async resetTransaction() {
        let response = await ResetTransaction(this.state.id);
        if(!response.error) {
            this.loadTransaction().then(r => {
                this.props.addToast("Transaction has been reset successfully", {
                    autoDismiss: true,
                    appearance: "success"
                });
                this.setState(prevState => ({...prevState, showResetModal: false}));
            });
        } else {
            this.setState(prevState => ({...prevState, error: response}));
        }
    }
        
}


export default withToast(TransactionDetails);