import React from "react";
import {Nav, Navbar, NavDropdown} from "react-bootstrap";
import {
    FiLogOut,
    BsPeopleFill,
    IoIosArrowDropright,
    RiLockPasswordLine,
    FcHome,
    FcSupport,
    FcAcceptDatabase,
    FcCommandLine,
    FcComboChart, FcConferenceCall, FcMindMap, FcCurrencyExchange
} from "react-icons/all";
import {logout} from "../auth/Oauth2Service";
import logo from './../../assets/images/tiger_logo.png'
import GlobalConstants from "../config/GlobalConstants";
import {Link} from "react-router-dom";

export default function AppNavbar({setToken, roles, setRoles}) {
    const accountTitle = (<span><FcConferenceCall size={25} />&#xA0;Account</span>);
    const entityTitle = (<span><FcAcceptDatabase size={25}/>&#xA0;Resources</span>)
    const administrationTitle = (<span><FcCommandLine size={25}/>&#xA0;Administration</span>)
    const toolsTitle = (<span><FcMindMap size={25}/>&#xA0;Tools</span>)
    const reportingTitle = (<span><FcComboChart size={25}/>&#xA0;Reporting</span>)
    const supportTitle = (<span><FcSupport size={25}/>&#xA0;Support & CRM</span>)
    const purchaseTitle = (<span><FcCurrencyExchange size={25}/>&#xA0;Purchases</span>)

    const navbarStyle = {
        paddingLeft: "10px",
        paddingRight: "10px",
        borderTop: "solid 1px #616060",
        borderTopLeftRadius: "20px",
        borderBottomLeftRadius: "20px",
        borderBottomRightRadius: "20px",
        borderTopRightRadius: "20px",
        backgroundColor: "#272c30",
        borderBottom: "solid 1px #616060",
        marginRight: "10px"
    }

    const environmentStyle = {
        width: "120px",
        textAlign: "center",
        borderStyle: "solid",
        paddingTop: "5px",
        paddingLeft: "10px",
        paddingRight: "10px",
        paddingBottom: "5px",
        borderWidth: "1px",
        borderColor: GlobalConstants.ENVIRONMENT === "production" ? "#E12C00" : "#10BD10",
        borderRadius: "20px",
        color: GlobalConstants.ENVIRONMENT === "production" ? "#E12C00" : "#10BD10",
        fontSize: "14px",
    }

    return (
        <div style={{fontFamily: "Mikado"}}>
            <Navbar bg="dark" variant="dark" expand="lg">
                <Navbar.Brand href={"/" + GlobalConstants.APP_PATH}>
                    <img
                        src={logo}
                        width="30"
                        height="30"
                        alt="Tigermedia Logo"
                    />{' '}
                    <span style={{color: '#a1a3a6'}}>Tiger UI</span>
                    <span style={{color: '#a1a3a6', fontSize: "12px"}}>&#xA0;&#xA0;v{GlobalConstants.VERSION}</span>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav" className="justify-content-sm-center">
                    <Nav className="justify-content">


                        {roles != null && roles.includes('ROLE_ADMIN') &&
                            <Nav.Link href={"/"} style={navbarStyle}><span><FcHome size={25}/>&#xA0;Home</span></Nav.Link>
                        }

                        {roles != null && roles.includes('ROLE_ADMIN') &&
                            <NavDropdown title={entityTitle} id="basic-nav-dropdown" style={navbarStyle}>
                                <Link className="navbar-dropdown-link" to={"/" + GlobalConstants.APP_PATH + "products"}><IoIosArrowDropright/>&#xA0;Products</Link><br/>
                                <Link className="navbar-dropdown-link" to={"/" + GlobalConstants.APP_PATH + "accounts"}><IoIosArrowDropright/>&#xA0;Accounts</Link><br/>
                                <Link className="navbar-dropdown-link"
                                      to={"/" + GlobalConstants.APP_PATH + "cards"}><IoIosArrowDropright/>&#xA0;Cards</Link><br/>
                                <Link className="navbar-dropdown-link"
                                      to={"/" + GlobalConstants.APP_PATH + "series"}><IoIosArrowDropright/>&#xA0;Series</Link><br/>
                                <Link className="navbar-dropdown-link"
                                      to={"/" + GlobalConstants.APP_PATH + "shopLayouts"}><IoIosArrowDropright/>&#xA0;Shop
                                    Layouts</Link><br/>
                                <Link className="navbar-dropdown-link" to={"/" + GlobalConstants.APP_PATH + "clusters"}><IoIosArrowDropright/>&#xA0;Clusters</Link><br/>
                                <Link className="navbar-dropdown-link"
                                      to={"/" + GlobalConstants.APP_PATH + "collections"}><IoIosArrowDropright/>&#xA0;Collections</Link><br/>
                                <Link className="navbar-dropdown-link" to={"/" + GlobalConstants.APP_PATH + "routines"}><IoIosArrowDropright/>&#xA0;Routines</Link><br/>
                                <Link className="navbar-dropdown-link"
                                      to={"/" + GlobalConstants.APP_PATH + "assets"}><IoIosArrowDropright/>&#xA0;Assets</Link><br/>
                                <Link className="navbar-dropdown-link"
                                      to={"/" + GlobalConstants.APP_PATH + "publishingHouses"}><IoIosArrowDropright/>&#xA0;Publishing
                                    Houses</Link>
                                <Link className="navbar-dropdown-link" to={"/" + GlobalConstants.APP_PATH + "imprints"}><IoIosArrowDropright/>&#xA0;Imprints</Link><br/>
                                <Link className="navbar-dropdown-link"
                                      to={"/" + GlobalConstants.APP_PATH + "categories"}><IoIosArrowDropright/>&#xA0;Categories</Link><br/>
                                <Link className="navbar-dropdown-link"
                                      to={"/" + GlobalConstants.APP_PATH + "orders"}><IoIosArrowDropright/>&#xA0;Orders</Link><br/>
                                <Link className="navbar-dropdown-link"
                                      to={"/" + GlobalConstants.APP_PATH + "couponGroups"}><IoIosArrowDropright/>&#xA0;Coupon
                                    Groups</Link><br/>
                                <Link className="navbar-dropdown-link"
                                      to={"/" + GlobalConstants.APP_PATH + "productGroups"}><IoIosArrowDropright/>&#xA0;Product
                                    Groups</Link><br/>
                                <Link className="navbar-dropdown-link" to={"/" + GlobalConstants.APP_PATH + "restores"}><IoIosArrowDropright/>&#xA0;Restores</Link><br/>
                                <Link className="navbar-dropdown-link"
                                      to={"/" + GlobalConstants.APP_PATH + "orderFailureLogs"}><IoIosArrowDropright/>&#xA0;Order
                                    Failure Logs</Link>
                                <Link className="navbar-dropdown-link"
                                      to={"/" + GlobalConstants.APP_PATH + "tags"}><IoIosArrowDropright/>&#xA0;Tags</Link><br/>
                                <Link className="navbar-dropdown-link"
                                      to={"/" + GlobalConstants.APP_PATH + "libraries"}><IoIosArrowDropright/>&#xA0;Libraries</Link><br/>
                                <Link className="navbar-dropdown-link"
                                      to={"/" + GlobalConstants.APP_PATH + "libraryPools"}><IoIosArrowDropright/>&#xA0;Library
                                    Pools</Link><br/>
                                <Link className="navbar-dropdown-link"
                                      to={"/" + GlobalConstants.APP_PATH + "affiliates"}><IoIosArrowDropright/>&#xA0;Affiliates</Link><br/>
                                <Link className="navbar-dropdown-link"
                                      to={"/" + GlobalConstants.APP_PATH + "apps"}><IoIosArrowDropright/>&#xA0;Apps</Link><br/>
                                <Link className="navbar-dropdown-link"
                                      to={"/" + GlobalConstants.APP_PATH + "newsletters"}><IoIosArrowDropright/>&#xA0;Newsletter</Link><br/>
                                <Link className="navbar-dropdown-link"
                                      to={"/" + GlobalConstants.APP_PATH + "devices"}><IoIosArrowDropright/>&#xA0;Devices</Link>
                            </NavDropdown>
                        }

                        {roles != null && (roles.includes('ROLE_ADMIN') || roles.includes('ROLE_BUNDLE_SHIPPER') || roles.includes('ROLE_SUPPORT'))&&
                        <NavDropdown title={purchaseTitle} id="basic-nav-dropdown" style={navbarStyle}>
                            <Link className="navbar-dropdown-link" to={"/" + GlobalConstants.APP_PATH + "purchases/ordered-bundles"}><IoIosArrowDropright/>&#xA0;Ordered Bundles</Link><br />


                            {roles.includes('ROLE_ADMIN') &&
                                    <>
                                        <Link className="navbar-dropdown-link" to={"/" + GlobalConstants.APP_PATH + "purchases/tickets"}><IoIosArrowDropright/>&#xA0;Tickets</Link><br />
                                        <Link className="navbar-dropdown-link" to={"/" + GlobalConstants.APP_PATH + "purchases/premium-access-types"}><IoIosArrowDropright/>&#xA0;Order Configs</Link><br />
                                        <Link className="navbar-dropdown-link" to={"/" + GlobalConstants.APP_PATH + "purchases/transactions"}><IoIosArrowDropright/>&#xA0;Transactions</Link><br />
                                        <Link className="navbar-dropdown-link" to={"/" + GlobalConstants.APP_PATH + "purchases/contracts"}><IoIosArrowDropright/>&#xA0;Contract Cancellation</Link>
                                    </>
                            }
                            </NavDropdown>
                        }

                        {roles != null && roles.includes('ROLE_ADMIN') &&
                        <NavDropdown title={reportingTitle} id="basic-nav-dropdown" style={navbarStyle}>
                            <Link className="navbar-dropdown-link" to={"/" + GlobalConstants.APP_PATH + "aggregators"}><IoIosArrowDropright/>&#xA0;Aggregators</Link><br />
                            <Link className="navbar-dropdown-link" to={"/" + GlobalConstants.APP_PATH + "reporting"}><IoIosArrowDropright/>&#xA0;Reporting Tool</Link>
                        </NavDropdown>
                        }

                        {roles != null && roles.includes('ROLE_ADMIN') &&
                        <NavDropdown title={supportTitle} id="basic-nav-dropdown" style={navbarStyle}>
                            <Link className="navbar-dropdown-link" to={"/" + GlobalConstants.APP_PATH + "support"}><IoIosArrowDropright/>&#xA0;Support-Tool</Link><br />
                            <Link className="navbar-dropdown-link" to={"/" + GlobalConstants.APP_PATH + "recommendations"}><IoIosArrowDropright/>&#xA0;Content-News</Link><br />
                            <Link className="navbar-dropdown-link" to={"/" + GlobalConstants.APP_PATH + "statistics"}><IoIosArrowDropright/>&#xA0;Statistiken</Link><br />
                        </NavDropdown>
                        }

                        {roles != null && roles.includes('ROLE_ADMIN') &&
                        <NavDropdown title={toolsTitle} id="basic-nav-dropdown" style={navbarStyle}>
                            <Link className="navbar-dropdown-link" to={"/" + GlobalConstants.APP_PATH + "mailjet-analysis"}><IoIosArrowDropright/>&#xA0;Mailjet Analysis</Link>
                        </NavDropdown>
                        }

                        {roles != null && roles.includes('ROLE_ADMIN') &&
                        <NavDropdown title={administrationTitle} id="basic-nav-dropdown" style={navbarStyle}>
                            <Link className="navbar-dropdown-link" to={"/" + GlobalConstants.APP_PATH + "swagger"}><IoIosArrowDropright/>&#xA0;API</Link>
                        </NavDropdown>
                        }

                        {roles != null && roles.length > 0 ?
                            <NavDropdown title={accountTitle} id="basic-nav-dropdown" style={navbarStyle}>
                                <NavDropdown.Item href="/#/password"><span><RiLockPasswordLine/>&#xA0;Password</span>
                                </NavDropdown.Item>
                                <NavDropdown.Item
                                    onClick={() => logout({setToken, setRoles})}><span><FiLogOut/>&#xA0;Logout</span></NavDropdown.Item>
                            </NavDropdown>
                        :
                            <Nav.Link href={"/#/register"} style={navbarStyle}><span><BsPeopleFill />&#xA0;Register</span></Nav.Link>
                        }
                    </Nav>
                </Navbar.Collapse>
                <div style={environmentStyle}>{GlobalConstants.ENVIRONMENT}</div>
            </Navbar>
        </div>
    )
}