import {
    GetFilteredSpineResources,
    GetSpineResource
} from "../../clients/SpineClient";

//API METHODS
export async function GetFilteredRestores(filter){
    return await GetFilteredSpineResources('api/restores', filter);
}

export async function GetRestore(id) {
    return await GetSpineResource('api/restores/' + id);
}