import {useState} from "react";

export default function useToken() {

    const getToken = () => {
        const token = JSON.parse(localStorage.getItem('ls.token'));
        return token;
    };

    const [token, setToken] = useState(getToken());

    const saveToken = userToken => {
        localStorage.setItem('ls.token', JSON.stringify(userToken));
        setToken(userToken);
    };

    return {
        setToken: saveToken,
        token
    }

}

export function getToken() {
    return JSON.parse(localStorage.getItem('ls.token'));
}

export function isInRole(role) {
    const token = getToken();
    if(token != null && token.sec != null) {
        const roles = atob(token.sec);
        if (roles !== undefined && roles !== "") {
            return roles.includes(role);
        } else {
            return false;
        }
    } else {
        return false;
    }
}

export function isInAnyRole(){
    const token = getToken();
    if(token != null) {
        return token !== "" && token.sec != null;
    } else {
        return false;
    }
}