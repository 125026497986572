import {Button} from "react-bootstrap";
import React, {Component} from "react";
import {withToast} from "../../util/ToastService";
import {Link} from "react-router-dom";
import {
    FaBook,
} from "react-icons/all";
import GlobalConstants from "../../config/GlobalConstants";
import {CreateExample, GetExample, UpdateExample} from "./ExampleService";
import {DetailsSection, RequiredFieldsAreValid} from "../../generators/DetailsGenerator";
import ExampleMetaData from "./ExampleMetaData";
import {PromiseButton} from "../../global/SpinningTiger";
import {ErrorHandler} from "../../util/ErrorHandler";

class ExampleDetails extends Component {

    //------------
    //Constructor
    //------------

    constructor(props) {
        super(props);

        this.state = {
            error: null,
            flowState: props.location.state,
            id: this.props.match.params.id,
            editMode: {
                active: false,
                expertMode: false
            },
            example: {},
            originalExample: {},
            sections: {
                general: true,
                section1: true,
            }
        }
    }

    //---------
    //Mounting
    //---------

    async componentDidMount() {
        await this.loadExample();
    }

    //--------
    //Loading
    //--------

    async loadExample() {
        let loadedExample = {};

        if(this.state.id != null && this.state.id !== "add") {

            loadedExample = await GetExample(this.state.id);

            if (!loadedExample.error) {
                const example = loadedExample.result;

                this.setState({
                    id: this.state.id,
                    editMode: this.state.editMode,
                    example: example,
                    originalExample: example,
                });
            } else {
                this.setState(prevState => ({...prevState, error: loadedExample}));
            }
        } else {
            this.setState((prevState) => ({...prevState,
                id: null,
                example: {
                    id: null,
                },
                editMode: {
                    active: true,
                    expertMode: false
                }}));
        }
    }

    //----------
    //Rendering
    //----------

    render() {

        return (
            <>
                <div className="details-title">
                    {this.state.example.id != null ? "Details of Example " + this.state.example.id : "Create a new Example"}
                </div>
                <div className="details-button-box" style={{height: "70px"}}>
                    <Link to={{pathname: "/" + GlobalConstants.APP_PATH +"examples", state: this.state.flowState}}>
                        <button className="form-btn-ci-light-blue" type="button">Back</button>
                    </Link>
                    {this.state.example.id != null &&
                    <button className={this.state.editMode.active ? "form-btn-ci-red" : "form-btn-ci-blue"} type="button" onClick={() => this.setState({
                        editMode: {
                            active: !this.state.editMode.active,
                            expertMode: this.state.editMode.expertMode
                        },
                        example: this.state.originalExample
                    })}>{this.state.editMode.active ? "Cancel" : "Edit"}</button>
                    }
                    {this.state.editMode.active &&
                    <PromiseButton text="Save" onClick={() => this.saveOrUpdateExample()} />
                    }
                    <Button style={{float: "right", marginTop: "5px"}} variant={this.state.editMode.expertMode ? "secondary": "outline-secondary"}
                            onClick={() => this.setState(prevState => ({
                                ...prevState,
                                editMode: {
                                    ...prevState.editMode,
                                    expertMode: !this.state.editMode.expertMode
                                }
                            }))}>Expert Mode</Button>
                </div>

                <div className="details-box-title">Resource Details</div>
                <div className="details-box">
                    {/* GENERAL DATA */}
                    <DetailsSection
                        nameInState="example"
                        fields={ExampleMetaData.DETAILS_GENERAL}
                        state={this.state}
                        onSetState={(s) => this.setState(s)}
                        sectionId="general"
                    />

                    {/* SECTION 1 */}
                    <DetailsSection
                        nameInState="example"
                        fields={ExampleMetaData.DETAILS_SECTION_1}
                        state={this.state}
                        onSetState={(s) => this.setState(s)}
                        sectionId="section1"
                        label={<span><FaBook/>&#xA0;Details Section 1</span>}
                    />

                </div>
                <div className="details-button-box" style={{height: "70px"}}>
                    <Link to={{pathname: "/" + GlobalConstants.APP_PATH +"examples", state: this.state.flowState}}>
                        <button className="form-btn-ci-light-blue" type="button">Back</button>
                    </Link>
                    {this.state.editMode.active &&
                    <PromiseButton text="Save" onClick={() => this.saveOrUpdateExample()} />
                    }
                </div>

                <ErrorHandler error={this.state.error}
                              onHide={() => this.setState(prevState => ({...prevState, error: null}))} />
            </>
        );
    }

    //------------
    //API Methods
    //------------

    async saveOrUpdateExample() {
        if (!RequiredFieldsAreValid("example", [ExampleMetaData.DETAILS_GENERAL, ExampleMetaData.DETAILS_SECTION_1],
            this.state, (s) => this.setState(s))) {
            return;
        }
        let example = {};
        if(this.state.example.id != null) {
            example = await UpdateExample(this.state.example);
        } else {
            example = await CreateExample(this.state.example);
        }

        if (!example.error) {
            if (this.state.example.id == null) {
                this.props.history.push("/"+ GlobalConstants.APP_PATH + "examples/" + example.result.id);
            }
            this.setState((prevState) =>({...prevState, example: example.result, editMode: {...prevState.editMode, active: false}}));

            this.props.addToast("The example has been updated successfully.", {
                autoDismiss: true,
                appearance: 'success'
            });
        } else {
            this.setState(prevState => ({...prevState, error: example}));
        }
    }

}


export default withToast(ExampleDetails);