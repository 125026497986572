import {Button} from "react-bootstrap";
import React, {Component} from "react";
import {withToast} from "../../util/ToastService";
import {Link} from "react-router-dom";
import {
    IoPeopleCircleOutline,
} from "react-icons/all";
import GlobalConstants from "../../config/GlobalConstants";
import {DetailsSection} from "../../generators/DetailsGenerator";
import {
    CreateTicketOrder,
    CreateTigerticket,
    GetTigerticket,
    ResetTigerticket,
    UpdateTigerticket
} from "./TicketService";
import TicketMetaData from "./TicketMetaData";
import {ActionModal, InfoModal} from "../../generators/ModalGenerator";
import {OrderTicketDialog} from "./OrderTicketDialog";
import {PromiseButton} from "../../global/SpinningTiger";
import {ErrorHandler} from "../../util/ErrorHandler";

class TigerticketDetails extends Component {

    //------------
    //Constructor
    //------------

    constructor(props) {
        super(props);

        this.state = {
            error: null,
            flowState: props.location.state,
            id: this.props.match.params.id,
            editMode: {
                active: false,
                expertMode: false,
                autoSave: true,
            },
            tigerticket: {},
            originalTigerticket: {},
            sections: {
                general: true,
                ticketDetails: true,
                statusDetails: true,
            },
            showResetModal: false,
            showOrderDialog: false
        }
    }

    //---------
    //Mounting
    //---------

    async componentDidMount() {
        //Set the title
        if(this.state.id != null) {
            document.title = "tigerticket " + this.state.id + " :: Tiger UI";
        } else {
            document.title = "New tigerticket :: Tiger UI";
        }
        await this.loadTigerticket();
    }

    //--------
    //Loading
    //--------

    async loadTigerticket() {
        let loadedTigerticket = {};

        if(this.state.id != null && this.state.id !== "add") {

            loadedTigerticket = await GetTigerticket(this.state.id);

            if (!loadedTigerticket.error) {
                const tigerticket = loadedTigerticket.result;

                this.setState({
                    id: this.state.id,
                    editMode: this.state.editMode,
                    tigerticket: tigerticket,
                    originalTigerticket: tigerticket,
                });
            } else {
                this.setState(prevState => ({...prevState, error: loadedTigerticket}));
            }
        } else {
            this.setState((prevState) => ({...prevState,
                id: null,
                tigerticket: {
                    id: null,
                    landingPageId: GlobalConstants.DEFAULT_LANDING_PAGE_ID,
                    createdBy: 'system'
                },
                editMode: {
                    active: true,
                    expertMode: false,
                    autoSave: false,
                }}));
        }
    }

    //----------
    //Rendering
    //----------

    render() {

        return (
            <>
                <div className="details-title">
                    {this.state.tigerticket.id != null ? "Details of tigerticket " + this.state.tigerticket.id : "Create a new tigerticket"}
                </div>
                <div className="details-button-box" style={{height: "70px"}}>
                    <Link to={{pathname: "/" + GlobalConstants.APP_PATH +"purchases/tickets", state: this.state.flowState}}>
                        <button className="form-btn-ci-light-blue" type="button">Back</button>
                    </Link>
                    {this.state.tigerticket.id != null &&
                    <button className={this.state.editMode.active ? "form-btn-ci-red" : "form-btn-ci-blue"} type="button" onClick={() => this.setState({
                        editMode: {
                            active: !this.state.editMode.active,
                            expertMode: this.state.editMode.expertMode
                        },
                        tigerticket: this.state.originalTigerticket
                    })}>{this.state.editMode.active ? "Cancel" : "Edit"}</button>
                    }
                    {this.state.editMode.active &&
                        <PromiseButton text="Save" onClick={() => this.saveOrUpdateTigerticket()} />
                    }
                    <button style={{marginLeft: "20px"}} className="form-btn-ci-light" onClick={() => this.setState(prevState => ({...prevState, showResetModal: true}))}>Reset tigerticket</button>
                    <button className={this.state.tigerticket.used ? "form-btn-ci-off" : "form-btn-ci-green"}
                            onClick={() => this.setState(prevState => ({...prevState, showOrderDialog: true}))}
                            disabled={this.state.tigerticket.used}>
                        Create Order
                    </button>
                    <Button style={{float: "right", marginTop: "5px", marginRight: "10px"}} variant={this.state.editMode.autoSave ? "secondary": "outline-secondary"}
                            onClick={() => this.setState(prevState => ({
                                ...prevState,
                                editMode: {
                                    ...prevState.editMode,
                                    autoSave: !this.state.editMode.autoSave
                                }
                            }))}>Auto Save</Button>
                </div>

                <div className="details-box-title">Resource Details</div>
                <div className="details-box">
                    {/* GENERAL DATA */}
                    <DetailsSection
                        nameInState="tigerticket"
                        fields={TicketMetaData.TIGERTICKET_DETAILS_GENERAL}
                        state={this.state}
                        onSetState={(s) => this.setState(s)}
                        onUpdateResource={() => this.saveOrUpdateTigerticket()}
                        sectionId="general"
                    />

                    <DetailsSection
                        nameInState="tigerticket"
                        fields={TicketMetaData.TIGERTICKET_DETAILS_TICKET}
                        state={this.state}
                        onSetState={(s) => this.setState(s)}
                        onUpdateResource={() => this.saveOrUpdateTigerticket()}
                        sectionId="ticketDetails"
                        label={<span><IoPeopleCircleOutline/>&#xA0;Ticket</span>}
                    />

                    <DetailsSection
                        nameInState="tigerticket"
                        fields={TicketMetaData.TIGERTICKET_DETAILS_STATUS}
                        state={this.state}
                        onSetState={(s) => this.setState(s)}
                        onUpdateResource={() => this.saveOrUpdateTigerticket()}
                        sectionId="statusDetails"
                        label={<span><IoPeopleCircleOutline/>&#xA0;Status</span>}
                    />

                </div>
                <div className="details-button-box" style={{height: "70px"}}>
                    <Link to={{pathname: "/" + GlobalConstants.APP_PATH +"purchases/tickets", state: this.state.flowState}}>
                        <button className="form-btn-ci-light-blue" type="button">Back</button>
                    </Link>
                    {this.state.editMode.active &&
                        <PromiseButton text="Save" onClick={() => this.saveOrUpdateTigerticket()} />
                    }
                </div>

                <ActionModal show={this.state.showResetModal}
                             actionButtonText={"Continue"}
                             size='sm'
                             onAction={() => this.resetTigerticket()}
                             onHide={() => this.setState(prevState => ({...prevState, showResetModal: false}))}
                             title="Reset tigerticket "
                             body={
                        <div style={{textAlign: "center"}}>
                            By resetting the tigerticket, redemption status will be deleted so it can be redeemed again. Continue?
                        </div>
                }/>

                <InfoModal show={this.state.showOrderDialog}
                           onHide={() => this.setState(prevState => ({...prevState, showOrderDialog: false}))}
                           title={"Create order for ticket " + this.state.tigerticket.code}
                           body={
                               <OrderTicketDialog code={this.state.tigerticket.code} pin={this.state.tigerticket.pin}
                                                  onOrder={(accountId) => this.createTicketOrder(accountId)}/>
                           }/>

                <ErrorHandler error={this.state.error}
                              onHide={() => this.setState(prevState => ({...prevState, error: null}))} />
            </>
        );
    }

    async createTicketOrder(accountId) {
        let response = await CreateTicketOrder(this.state.tigerticket.code, this.state.tigerticket.pin, accountId);
        if (!response.error) {
            this.loadTigerticket().then(r => this.setState(prevState => ({...prevState, showOrderDialog: false})));
        } else {
            this.setState(prevState => ({...prevState, error: response}));
        }
    }

    //------------
    //API Methods
    //------------

    async saveOrUpdateTigerticket() {
        let tigerticket = {};
        if(this.state.tigerticket.id != null) {
            tigerticket = await UpdateTigerticket(this.state.tigerticket);
        } else {
            tigerticket = await CreateTigerticket(this.state.tigerticket);
        }

        if (!tigerticket.error) {
            this.setState((prevState) =>({...prevState, tigerticket: tigerticket.result, editMode: {...prevState.editMode, active: this.state.editMode.autoSave}}));

            this.props.addToast("The tigerticket has been updated successfully.", {
                autoDismiss: true,
                appearance: 'success'
            });
        } else {
            this.setState(prevState => ({...prevState, error: tigerticket}));
        }
    }

    async resetTigerticket() {
        let response = await ResetTigerticket(this.state.id);
        if(!response.error) {
            this.loadTigerticket().then(r => {
                this.props.addToast("tigerticket has been reset successfully", {
                    autoDismiss: true,
                    appearance: "success"
                });
                this.setState(prevState => ({...prevState, showResetModal: false}));
            });
        } else {
            this.setState(prevState => ({...prevState, error: response}));
        }
    }

        
}


export default withToast(TigerticketDetails);