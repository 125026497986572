import {
    AssociateSpineResources,
    GetSpineResource,
    UpdateSpineResource
} from "../../clients/SpineClient";
import GlobalConstants from "../../config/GlobalConstants";

//API METHODS
export async function GetReportingProduct(id) {
    return await GetSpineResource('api/products/' + id + '/reporting');
}

export async function UpdateReportingProduct(id, reportingProduct) {
    return await UpdateSpineResource('api/products/' + id + '/reporting', reportingProduct);
}

export async function SetReportingProductAggregator(id, aggregatorId) {
    return await AssociateSpineResources('api/products/' + id + '/reporting/aggregator', GlobalConstants.SPINE_CLIENT_HOST + 'api/aggregators/' + aggregatorId);
}

//---------- OPTIONAL -------------
/*
export async function PatchReportingProduct(reportingProduct, newValue) {
    return await PatchResource('api/reportingProducts/' + reportingProduct.id + '/patch', {attribute: newValue});
}
 */