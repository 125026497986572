const RECOMMENDATION_TYPES = [{id: 'NEW_PRODUCT', label: 'New Product'}, {id: 'SIMILIAR_PRODUCT', label: 'Similar Product'}];

const TransactionMetaData = {

    TRANSACTION_COLUMNS: [
        //Default & Badges
        {label: "ID", id: 'id', columnType: 'default', show: true},
    ],

    TRANSACTION_DETAILS_GENERAL: [
        [{label: 'ID', id: 'id', type: 'text', editable: 'never'}, {label: 'Current Client', id: 'currentClient', type: 'select', options: ["PAYONE", "BILLWERK", "INTERN"], editable: 'true'}, {label: 'Last Modified By', id: 'lastModifiedBy', type: 'text', editable: 'never'}, {label: "Last Modified Date", id: 'lastModifiedDate', type: 'datetime', editable: 'never'}],
        [{label: 'Created By', id: 'createdBy', type: 'text', editable: 'never', isExpertField: true}, {label: 'Created At', id: 'createdDate', type: 'datetime', editable: 'never', isExpertField: true}],
    ],
    TRANSACTION_PURCHASE_DETAILS: [
        [{label: 'Account Email', id: 'accountEmail', type: 'text', editable: 'true'}, {label: 'State', id: 'state', type: 'text', editable: 'never'}, {label: 'Merchant Reference', id: 'merchantReference', type: 'text', editable: 'true'}, {label: 'Payment Channel', id: 'paymentChannelType', type: 'text', editable: 'true'}],
        [{label: 'Payone Transaction ID', id: 'payoneTransactionId', type: 'text', editable: 'true'}, {label: 'Billwerk Order ID', id: 'billwerkOrderId', type: 'text', editable: 'true'}, {label: 'Billwerk Contract ID', id: 'billwerkContractId', type: 'text', editable: 'true'}],
        [{label: 'Failures', id: 'failures', type: 'text', editable: 'never'}, {label: 'Last Failed Try', id: 'lastFailedTry', type: 'datetime', editable: 'never'}, {label: 'Blocked Until', id: 'blockedUntil', type: 'datetime', editable: 'never'}]
    ],
    TRANSACTION_PRODUCT_DETAILS: [
        [{label: 'Mapped Product ID', id: 'mappedBillwerkProductId', type: 'text', editable: 'true'}, {label: 'Ticket ID', id: 'ticketId', type: 'text', editable: 'true'}, {label: 'Ticket Type', id: 'premiumType', type: 'text', editable: 'true'}]
    ],
    RECOMMENDATION_PRODUCTS_SORT_PARAMS: [],
    RECOMMENDATION_PRODUCT_TYPES: RECOMMENDATION_TYPES,
};

export default TransactionMetaData;