const CouponMetaData = {

    //Details
    DETAILS_GENERAL: [
        [{label: 'ID', id: 'id', type: 'text', editable: 'never'}, {label: 'Code', id: 'code', type: 'text', editable: 'true'}],
    ],
    USAGE_DETAILS: [
        [{label: 'Usage Limit', id: 'usageLimit', type: 'text', editable: 'true'}, {label: 'Usage', id: 'usage', type: 'text', editable: 'never'},{label: 'Last Usage Date', id: 'lastUsageDate', type: 'text', editable: 'never'}],
    ],

    //Sorting
    COUPON_SORT_PARAMS: [{}],
};

export default CouponMetaData;