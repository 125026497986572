const ImprintMetaData = {

    //Columns
    COLUMNS: [
        //Default & Badges
        {label: "ID", id: 'id', columnType: 'default', show: true},
        {label: "Name", id: 'name', columnType: 'default', show: true},
        {label: "Publishing House ID", id: 'publishingHouseId', columnType: 'default', show: false},
        {label: "Publishing House Name", id: 'publishingHouseName', columnType: 'default', show: true},
    ],

    //Details
    DETAILS_GENERAL: [
        [{label: 'ID', id: 'id', type: 'text', editable: 'never', isExpertField: false}, {label: 'Name', id: 'name', type: 'text', editable: 'true', required: true}],
    ],
    DETAILS_PUBLISHING_HOUSE: [
        [{label: 'Publishing House ID', id: 'publishingHouseId', type: 'text', editable: 'true', required: true}, {label: 'Publishing House Name', id: 'publishingHouseName', type: 'text'}],
    ],

    //Sorting
    IMPRINT_SORT_PARAMS: [],
};

export default ImprintMetaData;