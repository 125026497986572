import React, {Component} from "react";
import GlobalConstants from "../config/GlobalConstants";

class Swagger extends Component {

    render() {
        return (
            <div className="content-box-centered" style={{height: "1200px"}}>
                <div className="content-box-title">Swagger</div>
                <div className="content-box-body">
                    <iframe title="swagger-ui" src={GlobalConstants.SPINE_CLIENT_HOST + "swagger-ui.html"} frameBorder="0" marginHeight="0" marginWidth="0"
                            width="100%" height="1200px" scrolling="auto" target='_top'/>
                </div>
            </div>
        );
    }
}

export default Swagger;