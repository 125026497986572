import {Table, Button} from "react-bootstrap";
import {useState} from "react";
import {openExternalTargetInNewTab} from "../../util/ResourceService";
import GlobalConstants from "../../config/GlobalConstants";

export function LayoutItemsDialog(props) {

    let [banners] = useState(props.shopLayout.layoutItems.filter(item => item.layoutItemType === 'BANNER'));
    let [queries] = useState(props.shopLayout.layoutItems.filter(item => item.layoutItemType === 'QUERY'));
    let [productLists] = useState(props.shopLayout.layoutItems.filter(item => item.layoutItemType === 'PRODUCT_LIST'));
    let [resources] = useState(props.shopLayout.layoutItems.filter(item => item.layoutItemType === 'RESOURCE'));

    let [showBanners, setShowBanners] = useState(true);
    let [showQueries, setShowQueries] = useState(false);
    let [showResources, setShowResources] = useState(false);
    let [showProductLists, setShowProductLists] = useState(false);

    return(
        <div>

            {banners.length > 0 &&
                <Button variant={showBanners ? "secondary" : "outline-secondary"} style={{margin: "10px"}} onClick={() => {
                    setShowBanners(true);
                    setShowQueries(false);
                    setShowResources(false);
                    setShowProductLists(false);
                }}>Banners</Button>
            }

            {queries.length > 0 &&
                <Button variant={showQueries ? "secondary" : "outline-secondary"} style={{margin: "10px"}} onClick={() => {
                    setShowBanners(false);
                    setShowQueries(true);
                    setShowResources(false);
                    setShowProductLists(false);
                }}>Queries</Button>
            }

            {productLists.length > 0 &&
                <Button variant={showProductLists ? "secondary" : "outline-secondary"} style={{margin: "10px"}} onClick={() => {
                    setShowBanners(false);
                    setShowQueries(false);
                    setShowResources(false);
                    setShowProductLists(true);
                }}>Product Lists</Button>
            }

            {resources.length > 0 &&
                <Button variant={showResources ? "secondary" : "outline-secondary"} style={{margin: "10px"}} onClick={() => {
                    setShowBanners(false);
                    setShowQueries(false);
                    setShowResources(true);
                    setShowProductLists(false);
                }}>Resources</Button>
            }

            {/* BANNERS */}
            {banners.length > 0 && showBanners &&
            <>
                <Table bordered hover responsive striped>
                    <thead>
                        <tr>
                            <th>Ordinal</th>
                            <th>Title</th>
                            <th>Size</th>
                            <th>Images</th>
                        </tr>
                    </thead>
                    <tbody>
                    {banners.map(item => (
                        <tr role="row" key={item.title}>
                            <td>{item.ordinal}</td>
                            <td>{item.title}</td>
                            <td>{item.banner.size != null ? item.banner.size : null}</td>
                            <td align={"center"}>
                                {item.banner.images.map(image => (
                                    <p style={{cursor: "pointer"}} onClick={() => openExternalTargetInNewTab(image.hdImage)}>
                                        <span style={{fontStyle: "italic", backgroundColor: "lightgrey", borderRadius: "5px", padding: "5px"}}>{image.title}:</span>
                                        <br />
                                        <img src={image.hdImage} alt={"Preview of image " + image.title} width="100px" height="100px" />
                                    </p>
                                ))}
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
            </>
            }

            {/* QUERIES */}
            {queries.length > 0 && showQueries &&
            <>
                <Table bordered hover responsive striped>
                    <thead>
                    <tr>
                        <th>Ordinal</th>
                        <th>Title</th>
                        <th>Product Type</th>
                        <th>Category Codes</th>
                    </tr>
                    </thead>
                    <tbody>
                    {queries.map(item => (
                        <tr role="row" key={item.title}>
                            <td>{item.ordinal}</td>
                            <td>{item.title}</td>
                            <td>{item.query.productType != null ? item.query.productType : null}</td>
                            <td>
                                <ul style={{listStyle: "square"}}>
                                {item.query.categoryCodes.map(code => (
                                    <li>{code}</li>
                                ))}
                                </ul>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
            </>
            }

            {/* PRODUCT LISTS */}
            {productLists.length > 0 && showProductLists &&
            <>
                <Table bordered hover responsive striped>
                    <thead>
                    <tr>
                        <th>Ordinal</th>
                        <th>Title</th>
                        <th>Product Identifier</th>
                    </tr>
                    </thead>
                    <tbody>
                    {productLists.map(item => (
                        <tr role="row" key={item.title}>
                            <td>{item.ordinal}</td>
                            <td>{item.title}</td>
                            <td>
                                <ul style={{listStyle: "square"}}>
                                    {item.productIdentifier.map(identifier => (
                                        <li><a href={GlobalConstants.SPINE_CLIENT_HOST + GlobalConstants.APP_PATH + "products/" + identifier} style={{fontStyle: "italic", color: "#333"}}>{identifier}</a></li>
                                    ))}
                                </ul>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
            </>
            }

            {/* RESOURCES */}
            {resources.length > 0 && showResources &&
            <>
                <Table bordered hover responsive striped>
                    <thead>
                    <tr>
                        <th>Ordinal</th>
                        <th>Title</th>
                        <th>Product Source Type</th>
                    </tr>
                    </thead>
                    <tbody>
                    {resources.map(item => (
                        <tr role="row" key={item.title}>
                            <td>{item.ordinal}</td>
                            <td>{item.title}</td>
                            <td>{item.productSourceType}</td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
            </>
            }
        </div>
    )
}