const ACQUISITION_TYPES = ["FULL", "SUBSCRIPTION", "PREVIEW"];
const ACQUISITION_SOURCES = ["ADMIN", "SUPPORT", "UNKNOWN", "IAP_APPLE", "IAP_GOOGLE", "SHOP", "MOBILCOM", "PARTNER", "COUPON", "LIBRARY", "GUESTACCOUNT", "SUBSCRIPTION", "RECOMMENDATION", "ACCOUNTCREATION", "IAP_APPLE_RESTORE", "IAP_GOOGLE_RESTORE", "TEST"];

const AcquisitionMetaData = {

    //Columns
    COLUMNS: [
        //Default & Badges
        {label: "ID", id: 'id', columnType: 'default', show: true},
        {label: "Name", id: 'name', columnType: 'default', show: true},
    ],

    //Details
    DETAILS_GENERAL: [
        [{label: 'ID', id: 'id', type: 'text', editable: 'never'}, {label: 'Version', id: 'version', type: 'text', editable: 'never'}, {label: "Account ID", id: 'accountId', type: 'text', editable: 'never'}],
    ],
    ACQUISITION_DETAILS: [
        [{label: 'Type', id: 'assignmentType', type: 'select', options: ACQUISITION_TYPES, editable: 'never'}, {label: 'Source', id: 'source', type: 'select', options: ACQUISITION_SOURCES, editable: 'never'}, {label: 'Valid Until', id: 'validUntil', type: 'text', editable: 'never'}],
        [{label: 'Product ID', id: 'productId', type: 'text', editable: 'never'}, {label: 'Subscription ID', id: 'subscriptionId', type: 'text', editable: 'never'}, {label: 'Slot Number', id: 'slotNumber', type: 'text', editable: 'never'}]
    ],

    //Sorting
    ACQUISITION_SORT_PARAMS: [],

};
export default AcquisitionMetaData;