import {
    CreateSpineResource,
    DeleteSpineResource,
    GetFilteredSpineResources,
    GetSpineResource,
    UpdateSpineResource
} from "../../clients/SpineClient";

//API METHODS
export async function CreateProductGroup(productGroup) {
    return await CreateSpineResource('api/productGroups', productGroup);
}

export async function GetFilteredProductGroups(filter){
    return await GetFilteredSpineResources('api/productGroups', filter);
}

export async function GetProductGroup(id) {
    return await GetSpineResource('api/productGroups/' + id);
}

export async function UpdateProductGroup(productGroup) {
    return await UpdateSpineResource('api/productGroups/' + productGroup.id, productGroup);
}

export async function DeleteProductGroup(productGroup) {
    return await DeleteSpineResource('api/productGroups/' + productGroup.id);
}

//---------- OPTIONAL -------------
/*
export async function PatchProductGroup(productGroup, newValue) {
    return await PatchResource('api/productGroups/' + productGroup.id + '/patch', {attribute: newValue});
}
 */