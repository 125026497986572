import React, {useState} from "react";
import {Col, Form, FormControl} from "react-bootstrap";
import Row from 'react-bootstrap/Row';
import {Label} from "reactstrap";
import {AsyncTypeahead} from "react-bootstrap-typeahead";
import GlobalConstants from "../../config/GlobalConstants";
import useToken from "../../auth/SpineTokenStorageService";

export function OrderTicketDialog(props) {
    const [code] = useState(props.code);
    const [pin] = useState(props.pin);
    const [accountId, setAccountId] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [accountList, setAccountList] = useState([]);
    const token = useToken();

    const filterBy = () => true;

    const handleSearch = (email) => {

        setIsLoading(true);

        fetch(GlobalConstants.SPINE_CLIENT_HOST + `api/accounts?email=${email}`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token.access_token,
            }
        })
            .then(function(resp) {
                return resp.json()
            })
            .then(function (accounts) {
                const accountList = accounts.map((account) => ({
                    id: account.id,
                    email: account.email
                }));

                setAccountList(accountList);
                setIsLoading(false);
            });
    };

    return (
        <>
            <Form>
                <Row>
                    <Form.Group as={Col}>
                        <Label>Code</Label>
                        <FormControl value={code} disabled={true} />
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Label>PIN</Label>
                        <FormControl value={pin} disabled={true} />
                    </Form.Group>
                </Row>
            </Form>
            <h5>Account</h5>
            <AsyncTypeahead
                filterBy={filterBy}
                id={"account-search-typeahead"}
                isLoading={isLoading}
                labelKey={"email"}
                minLength={3}
                onSearch={handleSearch}
                options={accountList}
                placeholder="Gib eine E-Mail-Adresse ein..."
                renderMenuItemChildren={(account, props) => (
                    <div onClick={() => setAccountId(account.id)}>
                        <span style={{fontWeight: "bold"}}>{account.email}</span> ({account.id})
                    </div>
                )}
            />
            <br />
            <div style={{float: "right"}}>
                <button className="form-btn-ci-blue" onClick={() => props.onOrder(accountId)}>Create Order</button>
            </div>
        </>
    );


}