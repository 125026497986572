import {Button} from "react-bootstrap";
import React, {Component} from "react";
import {withToast} from "../../util/ToastService";
import {Link} from "react-router-dom";
import GlobalConstants from "../../config/GlobalConstants";
import {CreateAffiliate, GetAffiliate, UpdateAffiliate} from "./AffiliateService";
import {DetailsSection} from "../../generators/DetailsGenerator";
import AffiliateMetaData from "./AffiliateMetaData";
import {PromiseButton} from "../../global/SpinningTiger";
import {ErrorHandler} from "../../util/ErrorHandler";

class AffiliateDetails extends Component {

    //------------
    //Constructor
    //------------

    constructor(props) {
        super(props);

        this.state = {
            error: null,
            flowState: props.location.state,
            id: this.props.match.params.id,
            editMode: {
                active: false,
                expertMode: false,
                autoSave: true,
            },
            affiliate: {},
            originalAffiliate: {},
            sections: {
                general: true,
                section1: true,
            },
            validationErrors: []
        }
    }

    //---------
    //Mounting
    //---------

    async componentDidMount() {
        //Set the title
        if(this.state.id != null) {
            document.title = "Affiliate " + this.state.id+ " :: Tiger UI";
        } else {
            document.title = "New affiliate :: Tiger UI";
        }
        await this.loadAffiliate();
    }

    //--------
    //Loading
    //--------

    async loadAffiliate() {
        let loadedAffiliate = {};

        if(this.state.id != null && this.state.id !== "add") {

            loadedAffiliate = await GetAffiliate(this.state.id);

            if (!loadedAffiliate.error) {
                const affiliate = loadedAffiliate.result;

                this.setState({
                    id: this.state.id,
                    editMode: this.state.editMode,
                    affiliate: affiliate,
                    originalAffiliate: affiliate,
                });
            } else {
                this.setState(prevState => ({...prevState, error: loadedAffiliate}));
            }
        } else {
            this.setState((prevState) => ({...prevState,
                id: null,
                affiliate: {
                    id: null,
                },
                editMode: {
                    active: true,
                    expertMode: false,
                    autoSave: false
                }}));
        }
    }

    //----------
    //Rendering
    //----------

    render() {

        return (
            <>
                <div className="details-title">
                    {this.state.affiliate.id != null ? "Details of Affiliate " + this.state.affiliate.id : "Create a new Affiliate"}
                </div>
                <div className="details-button-box" style={{height: "70px"}}>
                    <Link to={{pathname: "/" + GlobalConstants.APP_PATH +"affiliates", state: this.state.flowState}}>
                        <button className="form-btn-ci-light-blue" type="button">Back</button>
                    </Link>
                    {this.state.affiliate.id != null &&
                    <button className={this.state.editMode.active ? "form-btn-ci-red" : "form-btn-ci-blue"} type="button" onClick={() => this.setState({
                        editMode: {
                            active: !this.state.editMode.active,
                            expertMode: this.state.editMode.expertMode
                        },
                        affiliate: this.state.originalAffiliate
                    })}>{this.state.editMode.active ? "Cancel" : "Edit"}</button>
                    }
                    {this.state.editMode.active &&
                        <PromiseButton text="Save" onClick={() => this.saveOrUpdateAffiliate()} />
                    }
                    <Button style={{float: "right", marginTop: "5px", marginRight: "10px"}} variant={this.state.editMode.autoSave ? "secondary": "outline-secondary"}
                            onClick={() => this.setState(prevState => ({
                                ...prevState,
                                editMode: {
                                    ...prevState.editMode,
                                    autoSave: !this.state.editMode.autoSave
                                }
                            }))}>Auto Save</Button>
                </div>

                <div className="details-box-title">Resource Details</div>
                <div className="details-box">
                    {/* GENERAL DATA */}
                    <DetailsSection
                        nameInState="affiliate"
                        fields={AffiliateMetaData.DETAILS_GENERAL}
                        state={this.state}
                        onSetState={(s) => this.setState(s)}
                        onUpdateResource={() => this.saveOrUpdateAffiliate()}
                        sectionId="general"
                    />

                </div>
                <div className="details-button-box" style={{height: "70px"}}>
                    <Link to={{pathname: "/" + GlobalConstants.APP_PATH +"affiliates", state: this.state.flowState}}>
                        <button className="form-btn-ci-light-blue" type="button">Back</button>
                    </Link>
                    {this.state.editMode.active &&
                        <PromiseButton text="Save" onClick={() => this.saveOrUpdateAffiliate()} />
                    }
                </div>

                <ErrorHandler error={this.state.error}
                              onHide={() => this.setState(prevState => ({...prevState, error: null}))} />
            </>
        );
    }

    //------------
    //API Methods
    //------------

    async saveOrUpdateAffiliate() {
        let affiliate = {};
        if(this.state.affiliate.id != null) {
            affiliate = await UpdateAffiliate(this.state.affiliate);
        } else {
            affiliate = await CreateAffiliate(this.state.affiliate);
        }

        if (!affiliate.error) {
            if (this.state.affiliate.id == null) {
                this.props.history.push("/"+ GlobalConstants.APP_PATH + "affiliates/" + affiliate.result.id);
            }
            this.setState((prevState) =>({...prevState, affiliate: affiliate.result, editMode: {...prevState.editMode, active: this.state.editMode.autoSave}}));

            this.props.addToast("The affiliate has been updated successfully.", {
                autoDismiss: true,
                appearance: 'success'
            });
        } else {
            this.setState(prevState => ({...prevState, error: affiliate}));
        }
    }

}


export default withToast(AffiliateDetails);