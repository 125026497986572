import {
    CreateSpineResource,
    DeleteSpineResource,
    GetFilteredSpineResources,
    GetSpineResource
} from "../../clients/SpineClient";

//API METHODS
export async function CreateAcquisition(account, acquisition) {
    return await CreateSpineResource('api/accounts/' + account.id + '/acquisitions', acquisition);
}

export async function CreateTestAcquisition(account, acquisition) {
    return await CreateSpineResource('api/accounts/' + account.id + '/acquisitions?test=true', acquisition);
}

export async function GetFilteredAcquisitions(account, filter){
    return await GetFilteredSpineResources('api/accounts/' + account.id + '/acquisitions', filter);
}

export async function GetAcquisition(account, id) {
    return await GetSpineResource('api/accounts/' + account.id + '/acquisitions/' + id);
}

export async function DeleteAcquisition(account, acquisition) {
    return await DeleteSpineResource('api/accounts/' + account.id + '/acquisitions/' + acquisition.id, false);
}

//---------- OPTIONAL -------------
/*
export async function PatchAcquisition(acquisition, newValue) {
    return await PatchResource('api/acquisitions/' + acquisition.id + '/patch', {attribute: newValue});
}
 */