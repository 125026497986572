import {getToken} from "../auth/SpineTokenStorageService";
import {trackPromise} from "react-promise-tracker";
import GlobalConstants from "../config/GlobalConstants";
import {ConvertHateosResponse} from "./SpineClient";

export async function GetBillResource(url) {

    var token = getToken();
    let errorOccurred = false;
    let resourceNotFound = false;

    return trackPromise(fetch(GlobalConstants.BILL_CLIENT_HOST + url, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + token.access_token,
        }
    })
        .then(function(response) {
            if(!response.ok) {
                if(response.status === 404) {
                    resourceNotFound = true;
                    return response;
                } else {
                    errorOccurred = true;
                }
            }
            return response.json();
        })
        .then(function (response) {
            if(errorOccurred) {
                return generateErrorMessage(response);
            } else if(resourceNotFound) {
                return {error: false, message: "Resource not found", result: null}
            } else {
                return {error: false, message: "", result: response}
            }
        })
        .catch(function (error) {
            return {error: true, message: "An unknown error occurred: " + error, result: {}}
        }));
}

export async function GetAllBillResources(url){
    //Security check
    var token = getToken();
    let errorOccurred = false;
    let length = 0;

    return trackPromise(fetch(GlobalConstants.BILL_CLIENT_HOST + url, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + token.access_token,
        }
    })
        .then(function(response) {
            if(!response.ok) {
                errorOccurred = true;
            } else {
                length = response.headers.get('Content-Length');
            }
            return response.json();
        })
        .then(function (response) {
            response = ConvertHateosResponse(response);
            if(errorOccurred) {
                return generateErrorMessage(response);
            } else {
                return {error: false, message: "", result: response, length: length}
            }
        })
        .catch(function (error) {
            return {error: true, message: "An unknown error occurred: " + error, result: [], length: 0}
        }));
}

export async function GetFilteredBillResources(url, filter){
    //Security check
    var token = getToken();
    let errorOccurred = false;

    return trackPromise(fetch(GlobalConstants.BILL_CLIENT_HOST + url + createFilterQuery(filter), {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + token.access_token,
        }
    })
        .then(function(response) {
            if(!response.ok) {
                errorOccurred = true;
            }
            return response.json();
        })
        .then(function (response) {
            response = ConvertHateosResponse(response);
            if(errorOccurred) {
                return generateErrorMessage(response);
            } else {
                return {error: false, message: "", result: response}
            }
        })
        .catch(function (error) {
            return {error: true, message: "An unknown error occurred: " + error, result: []}
        }));
}

export async function CreateBillResource(url, resource, dtoIsReturned = true){
    //Security check
    let token = getToken();
    let errorOccurred = false;

    return trackPromise(fetch(GlobalConstants.BILL_CLIENT_HOST + url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token.access_token,
        },
        body: JSON.stringify(resource),
    })
        .then(function (response){
            if(!response.ok) {
                errorOccurred = true;
                return response.json();
            } else {
                //Result ok
                if(dtoIsReturned) {
                    //In case of an ResponseEntity with a dto
                    return response.json();
                } else {
                    //Void
                    return response;
                }
            }
        })
        .then(function (response){
            if(errorOccurred) {
                return generateErrorMessage(response);
            } else {
                return {error: false, message: "", result: response}
            }
        })
        .catch(function (error){
            return {error: true, message: "An unknown error occurred: " + error, result: {}}
        }));
}

export async function UpdateBillResource(url, resource) {
    //Security check
    var token = getToken();

    let errorOccurred = false;

    return trackPromise(fetch(GlobalConstants.BILL_CLIENT_HOST + url, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token.access_token,
        },
        body: JSON.stringify(resource),
    })
        .then(function(response) {
            if(!response.ok) {
                errorOccurred = true;
            }
            return response.json();
        })
        .then(function(response) {
            if(errorOccurred) {
                return generateErrorMessage(response);
            } else {
                return {error: false, message: "", result: response}
            }
        })
        .catch(async function(error) {
            return {error: true, message: "An unknown error occurred: " + error, result: {}}
        }));
}

export async function DeleteBillResource(url, dtoIsReturned = true) {
    //Security check
    var token = getToken();
    let spineError = false;
    let resourceNotFound = false;
    let gatewayError = false;

    return trackPromise(fetch(GlobalConstants.BILL_CLIENT_HOST + url, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token.access_token,
        }
    })
        .then(function(response) {
            if(!response.ok) {
                if (response.status === 404) {
                    resourceNotFound = true;
                    return response;
                } else if (response.status === 502) {
                    gatewayError = true;
                    return response;
                } else {
                    spineError = true;
                    return response.json();
                }
            } else {
                //Result ok
                if(dtoIsReturned) {
                    //In case of an ResponseEntity with a dto
                    return response.json();
                } else {
                    //Void
                    return response;
                }
            }
        })
        .then(function (response) {
            if(spineError) {
                return generateErrorMessage(response);
            } else if(resourceNotFound) {
                return {error: true, message: "Resource not found", result: {...response, customInfo: "Resource not found", path: url}}
            } else if (gatewayError) {
                return {error: true, message: "Gateway Error", result: {customInfo: "Gateway error"}}
            } else {
                return {error: false, message: "", result: response}
            }
        })
        .catch(function(error) {
            return {error: true, message: "An unknown error occurred: " + error, result: error}
        }))
}

//HELPERS

function generateErrorMessage(response) {
    let message;
    if(response.info != null) {
        message = response.info;
    } else {
        message = response.message;
    }
    if(response.errors != null) {
        return {error: true, message: message, result: response.errors}
    } else {
        return {error: true, message: message, result: null}
    }
}

function createFilterQuery(filter) {

    //Return an empty string if the filter is empty
    if(filter == null) {
        return "";
    }

    //Create a query otherwise
    let query = "?";

    Object.keys(filter).forEach((key, i) => {
        if(filter[key] != null && filter[key] !== "") {

            //Check for the data type of the filter param
            if(Array.isArray(filter[key])) {
                //TYPE = Array -> Transform Array ["A","B","C",...] to single String "A,B,C,"
                let entries = "";
                filter[key].forEach(entry => (entries = entries + "," + encodeURIComponent(entry)));
                if (entries.slice(-1) === ",") {
                    entries = entries.slice(0, -1);
                }
                if (filter[key].length > 0) {
                    query = query + key + "=" + entries + "&";
                }
            } else {
                //TYPE = Default
                query = query + key + "=" + encodeURIComponent(filter[key]) + "&";
            }
        }
    });

    //Remove the last character if its a '&' or '?'
    const lastChar = query.slice(-1);
    if(lastChar === '&' || lastChar === '?' ){
        query = query.slice(0,-1);
    }

    return query;
}