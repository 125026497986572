import React, {Component} from "react";
import '../../../App.scss'
import {withToast} from "../../util/ToastService";
import {
    FiSettings,
    GrObjectGroup
} from "react-icons/all";
import {ActionModal} from "../../generators/ModalGenerator";
import {ResourcesTable, TableHeader, TableSettingsDialog} from "../../generators/TableGenerator";
import "react-datepicker/dist/react-datepicker.css";
import OrderFailureLogMetaData from "./OrderFailureLogMetaData";
import {GetFilteredOrderFailureLogs} from "./OrderFailureLogService";
import {SpinningTiger} from "../../global/SpinningTiger";
import {ErrorHandler} from "../../util/ErrorHandler";

class OrderFailureLogs extends Component {

    //------------
    //Constructor
    //------------

    constructor(props) {
        super(props);

        this.state = {
            error: null,
            loading: false,
            orderFailureLogs: [],
            filter: props.location.state != null ? props.location.state.filter :
                {page: 1, orderId: "", title: "", per_page: 20, sortBy: "id", sortDirection: "ASC"},
            lastPage: props.location.state != null ? props.location.state.lastPage : 0,
            columns: props.location.state != null ? props.location.state.columns : OrderFailureLogMetaData.COLUMNS,
            customColumnData: [],
            showDialog: {
                tableSettings: false,
                deletionModal: false,
            },
        };

        this.resetFilter = this.resetFilter.bind(this);
        this.updateFilter = this.updateFilter.bind(this);
        this.showOrHideDialog = this.showOrHideDialog.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.loadOrderFailureLogList = this.loadOrderFailureLogList.bind(this);
    }

    //---------
    //Mounting
    //---------

    async componentDidMount() {
        //Set the title
        document.title = "Order Failure Logs :: Tiger UI";

        await this.loadOrderFailureLogList(this.state.filter);
    }

    //--------
    //Loading
    //--------

    async loadOrderFailureLogList(filter) {

        this.setState(prevState => ({...prevState, loading: true}));

        const loadedOrderFailureLogs = await GetFilteredOrderFailureLogs(filter);

        if (loadedOrderFailureLogs.error === true) {
            this.setState(prevState => ({...prevState, error: loadedOrderFailureLogs}));
        } else {
            loadedOrderFailureLogs.result.forEach(orderFailureLog => {
                if (this.state.orderFailureLogs.filter(ex => (ex.isChecked === true)).includes(orderFailureLog)) {
                    orderFailureLog.isChecked = true;
                }
            });
        }

        this.setState(prevState => ({
            ...prevState,
            loading: false,
            orderFailureLogs: loadedOrderFailureLogs.result,
            lastPage: Math.ceil(loadedOrderFailureLogs.length/this.state.filter.per_page),
            filter: filter
        }));

    }

    async applyFilter(e, filter) {
        e.preventDefault();
        await this.loadOrderFailureLogList(filter);
    }

    //----------
    //Rendering
    //----------

    render() {
        return (
            <div style={{marginBottom: 50}}>
                <div className="content-header">
                    <div className="content-header-title">Order Failure Logs</div>
                    <hr/>
                    <div className="content-header-description"><GrObjectGroup/>&#xA0;List and view logs of failed orders
                    </div>
                </div>

                {/* FILTER */}
                <div className="content-box-centered">
                    <div className="content-box-title">Filter</div>
                    <div className="content-box-body">
                        <form onSubmit={(e) => this.applyFilter(e, this.state.filter)}>
                            <div className="row">
                                <div className="col-md-2">
                                    <label>Order ID</label>
                                    <input type="text" value={this.state.filter.orderId}
                                           onChange={(e) => this.updateFilter('orderId', e.target.value)}/>
                                </div>
                            </div>
                            <hr/>
                            <div className="row">
                                <div className="col-md-3" style={{marginLeft: "auto"}}>
                                    <button className="form-btn-ci-light" type="button"
                                            onClick={() => this.resetFilter()}>Reset
                                    </button>
                                    <button className="form-btn-ci-blue" type="submit" onClick={(e) => this.applyFilter(e, this.state.filter)}>Apply Filter</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                {/* SHOW THE PRODUCT LIST*/}
                <div className="content-box-centered">
                    <div className="content-box-title">OrderFailureLogs List
                        <div style={{float: "right", fontWeight: "normal", cursor: "pointer"}}
                             onClick={() => this.showOrHideDialog('tableSettings', true)}><FiSettings/></div>
                    </div>
                    <div className="content-box-body">
                        <TableHeader nameInState="orderFailureLogs"
                                     viewOnly={true}
                                     deletionForbidden={true}
                                     sortParams={OrderFailureLogMetaData.ORDER_FAILURE_LOG_SORT_PARAMS}
                                     state={this.state}
                                     onSetState={(s) => this.setState(s)}
                                     onLoadPage={() => this.loadOrderFailureLogList(this.state.filter)}/>
                        <hr/>
                        {this.state.loading ?
                            <SpinningTiger />
                        :
                            <ResourcesTable
                                state={this.state}
                                columns={this.state.columns}
                                customcolumndata={this.state.customColumnData}
                                dataset={this.state.orderFailureLogs}
                                page={this.state.filter.page}
                                lastPage={this.state.lastPage}
                                onLoadPage={(page) => this.loadOrderFailureLogList({...this.state.filter, page: page})}
                                selectable={true}
                                resourcesurl='orderFailureLogs'
                                nameInState='orderFailureLogs'
                                onToggleResource={(allOrderFailureLogs) => this.setState(prevState => ({
                                    ...prevState,
                                    orderFailureLogs: allOrderFailureLogs
                                }))}/>
                        }
                    </div>
                </div>

                {/* TABLE SETTINGS */}
                <TableSettingsDialog show={this.state.showDialog.tableSettings}
                                     columns={this.state.columns}
                                     onSetState={(columns) => this.setState(prevState => ({
                                         ...prevState,
                                         columns: columns
                                     }))}
                                     onHide={() =>this.showOrHideDialog('tableSettings', false)}
                />

                {/* DIALOGS */}
                <ActionModal show={this.state.showDialog.deletionModal}
                             onHide={() => this.showOrHideDialog('deletionModal', false)}
                             onAction={() => this.deleteSelectedOrderFailureLogs()}
                             actionButtonText="Delete"
                             title={"Delete orderFailureLogs"}
                             body={"Are you sure you want to delete the selected orderFailureLogs?"}/>
                <ErrorHandler error={this.state.error}
                              onHide={() => this.setState(prevState => ({...prevState, error: null}))} />
            </div>
        );
    }

    //--------
    //ACTIONS
    //--------

    async deleteSelectedOrderFailureLogs() {
        this.state.orderFailureLogs
            .filter(orderFailureLog => orderFailureLog.isChecked === true);

        await this.loadOrderFailureLogList(this.state.filter);

        this.showOrHideDialog('deletionModal', false);
    }

    //----------------
    // STATE HANDLING
    //----------------

    updateFilter(id, value) {
        //Get the state and the filter
        let state = this.state;
        let filter = state.filter;

        //Update the attribute
        filter[id] = value;

        //Set the state
        this.setState(prevState => ({...prevState, filter: filter}));
    }

    showOrHideDialog(dialogId, show) {
        //Get the state and the dialog
        let state = this.state;
        let dialogs = state.showDialog;

        //Update the visibility
        dialogs[dialogId] = show;

        //Set the state
        this.setState(prevState => ({...prevState, showDialog: dialogs}));
    }

    async resetFilter(){
        let filter = {page: 1, orderId: "", title: "", per_page: 20, sortBy: "id", sortDirection: "ASC"};
        this.setState(prevState =>({
            ...prevState,
            filter:filter
        }));
        await this.loadOrderFailureLogList(filter);
    }

}

export default withToast(OrderFailureLogs);