import {
    GetFilteredSpineResources,
    GetSpineResource
} from "../../clients/SpineClient";

//API METHODS
export async function GetFilteredOrderFailureLogs(filter){
    return await GetFilteredSpineResources('api/orderFailureLogs', filter);
}

export async function GetOrderFailureLog(id) {
    return await GetSpineResource('api/orderFailureLogs/' + id);
}
