import React, {useState} from "react";
import {Badge, Button, Col, Form, InputGroup, Pagination, Table} from "react-bootstrap";
import Row from 'react-bootstrap/Row';
import {Label} from "reactstrap";
import {GetFilteredAggregators} from "../aggregators/AggregatorService";
import {InfoModal} from "../../generators/ModalGenerator";
import {SetReportingProductAggregator, UpdateReportingProduct} from "./ReportingProductService";
import {PromiseButton} from "../../global/SpinningTiger";

export function EditReportingProductDialog(props) {
    const [productId] = useState(props.productId);
    let [reportingProduct, setReportingProduct] = useState(props.reportingProduct);
    let [aggregator, setAggregator] = useState(props.aggregator);
    let [aggregators, setAggregators] = useState([]);
    let [aggregatorPage, setAggregatorPage] = useState(0);
    let [lastAggregatorPage, setLastAggregatorPage] = useState(0);
    let [showAggregatorDialog, setShowAggregatorDialog] = useState(false);

    return (
        <>
            <Form>
                <Row className={"details-row"}>
                    <Form.Group as={Col}>
                        <Label>External ID</Label>
                        <Form.Control type="text" value={reportingProduct.externalId}
                                      onChange={(e) => setReportingProduct({...reportingProduct, externalId: e.target.value})}/>
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Label>Created By</Label>
                        <Form.Control type="text" value={reportingProduct.createdBy} readOnly={true}/>
                    </Form.Group>
                </Row>
                <Row className={"details-row"}>
                    <Form.Group as={Col}>
                        <Label>Aggregator</Label>
                        <InputGroup>
                            <Form.Control type="text"
                                          value={aggregator.id !== undefined ? aggregator.id + " - " + aggregator.name : null}
                                          readOnly={true}/>
                                <Button variant="outline-secondary"
                                        onClick={() => loadAggregators(aggregatorPage, setAggregatorPage, setLastAggregatorPage)
                                            .then(r => {
                                                setAggregators(r);
                                                if (r.length > 0) {
                                                    setShowAggregatorDialog(true);
                                                }
                                            })}>Change</Button>
                        </InputGroup>
                    </Form.Group>
                </Row>

            </Form>

            <PromiseButton text="Save" onClick={() => saveReportingProduct(productId, aggregator, reportingProduct).then(result => {
                if(!result.error === true) {
                    props.onSaveSuccess();
                } else {
                    props.onSaveError(result);
                }
            }).catch(error => props.onSaveError(error))} />

            <InfoModal
                show={showAggregatorDialog}
                title={"Pick an aggregator"}
                onCancel={() => setShowAggregatorDialog(false)}
                onHide={() => setShowAggregatorDialog(false)}
                body={<PickAggregatorDialog
                    aggregators={aggregators}
                    setAggregator={(agg) => {
                        setReportingProduct({...reportingProduct, aggregatorId: agg.id});
                        setAggregator(agg);
                    }}
                    setShowAggregatorDialog={(e) => setShowAggregatorDialog(e)}
                    setAggregators={(e) => setAggregators(e)}
                    aggregatorPage={aggregatorPage}
                    lastAggregatorPage={lastAggregatorPage}
                    setAggregatorPage={(p) => setAggregatorPage(p)}
                    setLastAggregatorPage={(p) => setLastAggregatorPage(p)}
                />}
            />
        </>
    );
}

function PickAggregatorDialog(props) {
    return(
        <>
        <Table responsive bordered hover striped>
            <thead>
                <tr>
                    <th>ID</th>
                    <th>Name</th>
                    <th>Person</th>
                    <th>Financial Share</th>
                    <th>Phase</th>
                </tr>
            </thead>
            <tbody>
            {props.aggregators.map(agg => (
                <tr role="row" key={agg.id} style={{cursor: "pointer"}}
                    onClick={() => {
                        props.setAggregator(agg);
                        props.setShowAggregatorDialog(false);
                    }}>
                    <td>{agg.id}</td>
                    <td>{agg.name}</td>
                    <td>{agg.person}</td>
                    <td>{agg.financialShare}</td>
                    <td><Badge bg={agg.phase === "MEASURED" ? "success" : "primary"}>{agg.phase}</Badge></td>
                </tr>
            ))}
            </tbody>
        </Table>
            <Pagination>
                <Pagination.Item hidden={props.aggregatorPage === 0}
                                 onClick={() => loadAggregators(0, props.setAggregatorPage, props.setLastAggregatorPage).then(r => props.setAggregators(r))}>&lt;&lt;</Pagination.Item>
                <Pagination.Item hidden={props.aggregatorPage === 0}
                                 onClick={() => loadAggregators(props.aggregatorPage - 1, props.setAggregatorPage, props.setLastAggregatorPage).then(r => props.setAggregators(r))}>&lt;</Pagination.Item>
                <Pagination.Item hidden={props.aggregatorPage <= 1}
                                 onClick={() => loadAggregators(props.aggregatorPage - 2, props.setAggregatorPage, props.setLastAggregatorPage).then(r => props.setAggregators(r))}>{props.aggregatorPage - 1}</Pagination.Item>
                <Pagination.Item hidden={props.aggregatorPage === 0}
                                 onClick={() => loadAggregators(props.aggregatorPage - 1, props.setAggregatorPage, props.setLastAggregatorPage).then(r => props.setAggregators(r))}>{props.aggregatorPage}</Pagination.Item>
                <Pagination.Item active={true}>{props.aggregatorPage + 1}</Pagination.Item>
                <Pagination.Item
                    hidden={props.aggregatorPage === props.lastAggregatorPage}
                    onClick={() => loadAggregators(props.aggregatorPage + 1, props.setAggregatorPage, props.setLastAggregatorPage).then(r => props.setAggregators(r))}>{props.aggregatorPage + 2}</Pagination.Item>
                <Pagination.Item
                    hidden={props.aggregatorPage > props.lastAggregatorPage - 2}
                    onClick={() => loadAggregators(props.aggregatorPage + 2, props.setAggregatorPage, props.setLastAggregatorPage).then(r => props.setAggregators(r))}>{props.aggregatorPage + 3}</Pagination.Item>
                <Pagination.Item
                    hidden={props.aggregatorPage === props.lastAggregatorPage}
                    onClick={() => loadAggregators(props.aggregatorPage + 1, props.setAggregatorPage, props.setLastAggregatorPage).then(r => props.setAggregators(r))}>&gt;</Pagination.Item>
                <Pagination.Item
                    hidden={props.aggregatorPage === props.lastAggregatorPage}
                    onClick={() => loadAggregators(props.lastAggregatorPage, props.setAggregatorPage, props.setLastAggregatorPage).then(r => props.setAggregators(r))}>&gt;&gt;</Pagination.Item>
            </Pagination>
        </>
    );
}

async function loadAggregators(page, setAggregatorPage, setLastAggregatorPage){
    let response = await GetFilteredAggregators({page: page});
    if(!response.error) {
        setAggregatorPage(page);
        setLastAggregatorPage(Math.ceil(response.length/20) - 1);
    }
    return response.result;
}

async function saveReportingProduct(productId, aggregator, reportingProduct) {
    //First check if the reporting product exists or create it otherwise
    if (!reportingProduct) {
        reportingProduct = {
            id: productId,
            externalId: "unknown"
        }
    } else if (!reportingProduct.id) {
        reportingProduct = {
            id: productId,
            externalId: reportingProduct.externalId
        }
    }
    await UpdateReportingProduct(productId, reportingProduct);
    let response = await SetReportingProductAggregator(productId, aggregator.id);
    return response;
}