import React, {Component} from "react";
import '../../../App.scss'
import {withToast} from "../../util/ToastService";
import {
    FiSettings,
    GrObjectGroup
} from "react-icons/all";
import {DeleteDevice, GetFilteredDevices} from "./DevicesService";
import {ResourcesTable, TableHeader, TableSettingsDialog} from "../../generators/TableGenerator";
import DevicesMetaData from "./DevicesMetaData";
import {ActionModal} from "../../generators/ModalGenerator";
import {SpinningTiger} from "../../global/SpinningTiger";
import {ErrorHandler} from "../../util/ErrorHandler";

class Devices extends Component {

    //CONSTRUCTOR
    constructor(props) {
        super(props);

        //Filter
        this.filter = {page: 0, id: "", name: "", per_page: 20, sortBy: "id", sortDirection: "ASC"}

        //State
        this.state = {
            error: null,
            loading: false,
            devices: [],
            filter: props.location.state != null ? props.location.state.filter : this.filter,
            lastPage: props.location.state != null ? props.location.state.lastPage : 0,
            episodeEntries: [],
            columns: props.location.state != null ? props.location.state.columns : DevicesMetaData.COLUMNS,
            showTableSettings: false,
            showDialog: {
                deletionModal: false,
            },
            showImporter: false,
        };

        //Bind the functions
        this.applyFilter = this.applyFilter.bind(this);
    }

    //Load the screen initially
    async componentDidMount() {
        //Set the title
        document.title = "Devices :: Tiger UI";

        await this.loadPage(this.state.filter.page);
    }

    //Load a certain page
    async loadPage(page) {

        this.setState(prevState => ({...prevState, loading: true}));

        let filter = this.state.filter;
        filter.page = page;
        const response = await GetFilteredDevices(filter);

        if (response.error === true) {
            this.setState(prevState => ({...prevState, error: response}));
        } else {
            response.result.forEach(device => {
                if (this.state.devices.filter(ser => (ser.isChecked === true)).includes(device)) {
                    device.isChecked = true;
                }
            });
        }

        this.setState(prevState => ({
            ...prevState,
            loading: false,
            devices: response.result,
            lastPage: Math.ceil(response.length/this.state.filter.per_page),
            filter: {...prevState.filter, page: page}}));

    }

    render() {

        return (
            <div style={{marginBottom: 50}}>
                <div className="content-header">
                    <div className="content-header-title">Devices</div>
                    <hr/>
                    <div className="content-header-description"><GrObjectGroup/>&#xA0;Manage details of Devices.
                    </div>
                </div>

                {/* FILTER */}
                <div className="content-box-centered">
                    <div className="content-box-title">Filter</div>
                    <div className="content-box-body">
                        <form onSubmit={this.applyFilter}>
                            <div className="row">
                                <div className="col-md-3">
                                    <label>ID</label>
                                    <input type="text" value={this.state.filter.id}
                                           onChange={(event) => this.setState(prevState => ({
                                               ...prevState,
                                               filter: {
                                                   ...prevState.filter,
                                                   id: event.target.value,
                                               },
                                           }))}/>
                                </div>
                                <div className="col-md-5">
                                    <label>Name</label>
                                    <input type="text" value={this.state.filter.name}
                                           onChange={(event) => this.setState(prevState => ({
                                               ...prevState,
                                               filter: {
                                                   ...prevState.filter,
                                                   name: event.target.value,
                                               },
                                           }))}/>
                                </div>
                            </div>
                            <hr/>
                            <div className="row">
                                <div className="col-md-3" style={{marginLeft: "auto"}}>
                                    <button className="form-btn-ci-light" type="button"
                                            onClick={() => this.setState(prevState => ({...prevState, filter: this.filter}))}>Reset
                                    </button>
                                    <button className="form-btn-ci-blue" type="submit">Apply Filter</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                {/* MANAGEMENT */}
                <div className="content-box-centered">
                    <div className="content-box-title">Management</div>
                    <div className="content-box-body">
                        <form onSubmit={this.applyFilter}>
                            <div className="row">
                                <div className="col-md-3">
                                    <button className="form-btn-ci-light-blue" onClick={() => this.setState(prevState => ({...prevState, showImporter: true}))}>Import from .csv File</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                {/* devices LIST*/}
                <div className="content-box-centered">
                    <div className="content-box-title">Devices List
                        <div style={{float: "right", fontWeight: "normal", cursor: "pointer"}}
                             onClick={() => this.setState({showTableSettings: true})}><FiSettings/></div>
                    </div>
                    <div className="content-box-body">
                        <TableHeader nameInState="devices"
                                     viewOnly={false}
                                     deletionForbidden={false}
                                     sortParams={DevicesMetaData.DEVICES_SORT_PARAMS}
                                     state={this.state}
                                     onSetState={(s) => this.setState(s)}
                                     onLoadPage={() => this.loadPage(this.state.filter.page)}/>
                        <hr/>
                        {this.state.loading ?
                            <SpinningTiger />
                        :
                            <>
                                <ResourcesTable
                                        state={this.state}
                                        columns={this.state.columns}
                                        customcolumndata={this.state.customColumnData}
                                        dataset={this.state.devices}

                                        page={this.state.filter.page}
                                        totalPages={this.state.lastPage}
                                        handlePageClick={(event) => this.loadPage(event.selected)}

                                        selectable={true}
                                        resourcesurl='devices'
                                        nameInState='devices'
                                        isSpringDataRestResource={true}
                                        onToggleResource={(allDevices) => this.setState(prevState => ({
                                            ...prevState,
                                            devices: allDevices
                                        }))}/>


                            </>
                        }
                    </div>
                </div>

                {/* TABLE SETTINGS */}
                <TableSettingsDialog show={this.state.showTableSettings}
                                     columns={this.state.columns}
                                     onSetState={(columns) => this.setState(prevState => ({...prevState, columns: columns}))}
                                     onHide={() => this.setState({showTableSettings: false})}
                />

                {/* DIALOGS */}
                <ActionModal show={this.state.showDialog.deletionModal}
                             onHide={() => this.setState((prevState) => ({...prevState, showDialog: {...prevState.showDialog, deletionModal: false}}))}
                             onAction={() => this.deleteSelectedDevice()}
                             actionButtonText="Delete"
                             title={"Delete device"}
                             body={"Are you sure you want to delete the selected device?"}/>

                <ErrorHandler error={this.state.error}
                              onHide={() => this.setState(prevState => ({...prevState, error: null}))} />

            </div>
        );
    }

    toggleDevice(s) {
        let devices = this.state.devices;
        for (let device of devices) {
            if (device.id === s.id) {
                s.isChecked = !s.isChecked;
            }
        }
        this.setState(prevState => ({...prevState, devices: devices}));
    }

    //Filter Handler
    async applyFilter(event) {
        event.preventDefault();

        this.setState(prevState =>({...prevState, devices: []}));
        const response = await GetFilteredDevices(this.state.filter);

        if (response.error === true) {
            this.setState(prevState => ({...prevState, error: response}));
        } else {
            if (response.result.length === 0) {
                this.props.addToast("No device match the filter criteria", {
                    autoDismiss: true,
                    appearance: 'warning'
                });
            }
        }

        this.setState(prevState => ({...prevState, devices: response.result}));
    }

    //API Methods
    async deleteSelectedDevices() {
        this.state.devices
            .filter(device => device.isChecked === true)
            .forEach(device => DeleteDevice(device));

        await this.loadPage(this.state.filter.page);

        this.setState(prevState => ({...prevState, showDialog: {...prevState.showDialog, deletionModal: false}}));
    }
}

export default withToast(Devices);