const EpisodeMetaData = {

    //Columns
    COLUMNS: [
        //Default & Badges
        {label: "ID", id: 'id', columnType: 'default', show: true},
        {label: "Name", id: 'name', columnType: 'default', show: true},
    ],

    //Details
    DETAILS_GENERAL: [
        [{label: 'ID', id: 'id', type: 'text', editable: 'never'},
            {label: 'Series ID', id: 'season.series.id', type: 'text', editable: 'never'},
            {label: "Season ID", id: 'seasonId', type: 'text', editable: 'never'}],
        [{label: 'Displayed Episode Number', id: 'episodeNumber', type: 'text', editable: 'true'},
            {label: 'Episode Title', id: 'episodeTitle', type: 'text', editable: 'true'},
            {label: 'Sort Order in Season', id: 'seriesIndex', type: 'text', editable: 'true'}],
    ],

    //Sorting
    EPISODE_SORT_PARAMS: [{id: 'id', label: 'Example ID'}, {id: 'name', label: 'Name'}],
};

export default EpisodeMetaData;