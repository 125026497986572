import {Dropdown, Table} from "react-bootstrap";
import React, {useState} from "react";
import {createBooleanBadge, transformStringToReadableDate} from "../../../util/ResourceService";
import {GetSubscribersOfNewsletter} from "../NewsletterService";
import {AiOutlinePlus} from "react-icons/all";
import {InfoModal} from "../../../generators/ModalGenerator";
import {AddOrEditSubscriberDialog} from "./AddOrEditSubscriberDialog";
import {TablePagination} from "../../../generators/TableGenerator";

export function NewsletterSubscribersList(props) {

    let [subscribers, setSubscribers] = useState(props.subscribers);
    let [selectedNewsletter, setSelectedNewsletter] = useState(props.newsletter);
    let [newsletters] = useState(props.newsletterList);
    let [filter, setFilter] = useState({page: 1, accountId: "", email: "", active_only: false, confirmed_only: false});
    let [showEditDialog, setShowEditDialog] = useState(false);
    let [selectedSubscriber, setSelectedSubscriber] = useState({});
    let [lastPage, setLastPage] = useState(1);

    return(
        <div>
            <div className="details-title">Subscribers of {selectedNewsletter.name} (Mailjet List ID: {selectedNewsletter.mailjetListId})</div>
            <div className="details-button-box" style={{height: "70px"}}>
                <button className="form-btn-ci-light-blue" onClick={() => props.onHideDialog()}>Back</button>
                {newsletters.length > 1 &&
                    <Dropdown style={{float: "right"}}>
                        <Dropdown.Toggle variant="success" id="newsletter-dropdown">
                            {selectedNewsletter.name}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            {newsletters.map(n => (
                                <Dropdown.Item href="" onClick={(e) => changeNewsletter(e, n, setSelectedNewsletter, setFilter, setSubscribers, setLastPage)}>{n.name}</Dropdown.Item>
                            ))}
                        </Dropdown.Menu>
                    </Dropdown>
                }
            </div>

            <div className="details-box-title">Filter</div>
            <div className="details-box">
                <form onSubmit={(e) => reloadSubscribers(e, selectedNewsletter, filter, setSubscribers, setLastPage)}>
                    <div className="row">
                        <div className="col-md-4">
                            <label>E-Mail</label>
                            <br />
                            <input type="text" value={filter.email}
                                   onChange={(e) => setFilter({...filter, email: e.target.value})}/>
                        </div>
                        <div className="col-md-2">
                            <label>Account ID</label>
                            <br />
                            <input type="text" value={filter.accountId}
                                   onChange={(e) => setFilter({...filter, accountId: e.target.value})}/>
                        </div>
                        <div className="col-md-2">
                            <label>Active Only</label>
                            <br />
                            <input type="checkbox" value={filter.active_only}
                                   checked={filter.active_only}
                                   onChange={() => setFilter({...filter, active_only: !filter.active_only})}/>
                        </div>
                        <div className="col-md-2">
                            <label>Confirmed Only</label>
                            <br />
                            <input type="checkbox" value={filter.confirmed_only}
                                   checked={filter.confirmed_only}
                                   onChange={() => setFilter({...filter, confirmed_only: !filter.confirmed_only})}/>
                        </div>
                    </div>
                    <hr/>
                    <div className="row">
                        <div className="col-md-3" style={{marginLeft: "auto"}}>
                            <button className="form-btn-ci-light" type="button"
                                    onClick={() => setFilter({page: 1, accountId: "", email: "", active_only: false, confirmed_only: false})}>Reset
                            </button>
                            <button className="form-btn-ci-blue" type="submit" onClick={(e) => reloadSubscribers(e, selectedNewsletter, filter, setSubscribers, setLastPage)}>Apply Filter</button>
                        </div>
                    </div>
                </form>
            </div>

            <div className="details-box-title">Subscribers</div>
            <div className="details-box">
                <>
                    <div style={{height: "55px"}}>
                        <button className="form-btn-ci-blue" onClick={() => {
                            setSelectedSubscriber({id: null, newsletterId: selectedNewsletter.id, mailjetListId: selectedNewsletter.mailjetListId, active: false, confirmed: false});
                            setShowEditDialog(true);
                        }}><AiOutlinePlus size={18}/> Add to List</button>
                    </div>

                    {subscribers.length === 0 ?
                        <p style={{textAlign: "center", fontSize: "26px", paddingTop: "20px"}}>The list is empty</p>
                    :
                        <>
                        <Table bordered hover responsive striped>
                            <thead>
                            <tr>
                                <th>ID</th>
                                <th>Account ID</th>
                                <th>E-Mail</th>
                                <th>Active</th>
                                <th>Confirmed</th>
                                <th>First Subscribed At</th>
                                <th>Last Subscribed At</th>
                                <th>Count</th>
                                <th>Unsubscribed At</th>
                            </tr>
                            </thead>
                            <tbody>
                            {subscribers.map(sub => (
                                <tr role="row" key={sub.id} style={{cursor: "pointer"}} onClick={() => {
                                    setSelectedSubscriber(sub);
                                    setShowEditDialog(true);
                                }}>
                                    <td>{sub.id}</td>
                                    <td>{sub.accountId}</td>
                                    <td>{sub.email}</td>
                                    <td>{createBooleanBadge(sub.active)}</td>
                                    <td>{createBooleanBadge(sub.confirmed)}</td>
                                    <td>{transformStringToReadableDate(sub.firstSubscribedAt, true)}</td>
                                    <td>{transformStringToReadableDate(sub.lastSubscribedAt, true)}</td>
                                    <td>{sub.subscriptionCount}</td>
                                    <td>{transformStringToReadableDate(sub.unsubscribedAt, true)}</td>
                                </tr>
                            ))}
                            </tbody>
                        </Table>
                        <TablePagination page={filter.page} lastPage={lastPage}
                        onLoad={async (p) => {
                            setFilter({...filter, page: p});
                            await reloadSubscribers(null, selectedNewsletter, filter, setSubscribers, setLastPage);
                        }}/>
                        </>
                    }
                </>
            </div>

            {/* DIALOGS */}
            <InfoModal show={showEditDialog}
                         onHide={() => setShowEditDialog(false)}
                         title={"Manage subscriber " + selectedSubscriber.email}
                         body={
                             <AddOrEditSubscriberDialog subscriber={selectedSubscriber}
                                                        newsletter={selectedNewsletter}
                                                        onHideDialog={async (e) => {
                                                       setShowEditDialog(false);
                                                       await reloadSubscribers(e, selectedNewsletter, filter, setSubscribers, setLastPage)
                                                   }}/>
                         }/>
        </div>
    )
}

//API METHODS
async function changeNewsletter(event, newsletter, setSelectedNewsletter, setFilter, setSubscribers, setLastPage) {

    event.preventDefault();

    const defaultFilter = {page: 1, accountId: "", email: "", active_only: false, confirmed_only: false};

    setFilter(defaultFilter);

    await reloadSubscribers(event, newsletter, defaultFilter, setSubscribers, setLastPage).then(r => {
        setSelectedNewsletter(newsletter);
    });
}

async function reloadSubscribers(event, newsletter, filter, setSubscribers, setLastPage) {

    if(event != null) {
        event.preventDefault();
    }

    let subscribers = await GetSubscribersOfNewsletter(newsletter, filter);
    if(!subscribers.error) {
        setSubscribers(subscribers.result);
        setLastPage(Math.ceil(subscribers.length / 20))
    }
}