const CategoryMetaData = {

    //Columns
    COLUMNS: [
        //Default & Badges
        {label: "ID", id: 'id', columnType: 'default', show: true},
        {label: "Visibility", id: 'visibility', columnType: 'badge', options: [
                {value: 'VISIBLE', style: 'primary'},
                {value: 'HIDDEN', style: 'warning'}
            ],
            show: true},
        {label: "Name", id: 'name', columnType: 'default', show: true},
        {label: "Code", id: 'code', columnType: 'default', show: true},
        {label: "Parent Category ID", id: 'parentCategoryId', columnType: 'default', show: false},
        {label: "Parent Category Name", id: 'parentCategoryName', columnType: 'default', show: true},
    ],

    //Details
    DETAILS_GENERAL: [
        [{label: 'ID', id: 'id', type: 'text', editable: 'never', isExpertField: false}, {label: 'Name', id: 'name', type: 'text', editable: 'true', required: true}, {label: "Code", id: 'code', type: 'text', editable: 'true', required: true}],
    ],
    DETAILS_APPEARANCE: [
        [{label: 'Visibility', id: 'visibility', type: 'text', editable: 'true'}, {label: 'Parent Category ID', id: 'parentCategoryId', type: 'text', editable: 'true'}, {label: 'Parent Category Name', id: 'parentCategoryName', type: 'text', editable: 'true'} ],
    ],

    //Sorting
    CATEGORY_SORT_PARAMS: [],
};

export default CategoryMetaData;