import {Button, Col, Form, FormControl, InputGroup, Table} from "react-bootstrap";
import Row from 'react-bootstrap/Row';
import React, {Component} from "react";
import {withToast} from "../../util/ToastService";
import {Link} from "react-router-dom";
import {FaBook, FiTrash2,} from "react-icons/all";
import GlobalConstants from "../../config/GlobalConstants";
import {CreateCard, GetCard, GetFilteredMulticardVariants, UpdateCard} from "./CardService";
import {DetailsSection, RequiredFieldsAreValid} from "../../generators/DetailsGenerator";
import CardMetaData from "./CardMetaData";
import {PromiseButton} from "../../global/SpinningTiger";
import {ErrorHandler} from "../../util/ErrorHandler";
import {Label} from "reactstrap";
import {openExternalTargetInNewTab} from "../../util/ResourceService";
import {TableModal} from "../../generators/ModalGenerator";

class CardDetails extends Component {

    //------------
    //Constructor
    //------------

    constructor(props) {
        super(props);

        this.state = {
            error: null,
            flowState: props.location.state,
            id: this.props.match.params.id,
            editMode: {
                active: false,
                createCard: false,
                expertMode: false
            },
            card: {},
            originalCard: {},
            sections: {
                general: true,
                tigercard: true,
                multicard: true,
                wildcard: true,
                tigerticket: true,
            },

            productIsSelected: false,
            editProduct: false,
            selectedProduct: {productId: null, sortNumber: 0},

            selectedMulticardVariant: {},
            editMulticardVariant: false,

            multicardVariantName: null,
            totalPagesMulticardVariants: 1,
            multicardVariantsPage: 0,
            multicardVariants: [],

            showDialog: {
                multicardVariants: false,
            }
        }
    }

    //---------
    //Mounting
    //---------

    async componentDidMount() {
        if (this.state.id != null) {
            document.title = "NFC Card " + this.state.id + " :: Tiger UI";
        } else {
            document.title = "New NFC Card :: Tiger UI";
        }
        await this.loadCard();
    }

    //--------
    //Loading
    //--------

    async loadCard() {
        let loadedCard = {};

        if (this.state.id != null && this.state.id !== "add") {
            loadedCard = await GetCard(this.state.id);

            if (!loadedCard.error) {
                const card = loadedCard.result;

                this.setState({
                    id: this.state.id,
                    editMode: this.state.editMode,
                    card: card,
                    originalCard: card
                });
            } else {
                this.setState(prevState => ({...prevState, error: loadedCard}));
            }
        } else {
            this.setState((prevState) => ({
                ...prevState,
                id: null,
                card: {
                    id: null,
                    _embedded: {
                        multiTigerCardProducts: [],
                        products: []
                    }
                },
                editMode: {
                    active: true,
                    createCard: true,
                    expertMode: false
                }
            }));
        }
    }

    //----------
    //Rendering
    //----------

    render() {
        const labelStyle = {
            fontSize: "20px",
            backgroundColor: "#C8C8C8",
            borderStyle: "solid",
            borderColor: "#cdd7e0",
            borderWidth: "1px",
            borderRadius: "5px",
            paddingLeft: "10px",
            paddingRight: "10px",
            width: "100%"
        };

        return (
                <>
                    <div className="details-title">
                        {this.state.card.uid != null ? "Details of Card " + this.state.card.uid : "Create a new Card"}
                    </div>
                    <div className="details-button-box" style={{height: "70px"}}>
                        <Link to={{pathname: "/" + GlobalConstants.APP_PATH + "cards", state: this.state.flowState}}>
                            <button className="form-btn-ci-light-blue" type="button">Back</button>
                        </Link>
                        {this.state.card.uid != null &&
                                <button className={this.state.editMode.active ? "form-btn-ci-red" : "form-btn-ci-blue"}
                                        type="button" onClick={() => this.setState({
                                    editMode: {
                                        active: !this.state.editMode.active,
                                        expertMode: this.state.editMode.expertMode
                                    },
                                    card: this.state.originalCard
                                })}>{this.state.editMode.active ? "Cancel" : "Edit"}</button>
                        }
                        {this.state.editMode.active &&
                                <PromiseButton text="Save" onClick={() => this.saveOrUpdateCard()}/>
                        }
                        <Button style={{float: "right", marginTop: "5px"}}
                                variant={this.state.editMode.expertMode ? "secondary" : "outline-secondary"}
                                onClick={() => this.setState(prevState => ({
                                    ...prevState,
                                    editMode: {
                                        ...prevState.editMode,
                                        expertMode: !this.state.editMode.expertMode
                                    }
                                }))}>Expert Mode</Button>
                    </div>

                    <div className="details-box-title">Resource Details</div>
                    <div className="details-box">
                        {/* GENERAL DATA */}
                        <DetailsSection
                                nameInState="card"
                                fields={CardMetaData.DETAILS_GENERAL}
                                state={this.state}
                                onSetState={(s) => this.setState(s)}
                                sectionId="general"
                                key={"general" + this.state.id}
                        />

                        {/* SECTION 1 */}
                        {
                                this.state.card.cardType === 'TIGERCARD' &&
                                <DetailsSection
                                        nameInState="card"
                                        fields={CardMetaData.DETAILS_SECTION_TIGERCARD}
                                        state={this.state}
                                        onSetState={(s) => this.setState(s)}
                                        sectionId="tigercard"
                                        label={<span><FaBook/>&#xA0;tigercard</span>}
                                        key={"tigercard" + this.state.id}
                                />
                        }

                        {this.state.card.cardType === 'MULTI_TIGERCARD' &&
                                <Form>
                                    <Row>
                                        <Form.Group className="col-sm-3">
                                            <label>Variant Id</label>
                                            <br/>
                                            <InputGroup className={"justify-content-start"}>
                                                <FormControl type="text" value={this.state.card.multiTigercardVariant ? this.state.card.multiTigercardVariant.id : ""}
                                                       style={{width: "20%"}}
                                                       readOnly={true}/>
                                                <FormControl type="text"
                                                             value={this.state.card.multiTigercardVariant ? this.state.card.multiTigercardVariant.name : ""}
                                                             readOnly={true}/>
                                                {this.state.editMode.active &&
                                                            <Button Button variant="outline-secondary" onClick={() => {
                                                                this.loadMulticardVariants(0, null).then(
                                                                        r => this.setState(
                                                                                {showDialog: {multicardVariants: true}}));
                                                            }}>Pick
                                                            </Button>
                                                }
                                            </InputGroup>
                                        </Form.Group>
                                    </Row>
                                </Form>
                        }
                        {this.state.card.cardType === 'MULTI_TIGERCARD'
                                && this.state.card?._embedded?.products
                                &&
                                <Form>

                                    <hr/>
                                    <h4><FaBook/>&#xA0;Products of Multicard Variant</h4>
                                    <h5>(Editing affects all NFC Cards of this Multicard Variant)</h5>
                                    <hr/>

                                    <button className={"form-btn-ci-blue"}
                                            type="button"
                                            disabled={!this.state.card.multiTigercardVariantId}
                                            onClick={() => {
                                                this.setState(prevState => ({
                                                    ...prevState,
                                                    productIsSelected: !this.state.productIsSelected,
                                                    editProduct: true,
                                                    selectedProduct: {productId: "", sortNumber: 0}
                                                }));
                                            }}>{"Add new Product"}
                                    </button>

                                    {/* SELECTED Product */}
                                    {this.state.productIsSelected &&
                                            <>
                                                <br/>
                                                <br/>
                                                <Form style={{marginTop: "20px"}}>
                                                    <hr/>
                                                    <h4>{"Product " + this.state.selectedProduct.productId}
                                                        <span style={{
                                                            float: "right",
                                                            marginBottom: "10px"
                                                        }}>
                                                        {this.state.editProduct &&
                                                                <button className="form-btn-ci-red"
                                                                        onClick={() => this.setState(prevState => (
                                                                                {
                                                                                    ...prevState,
                                                                                    selectedProduct: this.state.card._embedded.products[0],
                                                                                    editProduct: false
                                                                                }))}>Cancel</button>
                                                        }
                                                            <button type="button"
                                                                    className="form-btn-ci-blue"
                                                                    onClick={() => {
                                                                        if (!this.state.editProduct) {
                                                                            this.setState(prevState => ({
                                                                                ...prevState,
                                                                                editProduct: true
                                                                            }));
                                                                        } else {
                                                                            this.addProductToMulticard(
                                                                                    this.state.card.multiTigercardVariant.id,
                                                                                    this.state.selectedProduct).then(
                                                                                    r => r);
                                                                        }
                                                                    }
                                                                    }>{this.state.editProduct ? "Save" :
                                                                    "Edit"}</button>
                                                                                <button type="button"
                                                                                        className="form-btn-ci-light-blue"
                                                                                        onClick={() => this.setState(
                                                                                                prevState => ({
                                                                                                    ...prevState,
                                                                                                    selectedProduct: {},
                                                                                                    productIsSelected: false
                                                                                                }))}>Close</button>
                                        </span>
                                                    </h4>
                                                    <Row>
                                                        <Form.Group as={Col}>
                                                            <Label style={labelStyle}>Product ID</Label>
                                                            <FormControl type="text"
                                                                         value={this.state.selectedProduct?.productId ?
                                                                                 this.state.selectedProduct.productId :
                                                                                 ""}
                                                                         readOnly={false}/>
                                                        </Form.Group>
                                                        <Form.Group as={Col}>
                                                            <Label style={labelStyle}>Title</Label>
                                                            <FormControl type="text"
                                                                         value={this.state.selectedProduct?._embedded?.productTitle ?
                                                                                 this.state.selectedProduct._embedded.productTitle :
                                                                                 ""}
                                                                         readOnly={true}
                                                            />
                                                        </Form.Group>
                                                        <Form.Group as={Col}>
                                                            <Label style={labelStyle}>Sort Order*</Label>
                                                            <FormControl type="text"
                                                                         value={this.state.selectedProduct.sortNumber ?
                                                                                 this.state.selectedProduct.sortNumber :
                                                                                 ""}
                                                                         readOnly={!this.state.selectedProduct}
                                                                         onChange={(e) => this.setState(prevState => ({
                                                                             ...prevState,
                                                                             selectedProduct: {
                                                                                 ...prevState.selectedProduct,
                                                                                 sortNumber: e.target.value
                                                                             }
                                                                         }))}/>
                                                        </Form.Group>
                                                    </Row>

                                                </Form>
                                            </>
                                    }

                                    <Table style={{marginTop: "10px"}} responsive striped bordered hover>
                                        <thead>
                                        <tr>
                                            <th>Product ID</th>
                                            <th>Title</th>
                                            <th>Cover</th>
                                            <th>Sort Order on Multicard</th>
                                            <th>Delete</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {this.state.card._embedded?.multiTigerCardProducts && this.state.card._embedded.multiTigerCardProducts
                                                .sort((a, b) => (a.sortNumber > b.sortNumber) ? 1 :
                                                        ((b.sortNumber > a.sortNumber) ? -1 : 0))
                                                .map(product => (
                                                        <tr role="row" key={product.productId}
                                                            style={{cursor: "pointer"}}
                                                            onClick={() => this.setState(prevState => ({
                                                                ...prevState,
                                                                selectedProduct: product,
                                                                productIsSelected: true,
                                                                editProduct: false
                                                            }))}>
                                                            <td><Link
                                                                    to={"/" + GlobalConstants.APP_PATH + "products/" + product.productId}
                                                                    style={{
                                                                        fontStyle: "italic",
                                                                        color: "#333",
                                                                        fontWeight: this.state.selectedProduct.id === product.productId ?
                                                                                "bold" : "normal"
                                                                    }}>{product.productId}</Link></td>
                                                            <td style={{
                                                                fontWeight: this.state.selectedProduct.productId === product.productId ?
                                                                        "bold" : "normal"
                                                            }}>{product._embedded.productTitle}</td>
                                                            <td style={{
                                                                fontWeight: this.state.selectedProduct.productId === product.productId ?
                                                                        "bold" : "normal"
                                                            }}>
                                                                {product._links && product._links.cover &&
                                                                        <img src={product._links.cover.href}
                                                                             alt={"cover"}
                                                                             style={{width: "100px", height: "100px"}}
                                                                             onClick={() => openExternalTargetInNewTab(
                                                                                     product._links.cover.href)}/>
                                                                }
                                                            </td>
                                                            <td style={{
                                                                fontWeight: this.state.selectedProduct.productId === product.productId ?
                                                                        "bold" : "normal"
                                                            }}>{product.sortNumber}</td>
                                                            <td>
                                                                <button className="form-btn-ci-red" type="button"
                                                                        onClick={() => this.deleteMulticardProduct(
                                                                                product._links.self.href)}>
                                                                    <FiTrash2/></button>
                                                            </td>
                                                        </tr>
                                                ))}
                                        </tbody>
                                    </Table>

                                </Form>
                        }

                        {
                                this.state.card.cardType === 'WILDCARD' &&
                                <DetailsSection
                                        nameInState="card"
                                        fields={CardMetaData.DETAILS_SECTION_WILDCARD}
                                        state={this.state}
                                        onSetState={(s) => this.setState(s)}
                                        sectionId="wildcard"
                                        label={<span><FaBook/>&#xA0;wildcard</span>}
                                        key={"wildcard-" + this.state.id}
                                />
                        }

                        {
                                this.state.card.cardType === 'TIGERTICKET' &&
                                <DetailsSection
                                        nameInState="card"
                                        fields={CardMetaData.DETAILS_SECTION_TIGERTICKET}
                                        state={this.state}
                                        onSetState={(s) => this.setState(s)}
                                        sectionId="tigerticket"
                                        label={<span><FaBook/>&#xA0;tigerticket</span>}
                                        key={"tigerticket-" + this.state.id}
                                />
                        }

                    </div>
                    <div className="details-button-box" style={{height: "70px"}}>
                        <Link to={{pathname: "/" + GlobalConstants.APP_PATH + "cards", state: this.state.flowState}}>
                            <button className="form-btn-ci-light-blue" type="button">Back</button>
                        </Link>
                        {this.state.editMode.active &&
                                <PromiseButton text="Save" onClick={() => this.saveOrUpdateCard()}/>
                        }
                    </div>

                    <ErrorHandler error={this.state.error}
                                  onHide={() => this.setState(prevState => ({...prevState, error: null}))}/>

                    <TableModal show={this.state.showDialog.multicardVariants}
                                onHide={() => this.setState({showDialog: {multicardVariants: false}})}
                                title="Click on a multicards to select it"
                                tableHeader={[{label: "ID", id: "id"}, {label: "Name", id: "name"}]}
                                tableData={this.state.multicardVariants}
                                keyName={"id"}
                                selectable={false}
                                page={this.state.multicardVariantsPage}
                                onLoadPage={(page) => this.loadMulticardVariants(page, this.state.multicardVariantName)}

                                handlePageClick={(event) => this.loadMulticardVariants(event.selected)}
                                totalPages={this.state.totalPagesMulticardVariants}

                                closeButtonText="Apply"
                                filter={this.multicardVariantFilter()}/>
                </>


        );
    }

    //------------
    //API Methods
    //------------

    async saveOrUpdateCard() {
        let addtionalSection;
        if (this.state.card.cardType === 'TIGERCARD') {
            addtionalSection = CardMetaData.DETAILS_SECTION_TIGERCARD;
        } else if (this.state.card.cardType === 'MULTI_TIGERCARD') {
            addtionalSection = CardMetaData.DETAILS_SECTION_MULTICARD;
        } else if (this.state.card.cardType === 'WILDCARD') {
            addtionalSection = CardMetaData.DETAILS_SECTION_WILDCARD;
        } else if (this.state.card.cardType === 'TIGERTICKET') {
            addtionalSection = CardMetaData.DETAILS_SECTION_TIGERTICKET;
        }
        if (!RequiredFieldsAreValid("card", [CardMetaData.DETAILS_GENERAL, addtionalSection],
                this.state, (s) => this.setState(s))) {
            return;
        }

        let card;
        if (this.state.editMode.createCard) {
            card = await CreateCard(this.state.card);
        } else {
            card = await UpdateCard(this.state.card);
        }

        if (!card.error) {
            if (this.state.card.uid == null) {
                this.props.history.push("/" + GlobalConstants.APP_PATH + "cards/" + card.result.uid);
            }
            this.setState((prevState) => ({
                ...prevState,
                card: card.result,
                originalCard: card.result,
                editMode: {...prevState.editMode, active: false}
            }));

            this.props.addToast("The card has been updated successfully.", {
                autoDismiss: true,
                appearance: 'success'
            });
        } else {
            this.setState(prevState => ({...prevState, error: card}));
        }
    }

    async addProductToMulticard(multiTigercardVariantId, product) {

    }

    async loadMulticardVariant(multiTigercardVariantId) {
        if (this.state.card.multiTigercardVariant?.id && this.state.card.multiTigercardVariant.id === multiTigercardVariantId) {
            let response = await GetFilteredMulticardVariants({id: multiTigercardVariantId});
            if (response.error === true) {
                this.setState(prevState => ({...prevState, error: response}));
            }

            this.setState(prevState => ({
                ...prevState,
                selectedMulticardVariant: response.result
            }));
        }
    }

    async loadMulticardVariants(page, name) {

        let response = {};
        if (name) {
            response = await GetFilteredMulticardVariants({page: page, name: name});
        } else {
            response = await GetFilteredMulticardVariants({page: page});
        }

        if (response.error === true) {
            this.setState(prevState => ({...prevState, error: response}));
        }

        this.setState(prevState => ({
            ...prevState,
            totalPagesMulticardVariants: response.pagination.totalPages,
            multicardVariantsPage: response.pagination.number,
            multicardVariants: response.result
        }));
    }

    multicardVariantFilter() {
        return (
                <>
                    <InputGroup className="mb-3">
                        <FormControl
                                placeholder="Search for an multicard by name"
                                onChange={(e) => this.setState(prevState => ({
                                    ...prevState,
                                    multicardVariantName: e.target.value
                                }))}
                        />
                            <Button variant="outline-secondary"
                                    onClick={() => this.loadMulticardVariants(this.state.multicardVariantsPage,
                                            this.state.multicardVariantName)}>Search</Button>
                    </InputGroup>
                </>);
    }


}


export default withToast(CardDetails);