import {
    CreateSpineResource,
    DeleteSpineResource,
    GetFilteredSpineResources,
    GetSpineResource,
    UpdateSpineResource
} from "../../clients/SpineClient";

//API METHODS
export async function CreateNewsletter(newsletter) {
    return await CreateSpineResource('api/newsletter', newsletter);
}

export async function GetFilteredNewsletters(filter){
    return await GetFilteredSpineResources('api/newsletter', filter);
}

export async function GetNewsletter(id) {
    return await GetSpineResource('api/newsletter/' + id);
}

export async function UpdateNewsletter(newsletter) {
    return await UpdateSpineResource('api/newsletter/' + newsletter.id, newsletter);
}

export async function DeleteNewsletter(newsletter) {
    return await DeleteSpineResource('api/newsletter/' + newsletter.id);
}

export async function GetSubscribersOfNewsletter(newsletter, filter) {
    return await GetFilteredSpineResources('api/newsletter/' + newsletter.id + '/subscribers', filter);
}

export async function SubscribeToNewsletter(request) {
    return await CreateSpineResource('api/newsletter/subscribe', request, false);
}

export async function ConfirmNewsletterSubscription(newsletter, subscriber) {
    return await GetSpineResource('api/newsletter/' + newsletter.id + '/subscribers/' + subscriber.id + '/confirm');
}

export async function UnSubscribeFromNewsletter(request) {
    return await CreateSpineResource('api/newsletter/unsubscribe', request, false);
}

export async function CreateSubscriber(newsletter, subscriber) {
    return await CreateSpineResource('api/newsletter/' + newsletter.id + '/subscribers', subscriber);
}

//---------- OPTIONAL -------------
/*
export async function PatchExample(newsletter, newValue) {
    return await PatchResource('api/newsletters/' + newsletter.id + '/patch', {attribute: newValue});
}
 */