const ProfileMetaData = {

    //Columns
    COLUMNS: [
        //Default & Badges
        {label: "ID", id: 'id', columnType: 'default', show: true},
        {label: "Gender", id: 'gender', columnType: 'badge', options: [
                {value: 'MALE', style: "primary"},
                {value: 'FEMALE', style: "danger"},
                {value: 'UNDEFINED', style: "dark"},
            ], show: true},
        {label: "Nick Name", id: 'nickName', columnType: 'default', show: true},
        {label: "Account ID", id: 'accountId', columnType: 'default', show: true},
        {label: "Birthday", id: 'birthday', columnType: 'default', show: true},
        {label: "Age Range From", id: 'ageRange', subId: 'min', columnType: 'sub', show: true},
        {label: "Age Range To", id: 'ageRange', subId: 'max', columnType: 'sub', show: true},
        {label: "Created By", id: 'createdBy', columnType: 'default', show: false},
        {label: "Created Date", id: 'createdDate', columnType: 'default', show: false},
        {label: "Last Modified By", id: 'lastModifiedBy', columnType: 'default', show: false},
        {label: "Last Modified Date", id: 'lastModifiedDate', columnType: 'default', show: false}
    ],

    //Details
    DETAILS_GENERAL: [
        [{label: 'ID', id: 'id', type: 'text', editable: 'never'}, {label: 'Name', id: 'name', type: 'text', editable: 'true', required: true}, {label: 'Gender', id: 'gender', type: 'select', options: ["UNDEFINED", "FEMALE", "MALE"], editable: 'true'}],
    ],
    CUSTOMER_DETAILS: [
        [{label: 'Birthday', id: 'birthday', type: 'date', editable: 'true'}, {label: 'Avatar', id: 'avatar', type: 'text', editable: 'true'}],
    ],

    //Sorting
    USER_SORT_PARAMS: [],
};

export default ProfileMetaData;