import React, {useState} from "react";
import {Col, Form} from "react-bootstrap";
import Row from 'react-bootstrap/Row';
import GlobalConstants from "../../../config/GlobalConstants";
import {getToken} from "../../../auth/SpineTokenStorageService";
import {AsyncTypeahead} from "react-bootstrap-typeahead";
import {GetFilteredSubscriptions} from "../../../resources/subscriptions/SubscriptionService";
import {PromiseButton} from "../../../global/SpinningTiger";

export function TransferTicketDialog(props) {

    const [ticket] = useState(props.ticket);
    const [account] = useState(props.account);
    const [message, setMessage] = useState(null);
    const [resetDuration, setResetDuration] = useState(false);
    const [targetAccount, setTargetAccount] = useState(null);

    const [isLoading, setIsLoading] = useState(false);
    const [accountList, setAccountList] = useState([]);
    const token = getToken();

    const handleSearch = (email) => {

        setIsLoading(true);

        fetch(GlobalConstants.SPINE_CLIENT_HOST + `api/accounts/search/byEmail?email=${email}`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token.access_token,
            }
        })
            .then(function(resp) {
                return resp.json()
            })
            .then(function (response) {
                let accounts = response["_embedded"]["accounts"];
                const accountList = accounts.map((account) => ({
                    id: account.id,
                    email: account.email
                }));

                setAccountList(accountList);
                setIsLoading(false);
            });
    };

    async function startTransfer() {
        //Validation
        setMessage(null);
        let isValid = await validateRequest();
        if (!isValid) {
            return;
        }

        let url;
        if (ticket.coupon.type === "DIGITICKET") {
            url = "api/purchases/digitickets/" + ticket.id + "/transfer?fromAccountId=" + account.id + "&toAccountId=" + targetAccount.id + "&resetDuration=" + resetDuration;
        } else if (ticket.coupon.type === "TIGERTICKET") {
            url = "api/purchases/tigertickets/" + ticket.id + "/transfer?fromAccountId=" + account.id + "&toAccountId=" + targetAccount.id + "&resetDuration=" + resetDuration;
        } else if (ticket.coupon.type === "COUPON") {
            url = "api/purchases/coupons/" + ticket.id + "/transfer?fromAccountId=" + account.id + "&toAccountId=" + targetAccount.id + "&resetDuration=" + resetDuration;
        }

        const token = getToken();
        await fetch(GlobalConstants.BILL_CLIENT_HOST + url, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token.access_token,
            }
        })
            .then(function(resp) {
                if (resp.ok) {
                    props.onTransferCompleted();
                } else {
                    return resp.json()
                }
            })
            .then(function (tickets) {
                setMessage("Ein Fehler ist aufgetreten:" + tickets.message);
            }).catch(function (error) {
                setMessage("Ein Fehler ist aufgetreten: " + error);
            });
    }

    async function validateRequest () {
        if(targetAccount == null || targetAccount.id == null) {
            setMessage("Bitte gib ein Ziel-Konto an.");
            return false;
        }

        //Check if the target account has a active subscription
        let response = await GetFilteredSubscriptions(targetAccount, {active_only: true});
        if(!response.error) {
            const subs = response.result;
            if(subs.length > 0) {
                const activeSub = subs[0];
                if(activeSub.articleId.indexOf("subscription") > -1) {
                    setMessage("Das Ziel-Konto hat bereits ein aktives Abo.");
                    return false;
                }
            }
        }

        return true;
    }

    const filterBy = () => true;

    return (
        <>
            <p>
                Übertrage das ausgewählte Ticket auf ein anderes Konto. Damit wird der Zugang für {account.email} beendet und für das andere Konto aktiviert.
                <br />
                <br />
                <Form>
                    <Row>
                        <Form.Group as={Col}>
                            <Form.Label>Zugangscode</Form.Label>
                            <Form.Control type="text" value={ticket.code} readOnly={true}/>
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label>Laufzeit</Form.Label>
                            <Form.Control type="text" value={ticket.coupon.premiumAccessDuration} readOnly={true}/>
                        </Form.Group>
                    </Row>
                </Form>
                <h5>Konto</h5>
                <AsyncTypeahead
                    filterBy={filterBy}
                    id={"account-search-typeahead"}
                    isLoading={isLoading}
                    labelKey={"email"}
                    minLength={3}
                    onSearch={handleSearch}
                    options={accountList}
                    placeholder="Gib eine E-Mail-Adresse ein..."
                    renderMenuItemChildren={(account, props) => (
                        <div onClick={() => setTargetAccount(account)}>
                            <span style={{fontWeight: "bold"}}>{account.email}</span> ({account.id})
                        </div>
                    )}
                />
            </p>
            <p>
                <Form>
                    <Form.Check checked={resetDuration} value={resetDuration} onChange={() => setResetDuration(!resetDuration)} label={"Laufzeit neu starten"}/>
                    <Form.Text>               Falls ausgewählt, wird die eventuell bereits verstrichene Laufzeit des Tickets nicht beachtet und bei Übertragung als neues Ticket betrachtet.
                        Andernfalls werden bei einem aktiven Ticket nur die Restlaufzeit übertragen.</Form.Text>
                </Form>
            </p>
            {message != null &&
                <p style={{textAlign: "center", color: "red", fontWeight: "bold"}}>
                    {message}
                </p>
            }
            <hr />
            <p style={{float: "right"}}>
                <PromiseButton className="form-btn-ci-red" style={{marginRight: "10px"}} onClick={props.onHide} text="Abbrechen"/>
                <PromiseButton className="form-btn-ci-light" onClick={() => startTransfer()} text="Übertragen" />
            </p>
        </>
    );
}