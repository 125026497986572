const PublishingHouseMetaData = {

    //Columns
    COLUMNS: [
        //Default & Badges
        {label: "ID", id: 'id', columnType: 'default', show: true},

        {label: "Name", id: 'name', columnType: 'default', show: true},
        {label: "E-Mail", id: 'email', columnType: 'default', show: true},
        {label: "Code", id: 'code', columnType: 'default', show: true},
        {label: "Default Imprint Id", id: 'defaultImprintId', columnType: 'default', show: false},
    ],

    //Details
    DETAILS_GENERAL: [
        [{label: 'ID', id: 'id', type: 'number', editable: 'never', isExpertField: false}, {label: 'Name', id: 'name', type: 'text', editable: 'true', required: true}, {label: 'Default Imprint ID', id: 'defaultImprintId', type: 'number', editable: 'never'}],
    ],
    OWNER_DETAILS: [
        [{label: 'E-Mail', id: 'email', type: 'text'},{label: 'Code', id: 'code', type: 'text', editable: 'true', required: true}],
    ],

    //Sorting
    PUBLISHING_HOUSE_SORT_PARAM: [],
};

export default PublishingHouseMetaData;