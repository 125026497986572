import {Button, ButtonGroup, Col, Form, FormControl, InputGroup, Table} from "react-bootstrap";
import Row from 'react-bootstrap/Row';
import React, {useState} from "react";
import {Label} from "reactstrap";
import {
    addSeasonToSeries,
    CreateEpisode,
    CreateSeries, DeleteEpisode, DeleteSeason,
    GetFilteredEpisodes, GetFilteredSeries,
    GetSeason,
    GetSeries, UpdateEpisode, UpdateSeason, UpdateSeries
} from "../series/SeriesService";
import {BsChevronDoubleRight} from "react-icons/all";
import {ActionModal, InfoModal} from "../../generators/ModalGenerator";
import {PromiseButton} from "../../global/SpinningTiger";
import {ErrorHandler} from "../../util/ErrorHandler";
import {PaginationHelp} from "../../global/PaginationHelp";

export function AddProductToSeriesDialog(props) {

    let [error, setError] = useState(null);

    //Series (if there is any)
    let [selectedSeries, setSelectedSeries] = useState(props.series);
    let [seriesList, setSeriesList] = useState([]);
    let [totalPages, setTotalPages] = useState(0);
    let [season, setSeason] = useState({episodes: []});

    //A list of products
    let [products, setProducts] = useState(props.products);

    //Tabs
    let [showSeriesList, setShowSeriesList] = useState(false);
    let [showSeriesDetails, setShowSeriesDetails] = useState(true);
    let [showSeasons, setShowSeasons] = useState(false);
    let [showEpisodes, setShowEpisodes] = useState(false);
    let [showProducts, setShowProducts] = useState(false);

    //Create a new season
    let [showNewSeason, setShowNewSeason] = useState(false);
    let [newSeason, setNewSeason] = useState({name: null, description: null, section: null, seriesId: null})

    //Modal
    let [showModal, setShowModal] = useState(false);
    let [modalMessage, setModalMessage] = useState("");

    //Season Management
    let [showSeasonDeletionModal, setShowSeasonDeletionModal] = useState(false);
    let [showSeasonUpdateModal, setShowSeasonUpdateModal] = useState(false);
    let [seasonToChange, setSeasonToChange] = useState({});

    //Episode Management
    let [showEpisodeDeletionModal, setShowEpisodeDeletionModal] = useState(false);
    let [showEpisodeUpdateModal, setShowEpisodeUpdateModal] = useState(false);
    let [episodeToChange, setEpisodeToChange] = useState({});

    //Filter
    let [filter, setFilter] = useState({page: 0, name: "", per_page: 20});

    return (
        <div>
            <Button style={{margin: "5px"}} variant={showSeriesList ? "secondary" : "outline-secondary"}
                    onClick={() => {
                        setShowSeriesList(true);
                        setShowSeriesDetails(false);
                        setShowSeasons(false);
                        setShowEpisodes(false);
                        setShowProducts(false);
                        setShowNewSeason(false);
                        GetFilteredSeries({page: 0}).then(r => {
                            setTotalPages(Math.ceil(r.length/filter.per_page));
                            setSeriesList(r.result);
                        });
                    }}>{"Pick a series"}</Button>
            <BsChevronDoubleRight/>
            <Button style={{margin: "5px"}} variant={showSeriesDetails ? "secondary" : "outline-secondary"}
                    onClick={() => {
                        setShowSeriesList(false);
                        setShowSeriesDetails(true);
                        setShowSeasons(false);
                        setShowEpisodes(false);
                        setShowProducts(false);
                        setShowNewSeason(false);
                    }}>{"1. Set Series Details"}</Button>
            <BsChevronDoubleRight/>
            <Button style={{margin: "5px"}} variant={showSeasons ? "secondary" : "outline-secondary"}
                    disabled={selectedSeries.id == null}
                    onClick={() => {
                        setShowSeriesList(false);
                        setShowSeriesDetails(false);
                        setShowSeasons(true);
                        setShowEpisodes(false);
                        setShowProducts(false);
                        getEpisodesOfProducts(products).then(r => setProducts(r));
                    }}>{"2. Select a Season"}</Button>
            <BsChevronDoubleRight/>
            <Button style={{margin: "5px"}} variant={showEpisodes ? "secondary" : "outline-secondary"}
                    onClick={() => {
                        setShowSeriesList(false);
                        setShowSeriesDetails(false);
                        setShowSeasons(false);
                        setShowEpisodes(true);
                        setShowProducts(true);
                        setShowNewSeason(false);
                    }}
                    disabled={season.episodes.length === 0}>{"3. Add new Episodes"}</Button>

            {showSeriesList &&
            <span style={{float: "right", marginTop: "5px"}}>
                    <Button variant="secondary"
                            onClick={() => {
                                setSelectedSeries({seasons: []});
                                setShowSeriesList(false);
                                setShowSeriesDetails(true);
                            }}>New series
                    </Button>
                </span>
            }

            {showSeriesDetails &&
            <span style={{float: "right", marginTop: "5px"}}>
                    <Button variant="secondary"
                            onClick={() => saveOrUpdateSeries(selectedSeries, setSelectedSeries)}>
                        {selectedSeries.id == null ? "Save Series" : "Update Series"}
                    </Button>
                </span>
            }

            {showSeasons &&
                <span style={{float: "right", marginTop: "5px"}}>
                    <Button variant={showNewSeason ? "outline-secondary" : "secondary"}
                        onClick={() => {
                            setNewSeason(prevState => ({...prevState, seriesId: selectedSeries.id}));
                            setShowNewSeason(!showNewSeason);
                        }}>{"Create a new Season"}
                    </Button>
                </span>
            }

            {showSeriesList &&
            <>
                <hr/>
                <h5>Series</h5>
                <hr/>
                <InputGroup className="mb-3">
                    <FormControl
                        placeholder="Search for a series"
                        onChange={(e) => setFilter(prevFilter => ({...prevFilter, name: e.target.value}))}
                    />
                        <Button variant="outline-secondary"
                                onClick={() => {
                                    GetFilteredSeries(filter).then(r => {
                                        setTotalPages(Math.ceil(r.length/filter.per_page));
                                        setSeriesList(r.result);
                                    });
                                }}>Search</Button>
                </InputGroup>
                <Table responsive hover striped bordered>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Name</th>
                            <th>Division Type</th>
                        </tr>
                    </thead>
                    <tbody>
                        {seriesList.map(s => (
                            <tr role="row" key={s.id} style={{cursor: "pointer"}} onClick={() => {
                                GetSeries(s.id).then(r => {
                                    setSelectedSeries(r.result);
                                    setShowSeriesList(false);
                                    setShowSeriesDetails(true);
                                })
                            }}>
                                <td>{s.id}</td>
                                <td>{s.name}</td>
                                <td>{s.divisionType}</td>
                            </tr>
                        ))
                        }
                    </tbody>
                </Table>
                <PaginationHelp
                        page={filter.page}
                        totalPages={totalPages}
                        handlePageClick={() => {
                            setFilter({page: 0, name: filter.name});
                            GetFilteredSeries(filter).then(r => {
                                        setTotalPages(Math.ceil(r.length/filter.per_page));
                                        setSeriesList(r.result);
                                    }
                            );
                        }}
                />
            </>
            }

            {showSeriesDetails &&
            <>
                <hr/>
                <h5>Series Details</h5>
                <hr/>

                <Form>
                    <Row>
                        <Form.Group as={Col}>
                            <Label>ID</Label>
                            <Form.Control value={selectedSeries.id} readOnly={true}/>
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Label>Name</Label>
                            <Form.Control value={selectedSeries.name}
                                          onChange={(event) => setSelectedSeries(prevSeries => ({
                                              ...prevSeries,
                                              name: event.target.value
                                          }))}/>
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Label>Recommended Age</Label>
                            <Form.Control value={selectedSeries.ageRecommended}
                                          onChange={(event) => setSelectedSeries(prevSeries => ({
                                              ...prevSeries,
                                              ageRecommended: event.target.value
                                          }))}/>
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Label>Division Type</Label>
                            <br/>
                            <ButtonGroup>
                                <Button variant={selectedSeries.divisionType === "SEASONS" ? "secondary" : "outline-secondary"}
                                        onClick={() => setSelectedSeries(prevSeries => ({
                                            ...prevSeries,
                                            divisionType: "SEASONS"
                                        }))}>Seasons</Button>
                                <Button variant={selectedSeries.divisionType === "EPISODES" ? "secondary" : "outline-secondary"}
                                        onClick={() => setSelectedSeries(prevSeries => ({
                                            ...prevSeries,
                                            divisionType: "EPISODES"
                                        }))}>Episodes</Button>
                            </ButtonGroup>
                        </Form.Group>
                    </Row>

                    <Row>
                        <Form.Group as={Col}>
                            <Label>Description</Label>
                            <Form.Control as="textarea" rows={5} value={selectedSeries.description}
                                          onChange={(event) => setSelectedSeries(prevSeries => ({
                                              ...prevSeries,
                                              description: event.target.value
                                          }))}/>
                        </Form.Group>
                    </Row>
                </Form>
            </>
            }
            {selectedSeries.seasons.length > 0 && (showSeasons || showNewSeason) &&
            <div>
                <hr/>
                <h5>Seasons</h5>
                <hr/>
            </div>
            }

            {showNewSeason &&
            <Form>
                <Row>
                    <Form.Group as={Col}>
                        <Label>Season Name</Label>
                        <Form.Control value={newSeason.name}
                                      placeholder="*"
                                      onChange={(event) => setNewSeason(prevSeason => ({
                                          ...prevSeason,
                                          name: event.target.value
                                      }))}/>
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Label>Sort Order in Series</Label>
                        <Form.Control value={newSeason.section}
                                      onChange={(event) => setNewSeason(prevSeason => ({
                                          ...prevSeason,
                                          section: event.target.value
                                      }))}/>
                    </Form.Group>
                </Row>
                <Row>
                    <Form.Group as={Col}>
                        <Label>Description</Label>
                        <Form.Control as="textarea" rows={3} value={newSeason.description}
                                      onChange={(event) => setNewSeason(prevSeason => ({
                                          ...prevSeason,
                                          description: event.target.value
                                      }))}/>
                    </Form.Group>
                </Row>
                <div style={{float: "right", marginBottom: "10px"}}>
                    <PromiseButton text="Save" onClick={() => addSeason(selectedSeries.id, newSeason)} />
                </div>
            </Form>
            }

            {selectedSeries.seasons.length > 0 && showSeasons &&

            <Table responsive striped bordered hover>
                <thead>
                <tr>
                    <th>ID</th>
                    <th>Name</th>
                    <th>Sort Order</th>
                    <th>#Episodes</th>
                    <th>Manage</th>
                </tr>
                </thead>
                <tbody>
                {selectedSeries.seasons
                    .sort((a,b) => (a.section > b.section) ? 1 : ((b.section > a.section) ? -1 : 0))
                    .map(season => (
                    <tr role="row" key={season.id} style={{cursor: "pointer"}}
                        onClick={() => {
                            setSeason(season);
                            setShowSeasons(false);
                            setShowEpisodes(true);
                            setShowNewSeason(false);
                            setShowProducts(true);
                        }}>
                        <td>{season.id}</td>
                        <td>{season.name}</td>
                        <td>{season.section}</td>
                        <td>{season.episodes.length}</td>
                        <td>
                            <Button style={{marginRight: "10px"}} variant="primary" onClick={() => {
                                setSeasonToChange(season);
                                setShowSeasonUpdateModal(true);
                            }}>Edit</Button>
                            <Button variant="danger" disabled={season.episodes.length > 0} onClick={() => {
                                setSeasonToChange(season);
                                setShowSeasonDeletionModal(true);
                            }}>Delete</Button>
                        </td>
                    </tr>
                ))}
                </tbody>
            </Table>
            }

            {showEpisodes &&
            <div>
                <hr/>
                <h5>Episodes in Season {season.name}</h5>
                <hr/>

                <Table responsive striped bordered hover>
                    <thead>
                    <tr>
                        <th>ID</th>
                        <th>Number</th>
                        <th>Title</th>
                        <th>Sort Order</th>
                        <th>Product ID</th>
                        <th>Manage</th>
                    </tr>
                    </thead>
                    <tbody>
                    {season.episodes
                        .sort((a, b) => (a.seriesIndex > b.seriesIndex) ? 1 : ((b.seriesIndex > a.seriesIndex) ? -1 : 0))
                        .map(episode => (
                            <tr role="row" key={episode.id} style={{cursor: "pointer"}}>
                                <td>{episode.id}</td>
                                <td>{episode.episodeNumber}</td>
                                <td>{episode.episodeTitle}</td>
                                <td>{episode.seriesIndex}</td>
                                <td>{episode.productId}</td>
                                <td>
                                    <Button style={{marginRight: "10px"}} variant="primary" onClick={() => {
                                        setEpisodeToChange(episode);
                                        setShowEpisodeUpdateModal(true);
                                    }}>Edit</Button>
                                    <Button variant="danger" onClick={() => {
                                        setEpisodeToChange(episode);
                                        setShowEpisodeDeletionModal(true);
                                    }}>Delete</Button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>

            </div>
            }

            {showProducts &&
            <>
                <hr/>
                <h5>Add Product(s) to selected Season</h5>
                <hr/>

                <Table responsive striped bordered hover>
                    <thead>
                    <tr>
                        <th>ID</th>
                        <th>Title</th>
                        <th>Cover</th>
                        <th>Episode Details</th>
                    </tr>
                    </thead>
                    <tbody>
                    {products.map(product => (
                        <tr role="row" key={product.id}>
                            <td>{product.id}</td>
                            <td>{product.title}</td>
                            <td>
                                <img src={product.coverUrl} alt={""} style={{width: "100px", height: "100px"}}/>
                            </td>
                            <td>
                                <Form>
                                    {/* Header input row (with 'Add' Button)*/}
                                    <Row>
                                        <Form.Group as={Col}>
                                            <InputGroup className="mb-6">
                                                <FormControl
                                                    placeholder="Episode Number*"
                                                    onChange={(e) => product.newEpisode.episodeNumber = e.target.value}
                                                />
                                                <FormControl
                                                    placeholder="Episode Title*"
                                                    onChange={(e) => product.newEpisode.episodeTitle = e.target.value}
                                                />
                                                <FormControl
                                                    placeholder="Sort Order in Season"
                                                    onChange={(e) => product.newEpisode.seriesIndex = e.target.value}
                                                />
                                                    <Button variant="outline-secondary"
                                                            onClick={() => addEpisode(season, product, products)}>Add</Button>
                                            </InputGroup>
                                        </Form.Group>
                                    </Row>
                                </Form>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
            </>
            }

            <InfoModal show={showModal} onHide={() => setShowModal(false)}
                       title="Error occurred" body={modalMessage}/>

            <ActionModal show={showEpisodeDeletionModal}
                         onHide={() => setShowEpisodeDeletionModal(false)}
                         onAction={() => deleteEpisode(selectedSeries.id, seasonToChange.id, episodeToChange, setSeason, products, setProducts, setShowEpisodeDeletionModal)}
                         actionButtonText="Delete"
                         title={"Delete episode"}
                         body={"Do you want to delete episode with ID " + episodeToChange.id + " ?"}/>

            <ActionModal show={showEpisodeUpdateModal}
                         onHide={() => setShowEpisodeUpdateModal(false)}
                         onAction={() => updateEpisode(selectedSeries.id, season.id, episodeToChange, setSeason, products, setProducts, setShowEpisodeUpdateModal, setShowModal, setModalMessage)}
                         actionButtonText="Save"
                         title={"Update episode"}
                         body={updateEpisodeModal(episodeToChange, setEpisodeToChange)}/>

            <ActionModal show={showSeasonDeletionModal}
                         onHide={() => setShowSeasonDeletionModal(false)}
                         onAction={() => deleteSeason(selectedSeries.id, seasonToChange, setShowSeasonDeletionModal, setModalMessage, setShowModal, setSelectedSeries)}
                         actionButtonText="Delete"
                         title={"Delete season"}
                         body={"Do you want to delete season with ID " + seasonToChange.id + " ?"}/>

            <ActionModal show={showSeasonUpdateModal}
                         onHide={() => setShowSeasonUpdateModal(false)}
                         onAction={() => updateSeason(selectedSeries.id, seasonToChange, setShowSeasonUpdateModal, setShowModal, setModalMessage, setSelectedSeries)}
                         actionButtonText="Save"
                         title={"Update season"}
                         body={updateSeasonModal(seasonToChange, setSeasonToChange)}/>

            <ErrorHandler error={error}
                          onHide={() =>setError(null)} />
        </div>
    );

    async function addSeason(season) {
        addSeasonToSeries(selectedSeries.id, season).then(async function (result) {
            if(result.error) {
                setError(result);
            } else {
                GetSeries(season.seriesId).then(r => {
                    if (r.error) {
                        setError(r);
                    } else {
                        setSelectedSeries(r.result);
                    }
                }).catch(error => setError(error));
            }
        }).catch(error => setError(error));
    }

    async function addEpisode(season, productToLink, products) {
        productToLink.newEpisode.seasonId = season.id;
        productToLink.newEpisode.productId = productToLink.id;

        CreateEpisode(selectedSeries.id, season.id, productToLink.newEpisode).then(function(result) {
            if(!result.error) {
                GetSeason(selectedSeries.id, season.id).then(function (result) {
                    if (!result.error) {
                        setSeason(result.result);
                        getEpisodesOfProducts(products).then(r => setProducts(r));
                    } else {
                        setError(result);
                    }
                }).catch(error => setError(error));
            } else {
                setError(result);
            }
        }).catch(error => setError(error));
    }

    async function getEpisodesOfProducts(products) {
        //Create the request
        let ids = "";
        products.forEach(product => (
            ids = ids + product.id + ","
        ));

        //Get the episodes
        let episodes = await GetFilteredEpisodes({pageSize: 100, productId: ids});

        //Now map the episodes to the products
        products.forEach(product => (product.linkedEpisodes = episodes.result.filter(episode => episode.productId === product.id)));

        //Reset the list of new episodes per product
        products.forEach(product => (product.newEpisode = {}));
        return products;
    }

    async function saveOrUpdateSeries(series, setSeries) {
        let result;
        if (series.id == null) {
            result = await CreateSeries(series);
        } else {
            result = await UpdateSeries(series);
        }
        if (!result.error) {
            setSeries(result.result);
        } else {
            setError(error);
        }
    }

    async function deleteEpisode(seriesId, seasonId, episode, setSeason, products, setProducts, setShowDeletionModal) {
        DeleteEpisode(seriesId, seasonId, episode).then(function (deletionResult) {
            setShowDeletionModal(false);
            if (!deletionResult.error) {
                GetSeason(seriesId, seasonId).then(function (result) {
                    if (!result.error) {
                        setSeason(result.result);
                        getEpisodesOfProducts(products).then(r => setProducts(r));
                    } else {
                        setError(result);
                    }

                }).catch(error => setError(error));
            } else {
                setError(deletionResult);
            }

        }).catch(error => setError(error));
    }

    async function updateEpisode(seriesId, seasonId, episode, setSeason, products, setProducts, setShowEpisodeUpdateModal, setShowModal, setModalMessage) {
        UpdateEpisode(episode).then(function (updateResult) {
            if(!updateResult.error) {
                setShowEpisodeUpdateModal(false);
                GetSeason(seriesId, seasonId).then(function (result) {
                    if (!result.error) {
                        setSeason(result.result);
                        getEpisodesOfProducts(products).then(r => setProducts(r));
                    } else {
                        setError(result);
                    }
                }).catch(error => setError(error));
            } else {
                setError(updateResult);
            }
        }).catch(error => setError(error));
    }

    async function deleteSeason(seriesId, season, setShowDeletionModal, setModalMessage, setShowModal, setSeries) {
        if(season.episodes.length === 0) {
            DeleteSeason(seriesId, season).then(function (deletionResult) {
                if (!deletionResult.error) {
                    setShowDeletionModal(false);
                    GetSeries(seriesId).then(r => {
                        if(!r.error) {
                            setSeries(r.result);
                        } else {
                            setError(r);
                        }
                    }).catch(error => setError(error));
                } else {
                    setError(deletionResult);
                }
            }).catch(error => setError(error));
        } else {
            setModalMessage("This season can not be deleted since it still has " + season.episodes.length + " episodes.");
            setShowModal(true);
        }

    }

    async function updateSeason(seriesId, season, setShowSeasonUpdateModal, setShowModal, setModalMessage, setSeries) {
        UpdateSeason(seriesId, season).then(function (updateResult) {
            if(!updateResult.error) {
                setShowSeasonUpdateModal(false);
                GetSeries(seriesId).then(r => {
                    if(!r.error) {
                        setSeries(r.result);
                    } else {
                        setError(r);
                    }
                }).catch(error => setError(error));
            } else {
                setError(updateResult);
            }
        }).catch(error => setError(error));
    }
}

//MODALS
function updateEpisodeModal(episode, setEpisodeToChange) {
    return(
        <>
            <Form>
                <Row>
                    <Form.Group as={Col} controlId="episodeId">
                        <Label>ID</Label>
                        <Form.Control value={episode.id} readOnly />
                    </Form.Group>
                </Row>
                <Row>
                    <Form.Group as={Col} controlId="episodeNumber">
                        <Label>Episode Number</Label>
                        <Form.Control value={episode.episodeNumber}
                                      onChange={(event => {
                                          setEpisodeToChange((prevEpisode) => ({
                                              ...prevEpisode,
                                             episodeNumber: event.target.value
                                          }))
                                      })} />
                    </Form.Group>
                    <Form.Group as={Col} controlId="episodeTitle">
                        <Label>Episode Title</Label>
                        <Form.Control value={episode.episodeTitle}
                                      onChange={(event => {
                                          setEpisodeToChange((prevEpisode) => ({
                                              ...prevEpisode,
                                              episodeTitle: event.target.value
                                          }))
                                      })} />
                    </Form.Group>
                </Row>
                <Row>
                    <Form.Group as={Col} controlId="section">
                        <Label>Sort Order in Season</Label>
                        <Form.Control value={episode.seriesIndex}
                                      onChange={(event => {
                                          setEpisodeToChange((prevEpisode) => ({
                                              ...prevEpisode,
                                              seriesIndex: event.target.value
                                          }))
                                      })} />
                    </Form.Group>
                    <Form.Group as={Col} controlId="productId">
                        <Label>Product ID</Label>
                        <Form.Control value={episode.productId}
                                      onChange={(event => {
                                          setEpisodeToChange((prevEpisode) => ({
                                              ...prevEpisode,
                                              productId: event.target.value
                                          }))
                                      })} />
                    </Form.Group>
                </Row>
            </Form>
        </>
    );
}

function updateSeasonModal(season, setSeasonToChange) {
    return(
        <>
            <Form>
                <Row>
                    <Form.Group as={Col} controlId="seasonId">
                        <Label>ID</Label>
                        <Form.Control value={season.id} readOnly />
                    </Form.Group>
                </Row>
                <Row>
                    <Form.Group as={Col} controlId="seasonName">
                        <Label>Name</Label>
                        <Form.Control value={season.name}
                                      onChange={(event => {
                                          setSeasonToChange((prevSeason) => ({
                                              ...prevSeason,
                                              name: event.target.value
                                          }))
                                      })} />
                    </Form.Group>
                    <Form.Group as={Col} controlId="section">
                        <Label>Sort Order in Series</Label>
                        <Form.Control value={season.section}
                                      onChange={(event => {
                                          setSeasonToChange((prevSeason) => ({
                                              ...prevSeason,
                                              section: event.target.value
                                          }))
                                      })} />
                    </Form.Group>
                </Row>
            </Form>
        </>
    );
}
