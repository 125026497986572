import React, {Component} from "react";
import '../../../App.scss'
import {withToast} from "../../util/ToastService";
import {
    FiSettings,
    GrObjectGroup
} from "react-icons/all";
import {ActionModal} from "../../generators/ModalGenerator";
import {ResourcesTable, TableHeader, TableSettingsDialog} from "../../generators/TableGenerator";
import "react-datepicker/dist/react-datepicker.css";
import TagMetaData from "./TagMetaData";
import {DeleteTag, GetFilteredTags} from "./TagService";
import {SpinningTiger} from "../../global/SpinningTiger";
import {ErrorHandler} from "../../util/ErrorHandler";

class Tags extends Component {

    //------------
    //Constructor
    //------------

    constructor(props) {
        super(props);

        this.state = {
            error: null,
            loading: false,
            tags: [],
            filter: props.location.state != null ? props.location.state.filter :
                {page: 0, query: "", name: "", per_page: 20, sortBy: "id", sortDirection: "ASC"},
            totalPages: props.location.state != null ? props.location.state.totalPages : 0,
            columns: props.location.state != null ? props.location.state.columns : TagMetaData.COLUMNS,
            customColumnData: [],
            showDialog: {
                tableSettings: false,
                deletionModal: false,
            },
        };

        this.resetFilter = this.resetFilter.bind(this);
        this.updateFilter = this.updateFilter.bind(this);
        this.showOrHideDialog = this.showOrHideDialog.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.loadPage = this.loadPage.bind(this);
    }

    //---------
    //Mounting
    //---------

    async componentDidMount() {
        //Set the title
        document.title = "Tags :: Tiger UI";

        await this.loadPage(0);
    }

    //--------
    //Loading
    //--------

    async loadPage(page) {

        this.setState(prevState => ({...prevState, loading: true}));

        let filter = this.state.filter;
        filter.page = page + 1;
        const loadedTags = await GetFilteredTags(filter);

        if (loadedTags.error === true) {
            this.setState(prevState => ({...prevState, error: loadedTags}));
        } else {
            loadedTags.result.forEach(tag => {
                if (this.state.tags.filter(ser => (ser.isChecked === true)).includes(tag)) {
                    tag.isChecked = true;
                }
            });
        }

        this.setState(prevState => ({
            ...prevState,
            loading: false,
            tags: loadedTags.result,
            totalPages: Math.ceil(loadedTags.length/this.state.filter.per_page),
            filter: {...prevState.filter, page: page}}));

    }

    async applyFilter(e) {
        e.preventDefault();
        await this.loadPage(0);
    }

    //----------
    //Rendering
    //----------

    render() {
        return (
            <div style={{marginBottom: 50}}>
                <div className="content-header">
                    <div className="content-header-title">Tags</div>
                    <hr/>
                    <div className="content-header-description"><GrObjectGroup/>&#xA0;List of tags that can be linked to a product
                    </div>
                </div>

                {/* FILTER */}
                <div className="content-box-centered">
                    <div className="content-box-title">Filter</div>
                    <div className="content-box-body">
                        <form onSubmit={(e) => this.applyFilter(e)}>
                            <div className="row">
                                <div className="col-md-4">
                                    <label>Query</label>
                                    <input type="text" value={this.state.filter.query}
                                           onChange={(e) => this.updateFilter('query', e.target.value)}/>
                                </div>
                                <div className="col-md-4">
                                    <label>Name</label>
                                    <input type="text" value={this.state.filter.name}
                                           onChange={(e) => this.updateFilter('name', e.target.value)}/>
                                </div>
                            </div>
                            <hr/>
                            <div className="row">
                                <div className="col-md-3" style={{marginLeft: "auto"}}>
                                    <button className="form-btn-ci-light" type="button"
                                            onClick={() => this.resetFilter()}>
                                        Reset
                                    </button>
                                    <button className="form-btn-ci-blue" type="submit" onClick={(e) => this.applyFilter(e)}>Apply Filter</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                {/* SHOW THE PRODUCT LIST*/}
                <div className="content-box-centered">
                    <div className="content-box-title">Tags List
                        <div style={{float: "right", fontWeight: "normal", cursor: "pointer"}}
                             onClick={() => this.showOrHideDialog('tableSettings', true)}><FiSettings/></div>
                    </div>
                    <div className="content-box-body">
                        <TableHeader nameInState="tags"
                                     viewOnly={false}
                                     deletionForbidden={false}
                                     sortParams={TagMetaData.TAG_SORT_PARAMS}
                                     state={this.state}
                                     onSetState={(s) => this.setState(s)}
                                     onLoadPage={() => this.loadPage(this.state.filter.page)}/>
                        <hr/>
                        {this.state.loading ?
                            <SpinningTiger />
                        :
                            <ResourcesTable
                            state={this.state}
                            columns={this.state.columns}
                            customcolumndata={this.state.customColumnData}
                            dataset={this.state.tags}
                            page={this.state.filter.page}
                            totalPages={this.state.totalPages}
                            handlePageClick={(event) => this.loadPage(event.selected)}
                            selectable={"true"}
                            resourcesurl='tags'
                            nameInState='tags'
                            onToggleResource={(allTags) => this.setState(prevState => ({
                                ...prevState,
                                tags: allTags
                            }))}/>
                        }
                    </div>
                </div>

                {/* TABLE SETTINGS */}
                <TableSettingsDialog show={this.state.showDialog.tableSettings}
                                     columns={this.state.columns}
                                     onSetState={(columns) => this.setState(prevState => ({
                                         ...prevState,
                                         columns: columns
                                     }))}
                                     onHide={() =>this.showOrHideDialog('tableSettings', false)}
                />

                {/* DIALOGS */}
                <ActionModal show={this.state.showDialog.deletionModal}
                             onHide={() => this.showOrHideDialog('deletionModal', false)}
                             onAction={() => this.deleteSelectedTags()}
                             actionButtonText="Delete"
                             title={"Delete tags"}
                             body={"Are you sure you want to delete the selected tags?"}/>
                <ErrorHandler error={this.state.error}
                              onHide={() => this.setState(prevState => ({...prevState, error: null}))} />
            </div>
        );
    }

    //--------
    //ACTIONS
    //--------

    async deleteSelectedTags() {
        this.state.tags
            .filter(tag => tag.isChecked === true)
            .forEach(tag => DeleteTag(tag));

        await this.loadPage(this.state.filter.page);

        this.showOrHideDialog('deletionModal', false);
    }

    //----------------
    // STATE HANDLING
    //----------------

    updateFilter(id, value) {
        //Get the state and the filter
        let state = this.state;
        let filter = state.filter;

        //Update the attribute
        filter[id] = value;

        //Set the state
        this.setState(prevState => ({...prevState, filter: filter}));
    }

    showOrHideDialog(dialogId, show) {
        //Get the state and the dialog
        let state = this.state;
        let dialogs = state.showDialog;

        //Update the visibility
        dialogs[dialogId] = show;

        //Set the state
        this.setState(prevState => ({...prevState, showDialog: dialogs}));
    }

    async resetFilter(){
        let filter = {page: 0, query: "", name: "", per_page: 20, sortBy: "id", sortDirection: "ASC"};
        this.setState(prevState =>({
            ...prevState,
            filter:filter
        }));
        await this.loadTagList(0);
    }

}

export default withToast(Tags);