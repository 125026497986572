import {Modal, Pagination, Table} from "react-bootstrap";
import React from "react";
import {PromiseButton} from "../global/SpinningTiger";
import {PaginationHelp} from "../global/PaginationHelp";

export function InfoModal(props) {
    return (
        <Modal
            {...props}
            size={props.size != null ? props.size : "xl"}
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {props.title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    {props.body}
                </div>
            </Modal.Body>
            {props.onHide != null && props.hideFooter == null &&
                <Modal.Footer>
                    <button className="btn-ci" onClick={props.onHide}>Close</button>
                </Modal.Footer>
            }
        </Modal>
    );
}

export function ActionModal(props) {
    let actionButtonText;
    if(props.actionButtonText === undefined) {
        actionButtonText = "Save";
    } else {
        actionButtonText = props.actionButtonText;
    }
    return (
        <Modal
            {...props}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {props.title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    {props.body}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button className="btn-ci" onClick={props.onHide}>Cancel</button>
                <PromiseButton className="btn-ci" text={actionButtonText} onClick={props.onAction} />
            </Modal.Footer>
        </Modal>
    );
}

export function TableModal(props) {
    let actionButtonText;
    let i = 0;
    if(props.actionButtonText === undefined) {
        actionButtonText = "Save";
    } else {
        actionButtonText = props.actionButtonText;
    }
    return (
        <Modal
            {...props}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {props.title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {props.filter != null &&
                    <div>
                        {props.filter}
                    </div>
                }
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            {props.tableHeader.map(header => (
                                <td key={i++}>{header.label}</td>
                            ))
                            }
                            {props.selectable &&
                                <td>Select</td>
                            }
                        </tr>
                    </thead>
                    <tbody>
                    {props.tableData.map(data => (
                        <tr role="row" key={props.keyName ? data[props.keyName] : data[0]} style={{cursor: "pointer"}}
                        onClick={() => props.onToggle(data)}>
                            {props.tableHeader.map(header => (
                                <td key={i++}>{data[header.id]}</td>
                            ))}
                            {props.selectable &&
                                <td><input type="checkbox" checked={data.isChecked} onChange={() => props.onToggle(data)}/></td>
                            }
                        </tr>
                    ))}
                    </tbody>
                </Table>

                {props.page != null && !props.handlePageClick &&
                    <Pagination>
                        <Pagination.Item hidden={props.page === 1}
                                         onClick={() => props.onLoadPage(1)}>&lt;&lt;</Pagination.Item>
                        <Pagination.Item hidden={props.page === 1}
                                         onClick={() => props.onLoadPage(props.page - 1)}>&lt;</Pagination.Item>
                        <Pagination.Item hidden={props.page <= 2}
                                         onClick={() => props.onLoadPage(props.page - 2)}>{props.page - 2}</Pagination.Item>
                        <Pagination.Item hidden={props.page === 1}
                                         onClick={() => props.onLoadPage(props.page - 1)}>{props.page - 1}</Pagination.Item>
                        <Pagination.Item active={true}>{props.page}</Pagination.Item>
                        <Pagination.Item
                            onClick={() => props.onLoadPage(props.page + 1)}>{props.page + 1}</Pagination.Item>
                        <Pagination.Item
                            onClick={() => props.onLoadPage(props.page + 2)}>{props.page + 2}</Pagination.Item>
                        <Pagination.Item
                            onClick={() => props.onLoadPage(props.page + 1)}>&gt;</Pagination.Item>
                    </Pagination>
                }
                {props.page != null && props.handlePageClick != null &&
                            <div className={"advanced-pagination"}>
                                <PaginationHelp
                                        page={props.page}
                                        totalPages={props.totalPages}
                                        handlePageClick={props.handlePageClick}
                                />
                            </div>

                }
            </Modal.Body>
            <Modal.Footer>
                <button className="btn-ci" onClick={props.onHide}>{props.closeButtonText != null ? props.closeButtonText : "Cancel"}</button>
                {props.onAction != null &&
                    <button className="btn-ci" onClick={props.onAction}>{actionButtonText}</button>
                }
            </Modal.Footer>
        </Modal>
    );
}