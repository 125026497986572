import React, {useState} from "react";
import {Button, Col, Form, InputGroup, Table} from "react-bootstrap";
import Row from 'react-bootstrap/Row';
import {Link} from "react-router-dom";
import {Badge} from "react-bootstrap";
import GlobalConstants from "../../config/GlobalConstants";
import {PaginationHelp} from "../../global/PaginationHelp";
import {CollectionStateBadges, CollectionTypeBadges} from "./CollectionMetaData";
import {GetFilteredCollections} from "./CollectionService";

export function PickCollectionDialog(props) {
    let [collections, setCollections] = useState({
        data: [],
        filter: {
            page: 0,
            name: "",
            query: "",
            per_page: 20,
        },
        totalPages: 0,
    });

    const loadCollections = async (page) => {
        let response = await GetFilteredCollections({page: page, title: collections.filter.name});
        if(!response.error) {
            setCollections({
                ...collections,
                data: response.result,
                filter: {
                    ...collections.filter,
                    page: page,
                },
                totalPages: Math.ceil(response.length / 20),
            });
        } else {
            setCollections({
                ...collections,
                error: response});
        }
    }

    return (
            <>
                <Form>
                    <Row>
                        <Form.Group as={Col}>
                            <InputGroup>
                                <Form.Control placeholder="Search for a collection by its title"
                                             value={collections.filter.name}
                                             onChange={(e) => setCollections({
                                                 ...collections,
                                                 filter: {
                                                     ...collections.filter,
                                                     name: e.target.value,
                                                 }
                                             })}></Form.Control>
                                <Button variant="outline-secondary" onClick={() => loadCollections(0)}>Search</Button>
                            </InputGroup>
                        </Form.Group>
                    </Row>
                </Form>
                <Table responsive bordered hover striped>
                    <thead>
                    <tr>
                        <th>ID</th>
                        <th>Name</th>
                        <th>Age</th>
                        <th>Language</th>
                        <th>State</th>
                        <th>Type</th>
                    </tr>
                    </thead>
                    <tbody>
                    {collections.data.map(collection => (
                            <tr role="row" key={collection.id} style={{cursor: "pointer"}}
                                onClick={() => props.onClickCollection(collection)}>
                                <td><Link to={"/" + GlobalConstants.APP_PATH + "collections/" + collection.id}
                                          style={{
                                              fontStyle: "italic",
                                              color: "#333"
                                          }}>{collection.id}</Link></td>
                                <td>{collection.name}</td>
                                <td>{collection.ageRecommended}</td>
                                <td>{collection.language}</td>
                                <td><Badge bg={CollectionStateBadges(collection)}>{collection.state}</Badge></td>
                                <td><Badge bg={CollectionTypeBadges(collection)}>{collection.collectionType}</Badge>
                                </td>
                            </tr>
                    ))}
                    </tbody>
                </Table>

                <div className="advanced-pagination">
                    <PaginationHelp
                            initialPage={0}
                            totalPages={collections.totalPages}
                            handlePageClick={(event) => loadCollections(event.selected)}
                    />
                </div>

            </>
    );
}
