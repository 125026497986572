import {
    CreateSpineResource,
    DeleteSpineResource,
    GetFilteredSpineResources,
    GetSpineResource,
    UpdateSpineResource
} from "../../clients/SpineClient";

//API METHODS
export async function CreateApp(app) {
    return await CreateSpineResource('api/apps', app);
}

export async function GetFilteredApps(filter){
    return await GetFilteredSpineResources('api/apps', filter);
}

export async function GetApp(id) {
    return await GetSpineResource('api/apps/' + id);
}

export async function UpdateApp(app) {
    return await UpdateSpineResource('api/apps/' + app.id, app);
}

export async function DeleteApp(app) {
    return await DeleteSpineResource('api/apps/' + app.id);
}

//---------- OPTIONAL -------------
/*
export async function PatchExample(example, newValue) {
    return await PatchResource('api/examples/' + example.id + '/patch', {attribute: newValue});
}
 */