import React, {useState} from "react";
import {Button, FormControl, InputGroup, Table} from "react-bootstrap";
import {GetFilteredTags} from "./TagService";
import {PaginationHelp} from "../../global/PaginationHelp";

export function PickTagDialog(props) {
    let [tags, setTags] = useState({
        data: [],
        filter: {
            page: 0,
            query: "",
            per_page: 20,
        },
        totalPages: 0,
    });

    const loadTagsPage = async (page) => {

        const response = await GetFilteredTags({
            page: page + 1,
            query: tags.filter.query,
            per_page: tags.filter.per_page,
        });

        if (response.error === true) {
            setTags({...tags, error: response});
        }
        setTags({
            ...tags,
            data: response.result,
            totalPages: Math.ceil(response.length / tags.filter.per_page),
            filter: {
                ...tags.filter,
                page: page
            }
        });
    }

    return (
    <div>
                <InputGroup className="mb-3">
                    <FormControl
                            placeholder="Search for a tag"
                            onChange={(e) => setTags({
                                ...tags,
                                filter: {
                                    query: e.target.value,
                                }
                            })}
                    />
                    <Button variant="outline-secondary"
                            onClick={() => loadTagsPage(0)}>Search</Button>
                </InputGroup>
                <Table striped bordered hover>
                    <thead>
                    <tr>
                        <th>ID</th>
                        <th>Name</th>
                    </tr>
                    </thead>
                    <tbody>
                    {tags.data.map(tag => (
                            <tr role="row" key={tag.id} style={{cursor: "pointer"}} onClick={() => props.onClickTag(tag)}>
                                <td>{tag.id}</td>
                                <td>{tag.name}</td>
                            </tr>
                    ))}
                    </tbody>
                </Table>
                <div className={"advanced-pagination"}>
                        <PaginationHelp
                                initialPage={0}
                                totalPages={tags.totalPages}
                                handlePageClick={(event) => loadTagsPage(event.selected)}
                        />
                </div>
            </div>)

}