import {encode} from "../util/Base64Service";
import { trackPromise } from "react-promise-tracker";
import {getToken} from "./SpineTokenStorageService";
import GlobalConstants from "../config/GlobalConstants";

export async function loginUser(credentials) {

    var data = "username=" + encodeURIComponent(credentials.username) + "&password="
        + encodeURIComponent(credentials.password) + "&grant_type=password&scope=read%20write&device_id=acp";

    if (credentials.externalId !== undefined) {
        data = data + "&externalId=" + encodeURIComponent(credentials.externalId)
    }

    //TODO APP_HOST wieder einkommentieren
    return trackPromise(fetch(GlobalConstants.SPINE_CLIENT_HOST + 'oauth/token',{
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Accept': 'application/json',
            'Authorization': 'Basic ' + encode('tigerbooksacp:e2e12b60-a316-41cd-bf26-0afc1d747a32')
        },
        body: data,
    })
        .then(function (response) {
            if(response.ok) {
                return response.json();
            }
            throw new Error(response.status + " - " + response.statusText);
        })
        .then(function(response) {
            return response;
        })
        .catch(function(error) {
            console.log("Error: " + error.message);
        }))
}

export async function getAccountRoles(token) {
    //TODO APP_HOST wieder einkommentieren
    return trackPromise(fetch(GlobalConstants.SPINE_CLIENT_HOST + 'oauth/check_token?token=' + token.access_token, {
        method: 'POST',
        headers: {
            'Authorization': 'Basic ' + encode('tigerbooksacp:e2e12b60-a316-41cd-bf26-0afc1d747a32')
        }
    })
        .then(function (response) {
            if(response.ok) {
                return response.json();
            }
            throw new Error(response.status + " - " + response.statusText);
        })
        .then(function (response) {
            if (response.user_name != null && !isNaN(response.user_name)) {
                return response.authorities;
            } else {
                return [];
            }
        })
        .catch(function (error) {
            console.log("Error: " + error.message);
        }))
}

export async function getClientToken(credentials) {

    var data = "grant_type=client_credentials";

    return fetch(GlobalConstants.SPINE_CLIENT_HOST + 'oauth/token', {
        method: 'POST',
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            "Accept": "application/json",
            "Authorization": "Basic " + encode(credentials.client_id + ':' + credentials.client_secret)
        },
        body: data,
    })
        .then(function (response) {
            return response.json();
        })
        .then(function(response) {
            var expiredAt = new Date();
            expiredAt.setSeconds(expiredAt.getSeconds() + response.expires_in);
            response.expires_at = expiredAt.getTime();
            return response;
        })
}

export async function fetchBillwerkToken() {

    let grant_type = "?grant_type=client_credentials";

    let headers = new Headers();
    headers.append("Content-Type", "application/x-www-form-urlencoded");

    let data = new URLSearchParams();
    data.append("client_id", GlobalConstants.BILLWERK_CLIENT_ID);
    data.append("client_secret", GlobalConstants.BILLWERK_CLIENT_SECRET);

    return fetch(GlobalConstants.BILLWERK_BASE_URL + GlobalConstants.BILLWERK_OAUTH_URL + grant_type, {
        method: 'POST',
        headers: headers,
        body: data,
    })
        .then(function (response) {
            return response.json();
        })
        .then(function(response) {
            return response;
        })
}

export async function logout({ setToken, setRoles }) {
    fetch(GlobalConstants.SPINE_CLIENT_HOST + 'api/logout', {
        method: 'POST'
    }).then(function() {
        localStorage.clear();
        sessionStorage.clear();
        setToken({});
        setRoles([]);
    })
}

export function hasValidToken() {
    var token = getToken();
    return token && token.expires_at && token.expires_at > new Date().getTime();
}
