import {GetFilteredRestResource, CreateRestResource, GetRestResource, UpdateRestResource, DeleteRestResource} from "../../clients/RestResourceClient";

//API METHODS
export async function CreateCard(card) {
    return await CreateRestResource('api/cards', card);
}

export async function GetFilteredCards(filter){
    if (filter.id) {
        let response = {
            result : [],
            length : 1
        }
        let card = await GetRestResource('api/cards/' + filter.id);
        response.result.push(card.result);
        return response;
    } else if (filter.code) {
        let response = {
            result : [],
            length : 1
        }
        let card = await GetRestResource('api/cards/search/card-by-code?code=' + filter.code);
        response.result.push(card.result);
        return response;
    } else {
        return await GetFilteredRestResource('api/cards', filter);
    }
}

export async function GetCard(id) {
    return await GetRestResource('api/cards/' + id);
}

export async function UpdateCard(card) {
    return await UpdateRestResource(card._links.self.href, card, true);
}

export async function DeleteCard(card) {
    return await DeleteRestResource(card._links.self.href, true);
}

export async function GetFilteredMulticardVariants(filter){
    if (filter.id) {
        let response = {
            result : [],
            length : 1
        }
        let card = await GetRestResource('api/multiTigercardVariants/' + filter.id);
        response.result.push(card.result);
        return response;
    } else {
        return await GetFilteredRestResource('api/multiTigercardVariants', filter);
    }
}
