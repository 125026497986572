const PremiumAccessTypeMetaData = {

    BILLWERK_PRODUCT_COLUMNS: [
        //Default & Badges
        {label: "ID", id: 'id', columnType: 'default', show: true},
    ],

    BILLWERK_PRODUCT_DETAILS_GENERAL: [
        [{label: 'ID', id: 'id', type: 'text', editable: 'never'}, {label: "Last Modified Date", id: 'lastModifiedDate', type: 'datetime', editable: 'never'}, {label: 'Last Modified By', id: 'lastModifiedBy', type: 'text', editable: 'never'}],
        [{label: 'Created By', id:'createdBy', type: 'text', editable: 'never', isExpertField: true}, {label: 'Created At', id: 'createdDate', type: 'datetime', editable: 'never', isExpertField: true}],
    ],

    BILLWERK_PRODUCT_PRODUCT_DETAILS: [
        [{label: 'Billwerk Plan ID', id: 'planId', type: 'text', editable: 'true'},
        {label: 'Billwerk Plan Variant ID', id: 'planVariantId', type: 'text', editable: 'true'},
        {label: 'Landing Page ID', id: 'landingPageId', type: 'text', editable: 'true'},
        {label: 'Edition', id: 'edition', type: 'select', options: ["NORMAL", "SWISS"], editable: "true"}],

        [{label: 'Billwerk Name', id: 'billwerkName', type: 'text', editable: 'true'},
        {label: 'Period', id: 'period', type: 'select', options: ["P1W", "P2W", "P1M", "P3M", "P6M", "P12M", "P24M"], editable: "true"},
        {label: 'Type', id: 'type', type: 'select', options: ["TIGERTICKET", "DIGITICKET", "SUBSCRIPTION", "COUPON"], editable: "true"},
        {label: 'Price', id: 'price', type: 'number', editable: 'true'},
        {label: 'Checkout Description', id: 'checkoutDescription', type: 'text', editable: 'true'}]
    ],


    PREMIUM_ACCESS_TYPE_COLUMNS: [
        //Default & Badges
        {label: "ID", id: 'id', columnType: 'default', show: true},
        {label: 'Active', id: 'active', columnType: 'boolean', show: true},
        {label: 'Digital', id: 'digital', columnType: 'boolean', show: true},
        {label: 'Name', id: 'name', columnType: 'default', show: true},
        {label: 'Duration', id: 'premiumAccessDuration', columnType: 'default', show: true},
        {label: 'Type', id: 'type', columnType: 'badge', options: [
                {value: "TIGERTICKET", style: 'danger'},
                {value: "DIGITICKET", style: 'success'}
            ], show: true},
    ],

    PREMIUM_ACCESS_TYPE_DETAILS_GENERAL: [
        [{label: 'ID', id: 'id', type: 'text', editable: 'never'}, {label: 'Last Modified By', id: 'lastModifiedBy', type: 'text', editable: 'never'}, {label: "Last Modified Date", id: 'lastModifiedDate', type: 'datetime', editable: 'never'}],
        [{label: 'Created By', type: 'text', editable: 'never', isExpertField: true}, {label: 'Created At', type: 'datetime', editable: 'never', isExpertField: true}],
    ],

    PREMIUM_ACCESS_TYPE_ACCESS_DETAILS: [
        [{label: 'Name', id: 'name', type: 'text', editable: 'true'},],
        [{label: 'Digital', id: 'digital', type: 'boolean', editable: 'true'}, {label: 'Active', id: 'active', type: 'boolean', editable: 'true'}, {label: 'Is default', id: 'default', type: 'boolean', editable: 'true'}],
        [{label: 'Description', id: 'description', editable: 'true', type: 'textarea', rows: 5}],
    ],

    COUPON_VARIANT_COLUMNS: [
        {label: "ID", id: 'id', columnType:'default', show: true},
        {label: "Name", id: 'name', columnType: 'default', show: true},
        {label: "Coupon Type", id: 'couponType', columnType: 'badge', options: [
                {value: 'Swiss Upgrade', style: 'danger'},
                {value: 'DDF', style: 'secondary'},
                {value: 'PRESENT', style: 'success'}
            ], show: true
        },
        {label: "Redemption Type", id: 'redemptionType', columnType: 'badge', options: [
                {value: 'SIGNUP', style: 'success'},
                {value: 'UPGRADE', style: 'danger'}
            ], show: true
        }
    ],

    COUPON_VARIANT_COUPON_TYPES: ["SWISS_UPGRADE", "DDF", "PRESENT"],
    COUPON_VARIANT_REDEMPTION_TYPES: ["SIGNUP", "UPGRADE"],

    COUPON_VARIANT_GENERAL_DETAILS: [
        [{label: 'ID', id: 'id', type: 'text', editable: 'never'}, {label: 'Last Modified By', id: 'lastModifiedBy', type: 'text', editable: 'never'}, {label: "Last Modified Date", id: 'lastModifiedDate', type: 'datetime', editable: 'never'}],
        [{label: 'Created By', type: 'text', editable: 'never', isExpertField: true}, {label: 'Created At', type: 'datetime', editable: 'never', isExpertField: true}],
    ],

    COUPON_VARIANT_VARIANT_DETAILS: [
        [{label: "Name", id: 'name', type: 'text', editable: 'true'},
            {label: "Coupon Type", id: 'couponType', type: 'select', options:  ["SWISS_UPGRADE", "DDF", "PRESENT"], editable: 'true'},
            {label: "Redemption Type", id: 'redemptionType', type: 'select', options: ["SIGNUP", "UPGRADE"], editable: 'true'}
        ]
    ],


    RECOMMENDATION_PRODUCTS_SORT_PARAMS: [],
    RECOMMENDATION_PRODUCT_TYPES: [],
};

export default PremiumAccessTypeMetaData;