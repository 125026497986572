import Row from 'react-bootstrap/Row';
import React, {Component} from "react";
import '../../../App.scss'
import FormSelect from "react-bootstrap/FormSelect";
import {withToast} from "../../util/ToastService";
import {
    FiSettings,
    GrObjectGroup
} from "react-icons/all";
import {ActionModal} from "../../generators/ModalGenerator";
import {ResourcesTable, TableHeader, TableSettingsDialog} from "../../generators/TableGenerator";
import "react-datepicker/dist/react-datepicker.css";
import ContractMetaData from "./ContractMetaData";
import {DeleteCancelledContract, GetCancelledContracts} from "./ContractService";
import {SpinningTiger} from "../../global/SpinningTiger";
import {Form} from "react-bootstrap";
import {ErrorHandler} from "../../util/ErrorHandler";

class Contracts extends Component {

    //------------
    //Constructor
    //------------

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            contracts: [],
            filter: props.location.state != null ? props.location.state.filter :
                {page: 0,
                    email: "",
                    productType: "",
                    type: "",
                    status: "",
                    sortBy : "cancellationDate",
                    sortDirection : "DESC",
                    per_page: 20},
            lastPage: props.location.state != null ? props.location.state.lastPage : 1,
            columns: props.location.state != null ? props.location.state.columns : ContractMetaData.COLUMNS,
            customColumnData: [],
            showDialog: {
                tableSettings: false,
                deletionModal: false,
                dialog_1: false,
                dialog_2: false,
                dialog_3: false
            },
        };

        this.resetFilter = this.resetFilter.bind(this);
        this.updateFilter = this.updateFilter.bind(this);
        this.showOrHideDialog = this.showOrHideDialog.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.loadContractList = this.loadContractList.bind(this);
    }

    //---------
    //Mounting
    //---------

    async componentDidMount() {
        await this.loadContractList(this.state.filter);
    }

    //--------
    //Loading
    //--------

    async loadContractList(filter) {

        this.setState(prevState => ({...prevState, loading: true}));

        const loadedContracts = await GetCancelledContracts(filter);

        if (loadedContracts.error === true) {
            this.setState(prevState => ({...prevState, error: loadedContracts}));
        } else {
            loadedContracts.result.forEach(contract => {
                if (this.state.contracts.filter(ex => (ex.isChecked === true)).includes(contract)) {
                    contract.isChecked = true;
                }
            });
        }

        this.setState(prevState => ({
            ...prevState,
            loading: false,
            contracts: loadedContracts.result,
            lastPage: Math.ceil(loadedContracts.length/this.state.filter.per_page),
            filter: filter
        }));

    }

    async applyFilter(e, filter) {
        e.preventDefault();
        await this.loadContractList(filter);
    }

    //----------
    //Rendering
    //----------

    render() {
        return (
            <div style={{marginBottom: 50}}>
                <div className="content-header">
                    <div className="content-header-title">Contracts</div>
                    <hr/>
                    <div className="content-header-description"><GrObjectGroup/>&#xA0;Contract cancellations requested at https://my.tiger.media/kuendigen
                    </div>
                </div>

                {/* FILTER */}
                <div className="content-box-centered">
                    <div className="content-box-title">Filter</div>
                    <div className="content-box-body">
                        <Form onSubmit={(e) => this.applyFilter(e, this.state.filter)}>
                            <Row>
                                <Form.Group as="Col">
                                    <label>E-Mail</label>
                                    <Form.Control type="text" value={this.state.filter.email} style={{height: "38px"}}
                                           onChange={(e) => this.updateFilter('email', e.target.value)}/>
                                </Form.Group>
                                <Form.Group as="Col" style={{marginLeft: "20px"}}>
                                    <label>Product Type</label>
                                    <FormSelect value={this.state.filter.productType} style={{cursor: "pointer"}}
                                           onChange={(e) => this.updateFilter('productType', e.target.value)}>
                                        <option value={""}>All</option>
                                        <option value="TIGERBOOKS">tigerbooks</option>
                                        <option value="TIGERTONES">tigertones</option>
                                    </FormSelect>
                                </Form.Group>
                                <Form.Group as="Col" style={{marginLeft: "20px"}}>
                                    <label>Status</label>
                                    <FormSelect value={this.state.filter.status} style={{cursor: "pointer"}}
                                           onChange={(e) => this.updateFilter('status', e.target.value)}>
                                        <option value={""}>All</option>
                                        <option value="REQUESTED">Requested</option>
                                        <option value="SUBMITTED">Submitted</option>
                                        <option value="CUSTOMER_NOT_FOUND">Customer Not Found</option>
                                        <option value="NO_CONTRACTS_FOUND">No Contracts Found</option>
                                        <option value="NO_ACTIVE_CONTRACT_FOUND">No Active Contract Found</option>
                                        <option value="NO_CONTRACT_FOUND_FOR_PRODUCT_TYPE">No Contract For Product Type</option>
                                        <option value="UNKNOWN_CONTRACT_VALIDITY">Unknown Contract Validity</option>
                                        <option value="BILLWERK_ERROR">Billwerk Error</option>
                                    </FormSelect>
                                </Form.Group>
                                <Form.Group as="Col" style={{marginLeft: "20px"}}>
                                    <label>Cancellation Type</label>
                                    <FormSelect value={this.state.filter.type} style={{cursor: "pointer"}}
                                                  onChange={(e) => this.updateFilter('type', e.target.value)}>
                                        <option value={""}>All</option>
                                        <option value="ON_TIME">Ordinary</option>
                                        <option value="EXTRAORDINARY">Extraordinary</option>
                                    </FormSelect>
                                </Form.Group>
                            </Row>
                            <hr/>
                            <Row>
                                <div className="col-md-3" style={{marginLeft: "auto"}}>
                                    <button className="form-btn-ci-light" type="button"
                                            onClick={() => this.resetFilter()}>Reset
                                    </button>
                                    <button className="form-btn-ci-blue" type="submit" onClick={(e) => this.applyFilter(e, this.state.filter)}>Apply Filter</button>
                                </div>
                            </Row>
                        </Form>
                    </div>
                </div>

                {/* SHOW THE PRODUCT LIST*/}
                <div className="content-box-centered">
                    <div className="content-box-title">Contracts List
                        <div style={{float: "right", fontWeight: "normal", cursor: "pointer"}}
                             onClick={() => this.showOrHideDialog('tableSettings', true)}><FiSettings/></div>
                    </div>
                    <div className="content-box-body">
                        <TableHeader nameInState="contracts"
                                     viewOnly={false}
                                     deletionForbidden={false}
                                     sortParams={ContractMetaData.CONTRACT_SORT_PARAMS}
                                     state={this.state}
                                     onSetState={(s) => this.setState(s)}
                                     onLoadPage={() => this.loadContractList(this.state.filter)}/>
                        <hr/>
                        {this.state.loading ?
                            <SpinningTiger />
                        :
                            <ResourcesTable
                                state={this.state}
                                columns={this.state.columns}
                                customcolumndata={[]}
                                dataset={this.state.contracts}

                                page={this.state.filter.page}
                                totalPages={this.state.lastPage}
                                handlePageClick={(event) => this.loadContractList({...this.state.filter, page: event.selected})}

                                selectable={true}
                                resourcesurl='contracts'
                                nameInState='nameInState'
                                onToggleResource={(allContracts) => this.setState(prevState => ({
                                    ...prevState,
                                    contracts: allContracts
                                }))}/>
                        }
                    </div>
                </div>

                {/* TABLE SETTINGS */}
                <TableSettingsDialog show={this.state.showDialog.tableSettings}
                                     columns={this.state.columns}
                                     onSetState={(columns) => this.setState(prevState => ({
                                         ...prevState,
                                         columns: columns
                                     }))}
                                     onHide={() =>this.showOrHideDialog('tableSettings', false)}
                />

                {/* DIALOGS */}
                <ActionModal show={this.state.showDialog.deletionModal}
                             onHide={() => this.showOrHideDialog('deletionModal', false)}
                             onAction={() => this.deleteSelectedContracts()}
                             actionButtonText="Delete"
                             title={"Delete contracts"}
                             body={"Are you sure you want to delete the selected contracts?"}/>
                <ErrorHandler error={this.state.error}
                              onHide={() => this.setState(prevState => ({...prevState, error: null}))} />

            </div>
        );
    }

    //--------
    //ACTIONS
    //--------

    async deleteSelectedContracts() {
        this.state.contracts
            .filter(contract => contract.isChecked === true)
            .forEach(contract => DeleteCancelledContract(contract));

        await this.loadContractList(this.state.filter);

        this.showOrHideDialog('deletionModal', false);
    }

    //----------------
    // STATE HANDLING
    //----------------

    updateFilter(id, value) {
        //Get the state and the filter
        let state = this.state;
        let filter = state.filter;

        //Update the attribute
        filter[id] = value;

        //Set the state
        this.setState(prevState => ({...prevState, filter: filter}));
    }

    showOrHideDialog(dialogId, show) {
        //Get the state and the dialog
        let state = this.state;
        let dialogs = state.showDialog;

        //Update the visibility
        dialogs[dialogId] = show;

        //Set the state
        this.setState(prevState => ({...prevState, showDialog: dialogs}));
    }

    async resetFilter(){
        let filter = {page: 0, email: "", productType: "", status: "", type: "", per_page: 20, sortBy: "id", sortDirection: "ASC"};
        this.setState(prevState =>({
            ...prevState,
            filter:filter
        }));
        await this.loadContractList(filter);
    }

}

export default withToast(Contracts);