import React, {Component} from "react";
import '../../../App.scss'
import {PaginationHelp} from "../../global/PaginationHelp";
import {withToast} from "../../util/ToastService";
import {
    FcAbout,
    FcAcceptDatabase,
    FcAddDatabase,
    FcCancel,
    FcClock,
    FcDataBackup,
    FcHighPriority,
    FcInTransit,
    FcInvite,
    FcMusic,
    FcOk,
    FcReading,
    HiOutlineExternalLink, MdModeEdit
} from "react-icons/all";
import {ResourcesTable, TableSettingsDialog} from "../../generators/TableGenerator";
import "react-datepicker/dist/react-datepicker.css";
import {Badge, Button, Pagination, Tab, Table, Tabs} from "react-bootstrap";
import {PromiseButton, SpinningTiger} from "../../global/SpinningTiger";
import background from "../../../assets/images/support_tool_bg.jpeg";
import RecommendationMetaData from "./RecommendationMetaData";
import {
    CreateRecommendationSubscriber,
    GetFilteredRecommendationMails,
    GetFilteredRecommendationsProducts,
    GetFilteredRecommendationSubscribers,
    QueueRecommendationMails,
    SendRecommendationMails,
    SetClusterIdsOfProducts, UpdateRecommendationProduct, UpdateRecommendationSubscriber
} from "./RecommendationService";
import GlobalConstants from "../../config/GlobalConstants";
import {InfoModal} from "../../generators/ModalGenerator";
import {AsyncTypeahead} from "react-bootstrap-typeahead";
import {GetFilteredProfiles, GetProfile, GetProfilePreferences} from "../profiles/ProfileService";
import {GetFilteredSubscriptions} from "../subscriptions/SubscriptionService";
import {GetFilteredClusters} from "../clusters/ClusterService";
import {ErrorHandler} from "../../util/ErrorHandler";

class Recommendations extends Component {

    //------------
    //Constructor
    //------------

    constructor(props) {
        super(props);

        this.state = {
            error: null,
            loading: false,

            //Data
            recommendationProducts: [],
            productFilter: {
                status: "PENDING",
                page: 0,
                per_page: 25
            },
            recommendationSubscribers: [],
            subscriberFilter: {
                subscribed: true,
                page: 0,
                status: "PENDING",
                per_page: 25
            },
            recommendationMails: [],
            mailFilter: {status: "PENDING", page: 0, per_page: 25},
            selectedProduct: {},
            clusters: [],
            clusterPage: 0,
            lastClusterPage: 0,

            //Appearance
            mainTab: 'products',
            productsTab: 'pending',
            subscribersTab: 'active',
            mailTab: 'pending',
            lastProductPage: 0,
            lastSubscriberPage: 0,
            lastMailPage: 0,
            productColumns: RecommendationMetaData.PRODUCT_COLUMNS,
            subscriberColumns: RecommendationMetaData.SUBSCRIBER_COLUMNS,
            mailColumns: RecommendationMetaData.MAIL_COLUMNS,
            showDialog: {
                tableSettings: false,
                deletionModal: false
            },
            selectedUserClusters: [],
            showUserClusterIds: false,
            showAddAccountModal: false,
            showEditClusterModal: false,
            showRemoveClusterModal: false,
            isLoading: false,
            accountList: [],
            selectedAccount: {},
        };

        this.updateFilter = this.updateFilter.bind(this);
        this.showOrHideDialog = this.showOrHideDialog.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.loadRecommendationProducts = this.loadRecommendationProducts.bind(this);
    }

    //---------
    //Mounting
    //---------

    async componentDidMount() {
        document.title = "Produkt-Empfehlung :: Tiger-UI"
        await this.loadRecommendationProducts(this.state.productFilter, 0);
        await this.loadRecommendationSubscribers(this.state.subscriberFilter, 1);
        await this.loadRecommendationMails(this.state.mailFilter, 1);
    }

    //--------
    //Loading
    //--------

    async loadRecommendationProducts(filter, page) {

        this.setState(prevState => ({...prevState, loading: true}));

        filter.page = page + 1;
        const loadedRecommendationProducts = await GetFilteredRecommendationsProducts(filter);
        filter.page = page;

        if (loadedRecommendationProducts.error === true) {
            this.setState(prevState => ({...prevState, error: loadedRecommendationProducts}));
        } else {
            loadedRecommendationProducts.result.forEach(product => {
                if (this.state.recommendationProducts.filter(ex => (ex.isChecked === true)).includes(product)) {
                    product.isChecked = true;
                }
            });
        }


        this.setState(prevState => ({
            ...prevState,
            loading: false,
            recommendationProducts: loadedRecommendationProducts.result,
            productFilter: filter,
            lastProductPage: Math.ceil(loadedRecommendationProducts.length / this.state.productFilter.per_page)
        }));
    }

    async loadRecommendationSubscribers(filter, page) {

        this.setState(prevState => ({...prevState, loading: true}));

        filter.page = page;

        const loadedRecommendationSubscribers = await GetFilteredRecommendationSubscribers(filter);

        if (loadedRecommendationSubscribers.error === true) {
            this.setState(prevState => ({...prevState, error: loadedRecommendationSubscribers}));
        } else {
            loadedRecommendationSubscribers.result.forEach(sub => {
                if (this.state.recommendationProducts.filter(ex => (ex.isChecked === true)).includes(sub)) {
                    sub.isChecked = true;
                }
            });
        }

        this.setState(prevState => ({
            ...prevState,
            loading: false,
            recommendationSubscribers: loadedRecommendationSubscribers.result,
            subscriberFilter: filter,
            lastSubscriberPage: Math.ceil(loadedRecommendationSubscribers.length / this.state.subscriberFilter.per_page)
        }));
    }

    async loadRecommendationMails(filter, page) {

        this.setState(prevState => ({...prevState, loading: true}));

        filter.page = page;

        const loadedRecommendationMails = await GetFilteredRecommendationMails(filter);

        if (loadedRecommendationMails.error === true) {
            this.setState(prevState => ({...prevState, error: loadedRecommendationMails}));
        } else {
            loadedRecommendationMails.result.forEach(sub => {
                if (this.state.recommendationMails.filter(ex => (ex.isChecked === true)).includes(sub)) {
                    sub.isChecked = true;
                }
            });
        }

        this.setState(prevState => ({
            ...prevState,
            loading: false,
            recommendationMails: loadedRecommendationMails.result,
            mailFilter: filter,
            lastMailPage: Math.ceil(loadedRecommendationMails.length / this.state.mailFilter.per_page)
        }));
    }

    async loadClusterPage(page) {
        this.updateState('loading', true);
        let response = await GetFilteredClusters({page: page + 1, visibility: true});
        if (!response.error) {
            let clusters = response.result;
            //Sort the result by its id since its not sorted by default
            if (clusters.length > 0) {
                clusters = clusters.sort((a, b) => (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0));

                //Set the cover url
                for (let i = 0; i < clusters.length; i++) {
                    let cluster = clusters[i];
                    let item = null
                    if (cluster.layoutItems[GlobalConstants.RECOMMENDATION_CLUSTER_APP_ID] != null) {
                        item = cluster.layoutItems[GlobalConstants.RECOMMENDATION_CLUSTER_APP_ID];
                    } else if (cluster.layoutItems[GlobalConstants.RECOMMENDATION_CLUSTER_APP_ID_FALLBACK] != null) {
                        item = cluster.layoutItems[GlobalConstants.RECOMMENDATION_CLUSTER_APP_ID_FALLBACK];
                    }
                    if (item != null && item.shopLayoutImages.length > 0) {
                        cluster.coverUrl = item.shopLayoutImages[0].hdImage;
                    } else {
                        cluster.coverUrl = null;
                    }
                }
            }
            this.setState(prevState => ({
                ...prevState,
                loading: false,
                clusters: clusters,
                clusterPage: page,
                lastClusterPage: Math.ceil(response.length / 20)
            }));
        } else {
            this.setState(prevState => ({...prevState, error: response, loading: false}));
        }
    }

    //----------
    //Rendering
    //----------

    render() {
        const filterBy = () => true;

        const handleSearch = async (email) => {

            this.updateState('isLoading', true)

            let token = this.props.token;

            let accountList = await fetch(GlobalConstants.SPINE_CLIENT_HOST + `api/accounts?email=${email}`, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + token.access_token,
                }
            })
                .then(function (resp) {
                    return resp.json()
                })
                .then(function (accounts) {
                    return accounts.map((account) => ({
                        id: account.id,
                        email: account.email
                    }));
                });

            this.updateState('accountList', accountList);
            this.updateState('isLoading', false);
        };

        return (
            <div style={{paddingTop: 30, backgroundImage: `url(${background})`, minHeight: "100vh", height: "auto"}}>
                <div className="new-content-header">
                    <div className="new-content-header-title">Content-News</div>
                </div>

                {/* SHOW THE PRODUCT LIST*/}
                <div className="support-tool-box">
                    <Tabs id="recommendation-main-tab" activeKey={this.state.mainTab}
                          onSelect={(k) => this.updateState('mainTab', k)}
                          className="mb-5">

                        {/* PRODUCTS */}
                        <Tab eventKey="products"
                             title={<span style={{color: "#495057"}}><FcMusic size={24}/> Produkte</span>}>
                            <Button variant="secondary" onClick={() => this.setClusterIds()}><FcDataBackup
                                size={24}/> Setze Helden-IDs</Button>
                            <hr/>
                            <Tabs id="recommendation-products-tabs" activeKey={this.state.productsTab}
                                  onSelect={(k) => {
                                      this.updateState('productsTab', k);
                                      let filter = this.state.productFilter;
                                      filter.page = 0;
                                      if (k === 'pending') {
                                          filter.status = "PENDING";
                                          this.loadRecommendationProducts(filter, 0).then(r => r);
                                      } else if (k === 'queued') {
                                          filter.status = "QUEUED";
                                          this.loadRecommendationProducts(filter, 0).then(r => r);
                                      } else if (k === 'successful') {
                                          filter.status = "SUCCESSFUL";
                                          this.loadRecommendationProducts(filter, 0).then(r => r);
                                      } else if (k === 'aborted') {
                                          filter.status = "ABORTED";
                                          this.loadRecommendationProducts(filter, 0).then(r => r);
                                      }
                                      this.updateState('productFilter', filter);
                                  }}
                                  className="mb-5">

                                {/* PENDING */}
                                <Tab eventKey="pending"
                                     title={<span style={{color: "#495057"}}><FcAcceptDatabase size={24}/> Warteschlange</span>}>
                                    <div style={{marginBottom: "20px"}}>
                                        <FcAbout size={28}/> Hier wird die Produkt-Warteschlange angezeigt, die mit dem
                                        nächsten Event abgearbeitet werden soll (Samstag, 02:00 Uhr).
                                    </div>
                                </Tab>

                                {/* QUEUED */}
                                <Tab eventKey="queued"
                                     title={<span style={{color: "#495057"}}><FcClock size={24}/> In Zustellung</span>}>
                                    <div style={{marginBottom: "20px"}}>
                                        <FcAbout size={28}/> Hier wird die Produkt-Warteschlange angezeigt, die aktuell
                                        abgearbeitet wird.
                                    </div>
                                </Tab>

                                {/* SUCCESSFUL */}
                                <Tab eventKey="successful"
                                     title={<span style={{color: "#495057"}}><FcOk size={24}/> Erfolgreich</span>}>
                                    <div style={{marginBottom: "20px"}}>
                                        <FcAbout size={28}/> Hier wird eine Liste von Produkten angezeigt, die
                                        erfolgreich empfohlen wurden.
                                    </div>
                                </Tab>

                                {/* ABORTED */}
                                <Tab eventKey="aborted" title={<span style={{color: "#495057"}}><FcCancel size={24}/> Abgebrochen/Fehlerhaft</span>}>
                                    <div style={{marginBottom: "20px"}}>
                                        <FcAbout size={28}/> Hier wird eine Liste von Produkten angezeigt, deren
                                        Empfehlung vorzeitig wieder deaktiviert wurde.
                                    </div>
                                </Tab>
                            </Tabs>

                            {this.state.loading ?
                                <SpinningTiger/>
                                :
                                <div>
                                    <Table responsive bordered hover striped>
                                        <thead>
                                        <tr>
                                            <th colSpan="2"
                                                style={{textAlign: "center"}}>Produkt:
                                            </th>
                                            <th colSpan="2"
                                                style={{textAlign: "center"}}><MdModeEdit size={18}/> Held/Welt:
                                            </th>
                                            <th colSpan="1"
                                                style={{textAlign: "center"}}>Empfehlungsbasis:
                                            </th>
                                            <th colSpan="1"
                                                style={{textAlign: "center"}}>Status:
                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {this.state.recommendationProducts.map(p => (
                                            <tr role="row" key={p.id}>
                                                <td>
                                                    <a href={"/" + GlobalConstants.APP_PATH + "products/" + p.productId}
                                                       target="_blank" rel="noreferrer" style={{color: "black"}}>
                                                        <span
                                                            style={{fontWeight: "bold"}}>{p.productId}</span> - <span>{p.title}</span>
                                                    </a>
                                                </td>
                                                <td align={"center"}>
                                                    {
                                                        p.coverUrl != null ?
                                                            <img src={p.coverUrl} width={150} alt={"Cover"}/> :
                                                            <FcHighPriority size={20}/>
                                                    }
                                                </td>
                                                <td align={"center"} style={{cursor: "pointer"}} onClick={() => {
                                                    this.updateState('selectedProduct', p);
                                                    if (p.clusterId != null) {
                                                        this.updateState('showRemoveClusterModal', true);
                                                    } else {
                                                        this.loadClusterPage(0).then(r => this.updateState('showEditClusterModal', true));
                                                    }
                                                }}>
                                                    {(p.clusterId != null && p.clusterTitle != null) ?
                                                        <a href={"/" + GlobalConstants.APP_PATH + "clusters/" + p.clusterId}
                                                           target="_blank" rel="noreferrer" style={{color: "#333"}}>
                                                            <span
                                                                style={{fontWeight: "bold"}}>{p.clusterId}</span> - {p.clusterTitle}
                                                        </a>
                                                        :
                                                        <FcHighPriority size={20}/>
                                                    }
                                                </td>
                                                <td align={"center"} style={{cursor: "pointer"}} onClick={() => {
                                                    this.updateState('selectedProduct', p);
                                                    this.updateState('showEditClusterModal', true);
                                                }}>
                                                    {
                                                        p.clusterCoverUrl != null ?
                                                            <img src={p.clusterCoverUrl} width={150} alt={"Cover"}/> :
                                                            <FcHighPriority size={20}/>
                                                    }
                                                </td>
                                                <td><Badge
                                                    variant={p.notificationType === 'NEW_PRODUCT' ? "primary" : "success"}>{p.notificationType === 'NEW_PRODUCT' ? "Neues Produkt" : "Ähnliches Produkt"}</Badge>
                                                </td>
                                                <td>
                                                    <Badge
                                                        variant={p.notificationStatus === 'PENDING' ? "primary" : (p.notificationStatus === 'QUEUED' ? "warning" : (p.notificationStatus === 'SUCCESSFUL' ? "success" : "danger"))}>
                                                        {p.notificationStatus === "PENDING" ? "Eingereiht" : (p.notificationStatus === 'QUEUED' ? "Vorbereitung" : (p.notificationStatus === 'SUCCESSFUL' ? "Erfolgreich" : "Abgebrochen"))}
                                                    </Badge>
                                                </td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </Table>
                                    <div className={"advanced-pagination"}>
                                        <PaginationHelp
                                            page={this.state.productFilter.page}
                                            totalPages={this.state.lastProductPage}
                                            handlePageClick={(event) => this.loadRecommendationProducts(this.state.productFilter, event.selected)}
                                        />

                                    </div>
                                </div>
                            }
                        </Tab>

                        {/* SUBSCRIBERS */}
                        <Tab eventKey="subscribers"
                             title={<span style={{color: "#495057"}}><FcReading size={24}/> Abonnenten</span>}>
                            <Button variant="secondary"
                                    onClick={() => this.updateState('showAddAccountModal', true)}><FcAddDatabase
                                size={24}/> Account hinzufügen</Button>
                            <hr/>
                            <Tabs id="recommendation-subscribers-tabs" activeKey={this.state.subscribersTab}
                                  onSelect={(k) => {
                                      this.updateState('subscribersTab', k);
                                      let filter = this.state.subscriberFilter;
                                      filter.page = 1;
                                      if (k === 'active') {
                                          filter.subscribed = true;
                                          this.loadRecommendationSubscribers(filter, 1).then(r => r);
                                      } else if (k === 'inactive') {
                                          filter.subscribed = false;
                                          this.loadRecommendationSubscribers(filter, 1).then(r => r);
                                      }
                                      this.updateState('subscriberFilter', filter);
                                  }}
                                  className="mb-5">

                                {/* SUBSCRIBED */}
                                <Tab eventKey="active"
                                     title={<span style={{color: "#495057"}}><FcOk size={24}/> Angemeldet</span>}>
                                    <div style={{marginBottom: "20px"}}>
                                        <FcAbout size={28}/> Hier werden die Kunden angezeigt, die die
                                        Produktempfehlungen erhalten.
                                    </div>
                                </Tab>

                                {/* UNSUBSCRIBED */}
                                <Tab eventKey="inactive"
                                     title={<span style={{color: "#495057"}}><FcCancel size={24}/> Abgemeldet</span>}>
                                    <div style={{marginBottom: "20px"}}>
                                        <FcAbout size={28}/> Hier werden die Kunden angezeigt, die sich von den
                                        Produktempfehlungen abgemeldet haben.
                                    </div>
                                </Tab>
                            </Tabs>

                            {this.state.loading ?
                                <SpinningTiger/>
                                :
                                <div>
                                    <Table responsive bordered hover striped>
                                        <thead>
                                        <tr>
                                            <th colSpan="1"
                                                style={{textAlign: "center"}}>E-Mail
                                            </th>
                                            <th colSpan="1"
                                                style={{textAlign: "center"}}><HiOutlineExternalLink/> Account ID
                                            </th>
                                            <th colSpan="1"
                                                style={{textAlign: "center"}}><HiOutlineExternalLink/> Profile ID
                                            </th>
                                            <th colSpan="1"
                                                style={{textAlign: "center"}}>Aktiven Zugang:
                                            </th>
                                            <th colSpan="1"
                                                style={{textAlign: "center"}}>Abonnierte Helden/Themen
                                            </th>
                                            <th colSpan="1"
                                                style={{textAlign: "center"}}>Altersspanne
                                            </th>
                                            <th colSpan="1"
                                                style={{textAlign: "center"}}>Bearbeiten
                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {this.state.recommendationSubscribers.map(s => (
                                            <tr role="row" key={s.id}>
                                                <td>{s.email}</td>
                                                <td>
                                                    <a href={"/" + GlobalConstants.APP_PATH + "accounts/" + s.accountId}
                                                       target="_blank" rel="noreferrer"
                                                       style={{color: "black"}}>{s.accountId}</a>
                                                </td>
                                                <td>
                                                    <a href={"/" + GlobalConstants.APP_PATH + "accounts/" + s.accountId + "/profiles/" + s.userId}
                                                       target="_blank" rel="noreferrer"
                                                       style={{color: "black"}}>{s.userId}</a>
                                                </td>
                                                <td align={"center"}><Badge
                                                    variant={s.active ? "success" : "danger"}>{s.active ? "Y" : "N"}</Badge>
                                                </td>
                                                <td align={"center"}><Button variant="secondary" onClick={() => {
                                                    this.updateState('selectedUserClusters', s.clusterIds);
                                                    this.updateState('showUserClusterIds', true);
                                                }}>Öffnen</Button></td>
                                                <td>{s.ageRangeMin} - {s.ageRangeMax} Jahre</td>
                                                <td>
                                                    <Button variant="secondary"
                                                            onClick={() => this.toggleAccountSubscription(s)}>{this.state.subscribersTab === 'active' ? "Abmelden" : "Anmelden"}</Button>
                                                </td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </Table>
                                    <Pagination>
                                        <Pagination.Item hidden={this.state.subscriberFilter.page === 1}
                                                         onClick={() => this.loadRecommendationSubscribers(this.state.subscriberFilter, 1)}>&lt;&lt;</Pagination.Item>
                                        <Pagination.Item hidden={this.state.subscriberFilter.page === 1}
                                                         onClick={() => this.loadRecommendationSubscribers(this.state.subscriberFilter, this.state.subscriberFilter.page - 1)}>&lt;</Pagination.Item>
                                        <Pagination.Item hidden={this.state.subscriberFilter.page <= 2}
                                                         onClick={() => this.loadRecommendationSubscribers(this.state.subscriberFilter, this.state.subscriberFilter.page - 2)}>{this.state.subscriberFilter.page - 2}</Pagination.Item>
                                        <Pagination.Item hidden={this.state.subscriberFilter.page === 1}
                                                         onClick={() => this.loadRecommendationSubscribers(this.state.subscriberFilter, this.state.subscriberFilter.page - 1)}>{this.state.subscriberFilter.page - 1}</Pagination.Item>
                                        <Pagination.Item
                                            active={true}>{this.state.subscriberFilter.page}</Pagination.Item>
                                        <Pagination.Item
                                            hidden={this.state.subscriberFilter.page === this.state.lastSubscriberPage}
                                            onClick={() => this.loadRecommendationSubscribers(this.state.subscriberFilter, this.state.subscriberFilter.page + 1)}>{this.state.subscriberFilter.page + 1}</Pagination.Item>
                                        <Pagination.Item
                                            hidden={this.state.subscriberFilter.page > this.state.lastSubscriberPage - 2}
                                            onClick={() => this.loadRecommendationSubscribers(this.state.subscriberFilter, this.state.subscriberFilter.page + 2)}>{this.state.subscriberFilter.page + 2}</Pagination.Item>
                                        <Pagination.Item
                                            hidden={this.state.subscriberFilter.page === this.state.lastSubscriberPage}
                                            onClick={() => this.loadRecommendationSubscribers(this.state.subscriberFilter, this.state.subscriberFilter.page + 1)}>&gt;</Pagination.Item>
                                        <Pagination.Item
                                            hidden={this.state.subscriberFilter.page === this.state.lastSubscriberPage}
                                            onClick={() => this.loadRecommendationSubscribers(this.state.subscriberFilter, this.state.lastSubscriberPage)}>&gt;&gt;</Pagination.Item>
                                    </Pagination>
                                </div>
                            }

                        </Tab>

                        {/* MAILS */}
                        <Tab eventKey="mails"
                             title={<span style={{color: "#495057"}}><FcInTransit size={24}/> Mails</span>}>
                            <Button variant="secondary" onClick={() => this.queueRecommendationMails()}><FcAddDatabase
                                size={24}/> Generiere E-Mail-Warteschlange</Button>
                            <Button style={{marginLeft: "10px"}} variant="secondary"
                                    onClick={() => this.sendRecommendationMails()}><FcInvite size={24}/> Starte den
                                E-Mail-Versand</Button>
                            <hr/>
                            <Tabs id="recommendation-mails-tabs" activeKey={this.state.mailTab}
                                  onSelect={(k) => {
                                      this.updateState('mailTab', k);
                                      let filter = this.state.mailFilter;
                                      filter.page = 1;
                                      if (k === 'pending') {
                                          filter.status = "PENDING";
                                          this.loadRecommendationMails(filter, 1).then(r => r);
                                      } else if (k === 'queued') {
                                          filter.status = "SENDING";
                                          this.loadRecommendationMails(filter, 1).then(r => r);
                                      } else if (k === 'successful') {
                                          filter.status = "SUCCESSFUL";
                                          this.loadRecommendationMails(filter, 1).then(r => r);
                                      } else if (k === 'failed') {
                                          filter.status = "FAILED";
                                          this.loadRecommendationMails(filter, 1).then(r => r);
                                      }
                                      this.updateState('mailFilter', filter);
                                  }}
                                  className="mb-5">

                                {/* PENDING */}
                                <Tab eventKey="pending"
                                     title={<span style={{color: "#495057"}}><FcAcceptDatabase size={24}/> Warteschlange</span>}>
                                    <div style={{marginBottom: "20px"}}>
                                        <FcAbout size={28}/> Hier werden die E-Mails angezeigt, die mit dem nächsten
                                        Event abgeschickt werden sollen (Samstag, 03:00 Uhr)
                                    </div>
                                </Tab>

                                {/* QUEUED */}
                                <Tab eventKey="queued"
                                     title={<span style={{color: "#495057"}}><FcClock size={24}/> In Versand</span>}>
                                    <div style={{marginBottom: "20px"}}>
                                        <FcAbout size={28}/> Hier werden die E-Mails angezeigt, die sich gerade in
                                        Zustellung befinden.
                                    </div>
                                </Tab>

                                {/* SUCCESSFUL */}
                                <Tab eventKey="successful"
                                     title={<span style={{color: "#495057"}}><FcOk size={24}/> Erfolgreich</span>}>
                                    <div style={{marginBottom: "20px"}}>
                                        <FcAbout size={28}/> Hier werden die E-Mails angezeigt, die erfolgreich
                                        zugestellt wurden.
                                    </div>
                                </Tab>

                                {/* FAILED */}
                                <Tab eventKey="failed"
                                     title={<span style={{color: "#495057"}}><FcCancel size={24}/> Fehlerhaft</span>}>
                                    <div style={{marginBottom: "20px"}}>
                                        <FcAbout size={28}/> Hier werden die E-Mails angezeigt, die nicht zugestellt
                                        werden konnten.
                                    </div>
                                </Tab>
                            </Tabs>

                            {this.state.loading ?
                                <SpinningTiger/>
                                :
                                <ResourcesTable
                                    state={this.state}
                                    columns={this.state.mailColumns}
                                    customcolumndata={[]}
                                    dataset={this.state.recommendationMails}

                                    selectable={false}
                                    resourcesurl='recommendationMails'
                                    nameInState='recommendationMails'
                                    onToggleResource={(allRecommendationMails) => this.setState(prevState => ({
                                        ...prevState,
                                        recommendationMails: allRecommendationMails
                                    }))}

                                    page={this.state.mailFilter.page}
                                    lastPage={this.state.lastMailPage}
                                    handlePageClick={(event) => this.loadRecommendationMails({
                                        ...this.state.mailFilter,
                                        page: event.selected + 1
                                    })}

                                />
                            }

                        </Tab>
                    </Tabs>

                    <hr/>

                </div>

                {/* TABLE SETTINGS */}
                <TableSettingsDialog show={this.state.showDialog.tableSettings}
                                     columns={this.state.productColumns}
                                     onSetState={(columns) => this.setState(prevState => ({
                                         ...prevState,
                                         productColumns: columns
                                     }))}
                                     onHide={() => this.showOrHideDialog('tableSettings', false)}
                />

                <InfoModal show={this.state.showUserClusterIds}
                           title={"Ausgewählte Helden"}
                           onHide={() => this.updateState('showUserClusterIds', false)}
                           body={
                               <>
                                   {this.state.selectedUserClusters.length > 0 ?
                                       <ul style={{listStyleType: "square"}}>
                                           {this.state.selectedUserClusters.map(c => (
                                               <li><a href={"/" + GlobalConstants.APP_PATH + "clusters/" + c}
                                                      target="_blank"
                                                      rel="noreferrer" style={{color: "black"}}>{c}</a></li>
                                           ))}
                                       </ul>
                                       :
                                       <div style={{width: "100%", textAlign: "center"}}>
                                           Der Kunde hat keine Helden oder Themen ausgewählt.
                                       </div>
                                   }
                               </>

                           }

                />

                <InfoModal show={this.state.showAddAccountModal}
                           title={"Account zur Liste hinzufügen"}
                           onHide={() => this.updateState('showAddAccountModal', false)}
                           body={
                               <>
                                   <AsyncTypeahead
                                       filterBy={filterBy}
                                       id={"account-search-typeahead"}
                                       isLoading={this.state.isLoading}
                                       labelKey={"email"}
                                       minLength={3}
                                       onSearch={handleSearch}
                                       options={this.state.accountList}
                                       placeholder="Gib eine E-Mail-Adresse ein..."
                                       renderMenuItemChildren={(account, props) => (
                                           <div onClick={() => this.updateState('selectedAccount', account)}>
                                               <span style={{fontWeight: "bold"}}>{account.email}</span> ({account.id})
                                           </div>
                                       )}
                                   />
                                   <br/>
                                   <Button variant="secondary" disabled={this.state.selectedAccount.id === null}
                                           onClick={() => this.addAccountToList(this.state.selectedAccount)}>Hinzufügen</Button>
                               </>

                           }
                />

                <InfoModal show={this.state.showEditClusterModal}
                           title={this.state.selectedProduct.title + " - Held bearbeiten"}
                           onHide={() => {
                               this.updateState('showEditClusterModal', false);
                               this.updateState('selectedProduct', {});
                           }}
                           body={
                               <>
                                   <Table responsive bordered hover striped>
                                       <thead>
                                       <tr>
                                           <th style={{width: "50px"}}></th>
                                           <th>ID</th>
                                           <th>Name</th>
                                           <th>Cover</th>
                                       </tr>
                                       </thead>
                                       <tbody>
                                       {this.state.clusters.map(c => (
                                           <tr role="row" key={c.id} style={{cursor: "pointer"}}
                                               onClick={() => this.updateProductCluster(c)}>
                                               <td style={{textAlign: "center"}}>{this.state.selectedProduct.clusterId === c.id ?
                                                   <FcOk size={20}/> : ""}</td>
                                               <td style={{fontWeight: this.state.selectedProduct.clusterId === c.id ? "bold" : "normal"}}>{c.id}</td>
                                               <td style={{fontWeight: this.state.selectedProduct.clusterId === c.id ? "bold" : "normal"}}>{c.name}</td>
                                               <td style={{textAlign: "center"}}>{c.coverUrl != null ?
                                                   <img src={c.coverUrl} alt={"Cover"} width={102}/> :
                                                   <FcHighPriority size={20}/>}</td>
                                           </tr>
                                       ))}
                                       </tbody>
                                   </Table>
                                   <div className={"advanced-pagination"}>
                                       <PaginationHelp
                                           initialPage={0}
                                           totalPages={this.state.lastClusterPage}
                                           handlePageClick={(event) => this.loadClusterPage(event.selected)}
                                       />
                                   </div>
                               </>
                           }
                />

                <InfoModal show={this.state.showRemoveClusterModal}
                           title={this.state.selectedProduct.title + " - Held bearbeiten"}
                           onHide={() => {
                               this.updateState('showRemoveClusterModal', false);
                               this.updateState('selectedProduct', {});
                           }}
                           body={
                               <div style={{textAlign: "center"}}>
                                   Das Produkt <strong>{this.state.selectedProduct.title}</strong> ist bereits dem
                                   Helden/der
                                   Welt <strong>{this.state.selectedProduct.clusterTitle}</strong> zugeordnet.
                                   <br/><br/>
                                   Soll der Held/die Welt entfernt oder geändert werden?
                                   <br/><br/>
                                   <div style={{marginLeft: "40%"}}>
                                       <PromiseButton className="form-btn-ci-red" text="Entfernen"
                                                      onClick={() => this.removeClusterFromProduct(this.state.selectedProduct)}/>
                                       <PromiseButton className="form-btn-ci-blue" text="Ändern" onClick={() => {
                                           this.loadClusterPage(0).then(r => {
                                               this.updateState('showRemoveClusterModal', false);
                                               this.loadClusterPage(0).then(r => this.updateState('showEditClusterModal', true));
                                           });
                                       }}/>
                                   </div>
                               </div>
                           }
                />

                <ErrorHandler error={this.state.error}
                              onHide={() => this.setState(prevState => ({...prevState, error: null}))}/>
            </div>
        );
    }

    //----------------
    // API Methods
    //----------------

    async updateProductCluster(cluster) {
        //Cover Url is required
        if (cluster.coverUrl == null) {
            this.props.addToast("Das Cover darf nicht leer sein.", {
                autoDismiss: true,
                appearance: "error"
            });
            return;
        }

        let product = this.state.selectedProduct;
        product.clusterTitle = cluster.name;
        product.clusterCoverUrl = cluster.coverUrl;
        product.clusterId = cluster.id;

        let response = await UpdateRecommendationProduct(product);
        if (!response.error) {
            this.props.addToast("Der Held/die Welt wurde erfolgreich gespeichert.", {
                autoDismiss: true,
                appearance: "success"
            });
            await this.loadRecommendationProducts(this.state.productFilter, 1);
            this.updateState('showEditClusterModal', false);
            this.updateState('selectedProduct', {});
        } else {
            this.setState(prevState => ({...prevState, error: response}));
        }
    }

    async removeClusterFromProduct(product) {
        product.clusterTitle = null;
        product.clusterCoverUrl = null;
        product.clusterId = null;

        let response = await UpdateRecommendationProduct(product);
        if (!response.error) {
            this.props.addToast("Der Held/die Welt wurde erfolgreich entfernt.", {
                autoDismiss: true,
                appearance: "success"
            });
            await this.loadRecommendationProducts(this.state.productFilter, 0);
            this.updateState('showRemoveClusterModal', false);
            this.updateState('selectedProduct', {});
        } else {
            this.setState(prevState => ({...prevState, error: response}));
        }
    }

    async toggleAccountSubscription(subscriber) {
        subscriber.subscribed = !subscriber.subscribed;
        let response = await UpdateRecommendationSubscriber(subscriber);
        if (!response.error) {
            await this.loadRecommendationSubscribers(this.state.subscriberFilter, 0);
        } else {
            this.setState(prevState => ({...prevState, error: response}));
        }
    }

    async addAccountToList(account) {
        //Fetch the users
        let userListResponse = await GetFilteredProfiles(this.state.selectedAccount.id, {});
        if (!userListResponse.error) {
            let users = userListResponse.result;
            let user = {};
            if (users.length > 0) {
                user = users[0];
            }

            if (user.id === null) {
                this.setState(prevState => ({...prevState, error: userListResponse}));

                return;
            }

            //Load the user
            let userResponse = await GetProfile(this.state.selectedAccount.id, user.id);
            if (userResponse.error) {
                this.setState(prevState => ({...prevState, error: userResponse}));
                return;
            }
            user = userResponse.result;

            //TODO Update this as soon as the account service is live and everything is tested
            let clusters = [];
            if (GlobalConstants.ENVIRONMENT !== 'production_TBD') {
                let clusterResponse = await GetProfilePreferences(user.id);
                if (!clusterResponse.error) {
                    clusters = clusterResponse.result.length > 0 ? clusterResponse.result.map(p => p.id) : [];
                } else {
                    this.setState(prevState => ({...prevState, error: clusterResponse}));
                    return;
                }
            } else {
                clusters = user.preferences.length > 0 ? user.preferences.map(p => p.id) : [];
            }

            //Fetch the subscription
            let active = false;
            let subResponse = await GetFilteredSubscriptions(this.state.selectedAccount, {active_only: true});
            if (!subResponse.error) {
                if (subResponse.result.length > 0) {
                    active = true;
                }
            }

            //Create the recommendation subscriber object
            let subscriber = {
                email: this.state.selectedAccount.email,
                accountId: this.state.selectedAccount.id,
                userId: user.id,
                clusterIds: clusters,
                ageRangeMin: user.ageRangeMin,
                ageRangeMax: user.ageRangeMax,
                active: active,
                subscribed: this.state.subscribersTab === 'active'
            }

            let created = await CreateRecommendationSubscriber(subscriber);
            if (!created.error) {
                this.loadRecommendationSubscribers(this.state.subscriberFilter, 1).then(r => this.updateState('showAddAccountModal', false));
            } else {
                this.setState(prevState => ({...prevState, error: created}));
            }
        }
    }

    async setClusterIds() {
        let response = await SetClusterIdsOfProducts();
        if (!response.error) {
            this.props.addToast("Die Ermittlung der Helden-IDs ist abgeschlossen.", {
                autoDismiss: true,
                appearance: "success"
            });
            await this.loadRecommendationProducts({
                status: "PENDING",
                page: 0
            }, 0);
        } else {
            this.setState(prevState => ({...prevState, error: response}));
        }
    }

    async queueRecommendationMails() {
        let response = await QueueRecommendationMails();
        if (!response.error) {
            this.props.addToast("Die Generierung der Mail-Warteschlange ist abgeschlossen.", {
                autoDismiss: true,
                appearance: "success"
            });
            await this.loadRecommendationMails({
                status: "PENDING",
                page: 1
            }, 1);
        } else {
            this.setState(prevState => ({...prevState, error: response}));
        }
    }

    async sendRecommendationMails() {
        let response = await SendRecommendationMails();
        if (!response.error) {
            this.props.addToast("Der Versand der E-Mails ist abgeschlossen.", {
                autoDismiss: true,
                appearance: "success"
            });
            await this.loadRecommendationMails({
                status: "SUCCESSFUL",
                page: 1
            }, 1);
        } else {
            this.setState(prevState => ({...prevState, error: response}));
        }
    }

    //----------------
    // STATE HANDLING
    //----------------

    updateFilter(id, value) {
        //Get the state and the filter
        let state = this.state;
        let filter = state.filter;

        //Update the attribute
        filter[id] = value;

        //Set the state
        this.setState(prevState => ({...prevState, filter: filter}));
    }

    updateState(param, value) {
        let state = this.state;
        state[param] = value;
        this.setState(state);
    }

    showOrHideDialog(dialogId, show) {
        //Get the state and the dialog
        let state = this.state;
        let dialogs = state.showDialog;

        //Update the visibility
        dialogs[dialogId] = show;

        //Set the state
        this.setState(prevState => ({...prevState, showDialog: dialogs}));
    }
}

export default withToast(Recommendations);