const ExampleMetaData = {

    //Columns
    COLUMNS: [
        //Default & Badges
        {label: "ID", id: 'id', columnType: 'default', show: true},
        {label: "Name", id: 'name', columnType: 'default', show: true},
    ],

    //Details
    DETAILS_GENERAL: [
        [{label: 'ID', id: 'id', type: 'number', editable: 'never', isExpertField: false}, {label: 'Field 2', id: 'field_2', type: 'text', editable: 'true', required: true}, {label: "Field 3", id: 'field_3', type: 'number', editable: 'true'}],
        [{label: 'Field 4', id: 'field_4', type: 'text', editable: 'true'}],
    ],
    DETAILS_SECTION_1: [
        [{label: 'Field 5', id: 'field_5', type: 'text', editable: 'new', required: true}],
        [{label: 'Field 6', id: 'field_6', type: 'number', editable: 'true', isExpertField: true}]
    ],

    //Sorting
    EXAMPLE_SORT_PARAMS: [{id: 'id', label: 'Example ID'}, {id: 'name', label: 'Name'}],
};

export default ExampleMetaData;