import React, {useState} from 'react';
import './../../App.scss'
import PropTypes from 'prop-types';
import {loginUser} from "./Oauth2Service";
import {InfoModal} from "../generators/ModalGenerator";

export default function Authentication({ setToken }) {
    const [username, setUserName] = useState();
    const [password, setPassword] = useState();

    const [modalShow, setModalShow] = useState(false);

    const handleSubmit = async e => {

        e.preventDefault();
        let token = await loginUser({
            username,
            password
        });
/*        let token = {
            access_token: "22ca7246-eb63-446c-9025-9dbd3d42a620",
            token_type: "bearer",
            refresh_token: "17e3c412-c146-4db4-b8ba-f4a92af3ffa9",
            expires_in: 4655139,
            scope: "read write"
        }*/

        if(token !== undefined){
            //Token is available -> Set the expiration date/time
            var expiredAt = new Date();
            expiredAt.setSeconds(expiredAt.getSeconds() + token.expires_in);
            token.expires_at = expiredAt.getTime();

            //Now save the token
            setToken(token);
        } else {
            setModalShow(true);
        }
    }

    return(
        <>
            <div className="content-header">
                <div className="content-header-title">Welcome to tiger media Backoffice UI</div>
            </div>
            <div className="content-box-centered">
                <div className="authentication-wrapper">
                    <h1>Please Log In</h1>
                    <form onSubmit={handleSubmit}>
                        <label>Email</label>
                        <input type="text" onChange={e => setUserName(e.target.value)}/>
                        <br />
                        <label>Password</label>
                        <input type="password" onChange={e => setPassword(e.target.value)}/>

                        <button className="btn-ci" type="submit">Sign In</button>

                    </form>
                </div>
                <div className="tibo" />
            </div>
            <div>
                <InfoModal show={modalShow} onHide={() => setModalShow(false)}
                           title="Authentication failed" body="Either the username/email or the password are not correct. Please check your credentials and try again" />
            </div>
        </>
    )
}

Authentication.propTypes = {
    setToken: PropTypes.func.isRequired
}