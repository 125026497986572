const ROUTINE_TYPE_ITEMS = ["", 'TIGER_ROUTINE', 'CUSTOMER_ROUTINE'];
const STATE_ITEMS = ["", 'ACTIVE', 'INACTIVE', "CARD_ACTIVE"];
const PRIVACY_ITEMS = ["", 'PRIVATE', 'PUBLIC'];
const WEEKDAY_ITEMS = ['MONDAY', 'TUESDAY', "WEDNESDAY", "THURSDAY", "FRIDAY", "SATURDAY", "SUNDAY"];

const RoutineMetaData = {

    //Columns
    COLUMNS: [
        {label: "ID", id: 'id', columnType: 'default', show: true},
        {label: "Name", id: 'name', columnType: 'default', show: true},
        {label: "Language", id: 'language', columnType: 'default', show: true},
        {label: "Automatic Start", id: 'startsAutomatically', columnType: 'boolean', show: true},
    ],

    //Details
    DETAILS_GENERAL: [
        [
            {label: 'ID', id: 'id', type: 'number', editable: 'never', isExpertField: false},
            {label: 'Name', id: 'name', type: 'text', editable: 'true', required: true, isExpertField: false},
            {label: 'Routine Type', id: 'routineType', type: 'select', options: ROUTINE_TYPE_ITEMS, editable: 'true', required: true, isExpertField: false},
            {label: 'Language', id: 'language', type: 'language', editable: 'true', isExpertField: false},
        ],
        [
                {label: 'Display Image Url', id: 'displayImageUrl', type: 'text', editable: 'true', required: true, isExpertField: false},
                {label: 'Preview', id: 'displayImageUrl', type: 'image', editable: 'never', isExpertField: false},
        ],
        [
            {label: 'State', id: 'state', type: 'select', options: STATE_ITEMS, editable: 'true', required: true, isExpertField: false},
            {label: 'Privacy', id: 'privacy', type: 'select', options: PRIVACY_ITEMS, editable: 'true', required: true, isExpertField: false},
            {label: 'Automatic start', id: 'startsAutomatically', type: 'boolean', editable: 'true', required: false, isExpertField: false},
            {label: 'Tags', type: 'custom', editable: 'true', required: false, isExpertField: false},
        ],
        [{label: 'Description', id: 'description', type: 'textarea', rows: 5, editable: 'true', isExpertField: false}],
        [{label: 'Internal Description', id: 'internalDescription', type: 'textarea', rows: 5, editable: 'true', isExpertField: false}],
        [{label: 'Actions', id: 'configuration', type: 'json', rows: 5, editable: 'true', isExpertField: false, required: true, }],
    ],

    DETAILS_SECTION_TIME: [
        [
            {label: "Start & End Time", id1: 'defaultStartTime', id2: 'defaultEndTime', type: 'timerange', editable: 'true', required: true, },
            {label: "Weekdays", id: 'defaultWeekdays', type: 'booleanArray', editable: 'true', allValues: WEEKDAY_ITEMS, inline: false},
            {label: "Fixed Date", id: 'defaultFixedDate', type: 'date', editable: 'true'},
        ],
    ],

    DETAILS_SECTION_ACCOUNT: [
        [
            {label: "Account ID", id: 'ownerAccountId', type: 'number', editable: 'true'},
            {label: "Profile ID", id: 'ownerProfileId', type: 'number', editable: 'true'}
        ],
    ],


    //Attributes
    ROUTINE_TYPES: ROUTINE_TYPE_ITEMS,
    STATES: STATE_ITEMS,
    PRIVACY_TYPES: PRIVACY_ITEMS,
    ROUTINE_COLLECTION_TYPE: ["", 'DEFAULT_COLLECTION', 'FREE_DEFAULT_COLLECTION'],

    //Sorting
    ROUTINE_SORT_PARAMS: [{id: 'id', label: 'Routine ID'}, {id: 'name', label: 'Name'}, {id: 'ageRecommended', label: 'Age'}],
};

export default RoutineMetaData;