import React, {useState} from "react";
import FormSelect from "react-bootstrap/FormSelect";
import Row from 'react-bootstrap/Row';
import CSVReader from "react-csv-reader";
import {AiOutlineWarning, FiArrowRight, MdOutlineCheckCircle} from "react-icons/all";
import {InfoModal} from "../../../generators/ModalGenerator";
import {Button, Col, Form, InputGroup, Table} from "react-bootstrap";
import {
    GenerateCouponCodes,
    GenerateDTTCodes,
    TriggerTicketDistribution
} from "../../../resources/tigertickets/TigerticketService";
import GlobalConstants from "../../../config/GlobalConstants";
import {openExternalTargetInNewTab} from "../../../util/ResourceService";
import TigerticketMetaData from "../../../resources/tigertickets/TigerticketMetaData";
import {
    GetBillwerkProductsByType,
    GetCouponVariantPage
} from "../../../purchases/premiumAccessTypes/PremiumAccessTypeService";
import {Label} from "reactstrap";

const papaparseOptions = {
    header: false,
    dynamicTyping: true,
    skipEmptyLines: true,
    transformHeader: header => header.toLowerCase().replace(/\W/g, "_")
};

const ERROR_TABLE_IS_EMPTY = "Fehler: Die hochgeladene Tabelle ist leer";
const ERROR_TOO_MANY_COLUMNS = "Fehler: Die hochgeladene Tabelle hat mehr als eine Spalte";
const ERROR_WRONG_PATTERN = " E-Mail-Adressen haben nicht die erwartete Form xx@yy.zz. Klicke auf den Button, um die entsprechenden Einträge aufzulisten. Es kann fortgefahren, aber nicht gewährleistet werden, dass der Prozess vollständig durchläuft."
const ERROR_DURING_CREATION = "Beim Generieren der Codes ist ein Fehler aufgetreten. Bitte schaue in der Console nach weiteren Details.";
const MEASURED_GENERATION_DURATION_PER_CODE = 0.009;


export function DTTMassGeneratorDialog(props) {

    //File & Upload
    let [uploaded, setUploaded] = useState(false);

    //Data
    let [amount, setAmount] = useState(1);
    let [option, setOption] = useState("email");
    let [emails, setEmails] = useState([]);
    let [coupons, setCoupons] = useState([]);
    let [couponVariants, setCouponVariants] = useState([]);
    let [selectedCouponVariantId, setSelectedCouponVariantId] = useState(null);
    let [selectedProductId, setSelectedProductId] = useState(null);
    let [templateId, setTemplateId] = useState(null);
    let [couponUrl, setCouponUrl] = useState("https://tiger.media/premium/tigerticket/");
    let [campaign, setCampaign] = useState("OTHER");
    let [pickTemplate, setPickTemplate] = useState(true);

    let [generatedCodes, setGeneratedCodes] = useState([]);

    //Tabbing
    let [tab, setTab] = useState(1);

    //Error handling & Validation
    let [processing, setProcessing] = useState(false);
    let [invalidMails, setInvalidMails] = useState([]);
    let [errorMessage, setErrorMessage] = useState("");

    //Dialogs
    let [showEmailsDialog, setShowEmailsDialog] = useState(false);
    let [showInvalidMailsDialog, setShowInvalidMailsDialog] = useState(false);
    let [showGeneratedCodesDialog, setShowGeneratedCodesDialog] = useState(false);

    const handleUpload = (emails, fileInfo) => {
        setProcessing(true);
        setEmails(emails);
        setUploaded(true);
        validateUploadedData(emails, setErrorMessage, setInvalidMails, setProcessing);
    }

    return (
        <>
            <p>
                <span style={{fontWeight: tab === 1 ? "bold" : "normal", cursor: "pointer"}} onClick={() => setTab(1)}>Schritt 1: Vorbereitung</span>&#xA0;
                <FiArrowRight/>&#xA0;
                <span style={{
                    fontWeight: tab === 2 ? "bold" : "normal",
                    cursor: (uploaded && !processing) ? "pointer" : "not-allowed"
                }}
                      onClick={() => {
                          if (uploaded) {
                              setTab(2)
                          }
                      }}>Schritt 2: Generierung der Codes</span>&#xA0;<FiArrowRight/>&#xA0;
                <span style={{
                    fontWeight: tab === 3 ? "bold" : "normal",
                    cursor: (uploaded && !processing && selectedProductId != null) ? "pointer" : "not-allowed"
                }} onClick={() => {
                    if (uploaded && !processing && selectedProductId != null) {
                        setTab(3)
                    }
                }}>Schritt 3 (Optional): Versand der Mails</span>
            </p>
            <hr/>
            {tab === 1 &&
            <>
                <h5><input type="checkbox" checked={option === "email"} onChange={() => setOption("email")} style={{width: "20px"}}/> Option A)</h5>
                <p style={{fontFamily: "Mikado"}}>
                    Wähle eine .csv-Datei zum Upload aus. Die .csv-Datei sollte nur eine Spalte haben, in der die
                    E-Mail-Adressen stehen und keinen Header besitzen:
                </p>
                <CSVReader cssClass="react-csv-input" onFileLoaded={handleUpload} parserOptions={papaparseOptions}/>
                {errorMessage !== "" &&
                <div style={{fontFamily: "Mikado", textAlign: "center"}}>
                    <span style={{color: 'red', textAlign: "center"}}>{errorMessage}</span>
                </div>
                }
                <div style={{marginTop: "30px"}}>
                    {((errorMessage === "" || errorMessage.indexOf(ERROR_WRONG_PATTERN) > 0) && uploaded) &&
                    <button
                        className={(uploaded && !processing) ? "form-btn-ci-light-blue" : "form-btn-ci-off"}
                        onClick={() => setShowEmailsDialog(true)}>{(uploaded && !processing) ? "Hochgeladene Einträge: " + emails.length : (uploaded && processing) ? "Daten werden verarbeitet" : "Nichts hochgeladen"}</button>
                    }
                    {(errorMessage.indexOf(ERROR_WRONG_PATTERN) > 0 && uploaded) &&
                    <button className="form-btn-ci-light" onClick={() => setShowInvalidMailsDialog(true)}>Zeige
                        fehlerhafte E-Mails</button>
                    }

                </div>
                <hr />
                <h5><input type="checkbox" checked={option === "amount"} onChange={() => setOption("amount")} style={{width: "20px"}}/> Option B)</h5>
                <p style={{fontFamily: "Mikado"}}>
                    Alternativ kann eine beliebige Anzahl von zu generierenden Tickets angegeben werden. Diese Tickets sind dann nicht an ein Konto gebunden.
                </p>
                <Form>
                    <Row>
                        <Form.Group as={Col}>
                            <Label>Gewünschte Anzahl:</Label>
                            <Form.Control type="number" value={amount} onChange={(e) => setAmount(Number(e.target.value))} disabled={option === "email"}/>
                        </Form.Group>
                    </Row>
                </Form>
                <div style={{float: "right"}}>
                    <button className={(uploaded && !processing) ? "form-btn-ci-red" : "form-btn-ci-off"}
                            disabled={!uploaded || processing}
                            onClick={() => {
                                setEmails([]);
                                setUploaded(false);
                                setErrorMessage("");
                                setInvalidMails([]);
                            }
                            }>Reset
                    </button>
                    <button className={((uploaded && !processing) || option === "amount") ? "form-btn-ci-blue" : "form-btn-ci-off"}
                            disabled={(!uploaded && option === "email") || processing}
                            onClick={() => {
                                setTab(2);
                                setProcessing(true);
                                setEmails(option === "email" ? emails.map(e => e[0]) : Array.apply(null, Array(amount)).map(function (x,i) {
                                    return null;
                                }));
                                loadCoupons(setCoupons, setSelectedProductId).then(() => setProcessing(false));
                            }}>Weiter
                    </button>
                </div>

            </>
            }

            {tab === 2 &&
            <>
                <p style={{fontFamily: "Mikado"}}>
                    Wähle einen Coupon/ein Billwerk-Produkt aus, das für die Code-Generierung verwendet werden soll.
                    Dieses Produkt wird dann für alle Tickets verwendet:
                </p>
                {(processing && coupons.length === 0) ?
                    <div style={{textAlign: "center", fontStyle: "italic"}}>
                        Produkte werden geladen, dies kann einen Moment dauern...
                    </div>
                    :
                    <Form>
                        <Row>
                            <Form.Group as={Col}>
                                <FormSelect style={{cursor: "pointer", backgroundColor: '#E3E3E3'}}
                                              onChange={(e) => {
                                                  setSelectedProductId(e.target.value);
                                                  const matchingCoupon = coupons.filter(c => Number(c.id) === Number(e.target.value))[0];
                                                  if (matchingCoupon.type === "COUPON") {
                                                      loadCouponVariants(setCouponVariants, setSelectedCouponVariantId).then(r => r);
                                                  } else {
                                                      setCouponVariants([]);
                                                      setSelectedCouponVariantId(null);
                                                  }
                                              }}>
                                    {coupons.map(cp => (
                                        <option value={cp.id}>ID: {cp.id} - {cp.billwerkName}</option>
                                    ))}
                                </FormSelect>
                            </Form.Group>
                        </Row>
                        {selectedCouponVariantId != null &&
                        <Row>
                            <Form.Group as={Col}>
                                <Label>Coupon Variant</Label>
                                <FormSelect style={{cursor: "pointer", backgroundColor: '#E3E3E3'}}
                                              onChange={(e) => setSelectedCouponVariantId(e.target.value)}>
                                    {couponVariants.map(cp => (
                                        <option value={cp.id}>ID: {cp.id} - {cp.name}</option>
                                    ))}
                                </FormSelect>
                            </Form.Group>
                        </Row>
                        }
                    </Form>
                }
                {errorMessage !== "" &&
                <div style={{marginTop: "10px", textAlign: "center", color: 'red'}}>
                    {errorMessage}
                </div>
                }
                {generatedCodes.length === 0 && errorMessage === "" &&
                <div style={{marginTop: "10px", textAlign: "center"}}>
                    Geschätzte benötigte Zeit zur Generierung
                    von {emails.length} Codes: {Math.round((emails.length * MEASURED_GENERATION_DURATION_PER_CODE) * 100) / 100}s
                </div>
                }
                <div style={{marginTop: "30px", marginLeft: "40%"}}>
                    <button className={selectedProductId != null ? "form-btn-ci-light-blue" : "form-btn-ci-off"}
                            onClick={() => {
                                if (generatedCodes.length === 0) {
                                    setProcessing(true);
                                    generateCodes(selectedProductId, emails, setGeneratedCodes, setErrorMessage, coupons, selectedCouponVariantId).then(r => setProcessing(false));
                                } else {
                                    setShowGeneratedCodesDialog(true);
                                }

                            }}
                            disabled={selectedProductId == null}>{(processing && generatedCodes.length === 0 && coupons.length > 0) ? "Codes werden generiert..." : (!processing && generatedCodes.length > 0) ? "Öffne generierte Codes" : "Starte Generierung"}
                    </button>
                </div>
                <div style={{float: "right"}}>
                    <button
                        className={(generatedCodes.length > 0 && !processing) ? "form-btn-ci-blue" : "form-btn-ci-off"}
                        disabled={generatedCodes.length === 0 || processing}
                        onClick={() => {
                            setTab(3);
                            setTemplateId(GlobalConstants.MAILJET_TIGERTICKET_MAIL_TEMPLATES[0]);
                        }}>Weiter
                    </button>
                </div>

            </>
            }

            {tab === 3 &&
            <>
                <Form>
                    <Row>
                        <Form.Control style={{fontFamily: "Mikado", marginLeft: "5px"}} plaintext readOnly value="1: Wähle ein Mailjet-Template aus oder gib manuell eine ID an, das für den Versand der E-Mails verwendet werden soll." />
                    </Row>
                    <Row>
                        <Form.Group as={Col}>
                            <InputGroup>
                                {pickTemplate ?
                                    <FormSelect style={{cursor: "pointer", backgroundColor: '#E3E3E3'}}
                                                  onChange={(e) => setTemplateId(e.target.value)}>
                                        {GlobalConstants.MAILJET_TIGERTICKET_MAIL_TEMPLATES.map(template => (
                                            <option value={template}>{template}</option>
                                        ))}
                                    </FormSelect>
                                    :
                                    <Form.Control type="number" style={{backgroundColor: '#E3E3E3'}}
                                                  onChange={(e) => setTemplateId(e.target.value)}/>

                                }
                                <Button variant="outline-secondary" onClick={() => setPickTemplate(!pickTemplate)}>{pickTemplate ? "Eingabe" : "Auswahl"}</Button>
                                <Button variant="secondary" onClick={() => openExternalTargetInNewTab("https://app.mailjet.com/template/" + templateId + "/version-history")}>Vorschau</Button>
                            </InputGroup>
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Control style={{fontFamily: "Mikado", marginLeft: "5px"}} plaintext readOnly value="2: Gib die Ziel-Url/Einlöseseite an, die über den Einlösebutton geöffnet werden soll (falls vorhanden)" />
                    </Row>
                    <Row>
                        <Form.Group as={Col}>
                            <Form.Control type="text" onChange={(e) => setCouponUrl(e.target.value)} value={couponUrl} />
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Control style={{fontFamily: "Mikado", marginLeft: "5px"}} plaintext readOnly value="3: Wähle die Kampagne aus, in dessen Rahmen die Mails verschickt werden (Optional)" />
                    </Row>
                    <Row>
                        <Form.Group as={Col}>
                            <FormSelect style={{cursor: "pointer", backgroundColor: "#E3E3E3"}} onChange={(e) => setCampaign(e.target.value)}>
                                {TigerticketMetaData.TIGERTICKET_DISTRIBUTION_CAMPAIGNS.map(cam => (
                                    <option value={cam.id}>{cam.label}</option>
                                ))}
                            </FormSelect>
                        </Form.Group>
                    </Row>
                </Form>
                <div style={{marginTop: "30px", marginLeft: "35%"}}>
                    <button className="form-btn-ci-light-blue"
                            onClick={() => {
                                startMailingProcess(generatedCodes, templateId, campaign, couponUrl, props.onSuccess, props.onError).then(r => r);
                                props.finishProcess();
                            }}
                            >Starte den Versand & Beende den Prozess
                    </button>
                </div>
            </>
            }

            <InfoModal show={showEmailsDialog}
                       onHide={() => setShowEmailsDialog(false)}
                       title={"Hochgeladene E-Mail-Adressen"}
                       body={<UploadedEmailsTable emails={emails} invalidEmails={invalidMails}/>}/>

            <InfoModal show={showInvalidMailsDialog}
                       onHide={() => setShowInvalidMailsDialog(false)}
                       title={"Hochgeladene E-Mail-Adressen mit eventuellen Fehlern"}
                       body={<InvalidEmailsTable invalidEmails={invalidMails}/>}/>

            <InfoModal show={showGeneratedCodesDialog}
                       onHide={() => setShowGeneratedCodesDialog(false)}
                       title={"Generierte DTT-Codes"}
                       body={<GeneratedCodesTable generatedCodes={generatedCodes}/>}/>

        </>
    );
}

async function loadCoupons(setCoupons, setSelectedProductId) {
    let loadedDigitickets = await GetBillwerkProductsByType("DIGITICKET", 0, 200);
    if (!loadedDigitickets.error) {
        let coupons = loadedDigitickets.result;
        let loadedCoupons = await GetBillwerkProductsByType("COUPON", 0, 200);
        if (!loadedCoupons.error) {
            coupons = coupons.concat(loadedCoupons.result);
        }
        setCoupons(coupons);
        setSelectedProductId(coupons[0].id != null ? coupons[0].id : null);
    }
}

async function loadCouponVariants(setCouponVariants, setSelectedCouponVariantId) {
    let loadedVariants = await GetCouponVariantPage(0);
    if (!loadedVariants.error) {
        setCouponVariants(loadedVariants.result);
        setSelectedCouponVariantId(loadedVariants.result.length > 0 ? loadedVariants.result[0].id : null);
    }
}

async function generateCodes(selectedProductId, emails, setGeneratedCodes, setErrorMessage, coupons, selectedCouponVariantId) {
    const matchingCoupon = coupons.filter(c => Number(c.id) === Number(selectedProductId))[0];
    let response = {};
    if (matchingCoupon.type === "DIGITICKET") {
        response = await GenerateDTTCodes(Number(selectedProductId), emails);
    } else {
        response = await GenerateCouponCodes(Number(selectedProductId), selectedCouponVariantId, 1);
    }
    if (!response.error) {
        if (response.result.length === 0) {
            //Error occurred.
            setErrorMessage(ERROR_DURING_CREATION);
        } else {
            let generatedCodes = response.result;
            generatedCodes.forEach(code => {
                code.coupon = matchingCoupon;
            })
            setGeneratedCodes(generatedCodes);

            setErrorMessage("");
        }
    } else {
        setErrorMessage(ERROR_DURING_CREATION);
    }
}

async function startMailingProcess(generatedCodes, templateId, campaign, couponUrl, onSuccess, onError) {
    let response = await TriggerTicketDistribution(generatedCodes, templateId, campaign, couponUrl);
    if(!response.error) {
        onSuccess();
    } else {
        onError();
    }
}

function validateUploadedData(emails, setErrorMessage, setInvalidEmails, setProcessing) {
    let invalidMails = [];

    //1. Check -> Is there any data?
    if(emails.length === 0) {
        setInvalidEmails([]);
        setErrorMessage(ERROR_TABLE_IS_EMPTY);
        setProcessing(false);
        return;
    }

    //2. Check -> Are there more than one columns?
    if(emails[0].length > 1) {
        setInvalidEmails([]);
        setErrorMessage(ERROR_TOO_MANY_COLUMNS);
        setProcessing(false);
        return;
    }

    //3. Check -> Pattern
    for(let i = 0; i < emails.length; i++) {
        //Check if the address has an "@" symbol and if the postfix (= substring after the "@") has an dot (i.e. gmail.com instead of gmailcom)
        let indexOfAt = emails[i][0].indexOf("@");
        let postfix = emails[i][0].substring(indexOfAt, emails[i][0].length);
        let indexOfTailingDot = postfix.indexOf(".");
        if(indexOfAt < 0 || indexOfTailingDot < 0) {
            invalidMails.push(emails[i][0]);
        }
    }
    setInvalidEmails(invalidMails);
    if(invalidMails.length > 0) {
        setErrorMessage("Fehler: " + invalidMails.length + ERROR_WRONG_PATTERN);
    }
    setProcessing(false);
}

function UploadedEmailsTable(props) {
    return(
        <>
            <Table responsive hover bordered striped>
                <thead>
                    <tr>
                        <th>E-Mail-Adresse</th>
                        <th>Validierung</th>
                    </tr>
                </thead>
                <tbody>
                {props.emails.map(email => (
                    <tr role="row" key={email}>
                        <td>{email[0]}</td>
                        <td style={{color: props.invalidEmails.indexOf(email[0]) > - 1 ? "red" : "#333"}}>
                            {props.invalidEmails.indexOf(email[0]) > -1 ? <AiOutlineWarning /> : <MdOutlineCheckCircle />}
                        </td>
                    </tr>
                ))}
                </tbody>
            </Table>
        </>
    );
}

function InvalidEmailsTable(props) {
    return (
        <>
            <Table responsive hover bordered striped>
                <thead>
                <tr>
                    <th>E-Mail-Adresse</th>
                </tr>
                </thead>
                <tbody>
                {props.invalidEmails.map(email => (
                    <tr role="row" key={email}>
                        <td>{email}</td>
                    </tr>
                ))}
                </tbody>
            </Table>
        </>
    );
}

function GeneratedCodesTable(props) {
    return(
        <>
            <Table responsive hover bordered striped>
                <thead>
                <tr>
                    <th>Konto-ID</th>
                    <th>E-Mail-Adresse</th>
                    <th>Code</th>
                    <th>PIN</th>
                </tr>
                </thead>
                <tbody>
                {props.generatedCodes.map(code => (
                    <tr role="row" key={code}>
                        <td>{code.createdByAccountId}</td>
                        <td>{code.createdByEmail}</td>
                        <td>{code.code}</td>
                        <td>{code.pin}</td>
                    </tr>
                ))}
                </tbody>
            </Table>
        </>
    );
}