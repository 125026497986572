import {
    CreateSpineFormDataResource,
    DeleteSpineResource,
    GetFilteredSpineResources,
    GetSpineResource,
    UpdateSpineResource
} from "../../clients/SpineClient";
import GlobalConstants from "../../config/GlobalConstants";

export async function CreateAsset(asset) {
    return await CreateSpineFormDataResource('api/assets', asset);
}

export async function GetAsset(id) {
    return await GetSpineResource('api/assets/' + id);
}

export async function GetFilteredAssets(filter) {
    return await GetFilteredSpineResources('api/assets/', filter);
}

export async function UpdateAsset(asset) {
    return await UpdateSpineResource('api/assets/' + asset.id, asset);
}

export async function DownloadAsset(id) {
    return await GetSpineResource('api/assets/' + id + '/content');
}

export async function DeleteAsset(asset) {
    return await DeleteSpineResource('api/assets/' + asset.id);
}

export function GenerateAssetPreview(asset) {
    let generatedUrl = null;
    let visibility = null;

    //Visibility (required for the amazon bucket)
    if (asset.visibility === "PUBLIC") {
        visibility = ".public/";
    } else if (asset.visibility === "PROTECTED") {
        visibility = ".protected/";
    }

    if (asset.storage === "AMAZON_S3") {
        generatedUrl = GlobalConstants.AMAZONBUCKET_URL + GlobalConstants.ENVIRONMENT + visibility + asset.storageReferenceId;
    } else if (asset.storage === "GOOGLE_CLOUD_STORAGE") {
        //Default prefix of the google storage reference id
        let urlPrefix = "gs://";
        //Check if the current id has the default prefix
        if (!asset.storageReferenceId.includes(urlPrefix)) {
            //No -> Get it
            urlPrefix = asset.storageReferenceId.substring(0, asset.storageReferenceId.indexOf(GlobalConstants.GOOGLESTORAGE_URL));
        }
        //Now replace the prefix by https://
        generatedUrl = asset.storageReferenceId.replace(urlPrefix, "https://");
    }

    return generatedUrl;
}