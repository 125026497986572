import React from "react";
import {Label} from "reactstrap";

//Management Section
export function ManagementSection(props) {

    return (
        <div className="content-box-centered">
            <div className="content-box-title">Management</div>
            <div className="content-box-body">
                <div className="row">
                    {props.content}
                </div>
            </div>
        </div>
    );
}

//Management Box
export function ManagementBox(props) {
    let i = 0;
    return(
        <>
            <div className={"col-md-" + props.width} style={{
                borderRadius: "5px",
                paddingBottom: "10px",
                marginLeft: "15px",
                backgroundColor: "#EAEAEA"
            }}>
                <Label style={{marginBottom: "10px", marginTop: "10px"}}>{props.label}</Label>
                <br/>
                {props.content.map(entry => (
                    <div key={i++} style={{float: "left"}}>
                        {entry}
                    </div>
                ))}
            </div>
        </>
    )
}

//Management Button
export function ManagementButton(props) {
    let enabled = true;
    if(props.enableConditionList != null) {
        enabled = props.enableConditionList.filter(resource => resource.isChecked === true).length > 0;
    }
    return (
        <button
            className={enabled ? "form-btn-ci" : "form-btn-ci-off"}
            type="button"
            disabled={!enabled}
            onClick={props.onClick}>{props.label}
        </button>
    );
}