import {Badge, Button, Col, Form, InputGroup, Table} from "react-bootstrap";
import Row from 'react-bootstrap/Row';
import React, {useState} from "react";
import FormSelect from "react-bootstrap/FormSelect";
import {Label} from "reactstrap";
import {AiOutlineFolderAdd, AiOutlineReload} from "react-icons/all";
import {GenerateAssetPreview, GetFilteredAssets} from "../assets/AssetService";
import {PromiseButton} from "../../global/SpinningTiger";
import {TablePagination} from "../../generators/TableGenerator";

export function PickAssetDialog(props) {

    let [page, setPage] = useState(0);
    let [name, setName] = useState(null);
    let [assets, setAssets] = useState(props.assets);
    let [assetProduct, setAssetProduct] = useState({assetId: null, ordinal: 0, productId: props.productId, assetProductType: "SCREENSHOT"});
    let [selectedAsset, setSelectedAsset] = useState(null);

    const loadAssets = async (page) => {
        const response = await GetFilteredAssets({page: page, query: name});
        if (!response.error) {
            setAssets(response.result);
            setPage(page);
        }
    }

    const getAssetTypeBadge = (asset) => {
        const type = asset != null ? asset.assetType : null;
        if (type == null) {
            return "N/A";
        }
        switch (type.toLowerCase()) {
            case "image":
                return <Badge bg="danger">Image</Badge>;
            case "binary":
                return <Badge bg="info">Binary</Badge>;
            case "audio":
                return <Badge bg="primary">Audio</Badge>;
            case "video":
                return <Badge bg="secondary">Video</Badge>;
            case "hls":
                return <Badge bg="success">HLS</Badge>;
            default:
                return "N/A";
        }
    }

    const getAssetPreview = (asset) => {
        let previewUrl = GenerateAssetPreview(asset);
        if (previewUrl == null) {
            return "No preview available";
        }
        if (asset.assetType === "IMAGE") {
            return <img src={previewUrl} width={150} alt={"Preview of asset " + asset.id} />
        } else {
            return <a href={previewUrl}><Button variant="secondary" type="button">Download</Button></a>
        }
    }

    if (assetProduct.assetId != null) {
        return (
            <div>
                <Form>
                    <Label>Selected Asset</Label>
                    <Row>
                        <br />
                        <Form.Group as={Col}>
                            <Form.Control readOnly={true} value={selectedAsset.id}/>
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Control readOnly={true} value={selectedAsset.name}/>
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Control readOnly={true} value={selectedAsset.assetType}/>
                        </Form.Group>
                    </Row>
                    <Row>
                        <Button variant="outline-secondary" type="button" onClick={() => {
                            setAssetProduct({...assetProduct, assetId: null});
                            setSelectedAsset(null);
                        }}><AiOutlineReload size={20}/>&#xA0;Pick another Asset</Button>
                    </Row>
                    <hr />
                    <Row>
                        <Form.Group as={Col}>
                            <Label>Ordinal</Label>
                            <Form.Control type="number" value={assetProduct.ordinal} onChange={(e) => setAssetProduct({...assetProduct, ordinal: e.target.value})}/>
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group as={Col}>
                            <Label>Type</Label>
                            <FormSelect value={assetProduct.assetProductType}
                                         onChange={(e) => setAssetProduct({...assetProduct, assetProductType: e.target.value})}>
                                <option value="SCREENSHOT">Screenshot</option>
                                <option value="COVER">Cover</option>
                                <option value="COVER_SMALL">Cover (Small)</option>
                                <option value="PREVIEW">Preview</option>
                                <option value="CONTENT">Content</option>
                                <option value="HLS">HLS</option>
                            </FormSelect>
                        </Form.Group>
                    </Row>
                </Form>
                <div>
                    <PromiseButton className="form-btn-ci-blue" text={<><AiOutlineFolderAdd size={20}/>&#xA0;Add Asset to Product</>} onClick={() => props.onCreateAssetProduct(assetProduct)} />
                </div>
            </div>
        )
    }

    return(
        <div>
            <Form>
                <Row>
                    <Form.Group as={Col}>
                        <InputGroup>
                            <Form.Control type="text" placeholder="Search for an asset" value={name} onChange={(e) => setName(e.target.value)} />
                            <PromiseButton className="form-btn-ci-blue" onClick={() => loadAssets(0)} text="Search" />
                        </InputGroup>
                    </Form.Group>
                </Row>
                <Row>
                    <Table responsive bordered striped hover>
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Name</th>
                                <th>Type</th>
                                <th>Public</th>
                                <th>Preview</th>
                            </tr>
                        </thead>
                        <tbody>
                        {assets.map(asset => (
                            <tr role="row" key={asset.id} onClick={() => {
                                setAssetProduct({...assetProduct, assetId: asset.id});
                                setSelectedAsset(asset);
                            }} style={{cursor: "pointer"}}>
                                <td>{asset.id}</td>
                                <td>{asset.name}</td>
                                <td>{getAssetTypeBadge(asset)}</td>
                                <td><Badge bg={asset.visbility === "PUBLIC" ? "success": "danger"}>{asset.visibility === "PUBLIC" ? "Y" : "N"}</Badge></td>
                                <td>{getAssetPreview(asset)}</td>
                            </tr>
                        ))}
                        </tbody>
                    </Table>
                    <TablePagination page={page} lastPage={9999}
                                     onLoad={(p) => loadAssets(p)}/>
                </Row>
            </Form>
        </div>
    );


}