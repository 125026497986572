import {
    GetAllBillResources,
    GetFilteredBillResources,
    CreateBillResource, UpdateBillResource
} from "../../clients/PurchaseClient";
import {CreateSpineResource} from "../../clients/SpineClient";

//TICKETS
export async function GetFilteredTigertickets(filter){
    return await GetFilteredBillResources('api/purchases/tigertickets', filter);
}

export async function GetTigerticketByAccountId(filter) {
    return await GetFilteredBillResources('api/purchases/tigertickets/search/by-account-id', filter);
}

export async function GetTigerticketByCode(code) {
    return await GetFilteredBillResources('api/purchases/tigertickets/search/by-code', {code: code})
}

export async function GetFilteredDigitickets(filter) {
    return await GetFilteredBillResources('api/purchases/digitickets', filter);
}

export async function GetDigiticketByAccountId(filter) {
    return await GetFilteredBillResources('api/purchases/digitickets/search/by-account-id', filter);
}

export async function GetDigiticketByCode(code) {
    return await GetFilteredBillResources('api/purchases/digitickets/search/by-code', {code: code})
}

export async function GetTigerticket(id){
    return await GetFilteredBillResources('api/v1/tickets/' + id);
}

export async function GetTicket(id, code) {
    const url = code.indexOf("DTT-") > - 1 ? "api/purchases/digitickets/" : "api/purchases/tigertickets/";
    return await GetFilteredBillResources(url + id);
}

export async function UpdateTigerticket(ticket) {
    const url = ticket.code.indexOf("DTT-") > - 1 ? "api/purchases/digitickets/" : "api/purchases/tigertickets/";
    return await UpdateBillResource(url + ticket.id, ticket);
}

//COUPONS
export async function GetFilteredCoupons(filter){
    return await GetFilteredBillResources('api/purchases/coupons', filter);
}

export async function GetAllBillwerkProducts(perPage = 20) {
    return await GetAllBillResources('api/purchases/products?per_page=' + perPage);
}

export async function GenerateDTTCodes(productId, emails) {
    return await CreateBillResource('api/purchases/digitickets/bulk-generation', {productId: productId, mailAddresses: emails})
}

export async function GenerateCouponCodes(productId, couponVariantId, amount) {
    return await CreateBillResource('api/purchases/coupons/bulk-generation?productId=' + productId + "&couponVariantId=" + couponVariantId + "&amount=" + amount, null);
}

export async function GetCouponByAccountId(filter) {
    return await GetFilteredBillResources('api/purchases/coupons/search/by-account-id', filter);
}

export async function GetCouponByCode(code) {
    return await GetFilteredBillResources('api/purchases/coupons/search/by-code', {code: code})
}

//Mailjet
export async function TriggerTicketDistribution(tickets, templateId, campaign, couponUrl) {
    return await CreateSpineResource('api/crm/send-distribution-mails', {templateId: templateId, campaign: campaign, couponUrl: couponUrl, tickets: tickets}, false);
}
//---------- OPTIONAL -------------
/*
export async function PatchDigiticket(digiticket, newValue) {
    return await PatchResource('api/digitickets/' + digiticket.id + '/patch', {attribute: newValue});
}
 */