import React, {Component} from "react";
import '../../../App.scss'
import {withToast} from "../../util/ToastService";
import {
    FiSettings,
    GrObjectGroup
} from "react-icons/all";
import {DeleteRoutine, GetFilteredRoutines} from "./RoutineService";
import {ResourcesTable, TableHeader, TableSettingsDialog} from "../../generators/TableGenerator";
import RoutineMetaData from "./RoutineMetaData";
import {ActionModal} from "../../generators/ModalGenerator";
import {SpinningTiger} from "../../global/SpinningTiger";
import {ErrorHandler} from "../../util/ErrorHandler";

class Routines extends Component {

    //CONSTRUCTOR
    constructor(props) {
        super(props);

        //Filter
        this.filter = {page: 0, id: "", name: "", per_page: 20, sortBy: "id", sortDirection: "ASC"}

        //State
        this.state = {
            error: null,
            loading: false,
            routines: [],
            filter: props.location.state != null ? props.location.state.filter : this.filter,
            totalPages: props.location.state != null ? props.location.state.totalPages : 0,
            columns: props.location.state != null ? props.location.state.columns : RoutineMetaData.COLUMNS,
            customColumnData: [
            ],
            showTableSettings: false,
            showDialog: {
                deletionModal: false,
            },
        };

        //Bind the functions
        this.applyFilter = this.applyFilter.bind(this);
    }

    //Load the screen initially
    async componentDidMount() {
        //Set the title
        document.title = "Routines :: Tiger UI";

        await this.loadPage(this.state.filter.page);
    }

    //Load a certain page
    async loadPage(page) {

        this.setState(prevState => ({...prevState, loading: true}));

        let filter = this.state.filter;
        filter.page = page;
        const response = await GetFilteredRoutines(filter);

        if (response.error === true) {
            this.setState(prevState => ({...prevState, error: response}));
        } else {
            response.result.forEach(routine => {
                if (this.state.routines.filter(ser => (ser.isChecked === true)).includes(routine)) {
                    routine.isChecked = true;
                }
            });
        }

        this.setState(prevState => ({
            ...prevState,
            loading: false,
            routines: response.result,
            totalPages: Math.ceil(response.length/this.state.filter.per_page),
            filter: {
                ...prevState.filter,
                page: page}}));

    }

    render() {

        return (
            <div style={{marginBottom: 50}}>
                <div className="content-header">
                    <div className="content-header-title">Routines</div>
                    <hr/>
                    <div className="content-header-description"><GrObjectGroup/>&#xA0;Manage details
                        and items of Routines.
                    </div>
                </div>

                {/* FILTER */}
                <div className="content-box-centered">
                    <div className="content-box-title">Filter</div>
                    <div className="content-box-body">
                        <form onSubmit={this.applyFilter}>
                            <div className="row">
                                <div className="col-md-3">
                                    <label>ID</label>
                                    <input type="text" value={this.state.filter.id}
                                           onChange={(event) => this.setState(prevState => ({
                                               ...prevState,
                                               filter: {
                                                   ...prevState.filter,
                                                   id: event.target.value,
                                               },
                                           }))}/>
                                </div>
                                <div className="col-md-5">
                                    <label>Name</label>
                                    <input type="text" value={this.state.filter.name}
                                           onChange={(event) => this.setState(prevState => ({
                                               ...prevState,
                                               filter: {
                                                   ...prevState.filter,
                                                   name: event.target.value,
                                               },
                                           }))}/>
                                </div>
                            </div>
                            <hr/>
                            <div className="row">
                                <div className="col-md-3" style={{marginLeft: "auto"}}>
                                    <button className="form-btn-ci-light" type="button"
                                            onClick={() => this.setState(prevState => ({...prevState, filter: this.filter}))}>Reset
                                    </button>
                                    <button className="form-btn-ci-blue" type="submit">Apply Filter</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                {/* Routines LIST*/}
                <div className="content-box-centered">
                    <div className="content-box-title">Routine List
                        <div style={{float: "right", fontWeight: "normal", cursor: "pointer"}}
                             onClick={() => this.setState({showTableSettings: true})}><FiSettings/></div>
                    </div>
                    <div className="content-box-body">
                        <TableHeader nameInState="routines"
                                     viewOnly={false}
                                     deletionForbidden={false}
                                     sortParams={RoutineMetaData.ROUTINE_SORT_PARAMS}
                                     state={this.state}
                                     onSetState={(s) => this.setState(s)}
                                     onLoadPage={() => this.loadPage(this.state.filter.page)}/>
                        <hr/>
                        {this.state.loading ?
                            <SpinningTiger />
                        :
                                <ResourcesTable
                                        state={this.state}
                                        columns={this.state.columns}
                                        customcolumndata={[]}
                                        dataset={this.state.routines}

                                        page={this.state.filter.page}
                                        totalPages={this.state.totalPages}
                                        handlePageClick={(event) => this.loadPage(event.selected)}

                                        selectable="true"
                                        resourcesurl='routines'
                                        nameinstate='routines'
                                        onToggleResource={(allRoutines) => this.setState(prevState => ({
                                            ...prevState,
                                            routines: allRoutines
                                        }))}/>
                        }
                    </div>
                </div>

                {/* TABLE SETTINGS */}
                <TableSettingsDialog show={this.state.showTableSettings}
                                     columns={this.state.columns}
                                     onSetState={(columns) => this.setState(prevState => ({...prevState, columns: columns}))}
                                     onHide={() => this.setState({showTableSettings: false})}
                />

                {/* DIALOGS */}
                <ActionModal show={this.state.showDialog.deletionModal}
                             onHide={() => this.setState((prevState) => ({...prevState, showDialog: {...prevState.showDialog, deletionModal: false}}))}
                             onAction={() => this.deleteSelectedRoutine()}
                             actionButtonText="Delete"
                             title={"Delete Routine"}
                             body={"Are you sure you want to delete the selected routine?"}/>


                <ErrorHandler error={this.state.error}
                              onHide={() => this.setState(prevState => ({...prevState, error: null}))} />

            </div>
        );
    }

    toggleRoutine(c) {
        let routines = this.state.routines;
        for (let routine of routines) {
            if (routine.id === c.id) {
                c.isChecked = !c.isChecked;
            }
        }
        this.setState(prevState => ({...prevState, routines: routines}));
    }

    //Filter Handler
    async applyFilter(event) {
        event.preventDefault();

        this.setState(prevState =>({...prevState, routines: []}));
        const loadedRoutines = await GetFilteredRoutines(this.state.filter);

        if (loadedRoutines.error === true) {
            this.setState(prevState => ({...prevState, error: loadedRoutines}));
        } else {
            if (loadedRoutines.result.length === 0) {
                this.props.addToast("No routines match the filter criteria", {
                    autoDismiss: true,
                    appearance: 'warning'
                });
            }
        }

        this.setState(prevState => ({...prevState, routines: loadedRoutines.result}));
    }

    //API Methods
    async deleteSelectedRoutine() {
        this.state.routines
            .filter(routines => routines.isChecked === true)
            .forEach(routine => DeleteRoutine(routine));

        await this.loadPage(this.state.filter.page);

        this.setState(prevState => ({...prevState, showDialog: {...prevState.showDialog, deletionModal: false}}));
    }
}

export default withToast(Routines);