import {
    CreateSpineResource,
    GetFilteredSpineResources,
    GetSpineResource, PatchSpineResource
} from "../../clients/SpineClient";

//API METHODS
export async function CreateOrder(accountId, order) {
    return await CreateSpineResource('api/accounts/' + accountId + '/orders', order);
}

export async function GetFilteredOrders(filter){
    return await GetFilteredSpineResources('api/orders', filter);
}

export async function GetOrder(id) {
    return await GetSpineResource('api/orders/' + id);
}

export async function OverrideOrderFailure(id) {
    return await PatchSpineResource('api/orders/' + id + '/overridefailure');
}

export async function RetryOrder(id) {
    return await PatchSpineResource('api/orders/' + id + '/retry');
}
