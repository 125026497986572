import {Badge, Button, Col, Form, Row, FormCheck, FormControl, InputGroup, Pagination, Table} from "react-bootstrap";
import React, {Component} from "react";
import {withToast} from "../../util/ToastService";
import {Link} from "react-router-dom";
import {
    BiSitemap,
    BiTrash, CgDetailsMore,
    RiCoupon3Line,
} from "react-icons/all";
import GlobalConstants from "../../config/GlobalConstants";
import {
    CouponBulkCreation,
    CreateCouponGroup,
    DeleteCoupon,
    GetCouponGroup,
    GetFilteredCoupons,
    UpdateCouponGroup
} from "./CouponGroupService";
import {DetailsSection} from "../../generators/DetailsGenerator";
import CouponGroupMetaData from "./CouponGroupMetaData";
import {GetFilteredNonAudioProducts} from "../products/ProductService";
import {ActionModal, InfoModal} from "../../generators/ModalGenerator";
import {PromiseButton} from "../../global/SpinningTiger";
import {ErrorHandler} from "../../util/ErrorHandler";

class CouponGroupDetails extends Component {

    //------------
    //Constructor
    //------------

    constructor(props) {
        super(props);

        this.state = {
            error: null,
            flowState: props.location.state,
            id: this.props.match.params.id,
            editMode: {
                active: false,
                expertMode: false,
                autoSave: true,
            },

            couponGroup: {},
            originalCouponGroup: {},

            products: [],
            originalProducts: [],
            availableProducts: [],
            productFilterPage: 1,
            productFilterName: "",

            coupons: [],
            couponFilterPage: 1,
            couponFilterCode: "",
            lastCouponPage: 1,

            bulkCreationPattern: "",
            bulkCreationCount: 1,
            bulkCreationSingleUse: false,

            sections: {
                general: true,
                couponDetails: true,
                products: true,
                coupons: true,
            },
            showProductsDialog: false,
            showBulkCreationDialog: false,
        }
    }

    //---------
    //Mounting
    //---------

    async componentDidMount() {
        //Set the title
        if(this.state.id != null) {
            document.title = "Coupon Group " + this.state.id + " :: Tiger UI";
        } else {
            document.title = "New coupon group :: Tiger UI";
        }

        await this.loadCouponGroup();
        await this.loadProducts();
        await this.loadCoupons(1);
    }

    //--------
    //Loading
    //--------

    async loadCouponGroup() {
        let loadedCouponGroup = {};

        if (this.state.id != null && this.state.id !== "add") {

            loadedCouponGroup = await GetCouponGroup(this.state.id);

            if (!loadedCouponGroup.error) {
                const couponGroup = loadedCouponGroup.result;

                this.setState({
                    id: this.state.id,
                    editMode: this.state.editMode,
                    couponGroup: couponGroup,
                    originalCouponGroup: couponGroup,
                });
            } else {
                this.setState(prevState => ({...prevState, error: loadedCouponGroup}));
            }
        } else {
            this.setState((prevState) => ({
                ...prevState,
                id: null,
                couponGroup: {
                    id: null,
                    productIds: [],
                },
                editMode: {
                    active: true,
                    expertMode: false,
                    autoSave: false,
                }
            }));
        }
    }

    async loadProducts() {
        if (this.state.id != null && this.state.id !== "add") {
            let loadedProducts = await GetFilteredNonAudioProducts({productId: this.state.couponGroup.productIds});

            if (!loadedProducts.error) {
                this.setState(prevState => ({
                    ...prevState,
                    products: loadedProducts.result,
                    originalProducts: loadedProducts.result
                }));
            } else {
                this.setState(prevState => ({...prevState, error: loadedProducts}));
            }
        }
    }

    async loadCoupons(page) {
        if (this.state.id != null && this.state.id !== "add") {
            let loadedCoupons = await GetFilteredCoupons(this.state.id, {
                page: page,
                code: this.state.couponFilterCode
            });

            if (!loadedCoupons.error) {
                this.setState(prevState => ({
                    ...prevState,
                    coupons: loadedCoupons.result,
                    couponFilterPage: page,
                    lastCouponPage: Math.ceil(loadedCoupons.length / 20)
                }));
            } else {
                this.setState(prevState => ({...prevState, error: loadedCoupons}));
            }
        }
    }

    //----------
    //Rendering
    //----------

    render() {

        return (
            <>
                <div className="details-title">
                    {this.state.couponGroup.id != null ? "Details of Coupon Group " + this.state.couponGroup.id : "Create a new CouponGroup"}
                </div>
                <div className="details-button-box" style={{height: "70px"}}>
                    <Link to={{pathname: "/" + GlobalConstants.APP_PATH + "couponGroups", state: this.state.flowState}}>
                        <button className="form-btn-ci-light-blue" type="button">Back</button>
                    </Link>
                    {this.state.couponGroup.id != null &&
                    <button className={this.state.editMode.active ? "form-btn-ci-red" : "form-btn-ci-blue"}
                            type="button" onClick={() => this.setState({
                        editMode: {
                            active: !this.state.editMode.active,
                            expertMode: this.state.editMode.expertMode
                        },
                        couponGroup: this.state.originalCouponGroup,
                        products: this.state.originalProducts
                    })}>{this.state.editMode.active ? "Cancel" : "Edit"}</button>
                    }
                    {this.state.editMode.active &&
                        <PromiseButton text="Save" onClick={() => this.saveOrUpdateCouponGroup()} />
                    }
                    <Button style={{float: "right", marginTop: "5px", marginRight: "10px"}} variant={this.state.editMode.autoSave ? "secondary": "outline-secondary"}
                            onClick={() => this.setState(prevState => ({
                                ...prevState,
                                editMode: {
                                    ...prevState.editMode,
                                    autoSave: !this.state.editMode.autoSave
                                }
                            }))}>Auto Save</Button>
                </div>

                <div className="details-box-title">Resource Details</div>
                <div className="details-box">
                    {/* GENERAL DATA */}
                    <DetailsSection
                        nameInState="couponGroup"
                        fields={CouponGroupMetaData.DETAILS_GENERAL}
                        state={this.state}
                        onSetState={(s) => this.setState(s)}
                        onUpdateResource={() => this.saveOrUpdateCouponGroup()}
                        sectionId="general"
                    />

                    {/* Coupon Details*/}
                    <DetailsSection
                        nameInState="couponGroup"
                        fields={CouponGroupMetaData.COUPON_DETAILS}
                        state={this.state}
                        onSetState={(s) => this.setState(s)}
                        sectionId="couponDetails"
                        onUpdateResource={() => this.saveOrUpdateCouponGroup()}
                        label={<span><CgDetailsMore/>&#xA0;Coupon Group Details</span>}
                    />

                    {this.state.sections.couponDetails &&
                    <>
                        <Form>
                            <Row>
                                <Form.Group as={Col} controlId="sharedPseudoAmountCoupon">
                                    <FormCheck id="sharedPseudoAmountCoupon" type="switch"
                                               style={{zIndex: "0"}}
                                               label="Shared Pseudo Amount Coupon"
                                               checked={this.state.couponGroup.sharedPseudoAmountCoupon}
                                               onChange={() => this.setState(prevState => ({
                                                   couponGroup: {
                                                       ...prevState.couponGroup,
                                                       sharedPseudoAmountCoupon: !this.state.couponGroup.sharedPseudoAmountCoupon
                                                   }
                                               }))}
                                               readOnly={!this.state.editMode.active}/>
                                </Form.Group>
                            </Row>
                        </Form>
                    </>
                    }

                    {/* PRODUCTS */}

                    <DetailsSection
                        nameInState="couponGroup"
                        fields={[]}
                        state={this.state}
                        onSetState={(s) => this.setState(s)}
                        sectionId="products"
                        label={<span><BiSitemap/>&#xA0;Products</span>}
                    />

                    {this.state.sections.products &&
                    <>
                        {this.state.editMode.active &&
                        <button className="form-btn-ci-blue" style={{marginBottom: "10px"}}
                                onClick={() => {
                                    this.setState(prevState => ({...prevState, showProductsDialog: true}));
                                    this.loadAvailableProducts(1).then(r => r);
                                }}>Add product(s)</button>
                        }
                        <Table responsive bordered hover striped>
                            <thead>
                            <tr>
                                <th>ID</th>
                                <th>Product Type</th>
                                <th>State</th>
                                <th>Title</th>
                                {this.state.editMode.active &&
                                <th>Remove</th>
                                }
                            </tr>
                            </thead>
                            <tbody>
                            {this.state.products.map(product => (
                                <tr role="row" key={product.id} style={{cursor: "pointer"}}>
                                    <td><a style={{fontStyle: "italic", color: "#333"}}
                                           href={GlobalConstants.SPINE_CLIENT_HOST + GlobalConstants.APP_PATH + "products/" + product.id}>{product.id}</a>
                                    </td>
                                    <td><Badge bg={
                                        product.productType === 'TIGERBOOK' ? "primary" :
                                            product.productType === 'MOVIE' ? "info" :
                                                product.productType === 'AUDIOBOOK' ? "light" :
                                                    product.productType === 'BOOK' ? "success" : "dark"
                                    }>{product.productType}</Badge></td>
                                    <td><Badge bg={
                                        product.state === 'NEW' ? "primary" :
                                            product.state === 'ACTIVE' ? "success" :
                                                product.state === 'INACTIVE' ? "warning" : "danger"
                                    }>{product.state}</Badge></td>
                                    <td>{product.title}</td>
                                    {this.state.editMode.active &&
                                    <td>
                                        <button className="form-btn-ci-red"
                                                onClick={() => this.removeProductFromCouponGroup(product)}><BiTrash/>
                                        </button>
                                    </td>
                                    }
                                </tr>
                            ))}
                            </tbody>
                        </Table>
                    </>
                    }

                    {/* COUPONS */}
                    <DetailsSection
                        nameInState="couponGroup"
                        fields={[]}
                        state={this.state}
                        onSetState={(s) => this.setState(s)}
                        sectionId="coupons"
                        label={<span><RiCoupon3Line/>&#xA0;Coupons</span>}
                    />

                    {this.state.sections.coupons &&
                    <>
                        <form style={{marginBottom: "20px"}}>
                            <div className="row">
                                <div className="col-md-3">
                                    <label>Code</label>
                                    <input type="text" value={this.state.couponFilterCode}
                                           onChange={(e) => this.setState(prevState => ({
                                               ...prevState,
                                               couponFilterCode: e.target.value
                                           }))}/>
                                </div>
                                <div className="col-md-4" style={{marginTop: "40px"}}>
                                    <button className="form-btn-ci-blue" type="button"
                                            onClick={() => this.loadCoupons(1)}>Apply
                                    </button>
                                    <button className="form-btn-ci-light" type="button"
                                            onClick={() => this.setState(prevState => ({
                                                ...prevState,
                                                couponFilterCode: "",
                                                couponFilterPage: 1
                                            }))}>Reset
                                    </button>
                                </div>
                            </div>
                        </form>

                        {this.state.editMode.active &&
                        <>
                            <hr/>
                            <Link
                                to={"/" + GlobalConstants.APP_PATH + "couponGroups/" + this.state.couponGroup.id + "/coupons/add"}>
                                <button
                                    className={this.state.couponGroup.id == null ? "form-btn-ci-off" : "form-btn-ci-blue"}
                                    disabled={this.state.couponGroup.id == null}
                                    style={{marginBottom: "10px"}}>Create single coupon
                                </button>
                            </Link>
                            <button
                                className={this.state.couponGroup.id == null ? "form-btn-ci-off" : "form-btn-ci-blue"}
                                disabled={this.state.couponGroup.id == null}
                                style={{marginBottom: "10px"}}
                                onClick={() => this.setState(prevState => ({...prevState, showBulkCreationDialog: true}))}>Create multiple coupons
                            </button>
                        </>
                        }

                        <Table responsive bordered hover striped>
                            <thead>
                            <tr>
                                <th>ID</th>
                                <th>Code</th>
                                <th>Usage Limit</th>
                                <th>Usage</th>
                                <th>Usable</th>
                                <th>Last Usage Date</th>
                                {this.state.editMode.active &&
                                <th>Remove</th>
                                }
                            </tr>
                            </thead>
                            <tbody>
                            {this.state.coupons.map(coupon => (
                                <tr role="row" key={coupon.id} style={{cursor: "pointer"}}>
                                    <td><a
                                        href={GlobalConstants.SPINE_CLIENT_HOST + GlobalConstants.APP_PATH + "couponGroups/" + this.state.id + "/coupons/" + coupon.id}
                                        style={{fontStyle: "italic", color: "#333"}}>{coupon.id}</a></td>
                                    <td>{coupon.code}</td>
                                    <td>{coupon.usageLimit}</td>
                                    <td>{coupon.usage}</td>
                                    <td>
                                        <Badge bg={coupon.usable ? "success" : "danger"}>{coupon.usable ? "Y" : "N"}</Badge>
                                    </td>
                                    <td>{coupon.lastUsageDate}</td>
                                    {this.state.editMode.active &&
                                    <td>
                                        <button className="form-btn-ci-red" onClick={() => this.deleteCoupon(coupon)}>
                                            <BiTrash/></button>
                                    </td>
                                    }
                                </tr>
                            ))}
                            </tbody>
                        </Table>
                        <Pagination>
                            <Pagination.Item hidden={this.state.couponFilterPage === 1}
                                             onClick={() => this.loadCoupons(1)}>&lt;&lt;</Pagination.Item>
                            <Pagination.Item hidden={this.state.couponFilterPage === 1}
                                             onClick={() => this.loadCoupons(this.state.couponFilterPage - 1)}>&lt;</Pagination.Item>
                            <Pagination.Item hidden={this.state.couponFilterPage <= 2}
                                             onClick={() => this.loadCoupons(this.state.couponFilterPage - 2)}>{this.state.couponFilterPage - 2}</Pagination.Item>
                            <Pagination.Item hidden={this.state.couponFilterPage === 1}
                                             onClick={() => this.loadCoupons(this.state.couponFilterPage - 1)}>{this.state.couponFilterPage - 1}</Pagination.Item>
                            <Pagination.Item active={true}>{this.state.couponFilterPage}</Pagination.Item>
                            <Pagination.Item hidden={this.state.couponFilterPage === this.state.lastCouponPage}
                                             onClick={() => this.loadCoupons(this.state.couponFilterPage + 1)}>{this.state.couponFilterPage + 1}</Pagination.Item>
                            <Pagination.Item hidden={this.state.couponFilterPage >= this.state.lastCouponPage - 1}
                                             onClick={() => this.loadCoupons(this.state.couponFilterPage + 2)}>{this.state.couponFilterPage + 2}</Pagination.Item>
                            <Pagination.Item hidden={this.state.couponFilterPage === this.state.lastCouponPage}
                                             onClick={() => this.loadCoupons(this.state.couponFilterPage + 1)}>&gt;</Pagination.Item>
                            <Pagination.Item hidden={this.state.couponFilterPage === this.state.lastCouponPage}
                                             onClick={() => this.loadCoupons(this.state.lastCouponPage)}>&gt;&gt;</Pagination.Item>
                        </Pagination>
                    </>
                    }

                </div>
                <div className="details-button-box" style={{height: "70px"}}>
                    <Link to={{pathname: "/" + GlobalConstants.APP_PATH + "couponGroups", state: this.state.flowState}}>
                        <button className="form-btn-ci-light-blue" type="button">Back</button>
                    </Link>
                    {this.state.editMode.active &&
                    <PromiseButton text="Save" onClick={() => this.saveOrUpdateCouponGroup()} />
                    }
                </div>

                <InfoModal show={this.state.showProductsDialog}
                           onHide={() => this.setState(prevState => ({...prevState, showProductsDialog: false}))}
                           title={"Select one or more products to add"}
                           body={this.pickProductsDialog()}/>

                <ActionModal show={this.state.showBulkCreationDialog}
                             onHide={() => this.setState(prevState => ({...prevState, showBulkCreationDialog: false}))}
                             onAction={() => this.performBulkCreation()}
                             actionButtonText="Create"
                             title={"Coupon Bulk Creation"}
                             body={this.bulkCreationDialog()}/>

                <ErrorHandler error={this.state.error}
                              onHide={() => this.setState(prevState => ({...prevState, error: null}))} />
            </>
        );
    }

    //------------
    //API Methods
    //------------

    async saveOrUpdateCouponGroup() {
        let couponGroup = {};
        if (this.state.couponGroup.id != null) {
            couponGroup = await UpdateCouponGroup(this.state.couponGroup);
        } else {
            couponGroup = await CreateCouponGroup(this.state.couponGroup);
        }

        if (!couponGroup.error) {
            if (this.state.couponGroup.id == null) {
                this.props.history.push("/"+ GlobalConstants.APP_PATH + "couponGroups/" + couponGroup.result.id);
            }
            this.setState((prevState) => ({
                ...prevState,
                couponGroup: couponGroup.result,
                editMode: {...prevState.editMode, active: this.state.editMode.autoSave}
            }));

            this.props.addToast("The couponGroup has been updated successfully.", {
                autoDismiss: true,
                appearance: 'success'
            });
        } else {
            this.setState(prevState => ({...prevState, error: couponGroup}));
        }
    }

    async loadAvailableProducts(page) {
        let loadedProducts = await GetFilteredNonAudioProducts({page: page, title: this.state.productFilterName});

        if (!loadedProducts.error) {
            this.setState(prevState => ({
                ...prevState,
                availableProducts: loadedProducts.result,
                productFilterPage: page
            }));
        } else {
            this.setState(prevState => ({...prevState, error: loadedProducts}));
        }
    }

    async deleteCoupon(coupon) {
        let response = await DeleteCoupon(this.state.id, coupon);

        if (!response.error) {
            let coupons = this.state.coupons;
            coupons = coupons.filter(cp => cp.id !== coupon.id);
            this.setState(prevState => ({...prevState, coupons: coupons}));
            this.props.addToast("The coupon has been removed successfully.", {
                autoDismiss: true,
                appearance: "success"
            });
        } else {
            this.setState(prevState => ({...prevState, error: response}));
        }
    }

    async performBulkCreation(){
        let request = {
            couponPattern: this.state.bulkCreationPattern,
            count: this.state.bulkCreationCount,
            singleUsage: this.state.bulkCreationSingleUse
        }
        let response = await CouponBulkCreation(this.state.couponGroup.id, request);

        if(!response.error) {
            let coupons = await GetFilteredCoupons(this.state.couponGroup.id, {page: 1});

            this.setState(prevState => ({
                ...prevState,
                coupons: coupons.result,
                showBulkCreationDialog: false
            }));

            this.props.addToast(this.state.bulkCreationCount + " coupons have been created successfully.", {
                autoDismiss: true,
                appearance: "success"
            });
        } else {
            this.setState(prevState => ({...prevState, error: response}));
        }
    }

    //--------
    // HELPERS
    //--------

    removeProductFromCouponGroup(product) {
        //Get the current setting
        let couponGroup = this.state.couponGroup;
        let productsInCouponGroup = couponGroup.productIds;
        let products = this.state.products;

        //Remove the corresponding product
        productsInCouponGroup = productsInCouponGroup.filter(id => id !== product.id);
        products = products.filter(prod => prod.id !== product.id);

        //Update the state
        couponGroup.productIds = productsInCouponGroup;

        this.setState(prevState => ({...prevState, couponGroup: couponGroup, products: products}));
    }

    addProductToCouponGroup(product) {
        //Get the current setting
        let couponGroup = this.state.couponGroup;
        let productsInCouponGroup = couponGroup.productIds;
        let products = this.state.products;

        //Add the corresponding product (if it does not already exist!)
        if (!productsInCouponGroup.includes(product.id)) {
            productsInCouponGroup.push(product.id);
            products.push(product);
        }

        couponGroup.productIds = productsInCouponGroup;

        this.setState(prevState => ({...prevState, couponGroup: couponGroup, products: products}));
    }

    //--------
    // DIALOGS
    //--------

    bulkCreationDialog() {
        return (
          <div>
              <p>
                  Allowed characters: a-z, 0-9 and '-' (All lowercase). Use '#' as a placeholder which will be replaced by a random character.You must provide at least 1 pattern characters. Max length of a coupon is 10 characters.
              </p>
              <Form>
                  <Form.Group as={Row} className="mb-3">
                      <Form.Label column sm="2">
                          Pattern
                      </Form.Label>
                      <Col sm="10">
                          <Form.Control type="text"
                                        placeholder="Examples: '##-###' or 'free-####"
                                        value={this.state.bulkCreationPattern}
                                        onChange={(e) => this.setState(prevState => ({...prevState, bulkCreationPattern: e.target.value}))}/>
                      </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-3">
                      <Form.Label column sm="2">
                          Count
                      </Form.Label>
                      <Col sm="10">
                          <Form.Control type="number"
                                        value={this.state.bulkCreationCount}
                                        onChange={(e) => this.setState(prevState => ({...prevState, bulkCreationCount: e.target.value}))}/>
                      </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-3">
                      <Form.Label column sm="2">
                          Single Usage
                      </Form.Label>
                      <Col sm="10">
                          <Form.Check type="switch"
                                      checked={this.state.bulkCreationSingleUse}
                                      value={this.state.bulkCreationSingleUse}
                                      onChange={(e) => this.setState(prevState => ({...prevState, bulkCreationSingleUse: !this.state.bulkCreationSingleUse}))} />
                      </Col>
                  </Form.Group>
              </Form>
          </div>
        );
    }

    pickProductsDialog() {
        return (
            <div>
                <InputGroup className="mb-3">
                    <FormControl
                        placeholder="Search for a product"
                        onChange={(e) => this.setState(prevState => ({
                            ...prevState,
                            productFilterName: e.target.value
                        }))}
                    />
                        <Button variant="outline-secondary"
                                onClick={() => this.loadAvailableProducts(1)}>Search</Button>
                </InputGroup>
                <Table striped bordered hover>
                    <thead>
                    <tr>
                        <th>ID</th>
                        <th>State</th>
                        <th>Product Type</th>
                        <th>Title</th>
                        <th>External ID</th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.state.availableProducts.map(prod => (
                        <tr role="row" key={prod.id} style={{cursor: "pointer"}}
                            onClick={() => this.addProductToCouponGroup(prod)}>
                            <td>{prod.id}</td>
                            <td>
                                <Badge bg={
                                    prod.state === 'NEW' ? "primary" :
                                        prod.state === 'ACTIVE' ? "success" :
                                            prod.state === 'INACTIVE' ? "warning" : "danger"
                                }>{prod.state}</Badge>
                            </td>
                            <td>
                                <Badge bg={
                                    prod.productType === 'TIGERBOOK' ? "primary" :
                                        prod.productType === 'MOVIE' ? "info" :
                                            prod.productType === 'AUDIOBOOK' ? "light" :
                                                prod.productType === 'BOOK' ? "success" : "dark"
                                }>{prod.productType}</Badge>
                            </td>
                            <td>{prod.title}</td>
                            <td>{prod.externalId}</td>
                        </tr>
                    ))}
                    </tbody>
                </Table>

                <Pagination>
                    <Pagination.Item hidden={this.state.productFilterPage === 1}
                                     onClick={() => this.loadAvailableProducts(1)}>&lt;&lt;</Pagination.Item>
                    <Pagination.Item hidden={this.state.productFilterPage === 1}
                                     onClick={() => this.loadAvailableProducts(this.state.productFilterPage - 1)}>&lt;</Pagination.Item>
                    <Pagination.Item hidden={this.state.productFilterPage <= 2}
                                     onClick={() => this.loadAvailableProducts(this.state.productFilterPage - 2)}>{this.state.productFilterPage - 2}</Pagination.Item>
                    <Pagination.Item hidden={this.state.productFilterPage === 1}
                                     onClick={() => this.loadAvailableProducts(this.state.productFilterPage - 1)}>{this.state.productFilterPage - 1}</Pagination.Item>
                    <Pagination.Item active={true}>{this.state.productFilterPage}</Pagination.Item>
                    <Pagination.Item
                        onClick={() => this.loadAvailableProducts(this.state.productFilterPage + 1)}>{this.state.productFilterPage + 1}</Pagination.Item>
                    <Pagination.Item
                        onClick={() => this.loadAvailableProducts(this.state.productFilterPage + 2)}>{this.state.productFilterPage + 2}</Pagination.Item>
                    <Pagination.Item
                        onClick={() => this.loadAvailableProducts(this.state.productFilterPage + 1)}>&gt;</Pagination.Item>
                </Pagination>
            </div>
        )
    }

}


export default withToast(CouponGroupDetails);