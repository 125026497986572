const LibraryMetaData = {

    //Columns
    COLUMNS: [
        //Default & Badges
        {label: "ID", id: 'id', columnType: 'default', show: true},
        {label: "API Type", id: 'apiType', columnType: 'badge', options: [
                {value: 'DIVIBIB', style: "secondary"},
                {value: 'BIBLIOMONDO', style: "primary"},
                {value: 'ASTEC', style: "success"},
                {value: 'EKZ', style: "warning"}
            ],
            show: true},
        {label: "Name", id: 'name', columnType: 'default', show: true},
        {label: "Library Pool ID", id: 'poolId', columnType: 'default', show: true},
        {label: "External ID", id: 'externalId', columnType: 'default', show: true},

        {label: "Concurrent Active Accounts", id: 'concurrentActiveAccounts', columnType: 'default', show: false},
        {label: "Message", id: 'message', columnType: 'default', show: false},
        {label: "Info Username", id: 'infoUsername', columnType: 'default', show: false},
        {label: "Info Password", id: 'infoPassword', columnType: 'default', show: false},
        {label: "Asset ID", id: 'assetId', columnType: 'default', show: false},
        {label: "Asset URL", id: 'assetUrl', columnType: 'default', show: false},
        {label: "Federal State", id: 'federalState', columnType: 'default', show: false},
        {label: "Country", id: 'country', columnType: 'default', show: false},
        {label: "Propagate Username", id: 'propagateUsername', columnType: 'boolean', show: false},
    ],

    //Details
    DETAILS_GENERAL: [
        [{label: 'ID', id: 'id', type: 'text', editable: 'never', isExpertField: false}, {label: "Name", id: 'name', type: 'text', editable: 'true', required: true}, {label: 'External ID', id: 'externalId', type: 'text', editable: 'true'}],
        //[{label: 'Endpoint URL', id: 'endpointUrl', type: 'text', editable: 'true'}, {label: "API Type", id: 'apiType', type: 'select', options: ['DIVIBIB','BIBLIOMONDO','ASTEC', 'EKZ'], editable: 'true'}, {label: 'Product ID', id: 'productId', type: 'text', editable: 'true'}, {label: 'Concurrent Active Accounts', id: 'concurrentActiveAccounts', type: 'text', editable: 'true'}],
    ],
    CUSTOMER_DETAILS: [
        [{label: 'Info Username', id: 'infoUsername', type: 'text', editable: 'true', isExpertField: true}, {label: 'Info Password', id: 'infoPassword', type: 'text', editable: 'true', isExpertField: true}],
        [{label: 'Message', id: 'message', type: 'textarea', rows: 10, editable: 'true'}]
    ],
    LOCATION_DETAILS: [
        [{label: 'Federal State', id: 'federalState', type: 'text', editable: 'true'}, {label: 'Country', id: 'country', type: 'text', editable: 'true'}],
    ],

    //Sorting
    LIBRARY_SORT_PARAMS: [],
};

export default LibraryMetaData;