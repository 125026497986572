import {
    Badge,
    Button,
    Col,
    Form,
    FormCheck,
    FormControl,
    InputGroup,
    Pagination,
    Spinner,
    Table
} from "react-bootstrap";
import Row from 'react-bootstrap/Row';
import React, {Component} from "react";
import FormSelect from "react-bootstrap/FormSelect";
import {withToast} from "../../util/ToastService";
import {Link} from "react-router-dom";
import {
    BiCurrentLocation,
    FcViewDetails, ImImage, IoPeopleCircleOutline, RiStackLine,
} from "react-icons/all";
import GlobalConstants from "../../config/GlobalConstants";
import {CreateLibrary, GetLibrary, UpdateLibrary} from "./LibraryService";
import {DetailsSection} from "../../generators/DetailsGenerator";
import LibraryMetaData from "./LibraryMetaData";
import {
    CreateLibraryPool,
    GetFilteredLibraryPools,
    GetLibraryPool,
    UpdateLibraryPool
} from "../libraryPools/LibraryPoolService";
import {Label} from "reactstrap";
import {GetAsset, GetFilteredAssets, UpdateAsset} from "../assets/AssetService";
import {generateAssetDownloadUrl, openExternalTargetInNewTab} from "../../util/ResourceService";
import {InfoModal} from "../../generators/ModalGenerator";
import {GetFilteredNonAudioProducts} from "../products/ProductService";
import {PromiseButton} from "../../global/SpinningTiger";
import {ErrorHandler} from "../../util/ErrorHandler";

class LibraryDetails extends Component {

    //------------
    //Constructor
    //------------

    constructor(props) {
        super(props);

        this.state = {
            error: null,
            flowState: props.location.state,
            id: this.props.match.params.id,
            editMode: {
                active: false,
                expertMode: false,
                autoSave: true,
            },
            saving: false,
            library: {},
            originalLibrary: {},
            libraryPool: {},
            libraryPools: [],
            poolFilterName: "",
            poolFilterPage: 1,
            asset: {},
            assets: [],
            products: [],
            productFilterPage: 1,
            productFilterName: "",
            poolGeneration: 'auto',
            editPool: false,
            editAsset: false,
            assetFilterPage: 1,
            assetQuery: null,
            sections: {
                general: true,
                customerDetails: true,
                poolDetails: true,
                attributeDetails: true,
                assetDetails: true,
                locationDetails: false,
            },
            showPoolPickerDialog: false,
            showAssetPickerDialog: false,
            showProductPickerDialog: false,
            validationErrors: []
        }
    }

    //---------
    //Mounting
    //---------

    async componentDidMount() {
        //Set the title
        if(this.state.id != null) {
            document.title = "Library " + this.state.id + " :: Tiger UI";
        } else {
            document.title = "New library :: Tiger UI";
        }
        await this.loadLibrary();
        await this.loadLibraryPool();
        await this.loadAsset();
    }

    //--------
    //Loading
    //--------

    async loadLibrary() {
        let loadedLibrary = {};

        if(this.state.id != null && this.state.id !== "add") {

            loadedLibrary = await GetLibrary(this.state.id);

            if (!loadedLibrary.error) {
                const library = loadedLibrary.result;

                this.setState({
                    id: this.state.id,
                    editMode: this.state.editMode,
                    library: library,
                    originalLibrary: library,
                });
            } else {
                this.props.addToast("An error occurred during fetching library " + this.state.id + ". Cause: " + loadedLibrary.message, {
                    autoDismiss: true,
                    appearance: 'error'
                });
            }
        } else {
            this.setState((prevState) => ({...prevState,
                id: null,
                library: {
                    id: null,
                    active: true
                },
                editMode: {
                    active: true,
                    expertMode: false,
                    autoSave: false,
                }}));
        }
    }

    async loadLibraryPool() {
        let loadedLibraryPool = {};

        if(this.state.library.poolId != null) {

            loadedLibraryPool = await GetLibraryPool(this.state.library.poolId);

            if(!loadedLibraryPool.error) {
                const libraryPool = loadedLibraryPool.result;

                this.setState(prevState => ({
                    ...prevState,
                    libraryPool: libraryPool
                }));
            } else {
                this.props.addToast("An error occurred during fetching library pool " + this.state.library.poolId + ". Cause: " + loadedLibraryPool.message, {
                    autoDismiss: true,
                    appearance: 'error'
                });
            }
        }
    }

    async loadAsset() {
        let loadedAsset = {};

        if(this.state.library.assetId != null) {

            loadedAsset = await GetAsset(this.state.library.assetId);

            if(!loadedAsset.error) {
                const asset = loadedAsset.result;

                this.setState(prevState => ({
                    ...prevState,
                    asset: asset
                }));
            } else {
                this.props.addToast("An error occurred during fetching asset " + this.state.library.assetId + ". Cause: " + loadedAsset.message, {
                    autoDismiss: true,
                    appearance: 'error'
                })
            }
        }

    }

    //----------
    //Rendering
    //----------

    render() {

        const labelStyle = {
            fontSize: "20px",
            backgroundColor: "#C8C8C8",
            borderStyle: "solid",
            borderColor: "#cdd7e0",
            borderWidth: "1px",
            borderRadius: "5px",
            paddingLeft: "10px",
            paddingRight: "10px",
            width: "100%"
        };

        return (
            <>
                <div className="details-title">
                    {this.state.library.id != null ? "Details of Library " + this.state.library.id : "Create a new Library"}
                </div>
                <div className="details-button-box" style={{height: "70px"}}>
                    <Link to={{pathname: "/" + GlobalConstants.APP_PATH + "libraries", state: this.state.flowState}}>
                        <button className="form-btn-ci-light-blue" type="button">Back</button>
                    </Link>
                    {this.state.library.id != null &&
                    <button className={this.state.editMode.active ? "form-btn-ci-red" : "form-btn-ci-blue"}
                            type="button" onClick={() => this.setState({
                        editMode: {
                            active: !this.state.editMode.active,
                            expertMode: this.state.editMode.expertMode
                        },
                        library: this.state.originalLibrary
                    })}>{this.state.editMode.active ? "Cancel" : "Edit"}</button>
                    }
                    {this.state.editMode.active &&
                    <button className="form-btn-ci-blue" type="button" disabled={this.state.saving}
                            onClick={() => this.saveOrUpdateLibrary()}>{this.state.saving ?
                        <Spinner animation={"border"} size={"sm"}/> : "Save"}</button>
                    }
                    <Button style={{float: "right", marginTop: "5px"}}
                            variant={this.state.editMode.expertMode ? "secondary" : "outline-secondary"}
                            onClick={() => this.setState(prevState => ({
                                ...prevState,
                                editMode: {
                                    ...prevState.editMode,
                                    expertMode: !this.state.editMode.expertMode
                                }
                            }))}>Expert Mode</Button>
                    <Button style={{float: "right", marginTop: "5px", marginRight: "10px"}}
                            variant={this.state.editMode.autoSave ? "secondary" : "outline-secondary"}
                            onClick={() => this.setState(prevState => ({
                                ...prevState,
                                editMode: {
                                    ...prevState.editMode,
                                    autoSave: !this.state.editMode.autoSave
                                }
                            }))}>Auto Save</Button>
                </div>

                <div className="details-box-title">Resource Details</div>
                <div className="details-box">
                    {/* GENERAL DATA */}
                    <DetailsSection
                        nameInState="library"
                        fields={LibraryMetaData.DETAILS_GENERAL}
                        state={this.state}
                        onSetState={(s) => this.setState(s)}
                        onUpdateResource={() => this.saveOrUpdateLibrary()}
                        sectionId="general"
                    />

                    <Form>
                        <Row>
                            <Form.Group as={Col} controlId="endpointUrl">
                                <Label style={labelStyle}>Endpoint URL</Label>
                                <Form.Control type="text" value={this.state.library.endpointUrl}
                                              readOnly={!this.state.editMode.active}
                                              onChange={(e) => this.updateParam("library", "endpointUrl", e.target.value)}/>
                            </Form.Group>
                            <Form.Group as={Col} controlId="apiType">
                                <Label style={labelStyle}>API Type</Label>
                                <FormSelect type="text" value={this.state.library.apiType}
                                              readOnly={!this.state.editMode.active}
                                              onChange={(e) => this.updateParam("library", "apiType", e.target.value)}>
                                    <option>DIVIBIB</option>
                                    <option>BIBLIOMONDO</option>
                                    <option>ASTEC</option>
                                    <option>EKZ</option>
                                </FormSelect>
                            </Form.Group>
                            <Form.Group as={Col} controlId="productId">
                                <Label style={labelStyle}>ProductId</Label>
                                <InputGroup className="mb-3">
                                    <Form.Control type="text" value={this.state.library.productId}
                                                  readOnly={!this.state.editMode.active}
                                                  onChange={(e) => this.updateParam("library", "productId", e.target.value)}
                                    />
                                        <Button variant="outline-secondary"
                                                onClick={() => {
                                                    this.loadProductsPage(1).then(r => {
                                                        this.setState(prevState => ({
                                                            ...prevState,
                                                            showProductPickerDialog: true,
                                                        }));
                                                    });
                                                }}>Pick</Button>
                                </InputGroup>
                            </Form.Group>
                        </Row>
                    </Form>

                    {/* CUSTOMER DETAILS */}
                    <DetailsSection
                        nameInState="library"
                        fields={LibraryMetaData.CUSTOMER_DETAILS}
                        state={this.state}
                        onSetState={(s) => this.setState(s)}
                        sectionId="customerDetails"
                        onUpdateResource={() => this.saveOrUpdateLibrary()}
                        label={<span><IoPeopleCircleOutline/>&#xA0;Customer Details</span>}
                    />

                    {/* POOL DETAILS */}
                    <DetailsSection
                        nameInState="library"
                        fields={[]}
                        state={this.state}
                        onSetState={(s) => this.setState(s)}
                        sectionId="poolDetails"
                        label={<span><RiStackLine/>&#xA0;Library Pool Details</span>}
                    />

                    {this.state.sections.poolDetails &&
                    <>
                        <Form>
                            <Row>
                                <Form.Group as={Col} controlId="editLibraryPool">
                                    {this.state.library.id == null ?
                                        <Button
                                            variant={this.state.poolGeneration === 'auto' ? "secondary" : "outline-secondary"}
                                            onClick={() => {
                                                if (this.state.poolGeneration === 'auto') {
                                                    this.setState(prevState => ({
                                                        ...prevState,
                                                        poolGeneration: 'manual'
                                                    }))
                                                } else {
                                                    this.setState(prevState => ({...prevState, poolGeneration: 'auto'}))
                                                }

                                            }}>Generate Pool
                                            automatically: {this.state.poolGeneration === 'auto' ? "ON" : "OFF"}</Button>
                                        :
                                        <>
                                            {this.state.editPool ?
                                                <>
                                                    <PromiseButton text="Save" onClick={() => this.saveOrUpdateLibraryPool()} />
                                                    <Button variant="danger" style={{marginLeft: "5px"}}
                                                            onClick={() => this.setState(prevState => ({
                                                                ...prevState,
                                                                editPool: false
                                                            }))}>Cancel</Button>
                                                </>
                                                :
                                                <Button variant="primary" onClick={() => this.setState(prevState => ({
                                                    ...prevState,
                                                    editPool: true
                                                }))}>
                                                    Edit Pool
                                                </Button>
                                            }
                                        </>


                                    }

                                    {this.state.editMode.active &&
                                    <Button variant="outline-secondary" style={{marginLeft: "10px"}} onClick={() => {
                                        this.loadLibraryPoolsPage(1).then(r => {
                                            this.setState(prevState => ({
                                                ...prevState,
                                                poolGeneration: 'pick',
                                                showPoolPickerDialog: true,
                                            }));
                                        });
                                    }}>Pick Pool</Button>
                                    }
                                </Form.Group>
                            </Row>
                            <Row>
                                <Form.Group as={Col} controlId="poolId">
                                    <Label style={labelStyle}>Library Pool ID</Label>
                                    <Form.Control type="text" value={this.state.libraryPool.id}
                                                  readOnly={true}/>
                                </Form.Group>
                                <Form.Group as={Col} controlId="poolName">
                                    <Label style={labelStyle}>Name</Label>
                                    <Form.Control type="text" value={this.state.libraryPool.name}
                                                  readOnly={!(this.state.library.id == null || this.state.editPool)}
                                                  onChange={(e) => this.updateParam('libraryPool', 'name', e.target.value)}/>
                                </Form.Group>
                                <Form.Group as={Col} controlId="poolLicenses">
                                    <Label style={labelStyle}>Active Licenses*</Label>
                                    <Form.Control type="text" value={this.state.libraryPool.activeLicenses}
                                                  readOnly={!(this.state.library.id == null || this.state.editPool)}
                                                  onChange={(e) => this.updateParam('libraryPool', 'activeLicenses', e.target.value)}/>
                                </Form.Group>
                            </Row>
                            <Row>
                                <Form.Group as={Col} controlId="cardinality">
                                    <Label style={labelStyle}>Cardinality</Label>
                                    <FormSelect type="text" value={this.state.libraryPool.cardinality}
                                                  readOnly={!(this.state.library.id == null || this.state.editPool)}
                                                  onChange={(e) => this.updateParam('libraryPool', 'cardinality', e.target.value)}>
                                        <option>SINGLE</option>
                                        <option>MULTIPLE</option>
                                    </FormSelect>
                                </Form.Group>
                                <Form.Group as={Col} controlId="poolOwner">
                                    <Label style={labelStyle}>Owner</Label>
                                    <Form.Control type="text" value={this.state.libraryPool.owner}
                                                  readOnly={!(this.state.library.id == null || this.state.editPool)}
                                                  onChange={(e) => this.updateParam('libraryPool', 'owner', e.target.value)}/>
                                </Form.Group>
                                <Form.Group as={Col} controlId="poolRegion">
                                    <Label style={labelStyle}>Region</Label>
                                    <Form.Control type="text" value={this.state.libraryPool.region}
                                                  readOnly={!(this.state.library.id == null || this.state.editPool)}
                                                  onChange={(e) => this.updateParam('libraryPool', 'region', e.target.value)}/>
                                </Form.Group>
                            </Row>
                        </Form>
                    </>
                    }

                    {/* ATTRIBUTES DETAILS */}
                    <DetailsSection
                        nameInState="library"
                        fields={[]}
                        state={this.state}
                        onSetState={(s) => this.setState(s)}
                        sectionId="attributeDetails"
                        label={<span><FcViewDetails/>&#xA0;Attributes</span>}
                    />
                    {this.state.sections.attributeDetails &&
                    <>
                        <Form>
                            <Row>
                                <Form.Group>
                                    <FormCheck id="active" type="switch"
                                               label="Active"
                                               checked={this.state.library.active}
                                               disabled={!this.state.editMode.active}
                                               onChange={() => this.updateParam("library", "active", !this.state.library.active)}
                                               readOnly={!this.state.editMode.active}/>
                                    <FormCheck id="propagateUsername" type="switch" label="Propagate Username"
                                               checked={this.state.library.propagateUsername}
                                               disabled={!this.state.editMode.active}
                                               onChange={() => this.updateParam("library", "propagateUsername", !this.state.library.propagateUsername)}
                                               readOnly={!this.state.editMode.active}/>
                                </Form.Group>
                            </Row>
                        </Form>
                    </>
                    }

                    {/* ASSET DETAILS */}
                    <DetailsSection
                        nameInState="library"
                        fields={[]}
                        state={this.state}
                        onSetState={(s) => this.setState(s)}
                        sectionId="assetDetails"
                        label={<span><ImImage/>&#xA0;Asset Details</span>}
                    />

                    {this.state.sections.assetDetails &&
                    <>
                        <Form>
                            <Row>
                                <Form.Group as={Col} controlId="editAsset">
                                    {this.state.editAsset ?
                                        <>
                                            <PromiseButton text="Save Asset" onClick={() => this.updateAsset()} />
                                            <Button variant="danger" style={{marginLeft: "5px"}}
                                                    onClick={() => this.setState(prevState => ({
                                                        ...prevState,
                                                        editAsset: false
                                                    }))}>Cancel</Button>
                                        </>
                                        :
                                        <>
                                            {
                                                this.state.asset.id != null &&
                                                <Button variant="primary" onClick={() => this.setState(prevState => ({
                                                    ...prevState,
                                                    editAsset: true
                                                }))}>
                                                    Edit Asset
                                                </Button>
                                            }
                                        </>
                                    }

                                    {this.state.editMode.active &&
                                    <Button variant="outline-secondary" style={{marginLeft: "10px"}} onClick={() => {
                                        this.loadAssetsPage(1).then(r => {
                                            this.setState(prevState => ({
                                                ...prevState,
                                                showAssetPickerDialog: true,
                                            }));
                                        });
                                    }}>Pick Asset</Button>
                                    }
                                </Form.Group>
                            </Row>
                            <Row>
                                <Form.Group as={Col} controlId="assetId">
                                    <Label style={labelStyle}>Asset ID*</Label>
                                    <Form.Control type="text" value={this.state.asset.id}
                                                  readOnly={!this.state.editMode.active}
                                                  onChange={(e) => this.setState(prevState => ({
                                                      ...prevState,
                                                      library: {
                                                          ...prevState.library,
                                                          assetId: e.target.value
                                                      },
                                                      asset: {
                                                          id: e.target.value
                                                      }
                                                  }))}/>
                                </Form.Group>
                                <Form.Group as={Col} controlId="assetName">
                                    <Label style={labelStyle}>Name</Label>
                                    <Form.Control type="text" value={this.state.asset.name}
                                                  readOnly={!this.state.editAsset}
                                                  onChange={(e) => this.updateParam('asset', 'name', e.target.value)}/>
                                </Form.Group>
                                <Form.Group as={Col} controlId="assetVersion">
                                    <Label style={labelStyle}>Version</Label>
                                    <Form.Control type="text" value={this.state.asset.version}
                                                  readOnly={!this.state.editAsset}
                                                  onChange={(e) => this.updateParam('asset', 'version', e.target.value)}/>
                                </Form.Group>
                            </Row>
                            <Row>
                                <Form.Group as={Col} controlId="assetType">
                                    <Label style={labelStyle}>Type</Label>
                                    <Form.Control type="text" value={this.state.asset.assetType}
                                                  readOnly={!this.state.editAsset}
                                                  onChange={(e) => this.updateParam('asset', 'assetType', e.target.value)}/>
                                </Form.Group>
                                <Form.Group as={Col} controlId="assetMimeType">
                                    <Label style={labelStyle}>MIME Type</Label>
                                    <Form.Control type="text" value={this.state.asset.mimeType}
                                                  readOnly={!this.state.editAsset}
                                                  onChange={(e) => this.updateParam('asset', 'mimeType', e.target.value)}/>
                                </Form.Group>
                                <Form.Group as={Col} controlId="assetHash">
                                    <Label style={labelStyle}>md5 Hash</Label>
                                    <Form.Control type="text" value={this.state.asset.md5Hash}
                                                  readOnly={!this.state.editAsset}
                                                  onChange={(e) => this.updateParam('asset', 'md5Hash', e.target.value)}/>
                                </Form.Group>
                            </Row>
                            <Row>
                                <Form.Group as={Col} controlId="assetSize">
                                    <Label style={labelStyle}>Size</Label>
                                    <Form.Control type="text" value={this.state.asset.size}
                                                  readOnly={true}/>
                                </Form.Group>
                                <Form.Group as={Col} controlId="assetWidth">
                                    <Label style={labelStyle}>Width</Label>
                                    <Form.Control type="text" value={this.state.asset.width}
                                                  readOnly={true}/>
                                </Form.Group>
                                <Form.Group as={Col} controlId="assetHeight">
                                    <Label style={labelStyle}>Height</Label>
                                    <Form.Control type="text" value={this.state.asset.height}
                                                  readOnly={true}/>
                                </Form.Group>
                            </Row>
                            <Row>
                                <Form.Group as={Col} controlId="assetVisibility">
                                    <Label style={labelStyle}>Visibility</Label>
                                    <Form.Control type="text" value={this.state.asset.visibility}
                                                  readOnly={!this.state.editAsset}
                                                  onChange={(e) => this.updateParam('asset', 'visibility', e.target.value)}/>
                                </Form.Group>
                                <Form.Group as={Col} controlId="assetStorage">
                                    <Label style={labelStyle}>Storage</Label>
                                    <Form.Control type="text" value={this.state.asset.storage}
                                                  readOnly={!this.state.editAsset}
                                                  onChange={(e) => this.updateParam('asset', 'storage', e.target.value)}/>
                                </Form.Group>
                                <Form.Group as={Col} controlId="assetStorageReferenceId">
                                    <Label style={labelStyle}>Storage Reference ID</Label>
                                    <Form.Control type="text" value={this.state.asset.storageReferenceId}
                                                  readOnly={!this.state.editAsset}
                                                  onChange={(e) => this.updateParam('asset', 'storageReferenceId', e.target.value)}/>
                                </Form.Group>
                            </Row>
                        </Form>
                        <h5>Preview</h5>
                        <img src={generateAssetDownloadUrl(this.state.asset)} width="200" height="200"
                             alt="assetPreview" style={{cursor: "pointer"}}
                             onClick={() => openExternalTargetInNewTab(generateAssetDownloadUrl(this.state.asset))}/>
                    </>
                    }

                    {/* LOCATION DETAILS */}
                    <DetailsSection
                        nameInState="library"
                        fields={LibraryMetaData.LOCATION_DETAILS}
                        state={this.state}
                        onSetState={(s) => this.setState(s)}
                        sectionId="locationDetails"
                        onUpdateResource={() => this.saveOrUpdateLibrary()}
                        label={<span><BiCurrentLocation/>&#xA0;Location Details</span>}
                    />

                </div>
                <div className="details-button-box" style={{height: "70px"}}>
                    <Link to={{pathname: "/" + GlobalConstants.APP_PATH + "libraries", state: this.state.flowState}}>
                        <button className="form-btn-ci-light-blue" type="button">Back</button>
                    </Link>
                    {this.state.editMode.active &&
                    <PromiseButton text="Save" onClick={() => this.saveOrUpdateLibrary()} />
                    }
                </div>

                {/* DIALOGS */}
                <InfoModal show={this.state.showPoolPickerDialog}
                           onHide={() => this.setState(prevState => ({
                               ...prevState,
                               showPoolPickerDialog: false
                           }))}
                           title="Pick a library pool"
                           body={this.pickLibraryPoolDialog()}/>

                <InfoModal show={this.state.showAssetPickerDialog}
                           onHide={() => this.setState(prevState => ({
                               ...prevState,
                               showAssetPickerDialog: false
                           }))}
                           title="Pick an asset"
                           body={this.pickAssetDialog()}/>

                <InfoModal show={this.state.showProductPickerDialog}
                           onHide={() => this.setState(prevState => ({
                               ...prevState,
                               showProductPickerDialog: false
                           }))}
                           title="Pick a product"
                           body={this.pickProductDialog()}/>

                <ErrorHandler error={this.state.error}
                              onHide={() => this.setState(prevState => ({...prevState, error: null}))} />
            </>
        );
    }

    //------------
    //API Methods
    //------------

    async saveOrUpdateLibrary() {
        let library = this.state.library;
        let libraryPool = this.generateLibraryPool();

        this.setState(prevState => ({...prevState, saving: true}));

        //Check if the library already has a pool
        if (library.poolId == null) {
            //It does not
            let savedPool = {};

            if(libraryPool.id == null) {
                //Library Pool does not exist yet -> Create it
                savedPool = await CreateLibraryPool(libraryPool);
                if(!savedPool.error) {
                    //Library Pool has been created -> Set the id
                    libraryPool.id = savedPool.result.id;
                    library.poolId = savedPool.result.id;
                } else {
                    this.setState((prevState) => ({
                        ...prevState,
                        saving: false,
                        error: savedPool
                    }));
                    return;
                }
            } else {
                //Library Pool already exists
                library.poolId = libraryPool.id;
            }
        }

        let savedLibrary = {};

        if (library.id != null) {
            savedLibrary = await UpdateLibrary(library);
        } else {
            savedLibrary = await CreateLibrary(library);
        }

        if (!savedLibrary.error) {
            //Reload the asset it it has been updated
            if (this.state.library.assetId != null && this.state.asset != null && this.state.asset.id != null && this.state.asset.name == null) {
                await this.loadAsset();
            }
            if (this.state.library.id == null) {
                this.props.history.push("/"+ GlobalConstants.APP_PATH + "libraries/" + savedLibrary.result.id);
            }
            this.setState((prevState) => ({
                ...prevState,
                library: savedLibrary.result,
                libraryPool: libraryPool,
                editMode: {...prevState.editMode, active: this.state.editMode.autoSave},
                saving: false
            }));

            this.props.addToast("The library has been saved successfully.", {
                autoDismiss: true,
                appearance: 'success'
            });
        } else {
            this.setState((prevState) => ({
                ...prevState,
                libraryPool: libraryPool,
                saving: false,
                error: savedLibrary
            }));
        }
    }

    async saveOrUpdateLibraryPool(){
        let libraryPool = {};
        if(this.state.libraryPool.id != null) {
            libraryPool = await UpdateLibraryPool(this.state.libraryPool);
        } else {
            libraryPool = await CreateLibraryPool(this.state.libraryPool);
        }

        if (!libraryPool.error) {
            this.setState((prevState) => ({
                ...prevState,
                libraryPool: libraryPool.result,
                editPool: false
            }));

            this.props.addToast("The library pool has been updated successfully.", {
                autoDismiss: true,
                appearance: 'success'
            });
        } else {
            this.setState(prevState => ({...prevState, error: libraryPool}));
        }
    }

    async updateAsset(){
        let updatedAsset = await UpdateAsset(this.state.asset);

        if (!updatedAsset.error) {
            this.setState((prevState) => ({
                ...prevState,
                editAsset: false
            }));

            this.props.addToast("The asset has been updated successfully.", {
                autoDismiss: true,
                appearance: 'success'
            });
        } else {
            this.props.addToast("An error occurred. Cause: " + updatedAsset.message + ".", {
                autoDismiss: true,
                appearance: 'error'
            });
        }
    }

    async loadLibraryPoolsPage(page) {
        const loadedLibraryPools = await GetFilteredLibraryPools({page: page, name: this.state.poolFilterName});

        if (loadedLibraryPools.error === true) {
            this.props.addToast("An error occurred during the fetching the library pools. Cause: " + loadedLibraryPools.message, {
                autoDismiss: true,
                appearance: 'error'
            });
        }
        this.setState(prevState => ({
            ...prevState,
            libraryPools: loadedLibraryPools.result,
            libraryFilterPage: page
        }));
    }

    async loadAssetsPage(page) {
        const loadedAssets = await GetFilteredAssets({page: page, query: this.state.assetQuery});

        if (loadedAssets.error === true) {
            this.props.addToast("An error occurred during the fetching the assets. Cause: " + loadedAssets.message, {
                autoDismiss: true,
                appearance: 'error'
            });
        }
        this.setState(prevState => ({
            ...prevState,
            assets: loadedAssets.result,
            assetFilterPage: page
        }));
    }

    async loadProductsPage(page) {
        const loadedProducts = await GetFilteredNonAudioProducts({page: page, title: this.state.productFilterName});

        if (loadedProducts.error === true) {
            this.props.addToast("An error occurred during the fetching the products. Cause: " + loadedProducts.message, {
                autoDismiss: true,
                appearance: 'error'
            });
        }
        this.setState(prevState => ({
            ...prevState,
            products: loadedProducts.result,
            productFilterPage: page
        }));
    }

    //--------
    // HELPER
    //--------

    updateParam(resource, param, value) {
        let state = this.state;
        let res = state[resource];
        res[param] = value;
        state[resource] = res;

        this.setState(state);
    }

    generateLibraryPool() {
        let pool = {};
        if (this.state.poolGeneration === 'auto') {
            //AUTO GENERATION -> Generate the fields by using the data from the library params
            pool.name = this.state.library.name + '-POOL';
            pool.activeLicenses = this.state.library.concurrentActiveAccounts != null ? this.state.library.concurrentActiveAccounts : this.state.libraryPool.activeLicenses;
            pool.cardinality = 'SINGLE';
            pool.owner = this.state.libraryPool.owner;
            pool.region = this.state.libraryPool.region;
        } else if (this.state.poolGeneration === 'manual') {
            //MANUAL -> Use the fields that have been provided (id does not exist yet
            pool.name = this.state.libraryPool.name;
            pool.activeLicenses = this.state.libraryPool.activeLicenses;
            pool.cardinality = this.state.libraryPool.cardinality;
            pool.owner = this.state.libraryPool.owner;
            pool.region = this.state.libraryPool.region;
        } else {
            //PICKED -> ID exists
            pool.id = this.state.libraryPool.id;
            pool.name = this.state.libraryPool.name;
            pool.activeLicenses = this.state.libraryPool.activeLicenses;
            pool.cardinality = this.state.libraryPool.cardinality;
            pool.owner = this.state.libraryPool.owner;
            pool.region = this.state.libraryPool.region;
        }

        return pool;
    }

    //--------
    // DIALOGS
    //--------

    pickLibraryPoolDialog() {
        return (
            <div>
                <InputGroup className="mb-3">
                    <FormControl
                        placeholder="Search for a library pool"
                        onChange={(e) => this.setState(prevState => ({
                            ...prevState,
                            poolFilterName: e.target.value
                        }))}
                    />
                        <Button variant="outline-secondary"
                                onClick={() => this.loadLibraryPoolsPage(this.state.poolFilterPage)}>Search</Button>
                </InputGroup>
                <Table striped bordered hover>
                    <thead>
                    <tr>
                        <th>ID</th>
                        <th>Name</th>
                        <th>Active Licenses</th>
                        <th>Cardinality</th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.state.libraryPools.map(pool => (
                        <tr role="row" key={pool.id} style={{cursor: "pointer"}} onClick={() => {
                            this.setState(prevState => ({
                                ...prevState,
                                library: {
                                    ...prevState.library,
                                    poolId: pool.id
                                },
                                libraryPool: pool,
                                showPoolPickerDialog: false
                            }));
                        }}>
                            <td>{pool.id}</td>
                            <td>{pool.name}</td>
                            <td>{pool.activeLicenses}</td>
                            <td>{pool.cardinality}</td>
                        </tr>
                    ))}
                    </tbody>
                </Table>

                <Pagination>
                    <Pagination.Item hidden={this.state.poolFilterPage === 1}
                                     onClick={() => this.loadLibraryPoolsPage(1)}>&lt;&lt;</Pagination.Item>
                    <Pagination.Item hidden={this.state.poolFilterPage === 1}
                                     onClick={() => this.loadLibraryPoolsPage(this.state.poolFilterPage - 1)}>&lt;</Pagination.Item>
                    <Pagination.Item hidden={this.state.poolFilterPage <= 2}
                                     onClick={() => this.loadLibraryPoolsPage(this.state.poolFilterPage - 2)}>{this.state.poolFilterPage - 2}</Pagination.Item>
                    <Pagination.Item hidden={this.state.poolFilterPage === 1}
                                     onClick={() => this.loadLibraryPoolsPage(this.state.poolFilterPage - 1)}>{this.state.poolFilterPage - 1}</Pagination.Item>
                    <Pagination.Item active={true}>{this.state.poolFilterPage}</Pagination.Item>
                    <Pagination.Item
                        onClick={() => this.loadLibraryPoolsPage(this.state.poolFilterPage + 1)}>{this.state.poolFilterPage + 1}</Pagination.Item>
                    <Pagination.Item
                        onClick={() => this.loadLibraryPoolsPage(this.state.poolFilterPage + 2)}>{this.state.poolFilterPage + 2}</Pagination.Item>
                    <Pagination.Item
                        onClick={() => this.loadLibraryPoolsPage(this.state.poolFilterPage + 1)}>&gt;</Pagination.Item>
                </Pagination>
            </div>
        )
    }

    pickAssetDialog() {
        return (
            <div>
                <InputGroup>
                    <Form.Control value={this.state.assetQuery}
                                  placeholder="Query"
                                  onChange={(e) => this.setState(prevState => ({...prevState, assetQuery: e.target.value}))} />
                    {this.state.assetQuery != null && this.state.assetQuery !== "" &&
                        <Button variant="outline-danger" type="button" onClick={() => this.setState(prevState => ({...prevState, assetQuery: ""}), () => this.loadAssetsPage(1))}>Reset</Button>
                    }
                    <Button variant="outline-secondary" type="button" onClick={() => this.loadAssetsPage(1)}>Suche</Button>
                </InputGroup>
                <br />
                <Table striped bordered hover>
                    <thead>
                    <tr>
                        <th>ID</th>
                        <th>Name</th>
                        <th>Type</th>
                        <th>Preview</th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.state.assets.map(asset => (
                        <tr role="row" key={asset.id} style={{cursor: "pointer"}} onClick={() => {
                            let library = this.state.library;
                            library["assetId"] = asset.id;
                            this.setState(prevState => ({
                                ...prevState,
                                asset: asset,
                                library: library,
                                showAssetPickerDialog: false
                            }));
                        }}>
                            <td>{asset.id}</td>
                            <td>{asset.name}</td>
                            <td>{asset.assetType}</td>
                            <td><img src={generateAssetDownloadUrl(asset)} width="100px" height="100px" alt={"Preview of asset " + asset.id} /></td>
                        </tr>
                    ))}
                    </tbody>
                </Table>

                <Pagination>
                    <Pagination.Item hidden={this.state.assetFilterPage === 1}
                                     onClick={() => this.loadAssetsPage(1)}>&lt;&lt;</Pagination.Item>
                    <Pagination.Item hidden={this.state.assetFilterPage === 1}
                                     onClick={() => this.loadAssetsPage(this.state.assetFilterPage - 1)}>&lt;</Pagination.Item>
                    <Pagination.Item hidden={this.state.assetFilterPage <= 2}
                                     onClick={() => this.loadAssetsPage(this.state.assetFilterPage - 2)}>{this.state.assetFilterPage - 2}</Pagination.Item>
                    <Pagination.Item hidden={this.state.assetFilterPage === 1}
                                     onClick={() => this.loadAssetsPage(this.state.assetFilterPage - 1)}>{this.state.assetFilterPage - 1}</Pagination.Item>
                    <Pagination.Item active={true}>{this.state.assetFilterPage}</Pagination.Item>
                    <Pagination.Item
                        onClick={() => this.loadAssetsPage(this.state.assetFilterPage + 1)}>{this.state.assetFilterPage + 1}</Pagination.Item>
                    <Pagination.Item
                        onClick={() => this.loadAssetsPage(this.state.assetFilterPage + 2)}>{this.state.assetFilterPage + 2}</Pagination.Item>
                    <Pagination.Item
                        onClick={() => this.loadAssetsPage(this.state.assetFilterPage + 1)}>&gt;</Pagination.Item>
                </Pagination>
            </div>
        )
    }

    pickProductDialog() {
        return (
            <div>
                <InputGroup className="mb-3">
                    <FormControl
                        placeholder="Search for a product"
                        onChange={(e) => this.setState(prevState => ({
                            ...prevState,
                            productFilterName: e.target.value
                        }))}
                    />
                        <Button variant="outline-secondary"
                                onClick={() => this.loadProductsPage(this.state.productFilterPage)}>Search</Button>
                </InputGroup>
                <Table striped bordered hover>
                    <thead>
                    <tr>
                        <th>ID</th>
                        <th>State</th>
                        <th>Product Type</th>
                        <th>Title</th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.state.products.map(product => (
                        <tr role="row" key={product.id} style={{cursor: "pointer"}} onClick={() => {
                            let library = this.state.library;
                            library["productId"] = product.id;
                            this.setState(prevState => ({
                                ...prevState,
                                library: library,
                                showProductPickerDialog: false
                            }));
                        }}>
                            <td>{product.id}</td>
                            <td>
                                <Badge bg={
                                    product.state === 'NEW' ? "primary" :
                                    product.state === 'ACTIVE' ? "success" :
                                    product.state === 'INACTIVE' ? "warning" :
                                    "danger"
                                }>{product.state}</Badge>
                            </td>
                            <td>
                                <Badge bg={
                                    product.productType === 'TIGERBOOK' ? "primary" :
                                    product.productType === 'MOVIE' ? "info" :
                                    product.productType === 'AUDIOBOOK' ? "light" :
                                    product.productType === 'BOOK' ? "success" :
                                    "dark"
                                }>{product.productType}</Badge>
                            </td>
                            <td>{product.title}</td>
                        </tr>
                    ))}
                    </tbody>
                </Table>

                <Pagination>
                    <Pagination.Item hidden={this.state.productFilterPage === 1}
                                     onClick={() => this.loadProductsPage(1)}>&lt;&lt;</Pagination.Item>
                    <Pagination.Item hidden={this.state.productFilterPage === 1}
                                     onClick={() => this.loadProductsPage(this.state.productFilterPage - 1)}>&lt;</Pagination.Item>
                    <Pagination.Item hidden={this.state.productFilterPage <= 2}
                                     onClick={() => this.loadProductsPage(this.state.productFilterPage - 2)}>{this.state.productFilterPage - 2}</Pagination.Item>
                    <Pagination.Item hidden={this.state.productFilterPage === 1}
                                     onClick={() => this.loadProductsPage(this.state.productFilterPage - 1)}>{this.state.productFilterPage - 1}</Pagination.Item>
                    <Pagination.Item active={true}>{this.state.productFilterPage}</Pagination.Item>
                    <Pagination.Item
                        onClick={() => this.loadProductsPage(this.state.productFilterPage + 1)}>{this.state.productFilterPage + 1}</Pagination.Item>
                    <Pagination.Item
                        onClick={() => this.loadProductsPage(this.state.productFilterPage + 2)}>{this.state.productFilterPage + 2}</Pagination.Item>
                    <Pagination.Item
                        onClick={() => this.loadProductsPage(this.state.productFilterPage + 1)}>&gt;</Pagination.Item>
                </Pagination>
            </div>
        )
    }

}


export default withToast(LibraryDetails);