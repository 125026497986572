import {GetBillResource, GetFilteredBillResources} from "../../clients/PurchaseClient";

//API METHODS
export async function GetBillwerkCustomer(accountId){
    return await GetBillResource('api/purchases/customer/search/by-account-id?accountId=' + accountId);

    //TEST PURPOSE
    //return {error: false, result: CreateTestBillwerkCustomer(filter.search), message: ""};
}

export async function SearchBillwerkCustomersByEmail(email) {
    return await GetBillResource('api/purchases/customer/search/by-email?email=' + email);
}

export async function GetExtendedBillwerkCustomerDetails(email) {
    return await GetFilteredBillResources('api/purchases/customer/search/extended-by-email', {email: encodeURIComponent(email), includeDeleted: true});
}

export async function GetBillwerkContractOfCustomer(customerId) {
    return await GetFilteredBillResources('api/purchases/customer/' + customerId + '/contracts', {});
    //TEST PURPOSE
    //return {error: false, result: CreateTestBillwerkContracts(customerId), message: ""};
}