import {
    DeleteRestResource,
    GetFilteredRestResource,
    GetRestResource,
    CreateRestResource,
    UpdateRestResource
} from "../../clients/RestResourceClient";

//SERIES
export async function GetFilteredSeries(filter){
    return await GetFilteredRestResource('api/series', filter);

}

export async function CreateSeries(series) {
    return await CreateRestResource('api/series', series);
}

export async function GetSeries(id) {
    return await GetRestResource('api/series/' + id);
}

export async function GetResourceByUrl(url) {
    return await GetRestResource(url, true);
}

export async function UpdateSeries(series) {
    return await UpdateRestResource('api/series/' + series.id, series);
}

export async function DeleteSeries(series) {
    return await DeleteRestResource('api/series/' + series.id);
}

export async function GetSeriesByProductId(productId) {
    return await GetRestResource('api/series/search/by-product-id?productId=' + productId);
}

//SEASON
export async function GetSeason(seriesId, seasonId) {
    return await GetRestResource('api/series/' + seriesId + '/seasons/' + seasonId);
}

export async function CreateSeason(seriesId, season) {
    return await CreateRestResource('api/series/' + seriesId + '/seasons', season, false);
}

export async function addSeasonToSeries(seriesId, season) {
    return await CreateRestResource('api/series/' + seriesId + '/seasons', season);
}

export async function UpdateSeason(seriesId, season) {
    return await UpdateRestResource('api/series/' + seriesId + '/seasons/' + season.id, season);
}
export async function DeleteSeason(seriesId, season) {
    return await DeleteRestResource('api/series/' + seriesId + '/seasons/' + season.id, false);
}


//EPISODES
export async function CreateEpisode(seriesId, seasonId, episode) {
    return await CreateRestResource('api/series/' + seriesId + '/seasons/' + seasonId + '/episodes', episode, false);
}

export async function GetEpisode(seriesId, seasonId, id) {
    return await GetRestResource('api/series/' + seriesId + '/seasons/' + seasonId + '/episodes/' + id);
}
export async function GetFilteredEpisodes(filter) {
    return await GetFilteredRestResource('api/episodes', filter);
}

export async function DeleteEpisode(seriesId, seasonId, episode) {
    return await DeleteRestResource('api/series/' + seriesId + '/seasons/' + seasonId + '/episodes/' + episode.id, false);
}

export async function UpdateEpisode(episode) {
    return await UpdateRestResource(episode._links.self.href, episode, true);
}

export async function GetEpisodesByProductId(productId) {
    return await GetFilteredRestResource('api/episodes/search/by-product-id?productId=' + productId, {});
}

export async function CreateSeriesTag(routine, tag) {
    return await CreateRestResource('api/series/' +  routine.id + "/tags", tag);
}

export async function DeleteSeriesTag(routine, tag) {
    return await DeleteRestResource('api/series/' +  routine.id + "/tags?tagId=" + tag.id);
}
