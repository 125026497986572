import React, {Component} from "react";
import '../../../App.scss'
import FormSelect from "react-bootstrap/FormSelect";
import Row from 'react-bootstrap/Row';
import {withToast} from "../../util/ToastService";
import {
    FiSettings,
    GrObjectGroup
} from "react-icons/all";
import {ResourcesTable, TableHeader, TableSettingsDialog} from "../../generators/TableGenerator";
import "react-datepicker/dist/react-datepicker.css";
import OrderedBundlesMetaData from "./OrderedBundlesMetaData";
import {SpinningTiger} from "../../global/SpinningTiger";
import {Form} from "react-bootstrap";
import {ErrorHandler} from "../../util/ErrorHandler";
import {GetOrderedBundles} from "./OrderedBundlesService";
import GlobalConstants from "../../config/GlobalConstants";

class OrderedBundles extends Component {

    //------------
    //Constructor
    //------------

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            orderedBundles: [],
            filter: props.location.state != null ? props.location.state.filter :
                {page: 0,
                 planVariantId: '',
                 orderEmailAddress: '',
                 lastName: '',
                 color: '',
                 contractState: 'ACTIVE',
                 shipmentState: '',
                 status: "",
                 sortBy : "createdDate",
                 sortDirection : "DESC",
                 per_page: 20},
            lastPage: props.location.state != null ? props.location.state.lastPage : 1,
            totalElements: 0,
            columns: props.location.state != null ? props.location.state.columns : OrderedBundlesMetaData.COLUMNS,
            customColumnData: [],
            showDialog: {
                tableSettings: false,
                deletionModal: false
            },
        };

        this.resetFilter = this.resetFilter.bind(this);
        this.updateFilter = this.updateFilter.bind(this);
        this.showOrHideDialog = this.showOrHideDialog.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.loadPage = this.loadPage.bind(this);
    }

    //---------
    //Mounting
    //---------

    async componentDidMount() {
        await this.loadPage(this.state.filter.page);
    }

    //--------
    //Loading
    //--------

    async loadPage(page) {

        this.setState(prevState => ({...prevState, loading: true}));

        let filter = this.state.filter;
        filter.page = page;
        const response = await GetOrderedBundles(filter);

        if (response.error === true) {
            this.setState(prevState => ({...prevState, error: response}));
        } else {
            response.result.forEach(orderedBundle => {
                if (this.state.orderedBundles.filter(ob => (ob.isChecked === true)).includes(orderedBundle)) {
                    orderedBundle.isChecked = true;
                }
            });
        }

        this.setState(prevState => ({
            ...prevState,
            loading: false,
            orderedBundles: response.result,
            lastPage: Math.ceil(response.length/this.state.filter.per_page),
            totalElements: response.length,
            filter: {...prevState.filter, page: page}
        }
        ));

    }


    async applyFilter(e, filter) {
        e.preventDefault();
        await this.loadPage(this.state.filter.page);
    }

    //----------
    //Rendering
    //----------

    render() {
        return (
            <div style={{marginBottom: 50}}>
                <div className="content-header">
                    <div className="content-header-title">Ordered Bundles</div>
                    <hr/>
                    <div className="content-header-description"><GrObjectGroup/>&#xA0;Ordered Bundles at https://tiger.media/kombi-paket
                    </div>
                </div>

                {/* FILTER */}
                <div className="content-box-centered">
                    <div className="content-box-title">Filter</div>
                    <div className="content-box-body">
                        <Form onSubmit={(e) => this.applyFilter(e, this.state.filter)}>
                            <Row>
                                <Form.Group className="col-md-3">
                                    <label>E-Mail</label>
                                    <Form.Control type="text" value={this.state.filter.orderEmailAddress} style={{height: "38px"}}
                                           onChange={(e) => this.updateFilter('orderEmailAddress', e.target.value)}/>
                                </Form.Group>
                                <Form.Group className="col-md-3">
                                    <label>Nachname</label>
                                    <Form.Control type="text" value={this.state.filter.lastName} style={{height: "38px"}}
                                                  onChange={(e) => this.updateFilter('lastName', e.target.value)}/>
                                </Form.Group>
                                <Form.Group className="col-md-1">
                                    <label>Farbe</label>
                                    <FormSelect value={this.state.filter.color} style={{cursor: "pointer"}}
                                                  onChange={(e) => this.updateFilter('color', e.target.value)}>
                                        <option value={""}>All</option>
                                        <option value={"BLUE"}>Blau</option>
                                        <option value={"RED"}>Rot</option>
                                        <option value={"GREY"}>Grau</option>
                                        <option value={"BLACK"}>Schwarz</option>
                                        <option value={"PURPLE"}>Violett</option>
                                        <option value={"GREEN"}>Grün</option>
                                        <option value={"YELLOW"}>Gelb</option>
                                    </FormSelect>
                                </Form.Group>
                            </Row>
                            <Row>
                                <Form.Group className="col-md-2" style={{marginLeft: "20px"}}>
                                    <label>Typ Kombi-Paket</label>
                                    <FormSelect value={this.state.filter.planVariantId} style={{cursor: "pointer"}}
                                           onChange={(e) => this.updateFilter('planVariantId', e.target.value)}>
                                        <option value={""}>Alle</option>
                                        <option value={GlobalConstants.BUNDLE_FLEX_PLAN_VARIANT_ID}>Kombi-Paket FLEX</option>
                                        <option value={GlobalConstants.BUNDLE_12_PLAN_VARIANT_ID}>Kombi-Paket 12</option>
                                        <option value={GlobalConstants.BUNDLE_24_PLAN_VARIANT_ID}>Kombi-Paket 24</option>
                                    </FormSelect>
                                </Form.Group>
                                <Form.Group className="col-md-2" style={{marginLeft: "20px"}}>
                                    <label>Vertrags-Status</label>
                                    <FormSelect value={this.state.filter.contractState} style={{cursor: "pointer"}}
                                           onChange={(e) => this.updateFilter('contractState', e.target.value)}>
                                        <option value={""}>Alle</option>
                                        <option value="ACTIVE">Aktiv</option>
                                        <option value="REJECTED">Zurückgewiesen</option>
                                        <option value="CANCELLED">Widerrufen</option>
                                    </FormSelect>
                                </Form.Group>
                                <Form.Group className="col-md-2" style={{marginLeft: "20px"}}>
                                    <label>Bearbeitungs-Status</label>
                                    <FormSelect value={this.state.filter.shipmentState} style={{cursor: "pointer"}}
                                                  onChange={(e) => this.updateFilter('shipmentState', e.target.value)}>
                                        <option value={""}>Alle</option>
                                        <option value="PURCHASE_COMPLETED">Offen</option>
                                        <option value="POSSIBLY_DUPLICATE_RECORD">Mögliche Dublette</option>
                                        <option value="PACKAGED">Erledigt</option>
                                    </FormSelect>
                                </Form.Group>
                            </Row>
                            <hr/>
                            <Row>
                                <div className="col-md-3" style={{marginLeft: "auto"}}>
                                    <button className="form-btn-ci-light" type="button"
                                            onClick={() => this.resetFilter()}>Reset
                                    </button>
                                    <button className="form-btn-ci-blue" type="submit" onClick={(e) => this.applyFilter(e, this.state.filter)}>Apply Filter</button>
                                </div>
                            </Row>
                        </Form>
                    </div>
                </div>

                {/* SHOW THE ORDER LIST*/}
                <div className="content-box-centered">
                    <div className="content-box-title">Liste Bestellungen (Anzahl gefilterter Datensätze: {this.state.totalElements})
                        <div style={{float: "right", fontWeight: "normal", cursor: "pointer"}}
                             onClick={() => this.showOrHideDialog('tableSettings', true)}><FiSettings/></div>
                    </div>
                    <div className="content-box-body">
                        <TableHeader nameInState="orderedBundles"
                                     resourcesurl='purchases/ordered-bundles'
                                     csv={true}
                                     viewOnly={false}
                                     deletionForbidden={false}
                                     sortParams={OrderedBundlesMetaData.ORDERED_BUNDLES_SORT_PARAMS}
                                     state={this.state}
                                     onSetState={(s) => this.setState(s)}
                                     onLoadPage={() => this.loadPage(this.state.filter.page)}/>
                        <hr/>
                        {this.state.loading ?
                            <SpinningTiger />
                        :
                            <ResourcesTable
                                state={this.state}
                                columns={this.state.columns}
                                customcolumndata={[]}
                                dataset={this.state.orderedBundles}

                                page={this.state.filter.page}
                                totalPages={this.state.lastPage}
                                handlePageClick={(event) => this.loadPage(event.selected)}

                                selectable="true"
                                resourcesurl='purchases/ordered-bundles'
                                nameinstate='orderedBundles'
                                onToggleResource={(allBundles) => this.setState(prevState => ({
                                    ...prevState,
                                    orderedBundles: allBundles
                                }))}/>

                        }
                    </div>
                </div>

                {/* TABLE SETTINGS */}
                <TableSettingsDialog show={this.state.showDialog.tableSettings}
                                     columns={this.state.columns}
                                     onSetState={(columns) => this.setState(prevState => ({
                                         ...prevState,
                                         columns: columns
                                     }))}
                                     onHide={() =>this.showOrHideDialog('tableSettings', false)}
                />

                {/* DIALOGS */}
                <ErrorHandler error={this.state.error}
                              onHide={() => this.setState(prevState => ({...prevState, error: null}))} />

            </div>
        );
    }

    //--------
    //ACTIONS
    //--------

    //----------------
    // STATE HANDLING
    //----------------

    updateFilter(id, value) {
        //Get the state and the filter
        let state = this.state;
        let filter = state.filter;

        //Update the attribute
        filter[id] = value;

        //Set the state
        this.setState(prevState => ({...prevState, filter: filter}));
    }

    showOrHideDialog(dialogId, show) {
        //Get the state and the dialog
        let state = this.state;
        let dialogs = state.showDialog;

        //Update the visibility
        dialogs[dialogId] = show;

        //Set the state
        this.setState(prevState => ({...prevState, showDialog: dialogs}));
    }

    async resetFilter(){
        let filter = {page: 0, email: "", productType: "", status: "", type: "", per_page: 20, sortBy: "id", sortDirection: "ASC"};
        this.setState(prevState =>({
            ...prevState,
            filter:filter
        }));
        await this.loadPage(this.state.filter.page);
    }

}

export default withToast(OrderedBundles);