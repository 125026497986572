const BillwerkMetaData = {

    //Columns
    PlanIds: [
        {id: "5d9106bc4802021e047d907e", name: "tigerticket"},
        {id: "5bfe79bcaa67770c3c547cd0", name: "tigertones - Test Product"},
        {id: "5c0d60fcaa67771100b40acf", name: "tigertones - Gutschein 4 Wochen"},
        {id: "5be40bbae369ee0f980a644e", name: "tigerbooks - 1 Monat Trial"},
        {id: "5be40b85aa677716f4ad8d94", name: "tigerbooks - 7 Tage Trial"},
        {id: "5be40b3caa677716f4ad8b62", name: "tigertones - 14 Tage Trial"}
    ],

    PlanVariantIds: [
        {id: "61adf4ddce52a897723d9f64", name: "14 Tage"},
        {id: "5d91072348020014b022e648", name: "1 Monat"},
        {id: "5d9107494802021e047d90b9", name: "3 Monate"},
        {id: "5d91077748020014b022e7d9", name: "6 Monate"},
        {id: "5f03182b34f3e71ce432e7d5", name: "12 Monate"},
        {id: "61557a195512cf8cffbc6e72", name: "1 Monat Advent"},
        {id: "61717de4ef44f7d363eaee8a", name: "digitales tigerticket 1 Monat Bonus"},
        {id: "5fd9db7d712c98882232d118", name: "digitales tigerticket 6 Monate"},
        {id: "605de6b36d28e9dbfcd76051", name: "digitales tigerticket 12 Monate"},
        {id: "605de70a4e5117d39bed9bc3", name: "digitales tigerticket 24 Monate"},
        {id: "619fb9dd9348472f8b8fe0b2", name: "Gutschein tigerticket 1 Woche"},
        {id: "619fba4834695e31d385dea3", name: "Gutschein tigerticket 1 Monat"},
        {id: "619fba8a9348472f8b8fec3b", name: "Gutschein tigerticket 1 Jahr"},
        {id: "620bda7983297689e6a28c08", name: "12 Monate Swiss"},
        {id: "620bda301d00aa7a8d020b86", name: "6 Monate Swiss"},
        {id: "64ff0cc41301624421ad0507", name: "14 Tage Swiss (Design ab 2023)"},
        {id: "5bfe7a36e369ee0224d2c05f", name: "2 Tage Test"},
        {id: "603f4a6e2a1b5038ff4f51f5", name: "1 Monat 2021"},
        {id: "5c0d6293e369ee0f906ae6a7", name: "1 Monat"},
        {id: "5be40da6aa677716f4ad949e", name: "6 Monate"},
        {id: "5be40d63e369ee0f980a6e84", name: "1 Woche"},
        {id: "5be40d84e369ee0f980a7012", name: "1 Monat"},
        {id: "5db9ac6148020219e872b545", name: "1 Monat 2019"},
        {id: "5be40bf0e369ee0f980a6534", name: "1 Monat"},
        {id: "5be40ccbaa677716f4ad905a", name: "6 Monate"},
        {id: "5be40cffaa677716f4ad9151", name: "12 Monate"}
    ]
};

export default BillwerkMetaData;

export function getPlanById(planId) {
    let plan = {id: planId, name: ""};
    for(let i = 0; i < BillwerkMetaData.PlanIds.length; i++) {
        if(BillwerkMetaData.PlanIds[i].id === planId) {
            plan = BillwerkMetaData.PlanIds[i];
            break;
        }
    }
    return plan;
}

export function getPlanVariantById(planVariantId) {
    let plan = {id: planVariantId, name: ""};
    for(let i = 0; i < BillwerkMetaData.PlanVariantIds.length; i++) {
        if(BillwerkMetaData.PlanVariantIds[i].id === planVariantId) {
            plan = BillwerkMetaData.PlanVariantIds[i];
            break;
        }
    }
    return plan;
}


