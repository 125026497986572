import {useState} from "react";
import {Button, Col, Form, FormControl, InputGroup, Table} from "react-bootstrap";
import Row from "react-bootstrap/Row";
import {Link} from "react-router-dom";
import {Badge} from "reactstrap";
import GlobalConstants from "../../config/GlobalConstants";
import {PaginationHelp} from "../../global/PaginationHelp";
import {ProductStateBadges, ProductTypeBadges} from "./ProductMetaData";
import {GetFilteredProducts} from "./ProductService";

export function PickProductDialog(props) {
    let [products, setProducts] = useState({
        data: [],
        filter: {
            page: 0,
            name: "",
            query: "",
            per_page: 20,
        },
        totalPages: 0
    });

    const loadProducts = async (page) => {
        let response = await GetFilteredProducts({page: page, title: products.filter.name});
        if(!response.error) {
            setProducts({
                ...products,
                data: response.result,
                filter: {
                    page: page,
                },
                totalPages: Math.ceil(response.length / 20),
            });
        } else {
            setProducts({...products, error: response});
        }
    }


    return (
        <>
            <Form>
                <Row>
                    <Form.Group as={Col}>
                        <InputGroup>
                            <FormControl as="text" placeholder="Search for a product by its title" value={products.filter.name}
                                         onChange={(e) => setProducts({
                                             ...products,
                                             filter: {
                                                ...products.filter,
                                                 name: e.target.value,
                                             }
                                         })}></FormControl>
                                 <Button variant="outline-secondary" onClick={() => loadProducts(0)}>Search</Button>
                        </InputGroup>
                    </Form.Group>
                </Row>
            </Form>
            <Table responsive bordered hover striped>
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>State</th>
                        <th>Type</th>
                        <th>Title</th>
                        <th>IAP Product Identifier</th>
                    </tr>
                </thead>
                <tbody>
                {products.map(product => (
                    <tr role="row" key={product.id} style={{cursor: "pointer"}}
                        onClick={() => props.onClickProduct(product)}>
                        <td><Link to={"/" + GlobalConstants.APP_PATH + "products/" + product.id} style={{fontStyle: "italic", color: "#333"}}>{product.id}</Link></td>
                        <td><Badge bg={ProductStateBadges(product)}>{product.state}</Badge></td>
                        <td><Badge bg={ProductTypeBadges(product)}>{product.productType}</Badge></td>
                        <td>{product.title}</td>
                        <td>{product.iapProductIdentifier}</td>
                    </tr>
                ))}
                </tbody>
            </Table>
            <div className={"advanced-pagination"}>
                <PaginationHelp
                        initialPage={0}
                        totalPages={products.totalPages}
                        handlePageClick={(event) => loadProducts(event.selected)}
                />
            </div>
        </>
    );
}
