import {Table} from "react-bootstrap";

export function AccountSelectionByCodeDialog(props) {
    const ticket = props.ticket != null ? props.ticket : {};
    const accounts = props.accounts;

    const hasTicketRedeemed = (account) => {
        return ticket.redeemedByAccountId != null && Number(ticket.redeemedByAccountId) === Number(account.id);
    }

    const hasTicketBought = (account) => {
        return ticket.boughtByAccountId != null && Number(ticket.boughtByAccountId) === Number(account.id);
    }

    return(
        <>
            <p>Es wurden mehrere Treffer im Zusammenhang mit dem Zugangscode <strong>{ticket.code}</strong> gefunden. Bitte wähle das gewünschten Konto aus der Liste</p>
            <Table responsive bordered hover striped>
                <thead>
                    <tr>
                        <th>Konto-ID</th>
                        <th>E-Mail</th>
                        <th>Beschreibung</th>
                    </tr>
                </thead>
                <tbody>
                    {accounts.map(acc => (
                        <tr role="row" key={acc.id} style={{cursor: "pointer"}} onClick={() => props.onSelectAccount(acc)}>
                            <td>{acc.id}</td>
                            <td>{acc.email}</td>
                            <td>{hasTicketRedeemed(acc) ? "Hat das Ticket/die Swisscard eingelöst" : (hasTicketBought(acc) ? "Hat das Ticket/die Swisscard gekauft oder erstellt" : "N/A")}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </>
    );
}