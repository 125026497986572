import React, {Component} from "react";
import '../../../App.scss'
import FormSelect from "react-bootstrap/FormSelect";
import {withToast} from "../../util/ToastService";
import {
    FiSettings,
    GrObjectGroup
} from "react-icons/all";
import {ActionModal} from "../../generators/ModalGenerator";
import {ResourcesTable, TableHeader, TableSettingsDialog} from "../../generators/TableGenerator";
import "react-datepicker/dist/react-datepicker.css";
import ClusterMetaData from "./ClusterMetaData";
import {DeleteCluster, GetFilteredClusters} from "./ClusterService";
import {Badge} from "react-bootstrap";
import {openExternalTargetInNewTab} from "../../util/ResourceService";
import {GetFilteredApps} from "../apps/AppService";
import {SpinningTiger} from "../../global/SpinningTiger";
import {ErrorHandler} from "../../util/ErrorHandler";

class Clusters extends Component {

    //------------
    //Constructor
    //------------

    constructor(props) {
        super(props);

        this.state = {
            error: null,
            loading: false,
            clusters: [],
            apps: [],
            filter: props.location.state != null ? props.location.state.filter :
                {page: 1,
                    id: "",
                    visibility: "",
                    ageRangeMin: "",
                    ageRangeMax: "",
                    per_page: 20,
                    sortBy: "id",
                    sortDirection: "ASC"
                },
            lastPage: props.location.state != null ? props.location.state.lastPage : 0,
            columns: props.location.state != null ? props.location.state.columns : ClusterMetaData.COLUMNS,
            customColumnData: [
                {id: 'iconPreview', dataset: []},
                {id: 'layoutItems', dataset: []},
                {id: 'clusterType', dataset: []}
            ],
            showDialog: {
                tableSettings: false,
                deletionModal: false,
                dialog_1: false,
                dialog_2: false,
                dialog_3: false
            },
        };

        this.resetFilter = this.resetFilter.bind(this);
        this.updateFilter = this.updateFilter.bind(this);
        this.showOrHideDialog = this.showOrHideDialog.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.loadClusterList = this.loadClusterList.bind(this);
    }

    //---------
    //Mounting
    //---------

    async componentDidMount() {
        //Set the title
        document.title = "Clusters :: Tiger UI";

        await this.loadApps();
        await this.loadClusterList(this.state.filter);
    }

    //--------
    //Loading
    //--------

    async loadClusterList(filter) {

        this.setState(prevState => ({...prevState, loading: true}));
        let embedded = [];

        const response = await GetFilteredClusters(filter);

        if (response.error === true) {
            this.setState(prevState => ({...prevState, error: response}));
        } else {

            //NOTE: Result is not an array, but an _embedded object -> _embedded: { clusters: [{...,...}]}
            embedded = response.result;

            embedded.forEach(cluster => {
                if (this.state.clusters.filter(ex => (ex.isChecked === true)).includes(cluster)) {
                    cluster.isChecked = true;
                }
                cluster.clusterType = cluster.type;
            });


        }

        //Set the custom data
        let customColumnData = this.state.customColumnData;
        customColumnData[0].dataset = embedded.map(cluster => (
            <img src={cluster["_links"]["icon"]["href"]} alt={"Preview of cluster icon"}
                 height={150}
                 onClick={() => openExternalTargetInNewTab(cluster["_links"]["icon"]["href"])}/>));
        customColumnData[1].dataset = embedded.map(cluster => this.generateItemOverview(cluster));
        customColumnData[2].dataset = embedded.map(cluster => <Badge bg={cluster.clusterType === "HERO" ? "success" : "primary"}>{cluster.clusterType}</Badge> )

        this.setState(prevState => ({
            ...prevState,
            loading: false,
            clusters: embedded,
            lastPage: Math.ceil(response.length / this.state.filter.per_page),
            filter: filter,
            customColumnData: customColumnData,
        }));

    }

    async applyFilter(e, filter) {
        e.preventDefault();
        await this.loadClusterList(filter);
    }

    async loadApps(){
        let loadedApps = await GetFilteredApps({});
        if(!loadedApps.error) {
            this.setState(prevState => ({...prevState, apps: loadedApps.result}));
        } else {
            this.setState(prevState => ({...prevState, error: loadedApps}));
        }
    }

    //----------
    //Rendering
    //----------

    render() {
        return (
            <div style={{marginBottom: 50}}>
                <div className="content-header">
                    <div className="content-header-title">Clusters</div>
                    <hr/>
                    <div className="content-header-description"><GrObjectGroup/>&#xA0;View and maintain clusters for certain apps
                    </div>
                </div>

                {/* FILTER */}
                <div className="content-box-centered">
                    <div className="content-box-title">Filter</div>
                    <div className="content-box-body">
                        <form onSubmit={(e) => this.applyFilter(e, this.state.filter)}>
                            <div className="row">
                                <div className="col-md-2">
                                    <label>Visibility</label><br />
                                    <FormSelect value={this.state.filter.visibility}
                                           onChange={(e) => this.updateFilter('visibility', e.target.value)}>
                                        <option value="">All</option>
                                        <option value="false">Hidden</option>
                                        <option value="true">Visible</option>
                                    </FormSelect>
                                </div>
                                <div className="col-md-2">
                                    <label>Age Min</label>
                                    <input type="number" value={this.state.filter.ageRangeMin}
                                           onChange={(e) => this.updateFilter( 'ageRangeMin', e.target.value)}/>
                                </div>
                                <div className="col-md-2">
                                    <label>Age Max</label>
                                    <input type="number" value={this.state.filter.ageRangeMax}
                                           onChange={(e) => this.updateFilter('ageRangeMax', e.target.value)}/>
                                </div>

                            </div>
                            <hr/>
                            <div className="row">
                                <div className="col-md-3" style={{marginLeft: "auto"}}>
                                    <button className="form-btn-ci-light" type="button"
                                            onClick={() => this.resetFilter()}>Reset
                                    </button>
                                    <button className="form-btn-ci-blue" type="submit"
                                            onClick={(e) => this.applyFilter(e, this.state.filter)}>Apply Filter
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                {/* SHOW THE PRODUCT LIST*/}
                <div className="content-box-centered">
                    <div className="content-box-title">Clusters List
                        <div style={{float: "right", fontWeight: "normal", cursor: "pointer"}}
                             onClick={() => this.showOrHideDialog('tableSettings', true)}><FiSettings/></div>
                    </div>
                    <div className="content-box-body">
                        <TableHeader nameInState="clusters"
                                     viewOnly={false}
                                     deletionForbidden={false}
                                     sortParams={ClusterMetaData.CLUSTER_SORT_PARAMS}
                                     state={this.state}
                                     onSetState={(s) => this.setState(s)}
                                     onLoadPage={() => this.loadClusterList(this.state.filter)}/>
                        <hr/>
                        {this.state.loading ?
                            <SpinningTiger/>
                            :
                            <ResourcesTable
                                state={this.state}
                                columns={this.state.columns}
                                customcolumndata={this.state.customColumnData}
                                dataset={this.state.clusters}

                                page={this.state.filter.page - 1}
                                totalPages={this.state.lastPage}
                                handlePageClick={(event) => this.loadClusterList({...this.state.filter, page: event.selected + 1})}

                                selectable={true}
                                resourcesurl='clusters'
                                nameInState='clusters'
                                onToggleResource={(allClusters) => this.setState(prevState => ({
                                    ...prevState,
                                    clusters: allClusters
                                }))}/>
                        }
                    </div>
                </div>

                {/* TABLE SETTINGS */}
                <TableSettingsDialog show={this.state.showDialog.tableSettings}
                                     columns={this.state.columns}
                                     onSetState={(columns) => this.setState(prevState => ({
                                         ...prevState,
                                         columns: columns
                                     }))}
                                     onHide={() => this.showOrHideDialog('tableSettings', false)}
                />

                {/* DIALOGS */}
                <ActionModal show={this.state.showDialog.deletionModal}
                             onHide={() => this.showOrHideDialog('deletionModal', false)}
                             onAction={() => this.deleteSelectedClusters()}
                             actionButtonText="Delete"
                             title={"Delete clusters"}
                             body={"Are you sure you want to delete the selected clusters?"}/>

                <ErrorHandler error={this.state.error}
                              onHide={() => this.setState(prevState => ({...prevState, error: null}))} />

            </div>
        );
    }

    //--------
    //ACTIONS
    //--------

    async deleteSelectedClusters() {
        this.state.clusters
            .filter(cluster => cluster.isChecked === true)
            .forEach(cluster => DeleteCluster(cluster));

        await this.loadClusterList(this.state.filter);

        this.showOrHideDialog('deletionModal', false);
    }

    //----------------
    // STATE HANDLING
    //----------------

    updateFilter(id, value) {
        //Get the state and the filter
        let state = this.state;
        let filter = state.filter;

        //Update the attribute
        filter[id] = value;

        //Set the state
        this.setState(prevState => ({...prevState, filter: filter}));
    }

    showOrHideDialog(dialogId, show) {
        //Get the state and the dialog
        let state = this.state;
        let dialogs = state.showDialog;

        //Update the visibility
        dialogs[dialogId] = show;

        //Set the state
        this.setState(prevState => ({...prevState, showDialog: dialogs}));
    }

    async resetFilter() {
        let filter = {page: 1, id: "", visibility: "", ageRangeMin: null, ageRangeMax: null, per_page: 20, sortBy: "id", sortDirection: "ASC"};
        this.setState(prevState => ({
            ...prevState,
            filter: filter
        }));
        this.loadClusterList(filter);
    }

    //--------
    // HELPERS
    //--------

    generateItemOverview(cluster) {
        return (
            <ul>
                {Object.keys(cluster.layoutItems).map(key => (
                    <li key={key}>{this.getAppName(key)} - {this.generateItemTypeBade(cluster.layoutItems[key])}</li>
                ))}
            </ul>
        );
    }

    getAppName(item) {
        if(this.state.apps.length > 0) {
            return this.state.apps.filter(app => String(app.id) === item)[0].name;
        } else {
            return item;
        }
    }

    generateItemTypeBade(item) {
        return (
            <Badge bg={
                item.type === "AUDIO_BOOKS" ? "dark" :
                    item.type === "OVERVIEW" ? "light" :
                        item.type === "BOOKS" ? "success" :
                            item.type === "MOVIES" ? "warning" :
                                item.type === "TIGERBOOKS" ? "primare" : "danger"}>{item.type === "AUDIO_BOOKS" ? "AUDIOBOOKS" : item.type}</Badge>
        );
    }

}

export default withToast(Clusters);