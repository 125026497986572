import React, {Component} from "react";
import '../../../App.scss'
import {Form, Col} from "react-bootstrap";
import FormSelect from "react-bootstrap/FormSelect";
import {withToast} from "../../util/ToastService";
import {
    FiSettings,
    GrObjectGroup
} from "react-icons/all";
import {DeleteCollection, GetFilteredCollections} from "./CollectionService";
import {ResourcesTable, TableHeader, TableSettingsDialog} from "../../generators/TableGenerator";
import CollectionMetaData from "./CollectionMetaData";
import {ActionModal} from "../../generators/ModalGenerator";
import {SpinningTiger} from "../../global/SpinningTiger";
import {ErrorHandler} from "../../util/ErrorHandler";

class Collections extends Component {

    //CONSTRUCTOR
    constructor(props) {
        super(props);

        //Filter
        this.filter = {page: 0,
            id: "",
            name: "",
            collectionType: "",
            per_page: 20,
            sortBy: "id",
            sortDirection: "ASC"
        }

        //State
        this.state = {
            error: null,
            loading: false,
            collections: [],
            filter: props.location.state != null ? props.location.state.filter : this.filter,
            totalPages: props.location.state != null ? props.location.state.totalPages : 0,
            columns: props.location.state != null ? props.location.state.columns : CollectionMetaData.COLUMNS,
            customColumnData: [
            ],
            showTableSettings: false,
            showDialog: {
                deletionModal: false,
            },
        };

        //Bind the functions
        this.applyFilter = this.applyFilter.bind(this);
    }

    //Load the screen initially
    async componentDidMount() {
        //Set the title
        document.title = "Collections :: Tiger UI";

        await this.loadPage(this.state.filter.page);
    }

    //Load a certain page
    async loadPage(page) {

        this.setState(prevState => ({...prevState, loading: true}));

        let filter = this.state.filter;
        filter.page = page;
        const loadedCollections = await GetFilteredCollections(filter);

        if (loadedCollections.error === true) {
            this.setState(prevState => ({...prevState, error: loadedCollections}));
        } else {
            loadedCollections.result.forEach(collection => {
                if (this.state.collections.filter(ser => (ser.isChecked === true)).includes(collection)) {
                    collection.isChecked = true;
                }
            });
        }

        this.setState(prevState => ({
            ...prevState,
            loading: false,
            collections: loadedCollections.result,
            totalPages: Math.ceil(loadedCollections.length/this.state.filter.per_page),
            filter: {...prevState.filter, page: page}}));

    }

    render() {

        return (
            <div style={{marginBottom: 50}}>
                <div className="content-header">
                    <div className="content-header-title">Collections</div>
                    <hr/>
                    <div className="content-header-description"><GrObjectGroup/>&#xA0;Manage details and items of Collections.
                    </div>
                </div>

                {/* FILTER */}
                <div className="content-box-centered">
                    <div className="content-box-title">Filter</div>
                    <div className="content-box-body">
                        <form onSubmit={this.applyFilter}>
                            <div className="row">
                                <div className="col-md-3">
                                    <label>ID</label>
                                    <input type="text" value={this.state.filter.id}
                                           onChange={(event) => this.setState(prevState => ({
                                               ...prevState,
                                               filter: {
                                                   ...prevState.filter,
                                                   id: event.target.value,
                                               },
                                           }))}/>
                                </div>
                                <div className="col-md-5">
                                    <label>Name</label>
                                    <input type="text"
                                           value={this.state.filter.name}
                                           onChange={(event) => this.setState(prevState => ({
                                               ...prevState,
                                               filter: {
                                                   ...prevState.filter,
                                                   name: event.target.value,
                                               },
                                           }))}/>
                                </div>
                                <Form.Group as={Col}>
                                    <label>Type</label>
                                    <FormSelect style={{cursor: "pointer"}}
                                                value={this.state.filter.collectionType}
                                                onChange={(event) => this.setState(prevState => ({
                                                    ...prevState,
                                                    filter: {
                                                        ...prevState.filter,
                                                        collectionType: event.target.value,
                                                    },
                                                }))}>

                                        {CollectionMetaData.COLLECTION_TYPES.map(option => (
                                                <option key={option}
                                                        value={option}>{option}</option>
                                        ))}
                                    </FormSelect>
                                </Form.Group>
                            </div>
                            <hr/>
                            <div className="row">
                                <div className="col-md-3" style={{marginLeft: "auto"}}>
                                    <button className="form-btn-ci-light" type="button"
                                            onClick={() => this.setState(prevState => ({
                                                ...prevState,
                                                filter: this.filter
                                            }))}>Reset
                                    </button>
                                    <button className="form-btn-ci-blue" type="submit">Apply Filter</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                {/* Collections LIST*/}
                <div className="content-box-centered">
                    <div className="content-box-title">Collections List
                        <div style={{float: "right", fontWeight: "normal", cursor: "pointer"}}
                             onClick={() => this.setState({showTableSettings: true})}><FiSettings/></div>
                    </div>
                    <div className="content-box-body">
                        <TableHeader nameInState="collections"
                                     viewOnly={false}
                                     deletionForbidden={false}
                                     sortParams={CollectionMetaData.COLLECTION_SORT_PARAMS}
                                     state={this.state}
                                     onSetState={(s) => this.setState(s)}
                                     onLoadPage={() => this.loadPage(this.state.filter.page)}/>
                        <hr/>
                        {this.state.loading ?
                            <SpinningTiger />
                        :
                                <ResourcesTable
                                        state={this.state}
                                        columns={this.state.columns}
                                        customcolumndata={[]}
                                        dataset={this.state.collections}

                                        page={this.state.filter.page}
                                        totalPages={this.state.totalPages}
                                        handlePageClick={(event) => this.loadPage(event.selected)}

                                        selectable="true"
                                        resourcesurl='collections'
                                        nameinstate='collections'
                                        onToggleResource={(allCollections) => this.setState(prevState => ({
                                            ...prevState,
                                            collections: allCollections
                                        }))}/>
                        }
                    </div>
                </div>

                {/* TABLE SETTINGS */}
                <TableSettingsDialog show={this.state.showTableSettings}
                                     columns={this.state.columns}
                                     onSetState={(columns) => this.setState(prevState => ({...prevState, columns: columns}))}
                                     onHide={() => this.setState({showTableSettings: false})}
                />

                {/* DIALOGS */}
                <ActionModal show={this.state.showDialog.deletionModal}
                             onHide={() => this.setState((prevState) => ({...prevState, showDialog: {...prevState.showDialog, deletionModal: false}}))}
                             onAction={() => this.deleteSelectedCollection()}
                             actionButtonText="Delete"
                             title={"Delete Collection"}
                             body={"Are you sure you want to delete the selected collection?"}/>


                <ErrorHandler error={this.state.error}
                              onHide={() => this.setState(prevState => ({...prevState, error: null}))} />

            </div>
        );
    }

    toggleCollection(c) {
        let collections = this.state.collections;
        for (let collection of collections) {
            if (collection.id === c.id) {
                c.isChecked = !c.isChecked;
            }
        }
        this.setState(prevState => ({...prevState, collections: collections}));
    }

    //Filter Handler
    async applyFilter(event) {
        event.preventDefault();

        this.setState(prevState =>({...prevState, collections: []}));
        const loadedCollections = await GetFilteredCollections(this.state.filter);

        if (loadedCollections.error === true) {
            this.setState(prevState => ({...prevState, error: loadedCollections}));
        } else {
            if (loadedCollections.result.length === 0) {
                this.props.addToast("No collections match the filter criteria", {
                    autoDismiss: true,
                    appearance: 'warning'
                });
            }
        }

        this.setState(prevState => ({...prevState, collections: loadedCollections.result}));
    }

    //API Methods
    async deleteSelectedCollection() {
        this.state.collections
            .filter(collections => collections.isChecked === true)
            .forEach(collection => DeleteCollection(collection));

        await this.loadPage(this.state.filter.page);

        this.setState(prevState => ({...prevState, showDialog: {...prevState.showDialog, deletionModal: false}}));
    }
}

export default withToast(Collections);