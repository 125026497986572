import React, {Component} from "react";
import background from "../../../assets/images/support_tool_bg.jpeg";
import {Badge, Tab, Table, Tabs} from "react-bootstrap";
import {withToast} from "../../util/ToastService";
import {FcInvite, GiPresent, IoReloadOutline, RiNumber1, RiNumber2, RiNumber3, RiNumber4} from "react-icons/all";
import {GetFilteredSpineResources} from "../../clients/SpineClient";
import {SpinningTiger} from "../../global/SpinningTiger";
import {
    customDatePickerHeader,
    transformStringToReadableDate,
    transformToLocalDate
} from "../../util/ResourceService";
import DatePicker from "react-datepicker";
import GlobalConstants from "../../config/GlobalConstants";

class SupportStatistics extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            token: props.token,
            mainTab: 'mails',
            mailsTab: 'nps12',
            mailFilter: {
                sentAfter: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
                sentBefore: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
                mailSendStatus: null,
                status: null,
                articleName: null,
                survey: "NPS12",
                page: 1,
                per_page: 999
            },
            presentsFilter: {
                purchasedAfter: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
                purchasedBefore: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
                articleId: "tigerticket.coupon.1",
            },
            mailList: [],
            subscriptionList: [],
        }
    }

    async componentDidMount() {
        await this.loadSurveyMails(this.state.mailFilter);
    }

    render() {
        return (
            <div style={{paddingTop: 30, backgroundImage: `url(${background})`, minHeight: "100vh", height: "auto"}}>
                <div className="new-content-header">
                    <div className="new-content-header-title">Statistiken</div>
                </div>

                <div className="support-tool-box">

                    <Tabs id="statistics-main-tab" activeKey={this.state.mainTab}
                          onSelect={(k) => {
                              if (k === 'mails') {
                                  this.loadSurveyMails({
                                      ...this.state.mailFilter,
                                      mailSentStatus: null,
                                      survey: "NPS12"
                                  }).then(r => r);
                              } else if (k === 'presents') {
                                  this.loadPresents(this.state.presentsFilter).then(r => r);
                              }
                              this.updateState('mainTab', k);
                          }}
                          className="mb-5">

                        {/* MAILS */}
                        <Tab eventKey="mails"
                             title={<span style={{color: "#495057"}}><FcInvite size={24}/> E-Mails</span>}>
                            <form onSubmit={(e) => this.applyMailFilter(e)}>
                                <div className="row">
                                    <div className="col-md-3">
                                        <label>Im Zeitraum vom</label>
                                        <DatePicker wrapperClassName="datePicker"
                                                    todayButton={"Today"}
                                                    dateFormat={"yyyy-MM-dd"}
                                                    renderCustomHeader={({
                                                                             date,
                                                                             changeYear,
                                                                             changeMonth,
                                                                             decreaseMonth,
                                                                             increaseMonth,
                                                                             prevMonthButtonDisabled,
                                                                             nextMonthButtonDisabled
                                                                         }) => customDatePickerHeader(new Date(date), changeYear, changeMonth, decreaseMonth, increaseMonth, prevMonthButtonDisabled, nextMonthButtonDisabled)}
                                                    selected={this.state.mailFilter.sentAfter}
                                                    onChange={(date) => this.setState(prevState => ({
                                                        ...prevState,
                                                        mailFilter: {...prevState.mailFilter, sentAfter: date}
                                                    }))}/>
                                    </div>
                                    <div className="col-md-3">
                                        <label>bis zum</label>
                                        <DatePicker wrapperClassName="datePicker"
                                                    todayButton={"Today"}
                                                    dateFormat={"yyyy-MM-dd"}
                                                    renderCustomHeader={({
                                                                             date,
                                                                             changeYear,
                                                                             changeMonth,
                                                                             decreaseMonth,
                                                                             increaseMonth,
                                                                             prevMonthButtonDisabled,
                                                                             nextMonthButtonDisabled
                                                                         }) => customDatePickerHeader(new Date(date), changeYear, changeMonth, decreaseMonth, increaseMonth, prevMonthButtonDisabled, nextMonthButtonDisabled)}
                                                    selected={this.state.mailFilter.sentBefore}
                                                    onChange={(date) => this.setState(prevState => ({
                                                        ...prevState,
                                                        mailFilter: {...prevState.mailFilter, sentBefore: date}
                                                    }))}/>
                                    </div>
                                    <div className="col-md-3">
                                        <button className="form-btn-ci-light" style={{marginTop: "38px"}}
                                                onClick={(e) => this.applyMailFilter(e)}><IoReloadOutline
                                            size={24}/> Aktualisieren
                                        </button>
                                    </div>
                                </div>
                            </form>
                            <br/>
                            <hr/>
                            <Tabs id="mail-statistics-tab" activeKey={this.state.mailsTab}
                                  onSelect={(k) => {
                                      this.updateState('mailsTab', k);
                                      if (k === 'nps12') {
                                          this.loadSurveyMails({
                                              ...this.state.mailFilter,
                                              page: 1,
                                              survey: "NPS12",
                                              articleName: null
                                          }).then(r => r);
                                      } else if (k === 'expired_tigerticket') {
                                          this.loadSurveyMails({
                                              ...this.state.mailFilter,
                                              page: 1,
                                              survey: "EXPIRED_TIGERTICKET",
                                              articleName: null
                                          }).then(r => r);
                                      } else if (k === 'expiring_tigertickets') {
                                          this.loadExpiringTigerticketMails({
                                              ...this.state.mailFilter,
                                              page: 1,
                                              survey: null,
                                              articleName: null
                                          }).then(r => r);
                                      } else if (k === 'retrieval_mail') {
                                          this.loadRetrievalMails({
                                              ...this.state.mailFilter,
                                              page: 1,
                                              survey: null
                                          }).then(r => r);
                                      }
                                  }}
                                  className="mb-5">

                                {/* NPS 12 SURVEY MAILS*/}
                                <Tab eventKey="nps12" title={<span style={{color: "#495057"}}><RiNumber1 size={16}/> Umfrage: NPS12</span>}>
                                    {this.state.loading ?
                                        <SpinningTiger/>
                                        :
                                        <>
                                            <div style={{float: "left", marginBottom: "20px"}}>
                                                <div>
                                                    <Badge bg="primary" style={{
                                                        fontSize: "18px",
                                                        margin: "5px"
                                                    }}>Eingereiht: {this.state.mailList.filter(m => m.mailSendStatus === "PENDING").length}</Badge>
                                                    <Badge bg="secondary"
                                                           style={{fontSize: "18px", margin: "5px"}}>Im
                                                        Versand: {this.state.mailList.filter(m => m.mailSendStatus === "SENDING").length}</Badge>
                                                    <Badge bg="success" style={{
                                                        fontSize: "18px",
                                                        margin: "5px"
                                                    }}>Erfolgreich: {this.state.mailList.filter(m => m.mailSendStatus === "SUCCESSFUL").length}</Badge>
                                                    <Badge bg="danger" style={{
                                                        fontSize: "18px",
                                                        margin: "5px"
                                                    }}>Fehlerhaft: {this.state.mailList.filter(m => m.mailSendStatus === "FAILED").length}</Badge>
                                                    <Badge bg="warning" style={{
                                                        fontSize: "18px",
                                                        margin: "5px"
                                                    }}>Unbekannt: {this.state.mailList.filter(m => (m.mailSendStatus !== "PENDING" && m.mailSendStatus !== "SENDING" && m.mailSendStatus !== "SUCCESSFUL" && m.mailSendStatus !== "FAILED")).length}</Badge>
                                                </div>
                                            </div>
                                            <Table responsive bordered hover striped>
                                                <thead>
                                                <tr>
                                                    <th colSpan="2" style={{textAlign: "center"}}>Kunde</th>
                                                    <th colSpan="3" style={{textAlign: "center"}}>Status</th>
                                                </tr>
                                                <tr>
                                                    <th>ID</th>
                                                    <th>E-Mail</th>
                                                    <th>Versandstatus</th>
                                                    <th>Fehlversuche</th>
                                                    <th>Versand am</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {this.state.mailList.map(mail => (
                                                    <tr role="row" key={mail.id}>
                                                        <td>{mail.accountId}</td>
                                                        <td>{mail.email}</td>
                                                        <td>
                                                            <Badge
                                                                    bg={mail.mailSendStatus === "PENDING" ? "primary" : (mail.mailSendStatus === "SENDING" ? "secondary" : (mail.mailSendStatus === "SUCCESSFUL" ? "success" : (mail.mailSendStatus === "FAILED" ? "danger" : "warning")))}>
                                                                {mail.mailSendStatus === "PENDING" ? "Eingereiht" : (mail.mailSendStatus === "SENDING" ? "Im Versand" : (mail.mailSendStatus === "SUCCESSFUL" ? "Erfolgreich" : (mail.mailSendStatus === "FAILED" ? "Fehlgeschlagen" : "Unbekannt")))}
                                                            </Badge>
                                                        </td>
                                                        <td>{mail.numberRetries !== null ? mail.numberRetries : "---"}</td>
                                                        <td>{mail.mailSendStatus === "SUCCESSFUL" ? transformStringToReadableDate(mail.lastModifiedAt) : "---"}</td>
                                                    </tr>
                                                ))}
                                                </tbody>
                                            </Table>
                                        </>
                                    }
                                </Tab>

                                {/* EXPIRED TIGERTICKET SURVEY MAILS*/}
                                <Tab eventKey="expired_tigerticket"
                                     title={<span style={{color: "#495057"}}><RiNumber2 size={16}/> Umfrage: Warum kein neues tigerticket?</span>}>
                                    {this.state.loading ?
                                        <SpinningTiger/>
                                        :
                                        <>
                                            <div style={{float: "left", marginBottom: "20px"}}>
                                                <div>
                                                    <Badge bg="primary" style={{
                                                        fontSize: "18px",
                                                        margin: "5px"
                                                    }}>Eingereiht: {this.state.mailList.filter(m => m.mailSendStatus === "PENDING").length}</Badge>
                                                    <Badge bg="secondary"
                                                           style={{fontSize: "18px", margin: "5px"}}>Im
                                                        Versand: {this.state.mailList.filter(m => m.mailSendStatus === "SENDING").length}</Badge>
                                                    <Badge bg="success" style={{
                                                        fontSize: "18px",
                                                        margin: "5px"
                                                    }}>Erfolgreich: {this.state.mailList.filter(m => m.mailSendStatus === "SUCCESSFUL").length}</Badge>
                                                    <Badge bg="danger" style={{
                                                        fontSize: "18px",
                                                        margin: "5px"
                                                    }}>Fehlerhaft: {this.state.mailList.filter(m => m.mailSendStatus === "FAILED").length}</Badge>
                                                    <Badge bg="warning" style={{
                                                        fontSize: "18px",
                                                        margin: "5px"
                                                    }}>Unbekannt: {this.state.mailList.filter(m => (m.mailSendStatus !== "PENDING" && m.mailSendStatus !== "SENDING" && m.mailSendStatus !== "SUCCESSFUL" && m.mailSendStatus !== "FAILED")).length}</Badge>
                                                </div>
                                            </div>
                                            <Table responsive bordered hover striped>
                                                <thead>
                                                <tr>
                                                    <th colSpan="2" style={{textAlign: "center"}}>Kunde</th>
                                                    <th colSpan="3" style={{textAlign: "center"}}>Status</th>
                                                </tr>
                                                <tr>
                                                    <th>ID</th>
                                                    <th>E-Mail</th>
                                                    <th>Versandstatus</th>
                                                    <th>Fehlversuche</th>
                                                    <th>Versand am</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {this.state.mailList.map(mail => (
                                                    <tr role="row" key={mail.id}>
                                                        <td>{mail.accountId}</td>
                                                        <td>{mail.email}</td>
                                                        <td>
                                                            <Badge
                                                                variant={mail.mailSendStatus === "PENDING" ? "primary" : (mail.mailSendStatus === "SENDING" ? "secondary" : (mail.mailSendStatus === "SUCCESSFUL" ? "success" : (mail.mailSendStatus === "FAILED" ? "danger" : "warning")))}>
                                                                {mail.mailSendStatus === "PENDING" ? "Eingereiht" : (mail.mailSendStatus === "SENDING" ? "Im Versand" : (mail.mailSendStatus === "SUCCESSFUL" ? "Erfolgreich" : (mail.mailSendStatus === "FAILED" ? "Fehlgeschlagen" : "Unbekannt")))}
                                                            </Badge>
                                                        </td>
                                                        <td>{mail.numberRetries !== null ? mail.numberRetries : "---"}</td>
                                                        <td>{mail.mailSendStatus === "SUCCESSFUL" ? transformStringToReadableDate(mail.lastModifiedAt) : "---"}</td>
                                                    </tr>
                                                ))}
                                                </tbody>
                                            </Table>
                                        </>
                                    }
                                </Tab>

                                {/* RETRIEVAL MAILS*/}
                                <Tab eventKey="retrieval_mail"
                                     title={<span style={{color: "#495057"}}><RiNumber3 size={16}/> CRM: Willst du diese Vorteile verpassen?</span>}>
                                    {this.state.loading ?
                                        <SpinningTiger/>
                                        :
                                        <>
                                            <div style={{float: "left", marginBottom: "5px", width: "100%"}}>
                                                <div>
                                                    <Badge bg="info" style={{
                                                        fontSize: "18px",
                                                        margin: "5px",
                                                        width: "150px"
                                                    }}>Status:</Badge>
                                                    <Badge bg="primary" style={{
                                                        fontSize: "18px",
                                                        margin: "5px",
                                                        width: "150px"
                                                    }}>Eingereiht: {this.state.mailList.filter(m => m.mailSendStatus === "PENDING").length}</Badge>
                                                    <Badge bg="secondary"
                                                           style={{fontSize: "18px", margin: "5px", width: "150px"}}>Im
                                                        Versand: {this.state.mailList.filter(m => m.mailSendStatus === "SENDING").length}</Badge>
                                                    <Badge bg="success" style={{
                                                        fontSize: "18px",
                                                        margin: "5px",
                                                        width: "150px"
                                                    }}>Erfolgreich: {this.state.mailList.filter(m => m.mailSendStatus === "SUCCESSFUL").length}</Badge>
                                                    <Badge bg="danger" style={{
                                                        fontSize: "18px",
                                                        margin: "5px",
                                                        width: "150px"
                                                    }}>Fehlerhaft: {this.state.mailList.filter(m => m.mailSendStatus === "FAILED").length}</Badge>
                                                    <Badge bg="warning" style={{
                                                        fontSize: "18px",
                                                        margin: "5px",
                                                        width: "150px"
                                                    }}>Unbekannt: {this.state.mailList.filter(m => (m.mailSendStatus !== "PENDING" && m.mailSendStatus !== "SENDING" && m.mailSendStatus !== "SUCCESSFUL" && m.mailSendStatus !== "FAILED")).length}</Badge>
                                                </div>
                                            </div>
                                            <div style={{float: "left", marginBottom: "0px"}}>
                                                <div>
                                                    <Badge bg="info" style={{
                                                        fontSize: "18px",
                                                        margin: "5px",
                                                        width: "150px"
                                                    }}>Geschenke:</Badge>
                                                    <Badge bg="light" style={{
                                                        fontSize: "18px",
                                                        margin: "5px",
                                                        width: "150px"
                                                    }}>{this.state.mailList.filter(m => m.mailSendStatus === "PENDING").length > 0 ? this.state.mailList.filter(m => (m.mailSendStatus === "PENDING" && m.articleName.indexOf("coupon") > -1)).length : "0"}</Badge>
                                                    <Badge bg="light" style={{
                                                        fontSize: "18px",
                                                        margin: "5px",
                                                        width: "150px"
                                                    }}>{this.state.mailList.filter(m => m.mailSendStatus === "SENDING").length > 0 ? this.state.mailList.filter(m => (m.mailSendStatus === "SENDING" && m.articleName.indexOf("coupon") > -1)).length : "0"}</Badge>
                                                    <Badge bg="light" style={{
                                                        fontSize: "18px",
                                                        margin: "5px",
                                                        width: "150px"
                                                    }}>{this.state.mailList.filter(m => m.mailSendStatus === "SUCCESSFUL").length > 0 ? this.state.mailList.filter(m => (m.mailSendStatus === "SUCCESSFUL" && m.articleName.indexOf("coupon") > -1)).length : "0"}</Badge>
                                                    <Badge bg="light" style={{
                                                        fontSize: "18px",
                                                        margin: "5px",
                                                        width: "150px"
                                                    }}>{this.state.mailList.filter(m => m.mailSendStatus === "FAILED").length > 0 ? this.state.mailList.filter(m => (m.mailSendStatus === "FAILED" && m.articleName.indexOf("coupon") > -1)).length : "0"}</Badge>
                                                    <Badge bg="light" style={{
                                                        fontSize: "18px",
                                                        margin: "5px",
                                                        width: "150px"
                                                    }}>{this.state.mailList.filter(m => (m.mailSendStatus !== "PENDING" && m.mailSendStatus !== "SENDING" && m.mailSendStatus !== "SUCCESSFUL" && m.mailSendStatus !== "FAILED")).length > 0 ? this.state.mailList.filter(m => (m.mailSendStatus !== "PENDING" && m.mailSendStatus !== "SENDING" && m.mailSendStatus !== "SUCCESSFUL" && m.mailSendStatus !== "FAILED")).filter(m => m.articleName.indexOf("coupon") > -1).length : "0"}</Badge>
                                                </div>
                                            </div>
                                            <div style={{float: "left", marginBottom: "0px"}}>
                                                <div>
                                                    <Badge bg="info" style={{
                                                        fontSize: "18px",
                                                        margin: "5px",
                                                        width: "150px"
                                                    }}>tigertickets:</Badge>
                                                    <Badge bg="light" style={{
                                                        fontSize: "18px",
                                                        margin: "5px",
                                                        width: "150px"
                                                    }}>{this.state.mailList.filter(m => m.mailSendStatus === "PENDING").length > 0 ? this.state.mailList.filter(m => (m.mailSendStatus === "PENDING" && m.articleName.indexOf("tigerticket") > -1) && m.articleName.indexOf("coupon") < 0).length : "0"}</Badge>
                                                    <Badge bg="light" style={{
                                                        fontSize: "18px",
                                                        margin: "5px",
                                                        width: "150px"
                                                    }}>{this.state.mailList.filter(m => m.mailSendStatus === "SENDING").length > 0 ? this.state.mailList.filter(m => (m.mailSendStatus === "SENDING" && m.articleName.indexOf("tigerticket") > -1) && m.articleName.indexOf("coupon") < 0).length : "0"}</Badge>
                                                    <Badge bg="light" style={{
                                                        fontSize: "18px",
                                                        margin: "5px",
                                                        width: "150px"
                                                    }}>{this.state.mailList.filter(m => m.mailSendStatus === "SUCCESSFUL").length > 0 ? this.state.mailList.filter(m => (m.mailSendStatus === "SUCCESSFUL" && m.articleName.indexOf("tigerticket") > -1) && m.articleName.indexOf("coupon") < 0).length : "0"}</Badge>
                                                    <Badge bg="light" style={{
                                                        fontSize: "18px",
                                                        margin: "5px",
                                                        width: "150px"
                                                    }}>{this.state.mailList.filter(m => m.mailSendStatus === "FAILED").length > 0 ? this.state.mailList.filter(m => (m.mailSendStatus === "FAILED" && m.articleName.indexOf("tigerticket") > -1) && m.articleName.indexOf("coupon") < 0).length : "0"}</Badge>
                                                    <Badge bg="light" style={{
                                                        fontSize: "18px",
                                                        margin: "5px",
                                                        width: "150px"
                                                    }}>{this.state.mailList.filter(m => (m.mailSendStatus !== "PENDING" && m.mailSendStatus !== "SENDING" && m.mailSendStatus !== "SUCCESSFUL" && m.mailSendStatus !== "FAILED")).length > 0 ? this.state.mailList.filter(m => (m.mailSendStatus !== "PENDING" && m.mailSendStatus !== "SENDING" && m.mailSendStatus !== "SUCCESSFUL" && m.mailSendStatus !== "FAILED")).filter(m => (m.articleName.indexOf("tigerticket") > -1 && m.articleName.indexOf("coupon") < 0)).length : "0"}</Badge>
                                                </div>
                                            </div>
                                            <div style={{float: "left", marginBottom: "20px"}}>
                                                <div>
                                                    <Badge bg="info" style={{
                                                        fontSize: "18px",
                                                        margin: "5px",
                                                        width: "150px"
                                                    }}>Abos:</Badge>
                                                    <Badge bg="light" style={{
                                                        fontSize: "18px",
                                                        margin: "5px",
                                                        width: "150px"
                                                    }}>{this.state.mailList.filter(m => m.mailSendStatus === "PENDING").length > 0 ? this.state.mailList.filter(m => (m.mailSendStatus === "PENDING" && m.articleName.indexOf("coupon") < 0 && m.articleName.indexOf("tigerticket") < 0)).length : "0"}</Badge>
                                                    <Badge bg="light" style={{
                                                        fontSize: "18px",
                                                        margin: "5px",
                                                        width: "150px"
                                                    }}>{this.state.mailList.filter(m => m.mailSendStatus === "SENDING").length > 0 ? this.state.mailList.filter(m => (m.mailSendStatus === "SENDING" && m.articleName.indexOf("coupon") < 0 && m.articleName.indexOf("tigerticket") < 0)).length : "0"}</Badge>
                                                    <Badge bg="light" style={{
                                                        fontSize: "18px",
                                                        margin: "5px",
                                                        width: "150px"
                                                    }}>{this.state.mailList.filter(m => m.mailSendStatus === "SUCCESSFUL").length > 0 ? this.state.mailList.filter(m => (m.mailSendStatus === "SUCCESSFUL" && m.articleName.indexOf("coupon") < 0 && m.articleName.indexOf("tigerticket") < 0)).length : "0"}</Badge>
                                                    <Badge bg="light" style={{
                                                        fontSize: "18px",
                                                        margin: "5px",
                                                        width: "150px"
                                                    }}>{this.state.mailList.filter(m => m.mailSendStatus === "FAILED").length > 0 ? this.state.mailList.filter(m => (m.mailSendStatus === "FAILED" && m.articleName.indexOf("coupon") < 0 && m.articleName.indexOf("tigerticket") < 0)).length : "0"}</Badge>
                                                    <Badge bg="light" style={{
                                                        fontSize: "18px",
                                                        margin: "5px",
                                                        width: "150px"
                                                    }}>{this.state.mailList.filter(m => (m.mailSendStatus !== "PENDING" && m.mailSendStatus !== "SENDING" && m.mailSendStatus !== "SUCCESSFUL" && m.mailSendStatus !== "FAILED")).length > 0 ? this.state.mailList.filter(m => (m.mailSendStatus !== "PENDING" && m.mailSendStatus !== "SENDING" && m.mailSendStatus !== "SUCCESSFUL" && m.mailSendStatus !== "FAILED")).filter(m => m.articleName.indexOf("coupon") < 0 && m.articleName.indexOf("tigerticket") < 0).length : "0"}</Badge>
                                                </div>
                                            </div>
                                            <Table responsive bordered hover striped>
                                                <thead>
                                                <tr>
                                                    <th colSpan="3" style={{textAlign: "center"}}>Kunde</th>
                                                    <th colSpan="3" style={{textAlign: "center"}}>Status</th>
                                                </tr>
                                                <tr>
                                                    <th>ID</th>
                                                    <th>E-Mail</th>
                                                    <th>Premium-Typ</th>
                                                    <th>Versandstatus</th>
                                                    <th>Fehlversuche</th>
                                                    <th>Versand am</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {this.state.mailList.map(mail => (
                                                    <tr role="row" key={mail.id}>
                                                        <td>{mail.accountId}</td>
                                                        <td>{mail.email}</td>
                                                        <td>

                                                            <Badge
                                                                variant={mail.articleName !== undefined ? (mail.articleName.indexOf("coupon") > -1 ? "success" : (mail.articleName.indexOf("tigerticket") > -1 ? "danger" : "primary")) : "danger"}>
                                                                {mail.articleName !== undefined ? (mail.articleName.indexOf("coupon") > -1 ? "Geschenk" : (mail.articleName.indexOf("tigerticket") > -1 ? "tigerticket" : "Abo")) : ""}
                                                            </Badge>
                                                        </td>
                                                        <td>
                                                            <Badge
                                                                variant={mail.mailSendStatus === "PENDING" ? "primary" : (mail.mailSendStatus === "SENDING" ? "secondary" : (mail.mailSendStatus === "SUCCESSFUL" ? "success" : (mail.mailSendStatus === "FAILED" ? "danger" : "warning")))}>
                                                                {mail.mailSendStatus === "PENDING" ? "Eingereiht" : (mail.mailSendStatus === "SENDING" ? "Im Versand" : (mail.mailSendStatus === "SUCCESSFUL" ? "Erfolgreich" : (mail.mailSendStatus === "FAILED" ? "Fehlgeschlagen" : "Unbekannt")))}
                                                            </Badge>
                                                        </td>
                                                        <td>{mail.numberRetries !== null ? mail.numberRetries : "---"}</td>
                                                        <td>{mail.mailSendStatus === "SUCCESSFUL" ? transformStringToReadableDate(mail.lastModifiedDate) : "---"}</td>
                                                    </tr>
                                                ))}
                                                </tbody>
                                            </Table>
                                        </>
                                    }
                                </Tab>

                                {/* EXPIRING TIGERTICKETS*/}
                                <Tab eventKey="expiring_tigertickets"
                                     title={<span style={{color: "#495057"}}><RiNumber4 size={16}/> CRM: Dein Ticket läuft bald ab</span>}>
                                    {this.state.loading ?
                                        <SpinningTiger/>
                                        :
                                        <>
                                            <div style={{float: "left", marginBottom: "20px"}}>
                                                <div>
                                                    <Badge bg="primary" style={{
                                                        fontSize: "18px",
                                                        margin: "5px"
                                                    }}>Eingereiht: {this.state.mailList.filter(m => m.mailSendStatus === "PENDING").length}</Badge>
                                                    <Badge bg="secondary"
                                                           style={{fontSize: "18px", margin: "5px"}}>Im
                                                        Versand: {this.state.mailList.filter(m => m.mailSendStatus === "SENDING").length}</Badge>
                                                    <Badge bg="success" style={{
                                                        fontSize: "18px",
                                                        margin: "5px"
                                                    }}>Erfolgreich: {this.state.mailList.filter(m => m.mailSendStatus === "SUCCESSFUL").length}</Badge>
                                                    <Badge bg="danger" style={{
                                                        fontSize: "18px",
                                                        margin: "5px"
                                                    }}>Fehlerhaft: {this.state.mailList.filter(m => m.mailSendStatus === "FAILED").length}</Badge>
                                                    <Badge bg="warning" style={{
                                                        fontSize: "18px",
                                                        margin: "5px"
                                                    }}>Unbekannt: {this.state.mailList.filter(m => (m.mailSendStatus !== "PENDING" && m.mailSendStatus !== "SENDING" && m.mailSendStatus !== "SUCCESSFUL" && m.mailSendStatus !== "FAILED")).length}</Badge>
                                                </div>
                                            </div>
                                            <Table responsive bordered hover striped>
                                                <thead>
                                                <tr>
                                                    <th colSpan="3" style={{textAlign: "center"}}>Kunde</th>
                                                    <th colSpan="3" style={{textAlign: "center"}}>Status</th>
                                                </tr>
                                                <tr>
                                                    <th>ID</th>
                                                    <th>E-Mail</th>
                                                    <th>Subscription ID</th>
                                                    <th>Versandstatus</th>
                                                    <th>Fehlversuche</th>
                                                    <th>Versand am</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {this.state.mailList.map(mail => (
                                                    <tr role="row" key={mail.id}>
                                                        <td>{mail.accountId}</td>
                                                        <td>{mail.email}</td>
                                                        <td>{mail.subscriptionId}</td>
                                                        <td>
                                                            <Badge
                                                                variant={mail.mailSendStatus === "PENDING" ? "primary" : (mail.mailSendStatus === "SENDING" ? "secondary" : (mail.mailSendStatus === "SUCCESSFUL" ? "success" : (mail.mailSendStatus === "FAILED" ? "danger" : "warning")))}>
                                                                {mail.mailSendStatus === "PENDING" ? "Eingereiht" : (mail.mailSendStatus === "SENDING" ? "Im Versand" : (mail.mailSendStatus === "SUCCESSFUL" ? "Erfolgreich" : (mail.mailSendStatus === "FAILED" ? "Fehlgeschlagen" : "Unbekannt")))}
                                                            </Badge>
                                                        </td>
                                                        <td>{mail.numberRetries !== null ? mail.numberRetries : "---"}</td>
                                                        <td>{mail.mailSendStatus === "SUCCESSFUL" ? transformStringToReadableDate(mail.lastModifiedDate) : "---"}</td>
                                                    </tr>
                                                ))}
                                                </tbody>
                                            </Table>
                                        </>
                                    }
                                </Tab>
                            </Tabs>
                        </Tab>

                        {/* GESCHENKE */}
                        <Tab eventKey="presents"
                             title={<span style={{color: "#495057"}}><GiPresent size={24}/> Geschenke</span>}>
                            <form onSubmit={(e) => this.applySubscriptionFilter(e)}>
                                <div className="row">
                                    <div className="col-md-3">
                                        <label>Im Zeitraum vom</label>
                                        <DatePicker wrapperClassName="datePicker"
                                                    todayButton={"Today"}
                                                    dateFormat={"yyyy-MM-dd"}
                                                    renderCustomHeader={({
                                                                             date,
                                                                             changeYear,
                                                                             changeMonth,
                                                                             decreaseMonth,
                                                                             increaseMonth,
                                                                             prevMonthButtonDisabled,
                                                                             nextMonthButtonDisabled
                                                                         }) => customDatePickerHeader(new Date(date), changeYear, changeMonth, decreaseMonth, increaseMonth, prevMonthButtonDisabled, nextMonthButtonDisabled)}
                                                    selected={this.state.presentsFilter.purchasedAfter}
                                                    onChange={(date) => this.setState(prevState => ({
                                                        ...prevState,
                                                        presentsFilter: {...prevState.presentsFilter, purchasedAfter: date}
                                                    }))}/>
                                    </div>
                                    <div className="col-md-3">
                                        <label>bis zum</label>
                                        <DatePicker wrapperClassName="datePicker"
                                                    todayButton={"Today"}
                                                    dateFormat={"yyyy-MM-dd"}
                                                    renderCustomHeader={({
                                                                             date,
                                                                             changeYear,
                                                                             changeMonth,
                                                                             decreaseMonth,
                                                                             increaseMonth,
                                                                             prevMonthButtonDisabled,
                                                                             nextMonthButtonDisabled
                                                                         }) => customDatePickerHeader(new Date(date), changeYear, changeMonth, decreaseMonth, increaseMonth, prevMonthButtonDisabled, nextMonthButtonDisabled)}
                                                    selected={this.state.presentsFilter.purchasedBefore}
                                                    onChange={(date) => this.setState(prevState => ({
                                                        ...prevState,
                                                        presentsFilter: {...prevState.presentsFilter, purchasedBefore: date}
                                                    }))}/>
                                    </div>
                                    <div className="col-md-3">
                                        <button className="form-btn-ci-light" style={{marginTop: "38px"}}
                                                onClick={(e) => this.applySubscriptionFilter(e)}><IoReloadOutline
                                            size={24}/> Aktualisieren
                                        </button>
                                    </div>
                                </div>
                            </form>
                            <br/>
                            <hr/>
                            <div>
                                {this.state.loading ?
                                    <SpinningTiger />
                                :
                                    <>
                                        <div style={{float: "left", marginBottom: "5px", width: "100%"}}>
                                            <div>
                                                <Badge bg="info" style={{
                                                    fontSize: "18px",
                                                    margin: "5px",
                                                    width: "150px"
                                                }}>Laufzeit:</Badge>
                                                <Badge bg="primary" style={{
                                                    fontSize: "18px",
                                                    margin: "5px",
                                                    width: "150px"
                                                }}>Eine Woche: {this.state.subscriptionList.filter(s => s.articleId === GlobalConstants.PRODUCT_IAP_1_WEEK_PREMIUMACCESS).length}</Badge>
                                                <Badge bg="secondary"
                                                       style={{fontSize: "18px", margin: "5px", width: "150px"}}>
                                                    Ein Monat:  {this.state.subscriptionList.filter(s => s.articleId === GlobalConstants.PRODUCT_IAP_1_MONTH_PREMIUMACCESS).length}</Badge>
                                                <Badge bg="success" style={{
                                                    fontSize: "18px",
                                                    margin: "5px",
                                                    width: "150px"
                                                }}>Ein Jahr: {this.state.subscriptionList.filter(s => s.articleId === GlobalConstants.PRODUCT_IAP_1_YEAR_PREMIUMACCESS).length}</Badge>
                                            </div>
                                        </div>
                                        <div style={{float: "left", marginBottom: "0px", width: "100%"}}>
                                            <div>
                                                <Badge bg="info" style={{
                                                    fontSize: "18px",
                                                    margin: "5px",
                                                    width: "150px"
                                                }}>tigertones:</Badge>
                                                <Badge bg="light" style={{
                                                    fontSize: "18px",
                                                    margin: "5px",
                                                    width: "150px"
                                                }}>{this.state.subscriptionList.filter(s => s.articleId === GlobalConstants.PRODUCT_IAP_1_WEEK_PREMIUMACCESS).length > 0 ? this.state.subscriptionList.filter(s => (s.articleId === GlobalConstants.PRODUCT_IAP_1_WEEK_PREMIUMACCESS && s.productGroupId === Number(GlobalConstants.PRODUCT_GROUP_ID_TIGERTONES))).length : "0"}</Badge>
                                                <Badge bg="light" style={{
                                                    fontSize: "18px",
                                                    margin: "5px",
                                                    width: "150px"
                                                }}>{this.state.subscriptionList.filter(s => s.articleId === GlobalConstants.PRODUCT_IAP_1_MONTH_PREMIUMACCESS).length > 0 ? this.state.subscriptionList.filter(s => (s.articleId === GlobalConstants.PRODUCT_IAP_1_MONTH_PREMIUMACCESS && s.productGroupId === Number(GlobalConstants.PRODUCT_GROUP_ID_TIGERTONES))).length : "0"}</Badge>
                                                <Badge bg="light" style={{
                                                    fontSize: "18px",
                                                    margin: "5px",
                                                    width: "150px"
                                                }}>{this.state.subscriptionList.filter(s => s.articleId === GlobalConstants.PRODUCT_IAP_1_YEAR_PREMIUMACCESS).length > 0 ? this.state.subscriptionList.filter(s => (s.articleId === GlobalConstants.PRODUCT_IAP_1_YEAR_PREMIUMACCESS && s.productGroupId === Number(GlobalConstants.PRODUCT_GROUP_ID_TIGERTONES))).length : "0"}</Badge>
                                            </div>
                                        </div>
                                        <div style={{float: "left", marginBottom: "20px"}}>
                                            <div>
                                                <Badge bg="info" style={{
                                                    fontSize: "18px",
                                                    margin: "5px",
                                                    width: "150px"
                                                }}>tigerbooks:</Badge>
                                                <Badge bg="light" style={{
                                                    fontSize: "18px",
                                                    margin: "5px",
                                                    width: "150px"
                                                }}>{this.state.subscriptionList.filter(s => s.articleId === GlobalConstants.PRODUCT_IAP_1_WEEK_PREMIUMACCESS).length > 0 ? this.state.subscriptionList.filter(s => (s.articleId === GlobalConstants.PRODUCT_IAP_1_WEEK_PREMIUMACCESS && s.productGroupId === Number(GlobalConstants.PRODUCT_GROUP_ID_TIGERBOOKS))).length : "0"}</Badge>
                                                <Badge bg="light" style={{
                                                    fontSize: "18px",
                                                    margin: "5px",
                                                    width: "150px"
                                                }}>{this.state.subscriptionList.filter(s => s.articleId === GlobalConstants.PRODUCT_IAP_1_MONTH_PREMIUMACCESS).length > 0 ? this.state.subscriptionList.filter(s => (s.articleId === GlobalConstants.PRODUCT_IAP_1_MONTH_PREMIUMACCESS && s.productGroupId === Number(GlobalConstants.PRODUCT_GROUP_ID_TIGERBOOKS))).length : "0"}</Badge>
                                                <Badge bg="light" style={{
                                                    fontSize: "18px",
                                                    margin: "5px",
                                                    width: "150px"
                                                }}>{this.state.subscriptionList.filter(s => s.articleId === GlobalConstants.PRODUCT_IAP_1_YEAR_PREMIUMACCESS).length > 0 ? this.state.subscriptionList.filter(s => (s.articleId === GlobalConstants.PRODUCT_IAP_1_YEAR_PREMIUMACCESS && s.productGroupId === Number(GlobalConstants.PRODUCT_GROUP_ID_TIGERBOOKS))).length : "0"}</Badge>
                                            </div>
                                        </div>
                                        <Table responsive bordered hover striped>
                                            <thead>
                                            <tr>
                                                <th>Kunden ID</th>
                                                <th>Geschenkt am</th>
                                                <th>Laufzeit</th>
                                                <th>Gültig von</th>
                                                <th>Gültig bis</th>
                                                <th>Notiz</th>
                                                <th>Produktgruppe</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {this.state.subscriptionList.map(sub => (
                                                <tr role="row" key={sub.id}>
                                                    <td>{sub.accountId}</td>
                                                    <td>{transformStringToReadableDate(sub.purchaseTime)}</td>
                                                    <td>{sub.articleId === GlobalConstants.PRODUCT_IAP_1_YEAR_PREMIUMACCESS ? " 1 Jahr" : (sub.articleId === GlobalConstants.PRODUCT_IAP_1_MONTH_PREMIUMACCESS ? "1 Monat" : (sub.articleId === GlobalConstants.PRODUCT_IAP_1_WEEK_PREMIUMACCESS ? "1 Woche" : "Unbekannt"))}</td>
                                                    <td>{transformStringToReadableDate(sub.validFrom)}</td>
                                                    <td>{transformStringToReadableDate(sub.validUntil)}</td>
                                                    <td>{sub.note}</td>
                                                    <td>
                                                        <Badge bg={(sub.productGroupId === Number(GlobalConstants.PRODUCT_GROUP_ID_TIGERTONES) || sub.productGroupId === Number(GlobalConstants.PRODUCT_GROUP_ID_SWISS)) ? "danger" : (sub.productGroupId === Number(GlobalConstants.PRODUCT_GROUP_ID_TIGERBOOKS) ? "primary" : "warning")}>
                                                            {(sub.productGroupId === Number(GlobalConstants.PRODUCT_GROUP_ID_TIGERTONES) || sub.productGroupId === Number(GlobalConstants.PRODUCT_GROUP_ID_SWISS)) ? "tigertones" : (sub.productGroupId === Number(GlobalConstants.PRODUCT_GROUP_ID_TIGERBOOKS) ? "tigerbooks" : "Unbekannt")}
                                                        </Badge>
                                                    </td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        </Table>
                                    </>
                                }

                            </div>
                        </Tab>
                    </Tabs>
                </div>
            </div>
        )
    }

    //API METHODS
    async applyMailFilter(event) {
        event.preventDefault();
        if(this.state.mailsTab === 'nps12') {
            this.loadSurveyMails({...this.state.mailFilter, page: 1, survey: "NPS12", articleName: null}).then(r => r);
        } else if(this.state.mailsTab === 'expired_tigerticket') {
            this.loadSurveyMails({...this.state.mailFilter, page: 1, survey: "EXPIRED_TIGERTICKET", articleName: null}).then(r => r);
        } else if(this.state.mailsTab === 'expiring_tigertickets') {
            this.loadExpiringTigerticketMails({...this.state.mailFilter, page: 1, survey: null, articleName: null}).then(r => r);
        } else if(this.state.mailsTab === 'retrieval_mail') {
            this.loadRetrievalMails({...this.state.mailFilter, page: 1, survey: null}).then(r => r);
        }
    }

    async applySubscriptionFilter(event) {
        event.preventDefault();
        await this.loadPresents(this.state.presentsFilter);
    }

    async loadPresents(filter) {
        this.updateState('loading', true);
        let result = await GetFilteredSpineResources('api/subscriptions/search/support-subscriptions', {...filter, purchasedAfter: transformToLocalDate(filter.purchasedAfter.toString()), purchasedBefore: transformToLocalDate(filter.purchasedBefore.toString())});
        if(!result.error) {
            //Filter
            this.updateState('subscriptionList', result.result);
        } else {
            this.props.addToast("Beim Laden der Geschenke ist ein Fehler aufgetreten: " + result.message, {
                autoDismiss: true,
                appearance: "error"
            });
        }
        this.updateState('presentsFilter', filter);
        this.updateState('loading', false);
    }

    async loadMails(url, filter) {
        this.updateState('loading', true);
        let result = await GetFilteredSpineResources(url, {...filter, sentAfter: transformToLocalDate(filter.sentAfter.toString()), sentBefore: transformToLocalDate(filter.sentBefore.toString())});
        if(!result.error) {
            let list = result.result;
            for(let i = 0; i < list.length; i++) {
                if(list[i].articleName === undefined) {
                    list[i].articleName = "";
                }
            }
            this.updateState('mailList', result.result);
        } else {
            this.props.addToast("Beim Laden der Mails ist ein Fehler aufgetreten: " + result.message, {
                appearance: "error",
                autoDismiss: true
            });
        }
        this.updateState('loading', false);
        this.updateState('mailFilter', filter);
    }

    async loadExpiringTigerticketMails(filter) {
        await this.loadMails('api/crm/expiring-tigerticket-mails', filter);
    }

    async loadRetrievalMails(filter) {
        await this.loadMails('api/crm/retrieval-mails', filter);
    }

    async loadSurveyMails(filter) {
        await this.loadMails('api/crm/surveys', filter);
    }

    //HELPERS
    updateState(param, value) {
        let state = this.state;
        state[param] = value;
        this.setState(state);
    }
}

export default withToast(SupportStatistics);