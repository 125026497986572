import {
    CreateSpineResource,
    DeleteSpineResource,
    GetFilteredSpineResources,
    GetSpineResource,
    UpdateSpineResource
} from "../../clients/SpineClient";

//API METHODS
export async function CreateCart(accountId, cart) {
    return await CreateSpineResource('api/accounts/' + accountId + '/carts', cart);
}

export async function GetFilteredCarts(accountId, filter){
    return await GetFilteredSpineResources('api/accounts/' + accountId + '/carts', filter);
}

export async function GetCart(accountId, id) {
    return await GetSpineResource('api/accounts/' + accountId + '/carts/' + id);
}

export async function UpdateCart(accountId, cart) {
    return await UpdateSpineResource('api/accounts/' + accountId + '/carts/' + cart.id, cart);
}

export async function DeleteCart(accountId, cart) {
    return await DeleteSpineResource('api/accounts/' + accountId + '/carts/' + cart.id);
}

//---------- OPTIONAL -------------
/*
export async function PatchCart(cart, newValue) {
    return await PatchResource('api/carts/' + cart.id + '/patch', {attribute: newValue});
}
 */