import React, {useState} from "react";
import FormSelect from "react-bootstrap/FormSelect";
import Row from 'react-bootstrap/Row';
import {FiArrowRight} from "react-icons/all";
import {Button, Col, Form, InputGroup, Table} from "react-bootstrap";
import {
    GenerateCouponCodes,
    GenerateDTTCodes,
    TriggerTicketDistribution
} from "../../../resources/tigertickets/TigerticketService";
import GlobalConstants from "../../../config/GlobalConstants";
import {openExternalTargetInNewTab} from "../../../util/ResourceService";
import TigerticketMetaData from "../../../resources/tigertickets/TigerticketMetaData";
import {
    GetBillwerkProductsByType,
    GetCouponVariantPage
} from "../../../purchases/premiumAccessTypes/PremiumAccessTypeService";

export function DTTSingleGeneratorDialog(props) {

    //Data
    let [account] = useState(props.account);
    let [selectedType, setSelectedType] = useState(null);
    let [tickets, setTickets] = useState([]);
    let [coupons, setCoupons] = useState([]);
    let [couponVariants, setCouponVariants] = useState([]);
    let [selectedCouponVariant, setSelectedCouponVariant] = useState(null);
    let [selectedProduct, setSelectedProduct] = useState(null);
    let [templateId, setTemplateId] = useState(null);
    let [couponUrl, setCouponUrl] = useState("https://my.tiger.media/ticket/");
    let [campaign, setCampaign] = useState("OTHER");
    let [pickTemplate, setPickTemplate] = useState(true);

    let [generatedCodes, setGeneratedCodes] = useState([]);

    //Tabbing
    let [tab, setTab] = useState(1);

    //Processing
    let [processing, setProcessing] = useState(true);

    if (coupons.length === 0 && tickets.length === 0) {
        loadPremiumTypes(setCoupons, setTickets).then(r => setProcessing(false)).catch(r => setProcessing(false));
    }

    return (
        <>
            <p>
                <span style={{fontWeight: tab === 1 ? "bold" : "normal", cursor: "pointer"}} onClick={() => setTab(1)}>Schritt 1: Generierung des Codes</span>
                {account.id != null &&
                <>
                    &#xA0;<FiArrowRight/>&#xA0;
                    <span style={{
                        fontWeight: tab === 2 ? "bold" : "normal",
                        cursor: generatedCodes.length > 0 ? "pointer" : "not-allowed"
                    }}
                          onClick={() => {
                              if (generatedCodes.length > 0) {
                                  setTab(2);
                              }
                          }}>Schritt 2: Versand des Codes</span>
                </>
                }
            </p>
            <hr/>

            {tab === 1 &&
            <>
                <p style={{fontFamily: "Mikado"}}>
                    In diesem Fenster lassen sich für das ausgewählte Konto Zugangscodes inklusive PIN generieren.
                    Sobald die Generierung des/der Code(s) abgeschlossen ist, können diese vom Kunden eingelöst werden.
                    Es besteht die optionale Möglichkeit, die generierten Codes per E-Mail direkt an den Kunden zu
                    schicken.
                </p>
                <p style={{fontFamily: "Mikado"}}>
                    <strong>Wähle die Art des zu generierenden Zugangscodes aus:</strong>
                    <FormSelect style={{cursor: "pointer", backgroundColor: '#E3E3E3'}}
                                  onChange={(e) => {
                                      setSelectedType(e.target.value);
                                      setSelectedProduct(null);
                                      setSelectedCouponVariant(null);
                                      if (e.target.value === "COUPON") {
                                          loadCouponVariants().then(r => setCouponVariants(r));
                                      }
                                  }}>
                        <option value={null} disabled selected>Bitte wählen:</option>
                        <option value={"DIGITICKET"}>Digitales Ticket</option>
                        <option value={"COUPON"}>Coupon/Gutschein</option>
                    </FormSelect>
                </p>
                <br />
                {selectedType === "COUPON" &&
                    <p style={{fontFamily: "Mikado"}}>
                        <strong>Wähle den Gutschein/Coupon aus, der generiert werden soll</strong>
                        <FormSelect style={{cursor: "pointer", backgroundColor: '#E3E3E3'}}
                                      onChange={(e) => {
                                          setSelectedProduct(e.target.value);
                                      }}>
                            <option value={null} disabled selected>Bitte wählen:</option>
                            {coupons.map(cp => (
                                <option value={cp.id}>ID: {cp.id} - {cp.billwerkName}</option>
                            ))}
                        </FormSelect>
                        <br />
                        <strong>Wähle die zugehörige Coupon-Variante aus</strong>
                        <FormSelect style={{cursor: "pointer", backgroundColor: '#E3E3E3'}}
                                      onChange={(e) => setSelectedCouponVariant(e.target.value)}>
                            <option value={null} disabled selected>Bitte wählen:</option>
                            {couponVariants.map(cp => (
                                <option value={cp.id}>ID: {cp.id} - {cp.name}</option>
                            ))}
                        </FormSelect>
                    </p>
                }
                {selectedType === "DIGITICKET" &&
                <p style={{fontFamily: "Mikado"}}>
                    <strong>Wähle die Ticket-Variante aus, der generiert werden soll</strong>
                    <FormSelect style={{cursor: "pointer", backgroundColor: '#E3E3E3'}}
                                  onChange={(e) => setSelectedProduct(e.target.value)}>
                        <option value={null} disabled selected>Bitte wählen:</option>
                        {tickets.map(cp => (
                            <option value={cp.id}>ID: {cp.id} - {cp.billwerkName}</option>
                        ))}
                    </FormSelect>
                </p>
                }
                <br />
                <div style={{marginTop: "30px", marginLeft: account.id != null ? "30%" : "40%"}}>
                    <button className={selectedProduct != null ? "form-btn-ci-light-blue" : "form-btn-ci-off"}
                            onClick={() => {
                                setProcessing(true);
                                generateCodesForAccount(selectedProduct, account, generatedCodes, setGeneratedCodes, coupons, selectedCouponVariant, selectedType).then(r => setProcessing(false));
                            }}
                            disabled={selectedProduct == null || (selectedType === "COUPON" && selectedCouponVariant == null)}>{(processing && (coupons.length > 0 || tickets.length > 0)) ? "Code wird generiert..." : (!processing && generatedCodes.length > 0) ? "Weiteren Code generieren" : "Starte Generierung"}
                    </button>
                    {account.id != null &&
                    <button
                        className={(generatedCodes.length > 0 && !processing) ? "form-btn-ci-blue" : "form-btn-ci-off"}
                        disabled={generatedCodes.length === 0 || processing}
                        onClick={() => {
                            setTab(2);
                            setTemplateId(GlobalConstants.MAILJET_TIGERTICKET_MAIL_TEMPLATES[0]);
                        }}>Codes per Mail verschicken
                    </button>
                    }
                </div>
                {generatedCodes.length > 0 &&
                <Table responsive bordered hover striped style={{marginTop: "30px"}}>
                    <thead>
                    <tr>
                        <th>Zugangscode</th>
                        <th>PIN</th>
                    </tr>
                    </thead>
                    <tbody>
                    {generatedCodes.map(code => (
                        <tr role="row" key={code.code}>
                            <td>{code.code}</td>
                            <td>{code.pin}</td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
                }
            </>
            }

            {tab === 2 &&
            <>
                <Form>
                    <Row>
                        <Form.Control style={{fontFamily: "Mikado", marginLeft: "5px"}} plaintext readOnly
                                      value="1: Wähle ein Mailjet-Template aus oder gib manuell eine ID an, das für den Versand der E-Mails verwendet werden soll."/>
                    </Row>
                    <Row>
                        <Form.Group as={Col}>
                            <InputGroup>
                                {pickTemplate ?
                                    <FormSelect style={{cursor: "pointer", backgroundColor: '#E3E3E3'}}
                                                  onChange={(e) => setTemplateId(e.target.value)}>
                                        {GlobalConstants.MAILJET_TIGERTICKET_MAIL_TEMPLATES.map(template => (
                                            <option value={template}>{template}</option>
                                        ))}
                                    </FormSelect>
                                    :
                                    <Form.Control type="number" style={{backgroundColor: '#E3E3E3'}}
                                                  onChange={(e) => setTemplateId(e.target.value)}/>

                                }
                                <Button variant="outline-secondary"
                                        onClick={() => setPickTemplate(!pickTemplate)}>{pickTemplate ? "Eingabe" : "Auswahl"}</Button>
                                <Button variant="secondary"
                                        onClick={() => openExternalTargetInNewTab("https://app.mailjet.com/template/" + templateId + "/version-history")}>Vorschau</Button>
                            </InputGroup>
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Control style={{fontFamily: "Mikado", marginLeft: "5px"}} plaintext readOnly
                                      value="2: Gib die Ziel-Url/Einlöseseite an, die über den Einlösebutton geöffnet werden soll (falls vorhanden)"/>
                    </Row>
                    <Row>
                        <Form.Group as={Col}>
                            <Form.Control type="text" onChange={(e) => setCouponUrl(e.target.value)} value={couponUrl}/>
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Control style={{fontFamily: "Mikado", marginLeft: "5px"}} plaintext readOnly
                                      value="3: Wähle die Kampagne aus, in dessen Rahmen die Mails verschickt werden (Optional)"/>
                    </Row>
                    <Row>
                        <Form.Group as={Col}>
                            <FormSelect style={{cursor: "pointer", backgroundColor: "#E3E3E3"}}
                                          onChange={(e) => setCampaign(e.target.value)}>
                                {TigerticketMetaData.TIGERTICKET_DISTRIBUTION_CAMPAIGNS.map(cam => (
                                    <option value={cam.id}>{cam.label}</option>
                                ))}
                            </FormSelect>
                        </Form.Group>
                    </Row>
                </Form>
                <div style={{marginTop: "30px", marginLeft: "35%"}}>
                    <button className="form-btn-ci-light-blue"
                            onClick={() => {
                                startMailingProcess(generatedCodes, templateId, campaign, couponUrl, props.onSuccess, props.onError).then(r => r);
                                props.finishProcess();
                            }}
                    >Starte den Versand & Beende den Prozess
                    </button>
                </div>
            </>
            }
        </>
    );
}

async function loadPremiumTypes(setCoupons, setTickets) {
    let loadedDigitickets = await GetBillwerkProductsByType("DIGITICKET", 0);
    if (!loadedDigitickets.error) {
        setTickets(loadedDigitickets.result);
    }
    let loadedCoupons = await GetBillwerkProductsByType("COUPON", 0);
    if (!loadedCoupons.error) {
        setCoupons(loadedCoupons.result);
    }
}

async function loadCouponVariants() {
    let loadedVariants = await GetCouponVariantPage(0);
    if (!loadedVariants.error) {
        return loadedVariants.result;
    } else {
        return [];
    }
}

async function generateCodesForAccount(selectedCouponId, account, generatedCodes,
                                       setGeneratedCodes, coupons, selectedCouponVariantId, selectedType) {
    //Create the e-mail list (the aboservice api expects a list)
    let emails = [];
    emails.push(account != null ? account.email : "");

    let response = {};
    if (selectedType === "DIGITICKET") {
        response = await GenerateDTTCodes(Number(selectedCouponId), emails);
    } else {
        response = await GenerateCouponCodes(Number(selectedCouponId), selectedCouponVariantId, 1);
    }

    if (!response.error) {
        let existingCodes = generatedCodes;
        existingCodes = existingCodes.concat(response.result);
        setGeneratedCodes(existingCodes);
    }
}

async function startMailingProcess(generatedCodes, templateId, campaign, couponUrl, onSuccess, onError) {
    let response = await TriggerTicketDistribution(generatedCodes, templateId, campaign, couponUrl);
    if(!response.error) {
        onSuccess();
    } else {
        onError();
    }
}