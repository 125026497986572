import {Badge, Button, Row, Col, Modal, Pagination, Table} from "react-bootstrap";
import React from "react";
import {Link} from "react-router-dom";
import {openDetailsInNewTab, openExternalTargetInNewTab, transformStringToReadableDate} from "../util/ResourceService";
import GlobalConstants from "../config/GlobalConstants";
import {BsToggleOff, BsToggleOn, BsTrash, FaRegEye, RiFileAddLine} from "react-icons/all";
import {useHistory} from "react-router";
import {PaginationHelp} from "../global/PaginationHelp";
import { mkConfig, generateCsv, download } from "export-to-csv";

//RESOURCES TABLE WITH CUSTOM COLUMNS
let convertedDataset = [];
export function ResourcesTable(props) {

    const history = useHistory();

    convertedDataset = transformDataset(props.columns, props.dataset, props.customcolumndata, props.selectable);

    //Get the identifier of the different kinds of columns
    let defaultIdentifier = [];
    let customIdentifier = [];
    let linkIdentifier = [];
    let imageIdentifier = [];
    let tableIdentifier = [];
    let badgeIdentifier = [];
    let subIdentifier = [[]];
    let booleanIdentifier = [];
    let listIdentifier = [];
    let datetimeIdentifier = [];

    defaultIdentifier = props.columns.filter(column => column.columnType === 'default').map(column => column.id);
    customIdentifier = props.columns.filter(column => column.columnType === 'customValue').map(column => column.id);
    linkIdentifier = props.columns.filter(column => column.columnType === 'link').map(column => column.id);
    imageIdentifier = props.columns.filter(column => column.columnType === 'image').map(column => column.id);
    tableIdentifier = props.columns.filter(column => column.columnType === 'table').map(column => column.id);
    badgeIdentifier = props.columns.filter(column => column.columnType === 'badge').map(column => column.id);
    subIdentifier = props.columns.filter(column => column.columnType === 'sub').map(column => [column.id, column.subId]);
    booleanIdentifier = props.columns.filter(column => column.columnType === 'boolean').map(column => column.id);
    listIdentifier = props.columns.filter(column => column.columnType === 'list').map(column => column.id);
    datetimeIdentifier = props.columns.filter(column => column.columnType === 'datetime').map(column => column.id);

    for(let i = 0; i < subIdentifier.length; i++) {
        subIdentifier[i] = subIdentifier[i][0] + subIdentifier[i][1];
    }

    //Create the state that will be provided between the screens
    let stateToPersist = {
        filter: props.state != null ? props.state.filter : {},
        columns: props.state != null ? props.state.columns : [],
        page: props.state != null ? props.page : 0,
        totalPages : props.state != null ? props.totalPages : 0,
        handlePageClick : props.state != null ? props.handlePageClick : null
    };
    if(props.state != null) {
        stateToPersist[props.nameInState] = props.state[props.nameInState];
    } else {
        stateToPersist[props.nameInState] = [];
    }

    const handleRoute = (id) => {
        history.push({pathname: props.resourcesurl + "/" + id, state: stateToPersist});
    }
    let i = 0;
    let j = 0;
    return (
        <>
            <Table {...props} responsive striped hover bordered>
                <thead>
                    <tr>
                        {props.columns.filter(column => column.show === true).map(column => (
                            <th key={i++}>{column.label}</th>
                        ))}

                        {props.selectable &&
                            <th>Select</th>
                        }
                    </tr>
                </thead>

                <tbody>
                {convertedDataset.map(data => (
                    <tr role="row" key={data.id}
                        style={{cursor: "pointer"}}>

                        {props.columns.filter(column => column.show === true).map(column => (
                            (column.id.includes('id') && column.id !== 'uid' &&
                                    <td key={j++}><a style={{color: "#333"}} href={"/" + GlobalConstants.APP_PATH + props.resourcesurl + "/" + data.id}>{data.id}</a></td>)
                            ||
                            (badgeIdentifier.includes(column.id) &&
                                getBadgeResource(handleRoute, data, column.id, props.columns, j++))
                            ||
                            (defaultIdentifier.includes(column.id) && column.id !== 'isChecked' && column.id !== 'id' &&
                                <td key={j++} onClick={() => handleRoute(data.id)} className={column.className}>{data[column.id]}</td>)
                            ||
                            (subIdentifier.includes(column.id) &&
                                <td key={j++} onClick={() => handleRoute(data.id)}>{data[column.id]}</td>)
                            ||
                            (listIdentifier.includes(column.id) &&
                                <td key={j++} onClick={() => handleRoute(data.id)}>
                                    {data[column.id].join(',')}
                                </td>)
                            ||
                            (datetimeIdentifier.includes(column.id) &&
                                <td key={j++} onClick={() => handleRoute(data.id)}>
                                    {transformStringToReadableDate(data[column.id], true)}
                                </td>)
                            ||
                            (booleanIdentifier.includes(column.id) &&
                                <td key={j++} onClick={() => handleRoute(data.id)}>
                                    <Badge bg={data[column.id] ? "success" : "danger"}>{data[column.id] ? "Y" : "N"}</Badge>
                                </td>
                            )
                            ||
                            (customIdentifier.includes(column.id)&&
                                <td key={j++} className={column.className}>{data[column.id]}</td>
                            )
                            ||
                            (linkIdentifier.includes(column.id)&&
                                    <td key={j++} className={column.className}><a style={{color: "#333"}} href={column.urlTemplate.replace('{0}', data[column.id])}>{data[column.id]}</a></td>
                            )
                            ||
                            (imageIdentifier.includes(column.id) &&
                                <td key={j++}>
                                    {data[column.id] != null ?
                                        <img src={data[column.id]} alt={column.id} style={{width: "100px", height: "100px"}}
                                             onClick={() => openExternalTargetInNewTab(data[column.id])}/>
                                        :
                                        <div style={{width: "100px", height: "100px"}}></div>
                                    }
                                </td>
                            )
                            ||
                            (tableIdentifier.includes(column.id) &&
                                <td key={j++}>
                                    <Table striped bordered hover>
                                        <thead>
                                        <tr>
                                            {data[column.id].length > 0 && Object.keys(data[column.id][0])
                                                .filter(subKey => !Array.isArray(data[column.id][0][subKey]))
                                                .map(subKey => (
                                                    <th>{toLabel(subKey)}</th>
                                                ))}
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {data[column.id].map(obj => (
                                            <tr  role="row" key={obj}>
                                                {Object.keys(obj).map(subKey => (
                                                    !(Array.isArray(obj[subKey])) &&
                                                    <td key={j++}>{obj[subKey]}</td>
                                                ))
                                                }
                                            </tr>
                                        ))}
                                        </tbody>
                                    </Table>
                                </td>
                            )
                        ))}

                        {/* Create the checkbox (if the table is selectable */}
                        {props.selectable &&
                        <td key={j++} onClick={() => {
                            if(props.selectable) {
                                toggleResource(props, data);
                                data.isChecked = !data.isChecked;
                            }
                        }}>
                            <input type="checkbox" defaultChecked={false} checked={data.isChecked}/>
                        </td>
                        }

                    </tr>
                ))}

                </tbody>
            </Table>

            {props.handlePageClick != null ?
            <div className={"advanced-pagination"}>
            <PaginationHelp
                page={props.page}
                totalPages={props.totalPages}
                handlePageClick={props.handlePageClick}
            />
            </div>
            :
                <>
                    {props.isSpringDataRestResource ?
                        <Pagination>
                            <Pagination.Item hidden={props.page === 0}
                                             onClick={() => props.onLoadPage(0)}>&lt;&lt;</Pagination.Item>
                            <Pagination.Item hidden={props.page === 0}
                                             onClick={() => props.onLoadPage(props.page - 1)}>&lt;</Pagination.Item>
                            <Pagination.Item hidden={props.page <= 1}
                                             onClick={() => props.onLoadPage(props.page - 2)}>{props.page - 1}</Pagination.Item>
                            <Pagination.Item hidden={props.page === 0}
                                             onClick={() => props.onLoadPage(props.page - 1)}>{props.page}</Pagination.Item>
                            <Pagination.Item active={true}>{props.page + 1}</Pagination.Item>
                            <Pagination.Item hidden={props.page === props.lastPage - 1}
                                             onClick={() => props.onLoadPage(props.page + 1)}>{props.page + 2}</Pagination.Item>
                            <Pagination.Item hidden={props.page > props.lastPage - 3}
                                             onClick={() => props.onLoadPage(props.page + 2)}>{props.page + 3}</Pagination.Item>
                            <Pagination.Item hidden={props.page === props.lastPage - 1}
                                             onClick={() => props.onLoadPage(props.page + 1)}>&gt;</Pagination.Item>
                            <Pagination.Item hidden={props.page === props.lastPage - 1}
                                             onClick={() => props.onLoadPage(props.lastPage - 1)}>&gt;&gt;</Pagination.Item>
                        </Pagination>
                        :
                        <Pagination>
                            <Pagination.Item hidden={props.page === 1}
                                             onClick={() => props.onLoadPage(1)}>&lt;&lt;</Pagination.Item>
                            <Pagination.Item hidden={props.page === 1}
                                             onClick={() => props.onLoadPage(props.page - 1)}>&lt;</Pagination.Item>
                            <Pagination.Item hidden={props.page <= 2}
                                             onClick={() => props.onLoadPage(props.page - 2)}>{props.page - 2}</Pagination.Item>
                            <Pagination.Item hidden={props.page === 1}
                                             onClick={() => props.onLoadPage(props.page - 1)}>{props.page - 1}</Pagination.Item>
                            <Pagination.Item active={true}>{props.page}</Pagination.Item>
                            <Pagination.Item hidden={props.page === props.lastPage}
                                             onClick={() => props.onLoadPage(props.page + 1)}>{props.page + 1}</Pagination.Item>
                            <Pagination.Item hidden={props.page > props.lastPage - 2}
                                             onClick={() => props.onLoadPage(props.page + 2)}>{props.page + 2}</Pagination.Item>
                            <Pagination.Item hidden={props.page === props.lastPage}
                                             onClick={() => props.onLoadPage(props.page + 1)}>&gt;</Pagination.Item>
                            <Pagination.Item hidden={props.page === props.lastPage}
                                             onClick={() => props.onLoadPage(props.lastPage)}>&gt;&gt;</Pagination.Item>
                        </Pagination>

                    }
                </>
            }

        </>
    );
}

function getBadgeResource(handleRoute, data, key, columns, keyValue) {
    const column = columns
            .filter(column => column.id === key)[0];
    const badge = columns
        .filter(column => column.id === key)[0]
        .options[columns
            .filter(column => column.id === key)[0]
            .options.findIndex(x => x.value === data[key])];
    if (badge) {
        const label = badge.label != null ? badge.label : data[key];
        return (
                <td key={keyValue} onClick={() => handleRoute(data.id)}>
                    <Badge bg={badge.style} text={badge.text}>{label}</Badge>
                </td>
        )
    } else {
        const fallbackBadgeLabel = key + ":" + data[key];
        return (
            <td key={keyValue} onClick={() => handleRoute(data.id)}>
                {!column.nullable &&
                        <Badge bg={"dark"}>{fallbackBadgeLabel}</Badge>
                }
            </td>
        )
    }
}


export function TableHeader(props) {

    //Get a list of the resources
    let resources = props.state[props.nameInState];

    let resourcesurl = props.resourcesurl;
    if(!props.resourcesurl) { //fallback
        resourcesurl = props.nameInState;
    }

    const csvConfig = mkConfig({ useKeysAsHeaders: true,
        fieldSeparator: ';' });

    function downloadCsv() {
        const csv = generateCsv(csvConfig)(convertedDataset);
        download(csvConfig)(csv)
    }

    return( <>
        <div className="row" style={{marginLeft: "3px"}}>
            <div className="col-md-2" style={{marginTop: "35px"}}>
                {!props.viewOnly &&
                    <>
                        {!props.creationForbidden &&
                            <Link to={"/" + GlobalConstants.APP_PATH + resourcesurl + "/add"}>
                                <button className="form-btn-ci-blue" type="button"><RiFileAddLine/></button>
                            </Link>
                        }

                        {!props.deletionForbidden &&
                            <button
                            className={resources.filter(resource => resource.isChecked).length === 0 ? "form-btn-ci-off" : "form-btn-ci-red"}
                            type="button"
                            onClick={() => {
                            //Get the state and the dialogs flags
                            let state = props.state;
                            let dialogs = state.showDialog;

                            //Update the state of the deletion modal visibility
                            dialogs["deletionModal"] = true;
                            state["showDialog"] = dialogs;
                            props.onSetState(state);
                        }}
                            disabled={resources.filter(resource => resource.isChecked).length === 0}><BsTrash />
                            </button>
                        }
                    </>
                }
            </div>
            <div className="col-md-2">
                {props.sortParams.length > 0 &&
                <>
                    <label>Per Page:</label>
                    <input type="number" value={props.state.filter.per_page}
                           onChange={(e) => {
                               //Get the state and the filter
                               let state = props.state;
                               let filter = state.filter;

                               //Update the "per_page" attribute
                               filter["per_page"] = e.target.value;

                               //Update the filter and the state
                               state["filter"] = filter;
                               props.onSetState(state);

                           }}/>
                </>
                }

            </div>
            <div className="col-md-2">
                {props.sortParams.length > 0 &&
                    <>
                    <label>Sort by:</label>
                        <select style={{width: "100%", lineHeight: "45px", height: "45px", backgroundColor:"#ffffff"}} value={props.state.filter.sortBy}
                        onChange={(e) => {
                        //Get the state and the filter
                        let state = props.state;
                        let filter = state.filter;

                        //Update the "Sort By" attribute
                        filter["sortBy"] = e.target.value;

                        //Update the filter and the state
                        state["filter"] = filter;
                        props.onSetState(state);
                    }}>

                        <option value="">{props.defaultSortParamLabel ? props.defaultSortParamLabel : ""}</option>
                    {props.sortParams.map(param => (
                        <option key={param.id} value={param.id}>{param.label}</option>
                        ))}
                        </select>
                    </>
                }
            </div>
            <div className="col-md-2" style={{marginTop: "35px"}}>
                {props.sortParams.length > 0 &&
                <>
                    <select style={{width: "100%", lineHeight: "45px", height: "45px", backgroundColor:"#ffffff"}} value={props.state.filter.sortDirection}
                            onChange={(e) => {
                                //Get the state and the filter
                                let state = props.state;
                                let filter = state.filter;

                                //Update the "Sort By" attribute
                                filter["sortDirection"] = e.target.value;

                                //Update the filter and the state
                                state["filter"] = filter;
                                props.onSetState(state);
                            }}>
                        <option value=""></option>
                        <option value="ASC">Ascending</option>
                        <option value="DESC">Descending</option>
                    </select>
                </>
                }
            </div>
            <div className="col-md-2" style={props.sortParams.length > 0 ?{marginTop: "35px"} : {marginTop: "0px"}}>
                {props.sortParams.length > 0 ?
                    <>
                        <button className="form-btn-ci-blue" type="button"
                                onClick={props.onLoadPage}>Apply
                        </button>
                    </>
                    :
                    <>
                        <label>Per Page:</label>
                        <input type="number" value={props.state.filter.per_page}
                               onChange={(e) => {
                                   //Get the state and the filter
                                   let state = props.state;
                                   let filter = state.filter;

                                   //Update the "per_page" attribute
                                   filter["per_page"] = e.target.value;

                                   //Update the filter and the state
                                   state["filter"] = filter;
                                   props.onSetState(state);

                               }}/>
                    </>
                }
            </div>

            <div className="col-md-2" style={{marginTop: "35px"}}>
                <button
                    className={resources.filter(resource => resource.isChecked === true).length > 0 ? "form-btn-ci-light-blue" : "form-btn-ci-off"}
                    type="button"
                    disabled={resources.filter(resource => resource.isChecked === true).length === 0}
                    onClick={() => {
                        resources.filter(resource => resource.isChecked === true).forEach(resource => openDetailsInNewTab(resourcesurl + "/" + resource.id))
                    }}><FaRegEye />
                </button>
                <button className="form-btn-ci" type="button"
                        onClick={() => toggleAllResources(props)}>{resources.filter(resource => resource.isChecked).length === resources.length ? <BsToggleOn />: <BsToggleOff />}
                </button>
            </div>
        </div>

        {props.csv &&
                <>
                    <hr size={0}/>
                    <Row  style={{marginLeft: "3px", marginTop: "15px"}}>
                        <label>Table Tools</label>
                    </Row>
                    <Row  style={{marginLeft: "3px"}}>
                        <Col>
                            <button className="form-btn-ci" type="button"
                                    onClick={() => downloadCsv()}

                            >
                                CSV
                            </button>
                        </Col>
                    </Row>
                </>
        }
            </>
    );
}

//PAGINATION
export function TablePagination(props) {
    return(
    <Pagination>
        <Pagination.Item hidden={props.page === 1}
                         onClick={() => props.onLoad(1)}>&lt;&lt;</Pagination.Item>
        <Pagination.Item hidden={props.page === 1}
                         onClick={() => props.onLoad(props.page - 1)}>&lt;</Pagination.Item>
        <Pagination.Item hidden={props.page <= 2}
                         onClick={() => props.onLoad(props.page - 2)}>{props.page - 2}</Pagination.Item>
        <Pagination.Item hidden={props.page === 1}
                         onClick={() => props.onLoad(props.page - 1)}>{props.page - 1}</Pagination.Item>
        <Pagination.Item active={true}>{props.page}</Pagination.Item>
        <Pagination.Item hidden={props.page === props.lastPage}
                         onClick={() => props.onLoad(props.page + 1)}>{props.page + 1}</Pagination.Item>
        <Pagination.Item hidden={props.page >= props.lastPage - 1}
                         onClick={() => props.onLoad(props.page + 2)}>{props.page + 2}</Pagination.Item>
        <Pagination.Item hidden={props.page === props.lastPage}
                         onClick={() => props.onLoad(props.page + 1)}>&gt;</Pagination.Item>
    </Pagination>
    );
}
//SETTINGS DIALOG
export function TableSettingsDialog(props) {

    const filteredColumns = getFilteredColumns(props.columns);
    let i = 0;
    return (
        <Modal
            {...props}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Columns
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                {props.columns.map(column => (
                    <button key={props.columns.indexOf(column)} className={column.show ? "form-btn-ci" : "form-btn-ci-off"} style={{margin: "10px"}}
                            onClick={() => {
                                //Get the index of the column that should be updated
                                let columns = props.columns;
                                const index = columns.indexOf(column);

                                //Negate the show status
                                column.show = !column.show;

                                //Update the columns and the state
                                columns[index] = column;

                                //Call the setState method
                                props.onSetState(columns);

                            }}
                    >{column.label}</button>
                ))}
                </div>
                <div>
                    <Table striped bordered hover>
                        <thead>
                        <tr>
                            {filteredColumns.map(column => (
                                <th key={i++}>{column.label}</th>
                            ))}
                        </tr>
                        </thead>
                    </Table>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button className="btn-ci" onClick={props.onHide}>Close</button>
            </Modal.Footer>
        </Modal>
    );
}

//ACTION FUNCTIONS
function toggleResource(props, resourceToToggle) {
    //Get all the resources
    let allResources = props.dataset;

    //Ge the index of the current resource
    let index = -1;
    for(let i = 0; i < allResources.length; i++) {
        if(allResources[i].id === resourceToToggle.id) {
            index = i;
            break;
        }
    }

    //Get the resource object
    let resource = allResources[index];

    //Negate the isChecked flag
    resource.isChecked = !resource.isChecked;

    //Update the list
    allResources[index] = resource;

    //Update the state
    props.onToggleResource(allResources);
}

function toggleAllResources(props) {
    let state = props.state;
    let allResources = state[props.nameInState];
    if(allResources.filter(resource => resource.isChecked).length < allResources.length) {
        allResources.forEach(resource => {
            resource.isChecked = true;
        });
    } else {
        allResources.forEach(resource => {
            resource.isChecked = false;
        });
    }

    state[props.nameInState] = allResources;

    props.onSetState(state);
}

//HELPER FUNCTIONS
function getFilteredColumns(columns) {
    let filteredColumns = [];
    //Default columns
    columns.forEach(column => {
        if(column.show) {
            filteredColumns.push(column);
        }
    });
    return filteredColumns;
}

function transformDataset(columns, columnData, customColumns, selectable) {

    let transformedDataset = [];
    let index = 0;

    //Set the 'standard' attributes
    columnData.forEach(data => {

        let obj = {};

        //Iterate over all active DEFAULT columns
        columns
            .filter(column => column.columnType == null || column.columnType === 'default' || column.columnType === 'list' ||
                column.columnType === 'badge' || column.columnType === 'boolean' || column.columnType === 'datetime')
            .filter(column => column.show === true)
            .forEach(column => {
                obj[column.id] = data[column.id];
                data['type'] = 'default';
        });

        //Iterate over all active SUB columns
        columns
            .filter(column => column.columnType === 'sub')
            .filter(column => column.show === true)
            .forEach(column => {
                obj[column.id + column.subId] = data[column.id][column.subId];
                data['type'] = 'sub';
            });

        //Iterate over all active Custom columns
        columns
            .filter(column => (column.columnType !== null && column.columnType !== 'default' && column.columnType !== 'badge' && column.columnType !== 'boolean'))
            .filter(column => column.show === true)
            .forEach(column => {

                //Get the custom data by the identifier
                const identifier = column.id;
                const customObj = customColumns.filter(customColumn => customColumn.id === identifier);

                if(customObj.length > 0) {
                    obj[column.id] = customObj[0].dataset[index];
                    data['type'] = column.columnType;
                }
            });


        //Set the isChecked flag
        if(selectable) {
            obj.isChecked = data.isChecked;
        }

        transformedDataset.push(obj);

        index++;
    });

    //Set the custom attributes
    return transformedDataset;
}

function toLabel(identifier) {
    let label = identifier;

    //Add a space to camel case layout, i.e. productId -> product Id
    for(var i = 1; i < identifier.length; i++) {
        if(identifier[i] === identifier[i].toUpperCase()) {
            label = label.substring(0, i) + " " + label.substring(i, label.length);
        }
    }

    //Make the first letter to uppercase
    label = label[0].toUpperCase() + label.substring(1, label.length);

    return label;
}

export const copyUrlToClipboardColumn = (resource, props) => {
    if (resource.url != null) {
        return (
            <>
                <Button variant="outline-secondary"
                        onClick={() => openExternalTargetInNewTab(resource.url)}>Open</Button>
                &#xA0;
                &#xA0;
                <Button variant="outline-secondary"
                        onClick={() => {
                            navigator.clipboard.writeText(resource.url).then(r => {
                                props.addToast("URL copied to clipboard", {
                                    autoDismiss: true,
                                    appearance: 'info'
                                });
                            });
                        }}>Copy</Button>
            </>
        )
    } else {
        return null;
    }
}