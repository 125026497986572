import {
    CreateBillResource, DeleteBillResource,
    GetFilteredBillResources, GetBillResource, UpdateBillResource
} from "../../clients/PurchaseClient";

//API METHODS - BILLWERK PRODUCTS
export async function GetBillwerkProductPage(page){
    return await GetFilteredBillResources('api/purchases/products', {page: page});
}

export async function GetAllBillwerkProducts(pageSize) {
    return await GetFilteredBillResources('api/purchases/products', {pageSize: pageSize});
}

export async function GetBillwerkProductsByType(type, page, per_page = 20 ) {
    return await GetFilteredBillResources('api/purchases/products/search/by-type', {premiumType: type, page: page, pageSize: per_page});
}

export async function GetMappedBillwerkProduct(id) {
    return await GetBillResource('api/purchases/products/' + id);
}

export async function CreateMappedBillwerkProduct(mappedBillwerkProduct) {
    return await CreateBillResource('api/purchases/products', mappedBillwerkProduct);
}

export async function UpdateMappedBillwerkProduct(mappedBillwerkProduct) {
    return await UpdateBillResource('api/purchases/products/' + mappedBillwerkProduct.id, mappedBillwerkProduct);
}

export async function DeleteMappedBillwerkProduct(mappedBillwerkProduct) {
    return await DeleteBillResource('api/purchases/products/' + mappedBillwerkProduct.id, false);
}

export async function ImportFromBillwerk(updateExisting, deleteNonExisting) {
    return await GetBillResource('api/purchases/products/import-from-billwerk?updateExisting=' + updateExisting + "&deleteNonExisting=" + deleteNonExisting);
}

//API METHODS - PREMIUM ACCESS TYPES
export async function GetPremiumAccessTypePage(filter) {
    return await GetFilteredBillResources('api/purchases/premium-access-types/internal', filter);
}

export async function GetPremiumAccessType(id) {
    return GetBillResource('api/purchases/premium-access-types/' + id + '/internal');
}

export async function CreatePremiumAccessType(premiumAccessType) {
    return await CreateBillResource('api/purchases/premium-access-types', premiumAccessType);
}

export async function UpdatePremiumAccessType(premiumAccessType) {
    if (premiumAccessType.components != null) {
        //We don't want to update the phases via the update endpoint
        delete premiumAccessType["components"]
    }
    return await UpdateBillResource('api/purchases/premium-access-types/' + premiumAccessType.id, premiumAccessType);
}

export async function DeletePremiumAccessType(premiumAccessType) {
    return await DeleteBillResource('api/purchases/premium-access-types/' + premiumAccessType.id, false);
}

export async function UpdatePhaseOrder(id, request) {
    return await UpdateBillResource('api/purchases/premium-access-types/' + id + '/phases/update-order', request);
}

export async function TogglePaymentRequiredForPhase(typeId, phaseId, paymentRequired) {
    const request = {paymentRequired: paymentRequired};
    return await UpdateBillResource('api/purchases/premium-access-types/' + typeId + '/phases/' + phaseId, request);
}

export async function GetAvailableTarifUpgrades(id) {
    return await GetFilteredBillResources('api/purchases/premium-access-types/' + id + '/internal/available-upgrades', {});
}

export async function GetPossibleTariffSources(id) {
    return await GetFilteredBillResources('api/purchases/premium-access-types/' + id + '/available-sources', {});
}

export async function AddPremiumTypePhase(id, productId) {
    return await CreateBillResource('api/purchases/premium-access-types/' + id + '/phases', {productId: productId});
}

export async function DeletePremiumTypePhase(phase) {
    return await DeleteBillResource('api/purchases/premium-access-types/' + phase.premiumAccessTypeId + '/phases/' + phase.id);
}

//API METHODS - COUPON VARIANTS
export async function GetCouponVariantPage(page) {
    return await GetFilteredBillResources('api/purchases/couponVariants', {page: page});
}

export async function GetCouponVariantByType(type, page) {
    return await GetFilteredBillResources('api/purchases/couponVariants/search/by-type', {couponType: type, page: page})
}

export async function CreateCouponVariant(couponVariant) {
    return await CreateBillResource('api/purchases/couponVariants', couponVariant);
}

export async function GetCouponVariant(id) {
    return await GetBillResource('api/purchases/couponVariants/' + id);
}

export async function UpdateCouponVariant(couponVariant) {
    return await UpdateBillResource('api/purchases/couponVariants/' + couponVariant.id, couponVariant);
}