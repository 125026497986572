import React, {Component} from "react";
import '../../../App.scss'
import {withToast} from "../../util/ToastService";
import {
    FiSettings,
    GrObjectGroup
} from "react-icons/all";
import {DeleteSeries, GetFilteredSeries} from "./SeriesService";
import {TableHeader, TableSettingsDialog} from "../../generators/TableGenerator";
import SeriesMetaData from "./SeriesMetaData";
import GlobalConstants from "../../config/GlobalConstants";
import {ActionModal} from "../../generators/ModalGenerator";
import {Link} from "react-router-dom";
import {SpinningTiger} from "../../global/SpinningTiger";
import {Badge, Table} from "react-bootstrap";
import {ErrorHandler} from "../../util/ErrorHandler";
import {PaginationHelp} from "../../global/PaginationHelp";

class Series extends Component {

    //CONSTRUCTOR
    constructor(props) {
        super(props);

        //Filter
        this.filter = {page: 0, id: "", name: "", per_page: 20, sortBy: "id", sortDirection: "ASC"}

        //State
        this.state = {
            error: null,
            loading: false,
            series: [],
            filter: props.location.state != null ? props.location.state.filter : this.filter,
            totalPages: props.location.state != null ? props.location.state.totalPages : 0,
            episodeEntries: [],
            columns: props.location.state != null ? props.location.state.columns : SeriesMetaData.COLUMNS,
            customColumnData: [
                {id: 'seasons', dataset: []},
                {id: 'seasonsAndEpisodes', dataset: []}
            ],
            showEpisodesOfSeason: null,
            showTableSettings: false,
            showDialog: {
                deletionModal: false,
            }
        };

        //Bind the functions
        this.applyFilter = this.applyFilter.bind(this);
    }

    //Load the screen initially
    async componentDidMount() {
        //Set the title
        document.title = "Series :: Tiger UI";

        await this.loadPage(this.state.filter.page);
    }

    //Load a certain page
    async loadPage(page) {

        this.setState(prevState => ({...prevState, loading: true}));

        let filter = this.state.filter;
        filter.page = page;
        const loadedSeries = await GetFilteredSeries(filter);

        if (loadedSeries.error === true) {
            this.setState(prevState => ({...prevState, error: loadedSeries}));
        } else {
            loadedSeries.result.forEach(series => {
                if (this.state.series.filter(ser => (ser.isChecked === true)).includes(series)) {
                    series.isChecked = true;
                }
            });
        }

        this.setState(prevState => ({
            ...prevState,
            loading: false,
            series: loadedSeries.result,
            totalPages: Math.ceil(loadedSeries.length/this.state.filter.per_page),
            filter: {...prevState.filter, page: page}}));

    }

    //Generate a list of seasons & episodes
    generateEpisodeList(loadedSeries) {
        let episodeEntries = [];
        loadedSeries.forEach(series => {

            let episodeEntriesOfSeries = [];
            let prevSection = 0;

            series.seasons.forEach(season => {
                if(season.episodes.length > 0) {
                    //Season has episodes -> Should be the normal case
                    season.episodes
                        .sort((a,b) => (a.episodeNumber > b.episodeNumber) ? 1 : ((b.episodeNumber > a.episodeNumber) ? -1 : 0))
                        .forEach(episode => {
                        let ep = {};
                        if (season.section !== prevSection) {
                            ep.seasonId = (<><Link to={"/" + GlobalConstants.APP_PATH + "series/" + series.id + "/seasons/" + season.id} style={{color: "#333", fontStyle: "italic"}}>{season.id}</Link></>);
                            ep.seasonName = season.name;
                            ep.seasonSection = season.section;
                        } else {
                            ep.seasonId = "";
                            ep.seasonName = "";
                            ep.seasonSection = "";
                        }
                        ep.id = (<><Link to={"/" + GlobalConstants.APP_PATH + "series/" + series.id + "/seasons/" + season.id + "/episodes/" + episode.id} style={{color: "#333", fontStyle: "italic"}}>{episode.id}</Link></>);;
                        ep.episodeNumber = episode.episodeNumber;
                        ep.episodeTitle = episode.episodeTitle;
                        ep.productId = (<><Link to={"/" + GlobalConstants.APP_PATH + "products/" + episode.productId}  style={{color: "#333", fontStyle: "italic"}}>{episode.productId}</Link></>);
                        episodeEntriesOfSeries.push(ep);

                        prevSection = season.section;
                    });
                } else {
                    //Only if the season has been newly created
                    let ep = {};
                    ep.seasonId = (<><Link to={"/" + GlobalConstants.APP_PATH + "series/" + series.id + "/seasons/" + season.id} style={{color: "#333", fontStyle: "italic"}}>{season.id}</Link></>);
                    ep.seasonName = season.name;
                    ep.seasonSection = season.section;
                    ep.id = '---';
                    ep.episodeNumber = '---';
                    ep.episodeTitle = '---';
                    ep.productId = '---';
                    episodeEntriesOfSeries.push(ep);
                }
            });
                    //series.episodeEntries = episodeEntriesOfSeries;
            episodeEntries.push(episodeEntriesOfSeries);
        });

        return episodeEntries;
    }

    render() {

        return (
            <div style={{marginBottom: 50}}>
                <div className="content-header">
                    <div className="content-header-title">Series</div>
                    <hr/>
                    <div className="content-header-description"><GrObjectGroup/>&#xA0;Manage details, seasons,
                        and episodes of series.
                    </div>
                </div>

                {/* FILTER */}
                <div className="content-box-centered">
                    <div className="content-box-title">Filter</div>
                    <div className="content-box-body">
                        <form onSubmit={this.applyFilter}>
                            <div className="row">
                                <div className="col-md-3">
                                    <label>ID</label>
                                    <input type="text" value={this.state.filter.id}
                                           onChange={(event) => this.setState(prevState => ({
                                               ...prevState,
                                               filter: {
                                                   ...prevState.filter,
                                                   id: event.target.value,
                                               },
                                           }))}/>
                                </div>
                                <div className="col-md-5">
                                    <label>Name</label>
                                    <input type="text" value={this.state.filter.name}
                                           onChange={(event) => this.setState(prevState => ({
                                               ...prevState,
                                               filter: {
                                                   ...prevState.filter,
                                                   name: event.target.value,
                                               },
                                           }))}/>
                                </div>
                            </div>
                            <hr/>
                            <div className="row">
                                <div className="col-md-3" style={{marginLeft: "auto"}}>
                                    <button className="form-btn-ci-light" type="button"
                                            onClick={() => this.setState(prevState => ({...prevState, filter: this.filter}))}>Reset
                                    </button>
                                    <button className="form-btn-ci-blue" type="submit">Apply Filter</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                {/* SERIES LIST*/}
                <div className="content-box-centered">
                    <div className="content-box-title">Series List
                        <div style={{float: "right", fontWeight: "normal", cursor: "pointer"}}
                             onClick={() => this.setState({showTableSettings: true})}><FiSettings/></div>
                    </div>
                    <div className="content-box-body">
                        <TableHeader nameInState="series"
                                     viewOnly={false}
                                     deletionForbidden={false}
                                     sortParams={SeriesMetaData.SERIES_SORT_PARAMS}
                                     state={this.state}
                                     onSetState={(s) => this.setState(s)}
                                     onLoadPage={() => this.loadPage(this.state.filter.page)}/>
                        <hr/>
                        {this.state.loading ?
                            <SpinningTiger />
                        :
                            <>
                                <Table bordered responsive striped hover
                                isSpringDataRestResource={true}>
                                    <thead>
                                    <tr>
                                        <th>ID</th>
                                        <th>Name</th>
                                        <th>Language</th>
                                        <th>Division Type</th>
                                        <th>Content</th>
                                        <th>Select</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {this.state.series.map(s => (
                                        <tr role="row" key={s.id} style={{cursor: "pointer"}}>
                                            <td><Link style={{color: "#333"}}  to={"/" + GlobalConstants.APP_PATH + "series/" + s.id}>{s.id}</Link></td>
                                            <td onClick={() => this.toggleSeries(s)}>{s.name}</td>
                                            <td>{s.language}</td>
                                            <td onClick={() => this.toggleSeries(s)}><Badge bg={s.divisionType === "SEASONS" ? "success" : "primary"}>{s.divisionType}</Badge></td>
                                            <td>{this.generateSeriesContent(s)}</td>
                                            <td onClick={() => this.toggleSeries(s)}>
                                                <input type="checkbox" checked={s.isChecked}/>
                                            </td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </Table>
                                <div className={"advanced-pagination"}>
                                    <PaginationHelp
                                            page={this.state.filter.page}
                                            totalPages={this.state.totalPages}
                                            handlePageClick={(event) => this.loadPage(event.selected)}
                                    />
                                </div>

                            </>
                        }
                    </div>
                </div>

                {/* TABLE SETTINGS */}
                <TableSettingsDialog show={this.state.showTableSettings}
                                     columns={this.state.columns}
                                     onSetState={(columns) => this.setState(prevState => ({...prevState, columns: columns}))}
                                     onHide={() => this.setState({showTableSettings: false})}
                />

                {/* DIALOGS */}
                <ActionModal show={this.state.showDialog.deletionModal}
                             onHide={() => this.setState((prevState) => ({...prevState, showDialog: {...prevState.showDialog, deletionModal: false}}))}
                             onAction={() => this.deleteSelectedSeries()}
                             actionButtonText="Delete"
                             title={"Delete series"}
                             body={"Are you sure you want to delete the selected series?"}/>

                <ErrorHandler error={this.state.error}
                              onHide={() => this.setState(prevState => ({...prevState, error: null}))} />

            </div>
        );
    }

    toggleSeries(s) {
        let series = this.state.series;
        for (let ser of series) {
            if (ser.id === s.id) {
                s.isChecked = !s.isChecked;
            }
        }
        this.setState(prevState => ({...prevState, series: series}));
    }

    generateSeriesContent(series) {
        const baseStyle = {
            width: "90%",
            height: "50px",
            borderRadius: "40px",
            backgroundColor: "rgba(215,140,0,0.6)",
            margin: "10px",
            paddingLeft: "12px",
            paddingTop: "5px"
        }

        const episodeStyle = {
            width: "80%",
            height: "40px",
            marginLeft: "10%",
            backgroundColor: "#bebebe",
            padding: "5px",
            marginBottom: "5px",
            borderRadius: "30px"
        }

        const seasonIdStyle = {
            width: "40px",
            height:"40px",
            borderRadius: "40px",
            paddingTop: "8px",
            textAlign: "center",
            float: "left",
            backgroundColor: "rgba(0,0,0,0.5)",
            color: "white"
        }

        const seasonNameStyle = {
            width: "100%",
            textAlign: "center",
            paddingTop: "8px",
            paddingLeft: "40px"
        }

        const episodeIdStyle = {
            width: "30px",
            height:"30px",
            borderRadius: "30px",
            paddingTop: "4px",
            textAlign: "center",
            float: "left",
            backgroundColor: "rgba(0,0,0,0.5)",
            color: "white"
        }

        const episodeNameStyle = {
            width: "100%",
            textAlign: "center",
            paddingTop: "4px",
            paddingLeft: "40px"
        }

        return (
            <ul style={{listStyleType: "none"}}>
                {series.seasons
                        .sort((a,b) => (a.section > b.section) ? 1 : ((b.section > a.section) ? -1 : 0))
                        .map(s => (
                    <>
                    <li style={baseStyle} key={"season-" + s.id} onClick={() => {
                        if (s.episodes.length === 0) {
                            this.props.addToast("This season does not have any episodes", {
                                autoDismiss: true,
                                appearance: "info"
                            });
                        }
                        if (this.state.showEpisodesOfSeason !== s.id) {
                            this.setState(prevState => ({...prevState, showEpisodesOfSeason: s.id}));
                        } else {
                            this.setState(prevState => ({...prevState, showEpisodesOfSeason: null}));
                        }
                    }}><div style={seasonIdStyle}>{s.id}</div>
                        <div style={seasonNameStyle}>{s.name}</div>
                    </li>
                    {this.state.showEpisodesOfSeason === s.id &&
                        <>
                            {s.episodes.map(e => (
                                <li key={"episode" + e.id} style={episodeStyle}>
                                    <div style={episodeIdStyle}>{e.id}</div>
                                    <div style={episodeNameStyle}>{e.episodeNumber} - {e.episodeTitle}</div>
                                </li>
                            ))}
                        </>
                    }
                    </>
                ))}
            </ul>
        )
    }

    //Filter Handler
    async applyFilter(event) {
        event.preventDefault();

        this.setState(prevState =>({...prevState, series: []}));
        const loadedSeries = await GetFilteredSeries(this.state.filter);

        if (loadedSeries.error === true) {
            this.setState(prevState => ({...prevState, error: loadedSeries}));
        } else {
            if (loadedSeries.result.length === 0) {
                this.props.addToast("No series match the filter criteria", {
                    autoDismiss: true,
                    appearance: 'warning'
                });
            }
        }

        this.setState(prevState => ({...prevState, series: loadedSeries.result}));
    }

    //API Methods
    async deleteSelectedSeries() {
        this.state.series
            .filter(series => series.isChecked === true)
            .forEach(series => DeleteSeries(series));

        await this.loadPage(this.state.filter.page);

        this.setState(prevState => ({...prevState, showDialog: {...prevState.showDialog, deletionModal: false}}));
    }
}

export default withToast(Series);