import React, {useState} from "react";
import {Badge, Pagination, Table} from "react-bootstrap";
import GlobalConstants from "../../config/GlobalConstants";
import {Link} from "react-router-dom";

export function AccountOrders(props) {
    let [orders] = useState(props.orders);
    return(
      <>
        <Table bordered hover responsive striped>
            <thead>
                <tr>
                    <th>ID</th>
                    <th>State</th>
                    <th>Device ID</th>
                    <th>Coupon Code</th>
                </tr>
            </thead>
            <tbody>
            {orders.map(order => (
                <tr role="row" key={order.id}>
                    <td><Link style={{fontStyle: "italic", color: "#333"}} to={"/" + GlobalConstants.APP_PATH + "orders/" + order.id}>{order.id}</Link></td>
                    <td>
                        <Badge bg={
                            order.state === 'PENDING' ? "secondary" :
                            order.state === 'PROCESSING' ? "primary" :
                            order.state === 'FAILED' ? "danger" :
                            order.state === 'ASSIGNING' ? "warning" : "success"
                        }>{order.state}</Badge>
                    </td>
                    <td>{order.deviceId}</td>
                    <td>{order.couponCode}</td>
                </tr>
            ))}
            </tbody>
        </Table>
        <Pagination>
          <Pagination.Item hidden={props.page === 1}
                        onClick={() => props.onLoadPage(1)}>&lt;&lt;</Pagination.Item>
          <Pagination.Item hidden={props.page === 1}
                        onClick={() => props.onLoadPage(props.page - 1)}>&lt;</Pagination.Item>
          <Pagination.Item hidden={props.page <= 2}
                        onClick={() => props.onLoadPage(props.page - 2)}>{props.page - 2}</Pagination.Item>
          <Pagination.Item hidden={props.page === 1}
                        onClick={() => props.onLoadPage(props.page - 1)}>{props.page - 1}</Pagination.Item>
          <Pagination.Item active={true}>{props.page}</Pagination.Item>
          <Pagination.Item hidden={props.page === props.lastPage}
                        onClick={() => props.onLoadPage(props.page + 1)}>{props.page + 1}</Pagination.Item>
          <Pagination.Item hidden={props.page >= props.lastPage - 1}
              onClick={() => props.onLoadPage(props.page + 2)}>{props.page + 2}</Pagination.Item>
          <Pagination.Item hidden={props.page === props.lastPage}
              onClick={() => props.onLoadPage(props.page + 1)}>&gt;</Pagination.Item>
        </Pagination>
      </>
    );
}