import React, {Component} from "react";
import '../../../App.scss'
import FormSelect from "react-bootstrap/FormSelect";
import {
    FcCloseUpMode,
    FcDiploma2, FcElectronics, FiExternalLink
} from "react-icons/all";

import "react-datepicker/dist/react-datepicker.css";
import {
    Badge,
    Col,
    Form,
    FormControl,
    Pagination,
    Tab,
    Table,
    Tabs
} from "react-bootstrap";
import Row from 'react-bootstrap/Row';

import background from "../../../assets/images/support_tool_bg.jpeg";
import {SpinningTiger} from "../../global/SpinningTiger";
import {TableSettingsDialog} from "../../generators/TableGenerator";
import {withToast} from "../../util/ToastService";
import {
    GetCouponByAccountId,
    GetCouponByCode, GetCouponPage, GetCouponsOfCouponVariant,
    GetDigiticketByAccount,
    GetDigiticketByCode,
    GetDigiticketPage,
    GetTigerticketByAccount,
    GetTigerticketPage
} from "./TicketService";
import TicketMetaData from "./TicketMetaData";
import GlobalConstants from "../../config/GlobalConstants";
import {Link} from "react-router-dom";
import {Label} from "reactstrap";
import {
    GetTigerticketByCode
} from "../../resources/tigertickets/TigerticketService";
import {GetAllBillwerkProducts, GetCouponVariantPage} from "../premiumAccessTypes/PremiumAccessTypeService";
import {PeriodToReadableDuration} from "../../util/ResourceService";
import {ErrorHandler} from "../../util/ErrorHandler";

class Tickets extends Component {

    //------------
    //Constructor
    //------------

    constructor(props) {
        super(props);

        this.state = {
            error: null,
            loading: false,

            //Digitickets
            digitickets: [],
            digiticketFilter: {page: 0, per_page: 20},
            lastDigiticketPage: 0,

            //Tigertickets
            tigertickets: [],
            tigerticketFilter: {page: 0, per_page: 20},
            lastTigerticketPage: 0,

            //Coupons
            coupons: [],
            couponFilter: {accountId: "", code: "", variantId: "", page: 0, per_page: 20},
            lastCouponPage: 0,

            //Products
            products: [],

            //Appearance
            mainTab: 'digitickets',
            digiticketColumns: TicketMetaData.DIGITICKET_COLUMNS,
            tigerticketColumns: TicketMetaData.TIGERTICKET_COLUMNS,
            showDialog: {
                tableSettings: false,
                deletionModal: false
            },
            couponVariants: [],

            //Processing
            isLoading: false,
        };

        this.updateFilter = this.updateFilter.bind(this);
        this.showOrHideDialog = this.showOrHideDialog.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
    }

    //---------
    //Mounting
    //---------

    async componentDidMount() {
        await this.loadProducts();
        await this.loadDigitickets(this.state.digiticketFilter, 0);
        await this.loadCouponVariants();
    }

    //--------
    //Loading
    //--------

    async loadProducts() {
        let response = await GetAllBillwerkProducts(200);
        if (!response.error) {
            this.setState(prevState => ({...prevState, products: response.result}));
        } else {
            this.setState(prevState => ({...prevState, error: response}));
        }
    }

    async loadCouponVariants() {
        let response = await GetCouponVariantPage(0);
        if (!response.error) {
            this.setState(prevState => ({...prevState, couponVariants: response.result}));
        } else {
            this.setState(prevState => ({...prevState, error: response}));
        }
    }
    async loadDigitickets(filter, page) {

        this.setState(prevState => ({...prevState, loading: true}));

        filter.page = page;

        let loadedDigitickets;
        if (filter.code != null && filter.code !== "") {
            loadedDigitickets = await GetDigiticketByCode(filter.code, page);
        } else if (filter.accountId != null && filter.accountId !== "") {
            loadedDigitickets = await GetDigiticketByAccount(filter.accountId, page);
        } else {
            loadedDigitickets = await GetDigiticketPage(page);
        }
        let tickets = loadedDigitickets.result;
        if (loadedDigitickets.error === true) {
            this.setState(prevState => ({...prevState, error: loadedDigitickets}));
        } else if (Array.isArray(tickets)) {
            tickets.forEach(sub => {
                if (this.state.digitickets.filter(ex => (ex.isChecked === true)).includes(sub)) {
                    sub.isChecked = true;
                }
            });
            this.setTicketProducts(tickets);
        } else {
            tickets = [];
        }

        this.setState(prevState => ({
            ...prevState,
            loading: false,
            digitickets: tickets,
            digiticketFilter: filter,
            lastDigiticketPage: Math.ceil(loadedDigitickets.length / this.state.digiticketFilter.per_page)
        }));
    }

    async loadTigertickets(filter, page) {

        this.setState(prevState => ({...prevState, loading: true}));

        filter.page = page;

        let loadedTigertickets;
        if (filter.code != null && filter.code !== "") {
            loadedTigertickets = await GetTigerticketByCode(filter.code, page);
        } else if (filter.accountId != null && filter.accountId !== "") {
            loadedTigertickets = await GetTigerticketByAccount(filter.accountId, page);
        } else {
            loadedTigertickets = await GetTigerticketPage(page);
        }

        let tickets = loadedTigertickets.result;
        if (loadedTigertickets.error === true) {
            this.setState(prevState => ({...prevState, error: loadedTigertickets}));
        } else if (Array.isArray(tickets)) {
            tickets.forEach(sub => {
                if (this.state.tigertickets.filter(ex => (ex.isChecked === true)).includes(sub)) {
                    sub.isChecked = true;
                }
            });
            this.setTicketProducts(tickets);
        } else {
           tickets = [];
        }

        this.setState(prevState => ({
            ...prevState,
            loading: false,
            tigertickets: tickets,
            tigerticketFilter: filter,
            lastTigerticketPage: Math.ceil(loadedTigertickets.length / this.state.tigerticketFilter.per_page)
        }));
    }

    async loadCoupons(filter, page) {
        this.setState(prevState => ({...prevState, loading: true}));

        filter.page = page;

        let loadedCoupons;
        if (filter.code != null && filter.code !== "") {
            loadedCoupons = await GetCouponByCode(filter.code, page);
        } else if (filter.accountId != null && filter.accountId !== "") {
            loadedCoupons = await GetCouponByAccountId(filter.accountId, page);
        } else if (filter.variantId != null && filter.variantId !== "") {
            loadedCoupons = await GetCouponsOfCouponVariant(filter.variantId, page);
        } else {
            loadedCoupons = await GetCouponPage(page);
        }

        let coupons = loadedCoupons.result;
        if (loadedCoupons.error === true) {
            this.setState(prevState => ({...prevState, error: loadedCoupons}));
        } else if (Array.isArray(coupons)) {
           coupons.forEach(sub => {
                if (this.state.coupons.filter(ex => (ex.isChecked === true)).includes(sub)) {
                    sub.isChecked = true;
                }
            });
           this.setTicketProducts(coupons);
        } else {
           coupons = [];
        }

        this.setState(prevState => ({
            ...prevState,
            loading: false,
            coupons: coupons,
            couponFilter: filter,
            lastCouponPage: Math.ceil(loadedCoupons.length / this.state.couponFilter.per_page)
        }));
    }

    //----------
    //Rendering
    //----------

    render() {

        return (
            <div style={{paddingTop: 30, backgroundImage: `url(${background})`, minHeight: "100vh", height: "auto"}}>
                <div className="new-content-header">
                    <div className="new-content-header-title">Tickets</div>
                </div>

                {/* SHOW THE PRODUCT LIST*/}
                <div className="support-tool-box">
                    <Tabs id="recommendation-main-tab" activeKey={this.state.mainTab}
                          onSelect={(k) => {
                              if(k === 'digitickets') {
                                  this.loadDigitickets({per_page: 20, page: 0}, 0).then(r => r);
                              } else if (k === 'coupons') {
                                  this.loadCoupons({per_page: 20, page: 0}, 0).then(r => r);
                              } else {
                                  this.loadTigertickets({per_page: 20, page: 0}, 0).then(r => r);
                              }
                              this.updateState('mainTab', k);
                          }}
                          className="mb-5">

                        {/* MAILS */}
                        <Tab eventKey="digitickets"
                             title={<span style={{color: "#495057"}}><FcElectronics size={24}/> tigertones-Tickets & Digitickets</span>}>
                            <hr/>

                            {this.state.loading ?
                                <SpinningTiger/>
                                :
                                <>
                                    <h5>Filter</h5>
                                    <Form>
                                        <Row style={{width: "50%"}}>
                                            <Form.Group as={Col}>
                                                <Label>Code</Label>
                                                <FormControl type="text" value={this.state.digiticketFilter.code}
                                                             onChange={(e) => this.setState(prevState => ({
                                                                 ...prevState,
                                                                 digiticketFilter: {
                                                                     ...prevState.digiticketFilter,
                                                                     code: e.target.value
                                                                 }}))}/>
                                            </Form.Group>
                                            <Form.Group as={Col}>
                                                <Label>Account ID</Label>
                                                <FormControl type="text" value={this.state.digiticketFilter.accountId}
                                                             onChange={(e) => this.setState(prevState => ({
                                                                 ...prevState,
                                                                 digiticketFilter: {
                                                                     ...prevState.digiticketFilter,
                                                                     accountId: e.target.value
                                                                 }}))}/>
                                            </Form.Group>
                                        </Row>
                                    </Form>
                                    <div style={{float: "right"}}>
                                        <button className="form-btn-ci-light" onClick={() => {
                                            this.setState(prevState => ({
                                                ...prevState,
                                                tigerticketFilter: {
                                                    page: 0,
                                                    code: "",
                                                    accountId: "",
                                                    per_page: 20
                                                },
                                                digiticketFilter: {
                                                    page: 0,
                                                    code: "",
                                                    accountId: "",
                                                    per_page: 20
                                                }
                                            }));
                                            this.loadDigitickets({code: "", accountId: "", page: 0}, 0).then(r => r);
                                        }}>Reset</button>
                                        <button className="form-btn-ci-blue" onClick={() => this.loadDigitickets({code: this.state.digiticketFilter.code, accountId: this.state.digiticketFilter.accountId}, 0)}>Search</button>
                                    </div>
                                    <br />
                                    <br />
                                    <Table hover responsive bordered>
                                        <thead>
                                        <tr>
                                            <th colSpan="2" style={{textAlign: "center"}}>General Details</th>
                                            <th colSpan="3" style={{textAlign: "center"}}>Redemption Status</th>
                                            <th colSpan="2" style={{textAlign: "center"}}>Billwerk Details</th>
                                            <th colSpan="4" style={{textAlign: "center"}}>Source:</th>
                                        </tr>
                                        <tr>
                                            <th><FiExternalLink size={24}/> ID</th>
                                            <th>Code</th>
                                            <th>Used</th>
                                            <th><FiExternalLink size={24}/> Account ID</th>
                                            <th>At</th>
                                            <th>Name</th>
                                            <th>Duration</th>
                                            <th><FiExternalLink size={24}/> Account ID</th>
                                            <th>Email</th>
                                            <th>Source</th>
                                            <th>Created At</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {this.state.digitickets.map(digiticket => (
                                            <tr role="row" key={digiticket.id}>
                                                <td><Link style={{color: "#333"}}  to={"/" + GlobalConstants.APP_PATH + "purchases/digitickets/" + digiticket.id}>{digiticket.id}</Link></td>
                                                <td>{digiticket.code}</td>
                                                <td><Badge bg={digiticket.used ? 'success' : 'danger'}>{digiticket.used ? "Y" : "N"}</Badge></td>
                                                <td><Link style={{color: "#333"}}  to={"/" + GlobalConstants.APP_PATH + "accounts/" + digiticket.redeemedByAccountId}>{digiticket.redeemedByAccountId}</Link></td>
                                                <td>{digiticket.redeemedAt}</td>
                                                <td>{digiticket.product != null ? digiticket.product.billwerkName : null}</td>
                                                <td>{digiticket.product != null ? PeriodToReadableDuration(digiticket.product.period) : null}</td>
                                                <td><Link style={{color: "#333"}}  to={"/" + GlobalConstants.APP_PATH + "accounts/" + digiticket.createdByAccountId}>{digiticket.createdByAccountId}</Link></td>
                                                <td>{digiticket.createdByEmail}</td>
                                                <td><Badge bg={digiticket.source === 'CUSTOMER' ? 'success' : 'danger'}>{digiticket.source}</Badge></td>
                                                <td>{digiticket.createdDate}</td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </Table>
                                    <Pagination>
                                        <Pagination.Item hidden={this.state.digiticketFilter.page === 0}
                                                         onClick={() => this.loadDigitickets({}, this.state.digiticketFilter.page - 1)}>&lt;</Pagination.Item>
                                        <Pagination.Item active={true}>{this.state.digiticketFilter.page + 1}</Pagination.Item>
                                        <Pagination.Item hidden={this.state.digiticketFilter.page === this.state.lastDigiticketPage - 1}
                                                         onClick={() => this.loadDigitickets({}, this.state.digiticketFilter.page + 1)}>&gt;</Pagination.Item>
                                    </Pagination>
                                </>
                            }

                        </Tab>

                        <Tab eventKey="tigertickets"
                             title={<span style={{color: "#495057"}}><FcDiploma2 size={24}/> tigertickets (alt)</span>}>
                            <hr/>

                            {this.state.loading ?
                                <SpinningTiger/>
                                :
                                <>
                                    <h5>Filter</h5>
                                    <Form>
                                        <Row style={{width: "50%"}}>
                                            <Form.Group as={Col}>
                                                <Label>Code</Label>
                                                <FormControl type="text" value={this.state.tigerticketFilter.code}
                                                             onChange={(e) => this.setState(prevState => ({
                                                                 ...prevState,
                                                                 tigerticketFilter: {
                                                                     ...prevState.tigerticketFilter,
                                                                     code: e.target.value
                                                                 }}))}/>
                                            </Form.Group>
                                            <Form.Group as={Col}>
                                                <Label>Account ID</Label>
                                                <FormControl type="text" value={this.state.tigerticketFilter.accountId}
                                                             onChange={(e) => this.setState(prevState => ({
                                                                 ...prevState,
                                                                 tigerticketFilter: {
                                                                     ...prevState.tigerticketFilter,
                                                                     accountId: e.target.value
                                                                 }}))}/>
                                            </Form.Group>
                                        </Row>
                                    </Form>
                                    <div style={{float: "right"}}>
                                        <button className="form-btn-ci-light" onClick={() => {
                                            this.setState(prevState => ({
                                                ...prevState,
                                                tigerticketFilter: {
                                                    page: 0,
                                                    code: "",
                                                    accountId: "",
                                                    per_page: 20
                                                },
                                                digiticketFilter: {
                                                    page: 0,
                                                    code: "",
                                                    accountId: "",
                                                    per_page: 20
                                                }
                                            }));
                                            this.loadTigertickets({code: "", accountId: "", page: 0}, 0).then(r => r);
                                        }}>Reset</button>
                                        <button className="form-btn-ci-blue" onClick={() => this.loadTigertickets({code: this.state.tigerticketFilter.code, accountId: this.state.tigerticketFilter.accountId}, 0)}>Search</button>
                                    </div>
                                    <br />
                                    <br />
                                    <Table hover responsive bordered>
                                        <thead>
                                        <tr>
                                            <th colSpan="3" style={{textAlign: "center"}}>General Details</th>
                                            <th colSpan="3" style={{textAlign: "center"}}>Redemption Status</th>
                                            <th colSpan="2" style={{textAlign: "center"}}>Billwerk Details</th>
                                            <th colSpan="1" style={{textAlign: "center"}}>Source:</th>
                                        </tr>
                                        <tr>
                                            <th><FiExternalLink size={24}/> ID</th>
                                            <th>NFC Card Code</th>
                                            <th>Code</th>
                                            <th>Used</th>
                                            <th><FiExternalLink size={24}/> Account ID</th>
                                            <th>At</th>
                                            <th>Name</th>
                                            <th>Duration</th>
                                            <th>Created At</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {this.state.tigertickets.map(tigerticket => (
                                            <tr role="row" key={tigerticket.id}>
                                                <td><Link style={{color: "#333"}}  to={"/" + GlobalConstants.APP_PATH + "purchases/tigertickets/" + tigerticket.id}>{tigerticket.id}</Link></td>
                                                <td>{tigerticket.nfcCardCode}</td>
                                                <td>{tigerticket.code}</td>
                                                <td><Badge bg={tigerticket.used ? 'success' : 'danger'}>{tigerticket.used ? "Y" : "N"}</Badge></td>
                                                <td><Link style={{color: "#333"}}  to={"/" + GlobalConstants.APP_PATH + "accounts/" + tigerticket.redeemedByAccountId}>{tigerticket.redeemedByAccountId}</Link></td>
                                                <td>{tigerticket.redeemedAt}</td>
                                                <td>{tigerticket.product != null ? tigerticket.product.billwerkName : null}</td>
                                                <td>{tigerticket.product != null ? PeriodToReadableDuration(tigerticket.product.period) : null}</td>
                                                <td>{tigerticket.createdDate}</td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </Table>
                                    <Pagination>
                                        <Pagination.Item hidden={this.state.tigerticketFilter.page === 0}
                                                         onClick={() => this.loadTigertickets({}, this.state.tigerticketFilter.page - 1)}>&lt;</Pagination.Item>
                                        <Pagination.Item active={true}>{this.state.tigerticketFilter.page + 1}</Pagination.Item>
                                        <Pagination.Item hidden={this.state.tigerticketFilter.page === this.state.lastTigerticketPage - 1}
                                                         onClick={() => this.loadTigertickets({}, this.state.tigerticketFilter.page + 1)}>&gt;</Pagination.Item>
                                    </Pagination>
                                </>
                            }

                        </Tab>

                        <Tab eventKey="coupons"
                             title={<span style={{color: "#495057"}}><FcCloseUpMode size={24}/> Coupons</span>}>
                            <hr/>

                            {this.state.loading ?
                                <SpinningTiger/>
                                :
                                <>
                                    <h5>Filter</h5>
                                    <Form>
                                        <Row style={{width: "50%"}}>
                                            <Form.Group as={Col}>
                                                <Label>Code</Label>
                                                <FormControl type="text" value={this.state.couponFilter.code}
                                                             onChange={(e) => this.setState(prevState => ({
                                                                 ...prevState,
                                                                 couponFilter: {
                                                                     ...prevState.couponFilter,
                                                                     code: e.target.value
                                                                 }}))}/>
                                            </Form.Group>
                                            <Form.Group as={Col}>
                                                <Label>Account ID</Label>
                                                <FormControl type="text" value={this.state.couponFilter.accountId}
                                                             onChange={(e) => this.setState(prevState => ({
                                                                 ...prevState,
                                                                 couponFilter: {
                                                                     ...prevState.couponFilter,
                                                                     accountId: e.target.value
                                                                 }}))}/>
                                            </Form.Group>
                                            <Form.Group as={Col}>
                                                <Label>Variant</Label>
                                                <FormSelect style={{height: "48px"}} value={this.state.couponFilter.variantId}  onChange={(e) => this.setState(prevState => ({
                                                    ...prevState,
                                                    couponFilter: {
                                                        ...prevState.couponFilter,
                                                        variantId: e.target.value
                                                    }}))}>
                                                    <option value="">All</option>
                                                    {this.state.couponVariants.map(variant => (
                                                        <option value={variant.id}>{variant.name}</option>
                                                    ))}
                                                </FormSelect>
                                            </Form.Group>
                                        </Row>
                                    </Form>
                                    <div style={{float: "right"}}>
                                        <button className="form-btn-ci-light" onClick={() => {
                                            this.setState(prevState => ({
                                                ...prevState,
                                                tigerticketFilter: {
                                                    page: 0,
                                                    code: "",
                                                    accountId: "",
                                                    per_page: 20
                                                },
                                                digiticketFilter: {
                                                    page: 0,
                                                    code: "",
                                                    accountId: "",
                                                    per_page: 20
                                                },
                                                couponFilter: {
                                                    page: 0,
                                                    per_page: 20,
                                                    code: "",
                                                    accountId: "",
                                                    variantId: "",
                                                }
                                            }));
                                            this.loadCoupons({code: "", accountId: "", page: 0, per_page: 0, variantId: ""}, 0).then(r => r);
                                        }}>Reset</button>
                                        <button className="form-btn-ci-blue" onClick={() => this.loadCoupons({code: this.state.couponFilter.code, accountId: this.state.couponFilter.accountId, variantId: this.state.couponFilter.variantId}, 0)}>Search</button>
                                    </div>
                                    <br />
                                    <br />
                                    <Table hover responsive bordered>
                                        <thead>
                                        <tr>
                                            <th colSpan="3" style={{textAlign: "center"}}>General Details</th>
                                            <th colSpan="3" style={{textAlign: "center"}}>Redemption Status</th>
                                            <th colSpan="2" style={{textAlign: "center"}}>Billwerk Details</th>
                                        </tr>
                                        <tr>
                                            <th><FiExternalLink size={24}/> ID</th>
                                            <th>Code</th>
                                            <th>NFC Card Code</th>
                                            <th>Used</th>
                                            <th><FiExternalLink size={24}/> Account ID</th>
                                            <th>At</th>
                                            <th>Name</th>
                                            <th>Duration</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {this.state.coupons.map(c => (
                                            <tr role="row" key={c.id}>
                                                <td><Link style={{color: "#333"}}  to={"/" + GlobalConstants.APP_PATH + "purchases/coupons/" + c.id}>{c.id}</Link></td>
                                                <td>{c.code}</td>
                                                <td>{c.nfcCardCode}</td>
                                                <td><Badge bg={c.used ? 'success' : 'danger'}>{c.used ? "Y" : "N"}</Badge></td>
                                                <td><Link style={{color: "#333"}}  to={"/" + GlobalConstants.APP_PATH + "accounts/" + c.redeemedByAccountId}>{c.redeemedByAccountId}</Link></td>
                                                <td>{c.redeemedAt}</td>
                                                <td>{c.product != null ? c.product.billwerkName : null}</td>
                                                <td>{c.product != null ? PeriodToReadableDuration(c.product.period) : null}</td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </Table>
                                    <Pagination>
                                        <Pagination.Item hidden={this.state.couponFilter.page === 0}
                                                         onClick={() => this.loadCoupons(this.state.couponFilter, this.state.couponFilter.page - 1)}>&lt;</Pagination.Item>
                                        <Pagination.Item active={true}>{this.state.couponFilter.page + 1}</Pagination.Item>
                                        <Pagination.Item hidden={this.state.couponFilter.page === this.state.lastCouponPage - 1}
                                                         onClick={() => this.loadCoupons(this.state.couponFilter, this.state.couponFilter.page + 1)}>&gt;</Pagination.Item>
                                    </Pagination>
                                </>
                            }

                        </Tab>
                    </Tabs>

                    <hr/>

                </div>

                {/* TABLE SETTINGS */}
                <TableSettingsDialog show={this.state.showDialog.tableSettings}
                                     columns={this.state.digiticketColumns}
                                     onSetState={(columns) => this.setState(prevState => ({
                                         ...prevState,
                                         digiticketColumns: columns
                                     }))}
                                     onHide={() => this.showOrHideDialog('tableSettings', false)}
                />

                <ErrorHandler error={this.state.error}
                              onHide={() => this.setState(prevState => ({...prevState, error: null}))} />
            </div>
        );
    }

    //----------------
    // HELPERS
    //----------------

    setTicketProducts(ticketList) {
        for (let i = 0; i < ticketList.length; i++) {
            let ticket = ticketList[i];
            if (ticket.mappedBillwerkProductId != null) {
                let matchingProduct = this.state.products.filter(p => p.id === ticket.mappedBillwerkProductId);
                if (matchingProduct != null && matchingProduct.length > 0) {
                    ticket.product = matchingProduct[0];
                }
            }
        }
    }

    //----------------
    // STATE HANDLING
    //----------------

    updateFilter(id, value) {
        //Get the state and the filter
        let state = this.state;
        let filter = state.filter;

        //Update the attribute
        filter[id] = value;

        //Set the state
        this.setState(prevState => ({...prevState, filter: filter}));
    }

    updateState(param, value) {
        let state = this.state;
        state[param] = value;
        this.setState(state);
    }

    showOrHideDialog(dialogId, show) {
        //Get the state and the dialog
        let state = this.state;
        let dialogs = state.showDialog;

        //Update the visibility
        dialogs[dialogId] = show;

        //Set the state
        this.setState(prevState => ({...prevState, showDialog: dialogs}));
    }
}

export default withToast(Tickets);