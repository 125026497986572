import {Button, Col, Form, Table} from "react-bootstrap";
import Row from 'react-bootstrap/Row';
import React, {Component} from "react";
import {withToast} from "../../util/ToastService";
import {Link} from "react-router-dom";
import {BiDetail, FaLayerGroup,} from "react-icons/all";
import GlobalConstants from "../../config/GlobalConstants";
import {CreateApp, GetApp, UpdateApp} from "./AppService";
import {DetailsSection, RequiredFieldsAreValid} from "../../generators/DetailsGenerator";
import AppMetaData from "./AppMetaData";
import {GetProductGroup} from "../productGroups/ProductGroupService";
import {Label} from "reactstrap";
import {InfoModal} from "../../generators/ModalGenerator";
import {GetAllProductGroups} from "../products/ProductService";
import {PromiseButton} from "../../global/SpinningTiger";
import {ErrorHandler} from "../../util/ErrorHandler";

class AppDetails extends Component {

    //------------
    //Constructor
    //------------

    constructor(props) {
        super(props);

        this.state = {
            error: null,
            flowState: props.location.state,
            id: this.props.match.params.id,
            editMode: {
                active: false,
                expertMode: false,
                autoSave: true,
            },
            app: {},
            originalApp: {},
            attributes: [],
            productGroup: {},
            productGroups: [],
            sections: {
                general: true,
                productGroupDetails: true,
                attributes: true,
            },
            showProductGroupPickerDialog: false,
            validationErrors: []
        }
    }

    //---------
    //Mounting
    //---------

    async componentDidMount() {
        //Set the title
        if(this.state.id != null) {
            document.title = "App " + this.state.id + " :: Tiger UI";
        } else {
            document.title = "New app :: Tiger UI";
        }
        await this.loadApp();
        await this.loadProductGroup();
    }

    //--------
    //Loading
    //--------

    async loadApp() {
        let loadedApp = {};

        if(this.state.id != null && this.state.id !== "add") {

            loadedApp = await GetApp(this.state.id);

            if (!loadedApp.error) {
                const app = loadedApp.result;

                this.setState({
                    id: this.state.id,
                    editMode: this.state.editMode,
                    app: app,
                    attributes: app.attributes,
                    originalApp: app,
                });
            } else {
                this.setState(prevState => ({...prevState, error: loadedApp}));
            }
        } else {
            this.setState((prevState) => ({...prevState,
                id: null,
                app: {
                    id: null,
                },
                editMode: {
                    active: true,
                    expertMode: false,
                    autoSave: false,
                }}));
        }
    }

    async loadProductGroup() {
        let loadedProductGroup = {};

        if(this.state.app.productGroupId != null) {

            loadedProductGroup = await GetProductGroup(this.state.app.productGroupId);

            if (!loadedProductGroup.error) {
                const productGroup = loadedProductGroup.result;

                this.setState(prevState => ({...prevState, productGroup: productGroup}));
            } else {
                this.setState(prevState => ({...prevState, error: loadedProductGroup}));
            }
        }
    }

    //----------
    //Rendering
    //----------

    render() {

        const labelStyle = {
            fontSize: "20px",
            backgroundColor: "#C8C8C8",
            borderStyle: "solid",
            borderColor: "#cdd7e0",
            borderWidth: "1px",
            borderRadius: "5px",
            paddingLeft: "10px",
            paddingRight: "10px",
            width: "100%"
        };

        return (
            <>
                <div className="details-title">
                    {this.state.app.id != null ? "Details of App " + this.state.app.id : "Create a new App"}
                </div>
                <div className="details-button-box" style={{height: "70px"}}>
                    <Link to={{pathname: "/" + GlobalConstants.APP_PATH +"apps", state: this.state.flowState}}>
                        <button className="form-btn-ci-light-blue" type="button">Back</button>
                    </Link>
                    {this.state.app.id != null &&
                    <button className={this.state.editMode.active ? "form-btn-ci-red" : "form-btn-ci-blue"} type="button" onClick={() => this.setState({
                        editMode: {
                            active: !this.state.editMode.active,
                            expertMode: this.state.editMode.expertMode
                        },
                        app: this.state.originalApp
                    })}>{this.state.editMode.active ? "Cancel" : "Edit"}</button>
                    }
                    {this.state.editMode.active &&
                        <PromiseButton text="Save" onClick={() => this.saveOrUpdateApp()} />
                    }
                    <Button style={{float: "right", marginTop: "5px", marginRight: "10px"}} variant={this.state.editMode.autoSave ? "secondary": "outline-secondary"}
                            onClick={() => this.setState(prevState => ({
                                ...prevState,
                                editMode: {
                                    ...prevState.editMode,
                                    autoSave: !this.state.editMode.autoSave
                                }
                            }))}>Auto Save</Button>
                </div>

                <div className="details-box-title">Resource Details</div>
                <div className="details-box">
                    {/* GENERAL DATA */}
                    <DetailsSection
                        nameInState="app"
                        fields={AppMetaData.DETAILS_GENERAL}
                        state={this.state}
                        onSetState={(s) => this.setState(s)}
                        onUpdateResource={() => this.saveOrUpdateApp()}
                        sectionId="general"
                    />

                    {/* PRODUCT GROUP DETAILS */}
                    <DetailsSection
                        nameInState="app"
                        fields={[]}
                        state={this.state}
                        onSetState={(s) => this.setState(s)}
                        sectionId="productGroupDetails"
                        label={<span><FaLayerGroup/>&#xA0;Product Group Details</span>}
                    />

                    {this.state.sections.productGroupDetails &&
                    <>
                        <Form>
                            {this.state.editMode.active &&
                                <button className="form-btn-ci-blue" type="button" style={{marginBottom: "10px"}} onClick={() => this.loadProductGroups().then(() => this.setState(prevState => ({...prevState, showProductGroupPickerDialog: true})))}>Change</button>
                            }
                            <Row>
                                <Form.Group as={Col} controlId="productGroupId">
                                    <Label style={labelStyle}>Product Group ID</Label>
                                    <Form.Control type="text" value={this.state.productGroup.id}
                                                  readOnly={true}
                                                  onChange={(e) => this.updateParam("productGroup", "id", e.target.value)}/>
                                </Form.Group>
                                <Form.Group as={Col} controlId="productGroupName">
                                    <Label style={labelStyle}>Product Group Name</Label>
                                    <Form.Control type="text" value={this.state.productGroup.name}
                                                  readOnly={true}
                                                  onChange={(e) => this.updateParam("productGroup", "name", e.target.value)}/>
                                </Form.Group>
                            </Row>
                        </Form>
                    </>
                    }

                    {/* ATTRIBUTES */}
                    <DetailsSection
                        nameInState="app"
                        fields={[]}
                        state={this.state}
                        onSetState={(s) => this.setState(s)}
                        sectionId="attributes"
                        label={<span><BiDetail/>&#xA0;Attributes</span>}
                    />

                    {this.state.sections.attributes &&
                    <>
                        <Table responsive bordered hover striped>
                            <thead>
                                <tr>
                                    <th>Attribute Name</th>
                                    <th>Value</th>
                                </tr>
                            </thead>
                            <tbody>
                            {Object.keys(this.state.attributes).map(key =>(
                                <tr role="row" key={key}>
                                    <td>{key}</td>
                                    <td>{this.state.attributes[key]}</td>
                                </tr>
                            ))}
                            </tbody>
                        </Table>
                    </>
                    }

                </div>
                <div className="details-button-box" style={{height: "70px"}}>
                    <Link to={{pathname: "/" + GlobalConstants.APP_PATH +"apps", state: this.state.flowState}}>
                        <button className="form-btn-ci-light-blue" type="button">Back</button>
                    </Link>
                    {this.state.editMode.active &&
                        <PromiseButton text="Save" onClick={() => this.saveOrUpdateApp()} />
                    }
                </div>

                {/* DIALOGS */}
                <InfoModal show={this.state.showProductGroupPickerDialog}
                             onHide={() => this.setState(prevState => ({
                                 ...prevState,
                                 showProductGroupPickerDialog: false
                             }))}
                             title="Pick a product group"
                             body={this.pickProductGroupDialog()}/>

                <ErrorHandler error={this.state.error}
                              onHide={() => this.setState(prevState => ({...prevState, error: null}))} />
            </>
        );
    }

    //------------
    //API Methods
    //------------

    async saveOrUpdateApp() {
        if (!RequiredFieldsAreValid("app", AppMetaData.DETAILS_GENERAL, this.state, (s) => this.setState(s))) {
            return;
        }
        let app = {};
        if(this.state.app.id != null) {
            app = await UpdateApp(this.state.app);
        } else {
            app = await CreateApp(this.state.app);
        }

        if (!app.error) {
            if (this.state.app.id == null) {
                this.props.history.push("/"+ GlobalConstants.APP_PATH + "apps/" + app.result.id);
            }
            this.setState((prevState) =>({...prevState, app: app.result, editMode: {...prevState.editMode, active: this.state.editMode.autoSave}}));

            this.props.addToast("The app has been updated successfully.", {
                autoDismiss: true,
                appearance: 'success'
            });
        } else {
            this.setState(prevState => ({...prevState, error: app}));
        }
    }

    async loadProductGroups() {
        const loadedProductGroups = await GetAllProductGroups();

        if (loadedProductGroups.error === true) {
            this.setState(prevState => ({...prevState, error: loadedProductGroups}));
        }
        this.setState(prevState => ({
            ...prevState,
            productGroups: loadedProductGroups.result,
        }));
    }

    //--------
    // DIALOGS
    //--------

    pickProductGroupDialog() {
        return (
            <div>
                <Table striped bordered hover>
                    <thead>
                    <tr>
                        <th>ID</th>
                        <th>Name</th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.state.productGroups.map(group => (
                        <tr role="row" key={group.id} style={{cursor: "pointer"}} onClick={() => {
                            let app = this.state.app;
                            app.productGroupId = group.id;
                            this.setState(prevState => ({...prevState, app: app, productGroup: group, showProductGroupPickerDialog: false}));
                        }}>
                            <td>{group.id}</td>
                            <td>{group.name}</td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
            </div>
        )
    }

    //--------
    // HELPERS
    //--------

    updateParam(resource, param, value) {
        let state = this.state;
        let res = state[resource];
        res[param] = value;
        state[resource] = res;

        this.setState(state);
    }

}


export default withToast(AppDetails);