import React, {useState} from "react";
import {Badge, Table} from "react-bootstrap";
import GlobalConstants from "../../config/GlobalConstants";
import {Link} from "react-router-dom";
import {AiOutlineFileAdd, FiTrash2} from "react-icons/all";
import {DeleteProfile} from "../profiles/ProfileService";
import {ErrorHandler} from "../../util/ErrorHandler";

export function AccountProfiles(props) {
    let [profiles, setProfiles] = useState(props.profiles);
    let [error, setError] = useState(null);

    return(
      <>

              <Link to={"/" + GlobalConstants.APP_PATH + "accounts/" + props.accountId + "/profiles/add"}><button className="form-btn-ci-blue" type="button"><AiOutlineFileAdd/>&#xA0;Create Profile</button></Link>
        <Table style={{marginTop: "10px"}}bordered hover responsive striped>
            <thead>
                <tr>
                    <th>ID</th>
                    <th>Default Profile</th>
                    <th>Name</th>
                    <th>Remove</th>
                </tr>
            </thead>
            <tbody>
            {profiles.map(profile => (
                <tr role="row" key={profile.id}>
                    <td><Link style={{fontStyle: "italic", color: "#333"}} to={"/" + GlobalConstants.APP_PATH + "accounts/" + props.accountId + "/profiles/" + profile.id}>{profile.id}</Link></td>
                    <td><Badge bg={profile.defaultProfile ? "success" : "danger"}>{profile.defaultProfile ? "Y" : "N"}</Badge></td>
                    <td>{profile.name}</td>
                    <td>
                        <button className="form-btn-ci-red" onClick={() => deleteProfile(profile)}><FiTrash2 /></button>
                    </td>
                </tr>
            ))}
            </tbody>
        </Table>
          <ErrorHandler error={error}
                        onHide={() => setError(null)} />
      </>
    );

    async function deleteProfile(profile){
        let result = await DeleteProfile(props.accountId, profile);
        if(!result.error) {
            let profileList = profiles.filter(u => u.id !== profile.id);
            setProfiles(profileList);
        } else {
            setError(error);
        }
    }
}