import {CreateSpineResource, DeleteSpineResource, GetFilteredSpineResources, GetSpineResource, UpdateSpineResource} from "../../clients/SpineClient";

export async function GetFilteredCategories(filter){
    return await GetFilteredSpineResources('api/categories', filter);
}

export async function CreateCategory(category) {
    return await CreateSpineResource('api/categories', category);
}

export async function GetCategory(id) {
    return await GetSpineResource('api/categories/' + id);
}

export async function UpdateCategory(category) {
    return await UpdateSpineResource('api/categories/' + category.id, category);
}

export async function DeleteCategory(category) {
    return await DeleteSpineResource('api/categories/' + category.id);
}