import {Badge, Button, Col, Form, InputGroup, Pagination, Table} from "react-bootstrap";
import React, {Component} from "react";
import Row from "react-bootstrap/Row";
import {withToast} from "../../util/ToastService";
import {Link} from "react-router-dom";
import {FaBook, FaMoneyBillWave, IoReorderThreeOutline,} from "react-icons/all";
import GlobalConstants from "../../config/GlobalConstants";
import {CreateSubscription, GetSubscription, UpdateSubscription} from "./SubscriptionService";
import {DetailsSection, RequiredFieldsAreValid} from "../../generators/DetailsGenerator";
import SubscriptionMetaData from "./SubscriptionMetaData";
import {Label} from "reactstrap";
import {GetFilteredNonAudioProducts} from "../products/ProductService";
import {GetFilteredProductGroups} from "../productGroups/ProductGroupService";
import {InfoModal} from "../../generators/ModalGenerator";
import {PromiseButton} from "../../global/SpinningTiger";
import {ErrorHandler} from "../../util/ErrorHandler";

class SubscriptionDetails extends Component {

    //------------
    //Constructor
    //------------

    constructor(props) {
        super(props);

        this.state = {
            error: null,
            flowState: props.location.state,
            loading: false,
            id: this.props.match.params.id,
            accountId: this.props.match.params.accountId,
            editMode: {
                active: false,
                expertMode: false,
                autoSave: true,
            },
            subscription: {
                accountId: this.props.match.params.accountId,
                source: "SUPPORT",
                active: true,
            },
            originalSubscription: {
                accountId: this.props.match.params.accountId,
                source: "SUPPORT",
                active: true,
            },
            sections: {
                general: true,
                purchaseDetails: true,
                contentDetails: true,
                orderDetails: true,
            },
            //Articles
            showArticlePickerDialog: false,
            availableArticles: [],
            articlePage: 1,
            lastArticlePage: 1,
            
            //Product Groups
            showProductGroupPickerDialog: false,
            availableProductGroups: [],
            productGroupPage: 1,
            lastProductGroupPage: 1,
            validationErrors: []
        }
    }

    //---------
    //Mounting
    //---------

    async componentDidMount() {
        await this.loadSubscription();
    }

    //--------
    //Loading
    //--------

    async loadSubscription() {
        let loadedSubscription = {};

        if (this.state.id != null && this.state.id !== "add") {

            loadedSubscription = await GetSubscription({id: this.state.accountId}, this.state.id);

            if (!loadedSubscription.error) {
                const subscription = loadedSubscription.result;

                this.setState({
                    id: this.state.id,
                    editMode: this.state.editMode,
                    subscription: subscription,
                    originalSubscription: subscription,
                });
            } else {
                this.setState(prevState => ({...prevState, error: loadedSubscription}));
            }
        } else {
            this.setState((prevState) => ({
                ...prevState,
                id: null,
                subscription: {
                    id: null,
                    accountId: this.props.match.params.accountId,
                    source: "SUPPORT",
                    active: true,
                },
                editMode: {
                    active: true,
                    expertMode: false,
                    autoSave: false,
                }
            }));
        }
    }
    
    async loadArticles(page) {
        let loadedArticles = await GetFilteredNonAudioProducts({productType: 'SUBSCRIPTION', page: page});
        
        if(!loadedArticles.error) {
            let articles = loadedArticles.result;
            this.setState(prevState => ({
                ...prevState,
                availableArticles: articles,
                articlePage: page,
                lastArticlePage: Math.ceil(loadedArticles.length/20),
                loading: false,
            }));
        } else {
            this.setState(prevState => ({...prevState, error: loadedArticles}));
        }
    }
    
    async loadProductGroups(page) {
        let loadedProductGroups = await GetFilteredProductGroups({page: page});
        
        if(!loadedProductGroups.error) {
            let productGroups = loadedProductGroups.result;
            this.setState(prevState => ({
                ...prevState,
                availableProductGroups: productGroups,
                productGroupPage: page,
                lastProductGroupPage: Math.ceil(loadedProductGroups.length/20),
            }));
        } else {
            this.setState(prevState => ({...prevState, error: loadedProductGroups}));
        }
    }

    //----------
    //Rendering
    //----------

    render() {

        const labelStyle = {
            fontSize: "20px",
            backgroundColor: "#C8C8C8",
            borderStyle: "solid",
            borderColor: "#cdd7e0",
            borderWidth: "1px",
            borderRadius: "5px",
            paddingLeft: "10px",
            paddingRight: "10px",
            width: "100%"
        };

        return (
            <>
                <div className="details-title">
                    {this.state.subscription.id != null ? "Details of Subscription " + this.state.subscription.id : "Create a new Subscription"}
                </div>
                <div className="details-button-box" style={{height: "70px"}}>
                    <Link to={{pathname: "/" + GlobalConstants.APP_PATH + "accounts/" + this.state.accountId, state: this.state.flowState}}>
                        <button className="form-btn-ci-light-blue" type="button">Back</button>
                    </Link>
                    {this.state.subscription.id != null &&
                    <button className={this.state.editMode.active ? "form-btn-ci-red" : "form-btn-ci-blue"}
                            type="button" onClick={() => this.setState({
                        editMode: {
                            active: !this.state.editMode.active,
                            expertMode: this.state.editMode.expertMode
                        },
                        subscription: this.state.originalSubscription
                    })}>{this.state.editMode.active ? "Cancel" : "Edit"}</button>
                    }
                    {this.state.editMode.active &&
                    <PromiseButton text="Save" onClick={() => this.saveOrUpdateSubscription()} />
                    }
                    <Button style={{float: "right", marginTop: "5px"}}
                            variant={this.state.editMode.expertMode ? "secondary" : "outline-secondary"}
                            onClick={() => this.setState(prevState => ({
                                ...prevState,
                                editMode: {
                                    ...prevState.editMode,
                                    expertMode: !this.state.editMode.expertMode
                                }
                            }))}>Expert Mode</Button>
                    <Button style={{float: "right", marginTop: "5px", marginRight: "10px"}} variant={this.state.editMode.autoSave ? "secondary": "outline-secondary"}
                            onClick={() => this.setState(prevState => ({
                                ...prevState,
                                editMode: {
                                    ...prevState.editMode,
                                    autoSave: !this.state.editMode.autoSave
                                }
                            }))}>Auto Save</Button>
                </div>

                <div className="details-box-title">Resource Details</div>
                <div className="details-box">
                    {/* GENERAL DATA */}
                    <DetailsSection
                        nameInState="subscription"
                        fields={SubscriptionMetaData.DETAILS_GENERAL}
                        state={this.state}
                        onSetState={(s) => this.setState(s)}
                        onUpdateResource={() => this.saveOrUpdateSubscription()}
                        sectionId="general"
                    />

                    {/* PURCHASE DETAILS */}
                    <DetailsSection
                        nameInState="subscription"
                        fields={SubscriptionMetaData.PURCHASE_DETAILS}
                        state={this.state}
                        onSetState={(s) => this.setState(s)}
                        sectionId="purchaseDetails"
                        onUpdateResource={() => this.saveOrUpdateSubscription()}
                        label={<span><FaMoneyBillWave/>&#xA0;Purchase Details</span>}
                    />

                    {/* CONTENT DETAILS */}
                    <DetailsSection
                        nameInState="subscription"
                        fields={[]}
                        state={this.state}
                        onSetState={(s) => this.setState(s)}
                        sectionId="contentDetails"
                        label={<span><FaBook/>&#xA0;Content Details</span>}
                    />

                    {this.state.sections.contentDetails &&
                    <>
                        <Form>
                            <Row>
                                <Form.Group as={Col} controlId="articleId">
                                    <Label style={labelStyle}>Article ID*</Label>
                                    <InputGroup className="mb-3">
                                        <Form.Control type="text" value={this.state.subscription.articleId}
                                                      readOnly={true}/>
                                        {this.state.editMode.active &&
                                            <Button variant="outline-secondary" style={{zIndex: "0"}} onClick={() => {
                                                this.setState(prevState => ({...prevState, loading: true}));
                                                this.loadArticles(1).then(r => this.setState(prevState => ({
                                                    ...prevState,
                                                    showArticlePickerDialog: true,
                                                    loading: false}))).catch(r => this.setState(prevState => ({
                                                    ...prevState,
                                                    loading: false
                                                })));
                                            }}>Change</Button>
                                        }
                                    </InputGroup>

                                </Form.Group>
                                <Form.Group as={Col} controlId="productGroupId">
                                    <Label style={labelStyle}>Product Group ID*</Label>
                                    <InputGroup className="mb-3">
                                        <Form.Control type="text" value={this.state.subscription.productGroupId}
                                                      readOnly={true}/>
                                        {this.state.editMode.active &&
                                            <Button variant="outline-secondary" style={{zIndex: "0"}} onClick={() => {
                                                this.setState(prevState => ({...prevState, loading: true}));
                                                this.loadProductGroups(1).then(r => this.setState(prevState => ({
                                                    ...prevState,
                                                    showProductGroupPickerDialog: true,
                                                    loading: false}))).catch(r => this.setState(prevState => ({
                                                    ...prevState,
                                                    loading: false
                                                })));
                                            }}>Change</Button>
                                        }
                                    </InputGroup>
                                </Form.Group>
                            </Row>
                        </Form>
                    </>
                    }

                    {/* ORDER DETAILS */}
                    <DetailsSection
                        nameInState="subscription"
                        fields={SubscriptionMetaData.ORDER_DETAILS}
                        state={this.state}
                        onSetState={(s) => this.setState(s)}
                        sectionId="orderDetails"
                        onUpdateResource={() => this.saveOrUpdateSubscription()}
                        label={<span><IoReorderThreeOutline/>&#xA0;Order Details</span>}
                    />

                </div>
                <div className="details-button-box" style={{height: "70px"}}>
                    <Link to={{pathname: "/" + GlobalConstants.APP_PATH + "accounts/" + this.state.accountId, state: this.state.flowState}}>
                        <button className="form-btn-ci-light-blue" type="button">Back</button>
                    </Link>
                    {this.state.editMode.active &&
                    <PromiseButton text="Save" onClick={() => this.saveOrUpdateSubscription()} />
                    }
                </div>

                {/* DIALOGS */}
                <InfoModal show={this.state.showArticlePickerDialog}
                           onHide={() => this.setState(prevState => ({
                               ...prevState,
                               showArticlePickerDialog: false
                           }))}
                           title="Pick an article"
                           body={this.pickArticleDialog()}/>

                <InfoModal show={this.state.showProductGroupPickerDialog}
                           onHide={() => this.setState(prevState => ({
                               ...prevState,
                               showProductGroupPickerDialog: false
                           }))}
                           title="Pick a product group"
                           body={this.pickProductGroupDialog()}/>

                <ErrorHandler error={this.state.error}
                              onHide={() => this.setState(prevState => ({...prevState, error: null}))} />
            </>
        );
    }

    //------------
    //API Methods
    //------------

    async saveOrUpdateSubscription() {
        if (!RequiredFieldsAreValid('subscription', [SubscriptionMetaData.DETAILS_GENERAL, SubscriptionMetaData.PURCHASE_DETAILS],
            this.state, (s) => this.setState(s))) {
            return;
        }
        let subscription = {};
        if (this.state.subscription.id != null) {
            subscription = await UpdateSubscription({id: this.state.accountId }, this.state.subscription);
        } else {
            subscription = await CreateSubscription({id: this.state.accountId }, this.state.subscription);
        }

        if (!subscription.error) {
            if (this.state.subscription.id == null) {
                this.props.history.push("/"+ GlobalConstants.APP_PATH + "accounts/" + this.state.accountId + "/subscriptions/" + subscription.result.id);
            }
            this.setState((prevState) => ({
                ...prevState,
                subscription: subscription.result,
                editMode: {...prevState.editMode, active: this.state.editMode.autoSave}
            }));

            this.props.addToast("The subscription has been updated successfully.", {
                autoDismiss: true,
                appearance: 'success'
            });
        } else {
            this.setState(prevState => ({...prevState, error: subscription}));
        }
    }
    
    //--------
    // DIALOGS
    //--------
    
    pickArticleDialog() {
        return(
            <>
                <Table responsive bordered hover striped>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>State</th>
                            <th>Title</th>
                            <th>IAP Product Identifier</th>
                        </tr>
                    </thead>
                    <tbody>
                    {this.state.availableArticles.map(product => (
                        <tr role="row" key={product.id} style={{cursor: "pointer"}}
                        onClick={() => this.setState(prevState => ({
                            ...prevState,
                            subscription: {
                                ...prevState.subscription,
                                articleId: product.iapProductIdentifier
                            },
                            showArticlePickerDialog: false,
                        }))}>
                            <td><Link to={"/" + GlobalConstants.APP_PATH + "products/" + product.id} style={{fontStyle: "italic", color: "#333"}}>{product.id}</Link></td>
                            <td><Badge bg={
                                product.state === 'NEW' ? "primary" :
                                product.state === 'ACTIVE' ? "success" :
                                product.state === 'INACTIVE' ? "warning" : "danger"
                            }>{product.state}</Badge> </td>
                            <td>{product.title}</td>
                            <td>{product.iapProductIdentifier}</td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
                <Pagination>
                    <Pagination.Item hidden={this.state.articlePage === 1}
                                     onClick={() => this.loadArticles(1)}>&lt;&lt;</Pagination.Item>
                    <Pagination.Item hidden={this.state.articlePage === 1}
                                     onClick={() => this.loadArticles(this.state.articlePage - 1)}>&lt;</Pagination.Item>
                    <Pagination.Item hidden={this.state.articlePage <= 2}
                                     onClick={() => this.loadArticles(this.state.articlePage - 2)}>{this.state.articlePage - 2}</Pagination.Item>
                    <Pagination.Item hidden={this.state.articlePage === 1}
                                     onClick={() => this.loadArticles(this.state.articlePage - 1)}>{this.state.articlePage - 1}</Pagination.Item>
                    <Pagination.Item active={true}>{this.state.articlePage}</Pagination.Item>
                    <Pagination.Item hidden={this.state.articlePage === this.state.lastArticlePage}
                                     onClick={() => this.loadArticles(this.state.articlePage + 1)}>{this.state.articlePage + 1}</Pagination.Item>
                    <Pagination.Item hidden={this.state.articlePage >= this.state.lastArticlePage - 1}
                                     onClick={() => this.loadArticles(this.state.articlePage + 2)}>{this.state.articlePage + 2}</Pagination.Item>
                    <Pagination.Item hidden={this.state.articlePage === this.state.lastArticlePage}
                                     onClick={() => this.loadArticles(this.state.articlePage + 1)}>&gt;</Pagination.Item>
                </Pagination>
            </>
        );
    }

    pickProductGroupDialog() {
        return(
            <>
                <Table responsive bordered hover striped>
                    <thead>
                    <tr>
                        <th>ID</th>
                        <th>Name</th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.state.availableProductGroups.map(productGroup => (
                        <tr role="row" key={productGroup.id} style={{cursor: "pointer"}}
                            onClick={() => this.setState(prevState => ({
                                ...prevState,
                                subscription: {
                                    ...prevState.subscription,
                                    productGroupId: productGroup.id
                                },
                                showProductGroupPickerDialog: false,
                            }))}>
                            <td><Link to={"/" + GlobalConstants.APP_PATH + "productGroups/" + productGroup.id} style={{fontStyle: "italic", color: "#333"}}>{productGroup.id}</Link></td>
                            <td>{productGroup.name}</td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
                <Pagination>
                    <Pagination.Item hidden={this.state.productGroupPage === 1}
                                     onClick={() => this.loadProductGroups(1)}>&lt;&lt;</Pagination.Item>
                    <Pagination.Item hidden={this.state.productGroupPage === 1}
                                     onClick={() => this.loadProductGroups(this.state.productGroupPage - 1)}>&lt;</Pagination.Item>
                    <Pagination.Item hidden={this.state.productGroupPage <= 2}
                                     onClick={() => this.loadProductGroups(this.state.productGroupPage - 2)}>{this.state.productGroupPage - 2}</Pagination.Item>
                    <Pagination.Item hidden={this.state.productGroupPage === 1}
                                     onClick={() => this.loadProductGroups(this.state.productGroupPage - 1)}>{this.state.productGroupPage - 1}</Pagination.Item>
                    <Pagination.Item active={true}>{this.state.productGroupPage}</Pagination.Item>
                    <Pagination.Item hidden={this.state.productGroupPage === this.state.lastProductGroupPage}
                                     onClick={() => this.loadProductGroups(this.state.productGroupPage + 1)}>{this.state.productGroupPage + 1}</Pagination.Item>
                    <Pagination.Item hidden={this.state.productGroupPage >= this.state.lastProductGroupPage - 1}
                                     onClick={() => this.loadProductGroups(this.state.productGroupPage + 2)}>{this.state.productGroupPage + 2}</Pagination.Item>
                    <Pagination.Item hidden={this.state.productGroupPage === this.state.lastProductGroupPage}
                                     onClick={() => this.loadProductGroups(this.state.productGroupPage + 1)}>&gt;</Pagination.Item>
                </Pagination>
            </>
        );
    }

}


export default withToast(SubscriptionDetails);