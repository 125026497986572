import {
    CreateSpineResource,
    DeleteSpineResource,
    GetFilteredSpineResources,
    GetSpineResource,
    UpdateSpineResource
} from "../../clients/SpineClient";
import {trackPromise} from "react-promise-tracker";
import GlobalConstants from "../../config/GlobalConstants";
import avatarList from "../../../assets/data/avatar-list.json";
import {getToken} from "../../auth/SpineTokenStorageService";
import {GetRestResource} from "../../clients/RestResourceClient";

//API METHODS
//TODO LEGACY
export async function CreateProfile(accountId, profile) {
    profile.nickName = profile.name;
    return await CreateSpineResource('api/accounts/' + accountId + '/users', profile);
}

export async function GetFilteredProfiles(accountId, filter){
    return await GetFilteredSpineResources('api/accounts/' + accountId + "/profiles", filter);
}

export async function GetProfile(id) {
    return await GetSpineResource('api/profiles/' + id);
}

export async function UpdateProfile(accountId, profile) {
    return await UpdateSpineResource('api/profiles/' + profile.id, profile);
}

//TODO LEGACY
export async function DeleteProfile(accountId, profile) {
    return await DeleteSpineResource('api/accounts/' + accountId + '/users/' + profile.id, false);
}

export async function GetProfilePreferences(profileId) {
    return await GetFilteredSpineResources('api/profiles/' + profileId + '/preferences');
}

export async function GetProfileRecommendations(profileId) {
    return await GetRestResource('https://server.tigerbooks.de/api/searches/products-by-clients?productType=AUDIOBOOK&source=RECOMMENDATIONS&profileId=' + profileId, true);
}

export async function LoadAvatarList() {
    let errorOccurred = false;
    return trackPromise(fetch(GlobalConstants.AVATAR_LIST, {
        method: 'GET'
    })
        .then(function(response) {
            if(!response.ok) {
                errorOccurred = true;
            }
            return response.json();
        })
        .then(function (response) {
            if(errorOccurred) {
                return {error: true, result: avatarList};
            } else {
                return {error: false, result: response};
            }
        })
        .catch(function (error) {
            return {error: true, message: "An unknown error occurred: " + error, result: avatarList, length: 0}
        }));
}

export async function UpdateAvatar(profileId, newAvatar) {
    let errorOccurred = false;
    let token = getToken();
    return trackPromise(fetch(GlobalConstants.SPINE_CLIENT_HOST + 'api/profiles/' + profileId + '/update-avatar', {
        method: 'PUT',
        headers: {
            'Authorization': 'Bearer ' + token.access_token,
            'Content-Type': 'text/uri-list'
        },
        body: newAvatar
    }).then(function(response) {
        if(!response.ok) {
            errorOccurred = true;
        }
        return response;
    })
        .then(function (response) {
            if(errorOccurred) {
                return {error: true, message: response.message};
            } else {
                return {error: false};
            }
        })
        .catch(function (error) {
            return {error: true, message: "An unknown error occurred: " + error}
        }));
}

//---------- OPTIONAL -------------
/*
export async function PatchProfile(profile, newValue) {
    return await PatchResource('api/profiles/' + profile.id + '/patch', {attribute: newValue});
}
 */