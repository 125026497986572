const COLLECTION_TYPE_ITEMS = ["",
    'CUSTOMER_COLLECTION',
    'TIGER_COLLECTION',
    'MULTI_TIGERCARD_COLLECTION'];
const STATE_ITEMS = ['ACTIVE', 'INACTIVE'];
const PRIVACY_ITEMS = ['PRIVATE', 'PUBLIC'];

const CollectionMetaData = {

    //Columns
    COLUMNS: [
        {label: "ID", id: 'id', columnType: 'default', show: true},
        {label: "Name", id: 'name', columnType: 'default', show: true},
        {label: "Language", id: 'language', columnType: 'default', show: true},
        {label: "Age", id: 'ageRecommended', columnType: 'default', show: true},
        {label: "State", id: 'state', columnType: 'badge', options: [
                {value: "ACTIVE", style: "success", label: "ACTIVE"},
                {value: "INACTIVE", style: "danger", label: "INACTIVE"},
            ], show: true},
        {label: "Collection Type", id: 'collectionType', columnType: 'badge', options: [
                {value: "TIGER_COLLECTION", style: "primary", label: "TIGER"},
                {value: "CUSTOMER_COLLECTION", style: "cyan", label: "CUSTOMER"},
                {value: "MULTI_TIGERCARD_COLLECTION", style: "light-blue", label: "MULTI_TIGERCARD"},
            ], show: true},
        {label: "Privacy", id: 'privacy', columnType: 'badge', options: [
                {value: "PUBLIC", style: "success", label: "PUBLIC"},
                {value: "PRIVATE", style: "danger", label: "PRIVATE"},
            ], show: true},
    ],

    //Details
    DETAILS_GENERAL: [
        [{label: 'ID', id: 'id', type: 'number', editable: 'never', isExpertField: false},
            {label: 'Collection Type', id: 'collectionType', type: 'select', options: COLLECTION_TYPE_ITEMS, editable: 'true', required: true, isExpertField: false},
            {label: "Recommended Age", id: 'ageRecommended', type: 'number', editable: 'true'},
            {label: "Language", id: 'language', type: 'language', editable: 'true'}
        ],
        [{label: 'Name', id: 'name', type: 'text', editable: 'true', required: true, isExpertField: false}],
        [
                {label: 'Display Image Url', id: 'displayImageUrl', type: 'text', editable: 'true', isExpertField: false},
                {label: 'Display Image', id: 'displayImageUrl', type: 'image', editable: 'false', isExpertField: false},
        ],
        [
            {label: 'State', id: 'state', type: 'select', options: STATE_ITEMS, editable: 'true', required: false, isExpertField: false},
            {label: 'Privacy', id: 'privacy', type: 'select', options: PRIVACY_ITEMS, editable: 'true', required: false, isExpertField: false},
            {label: 'Tags', type: 'custom', editable: 'true', required: false, isExpertField: false},
        ],
        [{label: 'Description', id: 'description', type: 'textarea', rows: 5, editable: 'true', isExpertField: false}],
        [{label: 'Internal Description', id: 'internalDescription', type: 'textarea', rows: 5, editable: 'true', isExpertField: false}],
    ],

    DETAILS_SECTION_ACCOUNT: [
        [
            {label: "Account ID", id: 'ownerAccountId', type: 'number', editable: 'true'},
            {label: "Profile ID", id: 'ownerProfileId', type: 'number', editable: 'true'}
        ],
    ],

    //Attributes
    COLLECTION_TYPES: COLLECTION_TYPE_ITEMS,
    STATES: STATE_ITEMS,
    PRIVACY_TYPES: PRIVACY_ITEMS,

    //Sorting
    COLLECTION_SORT_PARAMS: [{id: 'id', label: 'Collection ID'},
        {id: 'name', label: 'Name'},
        {id: 'ageRecommended', label: 'Age'}],
};

export default CollectionMetaData;

export function CollectionStateBadges(collection) {
    if(collection.state === "ACTIVE") {
        return "success";
    } else if(collection.state === "INACTIVE") {
        return "warning";
    } else {
        return "danger";
    }
}

export function CollectionTypeBadges(collection) {
    if(collection.collectionType === "CUSTOMER_COLLECTION") {
        return "primary";
    } else if(collection.collectionType === "TIGER_COLLECTION") {
        return "info";
    } else if(collection.collectionType === "MULTI_TIGERCARD_COLLECTION") {
        return "light";
    } else {
        return "dark";
    }
}

