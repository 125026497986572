import React, {useState} from "react";
import {Col, Form} from "react-bootstrap";
import Row from 'react-bootstrap/Row';
import {RedeemTicket} from "../SupportToolService";
import {AsyncTypeahead} from "react-bootstrap-typeahead";
import GlobalConstants from "../../../config/GlobalConstants";
import {getToken} from "../../../auth/SpineTokenStorageService";
import {Label} from "reactstrap";
import {PromiseButton} from "../../../global/SpinningTiger";

export function RedeemTicketDialog(props) {

    const [ticket, setTicket] = useState(null);
    let [message, setMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [loadedTickets, setLoadedTickets] = useState([]);
    const token = getToken();

    const filterBy = () => true;

    const handleSearch = (code) => {

        setIsLoading(true);

        const url = GlobalConstants.BILL_CLIENT_HOST + 'api/purchases/tickets/search/by-code?code=' + code;

        fetch(url, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token.access_token,
            }
        })
            .then(function(resp) {
                return resp.json()
            })
            .then(function (tickets) {
                const ticketList = tickets.map((ticket) => ({
                    code: ticket.code,
                    pin: ticket.secondSecret,
                    used: ticket.used
                }));

                setLoadedTickets(ticketList);
                setIsLoading(false);
            });
    };

    return (
        <>
            <p>
                Sobald auf den Einlöse-Button geklickt wird, startet der Prozess und es wird eine Order erstellt. Dies dauert einen Moment (etwa 1-2 Minuten).
                Die Seite muss dann noch einmal neu geladen werden, um die Änderungen anzuzeigen.
            </p>
            <Form>
                <br />
                <Row>
                    <Form.Group as={Col}>
                        <Label>Kunde</Label>
                        <Form.Control type="text"
                                      value={props.email}
                                      readOnly={true}/>
                    </Form.Group>
                </Row>
                <br />
                <Row>
                    <Form.Group as={Col}>
                        <Label>Zugangscode</Label>
                        <AsyncTypeahead
                            filterBy={filterBy}
                            id={"ticket-search-typeahead"}
                            isLoading={isLoading}
                            labelKey={"code"}
                            minLength={5}
                            onSearch={handleSearch}
                            options={loadedTickets}
                            placeholder={"Bitte gib einen Zugangscode an..."}
                            renderMenuItemChildren={(ticket, props) => (
                                <>
                                    {ticket.used === true &&
                                        <div style={{cursor: "not-allowed"}}>
                                            <span style={{fontWeight: "bold", color: "grey"}}>{ticket.code} <span style={{color: "red"}}>- Bereits eingelöst</span></span>
                                        </div>
                                    }
                                    {ticket.used === false &&
                                        <div onClick={() => setTicket({code: ticket.code, pin: ticket.pin})}>
                                            <span style={{fontWeight: "bold"}}>{ticket.code}</span>
                                        </div>
                                    }
                                </>

                            )}
                        />
                    </Form.Group>
                </Row>
                <Row>
                    <Form.Group as={Col}>
                        <Form.Control type="text"
                                      value={ticket != null ? ticket.code : null}
                                      readOnly={true}
                                      disabled={true}
                                      placeholder="Zugangscode"/>
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Control type="text"
                                      value={ticket != null ? ticket.pin : null}
                                      readOnly={true}
                                      disabled={true}
                                      placeholder="PIN"/>
                    </Form.Group>
                </Row>
            </Form>
            <p style={{color: "red", textAlign: "center"}}>
                {message}
            </p>
            <p style={{float: "right"}}>
                <PromiseButton className="form-btn-ci-red" style={{marginRight: "10px"}} onClick={props.onHide} text="Abbrechen"/>
                <PromiseButton text="Einlösen" variant="secondary" onClick={() => {
                    RedeemTicket(ticket, props.accountId).then(r => {
                        if (!r.error) {
                            props.onHide();
                        } else {
                            setMessage(r.reason);
                        }
                    });
                }}/>
            </p>
        </>
    );
}