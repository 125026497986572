
//API METHODS
import {GetFilteredSpineResources} from "../clients/SpineClient";


export async function GetFilteredNotRenewedSubscriptions(filter){
    return await GetFilteredSpineResources('api/subscriptions/search/all-subscriptions-for-retrieval', filter);
}

export async function GetFilteredExpiringSubscriptions(filter) {
    return await GetFilteredSpineResources('api/subscriptions/search/all-subscriptions-by-expiration', filter);
}




//---------- OPTIONAL -------------
/*
export async function PatchExample(example, newValue) {
    return await PatchResource('api/examples/' + example.id + '/patch', {attribute: newValue});
}
 */