import {useState} from "react";
import Row from 'react-bootstrap/Row';
import {Button, Col, Form} from "react-bootstrap";
import {ChangeEmail} from "../../../resources/accounts/AccountService";

export function ChangeEmailDialog(props) {

    let [email, setEmail] = useState("");
    let [emailConfirmation, setEmailConfirmation] = useState("");
    let [error, setError] = useState("");

    return(
        <>
            <p>Gib eine neue E-Mail-Adresse für {props.account.email} an:</p>
            <Form>
                <Row>
                    <Form.Group as={Col}>
                        <Form.Control type="text"
                                      value={email}
                                      onChange={(e) => {
                                          setEmail(e.target.value);
                                          setError("");
                                      }}
                                      placeholder="Neue E-Mail-Adresse"/>
                    </Form.Group>
                </Row>
                <Row>
                    <Form.Group as={Col}>
                        <Form.Control type="text"
                                      value={emailConfirmation}
                                      onChange={(e) => {
                                          setEmailConfirmation(e.target.value);
                                          setError("");
                                      }}
                                      placeholder="Bestätigung der neuen E-Mail-Adresse"/>
                    </Form.Group>
                </Row>
            </Form>
            <p style={{color: "red", textAlign: "center"}}>
                {error}
            </p>
            <p style={{float: "right"}}>
                <Button variant="danger" style={{marginRight: "10px"}} onClick={props.onHide}>Abbrechen</Button>
                <Button variant="secondary" onClick={() => {
                    if(email === emailConfirmation) {
                        saveAccount(props.account, email).then(r => {
                            if(r.error) {
                                props.parentProps.addToast("Beim Ändern der E-Mail-Adresse ist ein Fehler aufgetreten: " + r.message, {
                                    autoDismiss: true,
                                    appearance: "error"
                                });
                            } else {
                                props.parentProps.addToast("Die E-Mail-Adresse wurde erfolgreich geändert.", {
                                    autoDismiss: true,
                                    appearance: "success"
                                });
                            }
                        });
                        props.onHide();
                    } else {
                        setError("Die E-Mail-Adressen stimmen nicht überein.")
                    }
                } }>Speichern</Button>
            </p>
        </>
    );
}

async function saveAccount(account, email) {
    account.email = email;
    return await ChangeEmail(account, email);
}