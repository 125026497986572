import {Badge, Button, Col, Form, FormControl, InputGroup, Table} from "react-bootstrap";
import Row from 'react-bootstrap/Row';
import React, {Component} from "react";
import FormSelect from "react-bootstrap/FormSelect";
import {withToast} from "../../util/ToastService";
import {Link} from "react-router-dom";
import {AiOutlineEye, BsPencil, FaSitemap, FiTrash2,} from "react-icons/all";
import GlobalConstants from "../../config/GlobalConstants";
import {CreateCluster, GetCluster, UpdateCluster} from "./ClusterService";
import {DetailsSection, RequiredFieldsAreValid} from "../../generators/DetailsGenerator";
import ClusterMetaData from "./ClusterMetaData";
import {Label} from "reactstrap";
import {GetAsset, GetFilteredAssets} from "../assets/AssetService";
import {generateAssetDownloadUrl, openExternalTargetInNewTab} from "../../util/ResourceService";
import {GetFilteredApps} from "../apps/AppService";
import ShopLayoutMetaData, {ShopLayoutItemTypeBadges} from "../shopLayout/ShopLayoutMetaData";
import {InfoModal} from "../../generators/ModalGenerator";
import {EditImageDialog} from "../shopLayout/EditImageDialog";
import {TablePagination} from "../../generators/TableGenerator";
import {PromiseButton} from "../../global/SpinningTiger";
import {ErrorHandler} from "../../util/ErrorHandler";

class ClusterDetails extends Component {

    //------------
    //Constructor
    //------------

    constructor(props) {
        super(props);

        this.state = {
            error: null,
            flowState: props.location.state,
            id: this.props.match.params.id,
            editMode: {
                active: false,
                expertMode: false,
                autoSave: true,
            },
            asset: {},
            apps: [],
            cluster: ClusterMetaData.NEW_CLUSTER,
            originalCluster: ClusterMetaData.NEW_CLUSTER,
            assets: [],
            assetPage: 1,
            lastAssetPage: 1,
            layoutItems: [],
            selectedItem: {
                id: null,
                shopLayoutImages: [],
            },
            selectedImage: {},
            sections: {
                general: true,
                appearance: true,
                layoutItems: true,
            },
            showEditImageDialog: false,
            showPickAssetDialog: false,
            validationErrors: []
        }
    }

    //---------
    //Mounting
    //---------

    async componentDidMount() {
        //Set the title
        if(this.state.id != null) {
            document.title = "Cluster " + this.state.id + " :: Tiger UI";
        } else {
            document.title = "New cluster :: Tiger UI";
        }
        await this.loadApps();
        await this.loadCluster();
        await this.loadAsset();
    }

    //--------
    //Loading
    //--------

    async loadCluster() {
        let loadedCluster = {};

        if (this.state.id != null && this.state.id !== "add") {

            loadedCluster = await GetCluster(this.state.id);

            if (!loadedCluster.error) {
                const cluster = loadedCluster.result;

                let layoutItems = [];
                for (let i = 0; i < Object.keys(cluster.layoutItems).length; i++) {
                    layoutItems.push(cluster.layoutItems[Object.keys(cluster.layoutItems)[i]]);
                }

                this.setState({
                    id: this.state.id,
                    editMode: this.state.editMode,
                    cluster: cluster,
                    layoutItems: layoutItems,
                    originalCluster: cluster,
                });
            } else {
                this.setState(prevState => ({...prevState, error: loadedCluster}));
            }
        } else {
            this.setState((prevState) => ({
                ...prevState,
                id: null,
                cluster: ClusterMetaData.NEW_CLUSTER,
                editMode: {
                    active: true,
                    expertMode: false,
                    autoSave: false,
                }
            }));
        }
    }

    async loadAsset() {
        if (this.state.cluster.assetId != null) {
            let loadedAsset = await GetAsset(this.state.cluster.assetId);

            if (!loadedAsset.error) {
                this.setState(prevState => ({...prevState, asset: loadedAsset.result}));
            } else {
                this.setState(prevState => ({...prevState, error: loadedAsset}));
            }
        }
    }

    async loadAssets(page) {
        let loadedAssets = await GetFilteredAssets({page: page});

        if (!loadedAssets.error) {
            this.setState(prevState => ({
                ...prevState,
                assets: loadedAssets.result,
                assetPage: page,
                lastAssetPage: Math.ceil(loadedAssets.length / 20)
            }));
        } else {
            this.setState(prevState => ({...prevState, error: loadedAssets}));
        }
    }

    async loadApps() {
        let loadedApps = await GetFilteredApps({});
        if (!loadedApps.error) {
            this.setState(prevState => ({...prevState, apps: loadedApps.result}));
        } else {
            this.setState(prevState => ({...prevState, error: loadedApps}));
        }
    }

    //----------
    //Rendering
    //----------

    render() {

        const labelStyle = {
            fontSize: "20px",
            backgroundColor: "#C8C8C8",
            borderStyle: "solid",
            borderColor: "#cdd7e0",
            borderWidth: "1px",
            borderRadius: "5px",
            paddingLeft: "10px",
            paddingRight: "10px",
            width: "100%"
        };

        return (
            <>
                <div className="details-title">
                    {this.state.cluster.id != null ? "Details of Cluster " + this.state.cluster.id : "Create a new Cluster"}
                </div>
                <div className="details-button-box" style={{height: "70px"}}>
                    <Link to={{pathname: "/" + GlobalConstants.APP_PATH + "clusters", state: this.state.flowState}}>
                        <button className="form-btn-ci-light-blue" type="button">Back</button>
                    </Link>
                    {this.state.cluster.id != null &&
                    <>
                        <button className={this.state.editMode.active ? "form-btn-ci-red" : "form-btn-ci-blue"}
                                type="button" onClick={() => this.setState({
                            editMode: {
                                active: !this.state.editMode.active,
                                expertMode: this.state.editMode.expertMode
                            },
                            cluster: this.state.originalCluster
                        })}>{this.state.editMode.active ? "Cancel" : "Edit"}</button>
                        <button className="form-btn-ci-light-blue" type="button"
                                onClick={() => this.setState(prevState => ({
                                    ...prevState,
                                    editMode: {...prevState.editMode, active: true},
                                    id: null,
                                    cluster: {...prevState.cluster, id: null}
                                }))}>Copy
                        </button>
                    </>
                    }
                    {this.state.editMode.active &&
                        <PromiseButton text="Save" onClick={() => this.saveOrUpdateCluster()} />
                    }
                    <Button style={{float: "right", marginTop: "5px", marginRight: "10px"}} variant={this.state.editMode.autoSave ? "secondary": "outline-secondary"}
                            onClick={() => this.setState(prevState => ({
                                ...prevState,
                                editMode: {
                                    ...prevState.editMode,
                                    autoSave: !this.state.editMode.autoSave
                                }
                            }))}>Auto Save</Button>
                </div>

                <div className="details-box-title">Resource Details</div>
                <div className="details-box">
                    {/* GENERAL DATA */}
                    <DetailsSection
                        nameInState="cluster"
                        fields={ClusterMetaData.DETAILS_GENERAL}
                        state={this.state}
                        onSetState={(s) => this.setState(s)}
                        onUpdateResource={() => this.saveOrUpdateCluster()}
                        sectionId="general"
                    />

                    {/* Appearance */}
                    <DetailsSection
                        nameInState="cluster"
                        fields={ClusterMetaData.DETAILS_APPEARANCE}
                        state={this.state}
                        onSetState={(s) => this.setState(s)}
                        sectionId="appearance"
                        onUpdateResource={() => this.saveOrUpdateCluster()}
                        label={<span><AiOutlineEye/>&#xA0;Appearance</span>}
                    />

                    {this.state.sections.appearance &&
                    <>
                        <Form>
                            <Row>
                                <Form.Group as={Col}>
                                    <Label style={labelStyle}>Asset ID</Label>
                                    <InputGroup>
                                        <FormControl type="text" value={this.state.cluster.assetId}
                                                     readOnly={!this.state.editMode.active}
                                                     onChange={(e) => this.setState(prevState => ({
                                                         ...prevState,
                                                         cluster: {...prevState.cluster, assetId: e.target.value}
                                                     }))}/>
                                        {this.state.editMode.active &&
                                            <Button variant="outline-secondary"
                                                    onClick={() => this.loadAssets(1).then(r => this.setState(prevState => ({
                                                        ...prevState,
                                                        showPickAssetDialog: true
                                                    })))}>Pick</Button>
                                        }
                                    </InputGroup>
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <Label style={labelStyle}>Preview</Label>
                                    {this.state.asset.id != null ?
                                        <img src={generateAssetDownloadUrl(this.state.asset)}
                                             alt={"Preview of asset " + this.state.asset.id} height={300}
                                             style={{cursor: "pointer"}}
                                             onClick={() => openExternalTargetInNewTab(generateAssetDownloadUrl(this.state.asset))}/>
                                        :
                                        <p style={{fontSize: "20px", textAlign: "center"}}>No preview available or the
                                            asset is not loaded yet</p>
                                    }

                                </Form.Group>
                            </Row>
                        </Form>
                    </>
                    }

                    {/* Layout Items */}
                    <DetailsSection
                        nameInState="cluster"
                        fields={[]}
                        state={this.state}
                        onSetState={(s) => this.setState(s)}
                        sectionId="layoutItems"
                        label={<span><FaSitemap/>&#xA0;Layout Items</span>}
                    />

                    {this.state.sections.layoutItems &&
                    <>
                        <>
                            {this.state.apps.map(app => (
                                <button
                                    className={(this.state.editMode.active && this.state.cluster.layoutItems[String(app.id)] == null) ? "form-btn-ci-blue" : "form-btn-ci-off"}
                                    disabled={!this.state.editMode.active || this.state.cluster.layoutItems[String(app.id)] != null}
                                    onClick={() => this.createNewItem(app)}>Create {app.name} item</button>
                            ))}
                        </>
                        <Table style={{marginTop: "10px"}} responsive bordered hover striped>
                            <thead>
                            <tr>
                                <th>ID</th>
                                <th>App ID</th>
                                <th>App Name</th>
                                <th>Type</th>
                                {this.state.editMode.active &&
                                <th>Remove</th>
                                }
                            </tr>
                            </thead>
                            <tbody>
                            {this.state.layoutItems.map(item => (
                                <tr role="row" key={item.id} style={{cursor: "pointer"}}
                                    onClick={() => {
                                        this.setState(prevState => ({...prevState, selectedItem: item}));
                                    }}>
                                    <td>{item.id}</td>
                                    <td><Link to={"/" + GlobalConstants.APP_PATH + "apps/" + item.appId}
                                              style={{fontStyle: "italic", color: "#333"}}>{item.appId}</Link></td>
                                    <td>{this.getAppName(item.appId)}</td>
                                    <td><Badge
                                            bg={ShopLayoutItemTypeBadges(item)}>{item.type === "AUDIO_BOOKS" ? "AUDIOBOOKS" : item.type}</Badge>
                                    </td>
                                    {this.state.editMode.active &&
                                    <th>
                                        <button className="form-btn-ci-red" onClick={() => this.removeItem(item)}>
                                            <FiTrash2/></button>
                                    </th>
                                    }
                                </tr>
                            ))}
                            </tbody>
                        </Table>
                    </>
                    }

                    {this.state.selectedItem.appId != null &&
                    <>
                        <hr/>
                        <h4>Selected Item:
                            <span style={{float: "right"}}>
                                <button type="button" className="form-btn-ci-light-blue"
                                        onClick={() => this.setState(prevState => ({
                                            ...prevState,
                                            selectedItem: {id: null, shopLayoutImages: []}
                                        }))}>Close</button>
                            </span>
                        </h4>
                        <Form style={{marginTop: "20px"}}>
                            <Row>
                                <Form.Group as={Col}>
                                    <Label style={labelStyle}>ID</Label>
                                    <FormControl type="text" readOnly={true} value={this.state.selectedItem.id}/>
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <Label style={labelStyle}>App</Label>
                                    <FormSelect readOnly={!this.state.editMode.active}
                                                 disabled={!this.state.editMode.active}
                                                 value={this.state.selectedItem.appId}
                                                 onChange={(e) => this.updateSelectedItem('appId', e.target.value)}>
                                        {this.state.apps.map(app => (
                                            <option value={app.id}>{app.name}</option>
                                        ))}
                                    </FormSelect>
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <Label style={labelStyle}>Type</Label>
                                    <FormSelect readOnly={!this.state.editMode.active}
                                                 disabled={!this.state.editMode.active}
                                                 value={this.state.selectedItem.type}
                                                 onChange={(e) => this.updateSelectedItem('type', e.target.value)}>
                                        {ShopLayoutMetaData.SHOPLAYOUT_TYPES.map(t => (
                                            <option>{t}</option>
                                        ))}
                                    </FormSelect>
                                </Form.Group>
                            </Row>
                            <Row>
                                <Form.Group as={Col}>
                                    <Label style={labelStyle}>Images</Label>
                                    <button type="button"
                                            className={this.state.editMode.active ? "form-btn-ci-blue" : "form-btn-ci-off"}
                                            disabled={!this.state.editMode.active}
                                            onClick={() => this.setState(prevState => ({
                                                ...prevState,
                                                selectedImage: {
                                                    ordinal: null,
                                                    productIdentifier: [],
                                                    query: null,
                                                },
                                                showEditImageDialog: true,
                                            }))}>Add Image
                                    </button>
                                    <Table style={{marginTop: "20px"}} striped bordered hover responsive>
                                        <thead>
                                        <tr>
                                            <th>Ordinal</th>
                                            <th>Title</th>
                                            <th>Preview</th>
                                            {this.state.editMode.active &&
                                            <th>Maintain</th>
                                            }
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {this.state.selectedItem.shopLayoutImages.map(image => (
                                            <tr role="row" key={image.ordinal}>
                                                <td>{image.ordinal}</td>
                                                <td>{image.title}</td>
                                                <td>
                                                    <img src={image.hdImage} alt={"Preview of image " + image.title}
                                                         height={150}
                                                         onClick={() => openExternalTargetInNewTab(image.hdImage)}/>
                                                </td>
                                                {this.state.editMode.active &&
                                                <td>
                                                    <button className="form-btn-ci-blue" type="button" onClick={() => {
                                                        this.setState(prevState => ({
                                                            ...prevState,
                                                            selectedImage: image,
                                                            showEditImageDialog: true
                                                        }))
                                                    }}><BsPencil/></button>
                                                    <button className="form-btn-ci-red" type="button"
                                                            onClick={() => this.removeImageFromBanner(image)}>
                                                        <FiTrash2/></button>
                                                </td>
                                                }
                                            </tr>
                                        ))}
                                        </tbody>
                                    </Table>
                                </Form.Group>
                            </Row>
                        </Form>
                    </>
                    }

                </div>
                <div className="details-button-box" style={{height: "70px"}}>
                    <Link to={{pathname: "/" + GlobalConstants.APP_PATH + "clusters", state: this.state.flowState}}>
                        <button className="form-btn-ci-light-blue" type="button">Back</button>
                    </Link>
                    {this.state.editMode.active &&
                        <PromiseButton text="Save" onClick={() => this.saveOrUpdateCluster()} />
                    }
                </div>

                {/* DIALOGS */}
                <InfoModal show={this.state.showEditImageDialog}
                           title={"Details of image '" + this.state.selectedImage.title + "'"}
                           body={<EditImageDialog image={this.state.selectedImage}
                                                  createNew={this.state.selectedImage.ordinal == null}
                                                  state={this.state}
                                                  onSetState={(s) => this.setState(s)}/>}/>

                <InfoModal show={this.state.showPickAssetDialog}
                           title={"Pick an asset"}
                           onHide={() => this.setState(prevState => ({...prevState, showPickAssetDialog: false}))}
                           body={this.pickAssetDialog()}/>
                <ErrorHandler error={this.state.error}
                              onHide={() => this.setState(prevState => ({...prevState, error: null}))} />
            </>
        );
    }

    //------------
    //API Methods
    //------------

    async saveOrUpdateCluster() {
        if (!RequiredFieldsAreValid("cluster", ClusterMetaData.DETAILS_GENERAL, this.state, (s) => this.setState(s))) {
            return;
        }
        let cluster = {};
        if (this.state.cluster.id != null) {
            cluster = await UpdateCluster(this.state.cluster);
        } else {
            cluster = await CreateCluster(this.state.cluster);
        }

        if (!cluster.error) {
            if (this.state.cluster.id == null) {
                this.props.history.push("/"+ GlobalConstants.APP_PATH + "clusters/" + cluster.result.id);
            }
            this.setState((prevState) => ({
                ...prevState,
                cluster: cluster.result,
                editMode: {...prevState.editMode, active: this.state.editMode.autoSave}
            }));

            this.props.addToast("The cluster has been updated successfully.", {
                autoDismiss: true,
                appearance: 'success'
            });
        } else {
            this.setState(prevState => ({...prevState, error: cluster}));
        }
    }

    //--------
    // DIALOGS
    //--------

    pickAssetDialog() {
        return (
            <>
                <Table responsive bordered striped hover>
                    <thead>
                    <tr>
                        <th>ID</th>
                        <th>Name</th>
                        <th>Asset Type</th>
                        <th>Visibility</th>
                        <th>Preview</th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.state.assets.map(asset => (
                        <tr role="row" key={asset.id} style={{cursor: "pointer"}}
                            onClick={() => this.setState(prevState => ({
                                ...prevState,
                                asset: asset,
                                cluster: {...prevState.cluster, assetId: asset.id},
                                showPickAssetDialog: false,
                            }))}>
                            <td><Link to={"/" + GlobalConstants.APP_PATH + "assets/" + asset.id}
                                      style={{fontStyle: "italic", color: "#333"}}>{asset.id}</Link></td>
                            <td>{asset.name}</td>
                            <td>{asset.assetType}</td>
                            <td>{asset.visibility}</td>
                            <td>
                                <img src={generateAssetDownloadUrl(asset)} alt={"Preview of asset " + asset.id}
                                     width={100} height={100}/>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
                <TablePagination page={this.state.assetPage} lastPage={this.state.lastAssetPage}
                                 onLoad={(p) => this.loadAssets(p)}/>
            </>
        );
    }

    //--------
    // HELPERS
    //--------

    removeImageFromBanner(image) {
        //Update the image list of the selected item
        let images = this.state.selectedItem.shopLayoutImages.filter(img => img.ordinal !== image.ordinal);

        let cluster = this.state.cluster;
        cluster["layoutItems"][String(this.state.selectedItem.appId)]["shopLayoutImages"] = images;
        this.setState(prevState => ({
            ...prevState,
            selectedItem: {
                ...prevState.selectedItem,
                shopLayoutImages: images,
            },
            cluster: cluster,
        }));
    }

    getAppName(item) {
        if (this.state.apps.length > 0) {
            return this.state.apps.filter(app => String(app.id) === String(item))[0].name;
        } else {
            return item;
        }
    }

    updateSelectedItem(field, value) {
        //Update the selected item
        let item = this.state.selectedItem;
        item[field] = value;

        //Update the item in the cluster
        let cluster = this.state.cluster;
        cluster["layoutItems"][String(item.appId)] = item;

        this.setState(prevState => ({...prevState, cluster: cluster, selectedItem: item}));
    }

    createNewItem(app) {
        let item = {
            id: null,
            appId: app.id,
            shopLayoutImages: [],
            type: "AUDIO_BOOKS"
        };

        let cluster = this.state.cluster;
        cluster.layoutItems[String(app.id)] = item;

        let layoutItems = this.state.layoutItems;
        layoutItems.push(item);

        this.setState(prevState => ({...prevState, cluster: cluster, selectedItem: item, layoutItems: layoutItems}));
    }

    removeItem(item) {
        let cluster = this.state.cluster;
        let layoutItems = this.state.layoutItems.filter(i => i.appId !== item.appId);

        cluster.layoutItems = {};
        for (let i = 0; i < layoutItems.length; i++) {
            cluster.layoutItems[String(layoutItems[i].appId)] = layoutItems[i];
        }

        this.setState(prevState => ({...prevState, cluster: cluster, layoutItems: layoutItems}));
    }

}


export default withToast(ClusterDetails);