import {
    CreateSpineResource,
    DeleteSpineResource,
    GetFilteredSpineResources,
    GetSpineResource,
    UpdateSpineResource
} from "../../clients/SpineClient";

//API METHODS
export async function CreateShopLayout(shopLayout) {
    return await CreateSpineResource('api/shopLayouts', shopLayout);
}

export async function GetFilteredShopLayouts(filter){
    return await GetFilteredSpineResources('api/shopLayouts', filter);
}

export async function GetShopLayout(id) {
    return await GetSpineResource('api/shopLayouts/' + id);
}

export async function UpdateShopLayout(shopLayout) {
    return await UpdateSpineResource('api/shopLayouts/' + shopLayout.id, shopLayout);
}

export async function DeleteShopLayout(shopLayout) {
    return await DeleteSpineResource('api/shopLayouts/' + shopLayout.id);
}

//---------- OPTIONAL -------------
/*
export async function PatchShopLayout(shopLayout, newValue) {
    return await PatchResource('api/shopLayouts/' + shopLayout.id + '/patch', {attribute: newValue});
}
 */