import React, {Component} from "react";
import '../../../App.scss'
import {withToast} from "../../util/ToastService";
import {
    FiSettings,
    GrObjectGroup
} from "react-icons/all";
import {ActionModal} from "../../generators/ModalGenerator";
import {ResourcesTable, TableHeader, TableSettingsDialog} from "../../generators/TableGenerator";
import "react-datepicker/dist/react-datepicker.css";
import RestoreMetaData from "./RestoreMetaData";
import {GetFilteredRestores} from "./RestoreService";
import {Button, ButtonGroup, Dropdown} from "react-bootstrap";
import {SpinningTiger} from "../../global/SpinningTiger";
import {ErrorHandler} from "../../util/ErrorHandler";

class Restores extends Component {

    //------------
    //Constructor
    //------------

    constructor(props) {
        super(props);

        this.state = {
            error: null,
            loading: false,
            restores: [],
            filter: props.location.state != null ? props.location.state.filter :
                {page: 1, accountId: "", state: "", dataHash: "", per_page: 20, sortBy: "id", sortDirection: "ASC"},
            lastPage: props.location.state != null ? props.location.state.lastPage : 0,
            columns: props.location.state != null ? props.location.state.columns : RestoreMetaData.COLUMNS,
            customColumnData: [],
            showDialog: {
                tableSettings: false,
                deletionModal: false,
            },
        };

        this.resetFilter = this.resetFilter.bind(this);
        this.updateFilter = this.updateFilter.bind(this);
        this.showOrHideDialog = this.showOrHideDialog.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.loadRestoreList = this.loadRestoreList.bind(this);
    }

    //---------
    //Mounting
    //---------

    async componentDidMount() {
        //Set the title
        document.title = "Restores :: Tiger UI";

        await this.loadRestoreList(this.state.filter);
    }

    //--------
    //Loading
    //--------

    async loadRestoreList(filter) {

        this.setState(prevState => ({...prevState, loading: true}));

        const loadedRestores = await GetFilteredRestores(filter);

        if (loadedRestores.error === true) {
            this.setState(prevState => ({...prevState, error: loadedRestores}));
        } else {
            loadedRestores.result.forEach(restore => {
                if (this.state.restores.filter(ex => (ex.isChecked === true)).includes(restore)) {
                    restore.isChecked = true;
                }
            });
        }

        this.setState(prevState => ({
            ...prevState,
            loading: false,
            restores: loadedRestores.result,
            lastPage: Math.ceil(loadedRestores.length/this.state.filter.per_page),
            filter: filter
        }));

    }

    async applyFilter(e, filter) {
        e.preventDefault();
        await this.loadRestoreList(filter);
    }

    //----------
    //Rendering
    //----------

    render() {
        return (
            <div style={{marginBottom: 50}}>
                <div className="content-header">
                    <div className="content-header-title">Restores</div>
                    <hr/>
                    <div className="content-header-description"><GrObjectGroup/>&#xA0;List and view details of restores
                    </div>
                </div>

                {/* FILTER */}
                <div className="content-box-centered">
                    <div className="content-box-title">Filter</div>
                    <div className="content-box-body">
                        <form onSubmit={(e) => this.applyFilter(e, this.state.filter)}>
                            <div className="row">
                                <div className="col-md-3">
                                    <label>Account ID</label>
                                    <input type="text" value={this.state.filter.accountId}
                                           onChange={(e) => this.updateFilter('accountId', e.target.value)}/>
                                </div>
                                <div className="col-md-5">
                                    <label>Data Hash</label>
                                    <input type="text" value={this.state.filter.dataHash}
                                           onChange={(e) => this.updateFilter('dataHash', e.target.value)}/>
                                </div>
                                <div className="col-md-3">
                                    <label>State</label>
                                    <br />
                                    <Dropdown as={ButtonGroup} drop="up">
                                        <Button style={{paddingTop: "10px", paddingBottom: "10px", marginBottom: "10px"}}
                                                variant={this.state.filter.state === '' ? "dark" : this.state.filter.state === 'PENDING' ? "secondary" : this.state.filter.state === 'PROCESSING' ? "primary" : this.state.filter.state === 'FAILED' ? "danger": this.state.filter.state === 'ASSIGNING' ? "warning": "success"}>
                                            {this.state.filter.state === "" ? "All" : this.state.filter.state}</Button>
                                        <Dropdown.Toggle style={{marginBottom: "10px"}} split
                                                         variant={this.state.filter.state === '' ? "outline-dark" : this.state.filter.state === 'PENDING' ? "outline-secondary" : this.state.filter.state === 'PROCESSING' ? "outline-primary" : this.state.filter.state === 'FAILED' ? "outline-danger": this.state.filter.state === 'ASSIGNING' ? "outline-warning": "outline-success"}/>
                                        <Dropdown.Menu>
                                            <Dropdown.Item
                                                onClick={() => this.updateFilter('state', "")}>All</Dropdown.Item>
                                            <Dropdown.Item
                                                onClick={() => this.updateFilter('state', "PENDING")}>Pending</Dropdown.Item>
                                            <Dropdown.Item
                                                onClick={() => this.updateFilter('state', "PROCESSING")}>Processing</Dropdown.Item>
                                            <Dropdown.Item
                                                onClick={() => this.updateFilter('state', "FAILED")}>Failed</Dropdown.Item>
                                            <Dropdown.Item
                                                onClick={() => this.updateFilter('state', "ASSIGNING")}>Assigning</Dropdown.Item>
                                            <Dropdown.Item
                                                onClick={() => this.updateFilter('state', "COMPLETED")}>Completed</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </div>
                            <hr/>
                            <div className="row">
                                <div className="col-md-3" style={{marginLeft: "auto"}}>
                                    <button className="form-btn-ci-light" type="button"
                                            onClick={() => this.resetFilter()}>Reset
                                    </button>
                                    <button className="form-btn-ci-blue" type="submit" onClick={(e) => this.applyFilter(e, this.state.filter)}>Apply Filter</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                {/* SHOW THE PRODUCT LIST*/}
                <div className="content-box-centered">
                    <div className="content-box-title">Restores List
                        <div style={{float: "right", fontWeight: "normal", cursor: "pointer"}}
                             onClick={() => this.showOrHideDialog('tableSettings', true)}><FiSettings/></div>
                    </div>
                    <div className="content-box-body">
                        <TableHeader nameInState="restores"
                                     viewOnly={true}
                                     deletionForbidden={true}
                                     sortParams={RestoreMetaData.RESTORE_SORT_PARAMS}
                                     state={this.state}
                                     onSetState={(s) => this.setState(s)}
                                     onLoadPage={() => this.loadRestoreList(this.state.filter)}/>
                        <hr/>
                        {this.state.loading ?
                            <SpinningTiger />
                        :
                            <ResourcesTable
                                state={this.state}
                                columns={this.state.columns}
                                customcolumndata={this.state.customColumnData}
                                dataset={this.state.restores}
                                page={this.state.filter.page}
                                lastPage={this.state.lastPage}
                                onLoadPage={(page) => this.loadRestoreList({...this.state.filter, page: page})}
                                selectable={true}
                                resourcesurl='restores'
                                nameInState='restores'
                                onToggleResource={(allRestores) => this.setState(prevState => ({
                                    ...prevState,
                                    restores: allRestores
                                }))}/>
                        }
                    </div>
                </div>

                {/* TABLE SETTINGS */}
                <TableSettingsDialog show={this.state.showDialog.tableSettings}
                                     columns={this.state.columns}
                                     onSetState={(columns) => this.setState(prevState => ({
                                         ...prevState,
                                         columns: columns
                                     }))}
                                     onHide={() =>this.showOrHideDialog('tableSettings', false)}
                />

                {/* DIALOGS */}
                <ActionModal show={this.state.showDialog.deletionModal}
                             onHide={() => this.showOrHideDialog('deletionModal', false)}
                             onAction={() => this.deleteSelectedRestores()}
                             actionButtonText="Delete"
                             title={"Delete restores"}
                             body={"Are you sure you want to delete the selected restores?"}/>
                <ErrorHandler error={this.state.error}
                              onHide={() => this.setState(prevState => ({...prevState, error: null}))} />
            </div>
        );
    }

    //--------
    //ACTIONS
    //--------

    async deleteSelectedRestores() {
        this.state.restores
            .filter(restore => restore.isChecked === true);

        await this.loadRestoreList(this.state.filter);

        this.showOrHideDialog('deletionModal', false);
    }

    //----------------
    // STATE HANDLING
    //----------------

    updateFilter(id, value) {
        //Get the state and the filter
        let state = this.state;
        let filter = state.filter;

        //Update the attribute
        filter[id] = value;

        //Set the state
        this.setState(prevState => ({...prevState, filter: filter}));
    }

    showOrHideDialog(dialogId, show) {
        //Get the state and the dialog
        let state = this.state;
        let dialogs = state.showDialog;

        //Update the visibility
        dialogs[dialogId] = show;

        //Set the state
        this.setState(prevState => ({...prevState, showDialog: dialogs}));
    }

    async resetFilter(){
        let filter = {page: 1, accountId: "", state: "", dataHash: "", per_page: 20, sortBy: "id", sortDirection: "ASC"};
        this.setState(prevState =>({
            ...prevState,
            filter:filter
        }));
        await this.loadRestoreList(filter);
    }

}

export default withToast(Restores);