import React, {useState} from 'react';
import './App.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import {BrowserRouter, Route, Switch} from "react-router-dom";
import CollectionDetails from "./components/resources/collections/CollectionDetails";
import Collections from "./components/resources/collections/Collections";
import RoutineDetails from "./components/resources/routines/RoutineDetails";
import Routines from "./components/resources/routines/Routines";
import Series from "./components/resources/series/Series";
import useToken from "./components/auth/SpineTokenStorageService";
import Dashboard from "./components/dashboard/Dashboard";
import Authentication from "./components/auth/Authentication";
import AppNavbar from "./components/global/AppNavbar";
import Products from "./components/resources/products/Products";
import ProductDetails from "./components/resources/products/ProductDetails";
import SeriesDetails from "./components/resources/series/SeriesDetails";
import GlobalConstants from "./components/config/GlobalConstants";
import {getAccountRoles} from "./components/auth/Oauth2Service";
import ExampleDetails from "./components/resources/template/ExampleDetails";
import Examples from "./components/resources/template/Examples";
import {TigertippsStatistics} from "./components/tools/statistics/TigertippsStatistics";
import SupportTool from "./components/tools/support/SupportTool";
import Imprints from "./components/resources/imprints/Imprints";
import ImprintDetails from "./components/resources/imprints/ImprintDetails";
import PublishingHouseDetails from "./components/resources/publishingHouse/PublishingHouseDetails";
import PublishingHouses from "./components/resources/publishingHouse/PublishingHouses";
import CategoryDetails from "./components/resources/categories/CategoryDetails";
import Categories from "./components/resources/categories/Categories";
import OrderFailureLogDetails from "./components/resources/orderFailureLog/OrderFailureLogDetails";
import OrderFailureLogs from "./components/resources/orderFailureLog/OrderFailureLogs";
import OrderDetails from "./components/resources/order/OrderDetails";
import Orders from "./components/resources/order/Orders";
import RestoreDetails from "./components/resources/restore/RestoreDetails";
import Restores from "./components/resources/restore/Restores";
import Tags from "./components/resources/tags/Tags";
import TagDetails from "./components/resources/tags/TagDetails";
import AffiliateDetails from "./components/resources/affiliate/AffiliateDetails";
import Affiliates from "./components/resources/affiliate/Affiliates";
import LibraryDetails from "./components/resources/libraries/LibraryDetails";
import Libraries from "./components/resources/libraries/Libraries";
import LibraryPoolDetails from "./components/resources/libraryPools/LibraryPoolDetails";
import LibraryPools from "./components/resources/libraryPools/LibraryPools";
import AppDetails from "./components/resources/apps/AppDetails";
import Apps from "./components/resources/apps/Apps";
import ProductGroupDetails from "./components/resources/productGroups/ProductGroupDetails";
import ProductGroups from "./components/resources/productGroups/ProductGroups";
import CouponGroupDetails from "./components/resources/couponGroups/CouponGroupDetails";
import CouponGroups from "./components/resources/couponGroups/CouponGroups";
import LegacyCouponDetails from "./components/resources/couponGroups/LegacyCouponDetails";
import AssetDetails from "./components/resources/assets/AssetDetails";
import Assets from "./components/resources/assets/Assets";
import AccountDetails from "./components/resources/accounts/AccountDetails";
import Accounts from "./components/resources/accounts/Accounts";
import SubscriptionDetails from "./components/resources/subscriptions/SubscriptionDetails";
import AcquisitionDetails from "./components/resources/acquisitions/AcquisitionDetails";
import ProfileDetails from "./components/resources/profiles/ProfileDetails";
import Profiles from "./components/resources/profiles/Profiles";
import ShopLayoutDetails from "./components/resources/shopLayout/ShopLayoutDetails";
import ShopLayouts from "./components/resources/shopLayout/ShopLayouts";
import ClusterDetails from "./components/resources/clusters/ClusterDetails";
import Clusters from "./components/resources/clusters/Clusters";
import SeasonDetails from "./components/resources/series/SeasonDetails";
import EpisodeDetails from "./components/resources/series/EpisodeDetails";
import Swagger from "./components/admin/Swagger";
import AggregatorDetails from "./components/reporting/aggregators/AggregatorDetails";
import Aggregators from "./components/reporting/aggregators/Aggregators";
import {ReportingService} from "./components/reporting/ReportingService";
import NewsletterDetails from "./components/resources/newsletter/NewsletterDetails";
import Newsletters from "./components/resources/newsletter/Newsletters";
import Recommendations from "./components/resources/recommendation/Recommendations";
import SupportStatistics from "./components/tools/statistics/SupportStatistics";
import Tickets from "./components/purchases/tickets/Tickets";
import PremiumAccessTypes from "./components/purchases/premiumAccessTypes/PremiumAccessTypes";
import Transactions from "./components/purchases/transactions/Transactions";
import MappedBillwerkProductDetails from "./components/purchases/premiumAccessTypes/MappedBillwerkProductDetails";
import PremiumAccessTypeDetails from "./components/purchases/premiumAccessTypes/PremiumAccessTypeDetails";
import TransactionDetails from "./components/purchases/transactions/TransactionDetails";
import DigiticketDetails from "./components/purchases/tickets/DigiticketDetails";
import TigerticketDetails from "./components/purchases/tickets/TigerticketDetails";
import Contracts from "./components/purchases/contracts/Contracts";
import CouponVariantDetails from "./components/purchases/premiumAccessTypes/CouponVariantDetails";
import CouponDetails from "./components/purchases/tickets/CouponDetails";
import {useHistory} from "react-router";
import CardDetails from "./components/resources/cards/CardDetails";
import Cards from "./components/resources/cards/Cards";
import OrderedBundles from "./components/purchases/orderedBundles/OrderedBundles";
import OrderedBundleDetails from "./components/purchases/orderedBundles/OrderedBundleDetails";
import Devices from "./components/resources/devices/Devices";
import DevicesDetails from "./components/resources/devices/DevicesDetails";

export default function App() {

    //Authentication
    const {token, setToken} = useToken();
    const [roles, setRoles] = useState([]);
    const history = useHistory();

    let isAuthenticated = false;

    if (token && token.expires_at && token.expires_at > new Date().getTime()) {
        //User token exists and is valid
        isAuthenticated = true;
    }

    //If the user is not authenticated, render the login form
    if (isAuthenticated) {
        if(roles == null || roles.length === 0) {
            getAccountRoles(token).then(r => {
                setRoles(r);
            });
        }
    }

    return (
        <>
            {/* Routing */}
            <BrowserRouter>
                {/* Navbar */}
                <AppNavbar setToken={setToken} roles={roles} setRoles={setRoles}/>

                <Switch>
                    {!isAuthenticated &&
                        <Authentication setToken={setToken} />
                    }

                    {isAuthenticated &&
                        <>
                        {/* Main */}
                        <Route exact={true} path={"/" + GlobalConstants.APP_PATH} component={Dashboard} />

                        {/* Purchases */}
                        <Route exact={true} path={"/" + GlobalConstants.APP_PATH + "purchases/ordered-bundles"} component={OrderedBundles} history={history}/>
                        <Route exact={true} path={"/" + GlobalConstants.APP_PATH + "purchases/ordered-bundles/:id(-?\\d+)"} component={OrderedBundleDetails} history={history} />
                        <Route exact={true} path={"/" + GlobalConstants.APP_PATH + "purchases/contracts"} component={Contracts} history={history}/>

                        <Route exact={true} path={"/" + GlobalConstants.APP_PATH + "purchases/tickets"} component={Tickets} history={history}/>
                        <Route exact={true} path={"/" + GlobalConstants.APP_PATH + "purchases/tigertickets/:id(-?\\d+)"} component={TigerticketDetails} history={history}/>
                        <Route exact={true} path={"/" + GlobalConstants.APP_PATH + "purchases/digitickets/:id(-?\\d+)"} component={DigiticketDetails} history={history}/>
                        <Route exact={true} path={"/" + GlobalConstants.APP_PATH + "purchases/coupons/:id(-?\\d+)"} component={CouponDetails} history={history} />

                        <Route exact={true} path={"/" + GlobalConstants.APP_PATH + "purchases/transactions"} component={Transactions} history={history} />
                        <Route exact={true} path={"/" + GlobalConstants.APP_PATH + "purchases/transactions/:id(-?\\d+)"} component={TransactionDetails} history={history} />
                        <Route exact={true} path={"/" + GlobalConstants.APP_PATH + "purchases/transactions/add"} component={TransactionDetails} history={history} />

                        <Route exact={true} path={"/" + GlobalConstants.APP_PATH + "purchases/products/:id(-?\\d+)"} component={MappedBillwerkProductDetails} history={history}/>
                        <Route exact={true} path={"/" + GlobalConstants.APP_PATH + "purchases/products/add"} component={MappedBillwerkProductDetails} history={history}/>

                        <Route exact={true} path={"/" + GlobalConstants.APP_PATH + "purchases/couponVariants/:id(-?\\d+)"} component={CouponVariantDetails} history={history} />
                        <Route exact={true} path={"/" + GlobalConstants.APP_PATH + "purchases/couponVariants/add"} component={CouponVariantDetails} history={history} />

                        <Route exact={true} path={"/" + GlobalConstants.APP_PATH + "purchases/premium-access-types"} component={PremiumAccessTypes} history={history} />
                        <Route exact={true} path={"/" + GlobalConstants.APP_PATH + "purchases/premium-access-types/:id(-?\\d+)"} component={(state) => <PremiumAccessTypeDetails {...state} history={history} />}/>
                        <Route exact={true} path={"/" + GlobalConstants.APP_PATH + "purchases/premium-access-types/add"} component={PremiumAccessTypeDetails} history={history}/>

                        {/* Resources */}
                        <Route exact={true} path={"/" + GlobalConstants.APP_PATH + "products/:id(-?\\d+)"} component={ProductDetails} history={history}/>
                        <Route exact={true} path={"/" + GlobalConstants.APP_PATH + "products/add"} component={ProductDetails} history={history}/>
                        <Route exact={true} path={"/" + GlobalConstants.APP_PATH + "products"} component={Products} history={history}/>

                        <Route exact={true} path={"/" + GlobalConstants.APP_PATH + "accounts/:id(-?\\d+)"} component={AccountDetails} history={history} />
                        <Route exact={true} path={"/" + GlobalConstants.APP_PATH + "accounts/add"} component={AccountDetails} history={history} />
                        <Route exact={true} path={"/" + GlobalConstants.APP_PATH + "accounts"} component={Accounts} history={history} />

                        <Route exact={true} path={"/" + GlobalConstants.APP_PATH + "accounts/:accountId/subscriptions/:id(-?\\d+)"} component={SubscriptionDetails} history={history} />
                        <Route exact={true} path={"/" + GlobalConstants.APP_PATH + "accounts/:accountId/subscriptions/add"} component={SubscriptionDetails} history={history} />

                        <Route exact={true} path={"/" + GlobalConstants.APP_PATH + "accounts/:accountId/acquisitions/:id(-?\\d+)"} component={AcquisitionDetails} history={history} />
                        <Route exact={true} path={"/" + GlobalConstants.APP_PATH + "accounts/:accountId/acquisitions/add"} component={AcquisitionDetails} history={history} />

                        <Route exact={true} path={"/" + GlobalConstants.APP_PATH + "accounts/:accountId/profiles/:id(-?\\d+)"} component={ProfileDetails} history={history} />
                        <Route exact={true} path={"/" + GlobalConstants.APP_PATH + "accounts/:accountId/profiles/add"} component={ProfileDetails} history={history} />
                        <Route exact={true} path={"/" + GlobalConstants.APP_PATH + "accounts/:accountId/profiles"} component={Profiles} history={history} />

                        <Route exact={true} path={"/" + GlobalConstants.APP_PATH + "assets/:id(-?\\d+)"} component={AssetDetails} history={history} />
                        <Route exact={true} path={"/" + GlobalConstants.APP_PATH + "assets/add"} component={AssetDetails} history={history} />
                        <Route exact={true} path={"/" + GlobalConstants.APP_PATH + "assets"} component={Assets} history={history} />

                        <Route exact={true} path={"/" + GlobalConstants.APP_PATH + "publishingHouses/:id(-?\\d+)"} component={PublishingHouseDetails} history={history} />
                        <Route exact={true} path={"/" + GlobalConstants.APP_PATH + "publishingHouses/add"} component={PublishingHouseDetails} history={history} />
                        <Route exact={true} path={"/" + GlobalConstants.APP_PATH + "publishingHouses"} component={PublishingHouses} history={history} />

                        <Route exact={true} path={"/" + GlobalConstants.APP_PATH + "imprints/:id(-?\\d+)"} component={ImprintDetails} history={history} />
                        <Route exact={true} path={"/" + GlobalConstants.APP_PATH + "imprints/add"} component={ImprintDetails} history={history} />
                        <Route exact={true} path={"/" + GlobalConstants.APP_PATH + "imprints"} component={Imprints} history={history} />

                        <Route exact={true} path={"/" + GlobalConstants.APP_PATH + "orders/:id(-?\\d+)"} component={OrderDetails} history={history}/>
                        <Route exact={true} path={"/" + GlobalConstants.APP_PATH + "orders"} component={Orders} history={history} />

                        <Route exact={true} path={"/" + GlobalConstants.APP_PATH + "couponGroups/:id(-?\\d+)"} component={CouponGroupDetails} history={history} />
                        <Route exact={true} path={"/" + GlobalConstants.APP_PATH + "couponGroups/add"} component={CouponGroupDetails} history={history} />
                        <Route exact={true} path={"/" + GlobalConstants.APP_PATH + "couponGroups"} component={CouponGroups} history={history} />

                        <Route exact={true} path={"/" + GlobalConstants.APP_PATH + "couponGroups/:groupId/coupons/:id(-?\\d+)"} component={LegacyCouponDetails} history={history} />
                        <Route exact={true} path={"/" + GlobalConstants.APP_PATH + "couponGroups/:groupId/coupons/add"} component={LegacyCouponDetails} history={history} />

                        <Route exact={true} path={"/" + GlobalConstants.APP_PATH + "shopLayouts/:id(-?\\d+)"} component={ShopLayoutDetails} history={history} />
                        <Route exact={true} path={"/" + GlobalConstants.APP_PATH + "shopLayouts/add"} component={ShopLayoutDetails} history={history} />
                        <Route exact={true} path={"/" + GlobalConstants.APP_PATH + "shopLayouts"} component={ShopLayouts} history={history} />

                        <Route exact={true} path={"/" + GlobalConstants.APP_PATH + "categories/:id(-?\\d+)"} component={CategoryDetails} history={history} />
                        <Route exact={true} path={"/" + GlobalConstants.APP_PATH + "categories/add"} component={CategoryDetails} history={history} />
                        <Route exact={true} path={"/" + GlobalConstants.APP_PATH + "categories"} component={Categories} history={history} />

                        <Route exact={true} path={"/" + GlobalConstants.APP_PATH + "productGroups/:id(-?\\d+)"} component={ProductGroupDetails} history={history} />
                        <Route exact={true} path={"/" + GlobalConstants.APP_PATH + "productGroups/add"} component={ProductGroupDetails} history={history} />
                        <Route exact={true} path={"/" + GlobalConstants.APP_PATH + "productGroups"} component={ProductGroups} history={history} />

                        <Route exact={true} path={"/" + GlobalConstants.APP_PATH + "restores/:id(-?\\d+)"} component={RestoreDetails} history={history}/>
                        <Route exact={true} path={"/" + GlobalConstants.APP_PATH + "restores"} component={Restores} history={history} />

                        <Route exact={true} path={"/" + GlobalConstants.APP_PATH + "orderFailureLogs/:id(-?\\d+)"} component={OrderFailureLogDetails} history={history}/>
                        <Route exact={true} path={"/" + GlobalConstants.APP_PATH + "orderFailureLogs"} component={OrderFailureLogs} history={history} />

                        <Route exact={true} path={"/" + GlobalConstants.APP_PATH + "tags/:id(-?\\d+)"} component={TagDetails} history={history} />
                        <Route exact={true} path={"/" + GlobalConstants.APP_PATH + "tags/add"} component={TagDetails} history={history}/>
                        <Route exact={true} path={"/" + GlobalConstants.APP_PATH + "tags"} component={Tags} history={history} />

                        <Route exact={true} path={"/" + GlobalConstants.APP_PATH + "series/:seriesId/seasons/:seasonId/episodes/:id(-?\\d+)"} component={EpisodeDetails} history={history}/>
                        <Route exact={true} path={"/" + GlobalConstants.APP_PATH + "series/:seriesId/seasons/:seasonId/episodes/add"} component={EpisodeDetails} history={history}/>
                        <Route exact={true} path={"/" + GlobalConstants.APP_PATH + "series/:seriesId/seasons/:id(-?\\d+)"} component={SeasonDetails} history={history}/>
                        <Route exact={true} path={"/" + GlobalConstants.APP_PATH + "series/:seriesId/seasons/add"} component={SeasonDetails} history={history}/>
                        <Route exact={true} path={"/" + GlobalConstants.APP_PATH + "series/:id(-?\\d+)"} component={SeriesDetails} history={history}/>
                        <Route exact={true} path={"/" + GlobalConstants.APP_PATH + "series/add"} component={SeriesDetails} history={history}/>
                        <Route exact={true} path={"/" + GlobalConstants.APP_PATH + "series"} component={Series} history={history}/>

                        <Route exact={true} path={"/" + GlobalConstants.APP_PATH + "collections/:id(-?\\d+)"} component={CollectionDetails} history={history}/>
                        <Route exact={true} path={"/" + GlobalConstants.APP_PATH + "collections/add"} component={CollectionDetails} history={history}/>
                        <Route exact={true} path={"/" + GlobalConstants.APP_PATH + "collections"} component={Collections} history={history}/>

                        <Route exact={true} path={"/" + GlobalConstants.APP_PATH + "routines/:id(-?\\d+)"} component={RoutineDetails} history={history}/>
                        <Route exact={true} path={"/" + GlobalConstants.APP_PATH + "routines/add"} component={RoutineDetails} history={history}/>
                        <Route exact={true} path={"/" + GlobalConstants.APP_PATH + "routines"} component={Routines} history={history}/>

                        <Route exact={true} path={"/" + GlobalConstants.APP_PATH + "libraries/:id(-?\\d+)"} component={LibraryDetails} history={history}/>
                        <Route exact={true} path={"/" + GlobalConstants.APP_PATH + "libraries/add"} component={LibraryDetails} history={history}/>
                        <Route exact={true} path={"/" + GlobalConstants.APP_PATH + "libraries"} component={Libraries}  history={history}/>

                        <Route exact={true} path={"/" + GlobalConstants.APP_PATH + "libraryPools/:id(-?\\d+)"} component={LibraryPoolDetails} history={history}/>
                        <Route exact={true} path={"/" + GlobalConstants.APP_PATH + "libraryPools/add"} component={LibraryPoolDetails} history={history}/>
                        <Route exact={true} path={"/" + GlobalConstants.APP_PATH + "libraryPools"} component={LibraryPools} history={history} />

                        <Route exact={true} path={"/" + GlobalConstants.APP_PATH + "affiliates/:id(-?\\d+)"} component={AffiliateDetails} history={history}/>
                        <Route exact={true} path={"/" + GlobalConstants.APP_PATH + "affiliates/add"} component={AffiliateDetails} history={history}/>
                        <Route exact={true} path={"/" + GlobalConstants.APP_PATH + "affiliates"} component={Affiliates}  history={history}/>

                        <Route exact={true} path={"/" + GlobalConstants.APP_PATH + "apps/:id(-?\\d+)"} component={AppDetails} history={history}/>
                        <Route exact={true} path={"/" + GlobalConstants.APP_PATH + "apps/add"} component={AppDetails} history={history}/>
                        <Route exact={true} path={"/" + GlobalConstants.APP_PATH + "apps"} component={Apps}  history={history}/>

                        <Route exact={true} path={"/" + GlobalConstants.APP_PATH + "clusters/:id(-?\\d+)"} component={ClusterDetails} history={history}/>
                        <Route exact={true} path={"/" + GlobalConstants.APP_PATH + "clusters/add"} component={ClusterDetails} history={history}/>
                        <Route exact={true} path={"/" + GlobalConstants.APP_PATH + "clusters"} component={Clusters}  history={history}/>

                        <Route exact={true} path={"/" + GlobalConstants.APP_PATH + "newsletters/:id(-?\\d+)"} component={NewsletterDetails} history={history}/>
                        <Route exact={true} path={"/" + GlobalConstants.APP_PATH + "newsletters/add"} component={NewsletterDetails} history={history}/>
                        <Route exact={true} path={"/" + GlobalConstants.APP_PATH + "newsletters"} component={Newsletters} history={history} />

                        <Route exact={true} path={"/" + GlobalConstants.APP_PATH + "cards/:id(-?\\d+)"} component={CardDetails} history={history}/>
                        <Route exact={true} path={"/" + GlobalConstants.APP_PATH + "cards/add"} component={CardDetails} history={history}/>
                        <Route exact={true} path={"/" + GlobalConstants.APP_PATH + "cards"} component={Cards} history={history} />

                        <Route exact={true} path={"/" + GlobalConstants.APP_PATH + "devices/:id(-?\\d+)"} component={DevicesDetails} history={history}/>
                        <Route exact={true} path={"/" + GlobalConstants.APP_PATH + "devices/add"} component={DevicesDetails} history={history}/>
                        <Route exact={true} path={"/" + GlobalConstants.APP_PATH + "devices"} component={Devices} history={history} />

                        {/* Tools */}
                        <Route exact={true} path={"/" + GlobalConstants.APP_PATH + "mailjet-analysis"} component={TigertippsStatistics} />
                        <Route exact={true} path={"/" + GlobalConstants.APP_PATH + "recommendations"} component={() => <Recommendations token={token}/>}/>

                        {/* Support */}
                        <Route exact={true} path={"/" + GlobalConstants.APP_PATH + "support"} component={SupportTool} />
                        <Route exact={true} path={"/" + GlobalConstants.APP_PATH + "statistics"} component={() => <SupportStatistics token={token} />}/>

                        {/* ADMIN */}
                        <Route exact={true} path={"/" + GlobalConstants.APP_PATH + "swagger"} component={Swagger} />

                        {/* REPORTING */}
                        <Route exact={true} path={"/" + GlobalConstants.APP_PATH + "aggregators/:id(-?\\d+)"} component={AggregatorDetails}  history={history}/>
                        <Route exact={true} path={"/" + GlobalConstants.APP_PATH + "aggregators/add"} component={AggregatorDetails}  history={history}/>
                        <Route exact={true} path={"/" + GlobalConstants.APP_PATH + "aggregators"} component={Aggregators}  history={history}/>
                        <Route exact={true} path={"/" + GlobalConstants.APP_PATH + "reporting"} component={ReportingService} />

                        {/* TEMPLATE */}
                        <Route exact={true} path={"/" + GlobalConstants.APP_PATH + "examples/:id(-?\\d+)"} component={ExampleDetails} history={history}/>
                        <Route exact={true} path={"/" + GlobalConstants.APP_PATH + "examples/add"} component={ExampleDetails} history={history}/>
                        <Route exact={true} path={"/" + GlobalConstants.APP_PATH + "examples"} component={Examples} />

                        </>
                    }

                </Switch>
            </BrowserRouter>
        </>
    );
}