import {Table} from "react-bootstrap";
import {BsGenderAmbiguous, BsGenderFemale, BsGenderMale} from "react-icons/all";
import {transformStringToReadableDate} from "../../../util/ResourceService";
import React, {useState} from "react";
import defaultAvatar from "./../../../../assets/images/loader_bg.png";
import {PromiseButton} from "../../../global/SpinningTiger";
import {Badge} from "reactstrap";
import {GetProfilePreferences} from "../../../resources/profiles/ProfileService";
import {InfoModal} from "../../../generators/ModalGenerator";

export function ProfilesTab(props) {
    const account = props.account ? props.account : {};
    const profiles = (account != null && account._embedded != null && account._embedded.profiles != null) ?
        account._embedded.profiles : [];
    const defaultProfile = profiles.filter(p => p.defaultProfile === true)[0];
    const notDefaultProfiles = profiles.filter(p => p.defaultProfile === false);
    const [showPreferences, setShowPreferences] = useState(false);
    const [preferences, setPreferences] = useState([]);

    const getGender = (profile) => {
        if (profile == null || profile.gender == null) {
            return <Badge color="secondary" pill><BsGenderAmbiguous size={20}/> Nicht angegeben</Badge>;
        }

        const gender = profile.gender;
        if (gender === "MALE") {
            return <Badge color="info" pill><BsGenderMale size={20}/> männlich</Badge>
        } else if (gender === "FEMALE") {
            return <Badge color="danger" pill><BsGenderFemale size={20}/> weiblich</Badge>
        } else {
            return <Badge color="secondary" pill><BsGenderAmbiguous size={20}/> Nicht angegeben</Badge>;
        }
    }

    const getBirthday = (profile) => {
        if (profile == null || profile.birthday == null) {
            return "Nicht angegeben";
        } else {
            return transformStringToReadableDate(profile.birthday);
        }
    }

    const getAgeRange = (profile) => {
        if (profile == null || profile.ageRange == null) {
            return "Nicht angegeben";
        }
        const ageMin = profile.ageRange.min != null ? profile.ageRange.min : 0;
        const ageMax = profile.ageRange.max != null ? profile.ageRange.max : 12;

        return ageMin + " - " + ageMax + " Jahre";
    }

    const getAvatarUrl = (profile) => {
        if (profile == null || profile._links == null || profile._links.avatar == null || profile._links.avatar.href == null) {
            return defaultAvatar;
        } else {
            return profile._links.avatar.href;
        }
    }

    const getClusterUrl = (cluster) => {
        if (cluster == null || cluster._links == null || cluster._links.icon == null || cluster._links.icon.href == null) {
            return defaultAvatar;
        } else {
            return cluster._links.icon.href;
        }
    }

    const loadPreferences = async (profileId) => {
        let preferences = await GetProfilePreferences(profileId);
        if (!preferences.error && preferences.result != null) {
            setPreferences(preferences.result);
            setShowPreferences(true);
        } else {
            console.log("Beim Laden der Helden ist ein Fehler aufgetreten: " + preferences.message);
        }
    }

    return (
        <>
            <div className="support-tool-box">
                <h4 style={{float: "right", marginBottom: "20px", marginRight: "5px"}}>{profiles.length} von {account.maxProfiles} Profilen erstellt</h4>
                <Table bordered striped responsive hover>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Geschlecht</th>
                            <th>Geburtstag</th>
                            <th>Altersspanne</th>
                            <th>Avatar</th>
                            <th>Helden & Welten</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr role="row" style={{fontWeight: "bold", fontSize: "18px", backgroundColor: "#fdbe01"}}>
                            <td colSpan="6">Standardprofil</td>
                        </tr>
                        {defaultProfile &&
                        <tr role="row">
                            <td>{defaultProfile.name}</td>
                            <td>{getGender(defaultProfile)}</td>
                            <td>{getBirthday(defaultProfile)}</td>
                            <td>{getAgeRange(defaultProfile)}</td>
                            <td><img src={getAvatarUrl(defaultProfile)} width={120} alt={"Avatar von Profil " + defaultProfile.id}/></td>
                            <td>
                                <PromiseButton className="form-btn-ci-light" text="Anzeigen" onClick={() => loadPreferences(defaultProfile.id)}/>
                            </td>
                        </tr>
                        }
                        {!defaultProfile &&
                            <tr role="row" style={{fontWeight: "bold", fontSize: "18px", color: "darkred", textAlign: "center"}}>
                                <td colSpan="6">Fehler im Konto: Kein Default-Profil vorhanden, bitte Entwicklern Bescheid geben!</td>
                            </tr>
                        }

                        <tr role="row" style={{fontWeight: "bold", fontSize: "18px", backgroundColor: "#fdbe01"}}>
                            <td colSpan="6">Weitere Profile ({notDefaultProfiles.length} von {account.maxProfiles - 1})</td>
                        </tr>
                        {notDefaultProfiles.map(profile => (
                            <tr role="row" key={profile.id} style={{height: "80px"}}>
                                <td>{profile.name}</td>
                                <td>{getGender(profile)}</td>
                                <td>{getBirthday(profile)}</td>
                                <td>{getAgeRange(profile)}</td>
                                <td><img src={getAvatarUrl(profile)} width={120} alt={"Avatar von Profil " + profile.id}/></td>
                                <td>
                                    <PromiseButton className="form-btn-ci-light" text="Anzeigen" onClick={() => loadPreferences(profile.id)}/>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>

            <InfoModal
                show={showPreferences}
                onHide={() => setShowPreferences(false)}
                title={"Ausgewählte Helden & Welten"}
                body={
                    <Table bordered striped responsive>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Cover</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr role="row" style={{fontWeight: "bold", fontSize: "18px"}}>
                                <td colSpan="2">Helden</td>
                            </tr>
                            {preferences.filter(p => p.type === "HERO").length === 0 &&
                                <tr role="row">
                                    <td colSpan="2" align={"center"}>Keine Helden ausgewählt</td>
                                </tr>
                            }
                            {preferences.filter(p => p.type === "HERO").map(p => (
                                <tr role="row" key={p.id}>
                                    <td>{p.name}</td>
                                    <td><img src={getClusterUrl(p)} width={100} alt={"Cover vom Helden " + p.name}/></td>
                                </tr>
                            ))}
                            <tr role="row" style={{fontWeight: "bold", fontSize: "18px"}}>
                                <td colSpan="2">Welten</td>
                            </tr>
                            {preferences.filter(p => p.type === "TOPIC").length === 0 &&
                            <tr role="row">
                                <td colSpan="2" align={"center"}>Keine Welten ausgewählt</td>
                            </tr>
                            }
                            {preferences.filter(p => p.type === "TOPIC").map(p => (
                                <tr role="row" key={p.id}>
                                    <td>{p.name}</td>
                                    <td><img src={getClusterUrl(p)} width={100} alt={"Cover von der Welt " + p.name}/></td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                }
                />
        </>
    )
}