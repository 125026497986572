const AffiliateMetaData = {

    //Columns
    COLUMNS: [
        //Default & Badges
        {label: "ID", id: 'id', columnType: 'default', show: true},
        {label: "Affiliate", id: 'affiliate', columnType: 'default', show: true},
        {label: "Integration URL", id: 'integrationUrl', columnType: 'default', show: true},
    ],

    //Details
    DETAILS_GENERAL: [
        [{label: 'ID', id: 'id', type: 'text', editable: 'never'}, {label: 'Affiliate', id: 'affiliate', type: 'text', editable: 'new'}, {label: 'Integration URL', id: 'integrationUrl', type: 'text', editable: 'true'}],
        [{label: 'Subscription Message', id: 'subscriptionMessage', type: 'textarea', rows: 5, editable: 'true'}],
    ],

    //Sorting
    AFFILIATE_SORT_PARAMS: [],
};

export default AffiliateMetaData;