import {useState} from "react";
import {Button, Col, Form} from "react-bootstrap";
import Row from 'react-bootstrap/Row';
import {ChangePassword} from "../../../resources/accounts/AccountService";

export function ChangePasswordDialog(props) {

    let [password, setPassword] = useState("");
    let [passwordConfirmation, setPasswordConfirmation] = useState("");
    let [error, setError] = useState("");

    return(
        <>
            <p>Gib ein neues Passwort für {props.account.email} an:</p>
            <Form>
                <Row>
                    <Form.Group as={Col}>
                        <Form.Control type="password"
                                      value={password}
                                      onChange={(e) => {
                                          setPassword(e.target.value);
                                          setError("");
                                      }}
                                      placeholder="Neues Passwort"/>
                    </Form.Group>
                </Row>
                <Row>
                    <Form.Group as={Col}>
                        <Form.Control type="password"
                                      value={passwordConfirmation}
                                      onChange={(e) => {
                                          setPasswordConfirmation(e.target.value);
                                          setError("");
                                      }}
                                      placeholder="Bestätigung des neuen Passworts"/>
                    </Form.Group>
                </Row>
            </Form>
            <p style={{color: "red", textAlign: "center"}}>
                {error}
            </p>
            <p style={{float: "right"}}>
                <Button variant="danger" style={{marginRight: "10px"}} onClick={props.onHide}>Abbrechen</Button>
                <Button variant="secondary" onClick={() => {
                    if(password === passwordConfirmation) {
                        saveAccount(props.account, password).then(r => {
                            if(r.error) {
                                props.parentProps.addToast("Beim Ändern des Passwortes ist ein Fehler aufgetreten: " + r.message, {
                                    appearance: "error",
                                    autoDismiss: true
                                });
                            } else {
                                props.parentProps.addToast("Das Passwort wurde erfolgreich geändert.", {
                                    autoDismiss: true,
                                    appearance: "success"
                                });
                            }
                        });
                        props.onHide();
                    } else {
                        setError("Die Passwörter stimmen nicht überein.")
                    }
                } }>Speichern</Button>
            </p>
        </>
    );
}

async function saveAccount(account, password) {
    return await ChangePassword(account, password);
}