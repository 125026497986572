const TigerticketMetaData = {

    //Columns
    COLUMNS: [
        //Default & Badges
        {label: "Code ID", id: 'id', columnType: 'default', show: true},
        {label: "Code/tigerticket-Nr.", id: 'code', columnType: 'default', show: true},
        {label: "Gekauft von", id: 'boughtByUserEmail', columnType: 'default', show: false},
        {label: "Gekauft am", id: 'boughtAt', columnType: 'default', show: false},
        {label: "Eingelöst von", id: 'redeemedByUserEmail', columnType: 'default', show: true},
        {label: "Eingelöst am", id: 'reserved', columnType: 'default', show: true},
        {label: "Coupon Code ID", id: 'coupon', subId: 'id', columnType: 'sub', show: false},
        {label: "Landing Page ID", id: 'coupon', subId: 'landingPageId', columnType: 'sub', show: false},
        {label: "Periode/Dauer", id: 'coupon', subId: 'period', columnType: 'sub', show: true},
        {label: "Billwerk-Produkt ID", id: 'coupon', subId: 'planVariantId', columnType: 'sub', show: true},
        {label: "Typ", id: 'coupon', subId: 'type', columnType: 'sub', show: false},
        {label: "Coupon Name", id: 'coupon', subId: 'name', columnType: 'sub', show: false},
        {label: "Eingelöst", id: 'used', columnType: 'boolean', show: true},
        {label: "PIN", id: 'secondSecret', columnType: 'customValue', show: true},
    ],

    //Details
    DETAILS_GENERAL: [
        [{label: 'Coupon Code ID', id: 'id', type: 'text', editable: 'never', isExpertField: false}, {label: 'Code', id: 'code', type: 'text', editable: 'never'}, {label: "PIN", id: 'secondSecret', type: 'text', editable: 'never'}, {label: "Eingelöst", id: 'used', type: 'boolean', editable: 'never'}],
        [{label: 'Beschreibung', id: 'description', type: 'text', editable: 'never'}],
    ],
    PURCHASE_DETAILS: [
        [{label: 'Gekauft von', id: 'boughtByUserEmail', type: 'text', editable: 'never'}, {label: 'Gekauft von', id: 'boughtByAccountId', type: 'text', editable: 'never'}, {label: 'Gekauft am', id: 'boughtAt', type: 'text', editable: 'never'}],
        [{label: 'Eingelöst von', id: 'redeemedByUserEmail', type: 'text', editable: 'never'},{label: 'Eingelöst von', id: 'redeemedByAccountId', type: 'text', editable: 'never'}, {label: 'Eingelöst am', id: 'reserved', type: 'text', editable: 'never'}],
    ],

    //Sorting
    TIGERTICKET_SORT_PARAMS: [{id: 'boughtAt', label: 'Kaufdatum'}, {id: 'redeemedAt', label: 'Einlösedatum'}, {id: 'id', label: 'Coupon Code ID'}],

    //Various
    TIGERTICKET_DISTRIBUTION_CAMPAIGNS: [{id: 'OTHER', label: 'Sonstige/Keine Kampagne'},{id: 'CASHBACK', label: 'Cashback-Kampagne'}]
};

export default TigerticketMetaData;