import {Button, Col, Form, FormControl, Table} from "react-bootstrap";
import Row from 'react-bootstrap/Row';
import React, {Component} from "react";
import FormSelect from "react-bootstrap/FormSelect";
import {withToast} from "../../util/ToastService";
import {Link} from "react-router-dom";
import {
    FaBook,
} from "react-icons/all";
import GlobalConstants from "../../config/GlobalConstants";
import {CreateSeason, GetSeason, GetSeries, UpdateSeason} from "./SeriesService";
import {DetailsSection} from "../../generators/DetailsGenerator";
import SeasonMetaData from "./SeasonMetaData";
import {Label} from "reactstrap";
import {PromiseButton} from "../../global/SpinningTiger";
import {ErrorHandler} from "../../util/ErrorHandler";

class SeasonDetails extends Component {

    //------------
    //Constructor
    //------------

    constructor(props) {
        super(props);

        this.state = {
            error: null,
            flowState: props.location.state,
            id: this.props.match.params.id,
            seriesId: this.props.match.params.seriesId,
            editMode: {
                active: false,
                expertMode: false,
                autoSave: true,
            },
            season: {
                episodes: [],
            },
            originalSeason: {},
            series: {},
            episodes: [],
            sections: {
                general: true,
                seriesDetails: true,
                episodes: true,
            }
        }
    }

    //---------
    //Mounting
    //---------

    async componentDidMount() {
        //Set the title
        if(this.state.id != null) {
            document.title = "Season " + this.state.id + " :: Tiger UI";
        } else {
            document.title = "New season :: Tiger UI";
        }
        await this.loadSeries();
        await this.loadSeason();
    }

    //--------
    //Loading
    //--------

    async loadSeason() {
        let loadedSeason = {};

        if (this.state.id != null && this.state.id !== "add") {

            loadedSeason = await GetSeason(this.state.seriesId, this.state.id);

            if (!loadedSeason.error) {
                const season = loadedSeason.result;

                this.setState({
                    id: this.state.id,
                    editMode: this.state.editMode,
                    season: season,
                    originalSeason: season,
                });
            } else {
                this.setState(prevState => ({...prevState, error: loadedSeason}));
            }
        } else {
            this.setState((prevState) => ({
                ...prevState,
                id: null,
                season: {
                    id: null,
                    episodes: [],
                },
                editMode: {
                    active: true,
                    expertMode: false,
                    autoSave: false
                }
            }));
        }
    }

    async loadSeries() {
        let loadedSeries = await GetSeries(this.state.seriesId);
        if (!loadedSeries.error) {
            this.setState(prevState => ({...prevState, series: loadedSeries.result}));
        } else {
            this.setState(prevState => ({...prevState, error: loadedSeries}));
        }
    }

    //----------
    //Rendering
    //----------

    render() {

        const labelStyle = {
            fontSize: "20px",
            backgroundColor: "#C8C8C8",
            borderStyle: "solid",
            borderColor: "#cdd7e0",
            borderWidth: "1px",
            borderRadius: "5px",
            paddingLeft: "10px",
            paddingRight: "10px",
            width: "100%"
        };

        return (
            <>
                <div className="details-title">
                    {this.state.season.id != null ? "Details of Season " + this.state.season.id : "Create a new Season"}
                </div>
                <div className="details-button-box" style={{height: "70px"}}>
                    <Link to={{pathname: "/" + GlobalConstants.APP_PATH + "series/" + this.state.seriesId, state: this.state.flowState}}>
                        <button className="form-btn-ci-light-blue" type="button">Back</button>
                    </Link>
                    {this.state.season.id != null &&
                    <button className={this.state.editMode.active ? "form-btn-ci-red" : "form-btn-ci-blue"}
                            type="button" onClick={() => this.setState({
                        editMode: {
                            active: !this.state.editMode.active,
                            expertMode: this.state.editMode.expertMode
                        },
                        season: this.state.originalSeason
                    })}>{this.state.editMode.active ? "Cancel" : "Edit"}</button>
                    }
                    {this.state.editMode.active &&
                    <PromiseButton text="Save" onClick={() => this.saveOrUpdateSeason()} />
                    }
                    <Button style={{float: "right", marginTop: "5px"}}
                            variant={this.state.editMode.expertMode ? "secondary" : "outline-secondary"}
                            onClick={() => this.setState(prevState => ({
                                ...prevState,
                                editMode: {
                                    ...prevState.editMode,
                                    expertMode: !this.state.editMode.expertMode
                                }
                            }))}>Expert Mode</Button>
                    <Button style={{float: "right", marginTop: "5px", marginRight: "10px"}} variant={this.state.editMode.autoSave ? "secondary": "outline-secondary"}
                            onClick={() => this.setState(prevState => ({
                                ...prevState,
                                editMode: {
                                    ...prevState.editMode,
                                    autoSave: !this.state.editMode.autoSave
                                }
                            }))}>Auto Save</Button>
                </div>

                <div className="details-box-title">Resource Details</div>
                <div className="details-box">
                    {/* GENERAL DATA */}
                    <DetailsSection
                        nameInState="season"
                        fields={SeasonMetaData.DETAILS_GENERAL}
                        state={this.state}
                        onSetState={(s) => this.setState(s)}
                        onUpdateResource={() => this.saveOrUpdateSeason()}
                        sectionId="general"
                    />

                    {/* SERIES */}
                    <DetailsSection
                        nameInState="season"
                        fields={[]}
                        state={this.state}
                        onSetState={(s) => this.setState(s)}
                        sectionId="seriesDetails"
                        label={<span><FaBook/>&#xA0;Series Details</span>}
                    />

                    {this.state.sections.seriesDetails &&
                    <>
                        <Form>
                            <Row>
                                <Form.Group as={Col}>
                                    <Label style={labelStyle}>ID</Label>
                                    <FormControl type="text" value={this.state.series.id} readOnly={true}/>
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <Label style={labelStyle}>Name</Label>
                                    <FormControl type="text" value={this.state.series.name} readOnly={true}/>
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <Label style={labelStyle}>Division Type</Label>
                                    <FormSelect value={this.state.series.divisionType} readOnly={true}
                                                 disabled={true}>
                                        <option>SEASONS</option>
                                        <option>EPISODES</option>
                                    </FormSelect>
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <Label style={labelStyle}>Age Recommended</Label>
                                    <FormControl type="text" value={this.state.series.ageRecommended} readOnly={true}/>
                                </Form.Group>
                            </Row>
                            {this.state.editMode.expertMode &&
                            <Row>
                                <Form.Group as={Col}>
                                    <Label style={labelStyle}>Description</Label>
                                    <FormControl as="textarea" rows="5" value={this.state.series.description}
                                                 readOnly={true}/>
                                </Form.Group>
                            </Row>
                            }
                        </Form>
                    </>
                    }

                    {/* EPISODES */}
                    <DetailsSection
                        nameInState="season"
                        fields={[]}
                        state={this.state}
                        onSetState={(s) => this.setState(s)}
                        sectionId="episodes"
                        label={<span><FaBook/>&#xA0;Episodes</span>}
                    />

                    {this.state.sections.episodes &&
                    <Table bordered hover responsive striped>
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Number</th>
                                <th>Title</th>
                                <th>Sort Order in Series</th>
                                <th>Product ID</th>
                            </tr>
                        </thead>
                        <tbody>
                        {this.state.season.episodes.map(episode => (
                            <tr role="row" key={episode.id}>
                                <td><Link to={"/" + GlobalConstants.APP_PATH + "series/" + this.state.seriesId + "/seasons/" + this.state.id + "/episodes/" + episode.id} style={{fontStyle: "italic", color: "#333"}}>{episode.id}</Link></td>
                                <td>{episode.episodeNumber}</td>
                                <td>{episode.episodeTitle}</td>
                                <td>{episode.seriesIndex}</td>
                                <td><Link to={"/" + GlobalConstants.APP_PATH + "products/" + episode.productId} style={{fontStyle: "italic", color: "#333"}}>{episode.productId}</Link></td>
                            </tr>
                        ))}
                        </tbody>
                    </Table>
                    }

                </div>
                <div className="details-button-box" style={{height: "70px"}}>
                    <Link to={{pathname: "/" + GlobalConstants.APP_PATH + "series/" + this.state.seriesId, state: this.state.flowState}}>
                        <button className="form-btn-ci-light-blue" type="button">Back</button>
                    </Link>
                    {this.state.editMode.active &&
                    <PromiseButton text="Save" onClick={() => this.saveOrUpdateSeason()} />
                    }
                </div>

                <ErrorHandler error={this.state.error}
                              onHide={() => this.setState(prevState => ({...prevState, error: null}))} />
            </>
        );
    }

    //------------
    //API Methods
    //------------

    async saveOrUpdateSeason() {
        let season = {};
        if (this.state.season.id != null) {
            season = await UpdateSeason(this.state.series.id, this.state.season);
        } else {
            season = await CreateSeason(this.state.series.id, this.state.season);
        }

        if (!season.error) {
            this.setState((prevState) => ({
                ...prevState,
                season: season.result,
                editMode: {...prevState.editMode, active: this.state.editMode.autoSave}
            }));

            this.props.addToast("The season has been updated successfully.", {
                autoDismiss: true,
                appearance: 'success'
            });
        } else {
            this.setState(prevState => ({...prevState, error: season}));
        }
    }

}


export default withToast(SeasonDetails);