import {Button} from "react-bootstrap";
import React, {Component} from "react";
import {withToast} from "../../util/ToastService";
import {Link} from "react-router-dom";
import {
    FaMoneyBillWave,
} from "react-icons/all";
import GlobalConstants from "../../config/GlobalConstants";
import {CreateAcquisition, GetAcquisition} from "./AcquisitionService";
import {DetailsSection} from "../../generators/DetailsGenerator";
import AcquisitionMetaData from "./AcquisitionMetaData";
import {PromiseButton} from "../../global/SpinningTiger";
import {ErrorHandler} from "../../util/ErrorHandler";

class AcquisitionDetails extends Component {

    //------------
    //Constructor
    //------------

    constructor(props) {
        super(props);

        this.state = {
            error: null,
            flowState: props.location.state,
            id: this.props.match.params.id,
            accountId: this.props.match.params.accountId,
            editMode: {
                active: false,
                expertMode: false,
                autoSave: true
            },
            acquisition: {},
            originalAcquisition: {},
            sections: {
                general: true,
                acquisitionDetails: true,
            }
        }
    }

    //---------
    //Mounting
    //---------

    async componentDidMount() {
        await this.loadAcquisition();
    }

    //--------
    //Loading
    //--------

    async loadAcquisition() {
        let loadedAcquisition = {};

        if(this.state.id != null && this.state.id !== "add") {

            loadedAcquisition = await GetAcquisition({id: this.state.accountId}, this.state.id);

            if (!loadedAcquisition.error) {
                const acquisition = loadedAcquisition.result;

                this.setState({
                    id: this.state.id,
                    editMode: this.state.editMode,
                    acquisition: acquisition,
                    originalAcquisition: acquisition,
                });
            } else {
                this.setState(prevState => ({...prevState, error: loadedAcquisition}));
            }
        } else {
            this.setState((prevState) => ({...prevState,
                id: null,
                acquisition: {
                    id: null,
                    accountId: this.props.match.params.accountId
                },
                editMode: {
                    active: true,
                    expertMode: false,
                    autoSave: false
                }}));
        }
    }

    //----------
    //Rendering
    //----------

    render() {

        return (
            <>
                <div className="details-title">
                    {this.state.acquisition.id != null ? "Details of Acquisition " + this.state.acquisition.id : "Create a new Acquisition"}
                </div>
                <div className="details-button-box" style={{height: "70px"}}>
                    <Link to={{pathname: "/" + GlobalConstants.APP_PATH +"accounts/" + this.state.accountId, state: this.state.flowState}}>
                        <button className="form-btn-ci-light-blue" type="button">Back</button>
                    </Link>
                    {this.state.editMode.active &&
                        <PromiseButton text="Save" onClick={() => this.saveOrUpdateAcquisition()} />
                    }
                    <Button style={{float: "right", marginTop: "5px"}} variant={this.state.editMode.expertMode ? "secondary": "outline-secondary"}
                            onClick={() => this.setState(prevState => ({
                                ...prevState,
                                editMode: {
                                    ...prevState.editMode,
                                    expertMode: !this.state.editMode.expertMode
                                }
                            }))}>Expert Mode</Button>
                    <Button style={{float: "right", marginTop: "5px", marginRight: "10px"}} variant={this.state.editMode.autoSave ? "secondary": "outline-secondary"}
                            onClick={() => this.setState(prevState => ({
                                ...prevState,
                                editMode: {
                                    ...prevState.editMode,
                                    autoSave: !this.state.editMode.autoSave
                                }
                            }))}>Auto Save</Button>
                </div>

                <div className="details-box-title">Resource Details</div>
                <div className="details-box">
                    {/* GENERAL DATA */}
                    <DetailsSection
                        nameInState="acquisition"
                        fields={AcquisitionMetaData.DETAILS_GENERAL}
                        state={this.state}
                        onSetState={(s) => this.setState(s)}
                        onUpdateResource={() => this.saveOrUpdateAcquisition()}
                        sectionId="general"
                    />

                    {/* SECTION 1 */}
                    <DetailsSection
                        nameInState="acquisition"
                        fields={AcquisitionMetaData.ACQUISITION_DETAILS}
                        state={this.state}
                        onSetState={(s) => this.setState(s)}
                        sectionId="acquisitionDetails"
                        onUpdateResource={() => this.saveOrUpdateAcquisition()}
                        label={<span><FaMoneyBillWave/>&#xA0;Acquisition Details</span>}
                    />

                </div>
                <div className="details-button-box" style={{height: "70px"}}>
                    <Link to={{pathname: "/" + GlobalConstants.APP_PATH +"accounts/" + this.state.accountId, state: this.state.flowState}}>
                        <button className="form-btn-ci-light-blue" type="button">Back</button>
                    </Link>
                    {this.state.editMode.active &&
                        <PromiseButton text="Save" onClick={() => this.saveOrUpdateAcquisition()} />
                    }
                </div>

                <ErrorHandler error={this.state.error}
                              onHide={() => this.setState(prevState => ({...prevState, error: null}))} />
            </>
        );
    }

    //------------
    //API Methods
    //------------

    async saveOrUpdateAcquisition() {
        let acquisition = await CreateAcquisition({id: this.state.accountId}, this.state.acquisition);

        if (!acquisition.error) {
            this.setState((prevState) =>({...prevState, acquisition: acquisition.result, editMode: {...prevState.editMode, active: this.state.editMode.autoSave}}));

            this.props.addToast("The acquisition has been updated successfully.", {
                autoDismiss: true,
                appearance: 'success'
            });
        } else {
            this.setState(prevState => ({...prevState, error: acquisition}));
        }
    }

}


export default withToast(AcquisitionDetails);