import {CreateSpineResource, DeleteSpineResource, GetFilteredSpineResources, GetSpineResource, UpdateSpineResource} from "../../clients/SpineClient";

export async function GetPublishingHouse(id) {
    return await GetSpineResource('api/publishingHouses/' + id);
}

export async function GetFilteredPublishingHouses(filter){
    return await GetFilteredSpineResources('api/publishingHouses', filter);
}

export async function CreatePublishingHouse(publishingHouse) {
    return await CreateSpineResource('api/publishingHouses/', publishingHouse);
}

export async function UpdatePublishingHouse(publishingHouse) {
    return await UpdateSpineResource('api/publishingHouses/' + publishingHouse.id, publishingHouse);
}

export async function DeletePublishingHouse(publishingHouse) {
    return await DeleteSpineResource('api/publishingHouses/' + publishingHouse.id);
}