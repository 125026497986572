import {getToken} from "../auth/SpineTokenStorageService";
import {trackPromise} from "react-promise-tracker";
import GlobalConstants from "../config/GlobalConstants";

export async function GetSpineResource(url) {

    var token = getToken();
    let spineError = false;
    let resourceNotFound = false;
    let gatewayError = false;

    return trackPromise(fetch(GlobalConstants.SPINE_CLIENT_HOST + url, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + token.access_token,
        }
    })
        .then(function(response) {
            if(!response.ok) {
                if(response.status === 404) {
                    resourceNotFound = true;
                    return response;
                } else if (response.status === 502) {
                    gatewayError = true;
                    return response;
                } else {
                    spineError = true;
                    return response.json();
                }
            } else {
                return response.json();
            }
        })
        .then(function (response) {
            if(spineError) {
                return generateErrorMessage(response);
            } else if(resourceNotFound) {
                return {error: true, message: "Resource not found", result: {...response, customInfo: "Resource not found", path: url}}
            } else if (gatewayError) {
                return {error: true, message: "Gateway Error", result: {customInfo: "Gateway error"}}
            } else {
                return {error: false, message: "", result: response}
            }
        })
        .catch(function (error) {
            return {error: true, message: "An unknown error occurred: " + error, result: error}
        }));
}

export async function GetVoidSpineRequest(url) {

    var token = getToken();
    let spineError = false;
    let resourceNotFound = false;
    let gatewayError = false;

    return trackPromise(fetch(GlobalConstants.SPINE_CLIENT_HOST + url, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + token.access_token,
        }
    })
        .then(function(response) {
            if(!response.ok) {
                if(response.status === 404) {
                    resourceNotFound = true;
                    return response;
                } else if (response.status === 502) {
                    gatewayError = true;
                    return response;
                } else {
                    spineError = true;
                    return response;
                }
            }
            return response;
        })
        .then(function (response) {
            if(spineError) {
                return generateErrorMessage(response);
            } else if(resourceNotFound) {
                return {error: true, message: "Resource not found", result: {...response, customInfo: "Resource not found", path: url}}
            } else if (gatewayError) {
                return {error: true, message: "Gateway Error", result: {customInfo: "Gateway error"}}
            } else {
                return {error: false, message: "", result: response}
            }
        })
        .catch(function (error) {
            return {error: true, message: "An unknown error occurred: " + error, result: error}
        }));
}

export async function GetAllSpineResources(url){
    //Security check
    var token = getToken();
    let spineError = false;
    let resourceNotFound = false;
    let gatewayError = false;
    let length = 0;

    return trackPromise(fetch(GlobalConstants.SPINE_CLIENT_HOST + url, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + token.access_token,
        }
    })
        .then(function(response) {
            if(!response.ok) {
                if (response.code === 404) {
                    resourceNotFound = true;
                    return response;
                } else if (response.code === 502) {
                    gatewayError = true;
                    return response;
                } else {
                    spineError = true;
                    return response.json();
                }
            } else {
                length = response.headers.get('Content-Length');
                return response.json();
            }
        })
        .then(function (response) {
            response = ConvertHateosResponse(response);
            if(spineError) {
                return generateErrorMessage(response);
            } else if(resourceNotFound) {
                return {error: true, message: "Resource not found", result: {...response, customInfo: "Resource not found", path: url}}
            } else if (gatewayError) {
                return {error: true, message: "Gateway Error", result: {customInfo: "Gateway error"}}
            } else {
                return {error: false, message: "", result: response, length: length}
            }
        })
        .catch(function (error) {
            return {error: true, message: "An unknown error occurred: " + error, result: error, length: 0}
        }));
}

export async function GetBlob(url, host = GlobalConstants.SPINE_CLIENT_HOST) {
    let token = getToken();

    return fetch(host + url, {
        method: 'GET',
        headers: {
            Accept: "*/*",
            Authorization: 'Bearer ' + token.access_token
        }
    })
        .then(response => {
            return response.blob().then(myBlob => {
                return URL.createObjectURL(myBlob);
            });
        });
}

export async function GetFilteredSpineResources(url, filter, accept = null){
    //Security check
    var token = getToken();
    let spineError = false;
    let resourceNotFound = false;
    let gatewayError = false;
    let length = null;
    if (accept == null) {
        accept = 'application/json'
    }

    return trackPromise(fetch(GlobalConstants.SPINE_CLIENT_HOST + url + createFilterQuery(filter), {
        method: 'GET',
        headers: {
            'Accept': accept,
            'Authorization': 'Bearer ' + token.access_token,
        }
    })
        .then(function(response) {
            if(!response.ok) {
                if (response.status === 404) {
                    resourceNotFound = true;
                    return response;
                } else if (response.status === 502) {
                    gatewayError = true;
                    return response;
                } else {
                    spineError = true;
                    return response.json();
                }
            } else {
                length = response.headers.get('X-Total-Count');
                return response.json();
            }
        })
        .then(function (response) {
            if(!length) {
                if(response["page"] != null) {
                    length = response["page"]["totalElements"];
                } else {
                    //Fallback
                    length = 0;
                }
            }
            response = ConvertHateosResponse(response);
            if(spineError) {
                return generateErrorMessage(response);
            } else if(resourceNotFound) {
                return {error: true, message: "Resource not found", result: {...response, customInfo: "Resource not found", path: url}}
            } else if (gatewayError) {
                return {error: true, message: "Gateway Error", result: {customInfo: "Gateway error"}}
            } else {
                return {error: false, message: "", result: response, length: length}
            }
        })
        .catch(function (error) {
            return {error: true, message: "An unknown error occurred: " + error, result: error, length: 0}
        }));
}

export async function CreateSpineResource(url, resource, dtoIsReturned = true){

    //Security check
    var token = getToken();
    let spineError = false;
    let resourceNotFound = false;
    let gatewayError = false;

    return trackPromise(fetch(GlobalConstants.SPINE_CLIENT_HOST + url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token.access_token,
        },
        body: JSON.stringify(resource),
    })
        .then(function (response){
            if(!response.ok) {
                if (response.status === 404) {
                    resourceNotFound = true;
                    return response;
                } else if (response.status === 502) {
                    gatewayError = true;
                    return response;
                } else {
                    spineError = true;
                    return response.json();
                }
            } else {
                //Result ok
                if(dtoIsReturned) {
                    //In case of an ResponseEntity with a dto
                    return response.json();
                } else {
                    //Void
                    return response;
                }
            }
        })
        .then(function (response){
            if(spineError) {
                return generateErrorMessage(response);
            } else if(resourceNotFound) {
                return {error: true, message: "Resource not found", result: {...response, customInfo: "Resource not found", path: url}}
            } else if (gatewayError) {
                return {error: true, message: "Gateway Error", result: {customInfo: "Gateway error"}}
            } else {
                return {error: false, message: "", result: response}
            }
        })
        .catch(function (error){
            return {error: true, message: "An unknown error occurred: " + error, result: error}
        }));
}

export async function StartAutomaticImport(importer, reportMonth){

    //Security check
    var token = getToken();
    let spineError = false;
    let resourceNotFound = false;
    let gatewayError = false;

    const formData = new FormData();
    formData.append("importer", importer);
    formData.append("reportMonth", reportMonth);

    return trackPromise(fetch(GlobalConstants.SPINE_CLIENT_HOST + "api/reports/auto-import", {
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + token.access_token,
        },
        body: formData
    })
            .then(function (response){
                if(!response.ok) {
                    if (response.status === 404) {
                        resourceNotFound = true;
                        return response;
                    } else if (response.status === 502) {
                        gatewayError = true;
                        return response;
                    } else {
                        spineError = true;
                        return response;
                    }
                } else {
                    return response;
                }
            })
            .then(function (response){
                if(spineError) {
                    return generateErrorMessage({...response, errorCode: response.status, message: response.statusText});
                } else if(resourceNotFound) {
                    return {error: true, message: "Resource not found", result: {...response, customInfo: "Resource not found"}}
                } else if (gatewayError) {
                    return {error: true, message: "Gateway Error", result: {customInfo: "Gateway error"}}
                } else {
                    return {error: false, message: "", result: response}
                }
            })
            .catch(function (error){
                return {error: true, message: "An unknown error occurred: " + error, result: error}
            }));
}

export async function ImportReportingData(reportMonth, report){

    //Security check
    var token = getToken();
    let spineError = false;
    let resourceNotFound = false;
    let gatewayError = false;

    const formData = new FormData();
    formData.append("report", report, report.name);
    formData.append("reportMonth", reportMonth);

    return trackPromise(fetch(GlobalConstants.SPINE_CLIENT_HOST + "api/reports/import", {
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + token.access_token,
        },
        body: formData
    })
        .then(function (response){
            if(!response.ok) {
                if (response.status === 404) {
                    resourceNotFound = true;
                    return response;
                } else if (response.status === 502) {
                    gatewayError = true;
                    return response;
                } else {
                    spineError = true;
                    return response;
                }
            } else {
                return response;
            }
        })
        .then(function (response){
            if(spineError) {
                return generateErrorMessage({...response, errorCode: response.status, message: response.statusText});
            } else if(resourceNotFound) {
                return {error: true, message: "Resource not found", result: {...response, customInfo: "Resource not found"}}
            } else if (gatewayError) {
                return {error: true, message: "Gateway Error", result: {customInfo: "Gateway error"}}
            } else {
                return {error: false, message: "", result: response}
            }
        })
        .catch(function (error){
            return {error: true, message: "An unknown error occurred: " + error, result: error}
        }));
}

export async function CreateSpineFormDataResource(url, resource, dtoIsReturned = true){
    //Security check
    var token = getToken();
    let spineError = false;
    let resourceNotFound = false;
    let gatewayError = false;

    //Create the form data
    const formData = new FormData();
    let keys = Object.keys(resource);
    for(let i = 0; i < keys.length; i++) {
        formData.append(keys[i], resource[keys[i]]);
    }

    return trackPromise(fetch(GlobalConstants.SPINE_CLIENT_HOST + url, {
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + token.access_token,
        },
        body: formData,
    })
        .then(function (response){
            if(!response.ok) {
                if (response.status === 404) {
                    resourceNotFound = true;
                    return response;
                } else if (response.status === 502) {
                    gatewayError = true;
                    return response;
                } else {
                    spineError = true;
                    return response.json();
                }
            } else {
                //Result ok
                if(dtoIsReturned) {
                    //In case of an ResponseEntity with a dto
                    return response.json();
                } else {
                    //Void
                    return response;
                }
            }
        })
        .then(function (response){
            if(spineError) {
                return generateErrorMessage(response);
            } else if(resourceNotFound) {
                return {error: true, message: "Resource not found", result: {...response, customInfo: "Resource not found", path: url}}
            } else if (gatewayError) {
                return {error: true, message: "Gateway Error", result: {customInfo: "Gateway error"}}
            } else {
                return {error: false, message: "", result: response}
            }
        })
        .catch(function (error){
            return {error: true, message: "An unknown error occurred: " + error, result: error}
        }));
}

export async function GetSpineResourcesWithPost(url){
    //Security check
    var token = getToken();
    let spineError = false;
    let resourceNotFound = false;
    let gatewayError = false;
    let length = 0;

    return fetch(GlobalConstants.SPINE_CLIENT_HOST + url, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + token.access_token,
        }
    })
        .then(function(response) {
            if(!response.ok) {
                if (response.status === 404) {
                    resourceNotFound = true;
                    return response;
                } else if (response.status === 502) {
                    gatewayError = true;
                    return response;
                } else {
                    spineError = true;
                    return response.json();
                }
            } else {
                length = response.headers.get('Content-Length');
                return response.json();
            }
        })
        .then(function (response) {
            if(spineError) {
                return generateErrorMessage(response);
            } else if(resourceNotFound) {
                return {error: true, message: "Resource not found", result: {...response, customInfo: "Resource not found", path: url}}
            } else if (gatewayError) {
                return {error: true, message: "Gateway Error", result: {customInfo: "Gateway error"}}
            } else {
                return {error: false, message: "", result: response, length: length}
            }
        })
        .catch(function (error) {
            return {error: true, message: "An unknown error occurred: " + error, result: error, length: 0}
        });
}

export async function UpdateSpineResource(url, resource) {
    //Security check
    var token = getToken();

    let spineError = false;
    let resourceNotFound = false;
    let gatewayError = false;

    return trackPromise(fetch(GlobalConstants.SPINE_CLIENT_HOST + url, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token.access_token,
        },
        body: JSON.stringify(resource),
    })
        .then(function(response) {
            if(!response.ok) {
                if (response.status === 404) {
                    resourceNotFound = true;
                    return response;
                } else if (response.status === 502) {
                    gatewayError = true;
                    return response;
                } else {
                    spineError = true;
                    return response.json();
                }
            } else {
                return response.json();
            }
        })
        .then(function(response) {
            if(spineError) {
                return generateErrorMessage(response);
            } else if(resourceNotFound) {
                return {error: true, message: "Resource not found", result: {...response, customInfo: "Resource not found", path: url}}
            } else if (gatewayError) {
                return {error: true, message: "Gateway Error", result: {customInfo: "Gateway error"}}
            } else {
                return {error: false, message: "", result: response}
            }
        })
        .catch(async function(error) {
            return {error: true, message: "An unknown error occurred: " + error, result: error}
        }));
}

export async function AssociateSpineResources(url, targetResource) {
    //Security check
    var token = getToken();

    let spineError = false;
    let resourceNotFound = false;
    let gatewayError = false;

    return trackPromise(fetch(GlobalConstants.SPINE_CLIENT_HOST + url, {
        method: 'PUT',
        headers: {
            'Content-Type': 'text/uri-list',
            'Authorization': 'Bearer ' + token.access_token,
        },
        body: targetResource,
    })
        .then(function(response) {
            if(!response.ok) {
                if (response.status === 404) {
                    resourceNotFound = true;
                    return response;
                } else if (response.status === 502) {
                    gatewayError = true;
                    return response;
                } else {
                    spineError = true;
                    return response.json();
                }
            } else {
                return response;
            }
        })
        .then(function(response) {
            if(spineError) {
                return generateErrorMessage(response);
            } else if(resourceNotFound) {
                return {error: true, message: "Resource not found", result: {...response, customInfo: "Resource not found", path: url}}
            } else if (gatewayError) {
                return {error: true, message: "Gateway Error", result: {customInfo: "Gateway error"}}
            } else {
                return {error: false, message: "", result: response}
            }
        })
        .catch(async function(error) {
            return {error: true, message: "An unknown error occurred: " + error, result: error}
        }));
}

export async function PatchSpineResourceWithBody(url, patch, dtoIsReturned = true) {
    //Security check
    var token = getToken();
    let spineError = false;
    let gatewayError = false;
    let resourceNotFound = false;

    return trackPromise(fetch(GlobalConstants.SPINE_CLIENT_HOST + url, {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token.access_token,
        },
        body: JSON.stringify(patch),
    })
        .then(function(response) {
            if(!response.ok) {
                if (response.status === 404) {
                    resourceNotFound = true;
                    return response;
                } else if (response.status === 502) {
                    gatewayError = true;
                    return response;
                } else {
                    spineError = true;
                    return response.json();
                }
            } else {
                //Result ok
                if(dtoIsReturned) {
                    //In case of an ResponseEntity with a dto
                    return response.json();
                } else {
                    //Void
                    return response;
                }
            }
        })
        .then(function (response) {
            if(spineError) {
                return generateErrorMessage(response);
            } else if(resourceNotFound) {
                return {error: true, message: "Resource not found", result: {...response, customInfo: "Resource not found", path: url}}
            } else if (gatewayError) {
                return {error: true, message: "Gateway Error", result: {customInfo: "Gateway error"}}
            } else {
                return {error: false, message: "", result: response}
            }
        })
        .catch(function(error) {
            return {error: true, message: "An unknown error occurred: " + error, result: error}
        }))
}

export async function PatchSpineResource(url) {
    //Security check
    var token = getToken();
    let spineError = false;
    let resourceNotFound = false;
    let gatewayError = false;

    return trackPromise(fetch(GlobalConstants.SPINE_CLIENT_HOST + url, {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token.access_token,
        }
    })
        .then(function(response) {
            if(!response.ok) {
               if (response.status === 404) {
                   resourceNotFound = true;
                   return response;
               } else if (response.status === 502) {
                   gatewayError = true;
                   return response;
               } else {
                   spineError = true;
                   return response.json()
               }
            } else {
                return response.json();
            }
        })
        .then(function (response) {
            if(spineError) {
                return generateErrorMessage(response);
            } else if(resourceNotFound) {
                return {error: true, message: "Resource not found", result: {...response, customInfo: "Resource not found", path: url}}
            } else if (gatewayError) {
                return {error: true, message: "Gateway Error", result: {customInfo: "Gateway error"}}
            } else {
                return {error: false, message: "", result: response}
            }
        })
        .catch(function(error) {
            return {error: true, message: "An unknown error occurred: " + error, result: error}
        }))
}

export async function DeleteSpineResource(url, dtoIsReturned = true) {
    //Security check
    var token = getToken();
    let spineError = false;
    let resourceNotFound = false;
    let gatewayError = false;

    return trackPromise(fetch(GlobalConstants.SPINE_CLIENT_HOST + url, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token.access_token,
        }
    })
        .then(function(response) {
            if(!response.ok) {
                if (response.status === 404) {
                    resourceNotFound = true;
                    return response;
                } else if (response.status === 502) {
                    gatewayError = true;
                    return response;
                } else {
                    spineError = true;
                    return response.json();
                }
            } else {
                //Result ok
                if(dtoIsReturned) {
                    //In case of an ResponseEntity with a dto
                    return response.json();
                } else {
                    //Void
                    return response;
                }
            }
        })
        .then(function (response) {
            if(spineError) {
                return generateErrorMessage(response);
            } else if(resourceNotFound) {
                return {error: true, message: "Resource not found", result: {...response, customInfo: "Resource not found", path: url}}
            } else if (gatewayError) {
                return {error: true, message: "Gateway Error", result: {customInfo: "Gateway error"}}
            } else {
                return {error: false, message: "", result: response}
            }
        })
        .catch(function(error) {
            return {error: true, message: "An unknown error occurred: " + error, result: error}
        }))
}

export async function UploadSpineFile(url, resource) {
    //Security check
    var token = getToken();
    let spineError = false;
    let resourceNotFound = false;
    let gatewayError = false;

    return trackPromise(fetch(GlobalConstants.SPINE_CLIENT_HOST + url, {
        method: 'POST',
        headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + token.access_token,
        },
        body: resource,
    })
        .then(function(response) {
            if(!response.ok) {
                if (response.status === 404) {
                    resourceNotFound = true;
                    return response;
                } else if (response.status === 502) {
                    gatewayError = true;
                    return response;
                } else {
                    spineError = true;
                    return response.json();
                }
            }
    })
        .then(function(response) {
            if(spineError) {
                return generateErrorMessage(response);
            } else if(resourceNotFound) {
                return {error: true, message: "Resource not found", result: {...response, customInfo: "Resource not found", path: url}}
            } else if (gatewayError) {
                return {error: true, message: "Gateway Error", result: {customInfo: "Gateway error"}}
            } else {
                return {error: false, message: "", result: response}
            }
        })
        .catch(function(error) {
            return {error: true, message: "An unkown error occurred: " + error, result: error}
        }))
}

//HELPERS

export function ConvertHateosResponse(response) {
    if(response["_embedded"] != null) {
        //Hateos resource -> Get the result list
        //Get the name of the resource list
        let keys = Object.keys(response["_embedded"]);

        if (keys.length > 0) {
            return response["_embedded"][keys[0]];
        } else {
            return [];
        }
    } else if (response["_links"] != null) {
        return [];
    } else if (Array.isArray(response)){
        //No Hateos resource -> return the response
        return response;
    } else {
        //Empty hateos resource
        return response;
    }
}

function generateErrorMessage(response) {
    let message = "";

    if (response.errorCode != null) {
        message = message + "Code: " + response.errorCode + "\n";
    }

    if(response.info != null) {
        message = message + "Info: " + response.info + "\n";
    }

    if(response.message != null) {
        message = message + "Message: " + response.message + "\n";
    }

    return {error: true, message: message, result: response};
}

export function createFilterQuery(filter) {

    //Return an empty string if the filter is empty
    if(filter == null) {
        return "";
    }

    //Create a query otherwise
    let query = "?";

    Object.keys(filter).forEach((key, i) => {
        if(filter[key] != null && filter[key] !== "") {

            //Check for the data type of the filter param
            if(Array.isArray(filter[key])) {
                //TYPE = Array -> Transform Array ["A","B","C",...] to single String "A,B,C,"
                let entries = "";
                filter[key].forEach(entry => (entries = entries + "," + encodeURIComponent(entry)));
                if (entries.slice(-1) === ",") {
                    entries = entries.slice(0, -1);
                }
                if (filter[key].length > 0) {
                    query = query + key + "=" + entries + "&";
                }
            } else {
                //TYPE = Default
                query = query + key + "=" + encodeURIComponent(filter[key]) + "&";
            }
        }
    });

    //Remove the last character if its a '&' or '?'
    const lastChar = query.slice(-1);
    if(lastChar === '&' || lastChar === '?' ){
        query = query.slice(0,-1);
    }

    return query;
}