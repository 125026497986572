const ContractMetaData = {

    //Columns
    COLUMNS: [
        //Default & Badges
        {label: "ID", id: 'id', columnType: 'default', show: true},
        {label: "Status", id: 'status', columnType: 'badge', options: [
                {value: "REQUESTED", style: "primary", label: "Requested"},
                {value: "SUCCESSFUL", style: "success", label: "Successful"},
                {value: "SUBMITTED", style: "success", label: "Submitted"},
                {value: "IN_APP_PURCHASE", style: "warning", label: "IAP"},
                {value: "CUSTOMER_NOT_FOUND", style: "warning", label: "N/F: Customer"},
                {value: "NO_CONTRACTS_FOUND", style: "warning", label: "N/F: Contracts"},
                {value: "NO_ACTIVE_CONTRACT_FOUND", style: "warning", label: "N/F: Active Contract"},
                {value: "TIGERTICKET_CONTRACT", style: "warning", label: "Ticket Contract"},
                {value: "NO_ACTIVE_SUBSCRIPTION_FOUND", style: "warning", label: "N/F: Subscription"},
                {value: "NO_CONTRACT_FOUND_FOR_PRODUCT_TYPE", style: "warning", label: "N/F: Product Contract"},
                {value: "UNKNOWN_CONTRACT_VALIDITY", style: "danger", label: "Error: Contract validity"},
                {value: "BILLWERK_ERROR", style: "danger", label: "Error: Billwerk"}
            ], show: true},
        {label: "Product Type", id: 'productType', columnType: 'badge', options: [
                {value: "TIGERBOOKS", style: "danger", label: "tigerbooks"},
                {value: "TIGERTONES", style: "success", label: "tigertones"}
            ], show: false},
        {label: "Cancellation Type", id: 'cancellationType', columnType: 'badge', options: [
                {value: "ON_TIME", style: "success", label: "Ordinary"},
                {value: "EXTRAORDINARY", style: "danger", label: "Extraordinary"}
            ], show: true},
        {label: "Extraordinary Reason", id: 'extraordinaryReason', columnType: 'default', show: false},
        {label: "E-Mail", id: 'email', columnType: 'default', show: true},
        {label: "Reason", id: 'reason', columnType: 'default', show: true},
        {label: "Note", id: 'note', columnType: 'default', show: false},
        {label: "Billwerk Customer ID", id: 'billwerkCustomerId', columnType: 'default', show: false},
        {label: "Billwerk Contract ID", id: 'billwerkContractId', columnType: 'default', show: false},
        {label: "Account ID", id: 'accountId', columnType: 'default', show: false},
        {label: "Cancellation Date", id: 'cancellationDate', columnType: 'datetime', show: true},
        {label: "Contract Validity", id: 'contractValidUntil', columnType: 'datetime', show: true},
        {label: "Submitted At", id: 'submittedAt', columnType: 'datetime', show: true},
        {label: "Withdrawn", id: 'withdrawn', columnType: 'boolean', show: false}
    ],

    //Details
    DETAILS_GENERAL: [
        [{label: 'ID', id: 'id', type: 'number', editable: 'never', isExpertField: false}, {label: 'Field 2', id: 'field_2', type: 'text', editable: 'true'}, {label: "Field 3", id: 'field_3', type: 'number', editable: 'true'}],
        [{label: 'Field 4', id: 'field_4', type: 'text', editable: 'true'}],
    ],
    DETAILS_SECTION_1: [
        [{label: 'Field 5', id: 'field_5', type: 'text', editable: 'new'}],
        [{label: 'Field 6', id: 'field_6', type: 'number', editable: 'true', isExpertField: true}]
    ],

    //Sorting
    CONTRACT_SORT_PARAMS: [{id: 'id', label: 'Cancellation ID'}, {id: 'cancellationDate', label: 'Cancellation Date'}],
};

export default ContractMetaData;