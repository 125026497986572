const ProductGroupMetaData = {

    //Columns
    COLUMNS: [
        //Default & Badges
        {label: "ID", id: 'id', columnType: 'default', show: true},
        {label: "Name", id: 'name', columnType: 'default', show: true},
    ],

    //Details
    DETAILS_GENERAL: [
        [{label: 'ID', id: 'id', type: 'number', editable: 'never'}, {label: 'Name', id: 'name', type: 'text', editable: 'true'}],
    ],

    //Sorting
    PRODUCT_GROUP_SORT_PARAMS: [],
};

export default ProductGroupMetaData;